import React, { useEffect, useState } from 'react';
import api from '../../../../services/api';

export function useCursosCancelado<T>() {
  const [cancelado, setCursos] = useState<T | null>(null);
  const [load_cancelado, setLoad] = useState(false);

  async function handleSetCursosCancelado(page = 1, search?: string) {
    setLoad(true);
    const { data } = await api.get('/courses', {
      params: {
        page,
        title: search,
        status: "Cancelado",
      },
    });
    if (data.data.length === 0 && page > 1) {
      handleSetCursosCancelado(page - 1);
      setCursos(data);
    } else {
      setCursos(data);
    }
    setLoad(false);
  }

  useEffect(() => {
    handleSetCursosCancelado();
  }, []);
  return {
    cancelado, handleSetCursosCancelado, load_cancelado,
  };
}
