/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from 'react';
import styled from 'styled-components';

type props = {
  data: any[] | undefined
  useStateSearch: [string, (value: string) => void]
  id?: string
  keySearch: string
  label?: string
  placeholder?: string
  button?: React.ReactNode
}

export function InputSearch(props:props) {
  const {
    data, keySearch, useStateSearch, label, id, placeholder, button,
  } = props;
  const [search, setSeach] = useStateSearch;
  const [focus, setFocus] = useState(false);

  const optionsFilter = data?.filter((item) => {
    if (item) {
      return item[keySearch]?.toLowerCase().includes(search.toLowerCase());
    } return false;
  });

  function handleInputValue(e: any) {
    setSeach(e.target.value);
    if (e.target.value === "") {
      return setFocus(false);
    }
    return setFocus(true);
  }

  function handleFocus() {
    // if (inputValue === "") {
    //   return setFocus(false);
    // }
    return setFocus(true);
  }

  function handleOnblur() {
    setTimeout(() => {
      setFocus(false);
    }, 150);
  }

  function handleKey(e: any) {
    if (e.key === "Enter") {
      handleOnblur();
    }
  }

  return (
    <div>
      <Label htmlFor={id}>{label}</Label>
      <WrapperInputAndButton>
        <div className="w-100">
          <Input
            id={id}
            className="form-control form-control-lg"
            style={{ cursor: "black" }}
            onChange={handleInputValue}
            onFocus={handleFocus}
            onBlur={handleOnblur}
            value={search}
            onKeyUp={handleKey}
            placeholder={placeholder}
            autoComplete="off"
          />
          {focus && optionsFilter?.length !== 0 && (
          <ContainerOptions>
            {optionsFilter?.map((item, i) => (
              <Option
                key={i}
                onClick={() => {
                  setSeach(item[keySearch]);
                }}
              >
                <p>{item[keySearch]}</p>
              </Option>
            ))}
          </ContainerOptions>
          )}
        </div>
        {button}
        {/* <ButtonWrapper
          type="button"
          className="btn btn-primary btn-lg"
        >
          Pesquisar
        </ButtonWrapper> */}
      </WrapperInputAndButton>
      {/* <p className="text-secondary">Após digitar uma pista, clique no botão pesquisar e visualize dados do egresso</p> */}
    </div>
  );
}

const Label = styled.label`
  font-size: 23px;
  font-weight: 700
  font-size: 12px;
  font-weight: bold;
`;

const WrapperInputAndButton = styled.div`
  display: flex;
  gap: 10px;
  border-radius: 4px;
  align-items: center;
  width: 100%;
`;

const ContainerOptions = styled.div`
  width: 100%;
  max-height: 250px;
  overflow: auto;
  border: gray solid 1px;
  position: absolute;
  background-color: white;
`;

const Option = styled.div`
  font-size: 18px;
  padding: 10px;
  background-color: white;
  cursor: pointer;
  :hover {
    background-color: rgb(130, 200, 255);
  }
`;

const Input = styled.input`
  @media (max-width: 1024px) {
    font-size: 16px;
  }
  @media (max-width: 768px) {
    font-size: 14px;
  }
`;
