import React, { useState } from 'react';
import {
  FaCalendarDay, FaUserCircle,
} from 'react-icons/fa';

import { GoBell } from 'react-icons/go';

import styled from 'styled-components';
import ReactModal from 'react-modal';
import { useHistory } from 'react-router-dom';
import { NavBarContainer } from './NavBar.styled';
import { MenuItems } from './MenuItem';
import brazaoIcone from '../../assets/brazao1.png';

import { useAuth } from '../../hooks/auth';
import { SelecionarPerfilModal } from '../SelecionarPerfilModal';
import { ListarFinalDePena } from '../ListarFinalDePena';

const NavBar = () => {
  const [isClicked, setClick] = useState(false);

  const { user, signed, signOut } = useAuth();

  const [isOpenOptions, setIsOpenOptions] = useState(false);

  const handleClick = () => {
    setClick(!isClicked);
  };

  const history = useHistory();

  return (
    <NavBarContainer>
      <div
        onClick={() => {
          // history.push('dashboard');
          window.location.href = '/dashboard';
        }}
        style={{
          cursor: 'pointer', display: "flex", flexDirection: 'row', alignItems: 'center',
        }}
      >
        <img src="/assets/social_icon.png" alt="icone-albergado" />
        <div style={{ marginLeft: 10, marginTop: 10 }}>
          <h1 style={{ fontSize: 18, fontWeight: 'bold' }}>E-ALBERGADO</h1>
          <h2 style={{ fontSize: 16, fontWeight: 'normal', marginTop: -5 }}>Sistema de controle de egressos</h2>
        </div>
      </div>

      <div className="user" style={{ display: signed ? "" : "none" }}>
        <div>
          <div
            className="d-flex flex-row justify-content-center align-items-center"
          >
            {signed && (user.profile.title === 'MASTER' || user.profile.title === 'Fiscal' || user.profile.title === 'Administrador') ? (
              <GoBell onClick={() => handleClick()} style={{ fontSize: 35, paddingRight: 8, cursor: signed && (user.profile.title === 'MASTER' || user.profile.title === 'Fiscal' || user.profile.title === 'Administrador') ? 'pointer' : '' }} className="icon" />) : (<></>)}

            <FaUserCircle onClick={() => setIsOpenOptions(!isOpenOptions)} style={{ fontSize: 30, paddingRight: 8, cursor: signed && user.profile.title === 'MASTER' ? 'pointer' : '' }} className="icon" />
            <span className="name-user">{signed ? user.name : 'Usuário'}</span>
          </div>
          {signed && user.profile.title === 'MASTER' && (
          <SelecionarPerfilModal isOpenState={[isOpenOptions, setIsOpenOptions]} />
          )}
          {isClicked && (
          <ListarFinalDePena isOpenState={[isClicked, setClick]} />
          )}
        </div>
        {
          signed && <span className="logout" style={{ cursor: 'pointer', fontSize: 16 }} onClick={signOut}>Sair</span>
        }
      </div>

      {/* <div className="user" style={{ display: signed ? "" : "none" }}>
        <div>
          <div
            className="d-flex flex-row justify-content-center align-items-center"
          >
            {signed && (user.profile.title === 'MASTER' || user.profile.title === 'Fiscal' || user.profile.title === 'Administrador') ? (
              <FaCalendarDay onClick={() => handleClick()} style={{ fontSize: 30, paddingRight: 8, cursor: signed && (user.profile.title === 'MASTER' || user.profile.title === 'Fiscal' || user.profile.title === 'Administrador') ? 'pointer' : '' }} className="icon" />) : (<></>)}

            <FaUserCircle onClick={() => setIsOpenOptions(!isOpenOptions)} style={{ fontSize: 30, paddingRight: 8, cursor: signed && user.profile.title === 'MASTER' ? 'pointer' : '' }} className="icon" />
            <span className="name-user">{signed ? user.name : 'Usuário'}</span>
          </div>
          {signed && user.profile.title === 'MASTER' && (
          <SelecionarPerfilModal isOpenState={[isOpenOptions, setIsOpenOptions]} />
          )}
          {isClicked && (
          <ListarFinalDePena isOpenState={[isClicked, setClick]} />
          )}
        </div>
        {
          signed && <span className="logout" style={{ cursor: 'pointer', fontSize: 16 }} onClick={signOut}>Sair</span>
        }
      </div> */}

      {/* <div className="menu-icon" onClick={handleClick}>
        <i className={isClicked ? 'fas fa-times' : 'fas fa-bars'} />
      </div>

      <ul className={isClicked ? 'nav-menu active' : 'nav-menu'}>
        {MenuItems.map((item, index) => (
          <li key={index}>
            <a className={item.cName} href={item.url}>
              {item.title}
            </a>
          </li>
        ))}
      </ul> */}
    </NavBarContainer>
  );
};

export default NavBar;

const Button = styled.button`
  background-color: white;
  cursor: pointer;
  border: none;
  border-radius: .3rem;
  padding: .5rem;
  :hover {
    background-color: #E5E5E5;
  }
`;
const ButtonBack = styled.button`
  background-color: white;
  color: #007BFF;
  cursor: pointer;
  border: none;
  border-radius: .3rem;
  padding: .5rem;
  :hover {
    background-color: #E5E5E5;
  }
`;
