import React, { useState } from 'react';
import { Chart } from 'react-chartjs-2';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { ArrayFormValues, FormAcompanhamentos } from '../../../../components/develop-nilton-components/FormAcompanhamentos';
import { SpinnerLoading } from '../../../../components/SpinnerLoading';

import api from '../../../../services/api';
import { IFatores } from '../type';

export function FatoresEmo({
  getValues, setValue, setTab,
}: IFatores) {
  const arrayFormValues: ArrayFormValues["formValues"] = [
    {
      label: "1. Apresenta dificuldades de adaptação?",
      type: "number",
      name: "dificuldade_adaptacao",
      onChange: (e:any) => {
        set_dificuldade_adaptacao(e.target.value);
        setValue('dificuldade_adaptacao', Number(e.target.value));
      },
    },
    {
      label: "2. Irrita-se facilmente?",
      type: "number",
      name: "irrita_facilmente",
      onChange: (e:any) => {
        set_irrita_facilmente(e.target.value);
        setValue('irrita_facilmente', Number(e.target.value));
      },
    },
    {
      label: "3. Reclama muito?",
      type: "number",
      name: "reclama_muito",
      onChange: (e:any) => {
        set_reclama_muito(e.target.value);
        setValue('reclama_muito', Number(e.target.value));
      },
    },
    {
      label: "4. É agressivo?",
      type: "number",
      name: "agressivo",
      onChange: (e:any) => {
        set_agressivo(e.target.value);
        setValue('agressivo', Number(e.target.value));
      },
    },
    {
      label: "5. Preocupa-se em apresentar bom desempenho? ",
      type: "number",
      name: "preocupa_apresentar_bom_desempenho",
      onChange: (e:any) => {
        set_preocupa_apresentar_bom_desempenho(e.target.value);
        setValue('preocupa_apresentar_bom_desempenho', Number(e.target.value));
      },
    },
    {
      label: "6. Possui algum tipo de movimento estereotipados, tique ou cacoete? ",
      type: "boolean",
      name: "esteriotipo_tique_cacoete",
      especificacao: 'especificacao_esteriotipo_tique_cacoete',
      onChange: (e:any) => {
        setValue('esteriotipo_tique_cacoete', e.target.value);
        set_esteriotipo_tique_cacoete(e.target.value);
      },
      onChangeEspecificacao: (e:any) => {
        setValue("especificacao_esteriotipo_tique_cacoete", e.target.value);
      },
    },
    {
      label: "7. Fala com voz modulada e ritmo adequado?",
      type: "number",
      name: "voz_modulada_ritmo_adequado",
      onChange: (e:any) => {
        set_voz_modulada_ritmo_adequado(e.target.value);
        setValue('voz_modulada_ritmo_adequado', Number(e.target.value));
      },
    },
    {
      label: "8. Denota alguma preocupação familiar específica?",
      type: "number",
      name: "preocupacao_familiar_especifica",
      onChange: (e:any) => {
        set_preocupacao_familiar_especifica(e.target.value);
        setValue('preocupacao_familiar_especifica', Number(e.target.value));
      },
    },
    {
      label: "9. É cooperador, não se negando a fazer as coisas?",
      type: "number",
      name: "cooperador",
      onChange: (e:any) => {
        set_cooperador(e.target.value);
        setValue('cooperador', Number(e.target.value));
      },
    },
    {
      label: "10. É satisfatoriamente sociável, não sendo muito egoísta ou exibicionista",
      type: "number",
      name: "satisfatoriamente_sociavel",
      onChange: (e:any) => {
        set_satisfatoriamente_sociavel(e.target.value);
        setValue('satisfatoriamente_sociavel', Number(e.target.value));
      },
    },
    {
      label: "11. É disciplinado, lembrando de suas obrigações rotineiras (regras)",
      type: "number",
      name: "disciplinado",
      onChange: (e:any) => {
        set_disciplinado(e.target.value);
        setValue('disciplinado', Number(e.target.value));
      },
    },
  ];

  const [dificuldade_adaptacao, set_dificuldade_adaptacao] = useState(getValues().dificuldade_adaptacao || 0);
  const [irrita_facilmente, set_irrita_facilmente] = useState(getValues().irrita_facilmente || 0);
  const [reclama_muito, set_reclama_muito] = useState(getValues().reclama_muito || 0);
  const [agressivo, set_agressivo] = useState(getValues().agressivo || 0);
  const [preocupa_apresentar_bom_desempenho, set_preocupa_apresentar_bom_desempenho] = useState(getValues().preocupa_apresentar_bom_desempenho || 0);
  const [preocupacao_familiar_especifica, set_preocupacao_familiar_especifica] = useState(getValues().preocupacao_familiar_especifica || 0);
  const [voz_modulada_ritmo_adequado, set_voz_modulada_ritmo_adequado] = useState(getValues().voz_modulada_ritmo_adequado || 0);
  const [cooperador, set_cooperador] = useState(getValues().cooperador || 0);
  const [satisfatoriamente_sociavel, set_satisfatoriamente_sociavel] = useState(getValues().satisfatoriamente_sociavel || 0);
  const [disciplinado, set_disciplinado] = useState(getValues().disciplinado || 0);

  const dataset = {
    labels: [
      'Adaptação',
      'Irritação',
      'Reclamação',
      'Agressivo',
      'Desempenho',
      'Voz',
      'familia',
      'Cooperação',
      'Sociavel',
      'Disciplina',

    ],
    datasets: [{
      label: '',
      data: [
        dificuldade_adaptacao,
        irrita_facilmente,
        reclama_muito,
        agressivo,
        preocupa_apresentar_bom_desempenho,
        voz_modulada_ritmo_adequado,
        preocupacao_familiar_especifica,
        cooperador,
        satisfatoriamente_sociavel,
        disciplinado,
      ],
      borderColor: '#FFA500',
      backgroundColor: '#FFA500',
    }],
  };
  const optionsChart = {
    scales: {
      y: {
        suggestedMax: 10,
        suggestedMin: 0,
      },
      x: {
        pointLabels: {
          font: {
            size: 50,
          },
        },
      },
    },
    plugins: { legend: { display: false } },
  };

  const [load, setLoad] = useState(false);

  const onSubmit = async (data:any) => {
    setLoad(true);
    try {
      await api.put(`/psicopedagogue/${getValues().id}`, data);
      setTab(3);

      window.scrollTo({ top: 0 });
    } catch (error) {
      // @ts-ignore
      toast.error(error.response.data.message);
    }
    setLoad(false);
  };

  const onSubmitError = (errors:any) => {
    const error = Object.keys(errors);
    for (let i = 0; i < error.length; i + 1) {
      toast.error(errors[error[i] as keyof any]?.message);
      break;
    }
  };

  const [esteriotipo_tique_cacoete, set_esteriotipo_tique_cacoete] = useState(getValues("esteriotipo_tique_cacoete"));
  const { register, handleSubmit } = useForm({ defaultValues: { ...getValues(), esteriotipo_tique_cacoete } });

  return (
    <div>
      <div style={{ width: '100%' }}>
        <Chart
          type="bar"
          data={dataset}
          // @ts-ignore
          options={optionsChart}
        />
      </div>

      <form onSubmit={handleSubmit(onSubmit, onSubmitError)}>
        <div>
          <FormAcompanhamentos
            formValues={arrayFormValues}
            register={register}
            keysEspecificacao={{
              esteriotipo_tique_cacoete,
            }}
          />
        </div>
        <div className="d-flex justify-content-between mt-5 mb-5 w-75">
          <button className="btn btn-primary btn-lg" onClick={() => setTab(1)} type="button" data-testid="back">Voltar</button>
          {load ? (
            <button
              className="btn btn-primary btn-lg"
              type="button"
            >
              <SpinnerLoading />
            </button>
          ) : (
            <button
              className="btn btn-primary btn-lg"
              type="submit"
              data-testid="next-2"
            >
              Avançar
            </button>
          )}
        </div>
      </form>
    </div>
  );
}
