import React, { useEffect, useState } from 'react';
import ReactModal from 'react-modal';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import Loading from 'react-loading';
import {
  FaRegFolderOpen,
} from 'react-icons/fa';
import { useAuth, usePermission } from '../../hooks/auth';
import api from "../../services/api";

type ListarFinalDePenaModalProps= {
  isOpenState: [boolean, (boolean: boolean) => void]
}

type list ={
    name:string, daysCount:string
}

export function ListarFinalDePena(props: ListarFinalDePenaModalProps) {
  const { isOpenState } = props;
  const [isOpen, setIsOpen] = isOpenState;
  const [list, setList] = useState<list[]>([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    async function getList() {
      try {
        const response = await api.get(`list_nearly_end_sentence`);
        if (response?.data) {
          setLoading(false);
          setList(response.data.data);
        }
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    }

    getList();
  }, []);
  return (

    <ReactModal
      isOpen={isOpen}
      ariaHideApp={false}
      onRequestClose={() => setIsOpen(false)}
      style={{
        content: {
          width: "800",
          overflow: 'auto',
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          marginRight: '-50%',
          transform: 'translate(-50%, -50%)',
          borderRadius: '.6rem',
          maxHeight: loading ? 'auto' : 600,
        },
        overlay: {
          background: 'rgba(0,0,0,.5)',
        },
      }}
    >

      {loading ? (
        <>
          <Loading color="#1B7E9F" type="spin" />
          <ButtonBack
            type="button"
            onClick={() => setIsOpen(false)}
          >
            Voltar
          </ButtonBack>
        </>
      )
        : (
          <>
            <h1 className="pb-2 mb-3 h3 border-bottom border-primary">Lista de egressos em últimos dias de pena</h1>
            {list.length === 0 ? (
              <div className="d-flex justify-content-center flex-column">
                <div style={{
                  alignSelf: 'center',
                }}
                >
                  <FaRegFolderOpen color="#C8CED2" size={55} />
                </div>

                <span style={{
                  justifyContent: 'center', alignItems: 'center', textAlign: 'center', margin: 30, color: '#6E727A',
                }}
                >
                  Não existem egressos em um período próximo de término de pena.
                </span>

                <ButtonBack
                  type="button"
                  onClick={() => setIsOpen(false)}
                >
                  Voltar
                </ButtonBack>
              </div>
            ) : (
              <>
                <div className="d-flex justify-content-center flex-column">
                  {
          // @ts-ignore
          list.map((egress, index) => (
            <li
              style={{
                backgroundColor: index % 2 == 0 ? '#ECF1F8' : '#f7fafc', padding: 5, marginBottom: 5, borderRadius: 10, height: 50, listStyleType: 'none', flex: 1,
              }}
              key={egress.name}

            >
              <span style={{ alignItems: 'center', justifyContent: 'center', textAlign: 'center' }}>{`O egresso: ${egress.name}, possui ${egress.daysCount} dia(s) restante(s).`}</span>
            </li>
          ))
}

                  <ButtonBack
                    type="button"
                    onClick={() => setIsOpen(false)}
                  >
                    Voltar
                  </ButtonBack>
                </div>
              </>
            )}

          </>
        )}
    </ReactModal>

  );
}

const Button = styled.button`
  background-color: white;
  cursor: pointer;
  border: none;
  border-radius: .3rem;
  padding: .5rem;
  :hover {
    background-color: #E5E5E5;
  }
`;
const ButtonBack = styled.button`
  background-color: white;
  color: #007BFF;
  cursor: pointer;
  border: none;
  border-radius: .3rem;
  padding: .5rem;
  :hover {
    background-color: #E5E5E5;
  }
`;
