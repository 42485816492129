import styled from "styled-components";

export const ConteinerSectionHeader = styled.div`
    // margin-top: 20px;
    .header {
        margin: 0 20px 20px 0;
        display: flex;
        justify-content: space-between;

        & h1 {
            font-size: 48px;
            font-weight: 700;
            color: rgba(48, 96, 153, 1);
        }

        & h2 {
            font-size: 36px;
            font-weight: 500;
            text-align: left;
        }
    }

    @media (max-width:1024px) {
      .header {
        h1 {
          font-size: 30px;
          margin: 0;
        }
        h2 {
          font-size: 26px;
          margin: 0;
        }
      }
    }
    @media (max-width:768px) {
      .header {
        h1 {
          font-size: 25px;
        }
        h2 {
          font-size: 20px;
        }
      }
    }
`;
