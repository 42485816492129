import { useQuery } from "react-query";
import api from "../../services/api";
import { IProfiles } from "../../types/IProfiles";

// export type UserAxiosResponse = {
//   data: IProfiles[]
//   meta:{last_page: number}
// }

export function useGetProfiles() {
  const { isLoading, data: profiles } = useQuery<IProfiles[]>(
    ['profiles'],
    async () => {
      const { data } = await api.get('/profiles');
      return data;
    },
  );
  return { isLoading, profiles };
}
