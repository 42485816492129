import React from 'react';

import { Administrador } from './components/administrador';
import { Fiscal } from './components/fiscal';
import { SectionHeader } from '../../components/SectionHeader';
import { useAuth, usePermission } from '../../hooks/auth';
import GridAdm from '../../components/GridAdm/GridAdm';
import { AdmContainer } from './Adm.styled';

export const Dashboard = () => {
  const { profile } = useAuth();
  const { title } = usePermission();

  return (
    <>
      <div id="painel-container">
        {profile?.title === 'Administrador' && <Administrador />}
        {profile?.title === 'Fiscal' && <Fiscal />}

        {profile?.title !== 'Administrador' && profile?.title !== 'Fiscal' && (
        <AdmContainer id="painel-container">
          <div className="header">
            {title === 'master' ? <h1>{`Módulo - ${profile?.title}`}</h1>
              : <h1>{`Módulo - ${title === "Psico-pedagogo" ? "Psicopedagogo" : title}`}</h1>}
            <h2>Painel de Funcionalidades</h2>
          </div>

          <GridAdm
            // @ts-ignore
            // eslint-disable-next-line react-hooks/rules-of-hooks
            cardItems={profile?.functions || usePermission().functions}
          />
        </AdmContainer>
        )}
      </div>
    </>
  );
};
