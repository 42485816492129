import React, { useEffect, useRef, useState } from 'react';
import { Doughnut, Bar, getElementsAtEvent } from 'react-chartjs-2';
import { Input } from 'reactstrap';
import { ToastContainer, toast } from 'react-toastify';

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';

import { BsDot } from 'react-icons/bs';
import ReactTooltip from 'react-tooltip';
import moment from 'moment';
import styled from 'styled-components';
import { MdOutlinePlaylistPlay } from 'react-icons/md';
import { useHistory } from 'react-router-dom';
import { TableGeral } from '../../Fiscal/CadastrarRonda/components/TableGeral';
import {
  TablePadrão,
  Td,
  Tr,
} from '../../../components/develop-nilton-components/TablePadrão';
import { AbaFilterContent } from '../../../components/develop-nilton-components/AbaFiltro';
import { useRounds } from '../../Fiscal/CadastrarRonda/hooks/useRounds';
import { Rounds } from '../../Fiscal/CadastrarRonda/types';
import api from '../../../services/api';
import { Detail } from '../../Adm/Egressos/Detail';
import { IEgresses } from '../../../types/IEgresses';
import { SectionHeader } from '../../../components/SectionHeader';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
);

export const Administrador = () => {
  const history = useHistory();

  const roundsGeral = useRounds<Rounds>();

  const chartRef = useRef();
  const chartRefBar = useRef();

  const [value, setValue] = useState('0');

  const [isAdmin, setIsAdmin] = useState(true);

  const [isWatchEgresse, setIsWatchEgresse] = React.useState(false);
  const [egresse, setEgresse] = React.useState<IEgresses | null>(null);

  async function handleUpdate(page?: number) {
    roundsGeral.handleSetRounds(page);
  }

  const [dashboard, setDashboard] = useState<any>({
    graph: null,
    dataBar: null,
    egressos: 0,
    rondas_homologadas: 0,
    projetos_homologados: 0,
  });

  const [filtersBar, setFiltersBar] = useState<any>({
    initial_date: undefined,
    final_date: undefined,
    isAdmin: 'true',
    isFiscal: 'false',
  });

  const [filtersDonougth, setFiltersDonougth] = useState<any>({
    isPad: 'true',
    isAdmin: 'true',
    isFiscal: 'false',
  });

  const [dataBar, setDataBar] = useState({
    labels: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun'],
    datasets: [
      {
        label: 'Cadastrados',
        data: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun'].map(() => 0),
        backgroundColor: '#0796D7',
      },
      {
        label: 'Pena a Finalizar',
        data: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun'].map(() => 0),
        backgroundColor: '#DC7070',
      },
    ],
  });

  const [dataDonougth, setDataDonougth] = useState({
    labels: ['Atribuído', 'Sem Atribuição'],
    datasets: [
      {
        label: '',
        data: [0, 0],
        borderColor: ['#fff'],
        backgroundColor: ['#0796D7', 'rgba(201, 19, 19, 0.6)'],
        pointBackgroundColor: '#fff',
      },
    ],
  });

  const [fiscais, setFiscais] = useState([]);

  const [nearlyEgresses, setNearlyEgresses] = useState([]);

  async function getNearlyEgresses() {
    api.get('/list_nearly_end_sentence').then(({ data }) => {
      setNearlyEgresses(data.data.map((item: any) => item.egresso));
    });
  }

  const [errorGraph, setErrorGraph] = useState('');

  async function getDashboard() {
    api
      .get('/dashboard', {
        params: filtersBar,
      })
      .then(({ data }) => {
        setDashboard(data);
        setDataBar(data.dataBar);
        setErrorGraph('');
      })
      .catch(error => {
        if (filtersBar.initial_date && filtersBar.final_date) {
          setErrorGraph(error?.response?.data?.message);
          /* toast(error?.response?.data?.message, {
          type: 'warning',
        }); */
        }
      });

    api
      .get('/dashboard', {
        params: filtersDonougth,
      })
      .then(({ data }) => {
        setDataDonougth(data.graph);
        setValue(data.graph.total);
      });
  }

  useEffect(() => {
    getDashboard();
    getNearlyEgresses();
  }, [filtersBar, filtersDonougth]);

  useEffect(() => {
    if (!isAdmin) {
      setFiltersBar({ ...filtersBar, isFiscal: 'true' });
      setFiltersDonougth({ ...filtersDonougth, isFiscal: 'true' });
    } else {
      setFiltersBar({ ...filtersBar, isFiscal: 'false' });
      setFiltersDonougth({ ...filtersDonougth, isFiscal: 'false' });
    }
  }, [isAdmin]);

  const onClick = (event: any) => {
    // @ts-ignore
    const clickedElements = getElementsAtEvent(chartRef?.current, event);
    if (clickedElements.length > 0) {
      // @ts-ignore
      // setValue(clickedElements[0].element.$context.raw);
    }
  };

  const onClickBar = (event: any) => {
    // @ts-ignore
    const clickedElements = getElementsAtEvent(chartRefBar?.current, event);
    if (clickedElements.length > 0) {
      // @ts-ignore
      console.log(clickedElements[0].element.$context.raw);
    }
  };

  const options = {
    // maintainAspectRatio: false,
    responsive: true,
    plugins: {
      tooltip: {
        enabled: true,
      },
      legend: {
        display: false,
        position: 'top' as const,
      },
    },
    value,
  };

  const optionsBar = {
    // maintainAspectRatio: false,
    responsive: true,
    scales: {
      x: {
        grid: {
          display: true,
        },
      },
      y: {
        grid: {
          display: false,
        },
      },
    },
    plugins: {
      legend: {
        display: false,
        position: 'top' as const,
      },
      tooltip: {
        enabled: true,
      },
    },
  };

  return (
    <div>
      {isWatchEgresse ? (
        <Detail
          useStateEgresse={[egresse, setEgresse]}
          useStateIsOpen={[isWatchEgresse, setIsWatchEgresse]}
        />
      ) : (
        <div id="painel-container">
          <SectionHeader subtitle="Dashboard" />

          <div
            style={{
              display: 'flex',
              width: '100%',
              alignItems: 'center',
              justifyContent: 'space-between',
              marginBottom: 50,
            }}
          >
            <div
              style={{
                display: 'flex',
                width: '33%',
                height: 88,
                alignItems: 'center',
                justifyContent: 'space-between',
                borderStyle: 'solid',
                borderColor: '#1B7E9F',
                borderWidth: 2,
                borderRadius: 8,
              }}
            >
              <CardStyle style={{ display: 'flex', alignItems: 'center' }}>
                <h1
                  style={{
                    marginLeft: 20,
                    marginBottom: 0,
                    color: '#1B7E9F',
                    fontSize: 28,
                    marginRight: 16,
                  }}
                >
                  {String(dashboard.egressos).padStart(2, '0')}
                </h1>
                <TitleCards style={{ marginBottom: 0, color: '#2C2C2C' }}>
                  Egressos
                </TitleCards>
              </CardStyle>
            </div>

            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                height: 88,
                borderStyle: 'solid',
                width: '33%',
                borderColor: '#1B7E9F',
                borderWidth: 2,
                borderRadius: 8,
              }}
            >
              <CardStyle style={{ display: 'flex', alignItems: 'center' }}>
                <h1
                  style={{
                    marginLeft: 20,
                    marginBottom: 0,
                    color: '#1B7E9F',
                    fontSize: 28,
                    marginRight: 16,
                  }}
                >
                  {String(dashboard.rondas_homologadas).padStart(2, '0')}
                </h1>
                <TitleCards style={{ marginBottom: 0, color: '#2C2C2C' }}>
                  Rondas Homologadas
                </TitleCards>
              </CardStyle>
            </div>

            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                height: 88,
                borderStyle: 'solid',
                width: '33%',
                borderColor: '#1B7E9F',
                borderWidth: 2,
                borderRadius: 8,
              }}
            >
              <CardStyle style={{ display: 'flex', alignItems: 'center' }}>
                <h1
                  style={{
                    marginLeft: 20,
                    marginBottom: 0,
                    color: '#1B7E9F',
                    fontSize: 28,
                    marginRight: 16,
                  }}
                >
                  {String(dashboard.projetos_homologados).padStart(2, '0')}
                </h1>
                <TitleCards style={{ marginBottom: 0, color: '#2C2C2C' }}>
                  Projetos Homologados
                </TitleCards>
              </CardStyle>
            </div>
          </div>

          <div style={{ display: 'flex' }}>
            <ChartStyle>
              <ChartStyleBarWrapper
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                  background: '#FBFAFA',
                  padding: 10,
                  // width: '65%',
                }}
              >
                <div
                  style={{
                    background: '#FBFAFA',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    marginBottom: 20,
                  }}
                >
                  {' '}
                  <RowToCol>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <Input
                        style={{ marginRight: 5, width: 150 }}
                        onChange={e =>
                          setIsAdmin(e.target.value === 'Administrador')
                        }
                        type="select"
                        name="user"
                        id="user"
                      >
                        <option value="Administrador">Administrador</option>
                        <option value="Fiscal">Fiscal</option>
                      </Input>
                      <h1
                        style={{
                          fontSize: 16,
                          fontWeight: 'bold',
                          marginLeft: 10,
                          marginBottom: 0,
                        }}
                      >
                        {isAdmin ? 'Egressos' : 'Fiscalizações'}
                      </h1>
                    </div>

                    <div
                      style={{
                        marginTop: errorGraph ? 20 : 0,
                        display: 'flex',
                        justifyContent: 'flex-end',
                        alignItems: 'flex-end',
                        width: '100%',
                      }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'flex-end',
                        }}
                      >
                        <Input
                          style={{ marginRight: 5 }}
                          onChange={e =>
                            setFiltersBar({
                              ...filtersBar,
                              initial_date: e.target.value,
                            })
                          }
                          type="date"
                        />
                        <Input
                          onChange={e =>
                            setFiltersBar({
                              ...filtersBar,
                              final_date: e.target.value,
                            })
                          }
                          type="date"
                        />
                      </div>
                      <span style={{ color: '#f00' }}>{errorGraph}</span>
                    </div>
                  </RowToCol>
                </div>

                <div>
                  <Bar
                    ref={chartRefBar}
                    onClick={onClickBar}
                    options={optionsBar}
                    data={dataBar}
                  />
                </div>

                {isAdmin ? (
                  <div>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <BsDot size={30} color="#0796D7" />
                      <h1 style={{ fontSize: 12, marginBottom: 0 }}>
                        Cadastrados
                      </h1>
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        marginTop: -10,
                      }}
                    >
                      <BsDot size={30} color="#DC7070" />
                      <h1 style={{ fontSize: 12, marginBottom: 0 }}>
                        Pena a Finalizar
                      </h1>
                    </div>
                  </div>
                ) : (
                  <div>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <BsDot size={30} color="#0796D7" />
                      <h1 style={{ fontSize: 12, marginBottom: 0 }}>
                        Pendente
                      </h1>
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        marginTop: -10,
                      }}
                    >
                      <BsDot size={30} color="#EFC903" />
                      <h1 style={{ fontSize: 12, marginBottom: 0 }}>
                        Em Andamento
                      </h1>
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        marginTop: -10,
                      }}
                    >
                      <BsDot size={30} color="#DC7070" />
                      <h1 style={{ fontSize: 12, marginBottom: 0 }}>
                        Concluído
                      </h1>
                    </div>
                  </div>
                )}
              </ChartStyleBarWrapper>

              {/* <div style={{ width: 10, background: '#fff', height: 450 }} /> */}

              <ChartStyleDonutWrapper
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                  background: '#FBFAFA',
                  padding: 10,
                  // width: '35%',
                }}
              >
                <div
                  style={{
                    background: '#FBFAFA',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    marginBottom: 20,
                  }}
                >
                  <div />
                  <div>
                    <Input
                      style={{ marginRight: 5, width: 150 }}
                      type="select"
                      onChange={e =>
                        setFiltersDonougth({
                          ...filtersDonougth,
                          isPad: e.target.value == 'Alvarás' ? 'true' : 'false',
                        })
                      }
                      name="round"
                      id="round"
                    >
                      <option value="Alvarás">Alvarás</option>
                      <option value="Notificações">Notificações</option>
                    </Input>
                  </div>
                </div>

                <div>
                  <Doughnut
                    ref={chartRef}
                    onClick={onClick}
                    plugins={[
                      {
                        id: 'here comes your id for the specific plugin',
                        beforeDraw(chart) {
                          const { width } = chart;
                          const { height } = chart;
                          const { ctx } = chart;
                          ctx.restore();
                          const fontSize = (height / 160).toFixed(2);
                          ctx.font = `${fontSize}em sans-serif`;
                          ctx.textBaseline = 'top';
                          ctx.fillStyle = '#1B7E9F';
                          // @ts-ignore
                          const text =
                            chart?.config?.options?.value ||
                            Number(chart?.config?.data?.datasets[0].data[0]) +
                              Number(chart?.config?.data?.datasets[0].data[1]);
                          const textX = Math.round(
                            (width - ctx.measureText(text).width) / 2,
                          );
                          const textY = height / 2 - 10;
                          ctx.fillText(text, textX, textY);
                          ctx.save();
                        },
                      },
                    ]}
                    data={dataDonougth}
                    options={options}
                  />
                </div>

                {isAdmin ? (
                  <div>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <BsDot size={30} color="#0796D7" />
                      <h1 style={{ fontSize: 12, marginBottom: 0 }}>
                        Atribuído
                      </h1>
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        marginTop: -10,
                      }}
                    >
                      <BsDot size={30} color="rgba(201, 19, 19, 0.6)" />
                      <h1 style={{ fontSize: 12, marginBottom: 0 }}>
                        Sem Atribuição
                      </h1>
                    </div>
                  </div>
                ) : (
                  <div>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <BsDot size={30} color="#0796D7" />
                      <h1 style={{ fontSize: 12, marginBottom: 0 }}>
                        Executados
                      </h1>
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        marginTop: -10,
                      }}
                    >
                      <BsDot size={30} color="rgba(201, 19, 19, 0.6)" />
                      <h1 style={{ fontSize: 12, marginBottom: 0 }}>
                        Não Executados
                      </h1>
                    </div>
                  </div>
                )}
              </ChartStyleDonutWrapper>
            </ChartStyle>
          </div>

          <div style={{ width: '100%', marginTop: 50 }}>
            <h1 style={{ color: '#2C2C2C', fontSize: 25, marginBottom: 20 }}>
              {isAdmin
                ? 'Egressos em Término de Pena'
                : 'Últimas Fiscalizações'}
            </h1>
            <AbaFilterContent
              tab={1}
              components={[
                isAdmin ? (
                  <TablePadrão
                    thead={[
                      'Egresso',
                      'Matrícula',
                      'Regime',
                      'Término da Pena',
                      'Ações',
                    ]}
                    headWidth={['80%', '50%', '50%', '50%', '50%']}
                  >
                    {nearlyEgresses.map((item: any, i) => (
                      <>
                        <Tr key={item?.id}>
                          <Td width={['80%', '50%', '50%', '50%', '50%'][0]}>
                            {item.nome}
                            <ReactTooltip />
                          </Td>
                          <Td width={['80%', '50%', '50%', '50%', '50%'][1]}>
                            {item.numero_cadastro}
                          </Td>
                          <Td width={['80%', '50%', '50%', '50%', '50%'][2]}>
                            {item.tipo_regime || 'Não informado'}
                          </Td>
                          <Td width={['80%', '50%', '50%', '50%', '50%'][3]}>
                            {moment(item.data_termino_pena).format(
                              'DD/MM/YYYY',
                            )}
                          </Td>
                          <Td
                            style={{ alignItems: 'center' }}
                            width={['80%', '50%', '50%', '50%', '50%'][4]}
                          >
                            <div
                              data-tip="Visualizar"
                              style={{ marginLeft: 10 }}
                              onClick={() => {
                                setEgresse(item);
                                setIsWatchEgresse(true);
                              }}
                            >
                              <MdOutlinePlaylistPlay
                                size={22}
                                cursor="pointer"
                              />
                            </div>
                          </Td>
                          <ReactTooltip />
                        </Tr>
                      </>
                    ))}
                  </TablePadrão>
                ) : (
                  // @ts-ignore
                  // <TableGeral isDashboard isDashboardAdm rounds={roundsGeral?.rounds && roundsGeral?.rounds?.data.length > 0 ? { ...roundsGeral.rounds, data: roundsGeral.rounds?.data.slice(0, 5) } : null} handleUpdate={handleUpdate} />
                  <TablePadrão
                    thead={[
                      'Referencial da Ronda',
                      'Fiscal',
                      'Egresso(s)',
                      'Data',
                      'Ações',
                    ]}
                    headWidth={['80%', '50%', '50%', '50%', '50%']}
                  >
                    {roundsGeral.rounds?.data
                      .slice(0, 5)
                      .map((item: any, i) => (
                        <>
                          <Tr key={item?.id}>
                            <Td width={['80%', '50%', '50%', '50%', '50%'][0]}>
                              {item.title}
                              <ReactTooltip />
                            </Td>
                            <Td width={['80%', '50%', '50%', '50%', '50%'][1]}>
                              {item.responsavel.name}
                            </Td>
                            <Td width={['80%', '50%', '50%', '50%', '50%'][2]}>
                              {item.egressos.length}
                            </Td>
                            <Td width={['80%', '50%', '50%', '50%', '50%'][3]}>
                              {moment(item.data_ronda).format('DD/MM/YYYY')}
                            </Td>
                            <Td
                              style={{ alignItems: 'center' }}
                              width={['80%', '50%', '50%', '50%', '50%'][4]}
                            >
                              <div
                                data-tip="Visualizar"
                                style={{ marginLeft: 10 }}
                                onClick={() => {
                                  // setEgresse(item);
                                  // setIsWatchEgresse(true);
                                  history.push({
                                    state: { ...item, isDashboard: true },
                                    pathname: '/homologar-ronda/egressos',
                                  });
                                }}
                              >
                                <MdOutlinePlaylistPlay
                                  size={22}
                                  cursor="pointer"
                                />
                              </div>
                            </Td>
                            <ReactTooltip />
                          </Tr>
                        </>
                      ))}
                  </TablePadrão>
                ),
              ]}
            />
          </div>
        </div>
      )}
    </div>
  );
};
const CardStyle = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-itens: center;
  @media only screen and (max-width: 800px) {
    flex-direction: column;
    width: 100%;
    align-items: center;
    justify-content: center;
  }
`;

const ChartStyle = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: start;
  width: 100%;
  @media only screen and (max-width: 800px) {
    flex-direction: column;
    width: 100%;
  }
`;

const RowToCol = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: start;
  @media only screen and (max-width: 800px) {
    flex-direction: column;
  }
`;

const ChartStyleBarWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: start;
  width: 65%;
  margin-bottom: 10px;
  @media only screen and (max-width: 800px) {
    flex-direction: column;
    width: 100%;
  }
`;

const ChartStyleDonutWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: start;
  width: 33%;
  margin-left: 10px;
  @media only screen and (max-width: 800px) {
    flex-direction: column;
    width: 100%;
  }
`;

const TitleCards = styled.div`
  font-size: 22px;
  color: #2c2c2c;
  @media only screen and (max-width: 800px) {
    font-size: 15px;
    color: #2c2c2c;
    text-align: center;
  }
`;
