import React, { useState } from 'react';
import { AbaFilterButtons, AbaFilterContent } from '../../../components/develop-nilton-components/AbaFiltro';
import { SectionHeader } from '../../../components/SectionHeader';
import { GraficoAnual } from './components/GraficoAnual';
import { GraficoTrimestral } from './components/GraficoTrimestral';

export function IndicadoresPsicologo() {
  const [tab, setTab] = useState(1);
  return (
    <div className="w-100">
      <SectionHeader subtitle="Indicadores" />
      <AbaFilterButtons
        useStateTab={[tab, setTab]}
        buttons={["Anual", "Trimestral"]}
      />

      <AbaFilterContent
        tab={tab}
        components={[
          <GraficoAnual />,
          <GraficoTrimestral />,
        ]}
      />
    </div>
  );
}
