import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory, useLocation } from 'react-router-dom';
import { AbaFilterContent } from '../../../../components/develop-nilton-components/AbaFiltro';
import { AbaProgress } from '../../../../components/develop-nilton-components/AbaProgress';
import { IndicadoresGraficosAcompanhamento } from '../../../../components/develop-nilton-components/IndicadoresGraficosAcompanhamento';
import { SectionHeader } from '../../../../components/SectionHeader';
import { AspectosSocias } from './AspectosSociais';
import { Observacao } from './Observacao';
import { Queixa } from './Queixa';
import { SocioEco } from './SocioEco';

export function PageIndicadoresIndividuaisAs() {
  const history = useHistory();
  const { state } = useLocation<any>();

  function handleRedirectPageHistory() {
    history.push({
      pathname: "/acompanhamento/historico",
      state: state.egresso,
    });
  }

  console.log(state.acompanhamento_concluidos);

  const { getValues, setValue } = useForm({ defaultValues: state.acompanhamento_concluidos });

  const [tab, setTab] = useState(1);
  return (
    <div className="w-100 mb-5" data-testid="page-indicadores">
      <SectionHeader subtitle="Indicadores Gráficos" handleRedirect={handleRedirectPageHistory} />

      <h3>{state.nome}</h3>

      <AbaProgress
        abas={[
          'Aspectos Sociais',
          'Socio-Econômicos',
          'Queixa',
          'Observações',
        ]}
        useStateTab={[tab, setTab]}
        size="medium"
      />

      <AbaFilterContent
        tab={tab}
        components={[
          <AspectosSocias
            getValues={getValues}
            setValue={setValue}
            setTab={setTab}
          />,
          <SocioEco
            getValues={getValues}
            setValue={setValue}
            setTab={setTab}
          />,
          <Queixa
            getValues={getValues}
            setValue={setValue}
            setTab={setTab}
          />,
          <Observacao
            getValues={getValues}
            setValue={setValue}
            setTab={setTab}
          />,
        ]}
      />

    </div>
  );
}
