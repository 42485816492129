import React from 'react';
import { Formik } from 'formik';
import {
  Button, Form, FormGroup, Input, Label, ModalFooter,
} from 'reactstrap';
import Select from 'react-select';
import { te } from 'date-fns/locale';
import { AiFillPlusCircle } from 'react-icons/ai';
import * as yup from 'yup';
import { InputField } from "../../../InputField";

type FormPart5Props = {
  initialValues: any
  handleNextStep: (newData: any) => void
  handleBackStep: () => void
  useStateCourses: [any[] | undefined, (courses:any[]) => void]
  experiencias_profissionais?: any
  setExperienciasProfissionais?: (value: any) => void
  isCreate: boolean;
}

export function FormPart5(props: FormPart5Props) {
  const {
    initialValues, isCreate, handleNextStep, handleBackStep, useStateCourses, experiencias_profissionais, setExperienciasProfissionais,
  } = props;

  const [cursos, setCursos] = useStateCourses;

  React.useEffect(() => {
    console.log(cursos);
  }, [cursos]);

  React.useEffect(() => {
    if (initialValues.experiencias_profissional1 && experiencias_profissionais.length == 0) {
      // @ts-ignore
      setExperienciasProfissionais(initialValues.experiencias_profissional1.split('&').map((item: any) => item.trim()));
    }
  }, [initialValues]);

  const [error, setError] = React.useState(false);

  return (
    <Formik
      initialValues={{ ...initialValues, experiencias_profissional1: null }}
      onSubmit={handleNextStep}
    >
      {(props) => {
        const {
          handleSubmit, values, setFieldValue,
        } = props;

        return (
          <Form onSubmit={(e) => {
            e.preventDefault();
            // @ts-ignore
            if (cursos?.filter((item) => item.checked).length > 0 && cursos?.filter((item) => item.checked).length < 3) {
              setError(true);
              return;
            }
            setError(false);

            handleSubmit(e);
          }}
          >
            <div>
              <InputField
                label="Formação Profissional"
                InputHTMLAttributes={{
                  id: 'formacao_profissional',
                  name: 'formacao_profissional',
                  placeholder: 'Insira a formação profissional',
                }}
              />
              <div>
                {/* <Label style={{ fontWeight: 'bold' }}>Experiências Profissionais (citar as 3 principais)</Label> */}
                <InputField
                  iconPlus
                  setFieldValue={setFieldValue}
                  data={experiencias_profissionais}
                  setData={setExperienciasProfissionais}
                  label="Experiências Profissionais"
                  InputHTMLAttributes={{
                    id: 'experiencias_profissional1',
                    name: 'experiencias_profissional1',
                    placeholder: 'Insira a experiencia profissional',
                  }}
                />

                {experiencias_profissionais?.map((experiencia: any, index: number) => (
                  <InputField
                    iconTrash
                    data={experiencias_profissionais}
                    setData={setExperienciasProfissionais}
                    label={`Experiência Profissional ${index + 1}`}
                    InputHTMLAttributes={{
                      id: experiencia,
                      name: `experiencias_profissional_${index + 1}`,
                      placeholder: 'Insira a experiência profissional',
                      value: experiencia,
                      disabled: true,
                    }}
                  />
                ))}

                {/* <InputField
                  InputHTMLAttributes={{
                    id: 'experiencias_profissional2',
                    name: 'experiencias_profissional2',
                    placeholder: 'Insira a experiencias profissional 2',
                  }}
                />
                <InputField
                  InputHTMLAttributes={{
                    id: 'experiencias_profissional3',
                    name: 'experiencias_profissional3',
                    placeholder: 'Insira a experiencias profissional 3',
                  }}
                /> */}
              </div>

              <InputField
                label="Habilidades e Competências"
                InputHTMLAttributes={{
                  type: "text",
                  id: 'habilidades_competencias',
                  name: 'habilidades_competencias',
                  placeholder: 'Insira suas habilidades e competências',
                }}
              />

              <div>
                {/* <Label style={{ fontWeight: 'bold' }}>Disponibilidade quanto ao Horário de Trabalho e Curso</Label> */}
                <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                  <div style={{ width: '50%', marginRight: 5 }}>
                    <div>
                      <InputField
                        label="Disponibilidade Quanto ao Trabalho"
                        InputHTMLAttributes={{
                          id: 'disponibilidade_horario_trabalho',
                          name: 'disponibilidade_horario_trabalho',
                          type: "select",
                          value: values.disponibilidade_horario_trabalho || '-',
                          style: { marginRight: 10 },
                        }}
                        LabelHTMLAttributes={{
                          className: 'mr-3',
                        }}
                        options={(
                          <>
                            <option value="-">
                              -
                            </option>
                            <option value="Matutino">
                              Matutino
                            </option>
                            <option value="Vespertino">
                              Vespertino
                            </option>
                            <option value="Tempo integral">
                              Tempo integral
                            </option>
                            <option value="Noturno">
                              Noturno
                            </option>
                          </>
                    )}
                      />
                      {/* <InputField
                        label="Trabalho - Matutino"
                        InputHTMLAttributes={{
                          type: "radio",
                          id: 'disponibilidade_horario_trabalho-1',
                          name: 'disponibilidade_horario_trabalho',
                          value: 'Matutino',
                          checked: values.disponibilidade_horario_trabalho === 'Matutino',
                          onClick: () => {
                            setFieldValue(
                              'disponibilidade_horario_trabalho',
                              values.disponibilidade_horario_trabalho === 'Matutino' ? undefined : "Matutino",
                            );
                          },
                        }}
                      />
                      <InputField
                        label="Trabalho - Vespertino"
                        InputHTMLAttributes={{
                          type: "radio",
                          id: 'disponibilidade_horario_trabalho-2',
                          name: 'disponibilidade_horario_trabalho',
                          value: 'Vespertino',
                          checked: values.disponibilidade_horario_trabalho === 'Vespertino',
                          onClick: () => setFieldValue(
                            'disponibilidade_horario_trabalho',
                            values.disponibilidade_horario_trabalho === 'Vespertino' ? undefined : "Vespertino",
                          ),
                        }}
                      />
                      <InputField
                        label="Trabalho - Tempo integral"
                        InputHTMLAttributes={{
                          type: "radio",
                          id: 'disponibilidade_horario_trabalho-3',
                          name: 'disponibilidade_horario_trabalho',
                          value: 'Tempo integral',
                          checked: values.disponibilidade_horario_trabalho === 'Tempo integral',
                          onClick: () => setFieldValue(
                            'disponibilidade_horario_trabalho',
                            values.disponibilidade_horario_trabalho === 'Tempo integral' ? undefined : "Tempo integral",
                          ),
                        }}
                      />
                      <InputField
                        label="Trabalho - Noturno"
                        InputHTMLAttributes={{
                          type: "radio",
                          id: 'disponibilidade_horario_trabalho-4',
                          name: 'disponibilidade_horario_trabalho',
                          value: 'Noturno',
                          checked: values.disponibilidade_horario_trabalho === 'Noturno',
                          onClick: () => setFieldValue(
                            'disponibilidade_horario_trabalho',
                            values.disponibilidade_horario_trabalho === 'Noturno' ? undefined : "Noturno",
                          ),
                        }}
                      /> */}
                    </div>
                  </div>
                  <div style={{ width: '50%' }}>
                    <div>
                      <InputField
                        label="Disponibilidade Quanto ao Curso"
                        InputHTMLAttributes={{
                          id: 'disponibilidade_horario_curso',
                          name: 'disponibilidade_horario_curso',
                          type: "select",
                          value: values.disponibilidade_horario_curso || '-',
                        }}
                        LabelHTMLAttributes={{
                          className: 'mr-3',
                        }}
                        options={(
                          <>
                            <option value="-">
                              -
                            </option>
                            <option value="Matutino">
                              Matutino
                            </option>
                            <option value="Vespertino">
                              Vespertino
                            </option>
                            <option value="Tempo integral">
                              Tempo integral
                            </option>
                            <option value="Noturno">
                              Noturno
                            </option>
                          </>
                    )}
                      />
                      {/* <InputField
                        label="Cursos - Matutino"
                        InputHTMLAttributes={{
                          type: "radio",
                          id: 'disponibilidade_horario_curso-1',
                          name: 'disponibilidade_horario_curso',
                          value: 'Matutino',
                          checked: values.disponibilidade_horario_curso === 'Matutino',
                          onClick: () => setFieldValue(
                            'disponibilidade_horario_curso',
                            values.disponibilidade_horario_curso === 'Matutino' ? undefined : "Matutino",
                          ),
                        }}
                      />
                      <InputField
                        label="Cursos - Vespertino"
                        InputHTMLAttributes={{
                          type: "radio",
                          id: 'disponibilidade_horario_curso-2',
                          name: 'disponibilidade_horario_curso',
                          value: 'Vespertino',
                          checked: values.disponibilidade_horario_curso === 'Vespertino',
                          onClick: () => setFieldValue(
                            'disponibilidade_horario_curso',
                            values.disponibilidade_horario_curso === 'Vespertino' ? undefined : "Vespertino",
                          ),
                        }}
                      />
                      <InputField
                        label="Cursos - Tempo integral"
                        InputHTMLAttributes={{
                          type: "radio",
                          id: 'disponibilidade_horario_curso-3',
                          name: 'disponibilidade_horario_curso',
                          value: 'Tempo integral',
                          checked: values.disponibilidade_horario_curso === 'Tempo integral',
                          onClick: () => setFieldValue(
                            'disponibilidade_horario_curso',
                            values.disponibilidade_horario_curso === 'Tempo integral' ? undefined : "Tempo integral",
                          ),
                        }}
                      />
                      <InputField
                        label="Cursos - Noturno"
                        InputHTMLAttributes={{
                          type: "radio",
                          id: 'disponibilidade_horario_curso-4',
                          name: 'disponibilidade_horario_curso',
                          value: 'Noturno',
                          checked: values.disponibilidade_horario_curso === 'Noturno',
                          onClick: () => setFieldValue(
                            'disponibilidade_horario_curso',
                            values.disponibilidade_horario_curso === 'Noturno' ? undefined : "Noturno",
                          ),
                        }}
                      /> */}
                    </div>
                  </div>
                </div>
              </div>

              <div style={{ marginBottom: 20 }}>
                <Label style={{ fontWeight: 'bold' }}>Cursos de Interesse (mínimo 3)</Label>
                <Select
                  closeMenuOnSelect={false}
                  name="curso_interesse"
                  options={cursos?.map((item: any) => ({ ...item, value: item.id, label: item.nome }))}
                  defaultValue={cursos?.filter((curso) => curso.checked)?.map((item: any) => ({ ...item, value: item.id, label: item.nome }))}
                  placeholder="Selecione"
                  onChange={(values) => {
                    const aux = values.map((value) => Number(value.value));
                    // @ts-ignore
                    setCursos(cursos?.map((curso) => (aux.includes(Number(curso.id)) ? ({ ...curso, checked: true }) : ({ ...curso, checked: false }))));
                  }}
                  noOptionsMessage={({ inputValue }) => "Sem opções"}
                  isMulti
                  theme={(theme) => ({
                    ...theme,
                    borderRadius: 5,
                    borderWidth: 5,
                    colors: {
                      ...theme.colors,
                      // primary25: "#080",
                      primary: "#cce5ff",
                    },
                  })}
                  styles={{
                    // @ts-ignore
                    control: (base, state) => ({
                      ...base,
                      // background: "#023950",
                      // Overwrittes the different states of border
                      borderColor: state.isFocused ? "#c2dbfe" : '#dee2e6',
                      borderWidth: state.isFocused ? 5 : 1,

                      // Removes weird border around container
                      boxShadow: state.isFocused ? null : null,
                      "&:hover": {
                        borderColor: state.isFocused ? "#c2dbfe" : '#dee2e6',
                      },
                      "&:focus": {
                        // Overwrittes the different states of border
                        borderColor: "#c2dbfe",
                      },
                    }),
                  }}
                />
                {error && (
                  <div className="error">
                    Selecione no mínimo 3 cursos
                  </div>
                )}

                {/* {cursos?.map((item) => (
                  <FormGroup check key={item.nome}>
                    <Input
                      type="checkbox"
                      checked={item.checked}
                      onChange={() => {
                        setCursos(cursos.map((curso) => (curso.id == item.id ? ({ ...curso, checked: !curso.checked }) : curso)));
                      }}
                    />
                    {' '}
                    <Label check>
                      {item.nome}
                    </Label>
                  </FormGroup>
                ))} */}
              </div>
            </div>

            <ModalFooter className="d-flex justify-content-between">
              <div>
                <Button type="button" size="lg" onClick={handleBackStep}>Voltar</Button>
              </div>
              <div>
                <Button style={{ borderColor: '#1B7E9F', background: '#1B7E9F' }} type="submit" color="primary" size="lg">{isCreate ? 'Avançar' : 'Salvar'}</Button>
              </div>
            </ModalFooter>
          </Form>
        );
      }}
    </Formik>
  );
}
