import React, { useEffect, useState } from 'react';
import api from '../../../../services/api';

function useProjetosSearch() {
  const [projetosSearch, setProjetosSearch] = useState<any | null>(null);

  async function handleProjetosSearch() {
    const { data } = await api.get('/projects');
    setProjetosSearch(data);
  }

  useEffect(() => {
    handleProjetosSearch();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return {
    projetosSearch, handleProjetosSearch,
  };
}

export default useProjetosSearch;
