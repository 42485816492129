import styled from "styled-components";

export const CardItemContainer = styled.div`
cursor: pointer;
  height: 190px;
  width: 250px;
  background: #E2E1E5;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  .img-card{
    background: #FFFFFF;
    border-radius: 50%;
    padding: 20px;
    width: 120px;
    height: 120px;
    display: flex;
    justify-content: center;
    align-items: center;
    /* margin-left:50px; */
    margin-top: 20px;

  }

  .link-card{
    margin-bottom: 10px;
  }

  a {
    color: #306099;
    font-weight:700;
    font-size:22px;
    text-decoration:none;
  }

  @media (max-width: 768px) {
    height: 150px;
    width: 210px;
    .img-card {
      width: 100px;
      height: 100px;
    }
  }
`;
