import React from 'react';
import { SectionHeader } from '../../../components/SectionHeader';
import { EgressoRelatorio } from './components/EgressoRelatorio';
import { ProjetoRelatorio } from './components/ProjetoRelatorio';
import { TurmaRelatorio } from './components/TurmaRelatorio';

export function RelatorioPedagogo() {
  return (
    <div style={{ width: "100%" }}>
      <SectionHeader subtitle="Relatórios" />

      <EgressoRelatorio />

      <TurmaRelatorio />

      <ProjetoRelatorio />
    </div>
  );
}
