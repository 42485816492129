import React from 'react';
import { useForm } from 'react-hook-form';
import { useHistory, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { SectionHeader } from '../../../../components/SectionHeader';
import api from '../../../../services/api';

export function PageAvaliacao() {
  const arrayNumber = Object.keys(new Array(11).fill(null));

  window.scrollTo({ top: 0 });

  const arrayValues = [
    {
      label: "Avaliação do professor do curso",
      note_for_approval: "(Aprovado à partir de 7)",
      name: "avaliacao_professor_curso",
    },
    {
      label: "Aspectos cognitivos",
      note_for_approval: "(Bom à partir de 7)",
      name: "aspectos_cognitivos",
    },
    {
      label: "Socialização",
      note_for_approval: "(Bom à partir de 7)",
      name: "socializacao",
    },
    {
      label: "Assiduidade / Pontualidade",
      note_for_approval: "(Aprovado à partir de 7)",
      name: "assiduidade_pontualidade",
    },
    {
      label: "Aproveitamento prático",
      note_for_approval: "(Bom à partir de 7)",
      name: "aproveitamentos_pratico",
    },
  ];

  const history = useHistory();
  const { register, handleSubmit } = useForm();

  const onSubmit = handleSubmit(async (data) => {
    try {
      await api.put(`/courses_egresses/${state.id}`, data);
      toast.success("Avaliação realizada");
      history.push('/analisar-egresso');
    } catch (error) {
      toast.error("Erro ao realizar a avaliação");
    }
  }, () => {
    toast.error("Preencha todos os itens");
  });

  const { state } = useLocation<any>();

  return (
    <div className="w-100">
      <SectionHeader subtitle="Analisar egresso" backPage="/analisar-egresso" />

      <h4 className="text-primary mb-3">
        Nome do curso:
        {' '}
        {state.course_title}
      </h4>

      <form onSubmit={onSubmit}>
        {arrayValues.map((inputValue) => (
          <div className="w-100 mb-5">
            <div className="d-flex align-items-center justify-content-between mb-1 w-75">
              <div className="mr-5">
                <h5 className="d-flex">
                  {inputValue.label}
                </h5>
                <h5 className="text-secondary">{inputValue.note_for_approval}</h5>
              </div>
              <div className="d-flex align-items-center justify-content-between w-50">
                <p className="m-0">Ruim</p>
                {arrayNumber.map((number) => (
                  <div className="d-flex flex-column align-items-center justify-content-between">
                    <label htmlFor={`${inputValue.name}_${number}`} style={{ cursor: "pointer" }}>{number}</label>
                    <input
                      type="radio"
                      id={`${inputValue.name}_${number}`}
                      value={number}
                      {...register(inputValue.name, { required: true })}
                    />
                  </div>
                ))}
                <p className="m-0">Excelente</p>
              </div>
            </div>
            {inputValue.name !== "avaliacao_professor_curso" && (
            <div className="mb-5 d-flex flex-column">
              <label className="h6">Relatório:</label>
              <textarea {...register(`${inputValue.name}_text`, { required: true })} className="w-75" placeholder="Digite..." />
            </div>
            )}
          </div>
        ))}

        <div className="mb-5 d-flex flex-column">
          <label className="h6">Outras observações:</label>
          <textarea name="outras_observacoes" className="w-75" placeholder="Digite..." />
        </div>
        <div className="d-flex justify-content-center w-100 mb-5">
          <button className="btn btn-primary btn-lg">Finalizar</button>
        </div>
      </form>

    </div>
  );
}
