import React from 'react';
import { format } from 'date-fns';
import ReactModal from 'react-modal';
import { AiOutlineCloseCircle } from 'react-icons/ai';
import { TablePadrão, Td, Tr } from './TablePadrão';
import { SectionHeader } from '../SectionHeader';

type PropsOcorrenciasTable = {
  egresse: any
  isOpen: boolean
  handleIsOpen(): void
}

const styleModal = {
  content: {
    width: "50%",
    margin: "auto auto",
  },
  overlay: {
    background: "rgba(1,1,1,.1)",
    overflow: "hidden",
  },
};

export function ModalRound({ egresse, isOpen, handleIsOpen }: PropsOcorrenciasTable) {
  return (
    <ReactModal
      isOpen={isOpen}
      ariaHideApp={false}
      shouldCloseOnOverlayClick
      onRequestClose={handleIsOpen}
      style={styleModal}
    >
      <SectionHeader
        subtitle="Fiscalizações"
        isIconClose={(
          <div style={{ cursor: "pointer" }} onClick={handleIsOpen}>
            <AiOutlineCloseCircle size={25} color="rgba(44, 44, 44, 0.9)" />
          </div>
          )}
      />

      <TablePadrão headWidth={["40%", "40%", "20%"]} thead={[]}>
        {egresse?.rondas?.rondasTodas.map((item: any) => (
          <>
            {item?.ronda && item?.ocorrencia && (
            <Tr key={item.id}>
              <Td style={{ justifyContent: 'flex-start' }} width="40%">{item?.ronda?.title}</Td>
              <Td style={{ justifyContent: 'flex-start' }} width="40%">{item?.ronda?.responsavel?.name}</Td>
              <Td style={{ justifyContent: 'flex-start' }} width="20%">{item?.data_ocorrencia ? format(new Date(item?.data_ocorrencia), "dd/MM/yyyy") : 'DD/MM/YYYY'}</Td>
            </Tr>
            )}
          </>
        ))}
      </TablePadrão>
    </ReactModal>
  );
}
