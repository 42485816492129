import { format } from 'date-fns';
import React from 'react';
import styled from 'styled-components';
import { SpinnerLoading } from '../../../../components/SpinnerLoading';
import { useGetRelatorioPsicopedagogo } from '../hooks/useGetRelatorioPsicopedagogo';

export function FatoresInt() {
  const {
    handleDownload,
    load,
    trimestral_options,
    year_options,
    type_relatorio,
    month_options,
    set_trimestre,
    set_typeRelatorio, set_year,
    set_month,
  } = useGetRelatorioPsicopedagogo();
  return (
    <Wrapper>
      <h2>Relatórios</h2>
      <div>
        <h5>Tipo de relatório</h5>
        <Select className="form-control" onChange={(e) => set_typeRelatorio(e.target.value)}>
          <option value="">Selecione o item</option>
          {type_relatorio.map((type, i) => (
            <option key={i} value={type.value}>{type.label}</option>
          ))}
        </Select>
        <P>Relatórios fatores intelectuais, fatores emocional, fatores fisico, queixa ou observção.</P>
      </div>
      <div>
        <h5>Relatório do trimestre</h5>
        <Select className="form-control" onChange={(e) => set_trimestre(e.target.value)}>
          <option value="">Selecione o item</option>
          {trimestral_options.map((trimestre, i) => (
            <option key={i} value={trimestre.value}>{trimestre.label}</option>
          ))}
        </Select>
        <P>Caso fique em branco será gerado do trimestre atual.</P>
      </div>
      <div>
        <h5>Relatório do mês</h5>
        <Select className="form-control" onChange={(e) => set_month(e.target.value)}>
          <option value="">Selecione o item</option>
          {month_options.map((month, i) => (
            <option key={i} value={month.value}>{month.label}</option>
          ))}
        </Select>
        <P>Caso queira um relatório, mensal deixe o campo trimestre vazio e escolha o mês.</P>
      </div>
      <div>
        <h5>Relatório do ano</h5>
        <Select className="form-control" onChange={(e) => set_year(e.target.value)}>
          <option value={format(new Date(), "yyyy")}>Selecione o item</option>
          {year_options.map((year, i) => (
            <option key={i} value={year}>{year}</option>
          ))}
        </Select>
        <P>Caso fique em branco será gerado um relatório do ano atual.</P>
      </div>
      <WrapperButton>
        {load ? (
          <button className="btn btn-success btn-lg" data-testid="load">
            <SpinnerLoading />
          </button>
        ) : (
          <button className="btn btn-success btn-lg" onClick={handleDownload}>Gerar relatório</button>
        )}
      </WrapperButton>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 10px;
  background: rgb(239, 239, 239);
  padding: 20px;
  width: 100%;
  border-radius: 10px;
`;

const P = styled.p`
color: #595959;
`;

const Select = styled.select`
  height: 59px;
  width: 335px;
  border-radius: 4px;

  font-size: 18px;
  font-weight: 400;
`;

const WrapperButton = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 20px;
`;
