import React, { useState } from 'react';
import { FieldArrayWithId, UseFieldArrayAppend } from 'react-hook-form';
import { BsFillPlusCircleFill } from 'react-icons/bs';
import styled from 'styled-components';
import api from '../../../../services/api';

export function MaterialField({
  append, options, disabled, fields,
}: any) {
  const [focus, setFocus] = useState(false);
  const [inputValue, setInputValue] = useState("");

  console.log(fields);

  const optionsFilter = options.filter((item: any) => {
    const alreadyExist = fields.map((item: any) => item.material.title);

    if (alreadyExist.includes(item.title)) {
      return false;
    }

    if (item.title.toLowerCase().includes(inputValue.toLowerCase())) {
      return true;
    }

    return false;
  });

  function addOptionMaterial() {
    if (optionsFilter.length === 0 && inputValue !== "") {
      api.post('materials', { title: inputValue })
        .then(({ data }) => {
          append({ material: { id: data.id, title: data.title } });
          // setOptionsMaterial((prev) => [...prev, { id: data.id, title: data.title }]);
        });
    }
  }

  return (
    <div style={{ display: "flex", gap: "20px" }}>
      <WrapperInputAndButton>
        <div style={{ position: "relative" }}>
          <input
            autoComplete="off"
            type="text"
            className="form-control form-control-lg"
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
            disabled={disabled}
            onFocus={() => setFocus(true)}
            onBlur={() => {
              setTimeout(() => {
                setFocus(false);
              }, 150);
            }}
            placeholder="Digite o material..."
          />

          <ContainerOptions
            className="ListMaterial"
            style={{
              display: !focus ? "none" : "block", maxHeight: "200px", border: "solid 1px black", borderRadius: "5px",
            }}
          >
            {optionsFilter.map((item: any, i: any) => (
              <Option
                key={i}
                onClick={() => {
                  setInputValue("");
                  append({ material: { id: item.id, title: item.title }, qtd: 1 });
                }}
              >
                {item.title}
              </Option>
            ))}
          </ContainerOptions>
          <p>Escolha entre os cadastrados disponíveis, ou comece a digitar e clique no botão adicionar</p>
        </div>
      </WrapperInputAndButton>
      <BsFillPlusCircleFill
        color="rgba(15, 169, 88, 1)"
        size={48}
        onClick={addOptionMaterial}
        style={{ cursor: "pointer" }}
      />
    </div>
  );
}

const WrapperInputAndButton = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`;

const ContainerOptions = styled.div`
  width: 100%;
  max-height: 250px;
  border: gray solid 1px;
  position: absolute;
  background-color: white;
  overflow: auto;
`;

const Option = styled.p`
  font-size: 18px;
  padding: 10px;
  cursor: pointer;
  :hover {
    background-color: rgb(130, 200, 255);
  }
`;
