import React, {
  createContext, useCallback, useState, useContext, useMemo,
} from 'react';

import { ToastContainer, toast } from 'react-toastify';
import {
  admin, assistente_social, convidado, fiscal, master, pedagogo, psicologo, psico_pedagogo,
} from '../components/CardItem/Funcionalities';

import api from '../services/api';

interface User {
  id: number;
  name: string;
  cpf: string;
  profile: {
    title: 'Administrador' | 'Fiscal' | 'Pedagogo' | 'Convidado' | 'Psicólogo' | "Psico-pedagogo" | "Assistente Social" | 'MASTER'
  }
}

interface AuthState {
  token: string;
  user: User;
}

interface SignInCredentials{
  cpf: string;
  password: string;
}

interface AuthContextData {
  user: User;
  loading: boolean;
  signIn(credentias: SignInCredentials): Promise<void>;
  signOut(): void;
  signed: boolean;
  profile?: {
    title: string;
    functions: {
        title: string;
        url: string;
        pathPainel: string;
    }[];
  }
  setProfile?: (user: any) => void;
  isInEgresse: boolean;
  setIsInEgress(value: boolean): void;
}

const AuthContext = createContext<AuthContextData>({} as AuthContextData);

export const AuthProvider: React.FC = ({ children }) => {
  const [isInEgresse, setIsInEgress] = useState(false);

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<AuthState>(() => {
    const token = localStorage.getItem('@albergado:token');
    const user = localStorage.getItem('@albergado:user');
    const profile = localStorage.getItem('@albergado:profile');

    if (token && user) {
      return { token, user: JSON.parse(user), profile: profile ? JSON.parse(profile) : null };
    }

    return {} as AuthState;
  });

  const signed = useMemo(
    () => !!data.token && !!data.user,
    [data],
  );

  const signIn = useCallback(async ({ cpf, password }) => {
    try {
      setLoading(true);

      const response = await api.post(`login`, {
        cpf,
        password,
      });

      const { auth, user } = response.data;

      // if (process.env.REACT_APP_PERMISSION === 'false') {
      //   if (user.profile.title !== 'Administrador' && user.profile.title !== 'Fiscal' && user.profile.title !== 'MASTER') {
      //     toast("Você não está autorizado a se autenticar", {
      //       type: 'error',
      //     });

      //     setLoading(false);

      //     return null;
      //   }
      // }

      localStorage.setItem('@albergado:token', auth.token);
      localStorage.setItem('@albergado:user', JSON.stringify(user));

      // @ts-ignore
      api.defaults.headers.authorization = `Bearer ${auth.token}`;

      setData({ token: auth.token, user });

      if (user.profile.id === 9) {
        window.location.href = '/';
      } else {
        setProfile(user.profile);
        window.location.href = '/dashboard';
      }
    } catch (error) {
      setLoading(false);

      localStorage.removeItem('@albergado:token');
      localStorage.removeItem('@albergado:user');

      const message = 'Ocorreu um erro ao fazer login, verifique suas credenciais!';

      toast(message, {
        type: 'error',
      });
    }
  }, []);

  const signOut = useCallback(() => {
    localStorage.removeItem('@albergado:token');
    localStorage.removeItem('@albergado:user');
    localStorage.removeItem('@albergado:profile');

    setData({} as AuthState);
  }, []);

  const saveProfileLocal = async (profile: any) => {
    localStorage.setItem('@albergado:profile', JSON.stringify(profile));
  };

  const setProfile = useCallback(async (profile: any) => {
    await saveProfileLocal(profile);
    window.location.href = '/';
    setTimeout(() => {
      setData((prev) => ({ ...prev, profile }));
    }, 1000);
  }, []);

  return (
    <AuthContext.Provider value={{
      // @ts-ignore
      isInEgresse, setIsInEgress, user: data.user, loading, signed, signIn, signOut, setProfile, profile: JSON.parse(localStorage.getItem('@albergado:profile')),
    }}
    >
      <>
        <ToastContainer />
        {children}
      </>
    </AuthContext.Provider>
  );
};

export function useAuth(): AuthContextData {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('useAuth must be used within an authProvider');
  }

  return context;
}

export function usePermission() {
  const { user } = useAuth();
  if (user?.profile.title === 'Administrador') {
    return admin;
  }
  if (user?.profile.title === 'Fiscal') {
    return fiscal;
  }
  if (user?.profile.title === "Convidado") {
    return convidado;
  }
  if (user?.profile.title === 'Pedagogo') {
    return pedagogo;
  }
  if (user?.profile.title === 'Psicólogo') {
    return psicologo;
  }

  if (user?.profile.title === "Psico-pedagogo") {
    return psico_pedagogo;
  }
  if (user?.profile.title === "Assistente Social") {
    return assistente_social;
  }
  // if (process.env.REACT_APP_PERMISSION === 'false') {
  //   return { ...master, perfis: master.perfis.filter((perfil) => perfil.title === "Administrador" || perfil.title === "Fiscal") };
  // }
  return master;
}
