import {
  ErrorMessage, useField, Form, Field,
} from 'formik';
import React from 'react';
import { AiFillPlusCircle } from 'react-icons/ai';
import { BsFillTrashFill } from 'react-icons/bs';
import { MdOutlineContentCopy } from 'react-icons/md';
import { toast } from 'react-toastify';
import {
  FormGroup, Input, InputGroup, InputGroupText, InputProps, Label, LabelProps,
} from 'reactstrap';

type InputFieldProps = {
  label?:string;
  iconPlus?:boolean;
  iconTrash?:boolean;
  InputHTMLAttributes: React.InputHTMLAttributes<HTMLInputElement>
  options?: React.ReactNode
  LabelHTMLAttributes?: React.DetailedHTMLProps<React.LabelHTMLAttributes<HTMLLabelElement>, HTMLLabelElement>
  data?: any
  setData?: (value: any) => void
  setFieldValue?: (key: string, value: string) => void;
}

export function InputField(inputProps: InputFieldProps) {
  const {
    iconPlus, setFieldValue, iconTrash, data, setData, label, InputHTMLAttributes, options, LabelHTMLAttributes,
  } = inputProps;
  const [field] = useField({ ...InputHTMLAttributes } as any);

  return (
    <FormGroup
      style={InputHTMLAttributes.style}
      check={(InputHTMLAttributes?.type === "radio" || InputHTMLAttributes?.type === "checkbox") && true}
      disabled={InputHTMLAttributes.disabled}
    >
      <Label
        {...LabelHTMLAttributes as React.Component<LabelProps>}
        for={InputHTMLAttributes?.id}
        style={LabelHTMLAttributes?.style === undefined ? { fontWeight: 'bold', display: (InputHTMLAttributes?.type === "radio" || InputHTMLAttributes?.type === "checkbox") ? 'none' : '' } : LabelHTMLAttributes.style}
      >
        {label}
      </Label>
      <Field
        name={InputHTMLAttributes?.name}
        disabled={InputHTMLAttributes?.disabled}
      >
        {(props:any) => {
          const { field } = props;
          return (
            <div>
              {(!iconTrash && !iconPlus) && (
                <Input
                  className="icon"
                  {...field}
                  {...InputHTMLAttributes as React.Component<InputProps>}
                >
                  {options}
                </Input>
              )}

              {iconTrash && (
                <InputGroup>
                  <Input
                    className="icon"
                    {...field}
                    {...InputHTMLAttributes as React.Component<InputProps>}
                  >
                    {options}
                  </Input>
                  <InputGroupText>
                    <MdOutlineContentCopy
                      color="rgba(44, 44, 44, 0.6)"
                      size={20}
                      onClick={() => {
                        window.navigator.clipboard.writeText(String(InputHTMLAttributes.value));
                      }}
                      style={{ cursor: 'pointer', marginRight: 10 }}
                    />
                    <BsFillTrashFill
                      onClick={() => {
                        const ind = data.indexOf(String(InputHTMLAttributes.value));
                        // @ts-ignore
                        setData(data.filter((item, index) => Number(index) != Number(ind)));
                      }}
                      style={{ cursor: 'pointer' }}
                      color="rgba(44, 44, 44, 0.6)"
                      size={20}
                    />
                  </InputGroupText>
                </InputGroup>
              )}
              {iconPlus && (
                <InputGroup>
                  <Input
                    className="icon"
                    {...field}
                    {...InputHTMLAttributes as React.Component<InputProps>}
                  >
                    {options}
                  </Input>
                  <InputGroupText style={{ background: "#fff" }}>
                    <AiFillPlusCircle
                      onClick={() => {
                        console.log(props.form.values);
                        console.log(InputHTMLAttributes);
                        console.log(props.form.values[`${InputHTMLAttributes?.name}`]);
                        if (props.form.values[`${InputHTMLAttributes?.name}`] !== "" && props.form.values[`${InputHTMLAttributes?.name}`] !== null) {
                          // @ts-ignore
                          setData([...data, props.form.values[`${InputHTMLAttributes?.name}`]]);
                          // @ts-ignore
                          setFieldValue(`${InputHTMLAttributes?.name}`, '');
                        }
                      }}
                      style={{ cursor: 'pointer' }}
                      color="rgba(44, 44, 44, 0.6)"
                      size={20}
                    />
                  </InputGroupText>
                </InputGroup>
              )}
            </div>
          );
        }}
      </Field>
      <Label
        {...LabelHTMLAttributes as React.Component<LabelProps>}
        for={InputHTMLAttributes.id}
        style={{ display: (InputHTMLAttributes?.type === "radio" || InputHTMLAttributes?.type === "checkbox") ? '' : 'none' }}
        check={(InputHTMLAttributes?.type === "radio" || InputHTMLAttributes?.type === "checkbox") && true}
      >
        {label}
      </Label>
      <ErrorMessage component="div" className="error" name={field?.name} />
    </FormGroup>
  );
}
