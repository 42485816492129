import React, { useState } from 'react';

import {
  Nav, NavItem, NavLink, TabContent, TabPane, Row, Col,
} from 'reactstrap';

import { Container } from './styles';

import Chart from '../components/Chart';

import Period from '../components/Modal/Period';
import { Fiscalizações } from './components/Fiscalizações';
import { SectionHeader } from '../../../components/SectionHeader';

export function IndicadoresFiscal() {
  const [tab, setTab] = useState('1');
  const [modalIsOpen, setIsOpen] = useState(false);
  const [reportIsOpen, setReportIsOpen] = useState(false);

  function closeModal() {
    setIsOpen(false);
  }

  function closeAndOpenReport() {
    setReportIsOpen(!reportIsOpen);
  }

  return (
    <Container>
      {/* <Header route="indicadores" name="Indicadores - Dashboards" /> */}
      <SectionHeader subtitle="Indicadores" />

      {/* graficos */}
      <div style={{ display: !reportIsOpen ? "" : "none" }} className="graphics">
        <Nav tabs>
          <NavItem>
            <NavLink
              style={{ cursor: 'pointer' }}
              className={tab === '1' ? 'active' : ''}
              onClick={() => setTab("1")}
            >
              Mensal
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              style={{ cursor: 'pointer' }}
              className={tab === '2' ? 'active' : ''}
              onClick={() => setTab("2")}
            >
              Trimestral
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent activeTab={tab}>
          <TabPane tabId="1">
            <Row>
              <Col sm="10">
                <Chart tab={tab === '1' ? "mensal" : "trimestral"} report={closeAndOpenReport} />
              </Col>
            </Row>
          </TabPane>

          <TabPane tabId="2">
            <Row>
              <Col sm="10">
                <Chart tab={tab === '1' ? "mensal" : "trimestral"} report={closeAndOpenReport} />
              </Col>
            </Row>
          </TabPane>
        </TabContent>
      </div>

      {/* <div>
        <div style={{ display: 'flex', marginTop: 50 }}>
          <h1 style={{ fontSize: 26 }}>Fiscalizações</h1>
          <h1 style={{
            fontSize: 26, marginLeft: 10, marginRight: 10, color: '#f00',
          }}
          >
            com problemas
          </h1>
          <h1 style={{ fontSize: 26 }}>do mês atual</h1>
          <h1
            style={{
              fontSize: 26, marginLeft: 10, color: '#007BFF', cursor: 'pointer', fontWeight: 'normal',
            }}
            onClick={() => setIsOpen(true)}
          >
            (ver outro período)
          </h1>
        </div>

        <div style={{
          width: '100%',
          borderStyle: 'solid',
          borderWidth: 0.5,
          borderColor: '#595959',
          marginBottom: 20,
        }}
        />

        <div style={{ width: '100%', marginBottom: 20 }}>
          <table style={{
            width: '100%', borderCollapse: 'collapse', borderStyle: 'solid', borderColor: 'black', borderWidth: 1,
          }}
          >
            <tr>
              <th style={{
                borderStyle: 'solid', textAlign: 'center', borderColor: 'black', borderWidth: 1,
              }}
              >
                Egresso
              </th>
              <th style={{
                borderStyle: 'solid', textAlign: 'center', borderColor: 'black', borderWidth: 1,
              }}
              >
                Data da ocorrência
              </th>
              <th style={{
                borderStyle: 'solid', textAlign: 'center', borderColor: 'black', borderWidth: 1,
              }}
              >
                Ocorrência
              </th>
            </tr>
            <tr>
              <td style={{
                borderStyle: 'solid', borderColor: 'black', borderWidth: 1, textAlign: 'center',
              }}
              >
                John
              </td>
              <td style={{
                borderStyle: 'solid', borderColor: 'black', borderWidth: 1, textAlign: 'center',
              }}
              >
                11/11/2021
              </td>
              <td style={{
                borderStyle: 'solid', borderColor: 'black', borderWidth: 1, textAlign: 'center',
              }}
              >
                Fora de casa
              </td>
            </tr>
            <tr>
              <td style={{
                borderStyle: 'solid', borderColor: 'black', borderWidth: 1, textAlign: 'center',
              }}
              >
                Mary
              </td>
              <td style={{
                borderStyle: 'solid', borderColor: 'black', borderWidth: 1, textAlign: 'center',
              }}
              >
                12/12/2021
              </td>
              <td style={{
                borderStyle: 'solid', borderColor: 'black', borderWidth: 1, textAlign: 'center',
              }}
              >
                Endereço inexistente
              </td>
            </tr>
            <tr>
              <td style={{
                borderStyle: 'solid', borderColor: 'black', borderWidth: 1, textAlign: 'center',
              }}
              >
                July
              </td>
              <td style={{
                borderStyle: 'solid', borderColor: 'black', borderWidth: 1, textAlign: 'center',
              }}
              >
                09/09/2021
              </td>
              <td style={{
                borderStyle: 'solid', borderColor: 'black', borderWidth: 1, textAlign: 'center',
              }}
              >
                Preso por outro crime
              </td>
            </tr>
          </table>
        </div>

        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <div
            style={{
              display: "flex", width: '20%', justifyContent: "center", cursor: 'pointer', backgroundColor: "#EDEDEC", padding: 10, borderRadius: 5,
            }}
          >
            <h1 style={{ color: '#000', fontSize: 14, marginTop: 10 }}>VOLTAR</h1>
          </div>

          <div
            style={{
              display: "flex", width: '20%', marginLeft: 10, justifyContent: "center", cursor: 'pointer', backgroundColor: "#FFAC0C", padding: 10, borderRadius: 5,
            }}
          >
            <h1 style={{ color: '#fff', fontSize: 14, marginTop: 10 }}>IMPRIMIR</h1>
          </div>
        </div>
      </div> */}

      <Period modalIsOpen={modalIsOpen} closeModal={closeModal} />

      <div style={{ display: reportIsOpen ? "" : "none" }}>
        <Fiscalizações back={closeAndOpenReport} />
      </div>
    </Container>
  );
}
