import React, { useRef, useState } from 'react';
import { format } from 'date-fns';
import { Chart } from 'react-chartjs-2';
import { IoIosDocument } from 'react-icons/io';
import ReactModal from 'react-modal';
import ReactTooltip from 'react-tooltip';
import { useReactToPrint } from 'react-to-print';
import { useHistory } from 'react-router-dom';
import { Td, Tr } from '../../../../components/develop-nilton-components/TablePadrão';
import { ITbodyGraficos } from '../types';

export function TbodyPageHistorico({
  egresso,
  headWidth,
  acompanhamento_concluidos,
  index,
}:ITbodyGraficos) {
  const history = useHistory();
  function handleRedirectIndicadores() {
    history.push({ pathname: "/perfil/indicadores", state: { egresso, acompanhamento_concluidos } });
  }

  return (
    <Tr>
      <Td width={headWidth[0]}>{index}</Td>
      <Td width={headWidth[1]}>{acompanhamento_concluidos.psicologo?.name}</Td>
      <Td width={headWidth[2]}>
        {format(new Date(acompanhamento_concluidos?.data_finalizacao), "dd/MM/yyyy")}
      </Td>
      <Td width={headWidth[3]}>
        <div onClick={handleRedirectIndicadores} data-tip="Realizar acompanhamento">
          <IoIosDocument
            cursor="pointer"
            size={30}
          />
          <ReactTooltip />
        </div>
      </Td>

    </Tr>
  );
}
