import React, { useEffect, useState } from 'react';
import { IOnSubmitResponse } from '../../../../components/SubmitResponse/type';
import api from '../../../../services/api';

export function useGetProjetosPendentes<T>() {
  const [projetosPendentes, setProjetosPendentes] = useState< T | null>(null);
  const [loadPendente, setLoad] = useState(false);

  async function handleProjetosPendentes(page = 1, search?: string) {
    setLoad(true);
    const { data } = await api.get('/projects', {
      params: {
        page,
        homologado: "null",
        nome: search,
      },
    });

    if (data.length === 0 && page > 1) {
      handleProjetosPendentes(page - 1);
    } else {
      setProjetosPendentes(data);
      setLoad(false);
    }
  }

  useEffect(() => {
    handleProjetosPendentes();
  }, []);

  return {
    projetosPendentes,
    handleProjetosPendentes,
    loadPendente,
  };
}
