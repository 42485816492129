import React, { useState } from 'react';
import { format } from 'date-fns';
import { IoIosDocument } from 'react-icons/io';
import ReactTooltip from 'react-tooltip';
import { useHistory } from 'react-router-dom';
import { Td, Tr } from '../../../../components/develop-nilton-components/TablePadrão';
import { ITbodyPageHistorico } from '../type';
import api from '../../../../services/api';

export function TbodyPageHistorico({
  egresso,
  headWidth,
  acompanhamento_concluidos,
  index,
}:ITbodyPageHistorico) {
  const history = useHistory();
  const [download, setDownload] = useState<any>(null);
  async function handleRedirectIndicadores() {
    const { data } = await api.get('/reports/relatorio_social_individual', {
      params: {
        acompanhamento_id: acompanhamento_concluidos.id,
      },
    });
    window.open(data.url, "_blank");
    setDownload(data);
  }

  console.log(download);

  return (
    <Tr>
      <Td width={headWidth[0]}>{index}</Td>
      <Td width={headWidth[1]}>{acompanhamento_concluidos.assistente_social?.name}</Td>
      <Td width={headWidth[2]}>
        {format(new Date(acompanhamento_concluidos?.data_finalizacao), "dd/MM/yyyy")}
      </Td>
      <Td width={headWidth[3]}>
        <a onClick={handleRedirectIndicadores} data-tip="Visualizar">
          <IoIosDocument
            cursor="pointer"
            size={30}
          />
          <ReactTooltip />
        </a>
      </Td>

    </Tr>
  );
}
