import React, { useEffect, useState } from 'react';
import { ImLocation } from 'react-icons/im';
import moment from 'moment';
import styled from 'styled-components';
import { MdDashboard, MdEqualizer } from 'react-icons/md';
import { BiArrowBack } from 'react-icons/bi';
import { IoInformationCircleSharp } from 'react-icons/io5';
import { AiOutlineMenu } from 'react-icons/ai';
import { Table } from 'reactstrap';
import { SectionHeader } from '../../../../components/SectionHeader';
import { IEgresses } from '../../../../types/IEgresses';

import Form1 from './Form1';
import Form2 from './Form2';
import Form3 from './Form3';
import api from '../../../../services/api';

type EgressesFormModal = {
  useStateEgresse: [IEgresses | null, (egresse: IEgresses | null) => void];
  useStateIsOpen: [boolean, (boolean: boolean) => void];
};

export function Detail({ useStateEgresse, useStateIsOpen }: EgressesFormModal) {
  const [avatar, setAvatar] = useState(null);
  const [egresse, setEgresse] = useStateEgresse;

  const [isWatchEgresse, setIsWatchEgresse] = useStateIsOpen;

  const [tab, setTab] = useState(1);

  async function getDataUser(egresse: any){
    api
      .get(`/egresses/${egresse.id}`)
      .then(({ data }: any) => {
        setEgresse(data)
      });
  }

  useEffect(() => {
    console.log(egresse)
    getDataUser(egresse)
  },[])

  return (
    <div className="w-100">
      <SectionHeader
        subtitle="Perfil do Egresso"
        isIconBack={
          <div
            onClick={() => setIsWatchEgresse(false)}
            style={{ cursor: 'pointer', marginRight: 10 }}
          >
            <BiArrowBack size={30} />
          </div>
        }
      />
      <HeaderStyle>
        <HeaderStyle>
          <div
            style={{
              cursor: 'pointer',
              width: 150,
              height: 150,
              marginRight: 10,
            }}
          >
            <label
              className="label-title"
              htmlFor="img-input"
              style={{ cursor: 'pointer' }}
            >
              {avatar ? (
                <div>
                  <img
                    style={{ borderRadius: '50%' }}
                    id="preview"
                    src={URL.createObjectURL(avatar)}
                    width={150}
                    height={150}
                  />
                </div>
              ) : (
                <div>
                  <img
                    style={{ borderRadius: '50%' }}
                    id="preview"
                    src={
                      !avatar && egresse?.avatar
                        ? egresse?.avatar?.url_file
                        : 'https://static.vecteezy.com/ti/vetor-gratis/p1/1840618-imagem-perfil-icone-masculino-icone-humano-ou-pessoa-sinal-e-simbolo-gr%C3%A1tis-vetor.jpg'
                    }
                    width={150}
                    height={150}
                  />
                </div>
              )}
            </label>
            <input
              id="img-input"
              type="file"
              name="avatar"
              disabled
              className="d-none"
              onChange={e => {
                // @ts-ignore
                setAvatar(e.target.files[0]);
              }}
            />
          </div>

          <div>
            <h1 style={{ fontSize: 24 }}>{egresse?.nome}</h1>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <h1 style={{ fontSize: 14, fontWeight: 'bold' }}>
                Data de cadastro no sistema:
              </h1>
              <h2
                style={{
                  fontSize: 14,
                  marginLeft: 5,
                  fontWeight: 'normal',
                }}
              >
                {new Date(egresse?.created_at).toLocaleDateString('pt-br')}
              </h2>
            </div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <h1 style={{ fontSize: 14, fontWeight: 'bold' }}>Matrícula:</h1>
              <h2
                style={{
                  fontSize: 14,
                  marginLeft: 5,
                  fontWeight: 'normal',
                }}
              >
                {egresse?.numero_cadastro}
              </h2>
            </div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <h1 style={{ fontSize: 14, fontWeight: 'bold' }}>Cadastro:</h1>
              <h2
                style={{
                  fontSize: 14,
                  marginLeft: 5,
                  fontWeight: 'normal',
                }}
              >
                {egresse?.cadastro_calb}
              </h2>
            </div>
            {egresse?.endereco && Array.isArray(egresse?.endereco) &&
              egresse?.endereco?.filter((addr: any) => addr.atual)
              ?.map((add: any) => (
                <div
                  style={{
                    marginBottom: 10,
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <div>
                    <div style={{ display: 'flex' }}>
                      <ImLocation size={20} color="#E33E09" />
                      <h2
                        style={{
                          marginLeft: 5,
                          marginBottom: 0,
                          fontSize: 14,
                          fontWeight: 'normal',
                        }}
                      >
                        {add.endereco},{add.numero_endereco},{add.bairro},{' '}
                        {add.municipio} -{add.estado},{add.cep}
                        {add?.complemento && `, ${add?.complemento}`}
                      </h2>
                    </div>

                    <div>
                      <span
                        style={{
                          marginLeft: 5,
                          marginBottom: 0,
                          fontSize: 12,
                          fontWeight: 'bold',
                        }}
                      >
                        Endereço atualizado em:{' '}
                        {new Date(add.created_at).toLocaleDateString('pt-br')}
                      </span>
                    </div>
                  </div>
                </div>
              ))}
          </div>
          <div>
            <div
              style={{
                background: '#1B7E9F',
                marginBottom: 20,
                padding: 5,
                borderRadius: 20,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <h1 style={{ marginBottom: 0, fontSize: 16, color: '#fff' }}>
                {egresse?.tipo_regime || 'Não informado'}
              </h1>
            </div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <h1 style={{ fontSize: 14, fontWeight: 'bold' }}>Início:</h1>
              <h2 style={{ fontSize: 14, marginLeft: 5, fontWeight: 'normal' }}>
                {egresse?.data_entrada_sistema_penitenciario
                  ? moment(egresse?.data_entrada_sistema_penitenciario).format(
                      'DD/MM/YYYY',
                    )
                  : '-'}
              </h2>
            </div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <h1 style={{ fontSize: 14, fontWeight: 'bold' }}>Fim:</h1>
              <h2 style={{ fontSize: 14, marginLeft: 5, fontWeight: 'normal' }}>
                {egresse?.data_termino_pena
                  ? moment(egresse?.data_termino_pena).format('DD/MM/YYYY')
                  : '-'}
              </h2>
            </div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <h1 style={{ fontSize: 14, fontWeight: 'bold' }}>
                Alvará de Soltura:
              </h1>
              <h2 style={{ fontSize: 14, marginLeft: 5, fontWeight: 'normal' }}>
                {egresse?.data_alvara_soltura
                  ? moment(egresse?.data_alvara_soltura).format('DD/MM/YYYY')
                  : '-'}
              </h2>
            </div>
          </div>
        </HeaderStyle>
      </HeaderStyle>
      <div style={{ marginTop: 20, display: 'flex', alignItems: 'center' }}>
        <div
          onClick={() => setTab(1)}
          style={{
            display: 'flex',
            borderRadius: 5,
            height: 40,
            flexDirection: 'column',
            justifyContent: 'space-between',
            borderStyle: 'solid',
            width: 200,
            marginRight: 10,
            cursor: 'pointer',
            borderColor: '#ccc',
            borderWidth: 1,
          }}
        >
          <div
            style={{
              display: 'flex',
              padding: 10,
              justifyContent: 'center',
              alignItems: 'center',
              height: tab == 1 ? 35 : 40,
            }}
          >
            <MdDashboard size={20} />
            <h1 style={{ fontSize: 14, marginBottom: 0, marginLeft: 5 }}>
              Dashboard
            </h1>
          </div>
          {tab == 1 && (
            <div
              style={{
                position: 'relative',
                bottom: 0,
                borderEndStartRadius: 5,
                borderEndEndRadius: 5,
                background: 'rgba(0,62,93)',
                width: '100%',
                height: 5,
              }}
            />
          )}
        </div>

        <div
          onClick={() => setTab(2)}
          style={{
            display: 'flex',
            borderRadius: 5,
            height: 40,
            flexDirection: 'column',
            justifyContent: 'space-between',
            borderStyle: 'solid',
            width: 200,
            marginRight: 10,
            cursor: 'pointer',
            borderColor: '#ccc',
            borderWidth: 1,
          }}
        >
          <div
            style={{
              display: 'flex',
              padding: 10,
              justifyContent: 'center',
              alignItems: 'center',
              height: tab == 2 ? 35 : 40,
            }}
          >
            <MdEqualizer size={20} />
            <h1 style={{ fontSize: 14, marginBottom: 0, marginLeft: 5 }}>
              Multidisciplinar
            </h1>
          </div>
          {tab == 2 && (
            <div
              style={{
                position: 'relative',
                bottom: 0,
                borderEndStartRadius: 5,
                borderEndEndRadius: 5,
                background: 'rgba(0,62,93)',
                width: '100%',
                height: 5,
              }}
            />
          )}
        </div>

        <div
          onClick={() => setTab(3)}
          style={{
            display: 'flex',
            borderRadius: 5,
            height: 40,
            flexDirection: 'column',
            justifyContent: 'space-between',
            borderStyle: 'solid',
            width: 200,
            marginRight: 10,
            cursor: 'pointer',
            borderColor: '#ccc',
            borderWidth: 1,
          }}
        >
          <div
            style={{
              display: 'flex',
              padding: 10,
              justifyContent: 'center',
              alignItems: 'center',
              height: tab == 3 ? 35 : 40,
            }}
          >
            <IoInformationCircleSharp size={20} />
            <h1 style={{ fontSize: 14, marginBottom: 0, marginLeft: 5 }}>
              Dados
            </h1>
          </div>
          {tab == 3 && (
            <div
              style={{
                position: 'relative',
                bottom: 0,
                borderEndStartRadius: 5,
                borderEndEndRadius: 5,
                background: 'rgba(0,62,93)',
                width: '100%',
                height: 5,
              }}
            />
          )}
        </div>
      </div>

      {tab == 1 && <Form1 useStateEgresse={[egresse, setEgresse]} />}
      {tab == 2 && <Form2 useStateEgresse={[egresse, setEgresse]} />}
      {tab == 3 && <Form3 useStateEgresse={[egresse, setEgresse]} />}
    </div>
  );
}

const HeaderStyle = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  width: 100%;
  @media only screen and (max-width: 800px) {
    flex-direction: column;
    align-items: center;
  }
`;
