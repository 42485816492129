import React, { useState } from 'react';
import { Chart } from 'react-chartjs-2';
import { useHistory } from 'react-router-dom';
import { AbaFilterButtons, AbaFilterContent } from '../../../components/develop-nilton-components/AbaFiltro';
import { useGetSearchEgresses } from '../../../components/develop-nilton-components/hooks/useGetSearchEgresses';
import { IconDetails } from '../../../components/develop-nilton-components/IconDetails';
import { SearchEgresses } from '../../../components/develop-nilton-components/SearchEgresses';
import { TablePadrão, Tr, Td } from '../../../components/develop-nilton-components/TablePadrão';
import { Pagination } from '../../../components/Pagination';
import { SectionHeader } from '../../../components/SectionHeader';
import { SpinnerLoading } from '../../../components/SpinnerLoading';
import { useReportFisca } from './hooks/useReportFisca';

export function Fiscalizacao() {
  const [tab, setTab] = useState(1);
  const { report } = useReportFisca();

  const optionsChart = {
    plugins: { legend: { display: true, position: "bottom" } },
  };

  return (
    <div className="w-100">
      <SectionHeader subtitle="Indicadores de Fiscalização" />

      {/* <AbaFilterButtons buttons={["Anual", "Trimestral"]} useStateTab={[tab, setTab]} /> */}
      {report ? (
        <div data-testid="graficos">

          <h3 className="m-2 border-bottom">Quadro geral</h3>
          <div className=" d-flex justify-content-center">
            <div>
              {report && (
              // @ts-ignore
              <Chart data={report.licenses} type="pie" options={optionsChart} />
              )}
            </div>
          </div>

          <h3 className="m-2 border-bottom">Notificações do número do processo do mês atual</h3>
          <div className=" d-flex justify-content-center">
            <div>
              {report && (
              // @ts-ignore
              <Chart data={report.pads} type="pie" options={optionsChart} />
              )}
            </div>
          </div>

          <h3 className="m-2 border-bottom">Fiscalizações do mês atual</h3>
          <div className=" d-flex justify-content-center">
            <div>
              {report && (
              // @ts-ignore
              <Chart data={report.rounds} type="pie" options={optionsChart} />
              )}
            </div>
          </div>
        </div>
      ) : (
        <div className="d-flex justify-content-center">
          <SpinnerLoading />

        </div>
      )}

    </div>
  );
}
