import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { BiArrowBack } from 'react-icons/bi';
import { TablePadrão } from '../../../../components/develop-nilton-components/TablePadrão';
import { SectionHeader } from '../../../../components/SectionHeader';
import { TbodyPageEgressesInTheRounds } from './TbodyPageEgressesInTheRounds';

export function PageEgresses() {
  const headWidth = ["10%", "50%", "50%", "50%"];
  const { state } = useLocation<any>();

  const history = useHistory();

  return (
    <div style={{ width: "100%" }}>
      <SectionHeader
        subtitle={`${state.title}`}
        isIconBack={(
          <div onClick={() => history.push({ pathname: state.isDashboard ? '/dashboard' : '/homologar-ronda' })} style={{ cursor: "pointer", marginRight: 10 }}>
            <BiArrowBack size={30} />
          </div>
  )}
        // backPage="/homologar-ronda"
      />
      <TablePadrão thead={["#", "Nome", "Detalhes", "Comprovante"]} headWidth={headWidth}>
        {state.egressos.map((item:any, i:number) => (
          <TbodyPageEgressesInTheRounds
            key={i}
            index={i + 1}
            headWidth={headWidth}
            item={item}
          />
        ))}
      </TablePadrão>
    </div>
  );
}
