import React from 'react';
import { TablePadrão, Td, Tr } from '../../../../components/develop-nilton-components/TablePadrão';
import { Pagination } from '../../../../components/Pagination';
import { SpinnerLoading } from '../../../../components/SpinnerLoading';
import { SubmitResponse } from '../../../../components/SubmitResponse';
import { ITable } from '../types';
import { TbodyDesempenho } from './TbodyDesempenho';

export function TableDesempenho({
  data, meta, load, handleUpdate,
}: ITable) {
  const headWidth = ["5%", "50%", "10%", "50%", "20%", "50%"];
  const thead = ["#", "Nome do curso", "Curso status", "Nome do egresso", "Opções"];
  return (
    <TablePadrão
      headWidth={headWidth}
      thead={thead}
    >
      {load && (
      <Tr>
        <Td width="100%"><SpinnerLoading /></Td>
      </Tr>
      )}
      {data?.length === 0 && (
      <Tr>
        <Td width="100%">
          <div className="w-100">
            <SubmitResponse submitResponse={{ message: "Não há egressos vinculados a turmas", status: "danger", title: "Aviso" }} />
          </div>
        </Td>
      </Tr>
      )}
      {data?.map((course_egresse, i) => (
        <TbodyDesempenho
          courses_egresses={course_egresse}
          headWidth={headWidth}
          index={i + 1}
          key={i}
        />
      ))}
      {data && (
      <Tr>
        <Td width="100%"><Pagination meta={meta} setPage={handleUpdate} load={load} /></Td>
      </Tr>
      )}
    </TablePadrão>
  );
}
