import { Formik } from 'formik';
import React from 'react';
import {
  Button, Form, Label, ModalFooter,
} from 'reactstrap';
import * as yup from 'yup';
import { InputField } from "../../../InputField";
import { mask_only_number } from '../../../../../utils/mask_only_number';

type FormPart1Props = {
  initialValues: any
  handleNextStep: (newData: any) => void
  handleBackStep: () => void
  isCreate: boolean;
}

export function FormPart3(props: FormPart1Props) {
  const {
    initialValues, isCreate, handleNextStep, handleBackStep,
  } = props;

  const validationSchema = yup.object({
    nome_mae: yup.string().required("Insira o nome da mãe").nullable(),
  });

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleNextStep}
      validationSchema={validationSchema}
    >
      {(props) => {
        const {
          handleSubmit, setFieldValue, values,
        } = props;
        return (
          <Form onSubmit={handleSubmit}>
            <div>
              <InputField
                label="Nome da Mãe*"
                InputHTMLAttributes={{
                  id: 'nome_mae',
                  name: 'nome_mae',
                  placeholder: 'Insira o nome da mãe',
                }}
              />

              <InputField
                label="Nome do Pai"
                InputHTMLAttributes={{
                  id: 'nome_pai',
                  name: 'nome_pai',
                  placeholder: 'Insira o nome do pai',
                }}
              />

              <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                <div style={{ width: '50%', marginRight: 5 }}>
                  <InputField
                    label="Núcleo Familiar"
                    InputHTMLAttributes={{
                      id: 'quantidade_pessoa_nucleo_familiar',
                      name: 'quantidade_pessoa_nucleo_familiar',
                      placeholder: 'Insira a quantidade do nucleo familiar',
                      onChange: (e) => setFieldValue('quantidade_pessoa_nucleo_familiar', mask_only_number(e.target.value)),
                      style: { marginRight: 10 },
                    }}
                  />
                </div>
                <div style={{ width: '50%' }}>
                  <div>
                    <InputField
                      label="Possui filhos"
                      InputHTMLAttributes={{
                        id: 'possui_filhos',
                        name: 'possui_filhos',
                        type: "select",
                        value: values.possui_filhos || '-',
                      }}
                      LabelHTMLAttributes={{
                        className: 'mr-3',
                      }}
                      options={(
                        <>
                          <option value="-">
                            -
                          </option>
                          <option value="T">
                            Sim
                          </option>
                          <option value="F">
                            Não
                          </option>
                        </>
                    )}
                    />
                    {/* <InputField
                        label="Sim"
                        InputHTMLAttributes={{
                          id: 'possui_filhos1',
                          name: 'possui_filhos',
                          type: "radio",
                          value: "T",
                          defaultChecked: values.possui_filhos === 'T',
                        }}
                        LabelHTMLAttributes={{
                          className: 'mr-3',
                        }}
                      />
                      <InputField
                        label="Não"
                        InputHTMLAttributes={{
                          id: 'possui_filhos2',
                          name: 'possui_filhos',
                          type: "radio",
                          value: "F",
                          defaultChecked: values.possui_filhos === 'F',
                        }}
                      /> */}
                  </div>
                </div>
              </div>

              {values.possui_filhos === "T" && (
              <div>
                <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                  <div style={{ width: '50%', marginRight: 5 }}>
                    <InputField
                      label="Quantidade de Filhos"
                      InputHTMLAttributes={{
                        id: 'quantidade_filhos',
                        name: 'quantidade_filhos',
                        placeholder: 'Insira a quantidade de filho',
                        disabled: !!(values.possui_filhos === '' || values.possui_filhos === 'F' || values.possui_filhos === null),
                        onChange: (e) => setFieldValue('quantidade_filhos', mask_only_number(e.target.value)),
                        style: { marginRight: 10 },
                      }}
                    />
                  </div>
                  <div style={{ width: '50%' }}>
                    <div>
                      <InputField
                        label="Faixa Etária dos Filhos"
                        InputHTMLAttributes={{
                          id: 'idade_filhos',
                          name: 'idade_filhos',
                          type: "select",
                          value: values.idade_filhos || '-',
                        }}
                        LabelHTMLAttributes={{
                          className: 'mr-3',
                        }}
                        options={(
                          <>
                            <option value="-">
                              -
                            </option>
                            <option value="0 a 6">
                              0 à 6 anos
                            </option>
                            <option value="7 a 12">
                              07 à 12 anos
                            </option>
                            <option value="13 a 17">
                              13 à 17 anos
                            </option>
                            <option value="maior de 18">
                              Maiores de 18 anos
                            </option>
                          </>
                    )}
                      />
                    </div>
                  </div>
                  {/* <Label style={{ fontWeight: 'bold' }}>Faixa Etária dos Filhos</Label>
                  <div className="d-flex">
                    <InputField
                      label="0 à 6 anos"
                      InputHTMLAttributes={{
                        id: 'idade_filhos1',
                        name: 'idade_filhos',
                        type: "radio",
                        value: "0 a 6",
                        defaultChecked: values.idade_filhos === '0 a 6',
                      }}
                      LabelHTMLAttributes={{
                        className: 'mr-3',
                      }}
                    />
                    <InputField
                      label="07 à 12 anos"
                      InputHTMLAttributes={{
                        id: 'idade_filhos2',
                        name: 'idade_filhos',
                        type: "radio",
                        value: "7 a 12",
                        defaultChecked: values.idade_filhos === "7 a 12",
                      }}
                      LabelHTMLAttributes={{
                        className: 'mr-3',
                      }}
                    />
                    <InputField
                      label="13 à 17 anos"
                      InputHTMLAttributes={{
                        id: 'idade_filhos3',
                        name: 'idade_filhos',
                        type: "radio",
                        value: "13 a 17",
                        defaultChecked: values.idade_filhos === "13 a 17",
                      }}
                      LabelHTMLAttributes={{
                        className: 'mr-3',
                      }}
                    />
                    <InputField
                      label="Maiores de 18 anos"
                      InputHTMLAttributes={{
                        id: 'idade_filhos4',
                        name: 'idade_filhos',
                        type: "radio",
                        value: "maior de 18",
                        defaultChecked: values.idade_filhos === "maior de 18",
                      }}
                    />
                  </div> */}
                </div>
              </div>
              )}

              {/* <div>
              <Label style={{ fontWeight: 'bold' }}>Possui residência fixa?</Label>
              <div className="d-flex">
                <InputField
                  label="Sim"
                  InputHTMLAttributes={{
                    id: 'possui_residencia_fixa1',
                    name: 'possui_residencia_fixa',
                    type: "radio",
                    value: "T",
                    defaultChecked: values.possui_residencia_fixa === 'T',
                  }}
                  LabelHTMLAttributes={{
                    className: 'mr-3',
                  }}
                />
                <InputField
                  label="Não"
                  InputHTMLAttributes={{
                    id: 'possui_residencia_fixa2',
                    name: 'possui_residencia_fixa',
                    type: "radio",
                    value: "F",
                    defaultChecked: values.possui_residencia_fixa === 'F',
                  }}
                  LabelHTMLAttributes={{
                    className: 'mr-3',
                  }}
                />
              </div>
            </div> */}

              {/* <InputField
              label="Email"
              InputHTMLAttributes={{
                id: 'email',
                name: 'email',
                placeholder: 'Insira o email',
                type: 'email',
              }}
            />
            <InputField
              label="Número de telefone para recado"
              InputHTMLAttributes={{
                id: 'telefone_recado',
                name: 'telefone_recado',
                placeholder: 'Insira o telefone de recado',
              }}
            /> */}

              {/* <div>
              <Label style={{ fontWeight: 'bold' }}>Escolaridade</Label>
              <InputField
                label="Analfabeto"
                InputHTMLAttributes={{
                  id: 'escolaridade-1',
                  name: 'escolaridade',
                  type: "radio",
                  value: "Analfabeto",
                  defaultChecked: values.escolaridade === 'Analfabeto',
                }}
                LabelHTMLAttributes={{
                  className: 'mr-3',
                }}
              />
              <InputField
                label="Fundamental Incompleto"
                InputHTMLAttributes={{
                  id: 'escolaridade-2',
                  name: 'escolaridade',
                  type: "radio",
                  value: "Fundamental Incompleto",
                  defaultChecked: values.escolaridade === 'Fundamental Incompleto',
                }}
                LabelHTMLAttributes={{
                  className: 'mr-3',
                }}
              />
              <InputField
                label="Fundamental Completo"
                InputHTMLAttributes={{
                  id: 'escolaridade-3',
                  name: 'escolaridade',
                  type: "radio",
                  value: "Fundamental Completo",
                  defaultChecked: values.escolaridade === 'Fundamental Completo',
                }}
                LabelHTMLAttributes={{
                  className: 'mr-3',
                }}
              />
              <InputField
                label="Médio Incompleto"
                InputHTMLAttributes={{
                  id: 'escolaridade-4',
                  name: 'escolaridade',
                  type: "radio",
                  value: "Médio Incompleto",
                  defaultChecked: values.escolaridade === 'Médio Incompleto',
                }}
              />
              <InputField
                label="Médio Completo"
                InputHTMLAttributes={{
                  id: 'escolaridade-5',
                  name: 'escolaridade',
                  type: "radio",
                  value: "Médio Completo",
                  defaultChecked: values.escolaridade === 'Médio Completo',
                }}
              />
              <InputField
                label="Superior Incompleto"
                InputHTMLAttributes={{
                  id: 'escolaridade-6',
                  name: 'escolaridade',
                  type: "radio",
                  value: "Superior Incompleto",
                  defaultChecked: values.escolaridade === 'Superior Incompleto',
                }}
              />
              <InputField
                label="Superior Completo"
                InputHTMLAttributes={{
                  id: 'escolaridade-7',
                  name: 'escolaridade',
                  type: "radio",
                  value: "Superior Completo",
                  defaultChecked: values.escolaridade === 'Superior Completo',
                }}
              />
              <InputField
                label="Pós Graduação (Lato senso) Incompleto"
                InputHTMLAttributes={{
                  id: 'escolaridade-8',
                  name: 'escolaridade',
                  type: "radio",
                  value: "Pós Graduação (Lato senso) Incompleto",
                  defaultChecked: values.escolaridade === 'Pós Graduação (Lato senso) Incompleto',
                }}
              />
              <InputField
                label="Pós Graduação (Lato senso)Completo"
                InputHTMLAttributes={{
                  id: 'escolaridade-9',
                  name: 'escolaridade',
                  type: "radio",
                  value: "Pós Graduação (Lato senso)Completo",
                  defaultChecked: values.escolaridade === 'Pós Graduação (Lato senso)Completo',
                }}
              />
              <InputField
                label="Pós Graduação (Stricto sensu, nível mestrado) Incompleto"
                InputHTMLAttributes={{
                  id: 'escolaridade-10',
                  name: 'escolaridade',
                  type: "radio",
                  value: "Pós Graduação (Stricto sensu, nível mestrado) Incompleto",
                  defaultChecked: values.escolaridade === 'Pós Graduação (Stricto sensu, nível mestrado) Incompleto',
                }}
              />
              <InputField
                label="Pós Graduação (Stricto sensu, nível mestrado) Completo"
                InputHTMLAttributes={{
                  id: 'escolaridade-11',
                  name: 'escolaridade',
                  type: "radio",
                  value: "Pós Graduação (Stricto sensu, nível mestrado) Completo",
                  defaultChecked: values.escolaridade === 'Pós Graduação (Stricto sensu, nível mestrado) Completo',
                }}
              />
              <InputField
                label="Pós Graduação (Stricto sensu, nível doutorado) Incompleto"
                InputHTMLAttributes={{
                  id: 'escolaridade-12',
                  name: 'escolaridade',
                  type: "radio",
                  value: "Pós Graduação (Stricto sensu, nível doutorado) Incompleto",
                  defaultChecked: values.escolaridade === 'Pós Graduação (Stricto sensu, nível doutorado) Incompleto',
                }}
              />
            </div> */}
            </div>
            <ModalFooter className="d-flex justify-content-between">
              <div>
                <Button type="button" size="lg" onClick={handleBackStep}>Voltar</Button>
              </div>
              <div>
                <Button style={{ borderColor: '#1B7E9F', background: '#1B7E9F' }} type="submit" color="primary" size="lg">{isCreate ? 'Avançar' : 'Salvar'}</Button>
              </div>
            </ModalFooter>
          </Form>
        );
      }}
    </Formik>
  );
}
