import styled from 'styled-components/';

interface IDivPropsType {
    left: string;
  }

export const Container = styled.div<IDivPropsType>`
  background-color: #fff;
  position: fixed;
  height: 100%;
  top: 0px;
  left: 0px;
  width: 300px;
  left: ${(props) => (props.left ? '0' : '-100%')};
  animation: showSidebar .4s;

  .menu-section1{
  }

  .title-section1 {
    display: flex;
    align-items: center;
    color: #003E5D;
    width: 100%;
    margin-top: 10px;
    margin-bottom: 30px;
    margin-left: 30px;
    margin-right: 10px;
    // padding: 5px 5px;
    justify-content: space-around;
    cursor: pointer;
    font-size: 20px;
    font-weight: bold;
  }

  .list-menu1 {
    display: flex;
    align-items: center;
    background: #fff;
    border: none;
    text-decoration: none;
    // border-radius: 15px;
    width: 100%;
    height: 60px;
    // margin: 10px;
    // padding-left: 20px;

    img {
      width: 20px;
      /* height: 38px; */
      margin-right: 10px;
      margin-left: 10px;
    }

    h1 {
      font-size: 16px;
      margin-top: 10px;
      font-weight: bold;
    }

  }

  .list-menu:hover{
    // background-color: #ccc;
    // border-radius: 4px;
    // transition: all 0.2s ease-out;
    cursor: pointer;
  }

  /* .list-menu:active {
    vertical-align: top;
    padding-top: 8px;
    border: none;
    text-decoration: none;
  } */

  .list-menu:focus { outline: none; }

  .title1-section-2 {
    display:flex;
    align-items:center;
    color:#003E5D;
    margin-left: 30px;
    width: 100%;
    margin-top:30px;
    margin-bottom: 30px;
    font-size:20px;
    font-weight:bold;
  }  

  @keyframes showSidebar {
    from {
      opacity: 0;
      width: 0;
    }
    to {
      opacity: 1;
      width: 300px;
    }
  }
`;
