import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */
    input[type=number] {
      -moz-appearance: textfield;
    }
  }

  a{
    text-decoration: none !important;
  }

  h1 {
    // margin-bottom: 0;
  }

  body{
    width: 100%;
    font-family: 'Roboto', sans-serif;
  }

  input {
    border-color: rgb(59, 59, 59);
    border-width: 0.5px;
  }

  select:focus {
    outline: none;
  }

  textarea:focus {
    outline: none;
  }

  .error {
    postion: absolute;
    color: red;
    font-size: 14px;
    font-weight: bold;
  }



  .container {
    display: block;
    position: relative;
    padding-left: 25px;
    margin-bottom: 12px;
    cursor: pointer;
  }

  /* Hide the browser's default checkbox */
  .container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  /* Create a custom checkbox */
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 15px;
    width: 15px;
    background-color: #eee;
    margin-top: 5px;
  }

  /* On mouse-over, add a grey background color */
  .container:hover input ~ .checkmark {
    background-color: #ccc;
  }

  /* When the checkbox is checked, add a blue background */
  .container input:checked ~ .checkmark {
    background-color: #2196F3;
  }

  /* Create the checkmark/indicator (hidden when not checked) */
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  .vertical {
    border-left: 48px solid #f2f2f2;
    // height: 100%;
  }

  .horizontal {
    border-bottom: 2px solid #1B7E9F;
    height: 2px;
    width: 40%;
  }

  tr:nth-child(even) {background-color: #e4e4e4;}

  /* Show the checkmark when checked */
  .container input:checked ~ .checkmark:after {
    display: block;
  }

  /* Style the checkmark/indicator */
  .container .checkmark:after {
    left: 5px;
    top: 2px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }


  .css-b62m3t-container {
    width: 400px
  }


  .css-b62m3t-container{
    width: 100%;
  }




  .pagination {
    margin: 15px 0;
    display: flex;
    list-style: none;
    outline: none;
  }

  .pagination>.active>a {
      background-color: #1B7E9F;
      border-color: #1B7E9F;
      color: #fff;
  }

  .pagination>li>a {
      border: 1px solid #1B7E9F;
      padding: 5px 10px;
      outline: none;
      cursor: pointer;
  }

  .pagination>.active>a,
  .pagination>.active>span,
  .pagination>.active>a:hover,
  .pagination>.active>span:hover,
  .pagination>.active>a:focus,
  .pagination>.active>span:focus {
      background-color: #1B7E9F;
      border-color: #1B7E9F;
      outline: none;
  }

  .pagination>li>a,
  .pagination>li>span {
      color: #1B7E9F;
  }

  .pagination>li:first-child>a,
  .pagination>li:first-child>span,
  .pagination>li:last-child>a,
  .pagination>li:last-child>span {
      border-radius: 0px;
  }

  .table-striped>tbody>tr:nth-child(odd){
    background-color:#fff;
  }

  @media (max-width: 1024px){
    .vertical {
      display: none;
    }
    .horizontal {
      border-bottom: 2px solid #1B7E9F;
      height: 2px;
      width: 100%;
    }
  }


`;

export default GlobalStyle;
