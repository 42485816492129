import React from 'react';
import { IoIosArrowDropleftCircle } from 'react-icons/io';

import { useHistory } from 'react-router-dom';

import { useAuth } from '../../../../hooks/auth';

interface IAlvara {
  id: number;
  numero_alvara: number;
  egress: {
    nome: string;
    cep: string;
    endereco: string;
    bairro: string;
    estado: string;
    municipio: string;
  };
  responsavel_execucao: any;
  executado: string;
  data_execucao: string;
  registro_seuu: string;
  attachment: {
    name:string;
    url_file: string;
  };
  observacao: string;
}

interface IProps {
  route: 'cadastrar-ronda' | 'listar-ronda' | 'cadastrar-alvara' | 'cumprir-alvara' | 'notificar-egresso' | 'relatorios' | 'indicadores';
  name: string;
  alvara?: IAlvara;
  pad?: any;
  setAlvara?: (value: IAlvara | null) => void;
  selecionados?: number
}

function Header({
  route, name, alvara, pad, setAlvara, selecionados = 0,
}: IProps) {
  const history = useHistory();
  const { user } = useAuth();

  return (
    <div>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        {/* <h1 style={{ color: '#306099', fontSize: 48 }}>
          Módulo -
          {' '}
          {user.profile.title}
        </h1> */}
        {/* <div
          onClick={() => {
            if (route === 'cumprir-alvara') {
              // @ts-ignore
              if (typeof setAlvara === "function") {
                setAlvara(null);
              } else {
                history.push('/dashboard');
              }
            } else {
              history.push('/dashboard');
            }
          }}
          style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
        >
          <IoIosArrowDropleftCircle style={{ fontSize: 20 }} color="#BE1E2D" />
          <h1 style={{
            fontSize: 20, color: '#BE1E2D', marginLeft: 5, marginBottom: 3,
          }}
          >
            voltar
          </h1>
        </div> */}
      </div>

      <div style={{ display: 'flex', alignItems: 'center' }}>
        {/* <h2>{name}</h2> */}

        <div style={{ width: '100%' }}>
          <div>
            {name && <h2 style={{ fontSize: 36, fontWeight: 500 }}>{`${name}`}</h2>}
          </div>

          <div style={{
            width: '100%', marginTop: 20, marginBottom: 10, borderBottom: 2, borderBottomStyle: 'solid', borderBottomColor: '#1B7E9F',
          }}
          />
        </div>
        {route === 'cadastrar-ronda'
        && (
        <h3 style={{
          marginLeft: 10, fontSize: 26, color: '#c7c7c7',
        }}
        >
          {`(${selecionados} selecionados)`}
        </h3>
        )}
        {route === 'cumprir-alvara' && !!alvara
        && (
          <h2 style={{ color: '#f00', marginLeft: 10 }}>{alvara.numero_alvara}</h2>
        )}
        {route === 'notificar-egresso' && pad
        && (
          <h2 style={{ color: '#f00', marginLeft: 10 }}>{pad.processo_pad}</h2>
        )}
      </div>
    </div>
  );
}

export default Header;
