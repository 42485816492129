import React from 'react';
import { TablePadrão, Td, Tr } from '../../../../components/develop-nilton-components/TablePadrão';
import { Pagination } from '../../../../components/Pagination';
import { SpinnerLoading } from '../../../../components/SpinnerLoading';
import { SubmitResponse } from '../../../../components/SubmitResponse';
import { Table } from '../types';
import { TbodyGeral } from './TbodyGeral';

export function TableGeral({
  handleUpdate, load, data, meta, addEgresses,
}:Table) {
  const headWidth = ["10%", "50%", "20%", "20%"];
  const thead = ["#", "Nome", "Detalhes", "Adicionar"];
  return (
    <div>
      <TablePadrão headWidth={headWidth} thead={thead}>
        {(load) && <Tr><Td width="100%"><SpinnerLoading /></Td></Tr>}
        {data?.length === 0 && (
          <Tr>
            <Td width="100%">
              <div className="w-100">
                <SubmitResponse submitResponse={{ message: "Todos os egressos já foram adicionados na turma", status: "danger", title: "Aviso" }} />
              </div>
            </Td>
          </Tr>
        )}
        {data?.map((item, i) => (
          <TbodyGeral
            key={i}
            handleUpdate={handleUpdate}
            egress={item}
            headWidth={headWidth}
            index={i + 1}
            addEgresses={addEgresses}
          />
        ))}
        <Tr>
          <Td width="100%">
            {data && <Pagination meta={meta} setPage={handleUpdate} load={load} />}
          </Td>
        </Tr>
      </TablePadrão>
    </div>
  );
}
