import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import ReactTooltip from 'react-tooltip';
import { Badge } from '../../../../components/develop-nilton-components/Badge';
import { IconAdd } from '../../../../components/develop-nilton-components/IconAdd';
import { IconDetails } from '../../../../components/develop-nilton-components/IconDetails';
import { ModalDetailsEgresse } from '../../../../components/develop-nilton-components/ModalDetailsEgresses';
import { Td, Tr } from '../../../../components/develop-nilton-components/TablePadrão';
import { useAuth } from '../../../../hooks/auth';
import api from '../../../../services/api';
import { ITbodyGeral } from '../type';

export function TbodyGeral({ headWidth, index, egress }: ITbodyGeral) {
  const [isOpen, setIsOpen] = useState(false);
  const logId = useAuth().user.id;
  function handleIsOpen() {
    setIsOpen(!isOpen);
  }
  function handleTootip() {
    toast.error('Egresso já está em acompanhamento.');
  }

  const history = useHistory();
  async function handleRedirectPageForm() {
    history.push({
      pathname: '/acompanhamento/consulta',
      state: egress,
    });
  }
  return (
    <Tr data-testid="table_geral">
      <Td width={headWidth[0]}>{index}</Td>
      <Td width={headWidth[1]}>{egress.nome}</Td>
      <Td width={headWidth[2]}>
        {(egress.acompanhamentos_psicopedagogicos.situacao === "Sem acompanhamento" || egress.acompanhamentos_psicopedagogicos.situacao === "Acompanhamento concluído") && (
        <Badge type="primary">Sem acompanhamento</Badge>
        )}
        {egress.acompanhamentos_psicopedagogicos.situacao === "Em acompanhamento" && (
          // @ts-ignore
          <div data-tip={egress.acompanhamentos_psicopedagogicos.em_acompanhamento.psicopedagogo.name}>
            <ReactTooltip />
            <Badge type="warning">{egress.acompanhamentos_psicopedagogicos.situacao}</Badge>
          </div>
        )}
      </Td>
      <Td width={headWidth[2]}>
        {egress.acompanhamentos_psicopedagogicos.situacao === "Em acompanhamento" ? (
          <div onClick={() => {
            if (egress.acompanhamentos_psicopedagogicos.em_acompanhamento.psicopedagogo.id === logId) {
              handleRedirectPageForm();
            } else {
              toast.error('Egresso acompanhado por outro psicólogo');
            }
          }}
          >
            <IconAdd
              cursor
              tooltipMessage="Realizar acompanhamento"
              color="text-primary"
              size={30}
            />
          </div>
        ) : (
          <div onClick={handleRedirectPageForm}>
            <IconAdd
              cursor
              tooltipMessage="Realizar acompanhamento"
              color="text-primary"
              size={30}
            />
          </div>
        )}

      </Td>
    </Tr>
  );
}
