import React from 'react';
import { ISubmitResponse } from './type';

export const SubmitResponse = ({ submitResponse }: ISubmitResponse) => (
  <div
    className={`alert alert-${submitResponse.status}`}
    role="alert"
    style={{ display: !submitResponse.message ? "none" : "block" }}
  >
    <h4 className="alert-heading">{submitResponse.title}</h4>
    <hr />
    <p className="mb-0">{submitResponse.message}</p>
  </div>
);
