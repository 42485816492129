/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import api from '../../../../services/api';

export function useGetEgressesById(egress_id:number) {
  const [egresse, setEgresse] = useState<any |null>(null);

  async function handleSetEgresses() {
    const { data } = await api.get(`/egresses/${egress_id}`);
    setEgresse(data);
  }
  useEffect(() => {
    handleSetEgresses();
  }, []);
  return { egresse, handleSetEgresses };
}
