import React, { useState } from 'react';
import { FaDownload } from 'react-icons/fa';
import { IconDetails } from '../../../../components/develop-nilton-components/IconDetails';
import { Td, Tr } from '../../../../components/develop-nilton-components/TablePadrão';
import api from '../../../../services/api';
import { ModalDetailsEgresseIntheRounds } from '../../../Adm/HomologarRonda/components/ModalDetailsEgresseIntheRounds';

type PropsTbodyPageEgressesInTheRounds = {
  headWidth: string[]
  item: any
  index: number
}

export function TbodyPageEgresses({ headWidth, item, index }:PropsTbodyPageEgressesInTheRounds) {
  const [isOpenModalOccurrence, setIsOpenModalOccurrence] = useState(false);

  function handleSetIsOpenModalOccurrece() {
    setIsOpenModalOccurrence(!isOpenModalOccurrence);
  }

  async function download(url:string, filename:string) {
    fetch(url).then((t) => t.blob().then((b) => {
      const a = document.createElement("a");
      a.href = URL.createObjectURL(b);
      a.setAttribute("download", filename);
      a.click();
    }));
  }

  async function handleDownload(item:any) {
    const { data } = await api.get(`/rounds_egresses/${item.id}/report`);
    download(data.url, `comprovante_ronda_${item.round_id}`);
  }
  return (
    <Tr>
      <Td width={headWidth[0]}>{index}</Td>
      <Td width={headWidth[1]}>{item?.egresso.nome}</Td>

      <Td width={headWidth[2]}>
        <div onClick={handleSetIsOpenModalOccurrece}>
          <IconDetails cursor tooltipMessage="Dados do egresso" />
        </div>
        <ModalDetailsEgresseIntheRounds
          item={item}
          observacao={item?.observacao}
          ocorrencia={item?.ocorrencia}
          isOpen={isOpenModalOccurrence}
          handleIsOpen={handleSetIsOpenModalOccurrece}
        />
      </Td>
      <Td width={headWidth[3]}>
        <div onClick={() => handleDownload(item)}>
          <FaDownload size={30} cursor="pointer" />
        </div>
      </Td>

    </Tr>
  );
}
