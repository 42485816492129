import styled from "styled-components";

export const AdmContainer = styled.div`
  margin-top: 20px;
  margin-bottom: 150px;

  .header {
    margin: 0 20px 20px 0;
  }

  & h1 {
    color:#306099;
    /* font-weight:700; */
    font-size: 48px;
    line-height: 56.25px;
  }

  & h2 {
    color: #182339;
    /* font-weight:500; */
    font-size: 36px;
    line-height: 42.19px;
  }

  @media (max-width:1024px) {
      .header {
        h1 {
          font-size: 30px;
          margin: 0;
        }
        h2 {
          font-size: 26px;
          margin: 0;
        }
      }
    }
    @media (max-width:599px) {
      .header {
        h1 {
          font-size: 25px;
        }
        h2 {
          font-size: 20px;
        }
      }
    }
`;
