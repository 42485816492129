import React, { useState } from 'react';
// @ts-ignore
import { CKEditor } from '@ckeditor/ckeditor5-react';
// @ts-ignore
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import api from '../../../../services/api';
import { IFatores } from '../type';
import { SpinnerLoading } from '../../../../components/SpinnerLoading';

export function Observacao({ getValues }:IFatores) {
  const history = useHistory();
  const [text, setText] = useState(getValues().observacoes);
  const [load, setLoad] = useState(false);

  const handleSubmit = async () => {
    setLoad(true);
    try {
      await api.put(`/social/${getValues().id}`, {
        observacoes: text === "" ? null : text,
        finalizado: "T",
        composicao_nucleo_familiar: getValues().composicao_nucleo_familiar,
        deficiencia_ou_necessidade: getValues().deficiencia_ou_necessidade,
        especificacao_deficiencia_ou_necessidade: getValues().deficiencia_ou_necessidade === "Sim" ? getValues().especificacao_deficiencia_ou_necessidade : null,
        especificacao_local_violento: getValues().local_violento === "Sim" ? getValues().especificacao_local_violento : null,
        especificacao_tradicao_religiosa: getValues().tradicao_religiosa === "Sim" ? getValues().especificacao_tradicao_religiosa : null,
        frequencia_uso_bebida: getValues().uso_bebida === "Sim" ? getValues().frequencia_uso_bebida : null,
        local_violento: getValues().local_violento,
        membro_com_vulnerabilidade: getValues().membro_com_vulnerabilidade,
        tipo_moradia: getValues().tipo_moradia,
        tipo_zona: getValues().tipo_zona,
        tradicao_religiosa: getValues().tradicao_religiosa,
        uso_bebida: getValues().uso_bebida,
        especificacao_participa_programa_auxilio_financeiro: getValues().especificacao_participa_programa_auxilio_financeiro,
        participa_programa_auxilio_financeiro: getValues().participa_programa_auxilio_financeiro,
        possui_membro_aposentadoria_outros: getValues().possui_membro_aposentadoria_outros,
        possui_membro_beneficio_continuo: getValues().possui_membro_beneficio_continuo,
        possui_membro_bolsa_familia: getValues().possui_membro_bolsa_familia,
        possui_membro_vinculo_empregaticio: getValues().possui_membro_vinculo_empregaticio,
        renda_trabalho: getValues().renda_trabalho,
        valor_beneficio_continuo: getValues().valor_beneficio_continuo,
        valor_bolsa_familia: getValues().valor_bolsa_familia,
        valor_outros_membros_beneficio_continuo: getValues().valor_outros_membros_beneficio_continuo,
        valor_outros_membros_bolsa_familia: getValues().valor_outros_membros_bolsa_familia,
      });
      history.push('/acompanhamento');
      window.scrollTo({ top: 0 });
    } catch (error) {
      // @ts-ignore
      toast.error(error.response.data.message);
    }
    setLoad(false);
  };

  return (
    <div className="w-75">
      <CKEditor
        editor={ClassicEditor}
        data={text || ""}
        // onReady={(editor: any) => {
        //   // You can store the "editor" and use when it is needed.
        //   console.log(editor);
        // }}
        onChange={(event: any, editor: { getData: () => any; }) => {
          const data = editor.getData();
          setText(data);
        }}
        // onBlur={(event: any, editor: any) => {
        //   console.log('Blur.', editor);
        // }}
        // onFocus={(event: any, editor: any) => {
        //   console.log('Focus.', editor);
        // }}
      />

      <div className="d-flex justify-content-center mt-5 mb-5">
        {load ? (
          <button
            className="btn btn-primary btn-lg"
          >
            <SpinnerLoading />
          </button>
        ) : (
          <button
            className="btn btn-primary btn-lg"
            onClick={handleSubmit}
          >
            Finalizar
          </button>
        )}
      </div>
    </div>
  );
}
