import { format } from 'date-fns';
import React, { useEffect } from 'react';
import ReactTooltip from 'react-tooltip';
import { BsEyeFill } from 'react-icons/bs';
import {
  Td,
  Tr,
} from '../../../../components/develop-nilton-components/TablePadrão';
import { OptionsHistoryFigma } from './OptionsHistoryFigma';
import { ModalDetailsEgresse } from '../../../../components/develop-nilton-components/ModalDetailsEgresses';
import { IEgresses } from '../../../../types/IEgresses';
import api from '../../../../services/api';
import { SpinnerLoading } from '../../../../components/SpinnerLoading';
import { useGetEgressesById } from '../hooks/useGetEgressesById';

type PropsTable = {
  item: any;
  widthBody: (string | number)[];
  useStateIsOpen: [boolean, (boolean: boolean) => void];
  useStateEgresse: [IEgresses | null, (egresse: IEgresses | null) => void];
  iteration: any;
  update(): Promise<void>;
  roundEgressesOfFiscal?: any;
  setRoundEgressesOfFiscal?(value: any): void;
};

export function TbodyListLoadEgresses({
  item,
  widthBody,
  useStateIsOpen,
  useStateEgresse,
  iteration,
  update,
  roundEgressesOfFiscal,
  setRoundEgressesOfFiscal,
}: PropsTable) {
  const [isOpenDetailsAddModal, setIsOpenDetailsAddModal] =
    React.useState(false);

  function handlesSetIsOpenModalDetailsEgresses() {
    setIsOpenDetailsAddModal(!isOpenDetailsAddModal);
  }

  const [isWatchEgresse, setIsWatchEgresse] = useStateIsOpen;
  const [egresse, setEgresse] = useStateEgresse;

  return (
    <Tr key={item.id}>
      <Td width={widthBody[0]}>{iteration + 1}</Td>
      <Td width={widthBody[1]}>
        <ReactTooltip />
        {item?.egresso?.nome?.substring(0, 20)}
      </Td>
      <Td width={widthBody[2]}>
        {item?.data_ocorrencia
          ? format(new Date(item?.data_ocorrencia), 'dd/MM/yyyy')
          : 'DD/MM/YYYY'}{' '}
        {item?.data_ocorrencia
          ? format(new Date(item?.data_ocorrencia), 'HH:mm')
          : 'HH:mm'}
      </Td>
      <Td style={{ alignItems: 'center' }} width={widthBody[3]}>
        <div
          data-tip="Detalhes"
          style={{ marginRight: 15 }}
          onClick={() => {
            setIsOpenDetailsAddModal(true);
          }}
        >
          <BsEyeFill color="black" size={22} cursor="pointer" />
        </div>
        <OptionsHistoryFigma item={item} disable update={update} />

        <ModalDetailsEgresse
          isOpen={isOpenDetailsAddModal}
          handleIsOpen={handlesSetIsOpenModalDetailsEgresses}
          egresse={item.egresso}
          useStateEgresse={[egresse, setEgresse]}
          useStateIsOpen={[isWatchEgresse, setIsWatchEgresse]}
        />
      </Td>
    </Tr>
  );
}
