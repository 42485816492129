import React from 'react';
import { FormState, UseFormRegister } from 'react-hook-form';
import { InputField } from '../styles/InputField';
import { Errors } from './Erro';

type INameField = {
    register: UseFormRegister<any>
    formState: FormState<any>
    label: string
    name: string
    placeholder?: string
}

export function NameField({
  register, formState, label, name, placeholder,
}:INameField) {
  return (
    <InputField>
      <label htmlFor="">{label}</label>
      <div className="flex-column align-content-start justify-content-start">
        <input
          autoComplete="off"
          type="text"
          {...register(name, { required: true })}
          className="form-control form-control-lg"
          placeholder={placeholder}
          required
        />
        {
          formState.errors[name] && (
          <Errors />
          )
        }
      </div>
    </InputField>
  );
}
