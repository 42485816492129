import React, { useState } from 'react';
import ReactModal from 'react-modal';
import styled from 'styled-components';
import { ModalOccurrences } from '../../../../components/develop-nilton-components/ModalOccurrences';
import { SpinnerLoading } from '../../../../components/SpinnerLoading';
import { useGetEgressesById } from '../hooks/useGetEgressesById';

type PropsDetailsEgresse = {
  item: any
  isOpen: boolean
  handleIsOpen(): void
  ocorrencia: any
  observacao: any
}

const styleModal = {
  content: {
    width: "85%",
    height: "470px",
    margin: "auto auto",
    borderRadius: "10px",
    overflow: "auto",
  },
  overlay: {
    background: "rgba(1,1,1,.1)",
    overflow: "hidden",
  },
};

export function ModalDetailsEgresseIntheRounds({
  item, isOpen, handleIsOpen, observacao, ocorrencia,
}:PropsDetailsEgresse) {
  const [isOpenModalOccurrences, setIsOpenModalOccurrences] = useState(false);
  const { egresse } = useGetEgressesById(item.egress_id);

  function handleIsOpenModalOccurrences() {
    setIsOpenModalOccurrences(!isOpenModalOccurrences);
    handleIsOpen();
  }

  return (
    <div>

      <ReactModal
        isOpen={isOpen}
        ariaHideApp={false}
        shouldCloseOnOverlayClick
        onRequestClose={handleIsOpen}
        style={styleModal}
      >
        <Title>Detalhes do egresso</Title>

        {!egresse && <Center><SpinnerLoading size={30} /></Center>}
        {egresse && (
        <Details>
          <div>

            <P>
              <strong>Nome:</strong>
              {' '}
              {item?.egresso.nome}
            </P>
            <P>
              <strong>Regime:</strong>
              {' '}
              {item?.egresso.tipo_regime}
            </P>
            <P>
              <strong>Endereço registrado:</strong>
              {' '}
              {item?.egresso.endereco}
            </P>
            <P>
              <strong>Número de fiscalizações:</strong>
              {' '}
              {item?.egresso.rondas?.total}
            </P>
            <P>
              <strong>Ocorrências negativas:</strong>
              {' '}
              {egresse?.rondas?.com_ocorrencia.length}
              {' '}
              {egresse?.rondas?.com_ocorrencia.length !== 0
              && <Span onClick={handleIsOpenModalOccurrences}>(Ver detalhes)</Span>}
            </P>
            <P>
              <strong>Telefone cadastrado:</strong>
              {' '}
              {item?.egresso.telefone_recado}
            </P>
            <P>
              <strong>Status da ronda:</strong>
              {' '}
              {ocorrencia?.name}
            </P>
            <P>
              <strong>Observação:</strong>
              {' '}
              {observacao}
            </P>
            {/* <P>
              <strong>Comprovante:</strong>
              {' '}
              <a href={comprovante?.url_file || undefined} rel="noreferrer">(Ver imagem)</a>
            </P> */}
          </div>
          <Avatar src={item?.avatar?.url_file || "https://static.vecteezy.com/ti/vetor-gratis/p1/1840618-imagem-perfil-icone-masculino-icone-humano-ou-pessoa-sinal-e-simbolo-gr%C3%A1tis-vetor.jpg"} alt="Foto do egresso" />
        </Details>
        )}

      </ReactModal>
      <ModalOccurrences
        isOpen={isOpenModalOccurrences}
        egresse={egresse}
        handleIsOpen={handleIsOpenModalOccurrences}
      />
    </div>
  );
}

const Details = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Avatar = styled.img`
    height: 300px;
    width: 300px;
    left: 951px;
    top: 118px;
    border-radius: 0px;
`;

const P = styled.p`
  font-size: 24px;
  font-weight: 400;
  line-height: 29px;
  letter-spacing: 0px;
  text-align: left;
`;

const Title = styled.h2`
  font-size: 36px;
  font-weight: 700;
  line-height: 42px;
  letter-spacing: 0px;
  text-align: left;

  padding: 0 0 15px 0;
  border-bottom: 1px solid #595959
`;

const Span = styled.span`
  color: #007bff;
  cursor: pointer
`;

const Center = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 17% 0 0 0;
`;
