import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { FaBars } from 'react-icons/fa';
import { SideBarContainer } from './SideBar.styled';
import { useSidebarList, useSidebarListMaster } from './sidebarList';
import MobileSideBar from './MobileSidebar';

function SideBar() {
  const permission = useSidebarList();
  const permissionMaster = useSidebarListMaster();

  const [selected, setSelected] = React.useState<any>(
    {
      icon: '/assets/dashboard.png',
      title: 'Dashboard',
      to: '/dashboard',
    },
  );

  const [sidebar, setSidebar] = useState(false);

  const showSiderbar = () => setSidebar(!sidebar);

  return (
    <SideBarContainer>
      <div className="menu-hamburger">
        <FaBars size={20} onClick={showSiderbar} />
      </div>
      <div className="menu-section">
        {/* <Link style={{ textDecoration: 'none' }} to="/dashboard">
          <div className="title-section">
            <img src="/assets/social_icon.png" alt="icone-albergado" />
            E-Albergado
          </div>
        </Link> */}

        {sidebar && <MobileSideBar active={setSidebar} />}
        <div style={{ marginTop: 20 }} />
        {permissionMaster ? permissionMaster?.functions.map((item, i) => (
          <Link key={item.title} style={{ textDecoration: 'none' }} to={item.to}>
            <button className="list-menu" onClick={() => setSelected(item)} style={{ background: selected?.title == item.title ? 'rgba(0,62,93, 0.3)' : '#fff' }}>
              {selected?.title == item.title && (
              <div style={{
                position: 'relative', right: 0, background: 'rgba(0,62,93)', width: 10, height: '100%',
              }}
              />
              )}
              <div style={{
                paddingLeft: selected?.title == item.title ? 10 : 20, display: "flex", flexDirection: 'row', alignItems: 'center',
              }}
              >
                <img src={item.icon} alt={item.title} />
                <h1 className="text">{item.title}</h1>
              </div>
            </button>
          </Link>
        )) : permission.functions.map((item, i) => (
          <Link key={item.title} style={{ textDecoration: 'none' }} to={item.to}>
            <button className="list-menu" onClick={() => setSelected(item)} style={{ background: selected?.title == item.title ? 'rgba(0,62,93, 0.3)' : '#fff' }}>
              {selected?.title == item.title && (
              <div style={{
                position: 'relative', right: 0, background: 'rgba(0,62,93)', width: 10, height: '100%',
              }}
              />
              )}
              <div style={{
                paddingLeft: selected?.title == item.title ? 10 : 20, display: "flex", flexDirection: 'row', alignItems: 'center',
              }}
              >
                <img src={item.icon} alt={item.title} />
                <h1 className="text">{item.title}</h1>
              </div>
            </button>
          </Link>
        ))}
        <div className="title-section" />
      </div>
    </SideBarContainer>
  );
}

export default SideBar;
