import { Formik } from 'formik';
import * as Yup from 'yup';
import React from 'react';
import {
  Button, Form, Modal, ModalBody, ModalFooter, ModalHeader,
} from 'reactstrap';
import { toast } from 'react-toastify';
import { useGetProfiles } from '../../../hooks/Profiles/useGetProfiles';
import { mask_cpf } from '../../../utils/mask_cpf';
import { InputField } from "../InputField";
import { IUser } from '../../../types/IUser';
import api from '../../../services/api';
import { useUpdateUsers } from '../../../hooks/Users/useUpdateUsers';
import { IFetchParams } from '../../../types/IFetchParams';

type UsersFormModal = {
  useStateUser: [IUser | null, (user: IUser | null) => void]
  useStateIsOpen: [boolean, (boolean: boolean) => void]
  useStateIsCreate: [boolean, (boolean: boolean) => void]
  queryKeysUsers: IFetchParams
}

export function UsersFormModal(props: UsersFormModal) {
  const {
    useStateIsOpen, useStateIsCreate, useStateUser, queryKeysUsers,
  } = props;

  const [isOpen, setIsOpen] = useStateIsOpen;
  const [isCreate, setIsCreate] = useStateIsCreate;

  const [user, setUser] = useStateUser;

  const { profiles } = useGetProfiles();

  const toggle = () => {
    setIsCreate(false);
    setIsOpen(!isOpen);
  };

  const data = {
    name: '',
    cpf: '',
    password: '',
    profile_id: '',
    habilitado: '',
  };

  const validationSchema = Yup.object({
    name: Yup.string().required('Insira o nome').nullable(),
    cpf: Yup.string().required('Insira o CPF').nullable(),
    password: Yup.string().required('Insira a senha').min(8, 'A senha deve ter pelo menos 8 caracteres'),
    profile_id: Yup.string().required('Selecione o perfil').nullable(),
    habilitado: Yup.string().required('Selecione o status').nullable(),
  });

  async function onSubmit(values: any) {
    const {
      name, cpf, password, profile_id, habilitado,
    } = values;

    const data = {
      name, cpf, profile_id, habilitado,
    };

    if (user) {
      toast.promise(async () => {
        if (password === "") {
          api.put(`/users/${user.id}`, data);
        } else {
          api.put(`/users/${user.id}`, { ...data, password });
        }
      },
      {
        pending: "Editando...",
        success: "Editado com sucesso",
        error: { render: ({ data }: any) => data.response.data.message },
      });
    } else {
      toast.promise(
        api.post(`/register`, { ...data, password }),
        {
          pending: "Cadastrando...",
          success: "Cadastrado com sucesso",
          error: { render: ({ data }: any) => data.response.data.message },
        },
      );
    }
    setIsOpen(false);
  }

  const { mutate } = useUpdateUsers(onSubmit, queryKeysUsers);

  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      onClosed={() => {
        setIsCreate(false);
        setUser(null);
      }}
    >
      <Formik
        initialValues={user ?? data}
        onSubmit={mutate as any}
        validationSchema={validationSchema}
      >
        {(props) => {
          const { handleSubmit, setFieldValue, values } = props;
          return (
            <Form onSubmit={handleSubmit}>
              <ModalHeader toggle={toggle}>
                {isCreate ? 'Cadastrar Usuário' : 'Editar Usuário'}
              </ModalHeader>
              <ModalBody>
                <InputField
                  label="Nome"
                  InputHTMLAttributes={{
                    id: 'name',
                    name: 'name',
                    placeholder: 'Insira o nome',
                  }}
                />
                <InputField
                  label="CPF"
                  InputHTMLAttributes={{
                    name: 'cpf',
                    placeholder: 'Insira o CPF',
                    onChange: (e) => setFieldValue('cpf', mask_cpf(e.target.value)),
                  }}
                />
                <InputField
                  label="Senha"
                  InputHTMLAttributes={{
                    name: 'password',
                    placeholder: user ? "Nova senha" : "Insira a senha",
                    type: 'password',
                  }}
                />

                <InputField
                  label="Perfil"
                  InputHTMLAttributes={{
                    id: 'profile_id',
                    name: 'profile_id',
                    placeholder: 'Selecione o perfil',
                    type: "select",
                  }}
                  options={(
                    <>
                      <option key="default" value="">Selecione o perfil</option>
                      {profiles?.map((profile) => (
                        <option key={profile.id} value={profile.id}>{profile.title}</option>
                      ))}
                    </>
                  )}
                />
                <InputField
                  label="Status"
                  InputHTMLAttributes={{
                    id: 'habilitado',
                    name: 'habilitado',
                    placeholder: "Selecione o status do usuário",
                    type: "select",
                  }}
                  options={(
                    <>
                      <option key="default" value="">Selecione o status do usuário</option>
                      <option key="F" value="F">Desabilitado</option>
                      <option key="T" value="T">Habilitado</option>
                    </>
                  )}
                />
              </ModalBody>
              <ModalFooter>
                <Button type="submit" color="primary">
                  {isCreate ? "Cadastrar" : "Salvar"}
                </Button>
                {' '}
                <Button color="secondary" onClick={toggle}>
                  Voltar
                </Button>
              </ModalFooter>
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
}
