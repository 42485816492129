import React, { useEffect, useState } from 'react';
import api from '../../../../services/api';

export function useGetEgressoMedia_notas_egressos_ultimos_cursos() {
  const [media_notas_egressos_ultimos_cursos, set_data_set_turma] = useState<any>(null);

  async function handle_media_notas_egressos_ultimos_cursos(year?: string) {
    const { data } = await api.get(`/reports/media_notas_egressos_ultimos_cursos`, { params: { year } });
    set_data_set_turma(data);
  }

  useEffect(() => {
    handle_media_notas_egressos_ultimos_cursos();
  }, []);

  return { media_notas_egressos_ultimos_cursos, handle_media_notas_egressos_ultimos_cursos };
}
