import React from 'react';
import { Chart } from 'react-chartjs-2';
import { eachYearOfInterval, format } from 'date-fns';
import { SpinnerLoading } from '../../../../components/SpinnerLoading';
import { useGetTurmaMedia_alunos_por_turma_no_ano } from '../hooks/useGetTurmaMedia_alunos_por_turma_no_ano';
import { useGetTurmaTurmas_por_projeto_no_ano } from '../hooks/useGetTurmaTurmas_por_projeto_no_ano';
import { useGetTurmaSituacao_das_turmas_no_ano } from '../hooks/useGetTurmaSituacao_das_turmas_no_ano';
import { useGetTurmaEvasao_nas_turma_no_ano } from '../hooks/useGetTurmaEvasao_nas_turma_no_ano';

export function Turma() {
  const { media_alunos_por_turma_no_ano, handleMedia_alunos_por_turma_no_ano } = useGetTurmaMedia_alunos_por_turma_no_ano();
  const { turmas_por_projeto_no_ano, handleTurmas_por_projeto_no_ano } = useGetTurmaTurmas_por_projeto_no_ano();
  const { situacao_das_turmas_no_ano, handleSituacao_das_turmas_no_ano } = useGetTurmaSituacao_das_turmas_no_ano();
  const { evasao_nas_turma_no_ano, handleEvasao_nas_turma_no_ano } = useGetTurmaEvasao_nas_turma_no_ano();

  const years = eachYearOfInterval({ start: new Date(0), end: new Date() }).map((item) => format(new Date(item), "yyyy"));

  const load = (media_alunos_por_turma_no_ano && turmas_por_projeto_no_ano && situacao_das_turmas_no_ano && evasao_nas_turma_no_ano);

  const options_pie = {
    plugins: {
      tooltip: {
        callbacks: {
          label(context: any) {
            return `${context.raw}%`;
          },
        },
      },
    },
  };

  const options_bar = {
    indexAxis: 'y' as const,
    elements: {
      bar: {
        borderWidth: 2,
      },
    },
    responsive: true,
    plugins: {
      legend: {
        position: 'right' as const,
      },
    },
  };

  const options_line = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top' as const,
      },
    },
  };

  console.log(turmas_por_projeto_no_ano);

  return (
    <div>
      {!load ? (
        <div className="d-flex justify-content-center m-5">
          <SpinnerLoading size={50} />
        </div>
      ) : (
        <div>
          {media_alunos_por_turma_no_ano && (
          <div>
            <div className="d-flex align-items-center justify-content-center mt-2">
              <p className="m-0 mr-3 h4">Média de alunos por turma em:</p>
              <select onChange={(e) => handleMedia_alunos_por_turma_no_ano(e.target.value)}>
                {years.map((year) => {
                  if (year === format(new Date(), 'yyyy')) {
                    return <option value={year} selected>{year}</option>;
                  } return <option value={year}>{year}</option>;
                })}
              </select>
            </div>
            <div className="d-flex justify-content-center">
              <div className="w-100">
                <Chart
                  data={media_alunos_por_turma_no_ano}
                  type="line"
                  options={options_line}
                  height={95}
                />
              </div>
            </div>
          </div>
          )}

          {turmas_por_projeto_no_ano && (
          <div>
            <div className="d-flex align-items-center justify-content-center mt-2">
              <p className="m-0 mr-3 h4">Turmas por projeto no ano:</p>
              <select onChange={(e) => handleTurmas_por_projeto_no_ano(e.target.value)}>
                {years.map((year) => {
                  if (year === format(new Date(), 'yyyy')) {
                    return <option value={year} selected>{year}</option>;
                  } return <option value={year}>{year}</option>;
                })}
              </select>
            </div>
            <div className="d-flex justify-content-center">
              <div className="w-100">
                <Chart
                  data={turmas_por_projeto_no_ano}
                  type="bar"
                  options={options_bar}
                  height={95}
                />
              </div>
            </div>
          </div>
          )}

          {situacao_das_turmas_no_ano && (
          <div>
            <div className="d-flex align-items-center justify-content-center mt-2">
              <p className="m-0 mr-3 h4">Situação das turmas no ano:</p>
              <select onChange={(e) => handleSituacao_das_turmas_no_ano(e.target.value)}>
                {years.map((year) => {
                  if (year === format(new Date(), 'yyyy')) {
                    return <option value={year} selected>{year}</option>;
                  } return <option value={year}>{year}</option>;
                })}
              </select>
            </div>
            <div className="d-flex justify-content-center">
              <div className="w-50">
                <Chart
                  data={situacao_das_turmas_no_ano}
                  type="pie"
                  options={options_pie}
                  height={95}
                />
              </div>
            </div>
          </div>
          )}

          {evasao_nas_turma_no_ano && (
          <div>
            <div className="d-flex align-items-center justify-content-center mt-2">
              <p className="m-0 mr-3 h4">Evasão nas turmas no ano:</p>
              <select onChange={(e) => handleEvasao_nas_turma_no_ano(e.target.value)}>
                {years.map((year) => {
                  if (year === format(new Date(), 'yyyy')) {
                    return <option value={year} selected>{year}</option>;
                  } return <option value={year}>{year}</option>;
                })}
              </select>
            </div>
            <div className="d-flex justify-content-center">
              <div className="w-100">
                <Chart
                  data={evasao_nas_turma_no_ano}
                  type="line"
                  options={options_line}
                  height={95}
                />
              </div>
            </div>
          </div>
          )}
        </div>
      )}

    </div>
  );
}
