import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import { IconDetails } from '../../../../components/develop-nilton-components/IconDetails';
import { IconDocument } from '../../../../components/develop-nilton-components/IconDocument';
import { ModalDetailsEgresse } from '../../../../components/develop-nilton-components/ModalDetailsEgresses';
import { Td, Tr } from '../../../../components/develop-nilton-components/TablePadrão';
import { ITbodyGeral } from '../type';

export function TbodyHistorico({ egress, headWidth, index }: ITbodyGeral) {
  const history = useHistory();
  const [isOpen, setIsOpen] = useState(false);

  function handleIsOpen() {
    setIsOpen((prev) => !prev);
  }

  function redirectHistorico() {
    history.push({
      pathname: "/acompanhamento/historico",
      state: egress,
    });
  }
  return (
    <Tr data-testid="table_historico">
      <Td width={headWidth[0]}>{index}</Td>
      <Td width={headWidth[1]}>{egress.nome}</Td>
      <Td width={headWidth[2]}>
        <div className="mr-3" onClick={handleIsOpen}>
          <IconDetails cursor tooltipMessage="Detalhes do egresso" />
        </div>

        <div onClick={redirectHistorico}>
          <IconDocument cursor tooltipMessage="Indicadores individuais" />
        </div>

        <ModalDetailsEgresse egresse={egress} handleIsOpen={handleIsOpen} isOpen={isOpen} />
        <ReactTooltip />
      </Td>
    </Tr>
  );
}
