import React, { useState } from 'react';
import { AbaFilterButtons, AbaFilterContent } from '../../../components/develop-nilton-components/AbaFiltro';
import { SearchEgresses } from '../../../components/develop-nilton-components/SearchEgresses';

import { SectionHeader } from '../../../components/SectionHeader';
import { TableConcluidos } from './components/TableConcluidos';
import { TableGeral } from './components/TableGeral';
import { useGetEgresses } from './Hooks/useGetEgresses';
import { Egresso } from './types';

export function PerfilPsicologicoDoEgresso() {
  const { egresses, handleSetEgresses, load } = useGetEgresses<Egresso>();
  const [tab, setTab] = useState(1);

  return (
    <div style={{ width: "100%" }}>
      <SectionHeader subtitle="Egressos em acompanhamento" />

      <div style={{ width: "100%" }}>
        <SearchEgresses handleSetSearch={handleSetEgresses} />
      </div>

      <AbaFilterButtons buttons={["Geral", "Histórico"]} useStateTab={[tab, setTab]} />

      <AbaFilterContent
        tab={tab}
        components={[
          <TableGeral
            egresses={egresses}
            handleSetEgresses={handleSetEgresses}
            load={load}
          />,
          <TableConcluidos
            egresses={egresses}
            handleSetEgresses={handleSetEgresses}
            load={load}
          />,
        ]}
      />

    </div>
  );
}
