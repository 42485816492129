import { eachMonthOfInterval, eachYearOfInterval, format } from 'date-fns';
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import api from '../../../../services/api';

export function useGetRelatorioPsicopedagogo() {
  const [year, set_year] = useState(format(new Date(), "yyyy"));
  const [trimestre, set_trimestre] = useState<string>("");
  const [typeRelatorio, set_typeRelatorio] = useState<string>("");
  const [month, set_month] = useState('');

  const type_relatorio = [
    { value: "todos", label: "Todos" },
    { value: "intelectual", label: "Fatores intelectuais" },
    { value: "emocional", label: "Fatores emocional" },
    { value: "fisico", label: "Fatores fisico" },
    { value: "queixa", label: "Queixa" },
    { value: "observacao", label: "Observação" },
  ];
  const year_options = eachYearOfInterval({ start: new Date(0), end: new Date() }).map((year) => format(new Date(year), "yyyy")).reverse();
  const trimestral_options = [
    { value: "01-03", label: "Primeiro trimestre" },
    { value: "03-06", label: "Segundo trimestre" },
    { value: "06-09", label: "Terceiro trimestre" },
    { value: "09-12", label: "Quarto trimestre" },
  ];

  const month_options = eachMonthOfInterval({ start: new Date().setMonth(0), end: new Date().setMonth(11) }).map((date) => ({ label: new Date(date).toLocaleDateString("pt-BR", { month: "long" }), value: format(date, "MM") }));

  const [load, setLoad] = useState(false);

  function handleTrimestre() {
    if (Number(format(new Date(), "MM")) < 3) {
      return "01-03";
    }
    if (Number(format(new Date(), "MM")) < 6) {
      return "03-06";
    }
    if (Number(format(new Date(), "MM")) < 9) {
      return "06-09";
    }
    if (Number(format(new Date(), "MM")) < 12) {
      return "09-12";
    }
    return "";
  }

  async function handleGenerateReport(year: string, typeRelatorio: string, trimestre:string, month:string) {
    if (typeRelatorio === "todos") {
      const { data } = await api.get('/reports/generate/pedagogo', {
        params: {
          year,
          intelectual: true,
          emocional: true,
          fisico: true,
          queixa: true,
          observacao: true,
          trimestre,
          month,
        },
      });

      fetch(data.url).then((res) => {
        res.blob().then((blob) => {
          const a = document.createElement("a");
          a.href = URL.createObjectURL(blob);
          a.setAttribute("download", "Relatório geral do egresso");
          a.click();
        });
      });
      return "";
    }
    const { data } = await api.get('/reports/generate/pedagogo', {
      params: {
        year,
        [typeRelatorio]: !!typeRelatorio,
        trimestre,
        month,
      },
    });

    fetch(data.url).then((res) => {
      res.blob().then((blob) => {
        const a = document.createElement("a");
        a.href = URL.createObjectURL(blob);
        a.setAttribute("download", "Relatório geral do egresso");
        a.click();
      });
    });
    return "";
  }

  const handleDownload = async () => {
    setLoad(true);
    if (typeRelatorio === "") {
      toast.error('Escolha o item para gerar o relatórios');
    } else {
      await handleGenerateReport(year, typeRelatorio, (trimestre === "" && month === "") ? handleTrimestre() : trimestre, month);
    }
    setLoad(false);
  };
  return {
    load,
    handleDownload,
    year_options,
    trimestral_options,
    type_relatorio,
    month_options,
    typeRelatorio,
    set_year,
    set_trimestre,
    set_typeRelatorio,
    set_month,
  };
}
