import React, { useState } from 'react';
import { FieldError, useFieldArray, useForm } from 'react-hook-form';
import { ToastContainer, toast } from 'react-toastify';
import { useHistory, useLocation } from 'react-router-dom';
import { SectionHeader } from '../../../../components/SectionHeader';
import {
  ObjetivoField,
  OrigemRecursosField,
  ParceiroField,
  ValorEnvolvidoField,
  SelectSala,
  SelectLaboratorio,
  MaterialValues,
  MaterialField,
  NameField,
} from '../../CadastrarProjeto/components';

import {
  Projeto,
  OptionsLaboratorio,
  OptionsMaterial,
  OptionsSalaDeAula,
  IProjeto,
} from '../../CadastrarProjeto/hooks/Model';

import { submitEditProjeto, submitProjeto } from '../../CadastrarProjeto/hooks/functions/SubmitProjeto';

import {
  SelectField,
  Button,
  Form,
} from '../../CadastrarProjeto/styles';

import { SpinnerLoading } from '../../../../components/SpinnerLoading';
import { useFormValidate } from '../../../../components/develop-nilton-components/hooks/useFormValidate';
import { IProjetoEdit } from '../../CadastrarProjeto/hooks/Model/Projeto/type';
import { DateField } from '../../../../components/develop-nilton-components/DateField';

export function EditProjeto() {
  const history = useHistory();

  const { state } = useLocation<IProjetoEdit>();

  const {
    register, handleSubmit, formState, control, setValue,
  } = useForm({
    defaultValues: {
      ...state,
    },
  });
  const { append, fields, remove } = useFieldArray({ control, name: "materials" });
  const [load, isLoad] = useState(false);

  // console.log(state.materials, fields);

  const optionsLaboratorio = OptionsLaboratorio();
  const optionsSala = OptionsSalaDeAula();
  const optionMaterial = OptionsMaterial({ fields });

  async function onSubmit(data: IProjetoEdit) {
    isLoad(true);
    const res = await submitEditProjeto(data);

    if (res === "success") {
      history.push("/gerir-projetos");
    }

    isLoad(false);
  }

  const formValues = Object.keys(formState.errors);

  const formStateErrors = Object.values(formState.errors) as (FieldError | undefined)[];
  const { formIsValidated } = useFormValidate({ formStateErrors, formValues });

  return (
    <div style={{ width: "100%", marginBottom: "30px" }}>
      <SectionHeader subtitle="Cadastrar projeto pedagógico" backPage="/gerir-projetos" />

      <Form onSubmit={handleSubmit(onSubmit)} className={formIsValidated} noValidate>
        <NameField
          register={register}
          formState={formState}
          label="Nome do projeto"
          name="nome"
          placeholder="Digite o nome do projeto..."
        />

        <div className="d-flex">
          <div className="mr-4">
            <DateField
              formState={formState}
              name="data_inicio"
              register={register}
              label="Início"
              // disable
            />
          </div>
          <DateField
            formState={formState}
            name="data_fim"
            register={register}
            label="Término"
          />
        </div>

        <OrigemRecursosField
          register={register}
          formState={formState}
          placeholder="Digite a origem do recurso..."
        />

        <ValorEnvolvidoField
          register={register}
          formState={formState}
          placeholder="Digite o valor envolvido..."
        />

        <ParceiroField
          register={register}
          formState={formState}
          placeholder="Digite o nome do parceiro..."
        />

        <ObjetivoField
          register={register}
          formState={formState}
          placeholder="Digite o objetivo..."
        />

        <SelectField>
          <h2>Recursos necessários</h2>
          <SelectLaboratorio
            formState={formState}
            options={optionsLaboratorio}
            name="laboratorio"
            register={register}
          />
          <SelectSala
            formState={formState}
            options={optionsSala}
            name="sala_aula"
            register={register}
          />
        </SelectField>

        <div>
          <h2 style={{ fontSize: "24px", fontWeight: 700 }}>Selecionar materiais</h2>
          <MaterialField append={append} options={optionMaterial} fields={fields} />
        </div>

        <MaterialValues fields={fields} remove={remove} setValue={setValue} />

        <div style={{ display: "flex", justifyContent: "center", margin: 50 }}>
          <Button type="submit" className="btn btn-primary btn-lg">
            {load ? <SpinnerLoading /> : "Editar"}
          </Button>
        </div>
      </Form>

      <ToastContainer />
    </div>
  );
}
