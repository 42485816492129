/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import { FieldError, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { BsFillXCircleFill } from 'react-icons/bs';
import { SectionHeader } from '../../../components/SectionHeader';
import { InputField } from '../../../components/develop-nilton-components/inputField';
import { data } from '../GerirTurma/types';
import { useFormValidate } from '../../../components/develop-nilton-components/hooks/useFormValidate';
import { DateField } from '../../../components/develop-nilton-components/DateField';
import { useGetProjetosOptions } from './hooks/useGetProjetosOptions';
import { SearchEgresses } from '../../../components/develop-nilton-components/SearchEgresses';
import { useGetSearchEgresses } from './hooks/useGetSearchEgresses';

import { TableGeral } from './components/TableGeral';
import { AbaFilterButtons, AbaFilterContent } from '../../../components/develop-nilton-components/AbaFiltro';
import { TableAdicionado } from './components/TableAdicionado';
import api from '../../../services/api';
import { SpinnerLoading } from '../../../components/SpinnerLoading';
import { InputAdd } from '../../../components/develop-nilton-components/InputAdd';
import { IconDetails } from '../../../components/develop-nilton-components/IconDetails';
import { ModalDetalhesProjeto } from '../GerirProjeto/components/ModalDetalhesProjeto';
import { TablePadrão, Td, Tr } from '../../../components/develop-nilton-components/TablePadrão';

const CadCurso = () => {
  const { register, formState, handleSubmit } = useForm<data>();
  const formStateErrors = Object.values(formState.errors) as (FieldError | undefined)[];
  const formValues = Object.keys(formState.errors);
  const history = useHistory();
  const [responseLoad, setResponseLoad] = useState(false);

  const { formIsValidated } = useFormValidate({ formStateErrors, formValues });

  const checkKeyDown = (e:any) => {
    if (e.code === 'Enter') e.preventDefault();
  };

  const [addedEgresses, setAddedEgresses] = useState<number[]>([]);
  const onSubmit = async (data: data) => {
    setResponseLoad(true);
    try {
      await api.post('/courses', {
        ...data,
        projects: projectAdded.map((project:any) => ({ id: project.id })),
        egresses: addedEgresses.map((egress_id) => ({ id: egress_id })),
      });
      toast.success("Curso cadastrado com sucesso");
      history.push("/gerir-turmas");
    } catch (error) {
      // @ts-ignore
      toast.error(error.response.data.message);
    }
    setResponseLoad(false);
  };

  const { load, projetoOptions } = useGetProjetosOptions<any>();
  const [allEgresses, setAllEgresses] = useState<any>(null);
  const [AllEgress_load, set_AllEgress_load] = useState<any>(true);

  const { egresses, handleSetOptionsEgrsses } = useGetSearchEgresses();

  const [tab, setTab] = useState(1);

  function addEgresses(egress_id: number) {
    const alreadyInCourse = allEgresses.find((egress:any) => egress.id === egress_id);

    if (alreadyInCourse.cursos?.pendentes.length === 0 && alreadyInCourse.cursos.em_andamento.length === 0) {
      setAddedEgresses((prev) => [...prev, egress_id]);
    } else toast.error('Egresso já está cadastrado em outra turma.');
  }
  function RemoveEgresses(egress_id: number) {
    setAddedEgresses((prev) => prev.filter((item) => (item !== egress_id)));
  }

  const filterEgressesAdicionado = egresses?.data?.filter((item:any) => {
    const itemDuplicated = addedEgresses.find((added) => added === item.id);
    if (itemDuplicated) {
      return true;
    } return false;
  });
  const filterEgressesGeral = egresses?.data?.filter((item:any) => {
    const itemDuplicated = addedEgresses.find((added) => added === item.id);
    if (itemDuplicated) {
      return false;
    } return true;
  });

  const [projectAdded, setProjectAdded] = useState<any[]>([]);
  const [isOpenModalProject, setIsOpenModalProject] = useState(false);

  function handleSetIsOpenModalProject() {
    setIsOpenModalProject((prev) => !prev);
  }

  function inputAddFunction(inputValue: string) {
    const data = projetoOptions?.find((project:any) => project.nome === inputValue);
    if (data) {
      if (projectAdded.find((project:any) => project.nome === inputValue)) {
        toast.info("Projeto já adicionado.");
      } else {
        setProjectAdded((prev:any) => [...prev, data]);
      }
    } else {
      toast.error('Projeto não cadastrado. Procure outro projeto.');
    }
  }

  function removeProjectList(inputValue: string) {
    const filterProjects = projectAdded.filter((project) => project.nome !== inputValue);

    setProjectAdded(filterProjects);
  }

  useEffect(() => {
    api.get('/egresses', { params: { nopaginate: true } })
      .then(({ data }) => {
        setAllEgresses(data);
        set_AllEgress_load(false);
      });
  }, []);

  return (
    <div className="w-100">
      <SectionHeader subtitle="Cadastrar Turma" backPage="/gerir-turmas" />

      {AllEgress_load ? (
        <div className="d-flex justify-content-center m-5"><SpinnerLoading /></div>
      ) : (
        <Form
          onSubmit={handleSubmit(onSubmit)}
          noValidate
          className={formIsValidated}
          onKeyDown={checkKeyDown}
        >
          <InputField
            label="Nome da turma"
            placeholder="Digite o nome do turma"
            register={register}
            name="title"
            formState={formState}
            size="lg"
          />

          <div className="d-flex">
            <DateField
              label="Início"
              register={register}
              name="data_inicio"
              formState={formState}
              size="lg"
            />
            <DateField
              label="Término"
              register={register}
              name="data_fim"
              formState={formState}
              size="lg"
            />
          </div>
          <InputField
            label="Coordenador"
            placeholder="Digite o nome do coordenador"
            register={register}
            name="coordenador"
            formState={formState}
            size="lg"
          />
          {/* <SelectField
          formState={formState}
          name="project_id"
          options={projetoOptions}
          register={register}
          load={load}
          label="Projeto vinculado"
          description="Escolha entre os projetos cadastrados disponíveis, ou comece a digitar e clique no botão adicionar"
        /> */}

          <InputAdd
            options={projetoOptions?.map((project: any) => project.nome as string)}
            functionAdd={inputAddFunction}
            label="Adicionar projeto"
            description="Escolha entre os cadastrados disponíveis e clique no botão adicionar"
          />

          {projectAdded.length !== 0 && (
          <div>
            <h4 className="text-primary m-0 mb-2">Projetos adicionados</h4>
            <TablePadrão
              headWidth={["5%", "50%", "45%"]}
              thead={["#", "Nome do projeto", "Opções"]}
            >
              {projectAdded.map((project, i) => (
                <Tr>
                  <Td width="5%">{i + 1}</Td>
                  <Td width="50%">{project.nome}</Td>
                  <Td width="45%">
                    <div className="d-flex">
                      <div className="mr-3" onClick={handleSetIsOpenModalProject}>
                        <IconDetails cursor />
                      </div>
                      <ModalDetalhesProjeto projeto={project} isOpen={isOpenModalProject} handleIsOpen={handleSetIsOpenModalProject} />
                      <div onClick={() => removeProjectList(project.nome)}>
                        <BsFillXCircleFill
                          color="rgba(242, 78, 30, 1)"
                          size={30}
                          style={{ cursor: "pointer" }}
                        />
                      </div>
                    </div>
                  </Td>
                </Tr>
              ))}
            </TablePadrão>
          </div>
          )}

          <SearchEgresses
            handleSetSearch={handleSetOptionsEgrsses}
          />

          <div>
            <AbaFilterButtons buttons={["Geral", "Adicionados"]} useStateTab={[tab, setTab]} />

            <AbaFilterContent
              tab={tab}
              components={[
                <TableGeral
                  data={filterEgressesGeral}
                  meta={egresses?.meta}
                  handleUpdate={handleSetOptionsEgrsses}
                  load={load}
                  addEgresses={addEgresses}
                />,
                <TableAdicionado
                  data={filterEgressesAdicionado}
                  meta={egresses?.meta}
                  handleUpdate={handleSetOptionsEgrsses}
                  load={load}
                  addEgresses={RemoveEgresses}
                />,
              ]}
            />

          </div>

          <div className="d-flex justify-content-center w-100 mb-5">
            <button className="btn btn-primary btn-lg">{responseLoad ? <SpinnerLoading /> : "Finalizar"}</button>
          </div>
        </Form>
      )}

    </div>
  );
};

export default CadCurso;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;
