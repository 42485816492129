import { IProjeto } from './type';

export function Projeto(props?: IProjeto) {
  if (!props) {
    return {
      data_fim: null,
      data_inicio: null,
      laboratorio: null,
      materials: [],
      nome: null,
      objetivo: null,
      origem_recurso: null,
      parceiro: null,
      sala_aula: null,
      valor_envolvido: null,
    };
  }
  return props;
}
