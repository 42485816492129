import { format } from 'date-fns';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import ReactTooltip from 'react-tooltip';
import { Badge } from '../../../../components/develop-nilton-components/Badge';
import { IconDetails } from '../../../../components/develop-nilton-components/IconDetails';
import { IconEdit } from '../../../../components/develop-nilton-components/IconEdit';
import { IconRemove } from '../../../../components/develop-nilton-components/IconRemove';
import { ModalDelete } from '../../../../components/develop-nilton-components/ModalDelete';
import { Td, Tr } from '../../../../components/develop-nilton-components/TablePadrão';
import { SpinnerLoading } from '../../../../components/SpinnerLoading';
import api from '../../../../services/api';
import { data, PropsTbody } from '../types';
import { ModalTurmas } from './ModalTurmas';

export function TbodyPendente({
  curso, headWidth, index, handleUpdate, meta,
}: PropsTbody) {
  const history = useHistory();
  const [loadFinalizar, setLoadFinalizar] = useState(false);
  const [isOpenModalDetalhes, setIsOpenModalDetalhes] = useState(false);
  const [isOpenModalDelete, setIsOpenModalDelete] = useState(false);

  function handleIsOpen() {
    setIsOpenModalDetalhes(!isOpenModalDetalhes);
  }
  function handleRedirectEdit() {
    history.push({
      pathname: "/gerir-turmas/editar",
      state: {
        id: curso.id,
        data_fim: format(new Date(curso.data_fim), "yyyy-MM-dd"),
        data_inicio: format(new Date(curso.data_inicio), "yyyy-MM-dd"),
        coordenador: curso.coordenador,
        projetos: curso.projetos,
        egresses: curso.egressos.map((item) => ({ id: item.egress_id })),
        title: curso.title,
      },
    });
  }

  async function finalizar() {
    setLoadFinalizar(true);
    try {
      await api.put(`/courses/${curso.id}`, { cancelado: "T" });
      toast.success("Curso cancelado com sucesso");
      await handleUpdate(meta.current_page);
    } catch (error) {
      // @ts-ignore
      toast.success(error.response.data.message);
    }
    setLoadFinalizar(false);
  }

  function handleSetIsOpenModalDelete() {
    setIsOpenModalDelete((prev) => !prev);
  }

  return (
    <Tr>
      <Td width={headWidth[0]}>{index}</Td>
      <Td width={headWidth[1]}>{curso.title}</Td>
      <Td width={headWidth[2]}>
        <div>
          <Badge type="info">{curso.situacao}</Badge>
        </div>
      </Td>
      <Td width={headWidth[3]}>
        <div className="d-flex justify-content-between align-items-center w-75">
          <div onClick={handleIsOpen}>
            <IconDetails cursor tooltipMessage="Detalhes do curso" />
          </div>
          <ModalTurmas
            handleIsOpen={handleIsOpen}
            isOpen={isOpenModalDetalhes}
            turmas={curso}
          />
          <div onClick={handleRedirectEdit}>
            <IconEdit color="text-warning" cursor tooltipMessage="Editar" />
          </div>
          {loadFinalizar ? (
            <div>
              <IconRemove
                color="text-danger"
                cursor
                tooltipMessage="Cancelar turma"
              />
            </div>
          ) : (
            <div onClick={handleSetIsOpenModalDelete}>
              <IconRemove
                color="text-danger"
                cursor
                tooltipMessage="Cancelar turma"
              />
            </div>
          )}
        </div>
        <ReactTooltip />
        <ModalDelete isOpen={isOpenModalDelete} handleIsOpen={handleSetIsOpenModalDelete} deleteFunction={finalizar}>
          Confirmar exclusão?
        </ModalDelete>
      </Td>
    </Tr>
  );
}
