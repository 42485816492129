/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from 'react';
import styled from 'styled-components';
import { useGetEgressesOptions } from '../../pages/Pedagogo/CadCurso/hooks/useGetEgressesOptions';
import { SpinnerLoading } from '../SpinnerLoading';

type props = {
  // eslint-disable-next-line no-unused-vars
  handleSetSearch(page?:number, search?: string): Promise<void>
  button?: React.ReactNode
}

export function SearchEgresses({ handleSetSearch, button }:props) {
  const { optionsEgresses } = useGetEgressesOptions();
  const [inputValue, setInputValue] = useState("");
  const [focus, setFocus] = useState(false);

  const optionsFilter = optionsEgresses
  && optionsEgresses.filter((item) => {
    if (item) {
      return item.toLowerCase().includes(inputValue.toLowerCase());
    } return false;
  });

  function handleInputValue(e: any) {
    setInputValue(e.target.value);
    // if (e.target.value === "") {
    //   return setFocus(false);
    // }
    return setFocus(true);
  }

  function handleFocus() {
    // if (inputValue === "") {
    //   return setFocus(false);
    // }
    return setFocus(true);
  }

  function handleOnblur() {
    setTimeout(() => {
      setFocus(false);
    }, 150);
  }

  function handleKey(e: any) {
    if (e.key === "Enter") {
      handleSetSearch(1, inputValue);
      handleOnblur();
    }
  }
  return (
    <div className="w-100">
      <Label htmlFor="">Pesquisar Egresso</Label>
      <WrapperInputAndButton>
        <div className="position-relative w-100">
          <input
            className="form-control form-control-lg"
            onChange={handleInputValue}
            placeholder="Digite o nome do egresso"
            onFocus={handleFocus}
            onBlur={handleOnblur}
            value={inputValue}
            onKeyUp={handleKey}
          />
          {focus && (
          <ContainerOptions>
            {!optionsFilter && (
              <div className="d-flex justify-content-center"><SpinnerLoading size={10} /></div>
            )}
            {optionsFilter && optionsFilter?.map((item, i) => (
              <Option
                key={i}
                onClick={() => {
                  setInputValue(item);
                  handleSetSearch(1, item);
                }}
              >
                <p>{item}</p>
              </Option>
            ))}
          </ContainerOptions>
          )}
        </div>
        {button || (
          <ButtonWrapper
            type="button"
            onClick={() => handleSetSearch(1, inputValue)}
            className="btn btn-primary btn-lg"
          >
            Pesquisar
          </ButtonWrapper>
        )}

      </WrapperInputAndButton>
      {button ? <div style={{ marginBottom: 10 }} /> : <p className="text-secondary">Após digitar uma pista, clique no botão pesquisar e visualize dados do egresso</p>}
    </div>
  );
}

const Label = styled.label`
  font-size: 23px;
  font-weight: 700;
`;

const WrapperInputAndButton = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  width: 100%;
`;

const ContainerOptions = styled.div`
  width: 100%;
  max-height: 250px;
  overflow: auto;
  border: gray solid 1px;
  position: absolute;
  background-color: white;
`;

const Option = styled.div`
  font-size: 18px;
  padding: 10px;
  background-color: white;
  cursor: pointer;
  :hover {
    background-color: rgb(130, 200, 255);
  }
`;

const ButtonWrapper = styled.button`
  height:50px;

  @media (max-width: 1024px) {
    font-size: 16px;
    height: 40px;
  }
`;
