import { format } from 'date-fns';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import ReactTooltip from 'react-tooltip';
import { Badge } from '../../../../components/develop-nilton-components/Badge';
import { IconAdd } from '../../../../components/develop-nilton-components/IconAdd';
import { IconDetails } from '../../../../components/develop-nilton-components/IconDetails';
import { IconEdit } from '../../../../components/develop-nilton-components/IconEdit';
import { IconRemove } from '../../../../components/develop-nilton-components/IconRemove';
import { ModalDelete } from '../../../../components/develop-nilton-components/ModalDelete';
import { Td, Tr } from '../../../../components/develop-nilton-components/TablePadrão';
import { SpinnerLoading } from '../../../../components/SpinnerLoading';
import api from '../../../../services/api';
import { Tbody } from '../types';
import { ModalDetalhesProjeto } from './ModalDetalhesProjeto';

export function TbodyPendentes({
  headWidth, projetoItem, index, handleProjetos, meta,
}:Tbody) {
  const history = useHistory();
  const [detalhesIsOpen, setDetalhesIsOpen] = useState(false);
  const [removeIsOpen, setRemoveIsOpen] = useState(false);
  const [removed, isRemoved] = useState(false);

  function handleDetalhesIsOpen() {
    setDetalhesIsOpen(!detalhesIsOpen);
  }

  const handleRedirectEdit = () => {
    history.push({
      state: {
        id: projetoItem.id,
        data_fim: projetoItem.data_fim ? format(new Date(projetoItem.data_fim), "yyyy-MM-dd") : null,
        data_inicio: projetoItem.data_inicio ? format(new Date(projetoItem.data_inicio), "yyyy-MM-dd") : null,
        laboratorio: projetoItem.laboratorio,
        nome: projetoItem.nome,
        objetivo: projetoItem.objetivo,
        origem_recurso: projetoItem.origem_recurso,
        parceiro: projetoItem.parceiro,
        sala_aula: projetoItem.sala_aula,
        valor_envolvido: projetoItem.valor_envolvido,
        materials: projetoItem.materiais.map((item) => ({
          qtd: item.qtd,
          material: {
            title: item.material.title,
            id: item.material.id,
          },
        })),
      },
      pathname: "/gerir-projetos/editar",
    });
  };

  const handleRemove = async () => {
    isRemoved(true);
    try {
      await api.delete(`/projects/${projetoItem.id}`);
      await handleProjetos(meta.current_page);
      toast.success("Removido com sucesso.");
    } catch (error) {
      toast.error("Erro ao remover projeto.");
    }
    isRemoved(false);
  };

  const handleRemoveIsOpen = () => {
    setRemoveIsOpen((prev) => !prev);
  };

  return (
    <Tr>
      <Td width={headWidth[0]}>{index}</Td>
      <Td width={headWidth[1]}>{projetoItem.nome}</Td>
      <Td width={headWidth[2]}>
        <Badge type="primary">{projetoItem.situacao}</Badge>

      </Td>
      <Td width={headWidth[3]}>
        <div className="d-flex justify-content-between w-50">
          <div onClick={handleDetalhesIsOpen}>
            <IconDetails cursor size={22} tooltipMessage="Detalhes" />
          </div>
          <ModalDetalhesProjeto
            isOpen={detalhesIsOpen}
            handleIsOpen={handleDetalhesIsOpen}
            projeto={projetoItem}
          />

          <div>
            <div onClick={handleRedirectEdit}>

              <IconEdit
                color="text-warning"
                cursor
                size={22}
                tooltipMessage="Editar"
              />
            </div>
          </div>

          <div>
            <div onClick={() => {
              if (!removed) {
                handleRemoveIsOpen();
              }
            }}
            >
              {removed ? <SpinnerLoading /> : (
                <IconRemove
                  color="text-danger"
                  cursor
                  size={22}
                  tooltipMessage="Excluir"
                />
              )}
            </div>
            <ModalDelete
              isOpen={removeIsOpen}
              deleteFunction={handleRemove}
              handleIsOpen={handleRemoveIsOpen}
            >
              Confirmar exclusão do projeto

            </ModalDelete>
          </div>
        </div>
        <ReactTooltip />
      </Td>
    </Tr>
  );
}
