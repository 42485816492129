import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { ArrayFormValues, FormAcompanhamentos } from '../../../../components/develop-nilton-components/FormAcompanhamentos';
import { SpinnerLoading } from '../../../../components/SpinnerLoading';

import api from '../../../../services/api';
import { IFatores } from '../type';

export function AspectosSocias({
  getValues, setValue, setTab,
}: IFatores) {
  const arrayFormValues: ArrayFormValues["formValues"] = [
    {
      label: "1. Qual a composição do núcleo familiar?",
      type: "familiar",
      name: "composicao_nucleo_familiar",
      onChange: (e:any) => {
        set_composicao_nucleo_familiar(e.target.value);
        setValue('composicao_nucleo_familiar', e.target.value);
      },
    },
    {
      label: "2. Identifica-se com alguma tradição religiosa?",
      type: "tradicao",
      name: "tradicao_religiosa",
      especificacao: 'especificacao_tradicao_religiosa',
      onChange: (e:any) => {
        setValue('tradicao_religiosa', e.target.value);
        set_tradicao_religiosa(e.target.value);
      },
      onChangeEspecificacao: (e:any) => {
        setValue("especificacao_tradicao_religiosa", e.target.value);
      },
    },
    {
      label: "3. Tipo de moradia?",
      type: "moradia",
      name: "tipo_moradia",
      onChange: (e:any) => {
        set_tipo_moradia(e.target.value);
        setValue('tipo_moradia', e.target.value);
      },
    },
    {
      label: "4. Tipo de zona",
      type: "zona",
      name: "tipo_zona",
      onChange: (e:any) => {
        set_tipo_zona(e.target.value);
        setValue('tipo_zona', e.target.value);
      },
    },
    {
      label: "5. Considera o local que mora violento?",
      type: "localViolento",
      name: "local_violento",
      especificacao: 'especificacao_local_violento',
      onChange: (e:any) => {
        setValue('local_violento', e.target.value);
        set_local_violento(e.target.value);
      },
      onChangeEspecificacao: (e:any) => {
        setValue("especificacao_local_violento", e.target.value);
      },
    },
    {
      label: "6. Faz uso de bebida alcoólica?",
      type: "bebida",
      name: "uso_bebida",
      especificacao: 'frequencia_uso_bebida',
      onChange: (e:any) => {
        setValue('uso_bebida', e.target.value);
        set_uso_bebida(e.target.value);
      },
      onChangeEspecificacao: (e:any) => {
        setValue("frequencia_uso_bebida", e.target.value);
      },
    },
    {
      label: "7. Apresenta alguma deficiêcia ou necessidade educacional especial?",
      type: "necessidadeEspecial",
      name: "deficiencia_ou_necessidade",
      especificacao: 'especificacao_deficiencia_ou_necessidade',
      onChange: (e:any) => {
        setValue('deficiencia_ou_necessidade', e.target.value);
        set_deficiencia_ou_necessidade(e.target.value);
      },
      onChangeEspecificacao: (e:any) => {
        setValue("especificacao_deficiencia_ou_necessidade", e.target.value);
      },
    },
    {
      label: "8. Possui algum membro da família em vulnerabilidade social?",
      type: "vulnerabilidadeSocial",
      name: "membro_com_vulnerabilidade",
      especificacao: 'especificacao_membro_com_vulnerabilidade',
      onChange: (e:any) => {
        setValue('membro_com_vulnerabilidade', e.target.value);
        set_membro_com_vulnerabilidade(e.target.value);
      },
      onChangeEspecificacao: (e:any) => {
        setValue('especificacao_membro_com_vulnerabilidade', e.target.value);
      },
    },
  ];

  const [load, setLoad] = useState(false);

  const onSubmit = async (data:any) => {
    setLoad(true);
    try {
      await api.put(`/social/${getValues().id}`, {
        composicao_nucleo_familiar: getValues().composicao_nucleo_familiar,
        deficiencia_ou_necessidade: getValues().deficiencia_ou_necessidade,
        especificacao_deficiencia_ou_necessidade: getValues().especificacao_deficiencia_ou_necessidade,
        especificacao_local_violento: getValues().especificacao_local_violento,
        especificacao_tradicao_religiosa: getValues().tradicao_religiosa === "Sim" ? getValues().especificacao_tradicao_religiosa : null,
        frequencia_uso_bebida: getValues().frequencia_uso_bebida,
        local_violento: getValues().local_violento,
        membro_com_vulnerabilidade: getValues().membro_com_vulnerabilidade,
        tipo_moradia: getValues().tipo_moradia,
        tipo_zona: getValues().tipo_zona,
        tradicao_religiosa: getValues().tradicao_religiosa,
        uso_bebida: getValues().uso_bebida,
      });
      setTab(2);
      window.scrollTo({ top: 0 });
    } catch (error) {
      // @ts-ignore
      toast.error(error.response.data.message);
    }
    setLoad(false);
  };

  const onSubmitError = (errors:any) => {
    const error = Object.keys(errors);
    for (let i = 0; i < error.length; i + 1) {
      toast.error(errors[error[i] as keyof any]?.message);
      break;
    }
  };

  console.log(getValues().tradicao_religiosa);

  const [composicao_nucleo_familiar, set_composicao_nucleo_familiar] = useState(getValues().composicao_nucleo_familiar || "");
  const [tipo_moradia, set_tipo_moradia] = useState(getValues().tipo_moradia || "");
  const [tipo_zona, set_tipo_zona] = useState(getValues().tipo_zona || "");
  const [tradicao_religiosa, set_tradicao_religiosa] = useState(getValues().tradicao_religiosa || "");
  const [local_violento, set_local_violento] = useState(getValues().local_violento || "");
  const [uso_bebida, set_uso_bebida] = useState(getValues().uso_bebida || "");
  const [deficiencia_ou_necessidade, set_deficiencia_ou_necessidade] = useState(getValues().deficiencia_ou_necessidade || "");
  const [membro_com_vulnerabilidade, set_membro_com_vulnerabilidade] = useState(getValues().membro_com_vulnerabilidade || "");
  const { register, handleSubmit } = useForm({
    defaultValues: {
      ...getValues(),
    },
  });

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit, onSubmitError)}>
        <div>
          <FormAcompanhamentos
            formValues={arrayFormValues}
            register={register}
            keysEspecificacao={{
              tradicao_religiosa,
              local_violento,
              uso_bebida,
              deficiencia_ou_necessidade,
              membro_com_vulnerabilidade,
            }}
          />
        </div>
        <div className="d-flex justify-content-between mt-5 mb-5 w-75">
          {load ? (
            <button
              className="btn btn-primary btn-lg"
              type="button"
            >
              <SpinnerLoading />
            </button>
          ) : (
            <button
              className="btn btn-primary btn-lg"
              type="submit"
              data-testid="next-2"
            >
              Avançar
            </button>
          )}
        </div>
      </form>
    </div>
  );
}
