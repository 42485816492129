import React, { useEffect, useState } from 'react';
import api from '../../../../services/api';

export function useCursosAndamento<T>() {
  const [cursos, setCursos] = useState<T | null>(null);
  const [load, setLoad] = useState(false);

  async function handleSetCursosAndamento(page = 1, search?: string) {
    setLoad(true);
    const { data } = await api.get('/courses', {
      params: {
        page,
        title: search,
        status: 'Em andamento',
      },
    });
    if (data.data.length === 0 && page > 1) {
      handleSetCursosAndamento(page - 1);
      setCursos(data);
    } else {
      setCursos(data);
    }
    setLoad(false);
  }

  useEffect(() => {
    handleSetCursosAndamento();
  }, []);
  return {
    cursos, handleSetCursosAndamento, load,
  };
}
