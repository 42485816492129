import React from 'react';
import { FormState, UseFormRegister } from 'react-hook-form';
import { IProjeto } from '../hooks/Model/Projeto/type';
import { InputField } from '../styles/InputField';
import { Errors } from './Erro';

type INameField = {
    register: UseFormRegister<any>
    formState: FormState<any>
}

export function DateField({ register, formState }:INameField) {
  return (
    <div style={{ display: "flex", width: "40%", gap: "10px" }}>
      <InputField>
        <label htmlFor="">Início</label>
        <div className="flex-column">
          <input
            autoComplete="off"
            type="date"
            {...register("data_inicio", { required: true })}
            className="form-control form-control-lg"
            required
          />
          {formState.errors.data_inicio && (
          <Errors />
          )}
        </div>
      </InputField>
      <InputField>
        <label htmlFor="">Término</label>
        <div className="flex-column">
          <input
            type="date"
            {...register("data_fim", { required: true })}
            className="form-control form-control-lg"
            placeholder="Digite o nome do projeto"
            required
          />
          {formState.errors.data_fim && (
          <Errors />
          )}
        </div>
      </InputField>
    </div>
  );
}
