import React, { useCallback } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import api from '../services/api';
import { IEgresses } from '../types/IEgresses';
import {
  EgressesAxiosResponse,
  useGetEgresses,
} from './Egresses/useGetEgresses';

type ProviderProps = {
  children: React.ReactNode;
};

type IEgressesContext = {
  egresses: EgressesAxiosResponse | undefined;
  enableEgresse: (egresse: IEgresses) => Promise<void>;
  updateEgresse: (egresse: IEgresses) => Promise<void>;
  createEgresse: (egresse: IEgresses, callback?: () => void) => Promise<void>;
  page: number;
  handlePage: (number: number) => void;
  handleReload: () => void;
  handleSearch: (string: string) => void;
  handleHabilitado: (value: any) => void;
  search: string;
  habilitado: string;
  load: boolean;
};

const EgressesContext = React.createContext({} as IEgressesContext);

export function EgressesProvider({ children }: ProviderProps) {
  const [page, setPage] = React.useState(1);
  const [search, setSearch] = React.useState('');
  const [habilitado, setHabilitado] = React.useState<any>(undefined);

  const [reload, setReload] = React.useState(false);
  const [load, setLoad] = React.useState(false);

  const queryClient = useQueryClient();

  const { data: egresses } = useQuery<EgressesAxiosResponse>(
    ['egresses', page, search, habilitado, reload],
    async () => {
      const { data } = await api.get('/egresses', {
        params: {
          habilitado,
          // nameOrcpfOrhabilidade: search,
          habilidadeOrNameOrAreaOrBairroOrLocalidadeOrMunicipio: search,
          page,
          perPage: 15,
        },
      });

      setLoad(false);
      return data as EgressesAxiosResponse;
    },
  );

  const handlePage = (number: number) => {
    setPage(number);
    // queryClient.invalidateQueries();
    setLoad(true);
  };

  const handleSearch = (string: string) => {
    setSearch(string);
    setPage(1);
    // queryClient.invalidateQueries();
    setLoad(true);
  };

  const handleHabilitado = (value: any) => {
    setHabilitado(value);
    setPage(1);
    // queryClient.invalidateQueries();
    setLoad(true);
  };

  const handleReload = () => {
    setHabilitado(undefined);
    setPage(1);
    setReload(!reload);
    setLoad(true);
  };

  const createEgresse = useCallback(
    async (egresse: IEgresses, callback?: () => void) => {
      try {
        const formData = new FormData();

        formData.append(
          'enderecos',
          JSON.stringify(
            egresse?.enderecos?.map((item: any) => {
              delete item.id;
              return item;
            }) || [],
          ),
        );

        delete egresse?.enderecos;
        formData.append('data', JSON.stringify(egresse));

        if (egresse.avatar) {
          formData.append('avatar', egresse.avatar);
        }

        await api.post(`/egresses`, formData);

        queryClient.refetchQueries();
        callback?.();
      } catch (error: any) {
        // toast.error(error.response.data.message);
        throw error.response.data.message;
      }
    },
    [queryClient, egresses?.data, egresses?.meta],
  );

  const enableEgresse = useCallback(
    async (egresse: IEgresses) => {
      try {
        const formData = new FormData();

        formData.append(
          'data',
          egresse?.habilitado === 'T'
            ? JSON.stringify({ habilitado: 'F' })
            : JSON.stringify({ habilitado: 'T' }),
        );

        await api.put(`/egresses/${egresse?.id}`, formData);

        // queryClient.invalidateQueries(['egresses', search, page]);
        queryClient.setQueryData(['egresses', search, page], {
          meta: egresses?.meta,
          data: egresses?.data.map(egress => {
            if (Number(egress.id) === Number(egresse.id)) {
              return {
                ...egress,
                habilitado: egress?.habilitado === 'T' ? 'F' : 'T',
              };
            }
            return egress;
          }),
        });
        // queryClient.refetchQueries();
      } catch (error: any) {
        toast.error(error.response.data.message);
      }
    },
    [habilitado, queryClient, search, page, egresses?.meta, egresses?.data],
  );

  const updateEgresse = useCallback(
    async (egresse: IEgresses) => {
      try {
        const formData = new FormData();

        formData.append('data', JSON.stringify(egresse));

        /* formData.append(
        'enderecos', JSON.stringify(egresse?.enderecos || []),
      ); */

        if (egresse.avatar) {
          formData.append('avatar', egresse.avatar);
        }

        const { data } = await api.put(`/egresses/${egresse?.id}`, formData);

        queryClient.setQueryData(['egresses', page, search], {
          meta: egresses?.meta,
          data: egresses?.data.map(egress => {
            if (Number(egress.id) === Number(egresse.id)) {
              return { ...egress, ...data };
            }
            return egress;
          }),
        });
        queryClient.refetchQueries();
      } catch (error: any) {
        // toast.error(error.response.data.message);
        throw error.response.data.message;
      }
    },
    [queryClient, page, search, egresses?.data, egresses?.meta],
  );

  return (
    <EgressesContext.Provider
      value={{
        egresses: load
          ? ({ ...egresses, data: [] } as EgressesAxiosResponse)
          : egresses,
        enableEgresse,
        updateEgresse,
        createEgresse,
        handlePage,
        handleReload,
        page,
        handleSearch,
        handleHabilitado,
        search,
        habilitado,
        load,
      }}
    >
      {children}
    </EgressesContext.Provider>
  );
}

export function useEgresses(): IEgressesContext {
  const context = React.useContext(EgressesContext);

  if (!context) {
    throw new Error('useEgresses must be used within an EgressesContext');
  }

  return context;
}
