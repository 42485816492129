import React, { useState, useEffect } from 'react';

import { ToastContainer, toast } from 'react-toastify';

import InputSearch from '../components/InputSearch';

import { SpinnerLoading } from '../../../components/SpinnerLoading';

import { Container } from './styles';

import TableAlvaras from '../components/TableAlvaras';
import Header from '../components/Header';
import api from '../../../services/api';

import { useAuth } from '../../../hooks/auth';

interface IPagination {
  total: number;
  per_page: number;
  current_page: number;
  last_page: number;
}

interface IAlvara {
  id: number;
  numero_alvara: number;
  egress: {
    nome: string;
    cep: string;
    endereco: string;
    bairro: string;
    estado: string;
    municipio: string;
  };
  responsavel_execucao: any;
  executado: string;
  data_execucao: string;
  registro_seuu: string;
  attachment: {
    name:string;
    url_file: string;
  };
  observacao: string;
}

interface IAlvaras {
  meta: IPagination;
  data: IAlvara[];
}

export function CadastrarAlvara() {
  const { user } = useAuth();

  const [tab, setTab] = useState('1');

  const [loading, setLoading] = useState(false);
  const [loadingInitial, setLoadingInitial] = useState(false);
  const [loadingSearch, setLoadingSearch] = useState(false);

  const [egresses, setEgresses] = useState<any[]>([]);
  const [egress, setEgress] = useState<any>(null);

  const [data, setData] = useState({
    numero_alvara: "",
    egress_id: "",
  });
  const [file, setFile] = useState<any>(null);

  const [alvara, setAlvara] = useState<IAlvara | null>(null);

  const [page, setPage] = useState(0);

  const [alvaras, setAlvaras] = useState<IAlvaras>({
    meta: {
      total: 0,
      per_page: 30,
      current_page: 1,
      last_page: 1,
    },
    data: [],
  });

  function getLicenses(page = 0) {
    setLoadingInitial(true);
    api.get(`licenses`, {
      params: {
        page: page + 1,
        executado: tab === '1' ? undefined : tab === '2' ? 'F' : tab === '3' ? 'T' : undefined,
        perPage: 15,
      },
    }).then(({ data: licenses }) => {
      setAlvaras(licenses);
      setPage(licenses.meta.current_page);
      setLoadingInitial(false);
    });
  }

  useEffect(() => {
    getLicenses();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [alvara, tab]);

  async function search(page: number, nome: string) {
    setLoadingSearch(true);
    setEgresses([]);
    setEgress(null);
    api.get(`egresses`, {
      params: {
        nome,
      },
    }).then(({ data }) => {
      setLoadingSearch(false);
      setEgresses(data.data);
    });
  }

  /* function getEgresses() {
    api.get(`egresses`, {
      params: {
        nopaginate: true,
      },
    }).then(({ data: egresses }) => {
      setEgresses(egresses);
      if (egresses.length > 0) {
        setData({ ...data, numero_alvara: "", egress_id: egresses[0].id });
      }
    });
  } */

  useEffect(() => {
    // getEgresses();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function cadastrar(): void {
    setLoading(true);

    if (data.egress_id === "") {
      toast("Selecione um egresso para poder Cadastrar Alvará", {
        type: 'error',
      });

      setLoading(false);

      return;
    }

    const formData = new FormData();
    formData.append('data', JSON.stringify({ ...data, egress_id: data.egress_id === "" ? null : data.egress_id }));
    // @ts-ignore
    formData.append('anexo', file);

    api.post(`licenses`, formData).then(({ data }) => {
      const message = 'Alvará cadastrado com sucesso!';

      toast(message, {
        type: 'success',
      });

      setData({
        numero_alvara: "",
        egress_id: "",
      });
      setFile(null);
      setEgress(null);
      setEgresses([]);

      getLicenses();

      setLoading(false);
    }).catch((error) => {
      setLoading(false);
      const message = error.response.data.message || 'Erro ao Cadastrar Alvará!';

      toast(message, {
        type: 'error',
      });
    });
  }

  return (
    <>
      <Container>
        <Header route="cadastrar-alvara" name="Cadastrar Alvará" />

        {/* <div style={{ marginTop: 20 }}>
          <h1 style={{ fontSize: 20 }}>Selecione o egresso envolvido</h1>
          <div style={{ display: 'flex', alignItems: 'center', marginBottom: 10 }}>
            <select
              onChange={(e) => setData({ ...data, egress_id: e.target.value })}
              style={{
                width: '60%', padding: 10, marginRight: 10, borderRadius: 5,
              }}
            >
              {egresses.map((item) => (
                <option value={item.id}>{item.nome}</option>
              ))}
            </select>
          </div>
        </div> */}

        <div className="content" style={{ marginTop: 20, width: '100%' }}>
          <InputSearch
            loading={loadingSearch}
            setSearch={search}
            title="Pesquise o egresso envolvido:"
            placeholder="Escreva parte do nome"
            buttonText="Buscar"
            description="Após digitar uma pista, clique no botão BUSCAR e preencha os dados do alvará"
          />
        </div>

        {egresses.length > 0
        && (
          <>
            <div style={{ width: '100%', marginTop: 20 }}>
              <table style={{
                width: '100%', borderCollapse: 'collapse', borderStyle: 'solid', borderColor: 'black', borderWidth: 1,
              }}
              >
                <tr>
                  <th style={{
                    borderStyle: 'solid', textAlign: 'center', borderColor: 'black', borderWidth: 1,
                  }}
                  >
                    Nome
                  </th>
                  <th style={{
                    borderStyle: 'solid', textAlign: 'center', borderColor: 'black', borderWidth: 1,
                  }}
                  >
                    CPF
                  </th>
                  <th style={{
                    borderStyle: 'solid', textAlign: 'center', borderColor: 'black', borderWidth: 1,
                  }}
                  >
                    Adicionar
                  </th>
                </tr>
                {egresses.map((item: any) => (
                  <tr>
                    <td style={{
                      borderStyle: 'solid', borderColor: 'black', borderWidth: 1, textAlign: 'center',
                    }}
                    >
                      {item.nome}
                    </td>
                    <td style={{
                      borderStyle: 'solid', borderColor: 'black', borderWidth: 1, textAlign: 'center',
                    }}
                    >
                      {item.cpf}
                    </td>
                    <td style={{
                      borderStyle: 'solid', borderColor: 'black', borderWidth: 1, textAlign: 'center',
                    }}
                    >
                      <div
                        onClick={() => {
                          setEgress(item);
                          setData({ ...data, egress_id: item.id });
                          // setEgresses([]);
                        }}
                        style={{ display: 'flex', justifyContent: 'center', marginTop: 5 }}
                      >
                        <h1 style={{
                          fontSize: 14, color: '#007BFF', marginLeft: 5, cursor: 'pointer',
                        }}
                        >
                          (Clique aqui para selecionar)
                        </h1>
                      </div>
                    </td>
                  </tr>
                ))}
              </table>
            </div>
          </>
        )}

        {egress
            && (
            <>
              <div style={{ display: 'flex', alignItems: 'center', marginTop: 20 }}>
                <h1 style={{ fontSize: 26 }}>Cadastrar Alvará para: </h1>
                <h1 style={{ color: '#f00', fontSize: 26, marginLeft: 10 }}>{egress?.nome}</h1>
              </div>

              <div style={{
                width: '100%',
                borderStyle: 'solid',
                borderWidth: 0.5,
                borderColor: '#595959',
                marginBottom: 20,
                marginTop: 10,
              }}
              />

              <div className="content" style={{ marginTop: 10 }}>
                <h1 style={{ fontSize: 20 }}>Número do alvará</h1>
                <input
                  style={{
                    padding: 10, borderRadius: 5, height: 40, width: '100%',
                  }}
                  type="number"
                  value={data.numero_alvara}
                  onChange={(e) => setData({ ...data, numero_alvara: e.target.value })}
                  placeholder="Digite o número do documento"
                />
              </div>

              <h1 style={{ fontSize: 20, marginTop: 20 }}>Arquivos que fazem parte deste processo:</h1>
              <div
                style={{
                  background: '#efefef', padding: 20,
                }}
                className="content"
              >
                <div>
                  <input
                    onChange={(e) => {
                      // @ts-ignore
                      setFile(e.target.files[0]);
                    }}
                    type="file"
                    name="file"
                    id="file"
                    className="inputfile"
                  />
                  <div style={{
                    display: 'flex', width: '40%', justifyContent: 'center', backgroundColor: "#ccc", padding: 10, borderRadius: 5,
                  }}
                  >
                    <label style={{ cursor: "pointer", marginTop: 10 }} htmlFor="file">{file ? file?.name : 'Clique para anexar arquivos'}</label>
                  </div>
                </div>
              </div>

              <h2 style={{
                fontSize: 16, color: '#595959', fontWeight: "normal", marginTop: 10,
              }}
              >
                Arquivos utilizados para apoiar o processo, podem ser usados documentos até o limite de 100 MB no total, caso passe o sistema avisará
              </h2>

              <div style={{ display: "flex", justifyContent: "flex-end", marginTop: 40 }}>
                {/* <div
            style={{
              display: "flex", width: '20%', justifyContent: "center", cursor: 'pointer', backgroundColor: "#f00", padding: 10, borderRadius: 5,
            }}
          >
            <h1 style={{ color: '#fff', fontSize: 14, marginTop: 10 }}>Cancelar</h1>
          </div> */}

                <div
                  onClick={cadastrar}
                  style={{
                    display: "flex", width: '20%', marginLeft: 10, justifyContent: "center", cursor: 'pointer', backgroundColor: "#080", padding: 10, borderRadius: 5,
                  }}
                >
                  {!loading ? <h1 style={{ color: '#fff', fontSize: 14, marginTop: 10 }}>Cadastrar</h1> : <SpinnerLoading color="#fff" size={30} />}
                </div>
              </div>
            </>
            )}

        <div style={{
          width: '100%',
          borderStyle: 'solid',
          borderWidth: 0.5,
          borderColor: '#595959',
          marginBottom: 20,
          marginTop: 40,
        }}
        />
        <h1 style={{ fontSize: 26, marginTop: 20, marginBottom: 20 }}>Alvarás Cadastrados</h1>
        <TableAlvaras user={user} loading={loadingInitial} tab={tab} setTab={setTab} alvaras={alvaras} setAlvara={setAlvara} page={page} getLicenses={getLicenses} />

        {/* <h2 style={{
          fontSize: 16, color: '#595959', fontWeight: "normal",
        }}
        >
          Escolha entre os egressos cadastrados disponíveis, ou comece a digitar o nome
        </h2> */}

      </Container>

      <ToastContainer />
    </>
  );
}
