import React, { useState } from 'react';
import { Chart } from 'react-chartjs-2';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import { ArrayFormValues, FormAcompanhamentos } from '../../../../components/develop-nilton-components/FormAcompanhamentos';
import { SpinnerLoading } from '../../../../components/SpinnerLoading';
import api from '../../../../services/api';
import { TypeAbasContainerComponents, TypeConcentração } from '../types';

export function Concentração({ setTab, getValues, setValue }: TypeAbasContainerComponents) {
  const [sente_cansaco, set_sente_cansaco] = useState(getValues().sente_cansaco || 0);
  const [consegue_concentrar, set_consegue_concentrar] = useState(getValues().consegue_concentrar || 0);
  const [fadiga_matinal, set_fadiga_matinal] = useState(getValues().fadiga_matinal || 0);
  const [manter_atencao_tarefas_especificas, set_manter_atencao_tarefas_especificas] = useState(getValues().manter_atencao_tarefas_especificas || 0);
  const [dificuldade_tomar_decisao, set_dificuldade_tomar_decisao] = useState(getValues().dificuldade_tomar_decisao || 0);

  const dataset = {
    labels: ['Cansaço', 'Concentração', 'Fadiga matinal', 'Atenção', 'Tomar decisões '],
    datasets: [{
      label: '',
      data: [sente_cansaco, consegue_concentrar, fadiga_matinal, manter_atencao_tarefas_especificas, dificuldade_tomar_decisao],
      borderColor: '#FFA500',
      backgroundColor: '#FFA500',
    }],
  };

  const { register, handleSubmit } = useForm({ defaultValues: getValues() });

  const [load, setLoad] = useState(false);

  const onSubmit = async (data: any) => {
    setLoad(true);
    try {
      await api.put(`/psychologist/${getValues().id}`, data);
      setTab(3);
    } catch (error) {
      // @ts-ignore
      toast.error(error.response.data.message);
    }
    setLoad(false);
  };

  const onSubmitError = (errors: any) => {
    const error = Object.keys(errors);
    for (let i = 0; i < error.length; i + 1) {
      toast.error(errors[error[i] as keyof TypeConcentração]?.message);
      break;
    }
  };

  const formItems: ArrayFormValues["formValues"] = [
    {
      label: "1 - Sente cansaço ou tem pouca energia?",
      name: "sente_cansaco",
      type: "number",
      onChange: (e:any) => {
        set_sente_cansaco(e.target.value);
        setValue("sente_cansaco", e.target.value);
      },
    },
    {
      label: "2 - Consegue se concentrar em atividades específicas?",
      name: "consegue_concentrar",
      type: "number",
      onChange: (e:any) => {
        set_consegue_concentrar(e.target.value);
        setValue("consegue_concentrar", e.target.value);
      },
    },
    {
      label: "3 - Quanto se sente exausto pela manhã (fadiga matinal)?",
      name: "fadiga_matinal",
      type: "number",
      onChange: (e:any) => {
        set_fadiga_matinal(e.target.value);
        setValue("fadiga_matinal", e.target.value);
      },
    },
    {
      label: "4 - Tem capacidade de se manter atenção em tarefas específicas?",
      name: "manter_atencao_tarefas_especificas",
      type: "number",
      onChange: (e:any) => {
        set_manter_atencao_tarefas_especificas(e.target.value);
        setValue("manter_atencao_tarefas_especificas", e.target.value);
      },
    },
    {
      label: "5 - Tem dificuldades em tomar decisões?",
      name: "dificuldade_tomar_decisao",
      type: "number",
      onChange: (e:any) => {
        set_dificuldade_tomar_decisao(e.target.value);
        setValue("dificuldade_tomar_decisao", e.target.value);
      },
    },
  ];

  return (
    <div>
      <div style={{ width: '100%' }}>
        <Chart
          data={dataset}
          type="bar"
          options={{
            plugins: {
              legend: { display: false },
            },
            scales: {
              y: {
                suggestedMin: 0,
                suggestedMax: 10,
              },
            },
          }}
        />
      </div>

      <p className="text-primary h5 border-top pt-2 mt-2" style={{ width: '100%' }}>Preencher novo acompanhamento</p>
      <Form onSubmit={handleSubmit(onSubmit, onSubmitError)}>
        <div>
          <FormAcompanhamentos
            formValues={formItems}
            register={register}
          />
        </div>
        <div className="d-flex justify-content-between m-5 w-75">
          <button className="btn btn-primary btn-lg" type="button" onClick={() => setTab(1)}>
            Voltar
          </button>
          {load ? (
            <button className="btn btn-primary btn-lg">
              <SpinnerLoading />
            </button>
          ) : (
            <button type="submit" className="btn btn-primary btn-lg">
              Avançar
            </button>
          )}

        </div>
      </Form>
    </div>
  );
}

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 50px;
  margin-top: 20px;
`;

const Input = styled.div`
  display: flex;
  flex-direction: column;
  cursor: pointer;
  label,input {
    cursor: pointer;
  }
`;

const InputContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
`;

const ContainerButton = styled.div`
  display: flex;
  justify-content: center;
`;
