import React, { useEffect, useState } from 'react';
import { useGet_relatorio_psicologico_indicadores } from './useGet_relatorio_psicologico_indicadores';

export function useGet_data_set_concentracao() {
  const { handle_relatorio_psicologico_indicadores } = useGet_relatorio_psicologico_indicadores();
  const [data_set_concentracao, set_data_set_concentracao] = useState<any>(null);

  async function handle_onChange_concentracao(year: string, trimestre?: string) {
    const concentracao = await handle_relatorio_psicologico_indicadores(year, "concentracao", trimestre);
    set_data_set_concentracao(concentracao);
  }

  useEffect(() => {
    handle_onChange_concentracao("2022");
  }, []);

  return { data_set_concentracao, handle_onChange_concentracao };
}
