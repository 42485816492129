import React, { useState } from 'react';
import { AbaFilterButtons, AbaFilterContent } from '../../../components/develop-nilton-components/AbaFiltro';
import { SectionHeader } from '../../../components/SectionHeader';
import { Egresso } from './components/Egresso';
import { Projeto } from './components/Projeto';
import { Turma } from './components/Turma';

export function EstatisticaPedagogo() {
  const [tab, setTab] = useState(1);
  return (
    <div className="w-100">
      <SectionHeader subtitle="Acompanhamento estatístico" />

      <AbaFilterButtons
        buttons={["Egressos", "Projetos", "Turmas"]}
        useStateTab={[tab, setTab]}
      />

      <AbaFilterContent
        tab={tab}
        components={[
          <Egresso />,
          <Projeto />,
          <Turma />,
        ]}
      />
    </div>
  );
}
