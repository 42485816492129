import { format } from 'date-fns';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import { Badge } from '../../../../components/develop-nilton-components/Badge';
import { IconAdd } from '../../../../components/develop-nilton-components/IconAdd';
import { IconDetails } from '../../../../components/develop-nilton-components/IconDetails';
import { IconEdit } from '../../../../components/develop-nilton-components/IconEdit';
import { IconRemove } from '../../../../components/develop-nilton-components/IconRemove';
import { Td, Tr } from '../../../../components/develop-nilton-components/TablePadrão';
import { Tbody } from '../types';
import { ModalDetalhesProjeto } from './ModalDetalhesProjeto';

export function TbodyNaoIniciado({ headWidth, projetoItem, index }:Tbody) {
  const history = useHistory();
  const [detalhesIsOpen, setDetalhesIsOpen] = useState(false);

  function handleDetalhesIsOpen() {
    setDetalhesIsOpen(!detalhesIsOpen);
  }

  console.log(projetoItem);

  const handleRedirectEdit = () => {
    history.push({
      state: {
        id: projetoItem.id,
        data_fim: projetoItem.data_fim ? format(new Date(projetoItem.data_fim), "yyyy-MM-dd") : null,
        data_inicio: projetoItem.data_inicio ? format(new Date(projetoItem.data_inicio), "yyyy-MM-dd") : null,
        laboratorio: projetoItem.laboratorio,
        nome: projetoItem.nome,
        objetivo: projetoItem.objetivo,
        origem_recurso: projetoItem.origem_recurso,
        parceiro: projetoItem.parceiro,
        sala_aula: projetoItem.sala_aula,
        valor_envolvido: projetoItem.valor_envolvido,
        materials: projetoItem.materiais.map((item) => ({
          qtd: item.qtd,
          material: {
            title: item.material.title,
            id: item.material.id,
          },
        })),
      },
      pathname: "/gerir-projetos/editar",
    });
  };

  return (
    <Tr>
      <Td width={headWidth[0]}>{index}</Td>
      <Td width={headWidth[1]}>{projetoItem.nome}</Td>
      <Td width={headWidth[2]}>
        <Badge type="info">{projetoItem.situacao}</Badge>
      </Td>
      <Td width={headWidth[3]}>
        <div className="d-flex justify-content-between align-items-center">
          <div onClick={handleDetalhesIsOpen}>
            <IconDetails cursor size={22} tooltipMessage="Detalhes" />
          </div>
          <ModalDetalhesProjeto
            isOpen={detalhesIsOpen}
            handleIsOpen={handleDetalhesIsOpen}
            projeto={projetoItem}
          />
          {/* <div onClick={handleRedirectEdit}>
            <IconEdit
              color="text-warning"
              cursor
              size={22}
              tooltipMessage="Editar"
            />
          </div> */}
          {/* <div>
            <IconAdd
                cursor
                color="text-primary"
                size={22}
                tooltipMessage="Enviar para homologação"
              />
          </div> */}
          <ReactTooltip />
        </div>
      </Td>
    </Tr>
  );
}
