import React from 'react';
import styled from 'styled-components';

type PropsAbaButton = {
  abas: string[]
  useStateTab: [number, React.Dispatch<React.SetStateAction<number>>]
  size?: "btn-sm" | "btn-lg" | "medium"
}

export function AbaProgress({ abas, useStateTab, size }: PropsAbaButton) {
  const [tab, setTab] = useStateTab;
  return (
    <div>
      {abas.map((item, i:number) => (
        <Button
          key={i}
          style={{ cursor: tab > i + 1 ? "pointer" : "default" }}
          className={`btn ${tab >= i + 1 ? 'btn-primary' : 'text-primary'} ${size === "medium" ? "" : size || "btn-lg"} m-1`}
          onClick={() => {
            if (tab > i + 1) {
              setTab(i + 1);
            }
          }}
        >
          {item}
        </Button>
      ))}
      <ContainerProgress className="progress" style={{ height: "5px" }}>
        <div className="progress-bar" style={{ width: "25%" }} />
      </ContainerProgress>
    </div>
  );
}

const Button = styled.button`
  :focus {
    box-shadow: 0 0 0 0;
    outline: 0;
  }
`;

const ContainerProgress = styled.div`
  position: absolute;
`;
