import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { AbaFilterButtons, AbaFilterContent } from '../../../components/develop-nilton-components/AbaFiltro';
import { IconAdd } from '../../../components/develop-nilton-components/IconAdd';
import { SearchField } from '../../../components/develop-nilton-components/SearchField';
import { SectionHeader } from '../../../components/SectionHeader';
import { TableConcluidos } from './components/TableConcluidos';
import { TableEmAndamento } from './components/TableEmAndamento';
import { TableGeral } from './components/TableGeral';
import { TableNaoIniciado } from './components/TableNaoIniciado';
import { TablePendentes } from './components/TablePendentes';
import { useGetProjetosConcluidos } from './Hooks/useGetProjetoConcluidos';
import { useGetProjetosEmAndamento } from './Hooks/useGetProjetoEmAndamento';
import { useGetProjetosNaoIniciado } from './Hooks/useGetProjetoNaoIniciado';
import { useGetProjetosPententes } from './Hooks/useGetProjetoPendentes';
import useProjetosAll from './Hooks/useProjetos';
import useProjetosSearch from './Hooks/useProjetosSearch';

function GerirProjeto() {
  const { projetos, handleProjetosAll, load } = useProjetosAll();
  const { handleProjetosPendentes, loadPendentes, projetosPendentes } = useGetProjetosPententes();
  const { projetosEmAndamento, handleProjetosEmAndamento, loadEmAndamento } = useGetProjetosEmAndamento();
  const { projetosNaoIniciado, handleProjetosNaoIniciado, loadNaoIniciado } = useGetProjetosNaoIniciado();
  const { projetosConcluidos, handleProjetosConcluidos, loadConcluido } = useGetProjetosConcluidos();

  const [inputValue, setInputValue] = useState("");
  const { projetosSearch } = useProjetosSearch();

  const [tab, setTab] = useState(1);
  const history = useHistory();

  async function handleUpdate(page?:number) {
    await handleProjetosAll(page);
    await handleProjetosPendentes(page);
    await handleProjetosEmAndamento(page);
    await handleProjetosNaoIniciado(page);
    await handleProjetosConcluidos(page);
  }

  // console.log(projetosEmAndamento);

  return (
    <div style={{ width: '100%' }}>
      <SectionHeader subtitle="Gerir projetos" />
      <div style={{ width: '100%' }}>
        <AbaFilterContent
          components={[<SearchField
            handleSetSearch={handleProjetosAll}
            keyNameOptions="nome"
            option={projetosSearch?.data}
            stateValue={[inputValue, setInputValue]}
            label="Pesquise o projeto desejado:"
            describe="Após digitar uma pista, clique no botão pesquisar e visualize os projetos."
          />,
            <SearchField
              handleSetSearch={handleProjetosPendentes}
              keyNameOptions="nome"
              option={projetosSearch?.data}
              stateValue={[inputValue, setInputValue]}
              label="Pesquise o projeto desejado:"
              describe="Após digitar uma pista, clique no botão pesquisar e visualize os projetos."
            />,
            <SearchField
              handleSetSearch={handleProjetosNaoIniciado}
              keyNameOptions="nome"
              option={projetosSearch?.data}
              stateValue={[inputValue, setInputValue]}
              label="Pesquise o projeto desejado:"
              describe="Após digitar uma pista, clique no botão pesquisar e visualize os projetos."
            />,
            <SearchField
              handleSetSearch={handleProjetosEmAndamento}
              keyNameOptions="nome"
              option={projetosSearch?.data}
              stateValue={[inputValue, setInputValue]}
              label="Pesquise o projeto desejado:"
              describe="Após digitar uma pista, clique no botão pesquisar e visualize os projetos."
            />,
            <SearchField
              handleSetSearch={handleProjetosConcluidos}
              keyNameOptions="nome"
              option={projetosSearch?.data}
              stateValue={[inputValue, setInputValue]}
              label="Pesquise o projeto desejado:"
              describe="Após digitar uma pista, clique no botão pesquisar e visualize os projetos."
            />]}
          tab={tab}
        />
      </div>

      <div className="d-flex justify-content-between align-items-center mb-1" style={{ width: "100%" }}>
        <AbaFilterButtons
          buttons={["Geral", "Pendentes", "Não Iniciado", "Em Andamento", "Concluído"]}
          useStateTab={[tab, setTab]}
        />
        <button
          className="btn btn-success d-flex align-items-center justify-content-between"
          onClick={() => { history.push("/cad_project"); }}
        >
          <IconAdd color="#fff" />
          <span>Cadastrar projeto</span>
        </button>
      </div>

      <AbaFilterContent
        tab={tab}
        components={[
          <TableGeral
            projetos={projetos}
            handleProjetos={handleUpdate}
            load={load}
          />,
          <TablePendentes
            projetos={projetosPendentes}
            handleProjetos={handleUpdate}
            load={loadPendentes}
          />,
          <TableNaoIniciado
            projetos={projetosNaoIniciado}
            handleProjetos={handleUpdate}
            load={loadNaoIniciado}
          />,
          <TableEmAndamento
            projetos={projetosEmAndamento}
            handleProjetos={handleUpdate}
            load={loadEmAndamento}
          />,
          <TableConcluidos
            projetos={projetosConcluidos}
            handleProjetos={handleUpdate}
            load={loadConcluido}
          />,
        ]}
      />

    </div>
  );
}

export default GerirProjeto;
