import React from 'react';

import { Container } from './styles';

import CardReport from '../CardReport';
import { SectionHeader } from '../../../../components/SectionHeader';

export function RelatorioFiscal() {
  return (
    <Container>
      {/* <Header route="relatorios" name="Relatórios" /> */}
      <SectionHeader subtitle="Relatórios" />

      <CardReport
        title="Fiscalização"
        optionsEgress={false}
        dateFilter={false}
        optionsEgressStatusOnly={false}
        optionsEgressGender
      />
      <CardReport
        title="Notificação do Número do Processo"
        optionsEgress={false}
        dateFilter={false}
        optionsEgressStatusOnly={false}
        optionsEgressGender
      />
      <CardReport
        title="Cumprimento de Alvará"
        optionsEgress={false}
        dateFilter={false}
        optionsEgressStatusOnly={false}
        optionsEgressGender
      />
      <CardReport
        title="Egresso - (Habilitado) com o regime aberto domiciliar"
        optionsEgress={false}
        dateFilter={false}
        optionsEgressStatusOnly
        optionsEgressGender
      />
      <CardReport
        title="Egresso - (Desabilitado)"
        optionsEgress
        dateFilter={false}
        optionsEgressStatusOnly
        optionsEgressGender
      />
      <CardReport
        title="Egresso - Fiscalização"
        optionsEgress={false}
        dateFilter={false}
        optionsEgressStatusOnly={false}
        optionsEgressGender
      />
      <CardReport
        title="Egresso - Não estava em casa"
        optionsEgress={false}
        dateFilter={false}
        optionsEgressStatusOnly={false}
        optionsEgressGender
      />
      <CardReport
        title="Egresso - Estado/Município"
        dateFilter={true}
        optionsEgress={false}
        optionsEgressStatusOnly={true}
        optionsEgressGender={false}
      />
    </Container>
  );
}
