/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import { FieldError, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { BsFillXCircleFill } from 'react-icons/bs';
import { useFormValidate } from '../../../../components/develop-nilton-components/hooks/useFormValidate';
import { data } from '../types';
import { useGetProjetosOptions } from '../../CadCurso/hooks/useGetProjetosOptions';
import { useGetSearchEgresses } from '../../CadCurso/hooks/useGetSearchEgresses';
import { SectionHeader } from '../../../../components/SectionHeader';
import { InputField } from '../../../../components/develop-nilton-components/inputField';
import { DateField } from '../../../../components/develop-nilton-components/DateField';
import { SelectField } from '../../../../components/develop-nilton-components/SelectField';
import { SearchEgresses } from '../../../../components/develop-nilton-components/SearchEgresses';
import { AbaFilterButtons, AbaFilterContent } from '../../../../components/develop-nilton-components/AbaFiltro';
import { TableGeral } from '../../CadCurso/components/TableGeral';
import { TableAdicionado } from '../../CadCurso/components/TableAdicionado';
import { SpinnerLoading } from '../../../../components/SpinnerLoading';
import api from '../../../../services/api';
import { InputAdd } from '../../../../components/develop-nilton-components/InputAdd';
import { IconDetails } from '../../../../components/develop-nilton-components/IconDetails';
import { ModalDetalhesProjeto } from '../../GerirProjeto/components/ModalDetalhesProjeto';
import { TablePadrão, Td, Tr } from '../../../../components/develop-nilton-components/TablePadrão';

export const PageEditTurmas = () => {
  const { state } = useLocation();
  const { register, formState, handleSubmit } = useForm({
    // @ts-ignore
    defaultValues: {
      title: state.title,
      data_inicio: state.data_inicio,
      data_fim: state.data_fim,
      coordenador: state.coordenador,
    },
  });
  const formStateErrors = Object.values(formState.errors) as (FieldError | undefined)[];
  const formValues = Object.keys(formState.errors);
  const history = useHistory();
  const [responseLoad, setResponseLoad] = useState(false);

  const { formIsValidated } = useFormValidate({ formStateErrors, formValues });

  const checkKeyDown = (e:any) => {
    if (e.code === 'Enter') e.preventDefault();
  };
  // @ts-ignore
  const [addedEgresses, setAddedEgresses] = useState<{id: number}[] | null>(null);
  const [load_course_egress, set_load_course_egress] = useState(1);
  const onSubmit = async (data:any) => {
    setResponseLoad(true);
    try {
      // @ts-ignore
      await api.put(`/courses/${state.id}`, {
        ...data,
        projects: projectAdded.map((project:any) => ({ id: project.id })),
      });
      toast.success("Curso editado com sucesso");
      history.push("/gerir-turmas");
    } catch (error) {
      // @ts-ignore
      toast.error(error.response.data.message);
    }
    setResponseLoad(false);
  };

  const { projetoOptions } = useGetProjetosOptions<{value: any, label: any}[]>();

  const { egresses, handleSetOptionsEgrsses, load: loadEgress } = useGetSearchEgresses();
  const [courses_egresses, set_courses_egresses] = useState<any>(null);

  const [tab, setTab] = useState(1);

  async function addEgresses(egress_id: number) {
    try {
      const { data } = await api.post("/courses_egresses", { egress_id, course_id: state.id });
      const { data: data_course_egresse } = await api.get('/courses_egresses', { params: { course: state.id } });
      set_courses_egresses(data_course_egresse.data);
      if (data) {
        setAddedEgresses((prev) => prev && [...prev, { id: egress_id }]);
        set_load_course_egress((prev) => prev + 1);
        handleSetOptionsEgrsses(1);
        toast.info('Egresso adicionado com sucesso.');
      }
    } catch (error) {
      set_load_course_egress((prev) => prev + 1);
      // @ts-ignore
      toast.info(error.response.data.message);
    }
  }
  async function RemoveEgresses(egress_id: number) {
    const course_egresse = courses_egresses?.find((item:any) => item.egress_id === egress_id);
    if (course_egresse) {
      await api.delete(`/courses_egresses/${course_egresse.id}`);
      setAddedEgresses((prev) => prev && prev.filter((item) => (item.id !== egress_id)));
      set_load_course_egress((prev) => prev + 1);
      toast.info('Egresso removido com sucesso.');
    }
  }

  const filterEgressesAdicionado = egresses?.data.filter((item:any) => {
    const itemDuplicated = addedEgresses?.find((added) => added.id === item.id);
    if (itemDuplicated) {
      return true;
    } return false;
  });
  const filterEgressesGeral = egresses?.data?.filter((item:any) => {
    const itemDuplicated = addedEgresses?.find((added) => added.id === item.id);
    if (itemDuplicated) {
      return false;
    } return true;
  });

  const [projectAdded, setProjectAdded] = useState<any[]>(state?.projetos.map((project:any) => ({ ...project.projeto, materiais: project.materiais })) || []);
  const [isOpenModalProject, setIsOpenModalProject] = useState(false);

  function handleSetIsOpenModalProject() {
    setIsOpenModalProject((prev) => !prev);
  }

  function inputAddFunction(inputValue: string) {
    const data = projetoOptions?.find((project:any) => project.nome === inputValue);
    if (data) {
      if (projectAdded.find((project:any) => project.nome === inputValue)) {
        toast.info("Projeto já adicionado.");
      } else {
        setProjectAdded((prev:any) => [...prev, data]);
      }
    } else {
      toast.error('Projeto não cadastrado. Procure outro projeto.');
    }
  }

  function removeProjectList(inputValue: string) {
    const filterProjects = projectAdded.filter((project) => project.nome !== inputValue);

    setProjectAdded(filterProjects);
  }

  useEffect(() => {
    (async () => {
      const { data } = await api.get('/courses_egresses', { params: { course: state.id } });
      set_courses_egresses(data.data);
      setAddedEgresses(data.data.map((item:any) => ({ id: item.egress_id })));
    })();
  }, [load_course_egress, state.id]);

  return (
    <div className="w-100">
      <SectionHeader subtitle="Editar turma" backPage="/gerir-turmas" />

      <Form
        onSubmit={handleSubmit(onSubmit)}
        noValidate
        className={formIsValidated}
        onKeyDown={checkKeyDown}

      >
        <InputField
          label="Nome da turma"
          placeholder="Digite o nome do turma"
          register={register}
          name="title"
          formState={formState}
          size="lg"
        />

        <div className="d-flex">
          <DateField
            label="Início"
            register={register}
            name="data_inicio"
            formState={formState}
            size="lg"
          />
          <DateField
            label="Término"
            register={register}
            name="data_fim"
            formState={formState}
            size="lg"
          />
        </div>
        <InputField
          label="Coordenador"
          placeholder="Digite o nome do coordenador"
          register={register}
          name="coordenador"
          formState={formState}
          size="lg"
        />

        <InputAdd
          options={projetoOptions?.map((project: any) => project.nome as string)}
          functionAdd={inputAddFunction}
          label="Adicionar projeto"
          description="Escolha entre os cadastrados disponíveis e clique no botão adicionar"
        />

        {projectAdded.length !== 0 && (
        <div>
          <h4 className="text-primary m-0 mb-2">Projetos adicionados</h4>
          <TablePadrão
            headWidth={["5%", "50%", "45%"]}
            thead={["#", "Nome do projeto", "Opções"]}
          >
            {projectAdded.map((project, i) => (
              <Tr>
                <Td width="5%">{i + 1}</Td>
                <Td width="50%">{project.nome}</Td>
                <Td width="45%">
                  <div className="d-flex">
                    <div className="mr-3" onClick={handleSetIsOpenModalProject}>
                      <IconDetails cursor />
                    </div>
                    <ModalDetalhesProjeto projeto={project} isOpen={isOpenModalProject} handleIsOpen={handleSetIsOpenModalProject} />
                    <div onClick={() => removeProjectList(project.nome)}>
                      <BsFillXCircleFill
                        color="rgba(242, 78, 30, 1)"
                        size={30}
                        style={{ cursor: "pointer" }}
                      />
                    </div>
                  </div>
                </Td>
              </Tr>
            ))}
          </TablePadrão>
        </div>
        )}

        <SearchEgresses
          handleSetSearch={handleSetOptionsEgrsses}
        />

        <div>
          <AbaFilterButtons buttons={["Geral", "Adicionados"]} useStateTab={[tab, setTab]} />

          <AbaFilterContent
            tab={tab}
            components={[
              <TableGeral
                data={filterEgressesGeral}
                meta={egresses?.meta}
                handleUpdate={handleSetOptionsEgrsses}
                load={loadEgress}
                addEgresses={addEgresses}
              />,
              <TableAdicionado
                data={filterEgressesAdicionado}
                meta={egresses?.meta}
                handleUpdate={handleSetOptionsEgrsses}
                load={loadEgress}
                addEgresses={RemoveEgresses}
              />,
            ]}
          />

        </div>

        <div className="d-flex justify-content-center w-100 mb-5">
          <button className="btn btn-primary btn-lg">{responseLoad ? <SpinnerLoading /> : "Finalizar"}</button>
        </div>
      </Form>

    </div>
  );
};

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;
