import React from 'react';
import { BsCircle } from 'react-icons/bs';
import { toast } from 'react-toastify';
import { Modal, ModalBody, ModalHeader, Progress } from 'reactstrap';
import { AiFillCloseCircle } from 'react-icons/ai';
import { useHistory } from 'react-router-dom';
import { useGetCoursesConcern } from '../../../hooks/CoursesConcern/useGetCoursesConcen';
import { useEgresses } from '../../../hooks/Egresses';
import { useUpdateEgresses } from '../../../hooks/Egresses/useUpdateEgresses';
import api from '../../../services/api';
import { IEgresses } from '../../../types/IEgresses';
import { IFetchParams } from '../../../types/IFetchParams';
import { StepProgress } from '../StepProgressBar';
import { FormPart1 } from './Components/FormPart1';
import { FormPart2 } from './Components/FormPart2';
import { FormPart3 } from './Components/FormPart3';
import { FormPart4 } from './Components/FormPart4';
import { FormPart5 } from './Components/FormPart5';
import { FormPart6 } from './Components/FormPart6';

import SideBar from '../../SideBar/SideBarEgress';
import NavBar from '../../NavBar/NavBar';
import { SectionHeader } from '../../SectionHeader';

type EgressesFormModal = {
  useStateIsOpen: [boolean, (boolean: boolean) => void];
  useStateIsCreate: [boolean, (boolean: boolean) => void];
  useStateEgresse: [IEgresses | null, (egresse: IEgresses | null) => void];
  queryKeysEgresse: IFetchParams;
};

export function EgressesFormModal(props: EgressesFormModal) {
  const {
    useStateIsOpen,
    useStateEgresse,
    useStateIsCreate,
    queryKeysEgresse,
  } = props;
  const history = useHistory();
  const [isOpen, setIsOpen] = useStateIsOpen;
  const [isCreate, setIsCreate] = useStateIsCreate;
  const [egresse, setEgresse] = useStateEgresse;
  const [tab, setTab] = React.useState(1);
  const { updateEgresse, createEgresse } = useEgresses();

  const [avatar, setAvatar] = React.useState<any>(null);

  const { courses_concern } = useGetCoursesConcern({ page: 1 });
  const [comportamento_outros, set_comportamento_outros] = React.useState('');
  const [comportamental, setComportamental] = React.useState([
    { checked: false, title: 'Ansiedade', value: 'Ansiedade' },
    { checked: false, title: 'Depressão', value: 'Depressão' },
    { checked: false, title: 'Esquizofrenia', value: 'Esquizofrenia' },
    {
      checked: false,
      title: 'Transtornos Alimentares',
      value: 'Transtornos Alimentares',
    },
    {
      checked: false,
      title: 'Estresse pós-traumático',
      value: 'Estresse pós-traumático',
    },
    { checked: false, title: 'Somatização', value: 'Somatização' },
    {
      checked: false,
      title: 'Transtorno Bipolar',
      value: 'Transtorno Bipolar',
    },
    {
      checked: false,
      title: 'Transtorno Obsessivo - compulsivo',
      value: 'Transtorno Obsessivo - compulsivo',
    },
    { checked: false, title: 'Outros', value: comportamento_outros },
  ]);
  const [personalidade, setPersonalidade] = React.useState([
    {
      checked: false,
      title: 'Transtornos de Personalidade',
      value: 'Transtornos de Personalidade',
    },
    { checked: false, title: 'Psicopatia', value: 'Psicopatia' },
    { checked: false, title: 'Serial Killers', value: 'Serial Killers' },
  ]);

  const [processos, setProcessos] = React.useState<any[] | undefined>([]);
  const [artigos, setArtigos] = React.useState<any[] | undefined>([]);
  const [experiencias_profissionais, setExperienciasProfissionais] =
    React.useState<any[] | undefined>([]);
  const [doencas_existentes, setDoencasExistentes] = React.useState<
    any[] | undefined
  >([]);
  const [doencas_ja_adquiridas, setDoencasJaAdquiridas] = React.useState<
    any[] | undefined
  >([]);
  const [cursos, setCursos] = React.useState<any[] | undefined>([]);
  const [enderecos, setEnderecos] = React.useState<any>([]);

  const startValue = {
    nome: null,
    numero_cadastro: null,
    data_entrada_sistema_penitenciario: null,
    data_termino_pena: null,
    data_alvara_soltura: null,
    nome_social: null,
    apelido: null,
    sexo: null,
    tipo_regime: null,
    numero_processo1: null,
    codigo_penal: null,
    numero_processo2: null,
    religiao: null,
    identificacao_lgbt: null,
    rg_uf: null,
    rg: null,
    cpf: null,
    nis: null,
    carteira_trabalho: null,
    titulo_eleitor: null,
    cor_raca: null,
    estado_civil: null,
    cep: null,
    endereco: null,
    numero_endereco: null,
    bairro: null,
    estado: null,
    municipio: null,
    nome_pai: null,
    nome_mae: null,
    quantidade_pessoa_nucleo_familiar: null,
    possui_filhos: null,
    idade_filhos: null,
    possui_residencia_fixa: null,
    email: null,
    telefone_recado: null,
    escolaridade: null,

    formacao_profissional: null,
    experiencias_profissional1: null,
    experiencias_profissional2: null,
    experiencias_profissional3: null,
    habilidades_competencias: null,
    disponibilidade_horario_trabalho: null,
    disponibilidade_horario_curso: null,
    curso_interesse: '',

    doencas_pre_existentes: null,
    doencas_adquiridas: null,
    componente_psicologico_comportamental: '',
    componente_psicologico_personalidade: '',
    status_economico: null,
    necessita_aluguel: null,
    necessita_moradia: null,
    possui_residencia_fora_estado: null,
    possui_dislexia: null,
    possui_tdah: null,
    possui_dpa: null,
    possui_discalculia: null,

    latitude_endereco: null,
    longitude_endereco: null,
    cep_trabalho: null,
    endereco_trabalho: null,
    bairro_trabalho: null,
    municipio_trabalho: null,
    numero_endereco_trabalho: null,
    quantidade_filhos: null,
    data_nascimento: null,

    naturalidade: null,
    nacionalidade: null,
    complemento_endereco: null,
    complemento_endereco_trabalho: null,

    observacao: null,
    extensao_horario: '',
    horario_extensao: null,
    motivo_extensao: null,
  };

  const resto = {
    curso_interesse: cursos
      ?.filter(item => item.checked)
      .map(subitem => String(subitem.id))
      .join(','),
    componente_psicologico_comportamental: comportamental
      .filter((item: any) => item.checked)
      .map((subitem: any) => String(subitem.value))
      .join('&'),
    componente_psicologico_personalidade: personalidade
      .filter((item: any) => item.checked)
      .map((subitem: any) => String(subitem.value))
      .join('&'),

    data_alvara_soltura: !egresse?.data_alvara_soltura
      ? null
      : egresse?.data_alvara_soltura,
    data_entrada_sistema_penitenciario:
      !egresse?.data_entrada_sistema_penitenciario
        ? null
        : egresse?.data_entrada_sistema_penitenciario,
    data_termino_pena: !egresse?.data_termino_pena
      ? null
      : egresse?.data_termino_pena,
    data_nascimento: !egresse?.data_nascimento
      ? null
      : egresse?.data_nascimento,
  };

  const onSubmit = async () => {
    const formData = new FormData();
    formData.append(
      'data',
      JSON.stringify({
        ...egresse,
        extensao_horario:
          egresse?.extensao_horario == '-' ? false : egresse?.extensao_horario,
        numero_processo1:
          processos && processos?.length > 0
            ? processos?.map(processo => String(processo)).join(',')
            : null,
        codigo_penal:
          artigos && artigos?.length > 0
            ? artigos?.map(artigo => String(artigo)).join(',')
            : null,
        experiencias_profissional1:
          experiencias_profissionais && experiencias_profissionais?.length > 0
            ? experiencias_profissionais
                ?.map(experiencia => String(experiencia))
                .join('&')
            : null,
        doencas_pre_existentes:
          doencas_existentes && doencas_existentes?.length > 0
            ? doencas_existentes?.map(doenca => String(doenca)).join('&')
            : null,
        doencas_adquiridas:
          doencas_ja_adquiridas && doencas_ja_adquiridas?.length > 0
            ? doencas_ja_adquiridas?.map(doenca => String(doenca)).join('&')
            : null,
        ...resto,
      }),
    );
    formData.append('avatar', avatar);
    formData.append('enderecos', JSON.stringify(enderecos));

    if (isCreate) {
      toast.promise(
        async () => {
          await createEgresse(
            {
              ...egresse,
              extensao_horario:
                egresse?.extensao_horario == '-' ||
                egresse?.extensao_horario == ''
                  ? false
                  : egresse?.extensao_horario,
              numero_processo1:
                processos && processos?.length > 0
                  ? processos?.map(processo => String(processo)).join(',')
                  : null,
              codigo_penal:
                artigos && artigos?.length > 0
                  ? artigos?.map(artigo => String(artigo)).join(',')
                  : null,
              experiencias_profissional1:
                experiencias_profissionais &&
                experiencias_profissionais?.length > 0
                  ? experiencias_profissionais
                      ?.map(experiencia => String(experiencia))
                      .join('&')
                  : null,
              doencas_pre_existentes:
                doencas_existentes && doencas_existentes?.length > 0
                  ? doencas_existentes?.map(doenca => String(doenca)).join('&')
                  : null,
              doencas_adquiridas:
                doencas_ja_adquiridas && doencas_ja_adquiridas?.length > 0
                  ? doencas_ja_adquiridas
                      ?.map(doenca => String(doenca))
                      .join('&')
                  : null,
              ...resto,
              enderecos,
              avatar,
            } as any,
            () => {
              setIsCreate(false);
              setTab(1);
              setCursos(
                courses_concern?.map(item => ({ ...item, checked: false })),
              );
              setComportamental([
                { checked: false, title: 'Ansiedade', value: 'Ansiedade' },
                { checked: false, title: 'Depressão', value: 'Depressão' },
                {
                  checked: false,
                  title: 'Esquizofrenia',
                  value: 'Esquizofrenia',
                },
                {
                  checked: false,
                  title: 'Transtornos Alimentares',
                  value: 'Transtornos Alimentares',
                },
                {
                  checked: false,
                  title: 'Estresse pós-traumático',
                  value: 'Estresse pós-traumático',
                },
                { checked: false, title: 'Somatização', value: 'Somatização' },
                {
                  checked: false,
                  title: 'Transtorno Bipolar',
                  value: 'Transtorno Bipolar',
                },
                {
                  checked: false,
                  title: 'Transtorno Obsessivo - compulsivo',
                  value: 'Transtorno Obsessivo - compulsivo',
                },
                {
                  checked: false,
                  title: 'Outros',
                  value: comportamento_outros,
                },
              ]);
              setPersonalidade([
                {
                  checked: false,
                  title: 'Transtornos de Personalidade',
                  value: 'Transtornos de Personalidade',
                },
                { checked: false, title: 'Psicopatia', value: 'Psicopatia' },
                {
                  checked: false,
                  title: 'Serial Killers',
                  value: 'Serial Killers',
                },
              ]);
              setEgresse(startValue as any);
              setIsOpen(false);
              setAvatar(null);
              localStorage.removeItem('enderecos');
            },
          );
        },
        {
          pending: 'Cadastrando...',
          error: { render: ({ data }: any) => data },
          success: 'Egresso cadastrado com sucesso',
        },
      );
    } else {
      toast.promise(
        async () => {
          // await api.put(`/egresses/${egresse?.id}`, formData);
          await updateEgresse({
            ...egresse,
            extensao_horario:
              egresse?.extensao_horario == '-' ||
              egresse?.extensao_horario == ''
                ? false
                : egresse?.extensao_horario,
            numero_processo1:
              processos && processos?.length > 0
                ? processos?.map(processo => String(processo)).join(',')
                : null,
            codigo_penal:
              artigos && artigos?.length > 0
                ? artigos?.map(artigo => String(artigo)).join(',')
                : null,
            experiencias_profissional1:
              experiencias_profissionais &&
              experiencias_profissionais?.length > 0
                ? experiencias_profissionais
                    ?.map(experiencia => String(experiencia))
                    .join('&')
                : null,
            doencas_pre_existentes:
              doencas_existentes && doencas_existentes?.length > 0
                ? doencas_existentes?.map(doenca => String(doenca)).join('&')
                : null,
            doencas_adquiridas:
              doencas_ja_adquiridas && doencas_ja_adquiridas?.length > 0
                ? doencas_ja_adquiridas?.map(doenca => String(doenca)).join('&')
                : null,
            ...resto,
            endereco: null,
            avatar,
          } as any);

          if (isCreate) {
            setIsCreate(false);
            setTab(1);
            setCursos(
              courses_concern?.map(item => ({ ...item, checked: false })),
            );
            setComportamental([
              { checked: false, title: 'Ansiedade', value: 'Ansiedade' },
              { checked: false, title: 'Depressão', value: 'Depressão' },
              {
                checked: false,
                title: 'Esquizofrenia',
                value: 'Esquizofrenia',
              },
              {
                checked: false,
                title: 'Transtornos Alimentares',
                value: 'Transtornos Alimentares',
              },
              {
                checked: false,
                title: 'Estresse pós-traumático',
                value: 'Estresse pós-traumático',
              },
              { checked: false, title: 'Somatização', value: 'Somatização' },
              {
                checked: false,
                title: 'Transtorno Bipolar',
                value: 'Transtorno Bipolar',
              },
              {
                checked: false,
                title: 'Transtorno Obsessivo - compulsivo',
                value: 'Transtorno Obsessivo - compulsivo',
              },
              { checked: false, title: 'Outros', value: comportamento_outros },
            ]);
            setPersonalidade([
              {
                checked: false,
                title: 'Transtornos de Personalidade',
                value: 'Transtornos de Personalidade',
              },
              { checked: false, title: 'Psicopatia', value: 'Psicopatia' },
              {
                checked: false,
                title: 'Serial Killers',
                value: 'Serial Killers',
              },
            ]);
            setEgresse(startValue as any);
            setIsOpen(false);
            setAvatar(null);
            localStorage.removeItem('enderecos');
          }
        },
        {
          pending: 'Editando...',
          error: { render: ({ data }: any) => data },
          success: 'Egresso editado com sucesso',
        },
      );
    }
  };

  const { mutate } = useUpdateEgresses(onSubmit, queryKeysEgresse);

  const handleNextStep = async (newData: any) => {
    delete newData.acompanhamentos_psicologogico;
    delete newData.acompanhamentos_psicopedagogicos;
    delete newData.acompanhamentos_sociais;
    delete newData.alvaras;
    delete newData.avatar_id;
    delete newData.created_at;
    delete newData.cursos;
    delete newData.deleted_at;
    delete newData.inRound;
    delete newData.pads;
    delete newData.rondas;
    delete newData.updated_at;
    delete newData.deleted_at;
    delete newData.trabalha;
    delete newData.status;
    delete newData.cursos_interesse;

    // delete newData.naturalidade;
    // delete newData.nacionalidade;
    // delete newData.complemento_endereco;
    // delete newData.complemento_endereco_trabalho;
    if (isCreate) {
      // @ts-ignore
      delete newData.id;
    }

    // @ts-ignore
    setEgresse(prevData => {
      if (prevData) {
        delete prevData.acompanhamentos_psicologogico;
        delete prevData.acompanhamentos_psicopedagogicos;
        delete prevData.acompanhamentos_sociais;
        delete prevData.alvaras;
        delete prevData.avatar_id;
        delete prevData.created_at;
        delete prevData.cursos;
        delete prevData.deleted_at;
        delete prevData.inRound;
        delete prevData.pads;
        delete prevData.rondas;
        delete prevData.updated_at;
        delete prevData.deleted_at;
        delete prevData.trabalha;
        delete prevData.status;
        delete prevData.cursos_interesse;
      }

      console.log({ ...prevData, ...newData });

      return { ...prevData, ...newData };
    });

    if (!isCreate) {
      // @ts-ignore
      setEgresse(prev => ({ ...prev, ...newData }));
      mutate();
    } else if (tab < steps.length) {
      setTab(tab + 1);
    } else {
      // @ts-ignore
      setEgresse(prev => ({ ...prev, ...newData }));
      mutate();
    }

    executeScroll();
  };
  const handleBackStep = async () => {
    if (tab > 1) {
      setTab(tab - 1);
      executeScroll();
    } else {
      setIsOpen(false);
    }
  };

  const steps = [
    {
      content: (
        <FormPart1
          initialValues={egresse ?? startValue}
          handleNextStep={handleNextStep}
          handleBackStep={handleBackStep}
          useStateAvatar={[avatar, setAvatar]}
          isCreate={isCreate}
        />
      ),
      label: 'Dados Pessoais',
    },
    {
      content: (
        <FormPart2
          initialValues={egresse}
          handleNextStep={handleNextStep}
          handleBackStep={handleBackStep}
          processos={processos}
          setProcessos={setProcessos}
          artigos={artigos}
          setArtigos={setArtigos}
          isCreate={isCreate}
        />
      ),
      label: 'Dados Processuais',
    },
    {
      content: (
        <FormPart3
          initialValues={egresse}
          handleNextStep={handleNextStep}
          handleBackStep={handleBackStep}
          isCreate={isCreate}
        />
      ),
      label: 'Dados Familiares',
    },
    {
      content: (
        <FormPart4
          initialValues={egresse}
          handleNextStep={handleNextStep}
          handleBackStep={handleBackStep}
          useStateCourses={[cursos, setCursos]}
          enderecos={enderecos}
          setEnderecos={setEnderecos}
          isCreate={isCreate}
        />
      ),
      label: 'Endereço',
    },
    {
      content: (
        <FormPart5
          initialValues={egresse}
          handleNextStep={handleNextStep}
          handleBackStep={handleBackStep}
          useStateCourses={[cursos, setCursos]}
          experiencias_profissionais={experiencias_profissionais}
          setExperienciasProfissionais={setExperienciasProfissionais}
          isCreate={isCreate}
        />
      ),
      label: 'Perfil Profissional',
    },
    {
      content: (
        <FormPart6
          initialValues={egresse}
          handleNextStep={handleNextStep}
          handleBackStep={handleBackStep}
          useStateComportamental={[comportamental, setComportamental]}
          useStateComportamental_outros={[
            comportamento_outros,
            set_comportamento_outros,
          ]}
          useStatePersonalidade={[personalidade, setPersonalidade]}
          isCreate={isCreate}
          doencas_existentes={doencas_existentes}
          setDoencasExistentes={setDoencasExistentes}
          doencas_adquiridas={doencas_ja_adquiridas}
          setDoencasAdquiridas={setDoencasJaAdquiridas}
        />
      ),
      label: 'Perfil Biopsicossocial',
    },
  ];

  React.useEffect(() => {
    console.log(cursos);
  }, [cursos]);

  const myRef = React.useRef<any>(null);

  const executeScroll = () =>
    myRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });

  const toggle = async () => {
    setIsCreate(false);
    setTab(1);
    setCursos(courses_concern?.map(item => ({ ...item, checked: false })));
    setComportamental([
      { checked: false, title: 'Ansiedade', value: 'Ansiedade' },
      { checked: false, title: 'Depressão', value: 'Depressão' },
      { checked: false, title: 'Esquizofrenia', value: 'Esquizofrenia' },
      {
        checked: false,
        title: 'Transtornos Alimentares',
        value: 'Transtornos Alimentares',
      },
      {
        checked: false,
        title: 'Estresse pós-traumático',
        value: 'Estresse pós-traumático',
      },
      { checked: false, title: 'Somatização', value: 'Somatização' },
      {
        checked: false,
        title: 'Transtorno Bipolar',
        value: 'Transtorno Bipolar',
      },
      {
        checked: false,
        title: 'Transtorno Obsessivo - compulsivo',
        value: 'Transtorno Obsessivo - compulsivo',
      },
      { checked: false, title: 'Outros', value: comportamento_outros },
    ]);
    setPersonalidade([
      {
        checked: false,
        title: 'Transtornos de Personalidade',
        value: 'Transtornos de Personalidade',
      },
      { checked: false, title: 'Psicopatia', value: 'Psicopatia' },
      { checked: false, title: 'Serial Killers', value: 'Serial Killers' },
    ]);
    setEgresse(startValue as any);
    setIsOpen(false);
    setAvatar(null);
  };

  React.useEffect(() => {
    if (egresse) {
      setProcessos(
        egresse?.numero_processo1
          ? egresse?.numero_processo1
              ?.split(',')
              .map((item: any) => item.trim())
          : [],
      );
      setArtigos(
        egresse?.codigo_penal
          ? egresse?.codigo_penal?.split(',').map((item: any) => item.trim())
          : [],
      );
      setExperienciasProfissionais(
        egresse?.experiencias_profissional1
          ? egresse?.experiencias_profissional1
              .split('&')
              .map((item: any) => item.trim())
          : [],
      );

      setDoencasExistentes(
        egresse?.doencas_pre_existentes
          ? egresse?.doencas_pre_existentes
              .split('&')
              .map((item: any) => item.trim())
          : [],
      );
      setDoencasJaAdquiridas(
        egresse?.doencas_adquiridas
          ? egresse?.doencas_adquiridas
              .split('&')
              .map((item: any) => item.trim())
          : [],
      );
    }

    if (tab < 5) {
      const coursesInitial = courses_concern?.map(item => ({
        ...item,
        checked: false,
      }));
      const initialCursos = egresse?.curso_interesse
        .split(',')
        .map((item: any) => Number(item));
      const initialComportamental =
        egresse?.componente_psicologico_comportamental.split('&');
      const initialPersonalidade =
        egresse?.componente_psicologico_personalidade.split('&');
      console.log(initialComportamental);
      if (isOpen === true && egresse && !isCreate) {
        setComportamental(
          comportamental.map((comportamental: any) =>
            initialComportamental?.find(
              (initial: any) => String(comportamental.title) == String(initial),
            )
              ? { ...comportamental, checked: true }
              : { ...comportamental, checked: false },
          ),
        );
        setPersonalidade(
          personalidade.map((personalidade: any) =>
            initialPersonalidade?.find(
              (initial: any) => String(personalidade.title) == String(initial),
            )
              ? { ...personalidade, checked: true }
              : { ...personalidade, checked: false },
          ),
        );
        setCursos(
          coursesInitial?.map(curso =>
            initialCursos.find(
              (initial: any) => Number(curso.id) == Number(initial),
            )
              ? { ...curso, checked: true }
              : { ...curso, checked: false },
          ),
        );
      } else {
        setCursos(coursesInitial?.map(curso => ({ ...curso, checked: false })));
      }
    }
  }, [isOpen, egresse, courses_concern]);

  React.useEffect(() => {
    localStorage.removeItem('enderecos');
    setTab(1);
    if (!isOpen) {
      toggle();
    }
  }, [isOpen]);

  React.useEffect(() => {
    window.onpopstate = function () {
      // history.got(1);
      history.go(1);
    };
  }, []);

  return (
    <div>
      <div ref={myRef} />
      {/* <ModalHeader> */}
      {/* <div>{isCreate ? 'Cadastrar Egresso' : 'Editar Egresso'}</div> */}
      {/* <AiFillCloseCircle /> */}
      {/* </ModalHeader> */}
      {/* <NavBar /> */}

      {/* <div style={{
        display: "flex", flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', height: 100, background: '#1B7E9F', padding: 10,
      }}
      >
        <div style={{ display: "flex", flexDirection: 'row', alignItems: 'center' }}>
          <img src="/assets/social_icon.png" alt="icone-albergado" />
          <div style={{ marginLeft: 10, marginTop: 10 }}>
            <h1 style={{ color: '#fff', fontSize: 18, fontWeight: 'bold' }}>E-ALBERGADO</h1>
            <h2 style={{
              color: '#fff', fontSize: 16, fontWeight: 'normal', marginTop: -5,
            }}
            >
              Sistema de controle de egressos
            </h2>
          </div>
        </div>
        <h1 style={{ fontSize: 22, color: '#fff' }}>{isCreate ? 'Cadastrar Egresso' : 'Editar Egresso'}</h1>
        <div />
        <div />
      </div> */}

      <div>
        {/* {window.screen.width > 1000
        && (
        <StepProgress
          useStateTab={[tab, setTab]}
          steps={steps}
        />
        )} */}
        {/* <div className="position-relative">
          <div className="d-flex position-relative" style={{ gap: '3%', zIndex: 1 }}>
            {steps.map((item, i) => (
              <div
                key={i}
                className="d-flex align-items-center flex-column"
                style={{ cursor: !isCreate ? 'pointer' : 'auto' }}
                onClick={() => {
                  if (!isCreate) {
                    setTab(i + 1);
                  }
                }}
              >
                <span
                  className="d-flex justify-content-center"
                  style={{
                    background: tab - 1 >= i ? '#007BFF' : '#E9ECEF', borderRadius: "50%", width: 30, height: 30, borderColor: 'none',
                  }}
                >
                  <p className={`mt-1 ${tab - 1 >= i ? `text-white` : ''}`}>{i + 1}</p>
                </span>
                <p style={{ color: tab - 1 >= i ? '#007BFF' : '#000' }}>{item.label}</p>
              </div>
            ))}
          </div>
          {tab === 1 && (
            <Progress
              value={0}
              style={{
                zIndex: 0, width: "85%", height: 2, left: 50,
              }}
              className="position-absolute top-0 mt-3"
            />
          )}
          {tab === 2 && (
            <Progress
              value={30}
              style={{
                zIndex: 0, width: "85%", height: 2, left: 50,
              }}
              className="position-absolute  top-0 mt-3"
            />
          )}
          {tab === 3 && (
            <Progress
              value={50}
              style={{
                zIndex: 0, width: "85%", height: 2, left: 50,
              }}
              className="position-absolute  top-0 mt-3"
            />
          )}
          {tab === 4 && (
            <Progress
              value={70}
              style={{
                zIndex: 0, width: "85%", height: 2, left: 50,
              }}
              className="position-absolute  top-0 mt-3"
            />
          )}
          {tab === 5 && (
            <Progress
              value={90}
              style={{
                zIndex: 0, width: "85%", height: 2, left: 50,
              }}
              className="position-absolute top-0 mt-3"
            />
          )}

          {tab === 6 && (
          <Progress
            value={100}
            style={{
              zIndex: 0, width: "85%", height: 2, left: 50,
            }}
            className="position-absolute top-0 mt-3"
          />
          )}
        </div> */}

        <SideBar
          isCreate={isCreate}
          tab={tab}
          setTab={setTab}
          setIsOpen={setIsOpen}
        >
          {/* { steps[tab - 1].content } */}
          {tab == 1 ? (
            <FormPart1
              initialValues={egresse ?? startValue}
              handleNextStep={handleNextStep}
              handleBackStep={handleBackStep}
              useStateAvatar={[avatar, setAvatar]}
              isCreate={isCreate}
              setIsOpen={setIsOpen}
            />
          ) : tab == 2 ? (
            <FormPart2
              initialValues={egresse}
              handleNextStep={handleNextStep}
              handleBackStep={handleBackStep}
              processos={processos}
              artigos={artigos}
              setArtigos={setArtigos}
              isCreate={isCreate}
              setProcessos={setProcessos}
            />
          ) : tab == 3 ? (
            <FormPart3
              initialValues={egresse}
              handleNextStep={handleNextStep}
              handleBackStep={handleBackStep}
              isCreate={isCreate}
            />
          ) : tab == 4 ? (
            <FormPart4
              initialValues={egresse}
              handleNextStep={handleNextStep}
              handleBackStep={handleBackStep}
              useStateCourses={[cursos, setCursos]}
              enderecos={enderecos}
              setEnderecos={setEnderecos}
              isCreate={isCreate}
            />
          ) : tab == 5 ? (
            <FormPart5
              initialValues={egresse}
              handleNextStep={handleNextStep}
              handleBackStep={handleBackStep}
              useStateCourses={[cursos, setCursos]}
              experiencias_profissionais={experiencias_profissionais}
              setExperienciasProfissionais={setExperienciasProfissionais}
              isCreate={isCreate}
            />
          ) : tab == 6 ? (
            <FormPart6
              initialValues={egresse}
              handleNextStep={handleNextStep}
              handleBackStep={handleBackStep}
              useStateComportamental={[comportamental, setComportamental]}
              useStateComportamental_outros={[
                comportamento_outros,
                set_comportamento_outros,
              ]}
              useStatePersonalidade={[personalidade, setPersonalidade]}
              isCreate={isCreate}
              doencas_existentes={doencas_existentes}
              setDoencasExistentes={setDoencasExistentes}
              doencas_adquiridas={doencas_ja_adquiridas}
              setDoencasAdquiridas={setDoencasJaAdquiridas}
            />
          ) : (
            <div />
          )}
        </SideBar>
      </div>
    </div>
  );
}
