import React from 'react';
import { IoIosArrowForward } from 'react-icons/io';
import { useHistory } from 'react-router-dom';
import { TablePadrão } from '../../../../components/develop-nilton-components/TablePadrão';
import { Pagination } from '../../../../components/Pagination';
// import { SpinnerLoading } from '../../../../components/SpinnerLoading';
import { Loading } from '../../../../components/new-components/Loading';
import { SubmitResponse } from '../../../../components/SubmitResponse';
import { headWidth, thead } from '../controlTableCadastrar';
import { BtnSendToHomologacao } from './BtnSendRoundsToHomologacao';
import { TbodyHomologaçãoGeral } from './TbodyHomologaçãoGeral';
import { Rounds } from '../types';
import { Badge } from '../../../../components/develop-nilton-components/Badge';
import { useAuth } from '../../../../hooks/auth';

type PropsTableGeral = {
  rounds: Rounds | null
  handleUpdate(page?:number):void | Promise<void>
  isDashboard?: boolean
  roundsGeral?: any
}

export function TableGeral({
  rounds, roundsGeral, handleUpdate, isDashboard,
}:PropsTableGeral) {
  const history = useHistory();
  const { profile } = useAuth();

  function handleCondicionStatus(item:any) {
    if (item.homologado === 'T') {
      return <div data-tip="Ronda concluída/homologada"><Badge type="success">Concluído</Badge></div>;
    }

    if (item.em_homologacao === "T" && item.homologado === 'F') {
      return <div data-tip="Ronda pendente de homologação"><Badge type="info">Pendente</Badge></div>;
    }

    if (item.em_homologacao === "F" && !item.homologado) {
      return <div data-tip="Ronda em andamento"><Badge type="warning">Em andamento</Badge></div>;
    }

    return '';
  }

  function handleCondicionSendHomologar(item: any) {
    if (!item.homologado && item.em_homologacao === "F") {
      return (
        <BtnSendToHomologacao
          item={item}
          key={item.id}
          handleUpdate={handleUpdate}
        />
      );
    }
    return <div />;
  }

  return (
    <div>

      <TablePadrão
        thead={thead}
        headWidth={headWidth}
      >
        {rounds?.data?.map((item, i) => (
          <TbodyHomologaçãoGeral
            data-testid="table"
            key={i}
            item={item}
            widthBody={headWidth}
            handleCondicionStatus={handleCondicionStatus}
            handleCondicionSendHomologar={handleCondicionSendHomologar}
            handleUpdate={handleUpdate}
            roundsGeral={roundsGeral}
          />
        ))}
      </TablePadrão>

      {rounds && !isDashboard && rounds?.data.length !== 0 && (<Pagination meta={rounds?.meta} setPage={handleUpdate} />)}

      {!rounds && (
        <div
          style={{
            display: "flex", justifyContent: "center", alignItems: "center",
          }}
          data-testid="load"
        >
          {/* <SpinnerLoading size={40} /> */}
          <Loading />
        </div>
      )}

      {rounds?.data?.length === 0 && (
      <SubmitResponse submitResponse={{ message: "Não foram identificadas rondas.", status: "danger", title: "Aviso" }} />
      )}

      {isDashboard && rounds && rounds?.data?.length > 0 && profile?.title === 'Fiscal' && (
        <div
          onClick={() => history.push('/cadastrar-ronda')}
          style={{
            cursor: 'pointer', display: "flex", justifyContent: "flex-end", alignItems: 'center',
          }}
        >
          <h1 style={{ fontSize: 18, marginBottom: 0 }}>Visualizar todas as rondas</h1>
          <IoIosArrowForward />
        </div>
      )}
    </div>
  );
}
