import React from 'react';
import { toast } from 'react-toastify';
import ReactTooltip from 'react-tooltip';
import { Badge } from 'reactstrap';
import styled from 'styled-components';
import { ButtonAdd } from '../../../components/new-components/ButtonAdd';
import { IconEdit } from '../../../components/new-components/IconEdit';
// import { InputSearch } from '../../../components/new-components/InputSearch';
import InputSearch from "../../Fiscal/components/InputSearch";
import { Loading } from '../../../components/new-components/Loading';
import { Pagination } from '../../../components/new-components/Pagination';
import { TableComponent } from '../../../components/new-components/Table';
import { UsersFormModal } from '../../../components/new-components/UsersFormModal';
import { SectionHeader } from '../../../components/SectionHeader';
import { useGetUsers } from '../../../hooks/Users/useGetUsers';
import { useUpdateUsers } from '../../../hooks/Users/useUpdateUsers';
import api from '../../../services/api';
import { IUser } from '../../../types/IUser';
import { orderPage } from '../../../utils/orderPages';

export function Usuarios() {
  const [search, setSearch] = React.useState('');
  const [page, setPage] = React.useState(1);
  const { users, isLoading } = useGetUsers({ page, search });

  const [isOpenUsersForm, setIsOpenUsersForm] = React.useState(false);
  const [isCreate, setIsCreate] = React.useState(false);
  const [user, setUser] = React.useState<IUser |null>(null);

  const handleChangeHabilitado = async (props:{id: number, habilitado: string}) => {
    const { id, habilitado } = props;
    toast.promise(
      api.put(`users/${id}`, { habilitado }),
      {
        pending: habilitado === "T" ? 'Habilitando usuário...' : "Desabilitando usuário...",
        success: habilitado === "T" ? 'Usuário habilitado' : "Usuário desabilitado",
        error: { render: ({ data }:any) => data.response.data.message },
      },
    );
  };

  const { mutate } = useUpdateUsers(handleChangeHabilitado, { query: 'users', page, search });

  async function searchEgresses(page: number, search: string) {
    setPage(page);
    setSearch(search);
  }

  return (
    <WrapperContainer>
      <UsersFormModal
        useStateIsOpen={[isOpenUsersForm, setIsOpenUsersForm]}
        useStateIsCreate={[isCreate, setIsCreate]}
        useStateUser={[user, setUser]}
        queryKeysUsers={{ page, search }}
      />

      <SectionHeader subtitle="Usuários" />

      <div style={{ marginBottom: 50 }} className="content">
        {/* <InputSearch
            label="Pesquisar Usuário"
            id="search"
            placeholder="Pesquise pelo nome ou CPF"
            keySearch="name"
            data={users?.data ?? []}
            useStateSearch={[search, setSearch]}
            button={(
              <ButtonAdd
                onClick={() => {
                  setIsCreate(true);
                  setIsOpenUsersForm(true);
                }}
              />
            )}
          /> */}
        <InputSearch
          loading={isLoading}
          setSearch={searchEgresses}
          autosearch
          title="Pesquisar Usuário"
          placeholder="Digite o nome do usuário"
          buttonText="Buscar"
          description=""
          button={(
            <ButtonAdd
              style={{ marginLeft: 10, background: '#68996D' }}
              onClick={() => {
                setIsCreate(true);
                setIsOpenUsersForm(true);
              }}
            />
                )}
        />
      </div>

      {isLoading ? <Loading />
        : (
          <div>
            <TableComponent data={users?.data.map((user, i) => ({
              Ordem: orderPage(i, page, users.data.length),
              Nome: user.name.length < 25 ? user.name : `${user.name.substring(0, 25)}...`,
              CPF: user.cpf,
              Perfil: user.profile.title,
              Status: (
                <div>
                  {user.habilitado === "T"
                    ? (
                      <Badge
                        color="success"
                        style={{ cursor: 'pointer' }}
                        onClick={() => mutate({ id: user.id, habilitado: "F" })}
                      >
                        Habilitado
                      </Badge>
                    )
                    : (
                      <Badge
                        color="danger"
                        style={{ cursor: 'pointer' }}
                        onClick={() => mutate({ id: user.id, habilitado: "T" })}
                      >
                        Desabilitado
                      </Badge>
                    )}
                </div>
              ),
              Opções: (
                <div className="d-flex justify-content-flex-start">
                  <ReactTooltip />
                  <div onClick={() => {
                    setUser(user);
                    setIsOpenUsersForm(true);
                  }}
                  >
                    <IconEdit cursor tooltipMessage="Editar" />
                  </div>
                </div>
              ),
            }))}
            />
          </div>
        )}
      {!isLoading && <Pagination meta={users?.meta} useStatePage={[page, setPage]} />}
    </WrapperContainer>
  );
}

const WrapperContainer = styled.div`
  width: 100%;

  @media (max-width: 640px) {
    width: 230px;
  }
  @media (max-width: 320px) {
    width: 100px;
  }

  .content {
    width: 100%;

    & div {
      display: flex;
    }

    select:focus {
      outline: none;
    }

    textarea:focus {
      outline: none;
    }

    & input {
      width: 100%;
    }

    & input:focus {
      outline: none !important;
    }

    .inputfile {
      width: 0.1px;
      height: 0.1px;
      opacity: 0;
      overflow: hidden;
      position: absolute;
      z-index: -1;
    }
    .inputfile:focus + label {
      outline: 1px dotted #000;
      outline: -webkit-focus-ring-color auto 5px;
    }
    .inputfile + label * {
      pointer-events: none;
    }
  }
`;
