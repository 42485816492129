import React, { useEffect, useState } from 'react';
import api from '../../../../services/api';

export function useGetIndicadores() {
  const [indicador, setIndicador] = useState<any>(null);

  async function handleSetIndicador(type: string, year?: string | number, trimestre?: string) {
    const { data } = await api.get('/reports/relatorio_psicopedagico_indicadores', {
      params: {
        [type]: true,
        year,
        trimestre,
      },
    });
    setIndicador(data);
  }

  return { indicador, handleSetIndicador };
}
