import React from 'react';
import { SectionHeader } from '../../../components/SectionHeader';
import { FatoresInt } from './components/FatoresInt';

export function RelatorioPsicopedagogo() {
  return (
    <div style={{ width: "100%" }}>
      <SectionHeader subtitle="Relatórios" />

      <FatoresInt />
    </div>
  );
}
