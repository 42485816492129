import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import api from "../../../../services/api";

type Egressos = {
  meta: {last_page: number}
  data: {
    id: number
    nome: string
    tipo_regime: string
    bairro: string
    cidade: string
    municipio: string
    endereco: string
    rondas: {
      com_ocorrencia: {
        updated_at: string
        ocorrencia: {
          id: number
          name:string
        }
        round_id: number
      }[],
      sem_ocorrencia: {
        updated_at: string
        ocorrencia: {
          id: number
          name:string
        }
        round_id: number
      }[],
      total: 0
    }
    telefone_recado: string
    cep: string
    avatar: {url_file: string}
  }[]
}

type Egressos2 = {
  meta: any
  data: {
    id: number
    nome: string
    tipo_regime: string
    bairro: string
    cidade: string
    municipio: string
    endereco: string
    cpf: string
    rondas: {
      com_ocorrencia: {
        updated_at: string
        ocorrencia: {
          id: number
          name:string
        }
        round_id: number
      }[],
      sem_ocorrencia: {
        updated_at: string
        ocorrencia: {
          id: number
          name:string
        }
        round_id: number
      }[],
      total: 0
    }
    telefone_recado: string
    cep: string
    avatar: {url_file: string}
  }[]
}

export function useEgresses() {
  const [egresses, setEgresses] = useState<Egressos | null>(null);
  const [load, setLoad] = useState(false);

  async function handleSetEgresses(page = 1, search?: string) {
    setLoad(true);
    const { data } = await api.get('/egresses', {
      params: {
        page,
        habilidadeOrNameOrAreaOrBairroOrLocalidadeOrMunicipio: search,
      },
    });

    const arr = data.data;
    // const compareRound = (a: any, b: any) => ((a.inRound.length < b.inRound.length && a.endereco.filter((add: any) => add.atual == true).length > 0) ? -1 : 0);
    // arr.sort(compareRound);

    // objs.sort((a, b) => ((a.last_nom > b.last_nom) ? 1 : ((b.last_nom > a.last_nom) ? -1 : 0)));

    /*  arr.sort((a: any, b: any) => (
      (a.endereco.filter((add: any) => add.atual == true).length < b.endereco.filter((add: any) => add.atual == true).length)
        ? 1
        : (b.endereco.filter((add: any) => add.atual == true).length < a.endereco.filter((add: any) => add.atual == true).length)
          ? -1 : 0)); */

    try {
      arr.sort((a: any, b: any) => (
        (a.inRound.length > b.inRound.length)
          ? 1
          : (b.inRound.length > a.inRound.length)
            ? -1 : 0)).sort((a: any, b: any) => (
        (a.endereco.filter((add: any) => add.atual == true).length < b.endereco.filter((add: any) => add.atual == true).length)
          ? 1
          : (b.endereco.filter((add: any) => add.atual == true).length < a.endereco.filter((add: any) => add.atual == true).length)
            ? -1 : 0));

      setEgresses({ ...data, data: arr });
      setLoad(false);
    } catch (error) {
      setEgresses(data);
      setLoad(false);
    }

    // console.log(arr.map((a1: any) => ({ inRound: a1.inRound })));
    // console.log(arr.map((a2: any) => ({ endereco: a2.endereco.filter((add: any) => add.atual == true) })));
  }
  useEffect(() => {
    handleSetEgresses();
  }, []);
  return { egresses, handleSetEgresses, load };
}
