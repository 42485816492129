import React from 'react';
import { TablePadrão, Td, Tr } from '../../../../components/develop-nilton-components/TablePadrão';
import { Pagination } from '../../../../components/Pagination';
import { SpinnerLoading } from '../../../../components/SpinnerLoading';
import { ITableGeral } from '../type';
import { TbodyGeral } from './TbodyGeral';

export function TableGeral({ egresses, load, handleSetEgresses }: ITableGeral) {
  const headWidth = ["5%", "45%", "25%", "25%"];
  const thead = ["#", "Nome", "Status", "Opções"];
  return (
    <div>
      <TablePadrão
        headWidth={headWidth}
        thead={thead}
      >
        {load && <Tr><Td width="100%"><SpinnerLoading /></Td></Tr>}
        {egresses?.data.map((item, i) => (
          <TbodyGeral
            headWidth={headWidth}
            egress={item}
            index={i + 1}
          />
        ))}
        {egresses?.meta && (
        <Tr>
          <Td width="100%">
            <Pagination meta={egresses.meta} setPage={handleSetEgresses} load={load} />
          </Td>
        </Tr>
        )}
      </TablePadrão>
    </div>
  );
}
