import React, { useEffect, useState } from 'react';
import api from '../../../../services/api';

export function useReportFisca() {
  const [report, setReport] = useState<any>(null);

  useEffect(() => {
    async function handleSetReport() {
      const { data } = await api.get('https://api-albergado.msbtec.com.br/reports/all?anual=true');
      setReport(data);
    }
    handleSetReport();
  }, []);
  return { report };
}
