/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { TablePadrão } from '../../../../components/develop-nilton-components/TablePadrão';
// import { Pagination } from '../../../../components/Pagination';
import { Pagination } from '../../../../components/new-components/Pagination';
import { SpinnerLoading } from '../../../../components/SpinnerLoading';
import { TbodyTablePendente } from './TbodyPendente';
import { Loading } from '../../../../components/new-components/Loading';

type PropsTableGeral = {
  rounds: any
  handleUpdate(page?:number): void | Promise<void>
}

export function TablePendente({ handleUpdate, rounds }:PropsTableGeral) {
  const headWidth = ["10%", "50%", "50%", "50%", "50%", "50%", "50%"];

  const [loading, setLoading] = useState(true);
  const [page, setPage] = React.useState(1);
  useEffect(() => { setLoading(!loading); }, [rounds, page]);
  useEffect(() => {
    handleUpdate(page);
  }, [page]);
  return (
    <div>
      {(!rounds || loading) ? (
        <CenterComponent>
          {/* <SpinnerLoading size={70} /> */}
          <Loading />
        </CenterComponent>
      ) : (
        <TablePadrão
          headWidth={headWidth}
          thead={["#", "Fiscal", "Nome da Ronda", "Data da Ronda", "Status", "Homologar"]}
        >
          {rounds?.data.map((item: any, i: number) => (
            <TbodyTablePendente
              key={item.id}
              index={i + 1}
              roundsItems={item}
              headWidth={headWidth}
            />
          ))}

        </TablePadrão>
      )}
      {rounds && rounds.data.length !== 0
      && (
      <Pagination
        meta={rounds?.meta}
        useStatePage={[page, setPage]}
      />
      )}

    </div>
  );
}

const CenterComponent = styled.div`
  display: flex;
  justify-content: center;
  margin: 10% 0;
`;
