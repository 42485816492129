import styled from "styled-components";

export const ConteinerPagination = styled.div`
.pagination {
    margin: 15px auto;
    display: flex;
    list-style: none;
    outline: none;
  }

  .pagination > .active > a{
    background-color: #1B7E9F;
    border-color: #1B7E9F;
    color: #fff;
  }

  .pagination > li > a{
    border: 1px solid #1B7E9F;
    padding: 5px 10px;
    outline: none;
    cursor: pointer;
  }

  .pagination > .active > a, .pagination > .active > span, .pagination > .active > a:hover, .pagination > .active > span:hover, .pagination > .active > a:focus, .pagination > .active > span:focus{
    background-color: #1B7E9F;
    border-color: #1B7E9F;
    outline: none;
  }

  .pagination > li > a, .pagination > li > span{
    color: #1B7E9F;
  }

  .pagination > li:first-child > a, .pagination > li:first-child > span, .pagination > li:last-child > a, .pagination > li:last-child > span{
    border-radius: 0px;
  }
`;
