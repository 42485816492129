import React, { useState } from 'react';
import { Chart } from 'react-chartjs-2';
import { IoIosDocument } from 'react-icons/io';
import ReactModal from 'react-modal';
import { useHistory } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import { IconAdd } from '../../../../components/develop-nilton-components/IconAdd';
import { IconDetails } from '../../../../components/develop-nilton-components/IconDetails';
import { IconDocument } from '../../../../components/develop-nilton-components/IconDocument';
import { ModalDetailsEgresse } from '../../../../components/develop-nilton-components/ModalDetailsEgresses';
import { Td, Tr } from '../../../../components/develop-nilton-components/TablePadrão';
import { data } from '../types';

type PropsTbodyTable = {
  egresse: data
  index: number
  headWidth: string[]
}

export function TbodyConcluidos({ egresse, index, headWidth }:PropsTbodyTable) {
  const [ModalIsOpen, setModalIsOpen] = useState(false);

  const history = useHistory();

  function handleIsOpenModalRelatorio() {
    history.push({ pathname: "/perfil/historico", state: egresse });
  }

  function handleModalIsOpen() {
    setModalIsOpen(!ModalIsOpen);
  }

  return (
    <Tr data-testid="table_egress">
      <Td width={headWidth[0]}>{index}</Td>
      <Td width={headWidth[1]}>{egresse?.nome}</Td>
      <Td width={headWidth[2]}>
        <div onClick={handleModalIsOpen} className="mr-3">
          <IconDetails
            cursor
            tooltipMessage="Dados do egressos"
            size={30}

          />
          <ReactTooltip />
        </div>
        <div onClick={handleIsOpenModalRelatorio} data-tip="Relatórios individuais">
          <IconDocument cursor tooltipMessage="Relatórios individuais" />
        </div>
        <ModalDetailsEgresse
          egresse={egresse}
          handleIsOpen={handleModalIsOpen}
          isOpen={ModalIsOpen}
        />
      </Td>

    </Tr>
  );
}
