import React, { useEffect, useState } from 'react';
import { addHours, format } from 'date-fns';
import { BsEyeFill, BsFillPlusCircleFill } from 'react-icons/bs';
import { MdPlaylistAddCheck } from 'react-icons/md';
import ReactTooltip from 'react-tooltip';
import { AiFillMinusCircle, AiOutlineCheck } from 'react-icons/ai';
import { IoSwapVertical } from 'react-icons/io5';
import styled from 'styled-components';
import {
  Td,
  Tr,
} from '../../../../components/develop-nilton-components/TablePadrão';
import { useGetEgressesById } from '../../../Adm/HomologarRonda/hooks/useGetEgressesById';
import { ModalAddComprovante } from './ModalAddComprovante';
import { useDeleteEgressesOfRounds } from '../hooks/useDeleteEgressesOfRounds';
import { Badge } from '../../../../components/develop-nilton-components/Badge';
import { ModalDetailsEgresse } from '../../../../components/develop-nilton-components/ModalDetailsEgresses';
import { IEgresses } from '../../../../types/IEgresses';
import { useEgresses } from '../../../../hooks/Egresses';
import { ModalHistoryComprovanteFigma } from './ModalHistoryComprovanteFigma';
import api from '../../../../services/api';
import { ModalConfirmRemoverRonda } from './ModalConfirmRemoverRonda';
import { ModalHistoryComprovanteFigmaEditable } from './ModalHistoryComprovanteFigmaEditable';

type PropsTbodyConfirmRound = {
  headWidth: string[];
  itemEgresso: any;
  update(): Promise<void>;
  roundEgressesOfFiscal?: any;
  setRoundEgressesOfFiscal?(value: any): void;
  useStateEgresse: [IEgresses | null, (egresse: IEgresses | null) => void];
  useStateIsOpen: [boolean, (boolean: boolean) => void];
  iteration: any;
  first: any;
  selectFirst(i: number): void;
  selectSecond(i: number): void;
};

export function TbodyConfirmRound({
  headWidth,
  itemEgresso,
  update,
  roundEgressesOfFiscal,
  setRoundEgressesOfFiscal,
  useStateEgresse,
  useStateIsOpen,
  iteration,
  first,
  selectFirst,
  selectSecond,
}: PropsTbodyConfirmRound) {
  const [isOpenComprovanteAddModal, setIsOpenComprovanteAddModal] =
    React.useState(false);
  const [isOpenDetailsAddModal, setIsOpenDetailsAddModal] =
    React.useState(false);
  const [isWatchEgresse, setIsWatchEgresse] = useStateIsOpen;
  const [egresse, setEgresse] = useStateEgresse;
  const [
    isOpenComprovanteHistoryAddModal,
    setIsOpenComprovanteHistoryAddModal,
  ] = React.useState(false);
  const { handleDeleteEgressesOfRounds } = useDeleteEgressesOfRounds();
  const [removeModal, setRemoveModal] = useState(false);

  function handleOpenWarningRemoveEgress(): void {
    setRemoveModal(!removeModal);
  }

  async function handleDelete() {
    if (setRoundEgressesOfFiscal && roundEgressesOfFiscal) {
      setRoundEgressesOfFiscal(
        roundEgressesOfFiscal.filter(
          (item: any) => item.egress_id != itemEgresso.egress_id,
        ),
      );
      await handleDeleteEgressesOfRounds(itemEgresso.id);
    }
  }

  function handlesSetIsOpenModalDetailsEgresses() {
    setIsOpenDetailsAddModal(!isOpenDetailsAddModal);
  }

  return (
    <Tr>
      <Td width={headWidth[0]}>{iteration + 1}</Td>

      <Td draggable width={headWidth[1]}>
        {itemEgresso?.egresso.nome}
      </Td>
      <Td draggable width={headWidth[2]}>
        {itemEgresso?.data_homologacao
          ? format(
              addHours(new Date(itemEgresso?.data_homologacao), 0),
              'dd/MM/yyyy HH:mm',
            )
          : '-'}
      </Td>
      <Td width={headWidth[3]}>
        {itemEgresso?.homologado ? (
          <div>
            <Badge type="success">Cumprido</Badge>
          </div>
        ) : (
          <div>
            <Badge type="warning">Pendente</Badge>
          </div>
        )}
      </Td>
      <Td width={headWidth[4]}>
        {itemEgresso?.ocorrencia ? (
          <ActionsStyle>
            <div
              data-tip="Comprovante da ronda adicionado"
              style={{ marginLeft: 15 }}
              onClick={() => {
                setIsOpenComprovanteHistoryAddModal(true);
              }}
            >
              <MdPlaylistAddCheck size={30} cursor="pointer" color="#000" />
            </div>
            {first !== null ? (
              <ActionsStyleEach
                data-tip="Trocar posições"
                onClick={() => selectSecond(iteration)}
              >
                {first !== iteration && (
                  <AiOutlineCheck color="#1B7E9F" size={24} cursor="pointer" />
                )}
              </ActionsStyleEach>
            ) : (
              <ActionsStyleEach
                data-tip="Trocar posições"
                onClick={() => selectFirst(iteration)}
              >
                <IoSwapVertical color="#1B7E9F" size={24} cursor="pointer" />
              </ActionsStyleEach>
            )}
          </ActionsStyle>
        ) : (
          <ActionsStyle>
            <div style={{ display: 'flex' }}>
              <div
                data-tip="Detalhes"
                style={{ marginLeft: 15 }}
                // style={{ display: 'flex', flexDirection: 'row' }}
                onClick={() => {
                  setIsOpenDetailsAddModal(true);
                }}
              >
                <BsEyeFill color="black" size={25} cursor="pointer" />
              </div>

              <div
                onClick={() => {
                  setIsOpenComprovanteAddModal(true);
                }}
                style={{ marginLeft: 15 }}
                // style={{ display: 'flex', flexDirection: 'row' }}
                data-tip="Executar ronda"
              >
                <BsFillPlusCircleFill
                  size={20}
                  cursor="pointer"
                  color="#1B7E9F"
                />
              </div>
            </div>
            <div style={{ display: 'flex' }}>
              <div
                data-tip="Remover da ronda"
                // style={{ display: 'flex', flexDirection: 'row' }}
                style={{ marginLeft: 15 }}
                onClick={handleOpenWarningRemoveEgress}
              >
                <AiFillMinusCircle
                  color="rgba(201, 19, 19, 0.6)"
                  size={24}
                  cursor="pointer"
                />
              </div>
              {first !== null ? (
                <div
                  data-tip="Trocar posições"
                  // style={{ display: 'flex', flexDirection: 'row' }}
                  style={{ marginLeft: 15 }}
                  onClick={() => selectSecond(iteration)}
                >
                  {first !== iteration && (
                    <AiOutlineCheck
                      color="#1B7E9F"
                      size={24}
                      cursor="pointer"
                    />
                  )}
                </div>
              ) : (
                <div
                  data-tip="Trocar posições"
                  // style={{ display: 'flex', flexDirection: 'row' }}
                  style={{ marginLeft: 15 }}
                  onClick={() => selectFirst(iteration)}
                >
                  <IoSwapVertical color="#1B7E9F" size={24} cursor="pointer" />
                </div>
              )}
            </div>

            {/* <div
              data-tip="Detalhes"
              style={{ marginRight: 10, marginBottom: 0 }}
              onClick={() => {
                setIsOpenDetailsAddModal(true);
              }}
            >
              <AiOutlineMenu color="black" size={25} cursor="pointer" />
            </div> */}
          </ActionsStyle>
        )}

        <ReactTooltip />

        <ModalAddComprovante
          round_egresses_egressos={itemEgresso}
          handleSetIsOpen={[
            isOpenComprovanteAddModal,
            setIsOpenComprovanteAddModal,
          ]}
          egresse={egresse}
          handleUpdate={update}
        />

        <ModalDetailsEgresse
          isOpen={isOpenDetailsAddModal}
          handleIsOpen={handlesSetIsOpenModalDetailsEgresses}
          egresse={itemEgresso.egresso}
          useStateEgresse={[egresse, setEgresse]}
          useStateIsOpen={[isWatchEgresse, setIsWatchEgresse]}
        />

        <ModalHistoryComprovanteFigmaEditable
          round_egresses_egressos={itemEgresso}
          handleSetIsOpen={[
            isOpenComprovanteHistoryAddModal,
            setIsOpenComprovanteHistoryAddModal,
          ]}
          egresse={itemEgresso.egresso}
          handleUpdate={update}
        />
        <ModalConfirmRemoverRonda
          isOpen={removeModal}
          title="Confirme a remoção"
          handleIsOpen={handleOpenWarningRemoveEgress}
          action={handleDelete}
        />
      </Td>
    </Tr>
  );
}

const ActionsStyle = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;

  @media only screen and (max-width: 800px) {
    flex-direction: column;
    align-items: center;
  }
`;

const ActionsStyleEach = styled.div`
  display: flex;
  margin-left: 0px;

  @media only screen and (max-width: 800px) {
    margin-left: 15px;
  }
`;
