import React, { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import { BiArrowBack } from 'react-icons/bi';
import { TablePadrão } from '../../../../components/develop-nilton-components/TablePadrão';
import { SectionHeader } from '../../../../components/SectionHeader';
import { SpinnerLoading } from '../../../../components/SpinnerLoading';
import api from '../../../../services/api';

import { TbodyPageEgressesInTheRounds } from './TbodyPageEgressesInTheRounds';

export function PageHomogarRound() {
  const headWidth = ["10%", "50%", "50%", "50%"];
  const history = useHistory();
  const { state } = useLocation<any>();
  const [load, setLoad] = useState(false);

  async function handleHomologarRonda() {
    setLoad(true);
    try {
      await api.put(`/rounds/${state.id}`, { em_homologacao: "T", homologado: "T" });
      toast("Ronda homologada com sucesso.", { type: "success" });
      history.push({ pathname: "/homologar-ronda" });
    } catch (error) {
      // @ts-ignore
      toast(error.response.data.message, { type: "error" });
    }
    setLoad(false);
  }

  return (
    <div style={{ width: "100%" }}>
      <SectionHeader
        subtitle={`${state.title}`}
        isIconBack={(
          <div onClick={() => history.push({ pathname: '/homologar-ronda' })} style={{ cursor: "pointer", marginRight: 10 }}>
            <BiArrowBack size={30} />
          </div>
  )}
        // backPage="/homologar-ronda"
      />
      <TablePadrão thead={["#", "Nome", "Detalhes", "Comprovante"]} headWidth={headWidth}>
        {state.egressos.map((item:any, i:number) => (
          <TbodyPageEgressesInTheRounds
            key={i}
            index={i + 1}
            headWidth={headWidth}
            item={item}
          />
        ))}
      </TablePadrão>
      <Center>
        <button disabled={load} style={{ background: '#1B7E9F', border: 'none' }} className="btn btn-primary btn-lg" onClick={handleHomologarRonda}>
          {load && <SpinnerLoading size={30} />}
          {!load && "Homologar"}
        </button>
      </Center>
    </div>
  );
}

const Center = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
