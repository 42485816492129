import React, { useEffect, useState } from 'react';
import api from '../../../../services/api';

export function useGetProjetoFontes_por_projeto_no_ano() {
  const [fontes_por_projeto_no_ano, set_data_set_turma] = useState<any>(null);

  async function handleFontes_por_projeto_no_ano(year?: string) {
    const { data } = await api.get(`/reports/fontes_por_projeto_no_ano`, { params: { year } });
    set_data_set_turma(data);
  }

  useEffect(() => {
    handleFontes_por_projeto_no_ano();
  }, []);

  return { fontes_por_projeto_no_ano, handleFontes_por_projeto_no_ano };
}
