import React, { useState } from 'react';
import { AbaFilterButtons, AbaFilterContent } from '../../../components/develop-nilton-components/AbaFiltro';
import { SearchEgresses } from '../../../components/develop-nilton-components/SearchEgresses';
import { SectionHeader } from '../../../components/SectionHeader';
import { TableGeral } from './components/TableGeral';
import { TableHistorico } from './components/TableHistorico';
import { useGetEgresses } from './hooks/useGetEgressesGeral';
import { Egresso } from './type';

export function AcompanhamentoPsicoPedagogico() {
  const { egresses, handleSetEgresses, load } = useGetEgresses<Egresso>();
  const [tab, setTab] = useState(1);

  return (
    <div style={{ width: "100%" }}>
      <SectionHeader subtitle="Egressos em acompanhamento" />

      <div style={{ width: "100%" }}>
        <SearchEgresses handleSetSearch={handleSetEgresses} />
      </div>

      <div>
        <AbaFilterButtons buttons={["Geral", "Histórico"]} useStateTab={[tab, setTab]} />
      </div>

      <AbaFilterContent
        tab={tab}
        components={[
          <TableGeral
            egresses={egresses}
            load={load}
            handleSetEgresses={handleSetEgresses}
          />,
          <TableHistorico
            egresses={egresses}
            load={load}
            handleSetEgresses={handleSetEgresses}
          />,
        ]}
      />

    </div>
  );
}
