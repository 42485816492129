import React, { useEffect, useState } from 'react';
import { useAuth, usePermission } from '../../../../hooks/auth';
import api from '../../../../services/api';
import { Rounds } from '../types';

export function useRounds<T>() {
  const [rounds, setRounds] = useState<T | null>(null);
  const { user } = useAuth();
  const permission = usePermission();

  async function handleSetRounds(page = 1, search?: string, title?: string) {
    if (title) {
      const { data } = await api.get('/rounds', {
        params: {
          page,
          responsable_id: permission.title === 'master' ? undefined : user?.id,
          name: title,
        },
      });
      setRounds(() => data);
    } else if (!search) {
      const { data } = await api.get('/rounds', {
        params: {
          page,
          responsable_id: permission.title === 'master' ? undefined : user?.id,
        },
      });
      setRounds(() => data);
    } else {
      const { data } = await api.get('/rounds', {
        params: {
          page,
          responsable_id: permission.title === 'master' ? undefined : user?.id,
          data: search,
        },
      });
      setRounds(() => data);
    }
  }
  useEffect(() => {
    handleSetRounds();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return { rounds, handleSetRounds, setRounds };
}
