import React, { useEffect, useState } from 'react';
import { useGet_relatorio_psicologico_indicadores } from './useGet_relatorio_psicologico_indicadores';

export function useGet_data_set_comportamento() {
  const { handle_relatorio_psicologico_indicadores } = useGet_relatorio_psicologico_indicadores();
  const [data_set_comportamento, set_data_set_comportamento] = useState<any>(null);

  async function handle_onChange_comportamento(year: string, trimestre?: string) {
    const comportamento = await handle_relatorio_psicologico_indicadores(year, "comportamento", trimestre);
    set_data_set_comportamento(comportamento);
  }
  useEffect(() => {
    handle_onChange_comportamento("2022");
  }, []);

  return { data_set_comportamento, handle_onChange_comportamento };
}
