import React, { useEffect, useState } from 'react';
import { Chart } from 'react-chartjs-2';
import { useGetIndicadores } from '../hooks/useGetIndicadores';
import { trimestral_options, handleTrimestre } from '../../../../components/develop-nilton-components/hooks/time_options';
import { SpinnerLoading } from '../../../../components/SpinnerLoading';

export function FatoresEmoTrimestral({ tab, tabType }: {tab:number, tabType: number}) {
  const { handleSetIndicador, indicador } = useGetIndicadores();

  function handleOnchange(e:any) {
    handleSetIndicador("intelectual", undefined, e.target.value);
  }

  const [load, setLoad] = useState(true);

  useEffect(() => {
    (async () => {
      setLoad(true);
      await handleSetIndicador("intelectual", undefined, handleTrimestre());
      setLoad(false);
    })();
  }, [tab, tabType]);

  return (
    <div>
      {load && (
        <div className="d-flex justify-content-center m-5">
          <SpinnerLoading />
        </div>
      )}
      {!load && (
        <div>
          <div className="mt-3 border-bottom border-secondary d-flex">
            <h3 className="mr-2">Percentual de fatores emocionais</h3>
            <select
              style={{ height: 35, width: 200, cursor: "pointer" }}
              className="form-control"
              onChange={handleOnchange}
            >
              {trimestral_options.map((trimestre, i) => (
                <option key={i} value={trimestre.value}>{trimestre.label}</option>
              ))}
            </select>
          </div>
          <div className="d-flex align-items-center flex-column w-100">
            <div>
              <Chart
                type="bar"
                data={indicador}
                options={{
                  plugins: {
                    legend: {
                      display: false,
                    },
                  },
                  indexAxis: 'y',
                }}
                style={{ width: 900 }}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
