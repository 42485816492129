import React, { useEffect, useState } from 'react';
import api from '../../../../services/api';

export function useGetTurmaTurmas_por_projeto_no_ano() {
  const [turmas_por_projeto_no_ano, set_media_alunos_por_turma_no_ano] = useState<any>(null);

  async function handleTurmas_por_projeto_no_ano(year?:string) {
    const { data } = await api.get('/reports/turmas_por_projeto_no_ano', {
      params: {
        year,
      },
    });
    set_media_alunos_por_turma_no_ano(data);
  }

  useEffect(() => {
    handleTurmas_por_projeto_no_ano();
  }, []);
  return { turmas_por_projeto_no_ano, handleTurmas_por_projeto_no_ano };
}
