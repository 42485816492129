// @ts-nocheck
import React, { useState, useEffect } from 'react';

import {
  Nav, NavItem, NavLink, TabContent, TabPane, Row, Col, Card, CardTitle, CardText, Button, Badge,
} from 'reactstrap';

import ReactPaginate from 'react-paginate';
import { ToastContainer, toast } from 'react-toastify';

import moment from 'moment';
import { FaUserPlus } from 'react-icons/fa';
import { SpinnerLoading } from '../../../components/SpinnerLoading';
import { Container } from './styles';

import PadDetail from './components/PadDetail';
import Header from '../components/Header';
import api from '../../../services/api';

import { useAuth } from '../../../hooks/auth';
import { SectionHeader } from '../../../components/SectionHeader';
import { TableComponent } from '../../../components/new-components/Table';
import { orderPage } from '../../../utils/orderPages';
import { IconAdd } from '../../../components/develop-nilton-components/IconAdd';
import { IconDetails } from '../../../components/develop-nilton-components/IconDetails';
import { PadDetailModal } from './components/PadDetailModal';
import { PadAddModal } from './components/PadAddModal';
import { InputSearch } from '../../../components/new-components/InputSearch';
import { ButtonAdd } from '../../../components/new-components/ButtonAdd';

interface IPad {
  id: number;
  processo_pad: number;
  egress: {
    nome: string;
    cep: string;
    endereco: string;
    bairro: string;
    estado: string;
    municipio: string;
  };
  responsavel_execucao: any;
  encaminhado: string;
  data_cumprimento: string;
  registro_seuu: string;
  attachment: {
    name: string;
    url_file: string;
  };
  observacao: string;
}

export function NotificarEgresso() {
  const { user, profile } = useAuth();

  const [tab, setTab] = useState('1');

  const [padSelect, setPadSelected] = useState<IPad | null>(null);
  const [padSelectId, setPadSelectedId] = useState<number>(0);
  const [isOpenPadDetailModal, setIsOpenPadDetailModal] = React.useState(false);
  const [isOpenPadAddModal, setIsOpenPadAddModal] = React.useState(false);

  const [egresses, setEgresses] = useState<any>([]);
  const [egress, setEgress] = useState<any>(null);
  const [pads, setPads] = useState<any>([]);

  const [page, setPage] = useState(0);

  const [isSearch, setInSearch] = useState('');

  const [loading, setLoading] = useState(false);
  const [loadingInitial, setLoadingInitial] = useState(false);
  const [loadingSearch, setLoadingSearch] = useState(false);
  function getPads(page = 0) {
    setLoadingInitial(true);
    api.get(`pads`, {
      params: {
        page: page + 1,
        encaminhado: tab === '1' ? undefined : tab === '2' ? 'F' : tab === '3' ? 'T' : undefined,
        processo_pad: isSearch !== '' ? isSearch : undefined,
        perPage: 15,
      },
    }).then(({ data }) => {
      setPads(data);
      setPage(data.meta.current_page);
      setLoadingInitial(false);
    });
  }

  useEffect(() => {
    if (!loadingSearch) {
      getPads();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [padSelect, tab, isSearch]);

  async function searchPad(page: number, processo_pad: string) {
    setInSearch(processo_pad);
    setLoadingSearch(true);
    setEgresses([]);
    setEgress(null);
    api.get(`pads`, {
      params: {
        processo_pad,
        encaminhado: tab === '1' ? undefined : tab === '2' ? 'F' : tab === '3' ? 'T' : undefined,
      },
    }).then(({ data }) => {
      setPads(data);
      setPage(data.meta.current_page);
      setLoadingSearch(false);
    });
  }

  function encaminhar(pad: any): void {
    const formData = new FormData();
    formData.append('data', JSON.stringify({
      encaminhado: 'T',
    }));

    api.put(`pads/${pad.processo_pad}`, formData).then(({ data }) => {
      getPads();

      const message = 'Notificação encaminhada com sucesso!';

      toast(message, {
        type: 'success',
      });
    }).catch((error) => {
      const message = error.response.data.message || 'Erro ao encaminhar notificação!';

      toast(message, {
        type: 'error',
      });
    });
  }

  async function atribuir(id: string) {
    const r = window.confirm(`Deseja atribuir para seu usuário ?`);
    if (r === false) return;

    try {
      setLoadingInitial(true);
      const pad = await api.put(`assign/pads/${id}`, { id, responsable_id_execucao: user.id });
      toast(`Atribuído com sucesso!`, {
        type: 'success',
      });
      getPads(0);
    } catch (error) {
      let message = 'Ocorreu um erro durante a atribuição';
      // @ts-ignore
      if (error?.response?.data?.message) {
        // @ts-ignore
        message = error.response.data.message;
      }

      toast(message, {
        type: 'error',
      });
    }
  }

  function padModalId(padId: any) {
    setIsOpenPadDetailModal(true);
    setPadSelectedId(padId);
  }

  return (
    <>
      <Container>
        {!padSelect
          ? (
            <>
              {/* <Header route="notificar-egresso" name="Notificar egresso" /> */}
              <SectionHeader subtitle="Notificar Egresso" />

              <div className="content">
                <div style={{ marginBottom: 10 }} />

                {user.profile.title === 'Fiscal' ? (
                  <InputSearch
                    placeholder="Digite a identificação da notificação"
                    keySearch="name"
                    data={pads?.data ?? []}
                    useStateSearch={[isSearch, setInSearch]}
                    id="search"
                  />
                ) : (
                  <InputSearch
                    placeholder="Digite a identificação da notificação"
                    keySearch="name"
                    data={pads?.data ?? []}
                    useStateSearch={[isSearch, setInSearch]}
                    id="search"
                    button={(
                      <ButtonAdd
                        onClick={() => {
                          setIsOpenPadAddModal(true);
                        }}
                      />
                  )}
                  />
                )}
              </div>

              <div>
                <h1 style={{ fontSize: 26, marginTop: 20, marginBottom: 20 }}>Últimas Notificações</h1>

                <Nav tabs>
                  <NavItem>
                    <NavLink
                      className={tab === '1' ? 'active' : ''}
                      style={{ cursor: "pointer" }}
                      onClick={() => setTab("1")}
                    >
                      Geral
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={tab === '2' ? 'active' : ''}
                      style={{ cursor: "pointer" }}
                      onClick={() => setTab("2")}
                    >
                      Pendentes
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={tab === '3' ? 'active' : ''}
                      style={{ cursor: "pointer" }}
                      onClick={() => setTab("3")}
                    >
                      Concluídos
                    </NavLink>
                  </NavItem>
                </Nav>
                <TabContent activeTab={tab}>

                  <TabPane tabId="1">
                    <Row>
                      <Col sm="12">
                        {(loadingInitial || !pads.data) ? (
                          <div style={{
                            display: "flex", justifyContent: "center", alignItems: 'center', height: '50vh',
                          }}
                          >
                            <SpinnerLoading color="#000" size={50} />
                          </div>
                        ) : (
                          <TableComponent data={pads?.data?.map((item: any) => ({
                            'Número do processo': item.processo_pad,
                            Egresso: item?.egress?.nome,
                            Fiscal: item?.responsavel_execucao?.name || (user.profile.title === 'Fiscal' || profile?.title === 'Fiscal' ? (
                              <div
                                title="Atribuir pra meu usuário"
                                style={{
                                  borderWidth: 1, borderColor: '#0275D8', borderStyle: 'solid', borderRadius: 8, cursor: 'pointer',
                                }}
                                onClick={() => atribuir(item.id)}
                              >
                                <FaUserPlus size={26} color="#0275D8" />
                              </div>
                            ) : 'Sem responsável'),
                            Executado: (
                              <>
                                {item.data_cumprimento ? (
                                  <Badge color="success">Executado</Badge>
                                ) : (
                                  <Badge color="danger">Não executado</Badge>
                                )}
                              </>
                            ),
                            ' Ações': (
                              <>
                                {!item.data_cumprimento ? (
                                  <div style={{ display: 'flex', justifyContent: 'center', marginTop: 5 }}>
                                    {(user.profile.title === 'Fiscal') ? (
                                      <div onClick={() => setPadSelected(item)}>
                                        <IconAdd cursor tooltipMessage="Clique para encaminhar" color="text-primary" />
                                      </div>
                                    ) : (
                                      <div onClick={() => {
                                        padModalId(item);
                                      }}
                                      >
                                        <IconDetails cursor tooltipMessage="Visualizar egresso" />
                                      </div>
                                    )}
                                  </div>
                                ) : (
                                  <div onClick={() => {
                                    padModalId(item);
                                  }}
                                  >
                                    <IconDetails cursor tooltipMessage="Visualizar egresso" />
                                  </div>
                                )}
                              </>),
                          }))}
                          />
                          // <table style={{
                          //   width: '100%', marginTop: 10, borderCollapse: 'collapse', borderStyle: 'solid', borderColor: 'black', borderWidth: 1,
                          // }}
                          // >
                          //   <tr>
                          //     <th style={{
                          //       borderStyle: 'solid', textAlign: 'center', borderColor: 'black', borderWidth: 1,
                          //     }}
                          //     >
                          //       Nº
                          //     </th>
                          //     <th style={{
                          //       borderStyle: 'solid', textAlign: 'center', borderColor: 'black', borderWidth: 1,
                          //     }}
                          //     >
                          //       Número do Processo
                          //     </th>
                          //     <th style={{
                          //       borderStyle: 'solid', textAlign: 'center', borderColor: 'black', borderWidth: 1,
                          //     }}
                          //     >
                          //       Egresso
                          //     </th>
                          //     <th style={{
                          //       borderStyle: 'solid', textAlign: 'center', borderColor: 'black', borderWidth: 1,
                          //     }}
                          //     >
                          //       Fiscal
                          //     </th>
                          //     <th style={{
                          //       borderStyle: 'solid', textAlign: 'center', borderColor: 'black', borderWidth: 1,
                          //     }}
                          //     >
                          //        Ações
                          //     </th>
                          //   </tr>
                          //   {pads && pads?.data?.map((item: any, index: number) => (
                          //     <tr>
                          //       <td style={{
                          //         borderStyle: 'solid', borderColor: 'black', borderWidth: 1, textAlign: 'center',
                          //       }}
                          //       >
                          //         {index + 1}
                          //       </td>
                          //       <td style={{
                          //         borderStyle: 'solid', borderColor: 'black', borderWidth: 1, textAlign: 'center',
                          //       }}
                          //       >
                          //         {item.processo_pad}
                          //       </td>
                          //       <td style={{
                          //         borderStyle: 'solid', borderColor: 'black', borderWidth: 1, textAlign: 'center',
                          //       }}
                          //       >
                          //         {item?.egress?.nome}
                          //       </td>
                          //       <td style={{
                          //         borderStyle: 'solid', borderColor: 'black', borderWidth: 1, textAlign: 'center',
                          //       }}
                          //       >
                          //         {item?.responsavel_execucao?.name || "Sem responsável"}
                          //       </td>
                          //       {!item.data_cumprimento ? (
                          //         <td style={{
                          //           borderStyle: 'solid', borderColor: 'black', borderWidth: 1, textAlign: 'center',
                          //         }}
                          //         >
                          //           <div onClick={() => ((user.profile.title === 'Fiscal' || localStorage.getItem("@albergado:profile")?.title === "Fiscal") || user.profile.title === 'MASTER') && setPadSelected(item)} style={{ display: 'flex', justifyContent: 'center', marginTop: 5 }}>
                          //             {((user.profile.title === 'Fiscal' || localStorage.getItem("@albergado:profile")?.title === "Fiscal") || user.profile.title === 'MASTER') ? (
                          //               <h1 style={{
                          //                 fontSize: 14, color: '#007BFF', marginLeft: 5, cursor: 'pointer',
                          //               }}
                          //               >
                          //                 (Clique aqui para encaminhar)
                          //               </h1>
                          //             ) : (
                          //               <h1 style={{ fontSize: 14, color: '#f00' }}>Não executado</h1>
                          //             )}
                          //           </div>
                          //         </td>
                          //       ) : (
                          //         <td style={{
                          //           borderStyle: 'solid', borderColor: 'black', borderWidth: 1, textAlign: 'center',
                          //         }}
                          //         >
                          //           {moment(item.data_cumprimento).format('DD/MM/YYYY')}
                          //         </td>
                          //       )}
                          //     </tr>
                          //   ))}
                          // </table>
                        )}
                      </Col>
                      <PadDetailModal id={padSelectId} useStateIsOpen={[isOpenPadDetailModal, setIsOpenPadDetailModal]} />
                    </Row>
                  </TabPane>

                  <TabPane tabId="2">
                    <Row>
                      <Col sm="12">
                        {(loadingInitial || pads.length === 0) ? (
                          <div style={{
                            display: "flex", justifyContent: "center", alignItems: 'center', height: '50vh',
                          }}
                          >
                            <SpinnerLoading color="#000" size={50} />
                          </div>
                        ) : (
                          // <table style={{
                          //   width: '100%', marginTop: 10, borderCollapse: 'collapse', borderStyle: 'solid', borderColor: 'black', borderWidth: 1,
                          // }}
                          // >
                          //   <tr>
                          //     <th style={{
                          //       borderStyle: 'solid', textAlign: 'center', borderColor: 'black', borderWidth: 1,
                          //     }}
                          //     >
                          //       Nº
                          //     </th>
                          //     <th style={{
                          //       borderStyle: 'solid', textAlign: 'center', borderColor: 'black', borderWidth: 1,
                          //     }}
                          //     >
                          //       Número do Processo
                          //     </th>
                          //     <th style={{
                          //       borderStyle: 'solid', textAlign: 'center', borderColor: 'black', borderWidth: 1,
                          //     }}
                          //     >
                          //       Egresso
                          //     </th>
                          //     <th style={{
                          //       borderStyle: 'solid', textAlign: 'center', borderColor: 'black', borderWidth: 1,
                          //     }}
                          //     >
                          //       Fiscal
                          //     </th>
                          //     <th style={{
                          //       borderStyle: 'solid', textAlign: 'center', borderColor: 'black', borderWidth: 1,
                          //     }}
                          //     >
                          //        Ações
                          //     </th>
                          //   </tr>
                          //   {pads && pads?.data?.map((item: any, index: number) => (
                          //     <tr>
                          //       <td style={{
                          //         borderStyle: 'solid', borderColor: 'black', borderWidth: 1, textAlign: 'center',
                          //       }}
                          //       >
                          //         {index + 1}
                          //       </td>
                          //       <td style={{
                          //         borderStyle: 'solid', borderColor: 'black', borderWidth: 1, textAlign: 'center',
                          //       }}
                          //       >
                          //         {item.processo_pad}
                          //       </td>
                          //       <td style={{
                          //         borderStyle: 'solid', borderColor: 'black', borderWidth: 1, textAlign: 'center',
                          //       }}
                          //       >
                          //         {item?.egress?.nome}
                          //       </td>
                          //       <td style={{
                          //         borderStyle: 'solid', borderColor: 'black', borderWidth: 1, textAlign: 'center',
                          //       }}
                          //       >
                          //         {item?.responsavel_execucao?.name || "Sem responsável"}
                          //       </td>
                          //       {!item.data_cumprimento ? (
                          //         <td style={{
                          //           borderStyle: 'solid', borderColor: 'black', borderWidth: 1, textAlign: 'center',
                          //         }}
                          //         >
                          //           <div onClick={() => (user.profile.title === 'Fiscal' || localStorage.getItem("@albergado:profile")?.title === "Fiscal") && setPadSelected(item)} style={{ display: 'flex', justifyContent: 'center', marginTop: 5 }}>
                          //             {((user.profile.title === 'Fiscal' || localStorage.getItem("@albergado:profile")?.title === "Fiscal") || user.profile.title === 'MASTER') ? (
                          //               <h1 style={{
                          //                 fontSize: 14, color: '#007BFF', marginLeft: 5, cursor: 'pointer',
                          //               }}
                          //               >
                          //                 (Clique aqui para encaminhar)
                          //               </h1>
                          //             ) : (
                          //               <h1 style={{ fontSize: 14, color: '#f00' }}>Não executado</h1>
                          //             )}
                          //           </div>
                          //         </td>
                          //       ) : (
                          //         <td style={{
                          //           borderStyle: 'solid', borderColor: 'black', borderWidth: 1, textAlign: 'center',
                          //         }}
                          //         >
                          //           {moment(item.data_cumprimento).format('DD/MM/YYYY')}
                          //         </td>
                          //       )}
                          //     </tr>
                          //   ))}
                          // </table>
                          <TableComponent data={pads?.data?.map((item: any) => ({
                            'Número do processo': item.processo_pad,
                            Egresso: item?.egress?.nome,
                            Fiscal: item?.responsavel_execucao?.name || (user.profile.title === 'Fiscal' || profile?.title === 'Fiscal' ? (
                              <div
                                title="Atribuir pra meu usuário"
                                style={{
                                  borderWidth: 1, borderColor: '#0275D8', borderStyle: 'solid', borderRadius: 8, cursor: 'pointer',
                                }}
                                onClick={() => atribuir(item.id)}
                              >
                                <FaUserPlus size={26} color="#0275D8" />
                              </div>
                            ) : 'Sem responsável'),
                            Executado: (
                              <>
                                {item.data_cumprimento ? (
                                  <Badge color="success">Executado</Badge>
                                ) : (
                                  <Badge color="danger">Não executado</Badge>
                                )}
                              </>
                            ),
                            ' Ações': (
                              <>
                                {!item.data_cumprimento ? (

                                  <div style={{ display: 'flex', justifyContent: 'center', marginTop: 5 }}>
                                    {(user.profile.title === 'Fiscal') ? (
                                      <div onClick={() => setPadSelected(item)}>
                                        <IconAdd cursor tooltipMessage="Clique para encaminhar" color="text-primary" />
                                      </div>
                                    ) : (
                                      <div onClick={() => {
                                        padModalId(item);
                                      }}
                                      >
                                        <IconDetails cursor tooltipMessage="Visualizar egresso" />
                                      </div>
                                    )}
                                  </div>
                                ) : (
                                  <div onClick={() => {
                                    setIsOpenPadDetailModal(true);
                                  }}
                                  >
                                    <IconDetails cursor tooltipMessage="Visualizar egresso" />
                                  </div>
                                )}
                              </>),
                          }))}
                          />
                        )}
                      </Col>
                    </Row>
                  </TabPane>

                  <TabPane tabId="3">
                    <Row>
                      <Col sm="12">
                        {(loadingInitial || !pads.data) ? (
                          <div style={{
                            display: "flex", justifyContent: "center", alignItems: 'center', height: '50vh',
                          }}
                          >
                            <SpinnerLoading color="#000" size={50} />
                          </div>
                        ) : (
                          <TableComponent data={pads?.data?.map((item: any) => ({
                            'Número do processo': item.processo_pad,
                            Egresso: item?.egress?.nome,
                            Fiscal: (
                              <>
                                {item?.responsavel_execucao?.name ? (
                                  <div style={{ display: 'flex', justifyContent: 'center', marginTop: 5 }}>
                                    {(user.profile.title === 'Fiscal' || profile?.title === 'Fiscal') ? (
                                      <div
                                        title="Atribuir pra meu usuário"
                                        style={{
                                          borderWidth: 1, borderColor: '#0275D8', borderStyle: 'solid', borderRadius: 8, cursor: 'pointer',
                                        }}
                                        onClick={() => atribuir(item.id)}
                                      >
                                        <FaUserPlus size={26} color="#0275D8" />
                                      </div>
                                    ) : (
                                      <span>{item?.responsavel_execucao?.name}</span>
                                    )}
                                  </div>
                                ) : (
                                  <span>Sem responsável</span>
                                )}
                              </>),
                            Executado: (
                              <>
                                {item.data_cumprimento ? (
                                  <Badge color="success">Executado</Badge>
                                ) : (
                                  <Badge color="danger">Não executado</Badge>
                                )}
                              </>
                            ),
                            ' Ações': (
                              <>
                                {!item.data_cumprimento ? (

                                  <div style={{ display: 'flex', justifyContent: 'center', marginTop: 5 }}>
                                    {(user.profile.title === 'Fiscal') ? (
                                      <div onClick={() => setPadSelected(item)}>
                                        <IconAdd cursor tooltipMessage="Clique para encaminhar" color="text-primary" />
                                      </div>
                                    ) : (
                                      <div onClick={() => {
                                        padModalId(item);
                                      }}
                                      >
                                        <IconDetails cursor tooltipMessage="Visualizar egresso" />
                                      </div>
                                    )}
                                  </div>
                                ) : (
                                  <div onClick={() => {
                                    padModalId(item);
                                  }}
                                  >
                                    <IconDetails cursor tooltipMessage="Visualizar egresso" />
                                  </div>
                                )}
                              </>),
                          }))}
                          />
                          // <table style={{
                          //   width: '100%', marginTop: 10, borderCollapse: 'collapse', borderStyle: 'solid', borderColor: 'black', borderWidth: 1,
                          // }}
                          // >
                          //   <tr>
                          //     <th style={{
                          //       borderStyle: 'solid', textAlign: 'center', borderColor: 'black', borderWidth: 1,
                          //     }}
                          //     >
                          //       Nº
                          //     </th>
                          //     <th style={{
                          //       borderStyle: 'solid', textAlign: 'center', borderColor: 'black', borderWidth: 1,
                          //     }}
                          //     >
                          //       Número do Processo
                          //     </th>
                          //     <th style={{
                          //       borderStyle: 'solid', textAlign: 'center', borderColor: 'black', borderWidth: 1,
                          //     }}
                          //     >
                          //       Egresso
                          //     </th>
                          //     <th style={{
                          //       borderStyle: 'solid', textAlign: 'center', borderColor: 'black', borderWidth: 1,
                          //     }}
                          //     >
                          //       Fiscal
                          //     </th>
                          //     <th style={{
                          //       borderStyle: 'solid', textAlign: 'center', borderColor: 'black', borderWidth: 1,
                          //     }}
                          //     >
                          //        Ações
                          //     </th>
                          //   </tr>
                          //   {pads && pads?.data?.map((item: any, index: number) => (
                          //     <tr>
                          //       <td style={{
                          //         borderStyle: 'solid', borderColor: 'black', borderWidth: 1, textAlign: 'center',
                          //       }}
                          //       >
                          //         {index + 1}
                          //       </td>
                          //       <td style={{
                          //         borderStyle: 'solid', borderColor: 'black', borderWidth: 1, textAlign: 'center',
                          //       }}
                          //       >
                          //         {item.processo_pad}
                          //       </td>
                          //       <td style={{
                          //         borderStyle: 'solid', borderColor: 'black', borderWidth: 1, textAlign: 'center',
                          //       }}
                          //       >
                          //         {item?.egress?.nome}
                          //       </td>
                          //       <td style={{
                          //         borderStyle: 'solid', borderColor: 'black', borderWidth: 1, textAlign: 'center',
                          //       }}
                          //       >
                          //         {item?.responsavel_execucao?.name || "Sem responsável"}
                          //       </td>
                          //       {!item.data_cumprimento ? (
                          //         <td style={{
                          //           borderStyle: 'solid', borderColor: 'black', borderWidth: 1, textAlign: 'center',
                          //         }}
                          //         >
                          //           <div onClick={() => (user.profile.title === 'Fiscal' || localStorage.getItem("@albergado:profile")?.title === "Fiscal") && setPadSelected(item)} style={{ display: 'flex', justifyContent: 'center', marginTop: 5 }}>
                          //             {((user.profile.title === 'Fiscal' || localStorage.getItem("@albergado:profile")?.title === "Fiscal") || user.profile.title === 'MASTER') ? (
                          //               <h1 style={{
                          //                 fontSize: 14, color: '#007BFF', marginLeft: 5, cursor: 'pointer',
                          //               }}
                          //               >
                          //                 (Clique aqui para encaminhar)
                          //               </h1>
                          //             ) : (
                          //               <h1 style={{ fontSize: 14, color: '#f00' }}>Não executado</h1>
                          //             )}
                          //           </div>
                          //         </td>
                          //       ) : (
                          //         <td style={{
                          //           borderStyle: 'solid', borderColor: 'black', borderWidth: 1, textAlign: 'center',
                          //         }}
                          //         >
                          //           {moment(item.data_cumprimento).format('DD/MM/YYYY')}
                          //         </td>
                          //       )}
                          //     </tr>
                          //   ))}
                          // </table>
                        )}
                      </Col>
                      <PadDetailModal id={padSelectId} useStateIsOpen={[isOpenPadDetailModal, setIsOpenPadDetailModal]} />
                    </Row>
                  </TabPane>

                </TabContent>

                <div style={{ width: '100%' }}>
                  {/* {loadingInitial ? (
                    <div style={{
                      display: "flex", justifyContent: "center", alignItems: 'center', height: '50vh',
                    }}
                    >
                      <SpinnerLoading color="#000" size={50} />
                    </div>
                  ) : (
                    <table style={{
                      width: '100%', borderCollapse: 'collapse', borderStyle: 'solid', borderColor: 'black', borderWidth: 1,
                    }}
                    >
                      <tr>
                        <th style={{
                          borderStyle: 'solid', textAlign: 'center', borderColor: 'black', borderWidth: 1,
                        }}
                        >
                          Nº
                        </th>
                        <th style={{
                          borderStyle: 'solid', textAlign: 'center', borderColor: 'black', borderWidth: 1,
                        }}
                        >
                          Número do Processo
                        </th>
                        <th style={{
                          borderStyle: 'solid', textAlign: 'center', borderColor: 'black', borderWidth: 1,
                        }}
                        >
                          Egresso
                        </th>
                        <th style={{
                          borderStyle: 'solid', textAlign: 'center', borderColor: 'black', borderWidth: 1,
                        }}
                        >
                          Fiscal
                        </th>
                        <th style={{
                          borderStyle: 'solid', textAlign: 'center', borderColor: 'black', borderWidth: 1,
                        }}
                        >
                           Ações
                        </th>
                      </tr>
                      {pads && pads?.data?.map((item: any, index: number) => (
                        <tr>
                          <td style={{
                            borderStyle: 'solid', borderColor: 'black', borderWidth: 1, textAlign: 'center',
                          }}
                          >
                            {index + 1}
                          </td>
                          <td style={{
                            borderStyle: 'solid', borderColor: 'black', borderWidth: 1, textAlign: 'center',
                          }}
                          >
                            {item.processo_pad}
                          </td>
                          <td style={{
                            borderStyle: 'solid', borderColor: 'black', borderWidth: 1, textAlign: 'center',
                          }}
                          >
                            {item?.egress?.nome}
                          </td>
                          <td style={{
                            borderStyle: 'solid', borderColor: 'black', borderWidth: 1, textAlign: 'center',
                          }}
                          >
                            {item?.responsavel_execucao?.name || "Sem responsável"}
                          </td>
                          {!item.data_cumprimento ? (
                            <td style={{
                              borderStyle: 'solid', borderColor: 'black', borderWidth: 1, textAlign: 'center',
                            }}
                            >
                              <div onClick={() => (user.profile.title === 'Fiscal' || localStorage.getItem("@albergado:profile")?.title === "Fiscal") && setPadSelected(item)} style={{ display: 'flex', justifyContent: 'center', marginTop: 5 }}>
                                <h1 style={{ fontSize: 14, color: '#f00' }}>Não executado</h1>
                                {(user.profile.title === 'Fiscal' || localStorage.getItem("@albergado:profile")?.title === "Fiscal")
      && (
      <h1 style={{
        fontSize: 14, color: '#007BFF', marginLeft: 5, cursor: 'pointer',
      }}
      >
        (Clique aqui para encaminhar)
      </h1>
      )}
                              </div>
                            </td>
                          ) : (
                            <td style={{
                              borderStyle: 'solid', borderColor: 'black', borderWidth: 1, textAlign: 'center',
                            }}
                            >
                              {moment(item.data_cumprimento).format('DD/MM/YYYY')}
                            </td>
                          )}
                        </tr>
                      ))}
                    </table>
                  )} */}

                  {pads && pads?.data?.length > 0
                    && (
                      <div style={{ display: "flex", alignItems: 'flex-end', justifyContent: "flex-end" }}>
                        <ReactPaginate
                          previousLabel="anterior"
                          nextLabel="próximo"
                          breakLabel="..."
                          breakClassName="break-me"
                          pageCount={pads ? pads?.meta?.last_page : 0}
                          marginPagesDisplayed={4}
                          pageRangeDisplayed={5}
                          forcePage={page - 1}
                          onPageChange={(page) => { getPads(page.selected); }}
                          containerClassName="pagination"
                          activeClassName="active"
                        />
                      </div>
                    )}
                </div>
              </div>
            </>
          ) : (
            <PadDetail pad={padSelect} setPad={setPadSelected} />
          )}
        <PadAddModal useStateIsOpen={[isOpenPadAddModal, setIsOpenPadAddModal]} />
      </Container>
      <ToastContainer />
    </>
  );
}
