import React from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import {
  Modal, ModalBody, ModalHeader, ModalFooter, Button, Form, Row, Col, FormGroup, Label, Input, FormText, InputGroup,
} from 'reactstrap';
import { format } from 'date-fns';
import { FaFileDownload } from 'react-icons/fa';
import { InputField } from '../../../../components/new-components/InputField';
import { useGetByIdPad } from '../hooks/useGetByIdPad';
import { Loading } from '../../../../components/new-components/Loading';
import { mask_cpf } from '../../../../utils/mask_cpf';

type PadDetailModalParams = {
  useStateIsOpen: [boolean, (boolean: boolean) => void];
  id:any
}

interface showPad {
  id: number;
  processo_pad: number;
  egress: {
    nome: string;
    cpf: string;
    data_entrada_sistema_penitenciario: string;
  };
  responsavel_execucao: {
    name: string;
  };
  encaminhado: string;
  data_cumprimento: string;
  data_atribuicao: string;
  registro_seuu: string;
  attachment: {
    name: string;
    url_file: string;
  };
  observacao: string;
}

export function PadDetailModal(props: PadDetailModalParams) {
  const {
    useStateIsOpen,
    id,
  } = props;

  const [isOpen, setIsOpen] = useStateIsOpen;

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  const data = {
    cpf: null,
    created_at: id?.created_at ? null : id?.created_at,
    processo_pad: null,
    data_atribuicao: id?.data_atribuicao ? null : id?.data_atribuicao,
    data_cumprimento: id?.data_cumprimento ? null : id?.data_cumprimento,
    numero_processo: null,
  };

  async function download(url:string, filename:string) {
    fetch(url).then((t) => t.blob().then((b) => {
      const a = document.createElement("a");
      a.href = URL.createObjectURL(b);
      a.setAttribute("download", filename);
      a.click();
    }));
  }

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <Formik
        initialValues={id ?? data}
        onSubmit={() => {}}
      >
        {(props) => {
          const { handleSubmit, setFieldValue, values } = props;
          const data_criacao: any = !id?.created_at ? null : format(new Date(values?.created_at), "dd/MM/yyyy");
          const data_atribuicao: any = !id?.data_atribuicao ? null : format(new Date(values?.data_atribuicao), "dd/MM/yyyy");
          const data_cumprimento: any = !id?.data_cumprimento ? null : format(new Date(values?.data_cumprimento), "dd/MM/yyyy");
          return (
            <Form onSubmit={handleSubmit}>
              <ModalHeader toggle={toggle}>Visualizar Notificação</ModalHeader>
              <ModalBody>
                <InputField
                  label="Nome do egresso"
                  InputHTMLAttributes={{
                    id: 'nome',
                    name: 'egress.nome',
                    placeholder: 'Nome do egresso',
                    disabled: true,
                  }}
                />

                <div className="d-flex" style={{ gap: 10 }}>
                  <InputField
                    label="CPF"
                    InputHTMLAttributes={{
                      id: 'cpf',
                      name: 'egress.cpf',
                      placeholder: '000.000.000-00',
                      disabled: true,
                      onChange: (e) => setFieldValue('cpf', mask_cpf(e.target.value)),
                    }}
                  />
                  <InputField
                    label="Data de criação"
                    InputHTMLAttributes={{
                      id: 'created_at',
                      name: 'created_at',
                      value: data_criacao,
                      disabled: true,
                    }}
                  />
                </div>

                <InputField
                  label="Numero do processo"
                  InputHTMLAttributes={{
                    id: 'processo_pad',
                    name: 'processo_pad',
                    placeholder: 'Numero do processo',
                    disabled: true,
                  }}
                />
                <div className="d-flex" style={{ gap: 10 }}>
                  <InputField
                    label="Data de atribuição"
                    InputHTMLAttributes={{
                      id: 'data_atribuicao',
                      name: 'data_atribuicao',
                      value: data_atribuicao,
                      placeholder: 'dd/mm/aaaa',
                      disabled: true,
                    }}
                  />
                  <InputField
                    label="Data de execução"
                    InputHTMLAttributes={{
                      id: 'data_cumprimento',
                      name: 'data_cumprimento',
                      value: data_cumprimento,
                      placeholder: 'dd/mm/aaaa',
                      disabled: true,
                    }}
                  />
                </div>

                <InputField
                  label="Fiscal Responsavel"
                  InputHTMLAttributes={{
                    id: 'responsavel_execucao',
                    name: 'responsavel_execucao.name',
                    placeholder: 'Fiscal Responsavel',
                    disabled: true,
                  }}
                />

                <div>
                  <InputGroup>
                    <InputField
                      label="Arquivo"
                      InputHTMLAttributes={{
                        id: 'attachment',
                        name: 'attachment.file',
                        placeholder: 'Não possui arquivo',
                        disabled: true,
                      }}
                    />
                    <Button
                      style={{ marginTop: 10 }}
                      size="10"
                      color="white"
                      onClick={() => {
                        download(values.attachment.url_file, `NotificarEgresso_${values.attachment.file}`);
                      }}
                    >
                      <FaFileDownload cursor="pointer" />
                    </Button>
                  </InputGroup>

                </div>
                <FormText>
                  Clique no botão para baixar o arquivo.
                </FormText>
                {' '}
              </ModalBody>
              <ModalFooter>
                <Button color="secondary" onClick={toggle}>
                  Voltar
                </Button>
              </ModalFooter>
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
}
