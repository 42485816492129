import React, { useState } from 'react';
import styled from 'styled-components';
import { SpinnerLoading } from '../SpinnerLoading';
import { IconAdd } from './IconAdd';

type IInputadd = {
  options: string[] | undefined;
  functionAdd(inputValue:string): void
  description?: string
  label?: string
};

export function InputAdd({
  options, functionAdd, description, label,
}: IInputadd) {
  const [focus, set_focus] = useState(false);
  const [input_value, set_input_value] = useState("");

  const filter_options = options?.filter((options) => options.includes(input_value));

  return (
    <div style={{ width: '100%' }} className="d-flex flex-column">
      <div className="position-relative w-100">
        <label htmlFor="add_project" className="h4">
          {label}
        </label>

        <div className="d-flex align-items-center">
          <input
            className="form-control form-control-lg "
            id="add_project"
            onFocus={() => set_focus(true)}
            onBlur={() => {
              setTimeout(() => {
                set_focus(false);
              }, 1000 / 6);
            }}
            value={input_value}
            onChange={(e) => set_input_value(e.target.value)}
            autoComplete="off"
          />
          <div onClick={() => functionAdd(input_value)}>
            <IconAdd color="text-success" size={50} cursor />
          </div>
        </div>
        {focus && !options && (
          <Select>
            <Option className="d-flex justify-content-center"><SpinnerLoading /></Option>
          </Select>
        )}
        {focus && filter_options?.length !== 0 && (
          <Select>
            {filter_options?.map((option, i) => (
              <Option key={i} onClick={() => set_input_value(option)}>{option}</Option>
            ))}
          </Select>
        )}
      </div>
      <p className="text-secondary">{description}</p>
    </div>
  );
}

const Select = styled.div`
  max-height: 230px;
  width: 100%;
  overflow: auto;
  border: gray solid 1px;
  position: absolute;
  background-color: white;
  z-index: 1000;
`;
const Option = styled.div`
  font-size: 18px;
  padding: 10px;
  cursor: pointer;
  :hover {
    background-color: rgb(130, 200, 255);
  }
`;
