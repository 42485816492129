import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { IndicadoresGraficosAcompanhamento } from '../../../../components/develop-nilton-components/IndicadoresGraficosAcompanhamento';
import { SectionHeader } from '../../../../components/SectionHeader';

export function PageIndicadoresIndividuaisPsicopedagogo() {
  const history = useHistory();
  const { state } = useLocation<any>();

  function handleRedirectPageHistory() {
    history.push({
      pathname: "/acompanhamento/historico",
      state: state.egresso,
    });
  }

  console.log(state.acompanhamento_concluidos);

  return (
    <div className="w-100 mb-5" data-testid="page-indicadores">
      <SectionHeader subtitle="Indicadores Gráficos" handleRedirect={handleRedirectPageHistory} />

      <h3>{state.nome}</h3>

      <div>
        <IndicadoresGraficosAcompanhamento
          data_sets={[{
            titleGraphic: "Intelectual",
            data: {
              labels: [
                'Obdiência',
                'Concentração',
                'Expressão',
                'Aprendizagem'],
              datasets: [{
                label: '',
                data: [
                  state.acompanhamento_concluidos.obdece_comandos_primeira_vez,
                  state.acompanhamento_concluidos.dificuldade_memorizacao_concentracao,
                  state.acompanhamento_concluidos.consegue_expressar_pensamentos,
                  state.acompanhamento_concluidos.dificuldade_aprendizagem,
                ],
                borderColor: '#FFA500',
                backgroundColor: '#FFA500',
              }],
            },
          }, {
            titleGraphic: "Emocional",
            data: {
              labels: [
                'Adaptação',
                'Irritação',
                'Reclamação',
                'Agressivo',
                'Desempenho',
                'Voz',
                'familia',
                'Cooperação',
                'Sociavel',
                'Disciplina',

              ],
              datasets: [{
                label: '',
                data: [
                  state.acompanhamento_concluidos.dificuldade_adaptacao,
                  state.acompanhamento_concluidos.irrita_facilmente,
                  state.acompanhamento_concluidos.reclama_muito,
                  state.acompanhamento_concluidos.agressivo,
                  state.acompanhamento_concluidos.preocupa_apresentar_bom_desempenho,
                  state.acompanhamento_concluidos.voz_modulada_ritmo_adequado,
                  state.acompanhamento_concluidos.preocupacao_familiar_especifica,
                  state.acompanhamento_concluidos.cooperador,
                  state.acompanhamento_concluidos.satisfatoriamente_sociavel,
                  state.acompanhamento_concluidos.disciplinado,
                ],
                borderColor: '#FFA500',
                backgroundColor: '#FFA500',
              }],
            },
          }]}
        />
      </div>
    </div>
  );
}
