import React, { useEffect, useState } from 'react';
import api from '../../../../services/api';

export function useGetEgressesOptions() {
  const [optionsEgresses, setOptionsEgresses] = useState<string[] | null>(null);
  const [load, setLoad] = useState(false);

  async function handleSetOptionsEgrsses() {
    setLoad(true);
    const { data } = await api.get("/egresses", { params: { nopaginate: true } });

    const queryNome = data.map((item:any) => item.nome);
    const queryHabilidade = data.map((item:any) => item.habilidades_competencias);
    const queryBairro = data.map((item:any) => item.bairro);
    const queryMunicipio = data.map((item:any) => item.municipio);
    const queryArea = data.map((item:any) => item.area);
    const set = new Set();
    const options = [...queryNome, ...queryHabilidade, ...queryMunicipio, ...queryBairro, ...queryArea];

    const optionsFilter = options.filter((item) => {
      const duplicated = set.has(item);
      set.add(item);
      return !duplicated;
    });

    setOptionsEgresses(optionsFilter);
    setLoad(false);
  }
  useEffect(() => {
    handleSetOptionsEgrsses();
  }, []);
  return {
    optionsEgresses, load,
  };
}
