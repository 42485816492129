import UserCheck from '../../assets/user_check.png';
import UserConfirm from '../../assets/user_confirm.png';
import List from '../../assets/list.png';
import HexUsers from '../../assets/hex_users.png';
import Notify from '../../assets/notify.png';
import Report from '../../assets/report.png';
import HexUsersPlus from '../../assets/hex_users_plus.png';
import { useAuth } from '../../hooks/auth';

export const admin = {
  title: 'Administrador',
  functions: [
    {
      icon: '/assets/dashboard.png',
      title: 'Dashboard',
      to: '/dashboard',
    },
    // {
    //   title: 'Cadastrar Egresso',
    //   icon: 'assets/cad_egresso.png',
    //   to: '/cad-egresso',
    // },
    // {
    //   title: 'Gerir Egresso',
    //   icon: '/assets/gerir_egre.png',
    //   to: '/gerir-egresso',
    // },
    {
      title: 'Egressos',
      icon: '/assets/gerir_egre.png',
      to: '/egressos',
    },
    // {
    //   title: 'Cadastrar Usuário',
    //   icon: '/assets/usuario_profile.png',
    //   to: '/cad-usuario',
    // },
    {
      title: 'Usuários',
      icon: '/assets/gerir_user.png',
      to: '/usuarios',
    },
    {
      title: 'Homologar Projeto',
      icon: '/assets/homologar_pro.png',
      to: '/homologar_pro',
    },
    {
      icon: UserConfirm,
      title: 'Homologar Ronda',
      to: '/homologar-ronda',
    },
    {
      icon: HexUsersPlus,
      title: 'Cadastrar Alvará',
      to: '/cadastrar-alvara',
    },
    {
      title: 'Notificar egresso',
      icon: '/assets/notify.png',
      to: '/notificar-egresso',
    },
    // {
    //   icon: '/assets/admin.png',
    //   title: 'Administrador',
    //   to: '/administrador',
    // },
    // {
    //   icon: 'assets/user.png',
    //   title: 'Usuários',
    //   to: '/usuarios',
    // },
  ],
};

export const fiscal = {
  title: 'Fiscal',
  functions: [
    {
      icon: '/assets/dashboard.png',
      title: 'Dashboard',
      to: '/dashboard',
    },
    {
      title: 'Egressos',
      icon: '/assets/gerir_egre.png',
      to: '/egressos',
    },
    {
      icon: UserCheck,
      title: 'Rondas',
      to: '/cadastrar-ronda',
    },
    // {
    //   icon: Users,
    //   title: 'Ronda diária',
    //   to: '/ronda-diaria',
    // },
    // {
    //   icon: UserConfirm,
    //   title: 'Homologar ronda',
    //   to: '/homologar-ronda',
    // },
    // {
    //   icon: List,
    //   title: 'Listar ronda',
    //   to: '/listar-ronda',
    // },
    /*     {
      icon: HexUsersPlus,
      title: 'Cadastrar Alvará',
      to: '/cadastrar-alvara',
    }, */
    {
      icon: HexUsers,
      title: 'Cumprir alvará',
      to: '/cumprir-alvara',
    },
    {
      icon: '/assets/notify.png',
      title: 'Notificar egresso',
      to: '/notificar-egresso',
    },
    {
      icon: '/assets/dashboard.png',
      title: 'Indicadores',
      to: '/indicadores',
    },
    {
      icon: Report,
      title: 'Relatórios',
      to: '/relatorios',
    },
  ],
};

export const convidado = {
  title: 'Convidado',
  functions: [
    {
      icon: '/assets/dashboard.png',
      title: 'Dashboard',
      to: '/dashboard',
    },
    {
      icon: '/assets/usuario_profile.png',
      title: 'Ver Egresso',
      to: '/pesquisar-egresso',
    },
    {
      icon: '/assets/icon_social.png',
      title: 'Socioeconômico',
      to: '/socio-economico',
    },
    {
      icon: '/assets/psico.png',
      title: 'Psicológicos',
      to: '/psicologicos',
    },
    {
      icon: UserCheck,
      title: 'Fiscalização',
      to: '/fiscalizacao',
    },
    {
      icon: '/assets/capacitacao.png',
      title: 'Capacitação',
      to: '/capacitacao',
    },
  ],
};

export const assistente_social = {
  title: 'Assistente Social',
  functions: [
    {
      icon: '/assets/dashboard.png',
      title: 'Dashboard',
      to: '/dashboard',
    },
    {
      icon: '/assets/analisar-egresso.png',
      title: 'Acompanhamento',
      to: '/acompanhamento',
    },
    {
      icon: '/assets/rela.png',
      title: 'Relatórios',
      to: '/relatorios',
    },
  ],
};

export const pedagogo = {
  title: 'Pedagogo',
  functions: [
    {
      icon: '/assets/dashboard.png',
      title: 'Dashboard',
      to: '/dashboard',
    },
    // {
    //   icon: '/assets/cad_curso.png',
    //   title: 'Cadastrar curso',
    //   to: '/cad_curso',
    // },
    // {
    //   title: 'Cadastrar Projeto',
    //   icon: '/assets/cad_project.png',
    //   to: '/cad_project',
    // },
    {
      title: 'Analisar Egresso',
      icon: '/assets/analisar-egresso.png',
      to: '/analisar-egresso',
    },
    {
      title: 'Turma',
      icon: HexUsers,
      to: '/gerir-turmas',
    },
    {
      title: 'Projetos',
      icon: '/assets/edit_projeto.png',
      to: '/gerir-projetos',
    },
    {
      title: 'Estatísticas',
      icon: '/assets/estatistica.png',
      to: '/estatistica',
    },
    {
      title: 'Relatório',
      icon: Report,
      to: '/relatorio',
    },
    // {
    //   icon: Report,
    //   title: 'Relatórios',
    //   to: '/relatorios',
    // },
  ],
};

export const psicologo = {
  title: 'Psicólogo',
  functions: [
    {
      icon: '/assets/dashboard.png',
      title: 'Dashboard',
      to: '/dashboard',
    },
    {
      icon: '/assets/Perfil.png',
      title: 'Perfil',
      to: '/perfil',
    },
    {
      icon: '/assets/estatistica.png',
      title: 'Indicadores',
      to: '/indicadores',
    },
    {
      icon: Report,
      title: 'Relatórios',
      to: '/relatorios',
    },
  ],
};

export const psico_pedagogo = {
  title: 'Psico-pedagogo',
  functions: [
    {
      icon: '/assets/dashboard.png',
      title: 'Dashboard',
      to: '/dashboard',
    },
    {
      icon: '/assets/Perfil.png',
      title: 'Acompanhamento',
      to: '/acompanhamento',
    },
    {
      icon: '/assets/estatistica.png',
      title: 'Indicadores',
      to: '/indicadores',
    },
    {
      icon: Report,
      title: 'Relatórios',
      to: '/relatorios',
    },
  ],
};

export function useSidebarList() {
  const { user } = useAuth();
  if (user?.profile.title === 'Administrador') {
    return admin;
  }
  if (user?.profile.title === 'Fiscal') {
    return fiscal;
  }
  if (user?.profile.title === 'Convidado') {
    return convidado;
  }
  if (user?.profile.title === 'Pedagogo') {
    return pedagogo;
  }

  if (user?.profile.title === 'Psicólogo') {
    return psicologo;
  }
  if (user?.profile.title === 'Psico-pedagogo') {
    return psico_pedagogo;
  }
  return assistente_social;
}

export function useSidebarListMaster() {
  const { profile } = useAuth();
  if (profile?.title === 'Administrador') {
    return admin;
  }
  if (profile?.title === 'Fiscal') {
    return fiscal;
  }
  if (profile?.title === 'Convidado') {
    return convidado;
  }
  if (profile?.title === 'Pedagogo') {
    return pedagogo;
  }

  if (profile?.title === 'Psicólogo') {
    return psicologo;
  }
  if (profile?.title === 'Psico-pedagogo') {
    return psico_pedagogo;
  }
  return null;
}
