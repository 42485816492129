import React from 'react';
import {
  Switch, Route,
  BrowserRouter as Router,
  Redirect,
} from 'react-router-dom';

import SideBar from '../SideBar/SideBar';

import { Dashboard } from '../../pages/Dashboard';

import CadastrarProjeto from '../../pages/Pedagogo/CadastrarProjeto';
import CadCurso from '../../pages/Pedagogo/CadCurso';
import { useAuth, usePermission } from '../../hooks/auth';
import { PageError } from '../PageError';
import GerirTurma from '../../pages/Pedagogo/GerirTurma';
import GerirProjeto from '../../pages/Pedagogo/GerirProjeto';
import { EditProjeto } from '../../pages/Pedagogo/GerirProjeto/components/EditProjeto';
import { RelatorioPedagogo } from '../../pages/Pedagogo/Relatorio';
import { PerfilPsicologicoDoEgresso } from '../../pages/Psicologo/PerfilPsicologicoDoEgresso';
import { PagePerfil } from '../../pages/Psicologo/PerfilPsicologicoDoEgresso/components/PagePefil';
import { PageEditTurmas } from '../../pages/Pedagogo/GerirTurma/components/PageEditTurma';
import { AcompanhamentoPsicoPedagogico } from '../../pages/Psicopedagogo/Acompanhamento';
import { RelatorioPsicopedagogo } from '../../pages/Psicopedagogo/Relatorio';
import { PageFormPsicopedagogo } from '../../pages/Psicopedagogo/Acompanhamento/components/PageForm';
import { AnalisarEgresso } from '../../pages/Pedagogo/AnalisarEgresso';
import { PageAvaliacao } from '../../pages/Pedagogo/AnalisarEgresso/components/PageAvaliacao';
import { EstatisticaPedagogo } from '../../pages/Pedagogo/Estatistica';
import { PageHistorico } from '../../pages/Psicologo/PerfilPsicologicoDoEgresso/components/PageHistorico';
import { PageIndicadoresGraficos } from '../../pages/Psicologo/PerfilPsicologicoDoEgresso/components/PageIndicadoresGraficos';
import { RelatorioPsicologo } from '../../pages/Psicologo/Relatorio';
import { IndicadoresPsicologo } from '../../pages/Psicologo/indicadores';
import { PageHistoricoPsicopedagogo } from '../../pages/Psicopedagogo/Acompanhamento/components/PageHistorico';
import { PageIndicadoresIndividuaisPsicopedagogo } from '../../pages/Psicopedagogo/Acompanhamento/components/PageIndicadores';
import { IndicadoresPsicopedagogo } from '../../pages/Psicopedagogo/Indicadores';
import { AcompanhamentoSocial } from '../../pages/AssistenteSocial/AcompanhamentoSocial';
import { RelatoriosAssistente } from '../../pages/AssistenteSocial/Relatorios';
import { SocioEconomico } from '../../pages/Convidado/SocioEconomico';
import { IndicadoresSocioEconomico } from '../../pages/Convidado/SocioEconomico/components/IndicadorSocioEconomico';
import { Psicologicos } from '../../pages/Convidado/Psicologicos';
import { IndicadoresPsicologicos } from '../../pages/Convidado/Psicologicos/components/IndicadoresPsicologiocos';
import { Fiscalizacao } from '../../pages/Convidado/Fiscalizacao';
import { IndicadoresFiscalizacao } from '../../pages/Convidado/Fiscalizacao/components/IndicadoresFiscalizacao';

import { Capacitacao } from '../../pages/Convidado/Capacitacao';
import { VerEgresso } from '../../pages/Convidado/VerEgresso';
import { PageFormAssistenteSocial } from '../../pages/AssistenteSocial/AcompanhamentoSocial/components/PageForm';
import { PageHistoricoAs } from '../../pages/AssistenteSocial/AcompanhamentoSocial/components/PageHistorico';
import { PageIndicadoresIndividuaisAs } from '../../pages/AssistenteSocial/AcompanhamentoSocial/components/PageIndicadores';
import { SelecionarPerfil } from '../../pages/SelecionarPerfil';
import { admRoutes } from '../../routes/Adm';
import { fiscalRoutes } from '../../routes/Fiscal';

const Routes = () => {
  const permission = usePermission();
  const { isInEgresse, profile } = useAuth();

  return (
    <Router>
      <>
        {!isInEgresse && <SideBar />}

        {!isInEgresse && <div className="vertical" />}

        {permission.title === "Administrador" && (
        <div style={{
          background: '#fff', paddingLeft: !isInEgresse ? 20 : 0, paddingRight: !isInEgresse ? 20 : 0, paddingBottom: !isInEgresse ? 20 : 0, /* height: '100%', */ width: '100%', /* borderRadius: 10, */ /* marginTop: 20, marginBottom: 10, marginRight: 10 */
        }}
        >
          <Switch>
            {admRoutes.map(({ component, path }) => (
              <Route exact path={path} component={component} />
            ))}
          </Switch>
        </div>
        )}

        {permission.title === "Fiscal" && (
        <div style={{
          background: '#fff', paddingLeft: !isInEgresse ? 20 : 0, paddingRight: !isInEgresse ? 20 : 0, paddingBottom: !isInEgresse ? 20 : 0, /* height: '100%', */ width: '100%', /* borderRadius: 10, */ /* marginTop: 20, marginBottom: 10, marginRight: 10 */
        }}
        >
          <Switch>
            {fiscalRoutes.map(({ component, path }) => (
              <Route exact path={path} component={component} />
            ))}
          </Switch>
        </div>
        )}

        {permission.title === "Pedagogo" && (
        <div style={{
          background: '#fff', paddingLeft: !isInEgresse ? 20 : 0, paddingRight: !isInEgresse ? 20 : 0, paddingBottom: !isInEgresse ? 20 : 0, /* height: '100%', */ width: '100%', /* borderRadius: 10, */ /* marginTop: 20, marginBottom: 10, marginRight: 10 */
        }}
        >
          <Switch>
            <Route exact path="/">
              <Redirect to="/dashboard" />
            </Route>
            <Route exact path="/dashboard" component={Dashboard} />
            <Route exact path="/cad_project" component={CadastrarProjeto} />
            <Route exact path="/gerir-turmas/cadastrar" component={CadCurso} />
            <Route exact path="/gerir-turmas/editar" component={PageEditTurmas} />
            <Route exact path="/gerir-turmas" component={GerirTurma} />
            <Route exact path="/gerir-projetos" component={GerirProjeto} />
            <Route exact path="/gerir-projetos/editar" component={EditProjeto} />
            <Route exact path="/relatorio" component={RelatorioPedagogo} />
            <Route exact path="/analisar-egresso" component={AnalisarEgresso} />
            <Route exact path="/analisar-egresso/avaliação" component={PageAvaliacao} />
            <Route exact path="/analisar-egresso/avaliação" component={PageAvaliacao} />
            <Route exact path="/estatistica" component={EstatisticaPedagogo} />

            <Route path="*" component={PageError} />
          </Switch>
        </div>
        )}

        {permission.title === "Psicólogo" && (
        <div style={{
          background: '#fff', paddingLeft: !isInEgresse ? 20 : 0, paddingRight: !isInEgresse ? 20 : 0, paddingBottom: !isInEgresse ? 20 : 0, /* height: '100%', */ width: '100%', /* borderRadius: 10, */ /* marginTop: 20, marginBottom: 10, marginRight: 10 */
        }}
        >
          <Switch>
            <Route exact path="/">
              <Redirect to="/dashboard" />
            </Route>
            <Route exact path="/dashboard" component={Dashboard} />
            <Route exact path="/relatorios" component={RelatorioPsicologo} />
            <Route exact path="/perfil" component={PerfilPsicologicoDoEgresso} />
            <Route exact path="/perfil/egresso" component={PagePerfil} />
            <Route exact path="/perfil/historico" component={PageHistorico} />
            <Route exact path="/perfil/indicadores" component={PageIndicadoresGraficos} />
            <Route exact path="/indicadores" component={IndicadoresPsicologo} />
            <Route path="*" component={PageError} />
          </Switch>
        </div>
        )}

        {permission.title === "Psico-pedagogo" && (
        <div style={{
          background: '#fff', paddingLeft: !isInEgresse ? 20 : 0, paddingRight: !isInEgresse ? 20 : 0, paddingBottom: !isInEgresse ? 20 : 0, /* height: '100%', */ width: '100%', /* borderRadius: 10, */ /* marginTop: 20, marginBottom: 10, marginRight: 10 */
        }}
        >
          <Switch>
            <Route exact path="/">
              <Redirect to="/dashboard" />
            </Route>
            <Route exact path="/dashboard" component={Dashboard} />
            <Route exact path="/relatorios" component={RelatorioPsicopedagogo} />
            <Route exact path="/acompanhamento" component={AcompanhamentoPsicoPedagogico} />
            <Route exact path="/acompanhamento/consulta" component={PageFormPsicopedagogo} />
            <Route exact path="/acompanhamento/historico" component={PageHistoricoPsicopedagogo} />
            <Route exact path="/acompanhamento/indicadores" component={PageIndicadoresIndividuaisPsicopedagogo} />
            <Route exact path="/indicadores" component={IndicadoresPsicopedagogo} />
            <Route path="*" component={PageError} />
          </Switch>
        </div>
        )}

        {permission.title === "Assistente Social" && (
        <div style={{
          background: '#fff', paddingLeft: !isInEgresse ? 20 : 0, paddingRight: !isInEgresse ? 20 : 0, paddingBottom: !isInEgresse ? 20 : 0, /* height: '100%', */ width: '100%', /* borderRadius: 10, */ /* marginTop: 20, marginBottom: 10, marginRight: 10 */
        }}
        >
          <Switch>
            <Route exact path="/">
              <Redirect to="/dashboard" />
            </Route>
            <Route exact path="/dashboard" component={Dashboard} />
            <Route exact path="/acompanhamento" component={AcompanhamentoSocial} />
            <Route exact path="/acompanhamento/consulta" component={PageFormAssistenteSocial} />
            <Route exact path="/acompanhamento/historico" component={PageHistoricoAs} />
            <Route exact path="/acompanhamento/indicadores" component={PageIndicadoresIndividuaisAs} />
            <Route exact path="/relatorios" component={RelatoriosAssistente} />
            <Route path="*" component={PageError} />
          </Switch>
        </div>
        )}

        {permission.title === "Convidado" && (
        <div style={{
          background: '#fff', paddingLeft: !isInEgresse ? 20 : 0, paddingRight: !isInEgresse ? 20 : 0, paddingBottom: !isInEgresse ? 20 : 0, /* height: '100%', */ width: '100%', /* borderRadius: 10, */ /* marginTop: 20, marginBottom: 10, marginRight: 10 */
        }}
        >
          <Switch>
            <Route exact path="/">
              <Redirect to="/dashboard" />
            </Route>
            <Route exact path="/dashboard" component={Dashboard} />
            <Route exact path="/pesquisar-egresso" component={VerEgresso} />
            <Route exact path="/socio-economico" component={SocioEconomico} />
            <Route exact path="/socio-economico/indicadores" component={IndicadoresSocioEconomico} />
            <Route exact path="/psicologicos" component={Psicologicos} />
            <Route exact path="/psicologicos/indicadores" component={IndicadoresPsicologicos} />
            <Route exact path="/fiscalizacao" component={Fiscalizacao} />
            <Route exact path="/fiscalizacao/indicadores" component={IndicadoresFiscalizacao} />
            <Route exact path="/capacitacao" component={Capacitacao} />
            <Route path="*" component={PageError} />
          </Switch>
        </div>
        )}

        {permission.title === 'master' && (
        <div className="w-100">
          <Route exact path="/perfis" component={SelecionarPerfil} />
          {
            profile?.title === "Administrador"
            && (
              <div style={{
                background: '#fff', paddingLeft: !isInEgresse ? 20 : 0, paddingRight: !isInEgresse ? 20 : 0, paddingBottom: !isInEgresse ? 20 : 0, /* height: '100%', */ width: '100%', /* borderRadius: 10, */ /* marginTop: 20, marginBottom: 10, marginRight: 10 */
              }}
              >
                <Switch>
                  <Route exact path="/">
                    <Redirect to="/dashboard" />
                  </Route>

                  {admRoutes.map(({ component, path }) => (
                    path === '/'
                      ? (
                        <Route key={path} exact path="/">
                          <Redirect to="/dashboard" />
                        </Route>
                      )
                      : <Route key={path} exact path={path} component={component} />
                  ))}
                </Switch>
              </div>
            )
          }
          {
            profile?.title === "Fiscal" && (
              <div style={{
                background: '#fff', paddingLeft: !isInEgresse ? 20 : 0, paddingRight: !isInEgresse ? 20 : 0, paddingBottom: !isInEgresse ? 20 : 0, /* height: '100%', */ width: '100%', /* borderRadius: 10, */ /* marginTop: 20, marginBottom: 10, marginRight: 10 */
              }}
              >
                <Switch>
                  {fiscalRoutes.map(({ component, path }) => (
                    <Route exact path={path} component={component} />
                  ))}
                </Switch>
              </div>
            )
          }
          {
            profile?.title === "Pedagogo"
            && (
              <div style={{
                background: '#fff', paddingLeft: !isInEgresse ? 20 : 0, paddingRight: !isInEgresse ? 20 : 0, paddingBottom: !isInEgresse ? 20 : 0, /* height: '100%', */ width: '100%', /* borderRadius: 10, */ /* marginTop: 20, marginBottom: 10, marginRight: 10 */
              }}
              >
                <Switch>
                  <Route exact path="/">
                    <Redirect to="/dashboard" />
                  </Route>
                  <Route exact path="/dashboard" component={Dashboard} />
                  <Route exact path="/cad_project" component={CadastrarProjeto} />
                  <Route exact path="/gerir-turmas/cadastrar" component={CadCurso} />
                  <Route exact path="/gerir-turmas/editar" component={PageEditTurmas} />
                  <Route exact path="/gerir-turmas" component={GerirTurma} />
                  <Route exact path="/gerir-projetos" component={GerirProjeto} />
                  <Route exact path="/gerir-projetos/editar" component={EditProjeto} />
                  <Route exact path="/relatorio" component={RelatorioPedagogo} />
                  <Route exact path="/analisar-egresso" component={AnalisarEgresso} />
                  <Route exact path="/analisar-egresso/avaliação" component={PageAvaliacao} />
                  <Route exact path="/analisar-egresso/avaliação" component={PageAvaliacao} />
                  <Route exact path="/estatistica" component={EstatisticaPedagogo} />

                  <Route path="*" component={PageError} />
                </Switch>
              </div>
            )
          }
          {
            profile?.title === "Psicólogo" && (
              <div style={{
                background: '#fff', paddingLeft: !isInEgresse ? 20 : 0, paddingRight: !isInEgresse ? 20 : 0, paddingBottom: !isInEgresse ? 20 : 0, /* height: '100%', */ width: '100%', /* borderRadius: 10, */ /* marginTop: 20, marginBottom: 10, marginRight: 10 */
              }}
              >
                <Switch>
                  <Route exact path="/">
                    <Redirect to="/dashboard" />
                  </Route>
                  <Route exact path="/dashboard" component={Dashboard} />
                  <Route exact path="/relatorios" component={RelatorioPsicologo} />
                  <Route exact path="/perfil" component={PerfilPsicologicoDoEgresso} />
                  <Route exact path="/perfil/egresso" component={PagePerfil} />
                  <Route exact path="/perfil/historico" component={PageHistorico} />
                  <Route exact path="/perfil/indicadores" component={PageIndicadoresGraficos} />
                  <Route exact path="/indicadores" component={IndicadoresPsicologo} />
                  <Route path="*" component={PageError} />
                </Switch>
              </div>
            )
          }
          {/* {
            profile?.title === "Psicólogo" && (
              <Switch>
                <Route exact path="/">
                  <Redirect to="/dashboard" />
                </Route>
                <Route exact path="/dashboard" component={Dashboard} />
                <Route exact path="/relatorios" component={RelatorioPsicologo} />
                <Route exact path="/perfil" component={PerfilPsicologicoDoEgresso} />
                <Route exact path="/perfil/egresso" component={PagePerfil} />
                <Route exact path="/perfil/historico" component={PageHistorico} />
                <Route exact path="/perfil/indicadores" component={PageIndicadoresGraficos} />
                <Route exact path="/indicadores" component={IndicadoresPsicologo} />
                <Route path="*" component={PageError} />
              </Switch>
            )
          } */}
          {
            profile?.title === "Psico-pedagogo" && (
              <div style={{
                background: '#fff', paddingLeft: !isInEgresse ? 20 : 0, paddingRight: !isInEgresse ? 20 : 0, paddingBottom: !isInEgresse ? 20 : 0, /* height: '100%', */ width: '100%', /* borderRadius: 10, */ /* marginTop: 20, marginBottom: 10, marginRight: 10 */
              }}
              >
                <Switch>
                  <Route exact path="/">
                    <Redirect to="/dashboard" />
                  </Route>
                  <Route exact path="/dashboard" component={Dashboard} />
                  <Route exact path="/relatorios" component={RelatorioPsicopedagogo} />
                  <Route exact path="/acompanhamento" component={AcompanhamentoPsicoPedagogico} />
                  <Route exact path="/acompanhamento/consulta" component={PageFormPsicopedagogo} />
                  <Route exact path="/acompanhamento/historico" component={PageHistoricoPsicopedagogo} />
                  <Route exact path="/acompanhamento/indicadores" component={PageIndicadoresIndividuaisPsicopedagogo} />
                  <Route exact path="/indicadores" component={IndicadoresPsicopedagogo} />
                  <Route path="*" component={PageError} />
                </Switch>
              </div>
            )
          }
          {
            profile?.title === "Assistente Social" && (
              <div style={{
                background: '#fff', paddingLeft: !isInEgresse ? 20 : 0, paddingRight: !isInEgresse ? 20 : 0, paddingBottom: !isInEgresse ? 20 : 0, /* height: '100%', */ width: '100%', /* borderRadius: 10, */ /* marginTop: 20, marginBottom: 10, marginRight: 10 */
              }}
              >
                <Switch>
                  <Route exact path="/">
                    <Redirect to="/dashboard" />
                  </Route>
                  <Route exact path="/dashboard" component={Dashboard} />
                  <Route exact path="/acompanhamento" component={AcompanhamentoSocial} />
                  <Route exact path="/acompanhamento/consulta" component={PageFormAssistenteSocial} />
                  <Route exact path="/acompanhamento/historico" component={PageHistoricoAs} />
                  <Route exact path="/acompanhamento/indicadores" component={PageIndicadoresIndividuaisAs} />
                  <Route exact path="/relatorios" component={RelatoriosAssistente} />
                  <Route path="*" component={PageError} />
                </Switch>
              </div>
            )
          }
          {
            profile?.title === "Convidado" && (
              <div style={{
                background: '#fff', paddingLeft: !isInEgresse ? 20 : 0, paddingRight: !isInEgresse ? 20 : 0, paddingBottom: !isInEgresse ? 20 : 0, /* height: '100%', */ width: '100%', /* borderRadius: 10, */ /* marginTop: 20, marginBottom: 10, marginRight: 10 */
              }}
              >
                <Switch>
                  <Route exact path="/">
                    <Redirect to="/dashboard" />
                  </Route>
                  <Route exact path="/dashboard" component={Dashboard} />
                  <Route exact path="/pesquisar-egresso" component={VerEgresso} />
                  <Route exact path="/socio-economico" component={SocioEconomico} />
                  <Route exact path="/socio-economico/indicadores" component={IndicadoresSocioEconomico} />
                  <Route exact path="/psicologicos" component={Psicologicos} />
                  <Route exact path="/psicologicos/indicadores" component={IndicadoresPsicologicos} />
                  <Route exact path="/fiscalizacao" component={Fiscalizacao} />
                  <Route exact path="/fiscalizacao/indicadores" component={IndicadoresFiscalizacao} />
                  <Route exact path="/capacitacao" component={Capacitacao} />
                  <Route path="*" component={PageError} />
                </Switch>
              </div>
            )
          }
        </div>
        )}

        <div className="vertical" />
      </>
    </Router>
  );
};

export default Routes;
