import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { AbaProgress } from '../../../../components/develop-nilton-components/AbaProgress';
import { SectionHeader } from '../../../../components/SectionHeader';
import { SpinnerLoading } from '../../../../components/SpinnerLoading';
import api from '../../../../services/api';
import { AbaContainer } from './AbaContainer';
import { Comportamento } from './Comportamento';
import { Concentração } from './Concentração';
import { Observação } from './Observação';
import { Queixa } from './Queixa';

export function PagePerfil() {
  const [tab, setTab] = useState(1);
  const { state } = useLocation<any>();
  const [acompanhamento, setAcompanhamento] = useState<any>(null);
  const history = useHistory();
  const [load, setLoad] = useState(false);

  const { getValues, setValue } = useForm();

  // console.log(getValues());

  useEffect(() => {
    api.post('/psychologist', { egress_id: state.id })
      .then(({ data }) => {
        const keys = Object.keys(data);
        keys.forEach((key) => {
          setValue(key, data[key]);
        });
        setAcompanhamento(data);
      })
      .catch((error) => {
        const errors = error.response.data.acompanhamento;
        const keys = Object.keys(errors);
        keys.forEach((key) => {
          setValue(key, errors[key]);
        });
        setAcompanhamento(errors);
      });
  }, []);

  async function handleCancelar() {
    setLoad(true);
    try {
      const { data } = await api.delete(`/psychologist/${getValues().id}`);
      toast.success(data.message);
      history.push('/perfil');
    } catch (error) {
      // @ts-ignore
      toast.success(error.response.datamessage);
    }
    setLoad(false);
  }

  console.log(getValues());

  return (
    <div style={{ width: "100%" }}>
      <SectionHeader subtitle="Perfil psicológico do egresso" backPage="/perfil" />
      <p className="text-primary h2">{`Nome: ${state.nome}`}</p>

      <div className="d-flex justify-content-between" style={{ width: "100%" }}>
        <AbaProgress
          abas={['Comportamento', 'Concentração', 'Queixa', 'Observações']}
          useStateTab={[tab, setTab]}
        />
        {load ? (
          <button className="btn btn-danger btn-lg">
            <SpinnerLoading />
          </button>
        ) : (
          <button className="btn btn-danger btn-lg" onClick={handleCancelar}>
            Cancelar
          </button>
        )}

      </div>

      {!acompanhamento && <div className="d-flex justify-content-center"><SpinnerLoading /></div>}

      {acompanhamento && (
      <AbaContainer
        tab={tab}
        components={[
          <Comportamento
            getValues={getValues}
            setTab={setTab}
            setValue={setValue}
          />,
          <Concentração
            getValues={getValues}
            setTab={setTab}
            setValue={setValue}
          />,
          <Queixa
            getValues={getValues}
            setTab={setTab}
            setValue={setValue}
          />,
          <Observação
            getValues={getValues}
            setTab={setTab}
            setValue={setValue}
          />,
        ]}
      />
      )}
    </div>
  );
}
