import { useQuery } from "react-query";
import api from "../../services/api";
import { CursosInterrese } from "../../types/CursosInterreses";
import { IFetchParams } from "../../types/IFetchParams";

export type CoursesAxiosResponse = CursosInterrese[]

export function useGetCoursesConcern({ page, search }:IFetchParams) {
  const { isLoading, data: courses_concern } = useQuery<CoursesAxiosResponse>(
    ['courses_concern', page, search],
    async () => {
      const { data } = await api.get('/courses_concern', { params: { search, page, perPage: 15 } });
      return data;
    },
  );
  return { isLoading, courses_concern };
}
