import React, { useState } from 'react';
import ReactPaginate from 'react-paginate';
import styled from 'styled-components';
import { SpinnerLoading } from '../SpinnerLoading';
import { ConteinerPagination } from './style';

type IProps = {
  meta: any
  setPage(page?: number, value?: string): Promise<void> | void
  adress?: string
  load?: boolean
}

export function Pagination({
  meta, setPage, adress, load,
}: IProps) {
  const [currentPage, setCurrentPage] = useState((meta?.current_page || 1) - 1);

  React.useEffect(() => {
    if (meta?.current_page) {
      console.log(meta?.current_page);
      setCurrentPage((meta?.current_page || 1) - 1);
    }
  }, [meta]);

  return (
    <div style={{
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      width: "100%",
    }}
    >
      <div className="d-flex justify-content-between w-25">
        <Wrapper>
          {`Mostrando ${meta?.current_page} de ${meta?.last_page} página(s)`}
        </Wrapper>
        <div>
          {load && <SpinnerLoading />}
        </div>
      </div>
      <ConteinerPagination>
        <ReactPaginate
          previousLabel="&laquo;"
          nextLabel="&raquo;"
          breakLabel="..."
          breakClassName="break-me"
          pageCount={meta?.last_page}
          marginPagesDisplayed={2}
          containerClassName="pagination"
          activeClassName="active"
          forcePage={currentPage}
          onPageChange={(page) => {
            if (adress) {
              setPage(page.selected + 1, adress);
            } else {
              setPage(page.selected + 1);
            }
          }}
        />
      </ConteinerPagination>
    </div>
  );
}

const Wrapper = styled.p`
  @media (max-width: 768px) {
    display: none;
  }
`;
