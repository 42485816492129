/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import { FieldError } from 'react-hook-form';

type PropsForm = {
  formValues: string[]
  formStateErrors: (FieldError | undefined)[]
}

export function useFormValidate({ formValues, formStateErrors }:PropsForm) {
  const [formIsValidated, setFormIsValited] = useState<"was-validated" | "need-validation">("need-validation");

  function formValid(values: string[]) {
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < values.length; ++i) {
      if (formStateErrors[i]) {
        console.log(formStateErrors[i]);
        setFormIsValited("was-validated");
        break;
      }
      setFormIsValited("need-validation");
    }
  }

  useEffect(() => {
    if (formStateErrors.length !== 0) {
      formValid(formValues);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formStateErrors]);
  return { formIsValidated };
}
