/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import ReactTooltip from 'react-tooltip';
import { useModal } from '../../../../components/develop-nilton-components/hooks/useModal';

import { ButtonAddEgressos } from './ButtonAddEgressos';
import { ButtonEdit } from './ButtonEdit';
import { ButtonEditFigma } from './ButtonEditFigma';
import { ButtonHistoryFigma } from './ButtonHistoryFigma';
import { ButtonExcluir } from './ButtonExcluir';
import { ModalDeleteRonda } from './ModalDeleteRonda';
import { ModalEditRonda } from './ModalEditRonda';

type PropsOptionTable = {
  item: any
  handleUpdate():void | Promise<void>
  disable?: boolean
}

export function OptionsTable({
  item, handleUpdate, disable,
}:PropsOptionTable) {
  const functionModalDelete = useModal();
  const functionModalEdit = useModal();

  return (
    <div style={{ display: "flex", gap: '15px' }}>
      {/* <ButtonAddEgressos
        item={item}
        disable={!!disable}
      /> */}
      {disable ? (
        <ButtonHistoryFigma
          item={item}
        />

      ) : (
        <ButtonEditFigma
          item={item}
          // disable={!!disable}
        />
      )}

      {/* <ButtonExcluir disable={!!disable} handleOpenModalDelete={functionModalDelete.handleOpenModal} /> */}

      {/* <ModalEditRonda
        isOpenModal={functionModalEdit.isOpenModal}
        handleIsOpenModal={functionModalEdit.handleOpenModal}
        item={item}
        handleUpdate={handleUpdate}
      />

      <ModalDeleteRonda
        handleIsOpenModal={functionModalDelete.handleOpenModal}
        isOpenModal={functionModalDelete.isOpenModal}
        id={item.id}
        handleUpdate={handleUpdate}
      /> */}

      <ReactTooltip />
    </div>
  );
}
