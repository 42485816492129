/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import api from '../../../../services/api';

export function UseRoundEgressesOfFical() {
  const [roundEgressesOfFiscal, setRoundEgressesOfFiscal] = useState<any |null>(null);

  const { state } = useLocation<any>();

  async function handleUseRoundEgressesOfFical() {
    try {
      const { data } = await api.get(`/rounds/${state.id}`);

      setRoundEgressesOfFiscal(data.egresses);
    } catch (error) {
      // @ts-ignore
      toast(error.response.data.message, { type: "error" });
    }
  }

  useEffect(() => {
    handleUseRoundEgressesOfFical();
  }, []);

  return { roundEgressesOfFiscal, setRoundEgressesOfFiscal, handleUseRoundEgressesOfFical };
}
