import styled from 'styled-components';

export const InputField = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;

    label {
        font-size: 24px;
        font-weight: 700;
        line-height: 28px;
        letter-spacing: 0px;
        text-align: left;
    }

    input {
        width: 100%;
        font-size: 18px;
        font-weight: 400;
        line-height: 28px;
        letter-spacing: 0em;
        text-align: left;
    }

    div {
        display: flex;
        /* align-items: center; */
        gap: 10px;
    }
`;
