import React, { useState } from 'react';
import { Chart } from 'react-chartjs-2';
import { AbaFilterButtons, AbaFilterContent } from '../../../components/develop-nilton-components/AbaFiltro';
import { SectionHeader } from '../../../components/SectionHeader';
import { GraficoAnual } from '../../Psicologo/indicadores/components/GraficoAnual';
import { GraficoTrimestral } from '../../Psicologo/indicadores/components/GraficoTrimestral';
import { useGet_data_set_comportamento } from '../../Psicologo/indicadores/hooks/useGet_data_set_comportamento';
import { useGet_data_set_concentracao } from '../../Psicologo/indicadores/hooks/useGet_data_set_concentracao';
import { useGet_data_set_observaçao } from '../../Psicologo/indicadores/hooks/useGet_data_set_observacao';
import { useGet_data_set_queixa } from '../../Psicologo/indicadores/hooks/useGet_data_set_queixa';

export function Psicologicos() {
  const [tab, setTab] = useState(1);
  const { data_set_comportamento, handle_onChange_comportamento } = useGet_data_set_comportamento();
  const { data_set_concentracao, handle_onChange_concentracao } = useGet_data_set_concentracao();
  const { data_set_queixa, handle_onChange_queixa } = useGet_data_set_queixa();
  const { data_set_observacao, handle_onChange_observacao } = useGet_data_set_observaçao();

  const load = data_set_comportamento && data_set_concentracao && data_set_queixa && data_set_observacao;

  const optionsChart = {
    plugins: { legend: { display: true, position: "bottom" } },
  };

  const chartArray = [
    {
      title: "Comportamento",
      type: "line",
      data: data_set_comportamento,
    },
    {
      title: "Concentração",
      type: "line",
      data: data_set_concentracao,
    },
    {
      title: "Queixa (ver outro período)",
      type: "line",
      data: data_set_queixa,
    },
    {
      title: "Observações",
      type: "line",
      data: data_set_observacao,
    },
  ];
  return (
    <div className="w-100">
      <SectionHeader subtitle="Indicadores Psicológicos" />

      <AbaFilterButtons buttons={["Anual", "Trimestral"]} useStateTab={[tab, setTab]} />

      <AbaFilterContent
        tab={tab}
        components={[
          <GraficoAnual />,
          <GraficoTrimestral />,
        ]}
      />

    </div>
  );
}
