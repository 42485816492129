import { format } from 'date-fns';
import React, { useState } from 'react';
import ReactTooltip from 'react-tooltip';
import { Badge } from '../../../../components/develop-nilton-components/Badge';
import { IconAdd } from '../../../../components/develop-nilton-components/IconAdd';
import { IconDetails } from '../../../../components/develop-nilton-components/IconDetails';
import { IconEdit } from '../../../../components/develop-nilton-components/IconEdit';
import { IconRemove } from '../../../../components/develop-nilton-components/IconRemove';
import { Td, Tr } from '../../../../components/develop-nilton-components/TablePadrão';
import { Tbody } from '../types';
import { ModalDetalhesProjeto } from './ModalDetalhesProjeto';

export function TbodyConcluidos({
  headWidth, projetoItem, index, handleProjetos,
}:Tbody) {
  const [detalhesIsOpen, setDetalhesIsOpen] = useState(false);

  function handleDetalhesIsOpen() {
    setDetalhesIsOpen(!detalhesIsOpen);
  }

  return (
    <Tr>
      <Td width={headWidth[0]}>{index}</Td>
      <Td width={headWidth[1]}>{projetoItem.nome}</Td>
      <Td width={headWidth[2]}>
        <Badge type="success">Concluído</Badge>
      </Td>
      <Td width={headWidth[3]}>
        <div onClick={handleDetalhesIsOpen}>
          <IconDetails cursor size={22} tooltipMessage="Detalhes" />
        </div>
        <ModalDetalhesProjeto
          isOpen={detalhesIsOpen}
          handleIsOpen={handleDetalhesIsOpen}
          projeto={projetoItem}
        />
        <ReactTooltip />
      </Td>
    </Tr>
  );
}
