import React from 'react';
import { AiFillWarning } from 'react-icons/ai';
import { BsFillTrashFill } from 'react-icons/bs';
import { ImBlocked } from 'react-icons/im';
import ReactModal from 'react-modal';
import styled from 'styled-components';

type IPropsModalDelete = {
  isOpen: boolean
  handleIsOpen():void
  deleteFunction(): void | Promise<void>
  children: React.ReactNode
}

const styleModalDelete = {
  content: {
    width: "350px",
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    borderRadius: "10px",
    overflow: "none",
  },
  overlay: {
    background: "rgba(1,1,1,.5)",
  },
};

export function ModalDelete({
  handleIsOpen, isOpen, deleteFunction, children,
}:IPropsModalDelete) {
  function handleDelete() {
    deleteFunction();
    handleIsOpen();
  }
  return (
    <ReactModal
      isOpen={isOpen}
      ariaHideApp={false}
      shouldCloseOnOverlayClick
      onRequestClose={() => {
        handleIsOpen();
      }}
      style={styleModalDelete}
    >
      <Center>
        <AiFillWarning color="#ffbf00" size={200} />
        <P><strong>{children}</strong></P>
        <ButtonContainer>
          <Buttton className="btn btn-secondary btn-lg" onClick={handleIsOpen}>
            <ImBlocked size={20} color="white" />
            Cancelar
          </Buttton>
          <Buttton className="btn btn-danger btn-lg" onClick={handleDelete}>
            <BsFillTrashFill size={20} color="white" />
            Excluir
          </Buttton>
        </ButtonContainer>
      </Center>
    </ReactModal>
  );
}

const P = styled.p`
  font-size: 130%;
`;

const Center = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-around;
`;

const Buttton = styled.button`
  display: flex;
  gap: 10px;
  justify-content: center;
  align-items: center;
`;
