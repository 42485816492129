import React, { useEffect, useState } from 'react';
import api from '../../../../services/api';
import { data } from '../types';

export function useGetProjetosPententes() {
  const [projetosPendentes, setProjetos] = useState<any | null>(null);
  const [loadPendentes, setLoad] = useState(false);

  async function handleProjetosPendentes(page = 1, search?: string) {
    setLoad(true);
    const { data } = await api.get('/projects', {
      params: {
        page,
        nome: search,
        filter: "Pendente de Homologação",
      },
    });

    if (data.data.length === 0 && page > 1) {
      handleProjetosPendentes(page - 1);
    }

    setProjetos(data);
    setLoad(false);
  }

  useEffect(() => {
    handleProjetosPendentes();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return {
    projetosPendentes, handleProjetosPendentes, loadPendentes,
  };
}
