import React from 'react';
import styled from 'styled-components';

type PropsStatus = {
  type: "success" | "warning" | 'danger' | 'primary' | "info"
  children: React.ReactNode
}

export function Badge({ children, type }:PropsStatus) {
  return (
    <div
      className={`badge badge-${type}`}
      style={{
        display: "flex", alignItems: 'center', background: type === 'success' ? '#68996D' : type === 'info' ? '#1B7E9F' : '#CBB708', padding: 10, cursor: "default", height: "20px", marginTop: "5px",
      }}
    >
      <Wrapper>{children}</Wrapper>
    </div>
  );
}

const Wrapper = styled.h1`
  font-size: 12px;
  margin-bottom: 0px;
  @media (max-width:1024px) {
   display: none;
  }
`;
