import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Label,
  Input,
} from 'reactstrap';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import { DatePicker } from 'reactstrap-date-picker';
import { format } from 'date-fns';
import { useFormValidate } from '../../../../components/develop-nilton-components/hooks/useFormValidate';
import { SpinnerLoading } from '../../../../components/SpinnerLoading';
import api from '../../../../services/api';
import { useGetOcurrences } from '../hooks/useGetOcurrences';
import { ModalImage } from '../../../../components/modalImage/modalImage';

type PropsAddEgressesInTheRounds = {
  round_egresses_egressos: any;
  egresse: any;
  handleSetIsOpen: [boolean, (boolean: boolean) => void];
  handleUpdate(page?: number): Promise<void> | void;
};

export function ModalAddComprovante({
  round_egresses_egressos,
  handleSetIsOpen,
  handleUpdate,
}: PropsAddEgressesInTheRounds) {
  const { ocurrences } = useGetOcurrences();
  const [comprovante, setComprovante] = useState<File | null>(null);
  const [load, setLoad] = useState(false);
  const [isOpen, setIsOpen] = handleSetIsOpen;
  const [isOpenImage, setIsOpenImage] = useState(false);
  const [dataExec, setDataExec] = useState('');
  const [timeExec, setTimeExec] = useState('');
  const [ocorrencia, setOcorrencia] = useState('');
  const [observacao, setObservacao] = useState('');

  // erros
  const [dataExecError, setDataExecError] = useState('');
  const [timeExecError, setTimeExecError] = useState('');
  const [ocorrenciaError, setOcorrenciaError] = useState('');
  function handleOpenImage() {
    setIsOpenImage(!isOpenImage);
  }

  const data = new Date();

  const { register, handleSubmit, formState, reset } = useForm({
    defaultValues: {
      comprovante: round_egresses_egressos?.comprovante,
      observacao: round_egresses_egressos?.observacao,
      ocorrencia_id: round_egresses_egressos?.ocorrencia_id,
      hora_ocorrencia: '',
    },
  });

  function handleSetComprovante(e: any) {
    setComprovante(e.target.files[0]);
  }

  // async function onSubmit(formValues: any) {
  //
  //   setLoad(true);
  //   try {
  //
  //     const formData = new FormData();
  //     const dataExecAux = new Date(dataExec);
  //     dataExecAux.setHours(
  //       Number(timeExec.split(':')[0]),
  //       Number(timeExec.split(':')[1]),
  //     );
  //
  //     if (formValues.comprovante) {
  //       if (formValues.comprovante.length > 0) {
  //         formData.append('comprovante', formValues.comprovante[0]);
  //       }
  //     }
  //     if (formValues.ocorrencia_id === '' && formValues.observacao !== '') {
  //
  //       formData.append('data', JSON.stringify({}));
  //     } else {
  //       formData.append(
  //         'data',
  //         JSON.stringify({
  //           ocorrencia_id: formValues.ocorrencia_id,
  //           data_ocorrencia: dataExecAux,
  //           observacao: formValues.observacao,
  //         }),
  //       );
  //     }
  //
  //     await api.put(`/rounds_egresses/${round_egresses_egressos.id}`, formData);
  //     await handleUpdate();
  //     toast('Comprovante adicionado com sucesso.', { type: 'success' });
  //     toggle();
  //   } catch (error) {
  //     console.log(error);
  //     // @ts-ignore
  //     toast(error.response?.data.message, { type: 'error' });
  //   }
  //   setLoad(false);
  // }

  async function onSubmit() {
    if (ocorrencia === '') {
      setOcorrenciaError('Campo Obrigatório');
    } else {
      setOcorrenciaError('');
    }
    if (dataExec === '') {
      setDataExecError('Campo Obrigatório');
    } else {
      setDataExecError('');
    }
    if (timeExec === '') {
      setTimeExecError('Campo Obrigatório');
    } else {
      setTimeExecError('');
    }
    if (ocorrencia !== '' && dataExec !== '' && timeExec !== '') {
      setLoad(true);
      try {
        const formData = new FormData();
        const dataExecAux = new Date(dataExec);
        dataExecAux.setHours(
          Number(timeExec.split(':')[0]),
          Number(timeExec.split(':')[1]),
        );

        if (comprovante) {
          // if (comprovante.length > 0) {

          formData.append('comprovante', comprovante);
          // }
        }

        if (ocorrencia === '' && observacao !== '') {
          formData.append('data', JSON.stringify({}));
        } else {
          formData.append(
            'data',
            JSON.stringify({
              ocorrencia_id: ocorrencia,
              data_ocorrencia: dataExecAux,
              observacao,
            }),
          );
        }

        await api.put(
          `/rounds_egresses/${round_egresses_egressos.id}`,
          formData,
        );
        await handleUpdate();
        toast('Comprovante adicionado com sucesso.', { type: 'success' });
        toggle();
      } catch (error) {
        console.log(error);
        // @ts-ignore
        toast(error.response?.data.message, { type: 'error' });
      }
      setLoad(false);
    }
  }

  const toggle = () => {
    setIsOpen(!isOpen);
    setComprovante(null);
    setDataExec('');
    setDataExecError('');
    setTimeExecError('');
    setOcorrenciaError('');
    setTimeExec('');
    reset();
  };

  const formValues = ['ocorrencia_id', 'hora_ocorrencia'];
  const formStateErrors = [
    formState.errors.ocorrencia_id,
    formState.errors.hora_ocorrencia,
  ];

  const { formIsValidated } = useFormValidate({ formValues, formStateErrors });
  function handleChange(v: string, f: string): void {
    setDataExec(v);
  }
  function handleChangeOcorrencia(e): void {
    setOcorrencia(e.target.value);

    if (dataExec === '') {
      setDataExec(new Date().toISOString());
    }
    if (timeExec === '') {
      setTimeExec(
        `${String(new Date().getHours()).padStart(2, '0')}:${String(
          new Date().getMinutes(),
        ).padStart(2, '0')}`,
      );
    }
  }

  return (
    <Modal
      isOpen={isOpen}
      ariaHideApp={false}
      shouldCloseOnOverlayClick
      toggle={toggle}
      size="lg"
    >
      <ModalHeader toggle={toggle}>Comprovante de Ronda</ModalHeader>
      <Form>
        <ModalBody>
          <div style={{ marginTop: 10 }}>
            <img
              onClick={handleOpenImage}
              style={{ cursor: 'pointer' }}
              src={
                round_egresses_egressos?.egresso?.avatar?.url_file
                  ? round_egresses_egressos?.egresso?.avatar?.url_file
                  : 'https://static.vecteezy.com/ti/vetor-gratis/p1/1840618-imagem-perfil-icone-masculino-icone-humano-ou-pessoa-sinal-e-simbolo-gr%C3%A1tis-vetor.jpg'
              }
              width={150}
              height={150}
            />
          </div>

          <div style={{ marginTop: 10 }}>
            <Label style={{ fontSize: 16, fontWeight: 'bold' }}>Nome</Label>
            <Input
              disabled
              placeholder={round_egresses_egressos?.egresso?.nome}
            />
          </div>

          <div style={{ marginTop: 10 }}>
            <Label style={{ fontSize: 16, fontWeight: 'bold' }}>Endereço</Label>
            <Input
              disabled
              placeholder={`${round_egresses_egressos?.address?.endereco}, nº ${round_egresses_egressos?.address?.numero_endereco}, ${round_egresses_egressos?.address?.bairro}, ${round_egresses_egressos?.address?.cep}, ${round_egresses_egressos?.address?.municipio} - ${round_egresses_egressos?.address?.estado}`}
            />
          </div>
          <div style={{ marginTop: 10 }}>
            <Label style={{ fontSize: 16, fontWeight: 'bold' }}>
              Comprovante de fiscalização
            </Label>
          </div>

          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              borderRadius: 10,
              flexDirection: 'row',
              alignItems: 'center',
              border: 'solid 1px #ccd5e0',
            }}
          >
            <div
              style={{
                height: !comprovante ? '150px' : undefined,
                border: 'solid 1px #ccd5e0',
                background: '#C8C8C8',
                borderRadius: '10px',
                width: '150px',
              }}
            >
              {/* {(!round_egresses_egressos?.comprovante?.url_file && !comprovante) && <Center><SpinnerLoading size={70} color="black" /></Center>} */}
              {comprovante && (
                <Comprovante
                  onClick={() =>
                    window.open(URL.createObjectURL(comprovante), 'blank')
                  }
                  src={URL.createObjectURL(comprovante)}
                  alt="Comprovante"
                />
              )}
              {!comprovante &&
                round_egresses_egressos?.comprovante?.url_file && (
                  <Comprovante
                    onClick={() =>
                      window.open(
                        round_egresses_egressos?.comprovante?.url_file,
                        'blank',
                      )
                    }
                    src={round_egresses_egressos?.comprovante?.url_file}
                    alt="Comprovante"
                  />
                )}
            </div>
            <div>
              <InputFile htmlFor="formFile">
                Clique aqui para anexar o comprovante
                <input
                  type="file"
                  id="formFile"
                  style={{ display: 'none' }}
                  // required
                  // {...register('comprovante', {
                  //   onChange: handleSetComprovante,
                  // })}
                  onChange={handleSetComprovante}
                />
              </InputFile>
              <MessageError isValid={formState.errors.comprovante}>
                Comprovante obrigatório.
              </MessageError>
            </div>
            <div style={{ color: '#ccd5e0', marginRight: 20 }}>
              {format(new Date(data), 'dd/MM/yyyy')}
            </div>
          </div>

          <div style={{ marginTop: 10 }}>
            <Label style={{ fontSize: 16, fontWeight: 'bold' }}>
              Selecione uma das opções abaixo *
            </Label>
            <WrapperSelect>
              <select
                {...register('ocorrencia_id', { required: true })}
                className="form-select"
                required
                onChange={handleChangeOcorrencia}
              >
                <option value="">Escolha</option>
                {ocurrences?.map(item => (
                  <option key={item.value} value={item.value}>
                    {item.label}
                  </option>
                ))}
              </select>
              {ocorrenciaError && (
                <span style={{ color: '#F66064' }}>{ocorrenciaError}</span>
              )}
            </WrapperSelect>
          </div>
          <div className="row" style={{ marginTop: 10 }}>
            <div className="col-sm">
              <Label style={{ fontSize: 16, fontWeight: 'bold' }}>
                Selecione a data da execução da fiscalização
              </Label>

              <DatePicker
                monthLabels={[
                  'Janeiro',
                  'Fevereiro',
                  'Março',
                  'Abril',
                  'Maio',
                  'Junho',
                  'Julho',
                  'Agosto',
                  'Setembro',
                  'Outubro',
                  'Novembro',
                  'Dezembro',
                ]}
                className="form-select"
                dayLabels={['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb']}
                // {...register('data_ocorrencia', { required: true })}
                // required
                name="data_ocorrencia"
                register={register}
                onChange={(v, f) => handleChange(v, f)}
                showClearButton={false}
                value={dataExec}
              />
              {/* <Input
                style={{ display: 'none' }}
                {...register('data_ocorrencia', { required: true })}
                required
                value={dataExec}
              /> */}
              {dataExecError && (
                <span style={{ color: '#F66064' }}>{dataExecError}</span>
              )}
              <div className="invalid-feedback">Campo obrigatório</div>
            </div>
            <div className="col">
              <Label style={{ fontSize: 16, fontWeight: 'bold' }}>
                Horário da execução da fiscalização
              </Label>
              <Input
                {...register('hora_ocorrencia', { required: true })}
                required
                placeholder="Insira o horário de início do evento"
                type="time"
                // value={timeExec}
                // {...register('hora_ocorrencia', { required: true })}
                value={timeExec}
                onChange={e => {
                  setTimeExec(e.target.value);
                }}
              />
              {timeExecError && (
                <span style={{ color: '#F66064' }}>{timeExecError}</span>
              )}
            </div>
          </div>
          <div style={{ marginTop: 10 }} />
          <div style={{ marginTop: 10 }}>
            <Label style={{ fontSize: 16, fontWeight: 'bold' }}>
              Observações
            </Label>
            <Textarea
              value={observacao}
              onChange={e => setObservacao(e.target.value)}
              rows={5}
              placeholder="Digite o problema que impediu de realizar a fiscalização"
              className="form-control"
            />
          </div>
        </ModalBody>
        <ModalFooter className="d-flex justify-content-between">
          <Button
            style={{ background: 'rgba(201, 19, 19, 0.6)' }}
            color="danger"
            onClick={toggle}
          >
            Cancelar
          </Button>{' '}
          <WrapperButton>
            <Button
              style={{ background: 'rgb(104, 153, 109)' }}
              disabled={load}
              className="btn btn-success"
              onClick={onSubmit}
            >
              {load && <SpinnerLoading />}
              {!load && 'Finalizar'}
            </Button>
          </WrapperButton>
        </ModalFooter>
      </Form>
      <ModalImage
        image={round_egresses_egressos?.egresso?.avatar?.url_file}
        isOpen={isOpenImage}
        handleIsOpen={handleOpenImage}
      />
    </Modal>
  );
}

type PropsMessageError = {
  isValid: boolean;
};

const MessageError = styled.p<PropsMessageError>`
  display: ${props => (props.isValid ? '' : 'none')};
  font-size: 12px;
  color: red;
  position: absolute;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 30px;
`;

const WrapperButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const WrapperSelect = styled.div`
  select {
    width: 100%;
    height: 40px;
    font-size: 18px;
    color: black;
  }
`;

const InputFile = styled.label`
  height: 60px;
  width: 100%;
  padding: 10px;
  border-radius: 8px;
  background: #c8c8c8;
  /* position: relative; */

  display: flex;
  justify-content: center;
  align-items: center;

  cursor: pointer;

  input {
    font-size: 20px;
    font-weight: 400;
    line-height: 23px;
  }
`;

const Textarea = styled.textarea`
  min-height: 150px;
  width: 100%;
  padding: 10px;
`;

const Comprovante = styled.img`
  width: 150px;
  height: 150px;
  border-radius: 10px;
  cursor: pointer;
`;
