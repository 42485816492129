import React from 'react';
import { BrowserRouter, useHistory } from "react-router-dom";
import { QueryClientProvider } from 'react-query';
// import Footer from "./components/Footer/Footer";
import Main from './components/Main/Main';
// import NavBar from "./components/NavBar/NavBar";
// import SecondaryHeader from "./components/SecondaryHeader/SecondaryHeader";
import { queryClient } from './global/queryClient';

import AppProvider from './hooks';

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <AppProvider>
          <div className="app" style={{ minHeight: "100vh", position: "relative" }}>
            <Main />
          </div>
        </AppProvider>
      </BrowserRouter>
    </QueryClientProvider>
  );
}

export default App;
