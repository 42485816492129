import React, { useEffect, useState } from 'react';
import api from '../../../../services/api';

export function useGetEgressoIndicadores_gerais_dos_egressos() {
  const [indicadores_gerais_dos_egressos, set_data_set_turma] = useState<any>(null);

  async function handle_indicadores_gerais_dos_egressos(year?: string) {
    const { data } = await api.get(`/reports/indicadores_gerais_dos_egressos`, { params: { year } });
    set_data_set_turma(data);
  }

  useEffect(() => {
    handle_indicadores_gerais_dos_egressos();
  }, []);

  return { indicadores_gerais_dos_egressos, handle_indicadores_gerais_dos_egressos };
}
