/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import api from '../../../../services/api';

export function useGetRoundsPendentes() {
  const [roundsPendentes, setRoundsPendentes] = useState<any | null>(null);

  async function handleSetRoundsPendentes(page = 1) {
    try {
      const { data } = await api.get('/rounds', {
        params: {
          em_homologacao: "T", homologado: "F", page, perPage: 15,
        },
      });
      if (data.meta.last_page < page) {
        handleSetRoundsPendentes(data.meta.last_page);
      } else {
        setRoundsPendentes(data);
      }
    } catch (error) {
      // @ts-ignore
      throw new Error(error.response.data.message);
    }
  }
  useEffect(() => {
    handleSetRoundsPendentes();
  }, []);
  return { roundsPendentes, handleSetRoundsPendentes };
}
