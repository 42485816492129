/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import { SectionHeader } from '../../../components/SectionHeader';
import { useGetAllRounds } from './hooks/useGetAllRounds';
import { AbaFilterButtons, AbaFilterContent } from '../../../components/develop-nilton-components/AbaFiltro';
import { TableTodos } from './components/TableTodos';
import { useGetRoundsPendentes } from './hooks/useGetRoundsPendentes';
import { useGetRoundsConcluidos } from './hooks/useGetRoundsConcluidos';
import { TablePendente } from './components/TablePendente';
import { TableConcluidos } from './components/TableConcluidos';

export function HomologarRondaAdm() {
  const [tab, setTab] = useState(1);
  const { rounds, handleSetUsers } = useGetAllRounds();
  const { handleSetRoundsPendentes, roundsPendentes } = useGetRoundsPendentes();
  const { roundsConcluidos, handleSetRoundsConcluidos } = useGetRoundsConcluidos();

  return (
    <div style={{ width: "100%" }}>
      <SectionHeader subtitle="Homologar Ronda" />

      <AbaFilterButtons
        buttons={["Geral", "Pendentes", "Concluídos"]}
        useStateTab={[tab, setTab]}
      />
      <div>
        <AbaFilterContent
          tab={tab}
          components={[
            <TableTodos
              rounds={rounds}
              handleUpdate={handleSetUsers}
            />,
            <TablePendente
              rounds={roundsPendentes}
              handleUpdate={handleSetRoundsPendentes}
            />,
            <TableConcluidos
              rounds={roundsConcluidos}
              handleUpdate={handleSetRoundsConcluidos}
            />,
          ]}
        />
      </div>
    </div>
  );
}
