import React, { useEffect, useState } from 'react';
import api from '../../../services/api';

export function useGetSearchEgresses() {
  const [egresses, setEgresses] = useState<any>(null);
  const [load, setLoad] = useState(false);

  async function handleSetOptionsEgresses(page = 1, search?: string) {
    setLoad(true);

    const { data } = await api.get("/egresses", {
      params: {
        page,
        habilidadeOrNameOrAreaOrBairroOrLocalidadeOrMunicipio: search,
      },
    });

    setEgresses(data);

    setLoad(false);
  }

  useEffect(() => {
    handleSetOptionsEgresses();
  }, []);
  return { handleSetOptionsEgresses, egresses, load };
}
