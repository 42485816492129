import React, { useEffect, useState } from 'react';
import api from '../../../../services/api';

export function useGetProjetosInfraestrutura_utilizada_por_projeto_no_ano() {
  const [infraestrutura_utilizada_por_projeto_no_ano, set_media_alunos_por_turma_no_ano] = useState<any>(null);

  async function handleInfraestrutura_utilizada_por_projeto_no_ano(year?:string) {
    const { data } = await api.get('/reports/infraestrutura_utilizada_por_projeto_no_ano', {
      params: {
        year,
      },
    });
    set_media_alunos_por_turma_no_ano(data);
  }

  useEffect(() => {
    handleInfraestrutura_utilizada_por_projeto_no_ano();
  }, []);
  return { infraestrutura_utilizada_por_projeto_no_ano, handleInfraestrutura_utilizada_por_projeto_no_ano };
}
