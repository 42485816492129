import React from 'react';
import styled from 'styled-components';
import { TablePadrão, Td, Tr } from '../../../../components/develop-nilton-components/TablePadrão';
import { Pagination } from '../../../../components/Pagination';
import { SpinnerLoading } from '../../../../components/SpinnerLoading';
import { TypeTableGeral } from '../types';
import { TbodyConcluidos } from './TbodyConcluidos';

export function TableConcluidos({ egresses, handleSetEgresses, load }:TypeTableGeral) {
  const headWidth = ["5%", "55%", "40%"];
  const thead = ["#", "Nome", "Opções"];

  return (
    <div>
      <TablePadrão thead={thead} headWidth={headWidth}>
        {load && (
        <Tr>
          <Td width="100%">
            <div className="d-flex justify-content align-items" data-testid="load">
              <SpinnerLoading />
            </div>
          </Td>
        </Tr>
        )}
        {egresses?.data.map((item, i) => (
          <TbodyConcluidos
            egresse={item}
            key={item.id}
            index={i + 1}
            headWidth={headWidth}
          />
        ))}
      </TablePadrão>
      {egresses && (
      <Pagination
        meta={egresses?.meta}
        setPage={handleSetEgresses}
      />
      )}
    </div>
  );
}
