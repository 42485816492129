import React, { useState } from 'react';
// @ts-ignore
import { CKEditor } from '@ckeditor/ckeditor5-react';
// @ts-ignore
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import api from '../../../../services/api';
import { IFatores } from '../type';
import { SpinnerLoading } from '../../../../components/SpinnerLoading';

export function Observacao({ getValues }:IFatores) {
  const history = useHistory();
  const [text, setText] = useState(getValues().observacoes);
  const [load, setLoad] = useState(false);

  const handleSubmit = async () => {
    setLoad(true);
    try {
      if (text !== "") {
        await api.put(`/psicopedagogue/${getValues().id}`, {
          observacoes: text,
          finalizado: "T",
          obdece_comandos_primeira_vez: Number(getValues().obdece_comandos_primeira_vez),
          consegue_expressar_pensamentos: Number(getValues().consegue_expressar_pensamentos),
          dificuldade_aprendizagem: Number(getValues().dificuldade_aprendizagem),
          dificuldade_adaptacao: Number(getValues().dificuldade_adaptacao),
          irrita_facilmente: Number(getValues().irrita_facilmente),
          reclama_muito: Number(getValues().reclama_muito),
          agressivo: Number(getValues().agressivo),
          preocupa_apresentar_bom_desempenho: Number(getValues().preocupa_apresentar_bom_desempenho),
          voz_modulada_ritmo_adequado: Number(getValues().voz_modulada_ritmo_adequado),
          preocupacao_familiar_especifica: Number(getValues().preocupacao_familiar_especifica),
          cooperador: Number(getValues().cooperador),
          satisfatoriamente_sociavel: Number(getValues().satisfatoriamente_sociavel),
          disciplinado: Number(getValues().disciplinado),
          dificuldade_memorizacao_concentracao: Number(getValues().dificuldade_memorizacao_concentracao),
        });
      } else {
        await api.put(`/psicopedagogue/${getValues().id}`, {
          finalizado: "T",
          obdece_comandos_primeira_vez: Number(getValues().obdece_comandos_primeira_vez),
          consegue_expressar_pensamentos: Number(getValues().consegue_expressar_pensamentos),
          dificuldade_aprendizagem: Number(getValues().dificuldade_aprendizagem),
          dificuldade_adaptacao: Number(getValues().dificuldade_adaptacao),
          irrita_facilmente: Number(getValues().irrita_facilmente),
          reclama_muito: Number(getValues().reclama_muito),
          agressivo: Number(getValues().agressivo),
          preocupa_apresentar_bom_desempenho: Number(getValues().preocupa_apresentar_bom_desempenho),
          voz_modulada_ritmo_adequado: Number(getValues().voz_modulada_ritmo_adequado),
          preocupacao_familiar_especifica: Number(getValues().preocupacao_familiar_especifica),
          cooperador: Number(getValues().cooperador),
          satisfatoriamente_sociavel: Number(getValues().satisfatoriamente_sociavel),
          disciplinado: Number(getValues().disciplinado),
        });
      }
      history.push('/acompanhamento');
      window.scrollTo({ top: 0 });
    } catch (error) {
      // @ts-ignore
      toast.error(error.response.data.message);
    }
    setLoad(false);
  };

  return (
    <div className="w-75">
      <CKEditor
        editor={ClassicEditor}
        data={text || ""}
        // onReady={(editor: any) => {
        //   // You can store the "editor" and use when it is needed.
        //   console.log(editor);
        // }}
        onChange={(event: any, editor: { getData: () => any; }) => {
          const data = editor.getData();
          setText(data);
        }}
        // onBlur={(event: any, editor: any) => {
        //   console.log('Blur.', editor);
        // }}
        // onFocus={(event: any, editor: any) => {
        //   console.log('Focus.', editor);
        // }}
      />
      <div className="d-flex justify-content-center mt-5 mb-5">
        {load ? (
          <button
            className="btn btn-primary btn-lg"
          >
            <SpinnerLoading />
          </button>
        ) : (
          <button
            className="btn btn-primary btn-lg"
            onClick={handleSubmit}
          >
            Finalizar
          </button>
        )}
      </div>
    </div>
  );
}
