import React, { useEffect, useState } from 'react';
import {
  Modal, ModalBody, ModalHeader, Button, ModalFooter, Form, FormGroup, Label, Input, Row, Col,
} from 'reactstrap';
import {
  format,
} from 'date-fns';
import { toast } from 'react-toastify';
import api from '../../../../services/api';
import { TbodyAlreadyAddsInTheRounds } from './TbodyAlreadyAddsInTheRounds';
import { TablePadrão } from '../../../../components/develop-nilton-components/TablePadrão';
import { SpinnerLoading } from '../../../../components/SpinnerLoading';
import { SubmitResponse } from '../../../../components/SubmitResponse';
import { TbodyToBeAddInTheRounds } from './TbodyToBeAddInTheRounds';

type AddEgressInRondasModalParams = {
  useStateIsOpen: [boolean, (boolean: boolean) => void];
  roundsEgresses: any
  setRoundEgresses?(value: any): void
  egresseState: any
  handleUpdate(): Promise<void>
  handleUpdateEgresses(): Promise<void>
  useStateCheckboxAll: [any, React.Dispatch<any>]
}

export function AddEgressInRondasModal(props: AddEgressInRondasModalParams) {
  const {
    useStateIsOpen,
    roundsEgresses,
    setRoundEgresses,
    egresseState,
    handleUpdate,
    handleUpdateEgresses,
    useStateCheckboxAll,
  } = props;
  const headWidth = ["70%", "30%"];
  const [checkboxAll, setCheckboxAll] = useStateCheckboxAll;
  const [isOpen, setIsOpen] = useStateIsOpen;
  const isDisableAddEgresses = checkboxAll?.find((item:any) => item.checked === true);
  const data = format((new Date(egresseState.created_at)), 'dd/MM/yyyy');

  const [isLoad, setIsLoad] = useState(false);

  // const { handleAddEgresses } = useCheckboxAll(checkboxAll, handleUpdate, setLoad, setTab);

  async function handleAddEgresses() {
    setIsLoad(true);
    await Promise.all(
      checkboxAll.map(async ({ round_id, egress_id, checked }: any) => {
        if (checked) {
          try {
            await api.post('/rounds_egresses', { round_id, egress_id });
            setCheckboxAll(
              roundsEgresses?.filter((item:any) => (item.egress_id != egress_id)),
            );
          } catch (error) {
            // @ts-ignore
            toast(error.response.data.message, { type: "error" });
          }
        }
      }),
    );
    await handleUpdate();
    toast("Egresso(s) adicionado(s) com sucesso", { type: "success" });
    setIsLoad(false);
  }

  useEffect(() => {
    /* setCheckboxAll(
      roundsEgresses?.map((item:any) => ({
        id: item.id,
        checked: false,
      })),
    ); */
  }, [roundsEgresses, setCheckboxAll]);

  const toggle = () => {
    setIsOpen(!isOpen);
    handleUpdate();
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle} size="lg">
      <ModalHeader toggle={toggle}>Confirmação de Rondas</ModalHeader>

      <Form>
        <ModalBody>
          <Row>
            <Col md={6}>
              <FormGroup>
                <Label for="referencialRonda" style={{ fontWeight: 'bold' }}>
                  Referencial da Ronda
                </Label>
                <Input
                  id="referencialRondaId"
                  name="referencialRonda"
                  placeholder={egresseState?.title}
                  type="text"
                  disabled
                />
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label for="dataCriacao" style={{ fontWeight: 'bold' }}>
                  Data de Criação
                </Label>
                <Input
                  id="dataCriacaoId"
                  name="data_criacao"
                  placeholder={data}
                  type="text"
                  disabled
                />
              </FormGroup>
            </Col>
          </Row>

          {checkboxAll?.filter((item:any) => item.checked === true).length > 0 && (
          <Row>
            <Col md={12}>
              {/* <Label style={{ marginTop: 5 }}>
                Egressos a serem adicionados
              </Label> */}
              <div style={{
                display: "flex", marginBottom: 20, width: '100%', justifyContent: "space-between", alignItems: 'center', fontWeight: 'bold',
              }}
              >
                <div className="horizontal" />
                <h1 style={{
                  fontSize: 16, width: "16%", fontWeight: 'bold', marginBottom: 0, marginLeft: 10,
                }}
                >
                  Novos Egressos
                </h1>
                <div className="horizontal" />
              </div>
              <div>
                <TablePadrão
                  thead={[]}
                  headWidth={headWidth}
                >
                  {checkboxAll?.filter((item:any) => item.checked === true).map((item: any, i: React.Key | null | undefined) => (
                    <TbodyToBeAddInTheRounds
                      key={i}
                      itemEgresso={item}
                      headWidth={headWidth}
                      checkboxAll={checkboxAll}
                      setCheckboxAll={setCheckboxAll}
                      isLoad={isLoad}
                    />
                  ))}
                </TablePadrão>
              </div>
            </Col>
          </Row>
          )}

          <Row>
            <Col md={12}>
              {/* <Label style={{ marginTop: 5 }}>
                Egressos adicionados
              </Label> */}

              <div style={{
                display: "flex", marginBottom: 20, width: '100%', justifyContent: "space-between", alignItems: 'center', fontWeight: 'bold',
              }}
              >
                <div className="horizontal" />
                <h1 style={{
                  fontSize: 16, width: "20%", fontWeight: 'bold', marginBottom: 0, marginLeft: 10,
                }}
                >
                  Egressos Inseridos
                </h1>
                <div className="horizontal" />
              </div>

              <div>
                <TablePadrão
                  thead={[]}
                  headWidth={headWidth}
                >
                  {roundsEgresses?.map((item: any, i: React.Key | null | undefined) => (
                    <TbodyAlreadyAddsInTheRounds
                      key={i}
                      itemEgresso={item}
                      headWidth={headWidth}
                      roundsEgresses={roundsEgresses}
                      setRoundEgresses={setRoundEgresses}
                      handleUpdate={handleUpdate}
                      handleUpdateEgresses={handleUpdateEgresses}
                      isLoad={isLoad}
                    />
                  ))}
                </TablePadrão>

                {roundsEgresses && roundsEgresses.length === 0
        && <SubmitResponse submitResponse={{ message: "Não há egressos na ronda.", status: "danger", title: "Aviso" }} />}
              </div>
            </Col>
          </Row>

        </ModalBody>
        <ModalFooter className="d-flex justify-content-between">
          <Button style={{ background: 'rgba(201, 19, 19, 0.6)' }} className="btn btn-danger" onClick={toggle}>
            Cancelar
          </Button>
          <Button style={{ background: 'rgba(4, 85, 12, 0.6)' }} className="btn btn-success" onClick={() => handleAddEgresses()} disabled={!isDisableAddEgresses || isLoad}>
            {isLoad && <SpinnerLoading />}
            {!isLoad && (
              'Finalizar'
            )}
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
}
