import React, { useEffect, useState } from 'react';
import api from '../../../../services/api';

export function useGetEgressoCursos_por_mes_no_ano() {
  const [cursos_por_mes_no_ano, set_data_set_projeto] = useState<any>(null);

  async function handleSet_egresso_cursos_por_mes_no_ano(year?: string) {
    const { data } = await api.get(`/reports/cursos_por_mes_no_ano`, { params: { year } });
    set_data_set_projeto(data);
  }

  useEffect(() => {
    handleSet_egresso_cursos_por_mes_no_ano();
  }, []);

  return { cursos_por_mes_no_ano, handleSet_egresso_cursos_por_mes_no_ano };
}
