import React, { useState } from 'react';

type IPropsUseModal = {
  isOpenModal: boolean
  handleOpenModal(): void
}

export function useModal():IPropsUseModal {
  const [isOpenModal, setIsOpenModal] = useState(false);
  function handleOpenModal() {
    setIsOpenModal(!isOpenModal);
  }
  return { isOpenModal, handleOpenModal };
}
