/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { eachYearOfInterval, format } from 'date-fns';
import { Chart } from 'react-chartjs-2';
import { SpinnerLoading } from '../../../../components/SpinnerLoading';
import { useGet_data_set_comportamento } from '../hooks/useGet_data_set_comportamento';
import { useGet_data_set_concentracao } from '../hooks/useGet_data_set_concentracao';
import { useGet_data_set_observaçao } from '../hooks/useGet_data_set_observacao';
import { useGet_data_set_queixa } from '../hooks/useGet_data_set_queixa';

export function GraficoTrimestral() {
  const { data_set_comportamento, handle_onChange_comportamento } = useGet_data_set_comportamento();
  const { data_set_concentracao, handle_onChange_concentracao } = useGet_data_set_concentracao();
  const { data_set_queixa, handle_onChange_queixa } = useGet_data_set_queixa();
  const { data_set_observacao, handle_onChange_observacao } = useGet_data_set_observaçao();
  const load = data_set_comportamento && data_set_concentracao && data_set_queixa && data_set_observacao;
  const year = format(new Date(), "yyyy");

  const defaultValueTrimestral = () => {
    if (Number(format(new Date(), "MM")) <= 3) {
      return "01-03";
    }
    if (Number(format(new Date(), "MM")) <= 6) {
      return "03-06";
    }
    if (Number(format(new Date(), "MM")) <= 9) {
      return "06-09";
    }
    if (Number(format(new Date(), "MM")) <= 12) {
      return "09-12";
    }
    return "09-12";
  };

  const trimestralOptions = [
    { value: "01-03", label: "1" },
    { value: "03-06", label: "2" },
    { value: "06-09", label: "3" },
    { value: "09-12", label: "4" },
  ];

  return (
    <div className="mb-5">
      {!load ? (
        <div className="d-flex justify-content-center">
          <SpinnerLoading />
        </div>
      ) : (
        <div>
          <div style={{ width: "100%" }}>
            <div className="d-flex m-3 justify-content-center">
              <h3 className="mr-2">Comportamento do trimestre:</h3>
              <select
                defaultValue={defaultValueTrimestral()}
                onChange={(e) => handle_onChange_comportamento(year, e.target.value)}
              >
                {trimestralOptions.map(({ label, value }, i) => (
                  <option value={value} key={i}>{label}</option>
                ))}
              </select>
            </div>
            <Chart
              data={data_set_comportamento}
              type="line"
              options={{
                elements: { point: { pointStyle: "rectRot" } },
                plugins: {
                  legend: {
                    position: 'bottom',
                    labels: { usePointStyle: true, pointStyle: "line" },
                  },
                },
                scales: {
                  y: {
                    suggestedMin: 0,
                    suggestedMax: 10,
                  },
                },
              }}
            />
          </div>
          <div style={{ width: "100%" }}>
            <div className="d-flex m-3 justify-content-center">
              <h3 className="mr-2">Concentração do trimestre:</h3>
              <select
                defaultValue={defaultValueTrimestral()}
                onChange={(e) => handle_onChange_concentracao(year, e.target.value)}
              >
                {trimestralOptions.map(({ label, value }, i) => (
                  <option value={value} key={i}>{label}</option>
                ))}
              </select>
            </div>
            <Chart
              data={data_set_concentracao}
              type="line"
              options={{
                elements: { point: { pointStyle: "rectRot" } },
                plugins: {
                  legend: {
                    position: 'bottom',
                    labels: { usePointStyle: true, pointStyle: "line" },
                  },
                },
                scales: {
                  y: {
                    suggestedMin: 0,
                    suggestedMax: 10,
                  },
                },
              }}
            />
          </div>
          <div style={{ width: "100%" }}>
            <div className="d-flex m-3 justify-content-center">
              <h3 className="mr-2">Queixa do trimestre:</h3>
              <select
                defaultValue={defaultValueTrimestral()}
                onChange={(e) => handle_onChange_queixa(year, e.target.value)}
              >
                {trimestralOptions.map(({ label, value }, i) => (
                  <option value={value} key={i}>{label}</option>
                ))}
              </select>
            </div>
            <Chart
              data={data_set_queixa}
              type="line"
              options={{
                elements: { point: { pointStyle: "rectRot" } },
                plugins: {
                  legend: {
                    display: false,
                    position: 'bottom',
                    labels: { pointStyle: "triangle" },
                  },
                },
                scales: {
                  y: {
                    suggestedMin: 0,
                    suggestedMax: 10,
                  },
                },
              }}
            />
          </div>
          <div style={{ width: "100%" }}>
            <div className="d-flex m-3 justify-content-center">
              <h3 className="mr-2">Observação do trimestre:</h3>
              <select
                defaultValue={defaultValueTrimestral()}
                onChange={(e) => handle_onChange_observacao(year, e.target.value)}
              >
                {trimestralOptions.map(({ label, value }, i) => (
                  <option value={value} key={i}>{label}</option>
                ))}
              </select>
            </div>
            <Chart
              data={data_set_observacao}
              type="line"
              options={{
                elements: { point: { pointStyle: "rectRot" } },
                plugins: {
                  legend: {
                    display: false,
                    position: 'bottom',
                    labels: { pointStyle: "triangle" },
                  },
                },
                scales: {
                  y: {
                    suggestedMin: 0,
                    suggestedMax: 10,
                  },
                },
              }}
            />
          </div>
        </div>
      )}
    </div>
  );
}
