import React from 'react';
import { TablePadrão, Td, Tr } from '../../../../components/develop-nilton-components/TablePadrão';
import { Pagination } from '../../../../components/Pagination';
import { SpinnerLoading } from '../../../../components/SpinnerLoading';
import { ITableGeral } from '../type';
import { TbodyHistorico } from './TbodyHistorico';

export function TableHistoricoAS({ egresses, load, handleSetEgresses }: ITableGeral) {
  const headWidth = ["5%", "50%", "45%"];
  const thead = ["#", "Nome do egresso", "Opções"];
  return (
    <div>
      <TablePadrão
        headWidth={headWidth}
        thead={thead}
      >
        {load && (
          <Tr>
            <Td width="100%">
              <SpinnerLoading />
            </Td>
          </Tr>
        )}
        {egresses?.data.map((egress, i) => (
          <TbodyHistorico
            egress={egress}
            headWidth={headWidth}
            index={i + 1}
          />
        ))}
        {egresses?.meta && (
          <Tr>
            <Td width="100%">
              <Pagination meta={egresses.meta} setPage={handleSetEgresses} load={load} />
            </Td>
          </Tr>
        )}
      </TablePadrão>
    </div>
  );
}
