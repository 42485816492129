import React, { useState } from 'react';
import { Chart } from 'react-chartjs-2';
import { SubmitErrorHandler, SubmitHandler, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import { ArrayFormValues, FormAcompanhamentos } from '../../../../components/develop-nilton-components/FormAcompanhamentos';
import { SpinnerLoading } from '../../../../components/SpinnerLoading';
import api from '../../../../services/api';
import { TypeAbasContainerComponents, TypeComportamento } from '../types';

export function Comportamento({ setTab, getValues, setValue }: TypeAbasContainerComponents) {
  const [muda_humor_facilmente, set_muda_humor_facilmente] = useState(getValues()?.muda_humor_facilmente || 0);
  const [fica_nervoso_constantemente, set_fica_nervoso_constantemente] = useState(getValues()?.fica_nervoso_constantemente || 0);
  const [fica_triste_euforico, set_fica_triste_euforico] = useState(getValues()?.fica_triste_euforico || 0);
  const [fica_raiva_hostil, set_fica_raiva_hostil] = useState(getValues()?.fica_raiva_hostil || 0);
  const [pensamentos_suicidas, set_pensamentos_suicidas] = useState(getValues()?.pensamentos_suicidas || 0);

  const dataset = {
    labels: ['Mudança de humor', 'Agressividade', 'Comp. bipolar', 'Raiva ou hostilidade', 'Automultilação ou suícidio'],
    datasets: [{
      label: '',
      data: [muda_humor_facilmente, fica_nervoso_constantemente, fica_triste_euforico, fica_raiva_hostil, pensamentos_suicidas],
      borderColor: '#FFA500',
      backgroundColor: '#FFA500',
    }],
  };

  const [load, setLoad] = useState(false);

  const { register, handleSubmit } = useForm({ defaultValues: getValues() });

  const onSubmit = async (data:any) => {
    setLoad(true);
    try {
      await api.put(`/psychologist/${getValues().id}`, data);
      setTab(2);
    } catch (error) {
      // @ts-ignore
      toast.error(error.response.data.message);
    }
    setLoad(false);
  };

  const onSubmitError = (errors:any) => {
    const error = Object.keys(errors);
    for (let i = 0; i < error.length; i + 1) {
      toast.error(errors[error[i] as keyof TypeComportamento]?.message);
      break;
    }
  };

  const formItems:ArrayFormValues["formValues"] = [
    {
      label: "1 - Muda de humor facilmente",
      name: "muda_humor_facilmente",
      type: "number",
      onChange: (e:any) => {
        set_muda_humor_facilmente(e.target.value);
        setValue("muda_humor_facilmente", e.target.value);
      },
    },
    {
      label: "2 - Fica nervoso, irritado ou agressivo constantemente?",
      name: "fica_nervoso_constantemente",
      type: "number",
      onChange: (e:any) => {
        set_fica_nervoso_constantemente(e.target.value);
        setValue("fica_nervoso_constantemente", e.target.value);
      },
    },
    {
      label: "3 - Por alguma razão específica, fica triste ou eufórico?",
      name: "fica_triste_euforico",
      type: "number",
      onChange: (e:any) => {
        set_fica_triste_euforico(e.target.value);
        setValue("fica_triste_euforico", e.target.value);
      },
    },
    {
      label: "4 - Por alguma razão, fica com raiva e/ou hostil?",
      name: "fica_raiva_hostil",
      type: "number",
      onChange: (e:any) => {
        set_fica_raiva_hostil(e.target.value);
        setValue("fica_raiva_hostil", e.target.value);
      },
    },
    {
      label: "5 - Tem pensamentos suicidas, de autofragelar ou machucar terceiros?",
      name: "pensamentos_suicidas",
      type: "number",
      onChange: (e:any) => {
        set_pensamentos_suicidas(e.target.value);
        setValue("pensamentos_suicidas", e.target.value);
      },
    },
  ];

  return (
    <div>
      <div style={{ width: '100%' }}>
        <Chart
          data={dataset}
          type="bar"
          options={{
            plugins: {
              legend: { display: false },
            },
            scales: {
              y: {
                suggestedMin: 0,
                suggestedMax: 10,
              },
            },
          }}
        />
      </div>

      <p className="text-primary h5 border-top pt-2 mt-2" style={{ width: '100%' }}>Preencher novo acompanhamento</p>
      <Form onSubmit={handleSubmit(onSubmit, onSubmitError)}>
        <FormAcompanhamentos
          formValues={formItems}
          register={register}
        />
        <div className="d-flex justify-content-center m-5">
          {load ? (
            <button className="btn btn-primary btn-lg" type="button">
              <SpinnerLoading />
            </button>
          ) : (
            <button type="submit" className="btn btn-primary btn-lg">
              Avançar
            </button>
          )}
        </div>
      </Form>
    </div>
  );
}

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 50px;
  margin-top: 20px;
`;

const Input = styled.div`
  display: flex;
  flex-direction: column;
  cursor: pointer;
  label,input {
    cursor: pointer;
  }
`;

const InputContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
`;

const ContainerButton = styled.div`
  display: flex;
  justify-content: center;
`;
