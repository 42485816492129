import React from 'react';
import {
  Switch, Route,
  BrowserRouter as Router, Redirect,
} from 'react-router-dom';

import { MainContainer } from './Main.style';

import Footer from "../Footer/Footer";
import NavBar from "../NavBar/NavBar";

import { Login } from '../../pages/Login';

import { useAuth } from '../../hooks/auth';
import { SelecionarPerfil } from '../../pages/SelecionarPerfil';
import Routes from './route';

// @ts-ignore
const PrivateRoute = ({ component: Component, ...rest }) => {
  const { user, signed, profile } = useAuth();

  return (
    <Route
      {...rest}
      render={(props) => (
        signed ? (
          <div className="d-flex" style={{ width: '100%' }}>
            {
              user.profile.title === "MASTER" ? (
                profile ? <Component {...props} /> : <SelecionarPerfil />
              ) : <Component {...props} />
            }
          </div>
        ) : (
          <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
        )
      )}
    />
  );
};

const Main = () => {
  const { signed } = useAuth();

  return (
    <>
      {(signed && window.location.pathname != '/login') && <NavBar />}
      <MainContainer>
        <Router>
          <Switch>
            <Route path="/login" component={Login} />
            <PrivateRoute path="/" component={Routes} />
          </Switch>
        </Router>
      </MainContainer>
      {/* {(signed && window.location.pathname != '/login') && <Footer />} */}
    </>
  );
};

export default Main;
