import React, { useEffect, useState } from 'react';
import { useGet_relatorio_psicologico_indicadores } from './useGet_relatorio_psicologico_indicadores';

export function useGet_data_set_queixa() {
  const { handle_relatorio_psicologico_indicadores } = useGet_relatorio_psicologico_indicadores();
  const [data_set_queixa, set_data_set_queixa] = useState<any>(null);

  async function handle_onChange_queixa(year: string, trimestre?: string) {
    const queixa = await handle_relatorio_psicologico_indicadores(year, "queixa", trimestre);
    set_data_set_queixa(queixa);
  }
  useEffect(() => {
    handle_onChange_queixa("2022");
  }, []);
  return { data_set_queixa, handle_onChange_queixa };
}
