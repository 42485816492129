import React, { useState } from "react";
import { AiFillEye } from "react-icons/ai";
import ReactTooltip from "react-tooltip";
import styled from "styled-components";
import { Td, Tr } from '../../../../components/develop-nilton-components/TablePadrão';
import { ModalDetailsEgresse } from "../../../../components/develop-nilton-components/ModalDetailsEgresses";
import { IEgresses } from "../../../../types/IEgresses";

type PropsTbodyAdicionarEgressoNaRonda = {
  itemEgresso:any
  headWidth: string[]
  isCheckboxAll: any
  handleChecked(egress_id:number): void
  loadEgress: boolean
  useStateEgresse: [IEgresses | null, (egresse:IEgresses | null) => void]
  useStateIsOpen: [boolean, (boolean: boolean) => void]
}

export function TbodyGeralPageAddRoundsEgresses({
  itemEgresso, headWidth, isCheckboxAll, handleChecked, loadEgress, useStateEgresse, useStateIsOpen,
}: PropsTbodyAdicionarEgressoNaRonda) {
  const [isOpenModalDetailsEgresses, setIsOpenModalDetailsEgresses] = useState(false);
  const checked = isCheckboxAll?.find((item: any) => item.egress_id === itemEgresso.id)?.checked;

  const [egresse, setEgresse] = useStateEgresse;
  const [isWatchEgresse, setIsWatchEgresse] = useStateIsOpen;

  function handlesSetIsOpenModalDetailsEgresses() {
    setIsOpenModalDetailsEgresses(!isOpenModalDetailsEgresses);
  }

  return (
    <Tr onClick={(e) => {}/* handleChecked(Number(e.currentTarget.id)) */} id={itemEgresso.id}>
      <Td style={{ alignItems: "center" }} width={headWidth[0]}>
        <CheckBox
          type="checkbox"
          autoComplete="off"
          checked={(itemEgresso.inRound.length === 0 && itemEgresso.endereco.filter((addr: any) => addr.atual).length !== 0) ? checked : undefined}
          onChange={(e) => handleChecked(itemEgresso.id)}
          disabled={loadEgress || itemEgresso.inRound.length !== 0 || itemEgresso.endereco.filter((addr: any) => addr.atual).length === 0}
        />
      </Td>
      <Td width={headWidth[1]}>{itemEgresso.nome}</Td>
      <Td width={headWidth[2]}>
        <ReactTooltip />
        <div
          onClick={handlesSetIsOpenModalDetailsEgresses}
          data-tip="Visualizar dados do egresso"
        >
          <AiFillEye
            size={30}
            cursor="pointer"
          />
        </div>

        {isOpenModalDetailsEgresses
        && (
        <ModalDetailsEgresse
          isOpen={isOpenModalDetailsEgresses}
          handleIsOpen={handlesSetIsOpenModalDetailsEgresses}
          egresse={itemEgresso}
          useStateEgresse={[egresse, setEgresse]}
          useStateIsOpen={[isWatchEgresse, setIsWatchEgresse]}
        />
        )}
      </Td>
    </Tr>
  );
}

const CheckBox = styled.input`
  width: 15px;
  height: 15px;
`;
