/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { eachYearOfInterval, format } from 'date-fns';
import { Chart } from 'react-chartjs-2';
import { SpinnerLoading } from '../../../../components/SpinnerLoading';
import { useGet_data_set_comportamento } from '../hooks/useGet_data_set_comportamento';
import { useGet_data_set_concentracao } from '../hooks/useGet_data_set_concentracao';
import { useGet_data_set_observaçao } from '../hooks/useGet_data_set_observacao';
import { useGet_data_set_queixa } from '../hooks/useGet_data_set_queixa';

export function GraficoAnual() {
  const { data_set_comportamento, handle_onChange_comportamento } = useGet_data_set_comportamento();
  const { data_set_concentracao, handle_onChange_concentracao } = useGet_data_set_concentracao();
  const { data_set_queixa, handle_onChange_queixa } = useGet_data_set_queixa();
  const { data_set_observacao, handle_onChange_observacao } = useGet_data_set_observaçao();
  const load = data_set_comportamento && data_set_concentracao && data_set_queixa && data_set_observacao;
  const yearsOptions = eachYearOfInterval({ start: new Date(0), end: new Date() }).map((item) => format(item, 'yyyy')).reverse();

  return (
    <div className="mb-5">
      {!load ? (
        <div className="d-flex justify-content-center">
          <SpinnerLoading />
        </div>
      ) : (
        <div data-testid="graficos">
          <div style={{ width: "100%" }}>
            <div className="d-flex m-3 justify-content-center">
              <h3 className="mr-2">Comportamento do ano:</h3>
              <select onChange={(e) => handle_onChange_comportamento(e.target.value)}>
                {yearsOptions.map((year) => (
                  <option value={year} key={year}>{year}</option>
                ))}
              </select>
            </div>
            <Chart
              data={data_set_comportamento}
              type="line"
              options={{
                elements: { point: { pointStyle: "rectRot" } },
                plugins: {
                  legend: {
                    position: 'bottom',
                    labels: { usePointStyle: true, pointStyle: "line" },
                  },
                },
                scales: {
                  y: {
                    suggestedMin: 0,
                    suggestedMax: 10,
                  },
                },
              }}
            />
          </div>
          <div style={{ width: "100%" }}>
            <div className="d-flex m-3 justify-content-center">
              <h3 className="mr-2">Concentração do ano:</h3>
              <select onChange={(e) => handle_onChange_concentracao(e.target.value)}>
                {yearsOptions.map((year) => (
                  <option value={year} key={year}>{year}</option>
                ))}
              </select>
            </div>
            <Chart
              data={data_set_concentracao}
              type="line"
              options={{
                elements: { point: { pointStyle: "rectRot" } },
                plugins: {
                  legend: {
                    position: 'bottom',
                    labels: { usePointStyle: true, pointStyle: "line" },
                  },
                },
                scales: {
                  y: {
                    suggestedMin: 0,
                    suggestedMax: 10,
                  },
                },
              }}
            />
          </div>
          <div style={{ width: "100%" }}>
            <div className="d-flex m-3 justify-content-center">
              <h3 className="mr-2">Queixa do ano:</h3>
              <select onChange={(e) => handle_onChange_queixa(e.target.value)}>
                {yearsOptions.map((year) => (
                  <option value={year} key={year}>{year}</option>
                ))}
              </select>
            </div>
            <Chart
              data={data_set_queixa}
              type="line"
              options={{
                elements: { point: { pointStyle: "rectRot" } },
                plugins: {
                  legend: {
                    display: false,
                    position: 'bottom',
                    labels: { pointStyle: "triangle" },
                  },
                },
                scales: {
                  y: {
                    suggestedMin: 0,
                    suggestedMax: 10,
                  },
                },
              }}
            />
          </div>
          <div style={{ width: "100%" }}>
            <div className="d-flex m-3 justify-content-center">
              <h3 className="mr-2">Observação do ano:</h3>
              <select onChange={(e) => handle_onChange_observacao(e.target.value)}>
                {yearsOptions.map((year) => (
                  <option value={year} key={year}>{year}</option>
                ))}
              </select>
            </div>
            <Chart
              data={data_set_observacao}
              type="line"
              options={{
                elements: { point: { pointStyle: "rectRot" } },
                plugins: {
                  legend: {
                    display: false,
                    position: 'bottom',
                    labels: { pointStyle: "triangle" },
                  },
                },
                scales: {
                  y: {
                    suggestedMin: 0,
                    suggestedMax: 10,
                  },
                },
              }}
            />
          </div>
        </div>
      )}
    </div>
  );
}
