import React, { useEffect, useState } from 'react';
import { FaDownload } from 'react-icons/fa';
import { IconDetails } from '../../../../components/develop-nilton-components/IconDetails';
import { Td, Tr } from '../../../../components/develop-nilton-components/TablePadrão';
import api from '../../../../services/api';
import { useGetEgressesById } from '../hooks/useGetEgressesById';
import { ModalDetailsEgresseIntheRounds } from './ModalDetailsEgresseIntheRounds';
import { SpinnerLoading } from '../../../../components/SpinnerLoading';
import { ModalDetailsEgresse } from '../../../../components/develop-nilton-components/ModalDetailsEgresses';

type PropsTbodyPageEgressesInTheRounds = {
  headWidth: string[]
  item: any
  index: number
}

export function TbodyPageEgressesInTheRounds({ headWidth, item, index }:PropsTbodyPageEgressesInTheRounds) {
  const [isOpenModalOccurrence, setIsOpenModalOccurrence] = useState(false);

  const [load, setLoad] = useState(false);
  const [loadEgress, setLoadEgress] = useState(false);

  const [data, setData] = useState(null);

  function handleSetIsOpenModalOccurrece() {
    setIsOpenModalOccurrence(!isOpenModalOccurrence);
  }

  async function download(url:string, filename:string) {
    fetch(url).then((t) => t.blob().then((b) => {
      const a = document.createElement("a");
      a.href = URL.createObjectURL(b);
      a.setAttribute("download", filename);
      a.click();
      setLoad(false);
    }));
  }

  async function handleDownload(item:any) {
    setLoad(true);
    const { data } = await api.get(`/rounds_egresses/${item.id}/report`);
    download(data.url, `comprovante_ronda_${item.round_id}`);
  }

  async function getEgresseData() {
    setLoadEgress(true);
    const { data } = await api.get(`/egresses/${item?.egresso?.id}`);
    console.log(data);
    setData(data);
    setLoadEgress(false);
  }

  useEffect(() => {
    getEgresseData();
  }, [item]);

  return (
    <Tr>
      <Td width={headWidth[0]}>{index}</Td>
      <Td width={headWidth[1]}>{item?.egresso.nome}</Td>

      <Td width={headWidth[2]}>
        <div onClick={data && !loadEgress ? handleSetIsOpenModalOccurrece : () => {}}>
          {loadEgress ? <SpinnerLoading /> : (<IconDetails cursor tooltipMessage="Dados do egresso" />)}
        </div>
        {/* <ModalDetailsEgresseIntheRounds
          item={item}
          observacao={item?.observacao}
          ocorrencia={item?.ocorrencia}
          isOpen={isOpenModalOccurrence}
          handleIsOpen={handleSetIsOpenModalOccurrece}
        /> */}
        {data && (
          <ModalDetailsEgresse
            isOpen={isOpenModalOccurrence}
            handleIsOpen={handleSetIsOpenModalOccurrece}
            egresse={data}
          />
        )}
      </Td>
      <Td width={headWidth[3]}>
        {load ? <SpinnerLoading /> : (
          <div onClick={() => handleDownload(item)}>
            <FaDownload size={30} cursor="pointer" />
          </div>
        )}
      </Td>

    </Tr>
  );
}
