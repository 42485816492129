import { format } from 'date-fns';
import React, { useState } from 'react';
import ReactModal from 'react-modal';
import { TablePadrão, Td, Tr } from '../../../../components/develop-nilton-components/TablePadrão';
import { data } from '../types';

type IModalTurmas = {
  isOpen: boolean
  handleIsOpen(): void
  turmas: data
}

export function ModalTurmas({ isOpen, handleIsOpen, turmas }:IModalTurmas) {
  const headWidth = ["10%", "90%"];
  const [isOpenEgresses, setIsOpenEgresses] = useState(false);
  const [isOpenProject, setIsOpenProject] = useState(false);

  console.log(turmas.projetos);

  function handleSetIsOpenEgresses() {
    setIsOpenEgresses(!isOpenEgresses);
  }

  function handleSetProject() {
    setIsOpenProject(!isOpenProject);
  }
  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={handleIsOpen}
      ariaHideApp={false}
      style={{
        content: {
          width: "60%",
          margin: "0 auto",
        },
        overlay: {
          background: "rgba(0,0,0,.1)",
        },
      }}
    >
      <div>
        <h2 className="border-bottom">
          Turma:
          {" "}
          <span className="text-danger">{turmas.title}</span>
        </h2>
        <div>
          <p className="h5">
            <strong>Nome:</strong>
            {" "}
            {turmas.title}
          </p>

          <p className="h5">
            <strong>Início:</strong>
            {" "}
            {format(new Date(turmas.data_inicio), "dd/MM/yyyy")}
          </p>
          <p className="h5">
            <strong>Fim:</strong>
            {" "}
            {format(new Date(turmas.data_fim), "dd/MM/yyyy")}
          </p>
          {turmas?.projetos.length !== 0 && (
            <p className="h5">
              <strong>Projeto:</strong>
              {" "}
              <span
                className="text-primary"
                onClick={handleSetProject}
                style={{ cursor: "pointer" }}
              >
                (Ver projetos)
              </span>
              <ReactModal
                isOpen={isOpenProject}
                ariaHideApp={false}
                onRequestClose={handleSetProject}
                style={{
                  content: {
                    width: "70%",
                    margin: "0 auto",
                  },
                  overlay: {
                    background: "rgba(0,0,0,.1)",
                  },
                }}
              >
                <div>
                  <h2 className="border-bottom">
                    Projetos
                  </h2>
                  <TablePadrão thead={["#", "Nome"]} headWidth={headWidth}>
                    {turmas?.projetos.map((item, i) => (
                      <Tr key={i}>
                        <Td width={headWidth[0]}>{i + 1}</Td>
                        <Td width={headWidth[1]}>{item.projeto.nome}</Td>
                      </Tr>
                    ))}
                    {/* <Tr>
                  <Td width={headWidth[0]}>1</Td>
                  <Td width={headWidth[1]}>Nome</Td>
                </Tr> */}
                  </TablePadrão>
                </div>
              </ReactModal>
            </p>
          )}
          {turmas?.egressos.length !== 0 && (

          <p className="h5">
            <strong>Egressos:</strong>
            {" "}
            <span
              className="text-primary"
              onClick={handleSetIsOpenEgresses}
              style={{ cursor: "pointer" }}
            >
              (Ver egressos)

            </span>
            <ReactModal
              isOpen={isOpenEgresses}
              ariaHideApp={false}
              onRequestClose={handleSetIsOpenEgresses}
              style={{
                content: {
                  width: "70%",
                  margin: "0 auto",
                },
                overlay: {
                  background: "rgba(0,0,0,.1)",
                },
              }}
            >
              <div>
                <h2 className="border-bottom">
                  Turma:
                  {" "}
                  <span className="text-danger">{turmas.title}</span>
                </h2>
                <TablePadrão thead={["#", "Nome"]} headWidth={headWidth}>
                  {turmas?.egressos?.map((item, i) => (
                    <Tr key={i}>
                      <Td width={headWidth[0]}>{i + 1}</Td>
                      <Td width={headWidth[1]}>{item?.egress?.nome}</Td>
                    </Tr>
                  ))}
                </TablePadrão>
              </div>
            </ReactModal>
          </p>
          )}
        </div>
      </div>

    </ReactModal>
  );
}
