import styled from 'styled-components';

export const Loading = styled.div`
  .spinner{
    animation: rotate .6s linear infinite;
  }
  @keyframes rotate {
    to {
      transform: rotate(360deg);
    }
  }
`;
