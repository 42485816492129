/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import ReactTooltip from 'react-tooltip';
import { useModal } from '../../../../components/develop-nilton-components/hooks/useModal';

import { ButtonAddEgressos } from './ButtonAddEgressos';
import { ButtonEdit } from './ButtonEdit';
import { ButtonEditFigma } from './ButtonEditFigma';
import { ButtonHistoryDetailsFigma } from './ButtonHistoryDetailsFigma';
import { ButtonExcluir } from './ButtonExcluir';
import { ModalDeleteRonda } from './ModalDeleteRonda';
import { ModalHistoryComprovanteFigma } from './ModalHistoryComprovanteFigma';

type PropsOptionTable = {
  item: any;
  disable?: boolean;
  update(): Promise<void>;
};

export function OptionsHistoryFigma({
  item,
  disable,
  update,
}: PropsOptionTable) {
  const functionModalEdit = useModal();

  return (
    <div style={{ display: 'flex', gap: '15px' }}>
      <ButtonHistoryDetailsFigma update={update} item={item} />

      <ReactTooltip />
    </div>
  );
}
