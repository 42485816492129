import { format, eachYearOfInterval, eachMonthOfInterval } from 'date-fns';
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import { SpinnerLoading } from '../../../../components/SpinnerLoading';
import api from '../../../../services/api';

export function EgressoRelatorio() {
  const [year, set_year] = useState(format(new Date(), "yyyy"));
  const [trimestre, set_trimestre] = useState<string>("");
  const [month, set_month] = useState<string>("");
  const [typeRelatorio, set_typeRelatorio] = useState<string>("");

  const year_options = eachYearOfInterval({ start: new Date(0), end: new Date() }).map((year) => format(new Date(year), "yyyy")).reverse();

  const month_options = eachMonthOfInterval({ start: new Date().setMonth(0), end: new Date().setMonth(11) }).map((date) => ({ label: new Date(date).toLocaleDateString("pt-BR", { month: "long" }), value: format(date, "MM") }));

  const [load, setLoad] = useState(false);

  const types_relatorio_egresso = [
    { value: "todos", label: "Todos" },
    { value: "comportamento", label: "Comportamento" },
    { value: "concentracao", label: "Concentração" },
    { value: "observacao", label: "Observações" },
    { value: "queixa", label: "Queixas" },
  ];

  const trimestral_options = [
    { value: "01-03", label: "Primeiro trimestre" },
    { value: "03-06", label: "Segundo trimestre" },
    { value: "06-09", label: "Terceiro trimestre" },
    { value: "09-12", label: "Quarto trimestre" },
  ];

  function handleTrimestre() {
    if (Number(format(new Date(), "MM")) < 3) {
      return "01-03";
    }
    if (Number(format(new Date(), "MM")) < 6) {
      return "03-06";
    }
    if (Number(format(new Date(), "MM")) < 9) {
      return "06-09";
    }
    if (Number(format(new Date(), "MM")) < 12) {
      return "09-12";
    }
    return "";
  }

  async function handleGenerateReport(year: string, typeRelatorio: string, trimestre:string, month:string) {
    if (typeRelatorio === "todos") {
      const { data } = await api.get('/reports/generate/pedagogo', {
        params: {
          year,
          comportamento: true,
          concentracao: true,
          observacao: true,
          queixa: true,
          trimestre,
          month,
        },
      });

      fetch(data.url).then((res) => {
        res.blob().then((blob) => {
          const a = document.createElement("a");
          a.href = URL.createObjectURL(blob);
          a.setAttribute("download", "Relatório geral do egresso");
          a.click();
        });
      });
      return "";
    }
    const { data } = await api.get('/reports/generate/pedagogo', {
      params: {
        year,
        [typeRelatorio]: !!typeRelatorio,
        trimestre,
        month,
      },
    });

    fetch(data.url).then((res) => {
      res.blob().then((blob) => {
        const a = document.createElement("a");
        a.href = URL.createObjectURL(blob);
        a.setAttribute("download", "Relatório geral do egresso");
        a.click();
      });
    });
    return "";
  }

  const handleDownload = async () => {
    setLoad(true);
    if (typeRelatorio === "") {
      toast.error('Escolha o item para gerar o relatórios');
    } else {
      await handleGenerateReport(year, typeRelatorio, (trimestre === "" && month === "") ? handleTrimestre() : trimestre, month);
    }
    setLoad(false);
  };
  return (
    <Wrapper>
      <h2>Acompanhamento Geral</h2>
      <div>
        <h5>Escolha o item para gerar o relatório</h5>
        <Select className="form-control" onChange={(e) => set_typeRelatorio(e.target.value)}>
          <option value="">Selecione o item</option>
          {types_relatorio_egresso.map((type, i) => (
            <option key={i} value={type.value}>{type.label}</option>
          ))}
        </Select>
        <P>Escolha entre comportamento, concentração, observações ou queixas</P>
      </div>

      <div>
        <h5>Relatório do trimestre</h5>
        <Select className="form-control" onChange={(e) => set_trimestre(e.target.value)}>
          <option value="">Selecione o item</option>
          {trimestral_options.map((trimestre, i) => (
            <option key={i} value={trimestre.value}>{trimestre.label}</option>
          ))}
        </Select>
        <P>Caso fique em branco será gerado do trimestre atual.</P>
      </div>

      <div>
        <h5>Relatório do mês</h5>
        <Select className="form-control" onChange={(e) => set_month(e.target.value)}>
          <option value="">Selecione o item</option>
          {month_options.map((month, i) => (
            <option key={i} value={month.value}>{month.label}</option>
          ))}
        </Select>
        <P>Caso queira um relatório, mensal deixe o campo trimestre vazio e escolha o mês.</P>
      </div>

      <div>
        <h5>Relatório do ano</h5>
        <Select className="form-control" onChange={(e) => set_year(e.target.value)}>
          <option value="">Selecione o item</option>
          {year_options.map((year, i) => (
            <option key={i} value={year}>{year}</option>
          ))}
        </Select>
        <P>Caso fique em branco será gerado um relatório do ano atual.</P>
      </div>
      <WrapperButton>
        {load && (
        <button className="btn btn-success btn-lg">
          <SpinnerLoading />
        </button>
        )}
        {!load && <button className="btn btn-success btn-lg" onClick={handleDownload}>Gerar relatório</button>}

      </WrapperButton>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-top: 10px;
  background: #EFEFEF;
  padding: 20px;
  width: 100%;
`;

const P = styled.p`
color: #595959;
`;

const Select = styled.select`
  height: 59px;
  width: 335px;
  border-radius: 4px;

  font-size: 18px;
  font-weight: 400;
`;

const WrapperButton = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 20px;
`;
