import React, { useState } from 'react';
import ReactModal from 'react-modal';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { useAuth, usePermission } from '../../hooks/auth';

type SelecionarPerfilModalProps= {
  isOpenState: [boolean, (boolean: boolean) => void]
}

export function SelecionarPerfilModal(props: SelecionarPerfilModalProps) {
  const { isOpenState } = props;
  const permission = usePermission();
  const { setProfile } = useAuth();
  const [isOpen, setIsOpen] = isOpenState;
  const history = useHistory();
  return (
    <ReactModal
      isOpen={isOpen}
      ariaHideApp={false}
      onRequestClose={() => setIsOpen(false)}
      style={{
        content: {
          width: "320px",
          overflow: 'auto',
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          marginRight: '-50%',
          transform: 'translate(-50%, -50%)',
          borderRadius: '.6rem',
        },
        overlay: {
          background: 'rgba(0,0,0,.5)',
        },
      }}
    >
      <h1 className="pb-2 mb-3 h3 border-bottom border-primary">Selecione o perfil</h1>
      <div className="d-flex justify-content-center flex-column">
        {
          // @ts-ignore
          permission?.perfis.map((perfil) => (
            <Button
              key={perfil.title}
              onClick={() => {
                setProfile?.(perfil);
              }}
              type="button"
            >
              {perfil.title}
            </Button>
          ))
}
        <ButtonBack
          type="button"
          onClick={() => setIsOpen(false)}
        >
          Voltar
        </ButtonBack>
      </div>
    </ReactModal>
  );
}

const Button = styled.button`
  background-color: white;
  cursor: pointer;
  border: none;
  border-radius: .3rem;
  padding: .5rem;
  :hover {
    background-color: #E5E5E5;
  }
`;
const ButtonBack = styled.button`
  background-color: white;
  color: #007BFF;
  cursor: pointer;
  border: none;
  border-radius: .3rem;
  padding: .5rem;
  :hover {
    background-color: #E5E5E5;
  }
`;
