import styled from 'styled-components';

export const ConteinerHomologarProjeto = styled.div`
  margin-top: 20px;
  width: 100%;

  .header {
    margin: 0 20px 20px 0;
    display: flex;
    justify-content: space-between;

    & h1 {
      font-size: 48px;
      font-weight: 700;
      line-height: 56px;
      letter-spacing: 0px;
      text-align: left;
      color: rgba(48, 96, 153, 1);
    }

    & h2 {
      font-size: 36px;
      font-weight: 500;
      line-height: 42px;
      letter-spacing: 0px;
      text-align: left;
    }

    & h3 {
      font-size: 17px;
      font-weight: 400;
      line-height: 21px;
    }

    & h2:nth-child(2n) {
      font-size: 30px;
      font-weight: 700;
      line-height: 35px;
      letter-spacing: 0px;
      text-align: left;
      color:rgba(24, 35, 57, 1);
    }
  }


`;

export const ConteinerListItensProjetos = styled.div`
  width: 98%;
  margin: 10px 0;
  padding: 0 21px 0 21px;
  background: #EFEFEF;
  border: 0.5px solid #EFEFEF;
  border-radius: 5px;

  display: flex;
  flex-direction: column;
  gap: 20px;

  form {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  .item_list {
    & h4 {
      margin: 0;
      font-size: 24px;
      font-weight: 700;
      line-height: 29px;
      letter-spacing: 0px;
      text-align: left;
    }

    height: 59px;
    display: flex;
    align-items: center;
    gap: 30px;
  }

  .data {
    display: flex;
    gap: 50px;

    & input {
      height: 48px;
      width: 241.47222900390625px;
      border-radius: 5px;
      padding: 10px, 15px, 10px, 15px;
    }
  }

  .recurso_necessario {
    h4 {
      font-size: 24px;
      font-weight: 700;
      line-height: 28px;
      letter-spacing: 0px;
      text-align: left;
    }

    p {
      color: rgba(176, 176, 176, 1);
    }
  }

  .material {
    display: flex;
    gap: 20px;

    & p {
      color: rgba(176, 176, 176, 1);
    }
  }

  .wrapper_materiais_selecionados {
      width: 800px;
      border-radius: 20px;
      border: 1px solid #000000;
      padding: 35px ;
      display: flex;
      flex-direction: column;
      gap: 15px;
  }

  .btn_submit {
    margin: 50px 0 50px 0;
    display: flex;
    justify-content: center;

    & button {
      height: 59px;
      width: 247px;
      border-radius: 11px;

      font-size: 24px;
      font-weight: 700;
      line-height: 30px;
      letter-spacing: 0px;
      text-align: center;
    }
  }

  .arrow_up {
    display: flex;
    justify-content: end;
    margin-bottom: 10px;
  }

`;

export const InputField = styled.div`
  label {
      font-size: 24px;
      font-weight: 700;
      line-height: 28px;
      letter-spacing: 0px;
      text-align: left;
  }

  input {
      height: 48px;
      border-radius: 5px;
      padding: 10px, 15px, 10px, 15px;
    }
`;

export const SelectField = styled.div`
  label {
      font-size: 24px;
      font-weight: 700;
      line-height: 28px;
      letter-spacing: 0px;
      text-align: left;
  }
  .multi-select__control {
      width: 685px;
      min-height: 59px;
      max-height: 100vh;
      border-radius: 4px;
    }
`;

export const Materiais = styled.div`
    position: relative;

    input {
      height: 48px;
      border-radius: 5px;
      padding: 10px 15px 10px 15px;
      width: 685px;
      min-height: 59px;
      max-height: 100vh;
    }
    .ListMaterial {
      position: absolute;
      top: 60px;
      background-color: white;
      overflow-y: scroll;
      width: 102%;

      & p {
        cursor: pointer;
        height: 48px;
        border-radius: 5px;
        padding: 10px 15px 10px 15px;

        font-size: 20px;
        line-height: 28px;
        letter-spacing: 0px;
        text-align: left;
        color: rgba(0, 0, 0, 1);
      }

      & p:hover{
        background-color: rgb(130, 200, 255);
      }
    }
`;

export const MateriaisSelecionados = styled.div`
      width: 70%;
      display: flex;
      align-items: center;
      justify-content: space-between;

      label {
          font-size: 18px;
          font-weight: 400;
          line-height: 21px;
          width: 100px;
          letter-spacing: 0px;
          text-align: left;
        }

       input {
          height: 59px;
          width: 182px;
          border-radius: 15px;
          padding: 0 0 0 80px;
        }

      button {
        position: absolute;
        right: 300px;
      }
`;
