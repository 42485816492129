import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { BiArrowBack } from 'react-icons/bi';
import { SectionHeader } from '../../../../components/SectionHeader';
import { Loading } from '../../../../components/new-components/Loading';
import { UseRoundEgressesOfFical } from '../hooks/useRoundEgressesOfFiscal';
import { TablePadrão } from '../../../../components/develop-nilton-components/TablePadrão';
import { TbodyListLoadEgresses } from './TBodyListLoadEgresses';
import { Detail } from '../../../Adm/Egressos/Detail';
import { IEgresses } from '../../../../types/IEgresses';

export function PageHistoryFigma() {
  const history = useHistory();
  const { state } = useLocation<any>();
  const {
    roundEgressesOfFiscal,
    setRoundEgressesOfFiscal,
    handleUseRoundEgressesOfFical,
  } = UseRoundEgressesOfFical();
  const [searchList, setSeachList] = useState(roundEgressesOfFiscal);

  const [egresse, setEgresse] = React.useState<IEgresses | null>(null);
  const [isWatchEgresse, setIsWatchEgresse] = React.useState(false);

  useEffect(() => {
    setSeachList(roundEgressesOfFiscal);
  }, [roundEgressesOfFiscal]);

  return (
    <WrapperContainer style={{ width: '100%' }}>
      {isWatchEgresse && (
        <Detail
          useStateEgresse={[egresse, setEgresse]}
          useStateIsOpen={[isWatchEgresse, setIsWatchEgresse]}
        />
      )}
      <div style={{ display: isWatchEgresse ? 'none' : undefined }}>
        <SectionHeader
          isIconBack={
            <BiArrowBack
              size={30}
              style={{ marginRight: 10 }}
              cursor="pointer"
              onClick={() => history.push({ pathname: '/cadastrar-ronda' })}
            />
          }
          subtitle={state.title}
          backPage="/cadastrar-ronda"
        />
        <br />
        <div className="content" />

        <div
          style={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}
        />

        <TablePadrão
          thead={['Ordem', 'Egresso', 'Data/hora', 'Ações']}
          headWidth={['10%', '50%', '20%', '20%']}
        >
          {searchList?.map((item: any, i: any) => (
            <TbodyListLoadEgresses
              data-testid="table"
              iteration={i}
              key={i}
              item={item}
              widthBody={['10%', '50%', '20%', '20%']}
              useStateEgresse={[egresse, setEgresse]}
              useStateIsOpen={[isWatchEgresse, setIsWatchEgresse]}
              roundEgressesOfFiscal={roundEgressesOfFiscal}
              setRoundEgressesOfFiscal={setRoundEgressesOfFiscal}
              update={handleUseRoundEgressesOfFical}
            />
          ))}
        </TablePadrão>

        {!roundEgressesOfFiscal && (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
            data-testid="load"
          >
            <Loading />
          </div>
        )}
      </div>
    </WrapperContainer>
  );
}

const WrapperContainer = styled.div`
  width: 100%;

  .content {
    width: 100%;

    & div {
      display: flex;
    }

    select:focus {
      outline: none;
    }

    textarea:focus {
      outline: none;
    }

    & input {
      width: 100%;
    }

    & input:focus {
      outline: none !important;
      border-color: #007bff;
    }

    .inputfile {
      /* visibility: hidden etc. wont work */
      width: 0.1px;
      height: 0.1px;
      opacity: 0;
      overflow: hidden;
      position: absolute;
      z-index: -1;
    }
    .inputfile:focus + label {
      /* keyboard navigation */
      outline: 1px dotted #000;
      outline: -webkit-focus-ring-color auto 5px;
    }
    .inputfile + label * {
      pointer-events: none;
    }
  }
`;
