/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { FormState, UseFormRegister } from 'react-hook-form';
import styled from 'styled-components';
import { Errors } from '../../pages/Pedagogo/CadastrarProjeto/components/Erro';

type INameField = {
  register: UseFormRegister<any>;
  formState: FormState<any>;
  label?: string;
  name: string;
  placeholder?: string;
  size?: "lg" | "sm"
  width?: string | number | null
  max?: number
  fontSize?: string
};

export function InputField({
  register,
  formState,
  label,
  name,
  placeholder,
  size,
  width,
  max,
  fontSize,
}: INameField) {
  return (
    <Input width={width || null} fontSize={fontSize || null}>
      {label && <label htmlFor="">{label}</label>}
      <div>
        <input
          maxLength={max}
          autoComplete="off"
          type="text"
          {...register(name, { required: true })}
          className={size ? `form-control form-control-${size} position-relative` : "form-control position-relative"}
          placeholder={placeholder || undefined}
          required
        />
        {formState.errors[name] && <Errors />}
      </div>
    </Input>
  );
}

type PropsInput = {
  width?: string | number | null
  fontSize?: string | null
}

export const Input = styled.div<PropsInput>`
    label {
        font-size: 24px;
        font-weight: 700;
        line-height: 28px;
        letter-spacing: 0px;
        text-align: left;
    }

    input {
        width: ${(props) => (props.width ? props.width : "100%")};
        font-size: ${(props) => (props.fontSize ? props.fontSize : "18px")};
        font-weight: 400;
        line-height: 28px;
        letter-spacing: 0em;
        text-align: left;
    }
`;
