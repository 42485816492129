import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import ReactTooltip from 'react-tooltip';
import { Badge } from '../../../../components/develop-nilton-components/Badge';
import { IconAdd } from '../../../../components/develop-nilton-components/IconAdd';
import { IconDetails } from '../../../../components/develop-nilton-components/IconDetails';
import { ModalDetailsEgresse } from '../../../../components/develop-nilton-components/ModalDetailsEgresses';
import { Td, Tr } from '../../../../components/develop-nilton-components/TablePadrão';
import { useAuth } from '../../../../hooks/auth';
import { data } from '../types';

type PropsTbodyTable = {
  egresse: data
  index: number
  headWidth: string[]
}

export function TbodyTable({ egresse, index, headWidth }:PropsTbodyTable) {
  const [ModalIsOpen, setModalIsOpen] = useState(false);

  const history = useHistory();

  async function handleModalIsOpen() {
    setModalIsOpen(!ModalIsOpen);
  }

  const logId = useAuth().user.id;
  console.log(egresse.acompanhamentos_psicologogico?.em_acompanhamento?.psicologo.id === logId);

  async function handleRedirectToPagePerfil() {
    history.push({ state: egresse, pathname: "/perfil/egresso" });
  }

  return (
    <Tr data-testid="table_geral">
      <Td width={headWidth[0]}>{index}</Td>
      <Td width={headWidth[1]}>{egresse.nome}</Td>
      <Td width={headWidth[2]}>
        {(egresse.acompanhamentos_psicologogico.situacao === "Sem acompanhamento" || egresse?.acompanhamentos_psicologogico?.situacao === "Acompanhamento concluído") && (
        <Badge type="primary">Sem acompanhamento</Badge>
        )}
        {egresse.acompanhamentos_psicologogico.situacao === "Em acompanhamento" && (
          <div data-tip={`Consulta iniciada por ${egresse?.acompanhamentos_psicologogico?.em_acompanhamento?.psicologo.name}`}>
            <Badge type="warning">{egresse?.acompanhamentos_psicologogico.situacao}</Badge>
          </div>
        )}
      </Td>

      <Td width={headWidth[3]}>
        <div onClick={handleModalIsOpen} data-testid="lista_egressos" className="mr-3">
          <IconDetails
            cursor
            tooltipMessage="Dados do egressos"
            size={30}
          />
          <ReactTooltip />
        </div>
        <ModalDetailsEgresse
          egresse={egresse}
          handleIsOpen={handleModalIsOpen}
          isOpen={ModalIsOpen}
        />
        {egresse.acompanhamentos_psicologogico.situacao === "Em acompanhamento" ? (
          <div onClick={() => {
            if (egresse.acompanhamentos_psicologogico.em_acompanhamento.psicologo.id === logId) {
              handleRedirectToPagePerfil();
            } else {
              toast.error('Egresso acompanhado por outro psicólogo');
            }
          }}
          >
            <IconAdd
              cursor
              tooltipMessage="Realizar acompanhamento"
              color="text-primary"
              size={30}
            />
          </div>
        ) : (
          <div onClick={handleRedirectToPagePerfil}>
            <IconAdd
              cursor
              tooltipMessage="Realizar acompanhamento"
              color="text-primary"
              size={30}
            />
          </div>
        )}
      </Td>

    </Tr>
  );
}
