import React, { useEffect, useState } from 'react';
import api from '../../../../services/api';

export function useCursosFinalizados<T>() {
  const [finalizado, setCursos] = useState<T | null>(null);
  const [load_finalizado, setLoad] = useState(false);

  async function handleSetCursosFinalizado(page = 1, search?: string) {
    setLoad(true);
    const { data } = await api.get('/courses', {
      params: {
        page,
        title: search,
        status: "Finalizado",
      },
    });
    if (data.data.length === 0 && page > 1) {
      handleSetCursosFinalizado(page - 1);
      setCursos(data);
    } else {
      setCursos(data);
    }
    setLoad(false);
  }

  useEffect(() => {
    handleSetCursosFinalizado();
  }, []);
  return {
    finalizado, handleSetCursosFinalizado, load_finalizado,
  };
}
