import React, { useEffect, useState } from 'react';
import api from '../../../../services/api';

function useProjetosAll() {
  const [projetos, setProjetos] = useState<any | null>(null);
  const [load, setLoad] = useState(false);

  async function handleProjetosAll(page = 1, search?: string) {
    setLoad(true);
    const { data } = await api.get('/projects', {
      params: {
        page,
        nome: search,
      },
    });
    if (data.data.length === 0 && page > 1) {
      handleProjetosAll(page - 1);
    }
    setProjetos(data);
    setLoad(false);
  }

  useEffect(() => {
    handleProjetosAll();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return {
    projetos, handleProjetosAll, load,
  };
}

export default useProjetosAll;
