import { format } from 'date-fns';
import React, { useState } from 'react';
import { useForm, useFieldArray } from 'react-hook-form';
import { useHistory, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { SectionHeader } from '../../../../components/SectionHeader';
import { SpinnerLoading } from '../../../../components/SpinnerLoading';
import {
  DateField,
  MaterialField,
  MaterialValues,
  NameField,
  ObjetivoField,
  OrigemRecursosField,
  ParceiroField,
  ValorEnvolvidoField,
} from '../../../Pedagogo/CadastrarProjeto/components';
import { submitEditProjeto } from '../../../Pedagogo/CadastrarProjeto/hooks/functions/SubmitProjeto';
import { OptionsLaboratorio, OptionsMaterial, OptionsSalaDeAula } from '../../../Pedagogo/CadastrarProjeto/hooks/Model';
import { IProjetoEdit } from '../../../Pedagogo/CadastrarProjeto/hooks/Model/Projeto/type';
import { SelectField } from '../../../Pedagogo/CadastrarProjeto/styles';

export function PageEditarProjeto() {
  // @ts-ignore

  const { state: { projeto } } = useLocation<IProjetoEdit>();
  const dataFim = format(new Date(projeto.data_fim), 'yyyy-MM-dd');
  const dataInicial = format(new Date(projeto.data_inicio), 'yyyy-MM-dd');

  const {
    register, handleSubmit, formState, control, setValue,
  } = useForm({ defaultValues: { ...projeto, data_fim: dataFim, data_inicio: dataInicial } });
  const { append, fields, remove } = useFieldArray({ control, name: "materials" });
  const [load, setLoad] = useState(false);
  const history = useHistory();

  const optionsLaboratorio = OptionsLaboratorio();
  const optionsSala = OptionsSalaDeAula();
  const optionMaterial = OptionsMaterial({ fields });

  async function onSubmit(data: IProjetoEdit) {
    setLoad(true);
    try {
      await submitEditProjeto({
        data_fim: data.data_fim,
        data_inicio: data.data_inicio,
        id: data.id,
        laboratorio: data.laboratorio,
        materials: data.materials,
        nome: data.nome,
        objetivo: data.objetivo,
        origem_recurso: data.origem_recurso,
        parceiro: data.parceiro,
        sala_aula: data.sala_aula,
        valor_envolvido: data.valor_envolvido,
      });
      toast("Projeto editado com sucesso!", {
        type: "success",
      });

      history.push('/homologar_pro');
    } catch (error) {
      toast('Erro ao editar projeto.', {
        type: "error",
      });
    }
    setLoad(false);
  }

  return (
    <div style={{ width: "100%" }}>
      <SectionHeader subtitle={`Editar Projeto - ${projeto.nome}`} backPage="/homologar_pro" />
      <form
        onSubmit={handleSubmit(onSubmit)}
        style={{ display: "flex", flexDirection: "column", gap: 10 }}
      >
        <NameField register={register} formState={formState} label="Nome do projeto" name="nome" />

        <DateField register={register} formState={formState} />

        <OrigemRecursosField register={register} formState={formState} />

        <ValorEnvolvidoField register={register} formState={formState} />

        <ParceiroField register={register} formState={formState} />

        <ObjetivoField register={register} formState={formState} />

        <SelectField>
          <h2>Recursos necessários</h2>
          <select className="form-control form-control-lg" {...register('laboratorio')}>
            <option value="">Selecione...</option>
            {optionsLaboratorio.map((item) => (
              <option value={item.value}>{item.label}</option>
            ))}
          </select>
          <select className="form-control form-control-lg" {...register('sala_aula')}>
            <option value="">Selecione...</option>
            {optionsSala.map((item) => (
              <option value={item.value}>{item.label}</option>
            ))}
          </select>
        </SelectField>

        <div>
          <h2 style={{ fontSize: "24px", fontWeight: 700 }}>Selecionar materiais</h2>
          <MaterialField append={append} options={optionMaterial} fields={fields} />
        </div>
        <div style={{ width: '10%' }}>
          <MaterialValues fields={fields} remove={remove} setValue={setValue} />
        </div>

        <div style={{
          width: "0%", display: "flex", justifyContent: "center", margin: 50,
        }}
        >
          {load && <SpinnerLoading size={20} />}
          {!load && <button className="btn btn-primary btn-lg">Finalizar</button>}
        </div>
      </form>
    </div>
  );
}
