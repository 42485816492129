import { useEffect, useState } from 'react';
import api from '../../../../services/api';

type IOcorrences = {
  data: {
    id: number
    data_ocorrencia: string
    ocorrencia: {
      name: string
    }
    egresso: {
      nome: string
    }}[]
  meta: {
    last_page: number
  }
}

export function useOcurrences() {
  const mes = new Date().getMonth() + 1 < 10 ? `0${new Date().getMonth() + 1}` : `${new Date().getMonth() + 1}`;
  const ano = new Date().getFullYear();

  const [ocurrences, setOcurrences] = useState<IOcorrences | null>(null);
  const [month, setMonth] = useState<string >(mes);
  const [year, setYear] = useState<string | number>(ano);

  async function setPage(page = 1) {
    const { data } = await api.get(`https://api-albergado.msbtec.com.br/reports/ocurrences`, {
      params: {
        month,
        year,
        page,
      },
    });

    setOcurrences(data);
  }

  useEffect(() => {
    setPage();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [month, year]);

  return {
    ocurrences, setPage, month, setMonth, year, setYear,
  };
}
