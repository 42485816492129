import { toast } from 'react-toastify';
import api from '../../../../../services/api';
import { IProjeto, IProjetoEdit } from '../Model/Projeto/type';

export async function submitProjeto(projeto: IProjeto) {
  try {
    await api.post('/projects', {
      ...projeto,
      materials: projeto.materials.map(({ qtd, material: { id } }) => ({
        qtd,
        id,
      })),
    });
    toast.success('Projeto pedagógico cadastrado com sucesso.');
    return "success";
  } catch (error) {
    // @ts-ignore
    toast.error(error.response.data.message);
    return 'error';
  }
}

export async function submitEditProjeto(projeto: IProjetoEdit) {
  console.log(projeto);
  try {
    await api.put(`/projects/${projeto.id}`, {
      ...projeto,
      materials: projeto.materials.map(({ qtd, material: { id } }) => ({
        qtd,
        id,
      })),
    });
    toast.success('Projeto pedagógico editado com sucesso.');
    return "success";
  } catch (error) {
    // @ts-ignore
    toast.error(error.response.data.message);
    return 'error';
  }
}
