import React, { useState } from "react";
import api from "../../../../services/api";

export function useGet_relatorio_psicologico_indicadores() {
  async function handle_relatorio_psicologico_indicadores(year: string, type_set: string, trimestre?: string) {
    const { data } = await api.get('/reports/relatorio_psicologico_indicadores', {
      params: {
        year,
        [type_set]: !!type_set,
        trimestre,
      },
    });
    return data;
  }

  return { handle_relatorio_psicologico_indicadores };
}
