import React, { useEffect, useState } from 'react';
import { Chart } from 'react-chartjs-2';
import { SpinnerLoading } from '../../../../components/SpinnerLoading';
import { handleTrimestre, trimestral_options } from '../../../../components/develop-nilton-components/hooks/time_options';
import { useGetIndicadores } from '../hooks/useGetIndicadores';

export function FatoresIntTrimestral({ tab, tabType }: {tab:number, tabType: number}) {
  const { indicador: obdiencia_ind, handleSetIndicador: handle_set_obdiencia_ind } = useGetIndicadores();
  const { indicador: interage_com_equipe, handleSetIndicador: handle_set_interage_com_equipe } = useGetIndicadores();
  const { indicador: espirito_iniciativa, handleSetIndicador: handle_set_espirito_iniciativa } = useGetIndicadores();
  const { indicador: dificuldade_memorizacao_concentracao, handleSetIndicador: handle_set_dificuldade_memorizacao_concentracao } = useGetIndicadores();
  const { indicador: ritmo_trabalho, handleSetIndicador: handle_set_dificuldade_ritmo_trabalho } = useGetIndicadores();
  const { indicador: consegue_expressar_pensamentos, handleSetIndicador: handle_set_consegue_expressar_pensamentos } = useGetIndicadores();
  const { indicador: dificuldade_aprendizagem, handleSetIndicador: handle_set_dificuldade_aprendizagem } = useGetIndicadores();

  const options_graphics = [
    {
      title: "Obediência",
      type: "line",
      options: {
        plugins: {
          legend: { display: false, position: "left" },
        },
      },
      data: obdiencia_ind,
      onChange: (e:any) => {
        handle_set_obdiencia_ind("obdece_comandos_primeira_vez", undefined, e.target.value);
      },
    },
    {
      title: "Interação em equipe",
      type: "pie",
      options: {
        plugins: {
          legend: { display: true, position: "left" },
        },
      },
      data: interage_com_equipe,
      onChange: (e:any) => {
        handle_set_interage_com_equipe("interage_com_equipe", undefined, e.target.value);
      },
    },
    {
      title: "Iniciativa",
      type: "pie",
      options: {
        plugins: {
          legend: { display: true, position: "left" },
        },
      },
      data: espirito_iniciativa,
      onChange: (e:any) => {
        handle_set_espirito_iniciativa('espirito_iniciativa', undefined, e.target.value);
      },
    },
    {
      title: "Memorização e concentração",
      type: "line",
      options: {
        plugins: {
          legend: { display: false, position: "left" },
        },
      },
      data: dificuldade_memorizacao_concentracao,
      onChange: (e:any) => {
        handle_set_dificuldade_memorizacao_concentracao("dificuldade_memorizacao_concentracao", undefined, e.target.value);
      },
    },
    {
      title: "Ritmo de trabalho",
      type: "pie",
      options: {
        plugins: {
          legend: { display: true, position: "left" },
        },
      },
      data: ritmo_trabalho,
      onChange: (e:any) => {
        handle_set_dificuldade_ritmo_trabalho("ritmo_trabalho", undefined, e.target.value);
      },
    },
    {
      title: "Expressão de sentimentos",
      type: "line",
      options: {
        plugins: {
          legend: { display: false, position: "left" },
        },
      },
      data: consegue_expressar_pensamentos,
      onChange: (e:any) => {
        handle_set_consegue_expressar_pensamentos("consegue_expressar_pensamentos", undefined, e.target.value);
      },
    },
    {
      title: "Dificuldade de aprendizado",
      type: "line",
      options: {
        plugins: {
          legend: { display: false, position: "left" },
        },
      },
      data: dificuldade_aprendizagem,
      onChange: (e:any) => {
        handle_set_dificuldade_aprendizagem("dificuldade_aprendizagem", undefined, e.target.value);
      },
    },
  ];

  const [load, set_load] = useState(false);

  useEffect(() => {
    (async () => {
      set_load(false);
      await handle_set_obdiencia_ind("obdece_comandos_primeira_vez", undefined, handleTrimestre());
      await handle_set_interage_com_equipe("interage_com_equipe", undefined, handleTrimestre());
      await handle_set_espirito_iniciativa('espirito_iniciativa', undefined, handleTrimestre());
      await handle_set_dificuldade_memorizacao_concentracao("dificuldade_memorizacao_concentracao", undefined, handleTrimestre());
      await handle_set_dificuldade_ritmo_trabalho("ritmo_trabalho", undefined, handleTrimestre());
      await handle_set_consegue_expressar_pensamentos("consegue_expressar_pensamentos", undefined, handleTrimestre());
      await handle_set_dificuldade_aprendizagem("dificuldade_aprendizagem", undefined, handleTrimestre());
      set_load(true);
    })();
  }, [tab, tabType]);

  return (
    <div>
      {!load && (
        <div className="d-flex justify-content-center m-5">
          <SpinnerLoading />
        </div>
      )}
      {load && options_graphics.map(({
        data, type, options, title, onChange,
      }, i) => (
        <div key={i}>
          <div className="mt-3 border-bottom border-secondary d-flex">
            <h3 className="mr-2">{title}</h3>
            <select
              style={{ height: 35, width: 200, cursor: "pointer" }}
              className="form-control"
              onChange={onChange}
              defaultValue={handleTrimestre()}
            >
              {trimestral_options.map((trimestre, i) => (
                <option key={i} value={trimestre.value} selected={trimestre.value === handleTrimestre()}>{trimestre.label}</option>
              ))}
            </select>
          </div>
          <div className="d-flex align-items-center flex-column w-100">
            <div>
              <Chart
                // @ts-ignore
                type={type}
                data={data}
                // @ts-ignore
                options={options}
                style={{ width: type === "line" ? 900 : 600 }}
              />
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}
