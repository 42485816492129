import React, { useEffect, useState } from 'react';
import { Badge } from 'reactstrap';
import { format } from 'date-fns';
import { AiFillEye, AiFillMinusCircle } from 'react-icons/ai';
import { FaFileDownload } from 'react-icons/fa';
import { toast } from 'react-toastify';
import ReactTooltip from 'react-tooltip';
import styled from 'styled-components';
import { useQueryClient } from 'react-query';
import { SectionHeader } from '../../../components/SectionHeader';
import { TableComponent } from '../../../components/new-components/Table';
import { Loading } from '../../../components/new-components/Loading';
import { IconEdit } from '../../../components/new-components/IconEdit';
import { Pagination } from '../../../components/new-components/Pagination';
import InputSearch from '../../Fiscal/components/InputSearch';
import { ButtonAdd } from '../../../components/new-components/ButtonAdd';
import { EgressesFormModal } from '../../../components/new-components/EgressesFormModal';
import { IEgresses } from '../../../types/IEgresses';
import api from '../../../services/api';
import { useEgresses } from '../../../hooks/Egresses';
import { useAuth, usePermission } from '../../../hooks/auth';
import { Detail } from './Detail';
import { useUpdateUsers } from '../../../hooks/Users/useUpdateUsers';
import { ModalConfirmEgressDisable } from './components/modalConfirmEgressDisable';

export type EgressesAxiosResponse = {
  data: IEgresses[];
  meta: { last_page: number; per_page: number };
};

export function Egressos() {
  const queryClient = useQueryClient();

  const {
    egresses: provider,
    page,
    search,
    handlePage,
    handleSearch,
    load,
    handleHabilitado,
    handleReload,
  } = useEgresses();

  const { setIsInEgress } = useAuth();
  const { title } = usePermission();

  const [loadingSearch, setLoadingSearch] = useState(false);

  const [isOpen, setIsOpen] = React.useState(false);
  const [isOpenModalConfirmDisableEgress, setIsOpenModalConfirmDisableEgress] =
    React.useState(false);
  const [isWatchEgresse, setIsWatchEgresse] = React.useState(false);
  const [isCreate, setIsCreate] = React.useState(false);
  const [egresse, setEgresse] = React.useState<IEgresses | null>(null);
  const [egresseAux, setEgresseAux] = React.useState<IEgresses | null>(null);
  // const [motivo, setMotivo] = useState('');

  useEffect(() => {
    if (egresseAux !== null) {
      handleIsOpenModalConfirmDisableEgress();
    }
  }, [egresseAux]);

  const [egresses, setEgresses] = useState<EgressesAxiosResponse>({
    meta: {
      total: 0,
      per_page: 15,
      current_page: 1,
      last_page: 1,
      first_page: 1,
      first_page_url: '/?page=1',
      last_page_url: '/?page=1',
      next_page_url: null,
      previous_page_url: null,
    },
    data: [],
  } as EgressesAxiosResponse);

  const [isLoad, setIsload] = useState(true);
  const [status, setStatus] = useState({
    habilitados: 0,
    desabilitados: 0,
  });

  async function getStatus() {
    const { data: habilitado } = await api.get('/egresses', {
      params: {
        habilitado: 'T',
      },
    });

    const { data: desabilitado } = await api.get('/egresses', {
      params: {
        habilitado: 'F',
      },
    });

    setIsload(false);
    setStatus({
      ...status,
      habilitados: habilitado?.meta?.total,
      desabilitados: desabilitado?.meta?.total,
    });
  }

  useEffect(() => {
    setIsload(true);
    getStatus();
  }, []);

  useEffect(() => {
    if (provider) {
      setEgresses(provider);
      getStatus();
    }
  }, [provider]);

  function download(url: any, filename: any) {
    fetch(url).then(t =>
      t.blob().then(b => {
        const a = document.createElement('a');
        a.href = URL.createObjectURL(b);
        a.setAttribute('download', filename);
        a.click();
      }),
    );
  }

  React.useEffect(() => {
    if (isOpen) {
      setIsInEgress(true);
    } else {
      setIsInEgress(false);
    }
  }, [isOpen]);

  async function searchEgresses(page: number, search: string) {
    setLoadingSearch(true);
    handleSearch(search);
    setLoadingSearch(false);
  }

  function handleIsOpenModalConfirmDisableEgress(): void {
    setIsOpenModalConfirmDisableEgress(!isOpenModalConfirmDisableEgress);
    if (isOpenModalConfirmDisableEgress) {
      setEgresseAux(null);
    }
  }

  const handleChangeHabilitado = async (motivo: string) => {
    const formData = new FormData();

    if (motivo?.length > 0) {
      formData.append(
        'data',
        egresseAux?.habilitado === 'T'
          ? JSON.stringify({ habilitado: 'F', motivo_desabilitar: motivo })
          : JSON.stringify({ habilitado: 'T', motivo_desabilitar: motivo }),
      );
    } else {
      formData.append(
        'data',
        egresseAux?.habilitado === 'T'
          ? JSON.stringify({ habilitado: 'F' })
          : JSON.stringify({ habilitado: 'T' }),
      );
    }

    const myPromise = new Promise(resolve =>
      api.put(`/egresses/${egresseAux?.id}`, formData).then(json => {
        setStatus({
          ...status,
          desabilitados:
            egresseAux?.habilitado === 'T'
              ? status.desabilitados + 1
              : egresseAux?.habilitado === 'F'
              ? status.desabilitados - 1
              : status.desabilitados,
          habilitados:
            egresseAux?.habilitado === 'F'
              ? status.habilitados + 1
              : egresseAux?.habilitado === 'T'
              ? status.habilitados - 1
              : status.habilitados,
        });

        setEgresses({
          meta: egresses.meta,
          data: egresses.data.map(item =>
            item.id == egresseAux.id
              ? { ...item, habilitado: item.habilitado == 'T' ? 'F' : 'T' }
              : item,
          ),
        });
        queryClient.refetchQueries(['egresses']);
        resolve(true);
      }),
    );

    toast.promise(myPromise, {
      pending:
        egresseAux?.habilitado === 'T'
          ? 'Desabilitando egresso...'
          : 'Habilitando egresso...',
      success:
        egresseAux?.habilitado === 'T'
          ? 'Egresso desabilitado'
          : 'Egresso habilitado',
      error: { render: ({ data }: any) => data.response.data.message },
    });
  };

  const { mutate } = useUpdateUsers(handleChangeHabilitado, {
    query: 'egresses',
    page,
    search,
  });

  async function auxFunction(motivoParameter: string) {
    // setMotivo(motivoParameter);
    mutate(motivoParameter);
  }

  const [loading, setLoading] = useState(false);

  async function deleteEgresse(id: string) {
    const functionThatReturnPromise = (id: string) =>
      new Promise(resolve => {
        setLoading(true);
        api.delete(`/egresses/${id}`).then(({ data }) => {
          setLoading(false);
          const aux = egresses.data.filter(item => String(item.id) == id)[0];
          setEgresses({
            meta: egresses.meta,
            data: egresses.data.filter(item => String(item.id) != id),
          });
          setStatus({
            ...status,
            desabilitados:
              aux?.habilitado === 'F'
                ? status.desabilitados - 1
                : status.desabilitados,
            habilitados:
              aux?.habilitado === 'T'
                ? status.habilitados - 1
                : status.habilitados,
          });
          queryClient.refetchQueries(['egresses']);
          resolve(true);
        });
      });

    toast.promise(functionThatReturnPromise(id), {
      pending: 'Removendo...',
      error: { render: ({ data }: any) => data },
      success: 'Egresso removido com sucesso',
    });
  }

  useEffect(() => {
    if (!isWatchEgresse) {
      // queryClient.refetchQueries(['egresses']);
      handleReload();
    }
  }, [isWatchEgresse]);

  return (
    <WrapperContainer>
      {isOpen ? (
        <EgressesFormModal
          useStateIsOpen={[isOpen, setIsOpen]}
          useStateIsCreate={[isCreate, setIsCreate]}
          useStateEgresse={[egresse, setEgresse]}
          queryKeysEgresse={{ page, search }}
        />
      ) : isWatchEgresse ? (
        <Detail
          useStateEgresse={[egresse, setEgresse]}
          useStateIsOpen={[isWatchEgresse, setIsWatchEgresse]}
        />
      ) : (
        <>
          <SectionHeader subtitle="Egressos" />

          <div className="content" style={{ marginBottom: 50 }}>
            <InputSearch
              loading={loadingSearch}
              setSearch={searchEgresses}
              autosearch
              title="Pesquisar Egresso"
              placeholder="Digite o nome do egresso"
              buttonText="Buscar"
              description=""
              button={
                <ButtonAdd
                  style={{ marginLeft: 10, background: '#68996D' }}
                  onClick={() => {
                    setEgresse(null);
                    setIsCreate(true);
                    setIsOpen(true);
                  }}
                />
              }
            />
          </div>

          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                background: '#f6f6f6',
                paddingRight: 10,
                paddingLeft: 10,
                paddingTop: 5,
                paddingBottom: 5,
                borderRadius: 5,
                marginBottom: 20,
                marginRight: 10,
              }}
            >
              <span>Total: {status.habilitados + status.desabilitados}</span>
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                background: '#f6f6f6',
                paddingRight: 10,
                paddingLeft: 10,
                paddingTop: 5,
                paddingBottom: 5,
                borderRadius: 5,
                marginBottom: 20,
                marginRight: 10,
              }}
            >
              <span style={{ color: '#198754' }}>
                Ativos: {status.habilitados}
              </span>
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                background: '#f6f6f6',
                paddingRight: 10,
                paddingLeft: 10,
                paddingTop: 5,
                paddingBottom: 5,
                borderRadius: 5,
                marginBottom: 20,
              }}
            >
              <span style={{ color: '#dc3545' }}>
                Desabilitados: {status.desabilitados}
              </span>
            </div>
          </div>

          {!isLoad ? (
            <TableComponent
              isEgresse
              data={egresses?.data.map((egresse, i) => ({
                Nome: (
                  <h1
                    style={{
                      fontSize: 16,
                      color: '#212529',
                      fontWeight: 'normal',
                    }}
                  >
                    {egresse.nome}
                  </h1>
                ),
                Status:
                  egresse.habilitado === 'T' ? (
                    <div>
                      <Badge
                        style={{ cursor: 'pointer' }}
                        onClick={() => setEgresseAux(egresse)}
                        color="success"
                      >
                        Habilitado
                      </Badge>
                    </div>
                  ) : (
                    <div>
                      <Badge
                        style={{ cursor: 'pointer' }}
                        onClick={() => setEgresseAux(egresse)}
                        color="danger"
                      >
                        Desabilitado
                      </Badge>
                    </div>
                  ),
                Opções: (
                  <div className="d-flex justify-content-flex-start">
                    <div
                      data-tip="Perfil do Egresso"
                      style={{ cursor: 'pointer' }}
                      onClick={() => {
                        setEgresse({
                          ...egresse,
                          data_alvara_soltura:
                            egresse.data_alvara_soltura === null
                              ? ''
                              : format(
                                  new Date(egresse?.data_alvara_soltura),
                                  'yyyy-MM-dd',
                                ),
                          data_entrada_sistema_penitenciario:
                            egresse.data_entrada_sistema_penitenciario === null
                              ? ''
                              : format(
                                  new Date(
                                    egresse?.data_entrada_sistema_penitenciario,
                                  ),
                                  'yyyy-MM-dd',
                                ),
                          data_termino_pena:
                            egresse.data_termino_pena === null
                              ? ''
                              : format(
                                  new Date(egresse?.data_termino_pena),
                                  'yyyy-MM-dd',
                                ),
                          data_nascimento:
                            egresse.data_nascimento === null
                              ? ''
                              : format(
                                  new Date(egresse?.data_nascimento),
                                  'yyyy-MM-dd',
                                ),
                        });
                        setIsWatchEgresse(true);
                      }}
                    >
                      <AiFillEye size={22} />
                    </div>
                    <div
                      className="ms-2"
                      data-tip="Editar Egresso"
                      onClick={() => {
                        setEgresse({
                          ...egresse,
                          data_alvara_soltura:
                            egresse.data_alvara_soltura === null
                              ? ''
                              : format(
                                  new Date(egresse?.data_alvara_soltura),
                                  'yyyy-MM-dd',
                                ),
                          data_entrada_sistema_penitenciario:
                            egresse.data_entrada_sistema_penitenciario === null
                              ? ''
                              : format(
                                  new Date(
                                    egresse?.data_entrada_sistema_penitenciario,
                                  ),
                                  'yyyy-MM-dd',
                                ),
                          data_termino_pena:
                            egresse.data_termino_pena === null
                              ? ''
                              : format(
                                  new Date(egresse?.data_termino_pena),
                                  'yyyy-MM-dd',
                                ),
                          data_nascimento:
                            egresse.data_nascimento === null
                              ? ''
                              : format(
                                  new Date(egresse?.data_nascimento),
                                  'yyyy-MM-dd',
                                ),
                        });
                        setIsCreate(false);
                        setIsOpen(true);
                      }}
                      style={{ cursor: 'pointer' }}
                    >
                      <IconEdit />
                    </div>

                    <div
                      data-tip="Relatório geral do egressso"
                      onClick={() => {
                        toast.promise(
                          api
                            .get(`/egresses/${egresse.id}/report`)
                            .then(({ data }) => {
                              download(data.url, `RELATÓRIO_${egresse.nome}`);
                            }),
                          {
                            pending: 'Baixando',
                          },
                        );
                      }}
                      className="ms-2"
                    >
                      <FaFileDownload cursor="pointer" />
                    </div>

                    {title === 'master' && (
                      <div
                        data-tip="Remover egresso"
                        className="ms-2"
                        onClick={() =>
                          !loading && deleteEgresse(String(egresse.id))
                        }
                      >
                        <AiFillMinusCircle
                          color="rgba(201, 19, 19, 0.6)"
                          size={20}
                          cursor="pointer"
                        />
                      </div>
                    )}
                    <ReactTooltip />
                  </div>
                ),
              }))}
            />
          ) : (
            <Loading />
          )}

          {!isLoad && !load && (
            <Pagination
              meta={egresses?.meta}
              useStatePage={[page, handlePage]}
            />
          )}
        </>
      )}
      <ModalConfirmEgressDisable
        handleIsOpen={handleIsOpenModalConfirmDisableEgress}
        title="Confirme esclarecendo o motivo"
        isOpen={isOpenModalConfirmDisableEgress}
        action={auxFunction}
        status={egresseAux?.habilitado === 'T' ? 'Habilitado' : 'Desabilitado'}
      />
    </WrapperContainer>
  );
}

const WrapperContainer = styled.div`
  width: 100%;

  @media (max-width: 640px) {
    width: 230px;
  }
  @media (max-width: 320px) {
    width: 100px;
  }

  .content {
    width: 100%;

    & div {
      display: flex;
    }

    select:focus {
      outline: none;
    }

    textarea:focus {
      outline: none;
    }

    & input {
      width: 100%;
    }

    & input:focus {
      outline: none !important;
    }

    .inputfile {
      width: 0.1px;
      height: 0.1px;
      opacity: 0;
      overflow: hidden;
      position: absolute;
      z-index: -1;
    }
    .inputfile:focus + label {
      outline: 1px dotted #000;
      outline: -webkit-focus-ring-color auto 5px;
    }
    .inputfile + label * {
      pointer-events: none;
    }
  }
`;
