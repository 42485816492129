import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import { CardItemContainer } from './CardItem.styled';

type Props = {
  url: string;
  pathPainel: string;
  title: string;
}

function CardItem({ url, pathPainel, title }: Props) {
  const history = useHistory();
  return (
    <CardItemContainer onClick={() => history.push({
      pathname: pathPainel,
      state: { user: title },
    })}
    >
      <div className="img-card">
        <img src={url} alt="icone-card" />
      </div>
      <div className="link-card">
        <Link to={{
          pathname: pathPainel,
          state: { user: title },
        }}
        >
          {title}
        </Link>
      </div>
    </CardItemContainer>
  );
}

export default CardItem;
