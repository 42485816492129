import React from 'react';
import { TablePadrão, Td, Tr } from '../../../../components/develop-nilton-components/TablePadrão';
import { Pagination } from '../../../../components/Pagination';
import { SpinnerLoading } from '../../../../components/SpinnerLoading';
import { SubmitResponse } from '../../../../components/SubmitResponse';
import { PropsTable } from '../types';
import { TbodyGeral } from './TbodyGeral';

export function TableGeral({
  projetos, handleProjetos, load,
}: PropsTable) {
  const headWidth = ["5%", "55%", "20%", "20%"];
  return (
    <div style={{ width: "98%" }}>
      <TablePadrão
        thead={["#", "Nome do projeto", "Status", "Opções"]}
        headWidth={headWidth}
      >
        {load && (
        <Tr>
          <Td width="100%"><SpinnerLoading /></Td>
        </Tr>
        )}
        {projetos?.data.map((item, i:number) => (
          <TbodyGeral
            key={item.id}
            index={i + 1}
            projetoItem={item}
            headWidth={headWidth}
            handleProjetos={handleProjetos}
            meta={projetos.meta}
          />
        ))}
        {projetos?.data.length === 0 && (
          <Tr>
            <Td width="100%">
              <div className="w-100">
                <SubmitResponse submitResponse={{
                  message: "Não há projeto cadastrado ou o projeto foi removido.",
                  status: "danger",
                  title: 'Aviso',
                }}
                />
              </div>
            </Td>
          </Tr>
        )}
      </TablePadrão>
      {projetos && <Pagination meta={projetos.meta} setPage={handleProjetos} />}
    </div>
  );
}
