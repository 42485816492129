import React from 'react';
import ReactPaginate from 'react-paginate';
import styled from 'styled-components';
import { ConteinerPagination } from '../../Pagination/style';

type PaginationProps = {
  meta: any
  useStatePage: [number, (number: number) => void]
}

export function Pagination(props: PaginationProps) {
  const { useStatePage, meta } = props;
  const [page, setPage] = useStatePage;
  return (
    <div style={{
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      width: "100%",
    }}
    >
      <div className="d-flex justify-content-between w-50">
        <Wrapper>
          {`Pagina ${page} de ${meta?.last_page ?? page} página(s)`}
          {/* Total de ${meta?.total || 0} registro(s). */}
        </Wrapper>
      </div>
      <ConteinerPagination>
        <ReactPaginate
          previousLabel="&laquo;"
          nextLabel="&raquo;"
          breakLabel="..."
          breakClassName="break-me"
          pageCount={meta?.last_page}
          marginPagesDisplayed={2}
          containerClassName="pagination"
        // subContainerClassName="pages pagination"
          forcePage={page - 1}
          activeClassName="active"
          onPageChange={(page) => setPage(page.selected + 1)}
        />
      </ConteinerPagination>
    </div>
  );
}

const Wrapper = styled.p`
  @media (max-width: 768px) {
    display: none;
  }
`;
