import React from 'react';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';
import { Loading } from './style';

type IProps= {
  size?: string | number
  color?: string
}

export function SpinnerLoading({ size, color }:IProps) {
  return (
    <Loading>
      <AiOutlineLoading3Quarters size={size} color={color} className="spinner" />
    </Loading>
  );
}
