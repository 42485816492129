import React, { useState } from 'react';
import {
  FieldValues, UseFormGetValues, UseFormRegister,
} from 'react-hook-form';

export type ArrayFormValues = {
  formValues: {
    label: string;
    type: "number" | "boolean" | "time"| "familiar" | "tradicao" | "moradia" | "zona" | "localViolento" | "tradicao" | "bebida" | "necessidadeEspecial" | "vulnerabilidadeSocial"|"beneficio"|"renda"|"financeiro"|"continuada"|"empregaticio"|"outros";
    name: string;
    onChange: (e: any) => void;
    onChangeEspecificacao?: (e: any) => void;
    onChangeEspecificacao2?: (e: any) => void;
    useComma?: boolean
    especificacao?: string;
    espeficacao2?: string
  }[]
  keysEspecificacao?: {[key: string]: string}
  register: UseFormRegister<FieldValues>
  getValues?: UseFormGetValues<any>
}

export function FormAcompanhamentos({
  formValues, register, keysEspecificacao, getValues,
}:ArrayFormValues) {
  const arrayNumber = Object.keys(Array(11).fill(null));
  const arrayBooelan = ["Sim", "Não"];
  const arrayTime = ["Lento", "Normal", "Rápido"];
  const arrayComposicaoFamiliar = ["01 a 02 membros", "03 a 05 membros", "acima de 5 membros"];
  const arrayReligiao = ["Católica", "Evangélica", "Espírita", "Umbanda", "Candomblé", "Outro"];
  const arraytipoMoradia = ["Própria", "Alugada", "Cedida", "Abrigo"];
  const arrayTipoZona = ["Urbana", "Rural"];
  const arrayLocalViolento = ["Assaltos", "Assasinatos", "Tráfico e uso de drogas", "Estupro", "Prostituição", "Outro"];
  const arrayBebida = ["Todos os dias", "uma vez por semana"];
  const arrayNecessidadeEspecial = ["Física", "Visual", "Mental", "Auditiva", "Outro"];
  const arrayRendaTrabalho = ["Formal", "Informal", "Outras rendas", "Não possuo"];
  const arrayAuxilioFinanceiro = ["Bolsa família", "Auxílio reclusão", "Renda cristã", "Benefício de prestação continuada", "Programa de erradicação do trabalho infantil e garantia safra", "Outro"];

  const [valor_beneficio_bolsa_familia, set_valor_beneficio_bolsa_familia] = useState(getValues ? getValues('valor_bolsa_familia') : "");
  const [valor_beneficio_continuo, set_valor_beneficio_continuo] = useState(getValues ? getValues('valor_beneficio_continuo') : "");

  console.log(valor_beneficio_bolsa_familia);

  const moneyMask = (value: string) => {
    value = value.replace('.', '').replace(',', '').replace(/\D/g, '');

    const options = { minimumFractionDigits: 2 };
    const result = new Intl.NumberFormat('pt-BR', options).format(
      parseFloat(value) / 100,
    );

    console.log(result);

    return `R$ ${result}`;
  };

  return (
    <div className="bg-light rounded p-4">
      {formValues.map((item, i) => (
        <div className="mb-4">
          {item.type === "number" && (
          <div>
            <p className="mb-2">{item.label}</p>
            <div className="d-flex">
              <p className="mr-4">Ruim</p>
              {arrayNumber.map((number) => (
                <>
                  <div className="d-flex flex-column mr-4">
                    <label htmlFor={`${item.name}-${number}`} style={{ cursor: "pointer" }} data-testid={`label-${number}`}>{number}</label>
                    <input
                      type="radio"
                      value={number}
                      {...register(item.name, { required: `${i + 1} - Preencha o item` })}
                      onChange={item.onChange}
                      id={`${item.name}-${number}`}
                    />
                  </div>
                </>
              ))}
              <p>Excelente</p>
            </div>
            {item.especificacao && (
            <div className="d-flex flex-column mt-4">
              <label htmlFor="">Especifique abaixo</label>
              <textarea
                className="w-75"
                {...register(item.especificacao, { required: `${i + 1} - Preencha a especificação do item` })}
                onChange={item.onChangeEspecificacao}
              />
            </div>
            )}
          </div>
          )}

          {item.type === "boolean" && (
          <div className="mb-4">
            <div>
              <p className="mb-2">{item.label}</p>
              <div className="d-flex">
                {arrayBooelan.map((boolean) => (
                  <div className=" d-flex mr-1 align-items-center">
                    <label htmlFor={`${item.name}-${boolean}`} style={{ cursor: "pointer" }} className="m-0 mr-1" data-testid={`label-${boolean}`}>{boolean}</label>
                    <input
                      className="m-1"
                      type="radio"
                      value={boolean}
                      {...register(item.name, { required: `${i + 1} - Preencha o item` })}
                      id={`${item.name}-${boolean}`}
                      data-testid={`input-${boolean}`}
                      onChange={item.onChange}
                    />

                  </div>
                ))}
              </div>
              {item.especificacao && (
              <div className="d-flex flex-column mt-4">
                {item?.useComma
                  ? <label htmlFor="">Caso sim, especifique (separe por virgula)</label>
                  : <label htmlFor="">Caso sim, especifique</label>}
                <textarea
                  className="w-75"
                  {...register(item.especificacao, {
                    required: (keysEspecificacao && keysEspecificacao[item.name] === "Sim")
                      ? `${i + 1} - Preencha a especificação do item`
                      : false,
                  })}
                  onChange={item.onChangeEspecificacao}
                />
              </div>
              )}
            </div>
          </div>
          )}

          {item.type === "familiar" && (
          <div className="mb-4">
            <div>
              <p className="mb-2">{item.label}</p>
              <div className="d-flex">
                {arrayComposicaoFamiliar.map((value) => (
                  <div className=" d-flex mr-1 align-items-center">
                    <label htmlFor={`${item.name}-${value}`} style={{ cursor: "pointer" }} className="m-0 mr-1" data-testid={`label-${value}`}>{value}</label>
                    <input
                      className="m-1"
                      type="radio"
                      value={value}
                      {...register(item.name, { required: `${i + 1} - Preencha o item` })}
                      id={`${item.name}-${value}`}
                      data-testid={`input-${value}`}
                      onChange={item.onChange}
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>
          )}

          {item.type === "tradicao" && (
          <div className="mb-4">
            <div>
              <p className="mb-2">{item.label}</p>
              <div className="d-flex">
                {arrayBooelan.map((value) => (
                  <div className=" d-flex mr-1 align-items-center">
                    <label htmlFor={`${item.name}-${value}`} style={{ cursor: "pointer" }} className="m-0 mr-1" data-testid={`label-${value}`}>{value}</label>
                    <input
                      className="m-1"
                      type="radio"
                      value={value}
                      {...register(item.name, { required: `${i + 1} - Preencha o item` })}
                      id={`${item.name}-${value}`}
                      data-testid={`input-${value}`}
                      onChange={item.onChange}
                    />
                  </div>
                ))}
              </div>
              {item.especificacao && (
                <fieldset disabled={(keysEspecificacao && keysEspecificacao[item.name] === "Não") || (keysEspecificacao && keysEspecificacao[item.name] === "")}>
                  <p className="mb-2 mt-4"><strong>Caso seja sim, escolha uma das opções:</strong></p>
                  <div className="d-flex">
                    {arrayReligiao.map((value) => (
                      <div className=" d-flex mr-1 align-items-center">
                        <label htmlFor={`${item.name}-${value}`} style={{ cursor: "pointer" }} className="m-0 mr-1" data-testid={`label-${value}`}>{value}</label>
                        <input
                          className="m-1"
                          type="radio"
                          value={value}
                          {...register(item.especificacao ? item.especificacao : "", { required: keysEspecificacao && keysEspecificacao[item.name] === "Sim" && `${i + 1} - Preencha o item de especificação` })}
                          id={`${item.name}-${value}`}
                          data-testid={`input-${value}`}
                          onChange={item.onChangeEspecificacao}
                        />
                      </div>
                    ))}
                    {/* <label htmlFor="">Outro</label>
                    <textarea
                      className="w-75"
                      {...register(item.especificacao)}
                      onChange={item.onChangeEspecificacao}
                    /> */}
                  </div>
                </fieldset>
              )}
            </div>
          </div>
          )}

          {item.type === "moradia" && (
          <div className="mb-4">
            <div>
              <p className="mb-2">{item.label}</p>
              <div className="d-flex">
                {arraytipoMoradia.map((value) => (
                  <div className=" d-flex mr-1 align-items-center">
                    <label htmlFor={`${item.name}-${value}`} style={{ cursor: "pointer" }} className="m-0 mr-1" data-testid={`label-${value}`}>{value}</label>
                    <input
                      className="m-1"
                      type="radio"
                      value={value}
                      {...register(item.name, { required: `${i + 1} - Preencha o item` })}
                      id={`${item.name}-${value}`}
                      data-testid={`input-${value}`}
                      onChange={item.onChange}
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>
          )}

          {item.type === "zona" && (
          <div className="mb-4">
            <div>
              <p className="mb-2">{item.label}</p>
              <div className="d-flex">
                {arrayTipoZona.map((value) => (
                  <div className=" d-flex mr-1 align-items-center">
                    <label htmlFor={`${item.name}-${value}`} style={{ cursor: "pointer" }} className="m-0 mr-1" data-testid={`label-${value}`}>{value}</label>
                    <input
                      className="m-1"
                      type="radio"
                      value={value}
                      {...register(item.name, { required: `${i + 1} - Preencha o item` })}
                      id={`${item.name}-${value}`}
                      data-testid={`input-${value}`}
                      onChange={item.onChange}
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>
          )}

          {item.type === "localViolento" && (
          <div className="mb-4">
            <div>
              <p className="mb-2">{item.label}</p>
              <div className="d-flex">
                {arrayBooelan.map((value) => (
                  <div className=" d-flex mr-1 align-items-center">
                    <label htmlFor={`${item.name}-${value}`} style={{ cursor: "pointer" }} className="m-0 mr-1" data-testid={`label-${value}`}>{value}</label>
                    <input
                      className="m-1"
                      type="radio"
                      value={value}
                      {...register(item.name, { required: `${i + 1} - Preencha o item` })}
                      id={`${item.name}-${value}`}
                      data-testid={`input-${value}`}
                      onChange={item.onChange}
                    />

                  </div>
                ))}
              </div>
              {item.especificacao && (
                <div>
                  <p className="mt-3 mb-2">Caso sim, por quê?</p>
                  <div className="d-flex">
                    {arrayLocalViolento.map((value) => (
                      <fieldset disabled={(keysEspecificacao && keysEspecificacao[item.name] === "Não") || (keysEspecificacao && keysEspecificacao[item.name] === "")} className=" d-flex mr-1 align-items-center">
                        <label htmlFor={`${item.especificacao}-${value}`} style={{ cursor: "pointer" }} className="m-0 mr-1" data-testid={`label-${value}`}>{value}</label>
                        <input
                          className="m-1"
                          type="radio"
                          value={value}
                          {...register(item.especificacao ? item.especificacao : "", { required: keysEspecificacao && keysEspecificacao[item.name] === "Sim" && `${i + 1} - Preencha o item de especificação` })}
                          id={`${item.especificacao}-${value}`}
                          data-testid={`input-${value}`}
                          onChange={item.onChangeEspecificacao}
                        />
                      </fieldset>
                    ))}
                    {/* <label htmlFor="">Outro:</label>
                <textarea
                  className="w-75"
                  {...register(item.especificacao)}
                  onChange={item.onChangeEspecificacao}
                /> */}
                  </div>
                </div>
              )}
            </div>
          </div>
          )}

          {item.type === "bebida" && (
          <div className="mb-4">
            <div>
              <p className="mb-2">{item.label}</p>
              <div className="d-flex">
                {arrayBooelan.map((value) => (
                  <div className=" d-flex mr-1 align-items-center">
                    <label htmlFor={`${item.name}-${value}`} style={{ cursor: "pointer" }} className="m-0 mr-1" data-testid={`label-${value}`}>{value}</label>
                    <input
                      className="m-1"
                      type="radio"
                      value={value}
                      {...register(item.name, { required: `${i + 1} - Preencha o item` })}
                      id={`${item.name}-${value}`}
                      data-testid={`input-${value}`}
                      onChange={item.onChange}
                    />

                  </div>
                ))}
              </div>
              {item.especificacao && (
                <div>
                  <p className="m-0"><strong>Caso sim, com que frequência?</strong></p>
                  <fieldset disabled={(keysEspecificacao && keysEspecificacao[item.name] === "Não") || (keysEspecificacao && keysEspecificacao[item.name] === "")} className="d-flex mt-2">
                    {arrayBebida.map((value) => (
                      <div className=" d-flex mr-1 align-items-center">
                        <label htmlFor={`${item.name}-${value}`} style={{ cursor: "pointer" }} className="m-0 mr-1" data-testid={`label-${value}`}>{value}</label>
                        <input
                          className="m-1"
                          type="radio"
                          value={value}
                          {...register(item.especificacao ? item.especificacao : "", { required: keysEspecificacao && keysEspecificacao[item.name] === "Sim" && `${i + 1} - Preencha o item de especificação` })}
                          id={`${item.name}-${value}`}
                          data-testid={`input-${value}`}
                          onChange={item.onChangeEspecificacao}
                        />
                      </div>
                    ))}
                  </fieldset>
                </div>
              )}
            </div>
          </div>
          )}

          {item.type === "necessidadeEspecial" && (
          <div className="mb-4">
            <div>
              <p className="mb-2">{item.label}</p>
              <div className="d-flex">
                {arrayBooelan.map((value) => (
                  <div className=" d-flex mr-1 align-items-center">
                    <label htmlFor={`${item.name}-${value}`} style={{ cursor: "pointer" }} className="m-0 mr-1" data-testid={`label-${value}`}>{value}</label>
                    <input
                      className="m-1"
                      type="radio"
                      value={value}
                      {...register(item.name, { required: `${i + 1} - Preencha o item` })}
                      id={`${item.name}-${value}`}
                      data-testid={`input-${value}`}
                      onChange={item.onChange}
                    />

                  </div>
                ))}
              </div>
              {item.especificacao && (
                <div>
                  <p className="mb-1 mt-3">Caso sim, especifique:</p>
                  <div className="d-flex flex-column">
                    {arrayNecessidadeEspecial.map((value) => (
                      <fieldset disabled={(keysEspecificacao && keysEspecificacao[item.name] === "Não") || (keysEspecificacao && keysEspecificacao[item.name] === "")} className=" d-flex mr-1 align-items-center">
                        <label htmlFor={`${item.especificacao}-${value}`} style={{ cursor: "pointer" }} className="m-0 mr-1" data-testid={`label-${value}`}>{value}</label>
                        <input
                          className="m-1"
                          type="radio"
                          value={value}
                          {...register(item.especificacao ? item.especificacao : "", { required: keysEspecificacao && keysEspecificacao[item.name] === "Sim" && `${i + 1} - Preencha o item de especificação` })}
                          id={`${item.especificacao}-${value}`}
                          data-testid={`input-${value}`}
                          onChange={item.onChangeEspecificacao}
                        />
                      </fieldset>
                    ))}
                    {/* <label htmlFor="">Outra:</label>
                    <textarea
                      className="w-75"
                      {...register(item.especificacao)}
                      onChange={item.onChangeEspecificacao}
                    /> */}
                  </div>
                </div>
              )}
            </div>
          </div>
          )}

          {item.type === "vulnerabilidadeSocial" && (
          <div className="mb-4">
            <div>
              <p className="mb-2">{item.label}</p>
              <div className="d-flex">
                {arrayBooelan.map((value) => (
                  <div className=" d-flex mr-1 align-items-center">
                    <label htmlFor={`${item.name}-${value}`} style={{ cursor: "pointer" }} className="m-0 mr-1" data-testid={`label-${value}`}>{value}</label>
                    <input
                      className="m-1"
                      type="radio"
                      value={value}
                      {...register(item.name, { required: `${i + 1} - Preencha o item` })}
                      id={`${item.name}-${value}`}
                      data-testid={`input-${value}`}
                      onChange={item.onChange}
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>
          )}

          {item.type === "renda" && (
          <div className="mb-4">
            <div>
              <p className="mb-2">{item.label}</p>
              <div className="d-flex">
                {arrayRendaTrabalho.map((value) => (
                  <div className=" d-flex mr-1 align-items-center">
                    <label htmlFor={`${item.name}-${value}`} style={{ cursor: "pointer" }} className="m-0 mr-1" data-testid={`label-${value}`}>{value}</label>
                    <input
                      className="m-1"
                      type="radio"
                      value={value}
                      {...register(item.name, { required: `${i + 1} - Preencha o item` })}
                      id={`${item.name}-${value}`}
                      data-testid={`input-${value}`}
                      onChange={item.onChange}
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>
          )}

          {item.type === "financeiro" && (
          <div className="mb-4">
            <div>
              <p className="mb-2">{item.label}</p>
              <div className="d-flex">
                {arrayBooelan.map((value) => (
                  <div className=" d-flex mr-1 align-items-center">
                    <label htmlFor={`${item.name}-${value}`} style={{ cursor: "pointer" }} className="m-0 mr-1" data-testid={`label-${value}`}>{value}</label>
                    <input
                      className="m-1"
                      type="radio"
                      value={value}
                      {...register(item.name, { required: `${i + 1} - Preencha o item` })}
                      id={`${item.name}-${value}`}
                      data-testid={`input-${value}`}
                      onChange={item.onChange}
                    />

                  </div>
                ))}
              </div>
              {item.especificacao && (
                <div>
                  <fieldset disabled={(keysEspecificacao && keysEspecificacao[item.name] === "Não") || (keysEspecificacao && keysEspecificacao[item.name] === "")} className="d-flex flex-column mt-4">
                    <p className="mb-2">Caso a resposta seja sim, especifique.</p>
                    {arrayAuxilioFinanceiro.map((value) => (
                      <div className=" d-flex mr-1 align-items-center">
                        <label htmlFor={`${item.especificacao}-${value}`} style={{ cursor: "pointer" }} className="m-0 mr-1" data-testid={`label-${value}`}>{value}</label>
                        <input
                          className="m-1"
                          type="radio"
                          value={value}
                          {...register(item.especificacao ? item.especificacao : '', { required: keysEspecificacao && keysEspecificacao[item.name] === "Sim" && `${i + 1} - Preencha o item de especificação` })}
                          id={`${item.especificacao}-${value}`}
                          data-testid={`input-${value}`}
                          onChange={item.onChangeEspecificacao}
                        />
                      </div>
                    ))}
                    {/* <label htmlFor="">Outro:</label>
                    <textarea
                      className="w-75"
                      {...register(item.especificacao)}
                      onChange={item.onChangeEspecificacao}
                    /> */}
                  </fieldset>
                </div>
              )}
            </div>
          </div>
          )}

          {item.type === "beneficio" && (
          <div className="mb-4">
            <div>
              <p className="mb-2">{item.label}</p>
              <div className="d-flex">
                {arrayBooelan.map((value) => (
                  <div className=" d-flex mr-1 align-items-center">
                    <label htmlFor={`${item.name}-${value}`} style={{ cursor: "pointer" }} className="m-0 mr-1" data-testid={`label-${value}`}>{value}</label>
                    <input
                      className="m-1"
                      type="radio"
                      value={value}
                      {...register(item.name, { required: `${i + 1} - Preencha o item` })}
                      id={`${item.name}-${value}`}
                      data-testid={`input-${value}`}
                      onChange={item.onChange}
                    />

                  </div>
                ))}
              </div>
              {item.especificacao && (
              <div className="d-flex align-items-center mt-4">
                <label htmlFor={`${item.name}-${i}-${item.especificacao}`} className="m-0 mr-1">Valor:</label>
                <input
                  id={`${item.name}-${i}-${item.especificacao}`}
                  className="w-75 form-control"
                  // @ts-ignore
                  value={valor_beneficio_bolsa_familia}
                  {...register(item.especificacao, { required: keysEspecificacao && keysEspecificacao[item.name] === "Sim" && `${i + 1} - Preencha o item de especificação` })}
                  onChange={(e) => {
                    if (item.onChangeEspecificacao) {
                      item.onChangeEspecificacao(e);
                    }
                    set_valor_beneficio_bolsa_familia(moneyMask(e.target.value));
                  }}
                  disabled={(keysEspecificacao && keysEspecificacao[item.name] === "Não") || (keysEspecificacao && keysEspecificacao[item.name] === "")}
                />
              </div>
              )}
              {item.espeficacao2 && (
                <div className="d-flex flex-column mt-4">
                  <label htmlFor={`${item.name}-${i}-${item.especificacao}`} className="m-0 mr-1">Caso seja mais de um membro, especifique o valor que cada um recebe</label>
                  <textarea
                    className="w-75 form-control"
                    id={`${item.name}-${i}-${item.espeficacao2}`}
                    {...register(item.espeficacao2)}
                    onChange={item.onChangeEspecificacao2}
                    disabled={(keysEspecificacao && keysEspecificacao[item.name] === "Não") || (keysEspecificacao && keysEspecificacao[item.name] === "")}
                  />
                </div>
              )}
            </div>
          </div>
          )}

          {item.type === "continuada" && (
          <div className="mb-4">
            <div>
              <p className="mb-2">{item.label}</p>
              <div className="d-flex">
                {arrayBooelan.map((value) => (
                  <div className=" d-flex mr-1 align-items-center">
                    <label htmlFor={`${item.name}-${value}`} style={{ cursor: "pointer" }} className="m-0 mr-1" data-testid={`label-${value}`}>{value}</label>
                    <input
                      className="m-1"
                      type="radio"
                      value={value}
                      {...register(item.name, { required: `${i + 1} - Preencha o item` })}
                      id={`${item.name}-${value}`}
                      data-testid={`input-${value}`}
                      onChange={item.onChange}
                    />

                  </div>
                ))}
              </div>
              {item.especificacao && (
              <div className="d-flex align-items-center mt-4">
                <label htmlFor={`${item.name}-${i}-${item.especificacao}`} className="mr-1">Valor:</label>
                <input
                  className="w-75 form-control "
                  id={`${item.name}-${i}-${item.especificacao}`}
                  value={valor_beneficio_continuo}
                  {...register(item.especificacao, { required: keysEspecificacao && keysEspecificacao[item.name] === "Sim" && `${i + 1} - Preencha o item de especificação` })}
                  onChange={(e) => {
                    if (item.onChangeEspecificacao) {
                      item.onChangeEspecificacao(e);
                    }
                    set_valor_beneficio_continuo(moneyMask(e.target.value));
                  }}
                  disabled={(keysEspecificacao && keysEspecificacao[item.name] === "Não") || (keysEspecificacao && keysEspecificacao[item.name] === "")}
                />
              </div>
              )}
              {item.espeficacao2 && (
                <div className="d-flex flex-column mt-4">
                  <label htmlFor={`${item.name}-${i}-${item.especificacao}`} className="m-0 mr-1">Caso seja mais de um membro, especifique o valor que cada um recebe</label>
                  <textarea
                    className="w-75 form-control"
                    id={`${item.name}-${i}-${item.espeficacao2}`}
                    {...register(item.espeficacao2)}
                    onChange={item.onChangeEspecificacao2}
                    disabled={(keysEspecificacao && keysEspecificacao[item.name] === "Não") || (keysEspecificacao && keysEspecificacao[item.name] === "")}
                  />
                </div>
              )}
            </div>
          </div>
          )}

          {item.type === "empregaticio" && (
          <div className="mb-4">
            <div>
              <p className="mb-2">{item.label}</p>
              <div className="d-flex">
                {arrayRendaTrabalho.map((value) => (
                  <div className=" d-flex mr-1 align-items-center">
                    <label htmlFor={`${item.name}-${value}`} style={{ cursor: "pointer" }} className="m-0 mr-1" data-testid={`label-${value}`}>{value}</label>
                    <input
                      className="m-1"
                      type="radio"
                      value={value}
                      {...register(item.name, { required: `${i + 1} - Preencha o item` })}
                      id={`${item.name}-${value}`}
                      data-testid={`input-${value}`}
                      onChange={item.onChange}
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>
          )}

          {item.type === "outros" && (
          <div className="mb-4">
            <div>
              <p className="mb-2">{item.label}</p>
              <div className="d-flex">
                {arrayRendaTrabalho.map((value) => (
                  <div className=" d-flex mr-1 align-items-center">
                    <label htmlFor={`${item.name}-${value}`} style={{ cursor: "pointer" }} className="m-0 mr-1" data-testid={`label-${value}`}>{value}</label>
                    <input
                      className="m-1"
                      type="radio"
                      value={value}
                      {...register(item.name, { required: `${i + 1} - Preencha o item` })}
                      id={`${item.name}-${value}`}
                      data-testid={`input-${value}`}
                      onChange={item.onChange}
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>
          )}

          {item.type === "time" && (
          <div className="mb-4">
            <p className="mb-2">{item.label}</p>
            <div className="d-flex">
              {arrayTime.map((time) => (
                <div className="d-flex mr-1 align-items-center">
                  <label htmlFor={`${item.name}-${time}`} className="m-0 mr-1" style={{ cursor: "pointer" }} data-testid={`label-${time}`}>{time}</label>
                  <input
                    className="m-1"
                    type="radio"
                    value={time}
                    {...register(item.name, { required: `${i + 1} - Preencha o item` })}
                    id={`${item.name}-${time}`}
                    onChange={item.onChange}
                  />
                </div>
              ))}
            </div>
          </div>
          )}
        </div>
      ))}
    </div>
  );
}
