import React from 'react';
import { FaEdit } from 'react-icons/fa';

type IconEditProps = {
  tooltipMessage?: string
  size?: number
  cursor?: boolean
}

export function IconEdit(props: IconEditProps) {
  const { cursor, size, tooltipMessage } = props;
  return (
    <FaEdit
      style={{ outline: 'none' }}
      data-tip={tooltipMessage}
      size={size || 20}
      cursor={cursor ? "pointer" : undefined}
    />
  );
}
