/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import api from '../../../../services/api';

export function useGetEgresses<T>() {
  const [egresses, setEgresses] = useState<T | null>();
  const [load, setLoad] = useState(false);

  async function handleSetEgresses(page = 1, habilidadeOrNameOrAreaOrBairroOrLocalidadeOrMunicipio?:string) {
    setLoad(true);
    const { data } = await api.get('/egresses', {
      params: {
        page,
        habilidadeOrNameOrAreaOrBairroOrLocalidadeOrMunicipio,
      },
    });
    setEgresses(data);
    setLoad(false);
  }

  useEffect(() => {
    handleSetEgresses();
  }, []);

  return { egresses, handleSetEgresses, load };
}
