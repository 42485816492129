/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import api from '../../../../services/api';

export function useGetAllRounds() {
  const [rounds, setUsers] = useState<any | null>(null);

  async function handleSetUsers(page = 1) {
    const { data } = await api.get("/rounds", {
      params: {
        perPage: 15,
        page,
        em_homologacao: "T",
      },
    });
    setUsers(data);
  }

  useEffect(() => {
    handleSetUsers();
  }, []);
  return { handleSetUsers, rounds };
}
