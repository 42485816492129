import { format } from 'date-fns';
import React, { useState } from 'react';
import ReactModal from 'react-modal';
import { TablePadrão, Td, Tr } from '../../../../components/develop-nilton-components/TablePadrão';
import { PropsModalDetalhesProjeto } from '../types';

export function ModalDetalhesProjeto({ projeto, isOpen, handleIsOpen }:PropsModalDetalhesProjeto) {
  const headWidth = ["5%", "50%", "50%"];
  const [isOpenModalMateriais, setIsOpenModalMateriais] = useState(false);

  function handleOpenModalMateriais() {
    handleIsOpen();
    setIsOpenModalMateriais((prev) => !prev);
  }
  return (
    <div>

      <ReactModal
        isOpen={isOpen}
        ariaHideApp={false}
        shouldCloseOnOverlayClick
        onRequestClose={handleIsOpen}
        style={{
          content: {
            width: "60%",
            margin: "0 auto",
          },
          overlay: {
            background: "rgba(0,0,0,.1)",
          },
        }}
      >
        <div>
          <div className="border-bottom mb-3">
            <h2>
              Detalhes do projeto
              {' '}
              {projeto.nome}
            </h2>
          </div>
          <p className="h5">
            <strong>Nome do projeto:</strong>
            {" "}
            {projeto.nome}
          </p>
          <p className="h5">
            <strong>Data inicial:</strong>
            {" "}
            {format(new Date(projeto.data_inicio), "dd/MM/yyyy")}
          </p>
          <p className="h5">
            <strong>Data final:</strong>
            {" "}
            {format(new Date(projeto.data_fim), "dd/MM/yyyy")}
          </p>
          <p className="h5">
            <strong>Parceiro:</strong>
            {" "}
            {projeto.parceiro}
          </p>
          <p className="h5">
            <strong>Objetivo:</strong>
            {" "}
            {projeto.objetivo}
          </p>
          <p className="h5">
            <strong>Origem do recurso:</strong>
            {" "}
            {projeto.origem_recurso}
          </p>
          <p className="h5">
            <strong>Valor envolvido:</strong>
            {" "}
            {projeto.valor_envolvido}
          </p>
          <p className="h5">
            <strong>Laboratório:</strong>
            {" "}
            {projeto.laboratorio}
          </p>
          <p className="h5">
            <strong>sala de aula:</strong>
            {" "}
            {projeto.sala_aula === "T" ? "Sim" : "Não"}
          </p>
          <p className="h5">
            <strong>Materiais:</strong>
            {" "}
            {projeto.materiais.length === 0 ? "Não há materiais" : (
              <span
                className="text-primary"
                style={{ cursor: "pointer" }}
                onClick={handleOpenModalMateriais}
              >
                (Ver materiais)
              </span>
            )}
          </p>
        </div>
      </ReactModal>
      <ReactModal
        isOpen={isOpenModalMateriais}
        onRequestClose={handleOpenModalMateriais}
        style={{
          content: {
            width: "50%",
            margin: "0 auto",
          },
          overlay: {
            background: "rgba(0,0,0,.1)",
          },
        }}
      >
        <TablePadrão thead={["#", "Nome do material", "Qtd"]} headWidth={headWidth}>
          {projeto.materiais.map((item:any, i:number) => (
            <Tr key={i}>
              <Td width={headWidth[0]}>{i + 1}</Td>
              <Td width={headWidth[1]}>{item.material.title}</Td>
              <Td width={headWidth[2]}>{item.qtd}</Td>
            </Tr>
          ))}
        </TablePadrão>
      </ReactModal>
    </div>
  );
}
