import React from 'react';

import { AuthProvider } from './auth';
import { EgressesProvider } from './Egresses';

const AppProvider: React.FC = ({ children }) => (
  <AuthProvider>
    <EgressesProvider>
      {children}
    </EgressesProvider>
  </AuthProvider>
);

export default AppProvider;
