import React, { useState } from 'react';
import { FaEdit } from 'react-icons/fa';

type PropsIconEdit = {
  tooltipMessage?: string
  size?: number
  cursor?: boolean
  color?: "text-primary" | "text-warning" | "text-danger"
  mouseOver?: boolean
}

export function IconEdit({
  tooltipMessage, size, cursor, color, mouseOver,
}:PropsIconEdit) {
  const [over, setOver] = useState(false);

  function handleSetOver() {
    setOver(!over);
  }

  return (
    <div
      data-tip={tooltipMessage || ""}
      onMouseEnter={mouseOver ? handleSetOver : undefined}
      onMouseLeave={mouseOver ? handleSetOver : undefined}
    >
      <FaEdit
        className={mouseOver ? (over ? color : undefined) : (color || undefined)}
        size={size || 25}
        cursor={cursor ? "pointer" : undefined}
      />
    </div>
  );
}
