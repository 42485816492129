import React, { useState } from 'react';
import { Chart } from 'react-chartjs-2';
import moment from 'moment';
import api from '../../../../../services/api';
import { IEgresses } from '../../../../../types/IEgresses';

type EgressesFormModal = {
  useStateEgresse: [IEgresses | null, (egresse:IEgresses | null) => void]
}

export default function Form2({ useStateEgresse }: EgressesFormModal) {
  const [dataPsicologico, setDataPsicologico] = useState(null);
  const [tabPsicologico, setTabPsicologico] = useState(1);

  const [dataPsicopedagogo, setDataPsicopedagogo] = useState(null);
  const [tabPsicopedagogo, setTabPsicopedagogo] = useState(1);

  const [dataSocial, setDataSocial] = useState(null);
  const [tabSocial, setTabSocial] = useState(1);

  const [egresse, setEgresse] = useStateEgresse;

  async function getGraphPsicologico() {
    if (egresse) {
      if (tabPsicologico < 3) {
        api.get('reports/media_de_acompanhamento_psicologico_egresso', {
          params: {
            egress_id: egresse.id,
            type: tabPsicologico === 1 ? 'comportamento' : 'concentracao',
          },
        }).then(({ data }) => {
          setDataPsicologico(data);
        });
      } else {
        api.get('reports/acompanhamento_psicologico_anual_egresso', {
          params: {
            egress_id: egresse.id,
            year: new Date().getFullYear(),
            type: tabPsicologico === 3 ? 'queixas' : 'observacoes',
          },
        }).then(({ data }) => {
          setDataPsicologico(data);
        });
      }
    }
  }

  React.useEffect(() => {
    getGraphPsicologico();
  }, [tabPsicologico]);

  async function getGraphPsicopedagogo() {
    if (egresse) {
      if (tabPsicopedagogo < 3) {
        api.get('reports/media_de_acompanhamento_psicopedagico_egresso', {
          params: {
            egress_id: egresse.id,
            type: tabPsicopedagogo === 1 ? 'intelectual' : 'emocional',
          },
        }).then(({ data }) => {
          setDataPsicopedagogo(data);
        });
      } else if (tabPsicopedagogo > 3) {
        api.get('reports/acompanhamento_psicopedagico_anual_egresso', {
          params: {
            egress_id: egresse.id,
            year: new Date().getFullYear(),
            type: tabPsicopedagogo === 4 ? 'queixas' : 'observacoes',
          },
        }).then(({ data }) => {
          setDataPsicopedagogo(data);
        });
      }
    }
  }

  React.useEffect(() => {
    getGraphPsicopedagogo();
  }, [tabPsicopedagogo]);

  async function getGraphSocial() {
    if (egresse) {
      if (tabSocial > 2) {
        api.get('social/acompanhamento_social_anual_egresso', {
          params: {
            egress_id: egresse.id,
            year: new Date().getFullYear(),
            type: tabSocial === 3 ? 'queixas' : 'observacoes',
          },
        }).then(({ data }) => {
          setDataSocial(data);
        });
      }
    }
  }

  React.useEffect(() => {
    getGraphSocial();
  }, [tabSocial]);

  return (
    <>
      <div style={{
        display: "flex", marginTop: 20, justifyContent: 'space-between', flexDirection: "column", width: '100%', background: '#f6f6f6',
      }}
      >
        <div style={{ display: "flex", alignItems: 'center', justifyContent: "space-between" }}>
          <h1 style={{
            margin: 10, fontSize: 16, fontWeight: 'bold',
          }}
          >
            Acompanhamento Psicológico
          </h1>
        </div>

        <div style={{
          width: '100%', borderBottom: 2, borderBottomStyle: 'solid', borderBottomColor: '#1B7E9F',
        }}
        />
      </div>

      <div style={{
        marginTop: 20, marginBottom: 20, display: 'flex', justifyContent: "center", alignItems: "center",
      }}
      >
        <div
          onClick={() => setTabPsicologico(1)}
          style={{
            display: 'flex', borderRadius: 5, height: 40, flexDirection: "column", justifyContent: 'space-between', borderStyle: 'solid', width: 200, marginRight: 5, cursor: 'pointer', borderColor: '#ccc', borderWidth: 1,
          }}
        >
          <div style={{
            display: 'flex', padding: 10, justifyContent: 'center', alignItems: "center", height: tabPsicologico == 1 ? 35 : 40,
          }}
          >
            <h1 style={{ fontSize: 14, marginBottom: 0, marginLeft: 5 }}>Comportamento</h1>
          </div>
          {tabPsicologico == 1 && (
          <div style={{
            position: 'relative', bottom: 0, borderEndStartRadius: 5, borderEndEndRadius: 5, background: 'rgba(0,62,93)', width: '100%', height: 5,
          }}
          />
          )}
        </div>

        <div
          onClick={() => setTabPsicologico(2)}
          style={{
            display: 'flex', borderRadius: 5, height: 40, flexDirection: "column", justifyContent: 'space-between', borderStyle: 'solid', width: 200, marginRight: 5, cursor: 'pointer', borderColor: '#ccc', borderWidth: 1,
          }}
        >
          <div style={{
            display: 'flex', padding: 10, justifyContent: 'center', alignItems: "center", height: tabPsicologico == 2 ? 35 : 40,
          }}
          >
            <h1 style={{ fontSize: 14, marginBottom: 0, marginLeft: 5 }}>Concentração</h1>
          </div>
          {tabPsicologico == 2 && (
          <div style={{
            position: 'relative', bottom: 0, borderEndStartRadius: 5, borderEndEndRadius: 5, background: 'rgba(0,62,93)', width: '100%', height: 5,
          }}
          />
          )}
        </div>

        <div
          onClick={() => setTabPsicologico(3)}
          style={{
            display: 'flex', borderRadius: 5, height: 40, flexDirection: "column", justifyContent: 'space-between', borderStyle: 'solid', width: 200, marginRight: 5, cursor: 'pointer', borderColor: '#ccc', borderWidth: 1,
          }}
        >
          <div style={{
            display: 'flex', padding: 10, justifyContent: 'center', alignItems: "center", height: tabPsicologico == 3 ? 35 : 40,
          }}
          >
            <h1 style={{ fontSize: 14, marginBottom: 0, marginLeft: 5 }}>Queixas</h1>
          </div>
          {tabPsicologico == 3 && (
          <div style={{
            position: 'relative', bottom: 0, borderEndStartRadius: 5, borderEndEndRadius: 5, background: 'rgba(0,62,93)', width: '100%', height: 5,
          }}
          />
          )}
        </div>

        <div
          onClick={() => setTabPsicologico(4)}
          style={{
            display: 'flex', borderRadius: 5, height: 40, flexDirection: "column", justifyContent: 'space-between', borderStyle: 'solid', width: 200, marginRight: 5, cursor: 'pointer', borderColor: '#ccc', borderWidth: 1,
          }}
        >
          <div style={{
            display: 'flex', padding: 10, justifyContent: 'center', alignItems: "center", height: tabPsicologico == 3 ? 35 : 40,
          }}
          >
            <h1 style={{ fontSize: 14, marginBottom: 0, marginLeft: 5 }}>Observações</h1>
          </div>
          {tabPsicologico == 4 && (
          <div style={{
            position: 'relative', bottom: 0, borderEndStartRadius: 5, borderEndEndRadius: 5, background: 'rgba(0,62,93)', width: '100%', height: 5,
          }}
          />
          )}
        </div>
      </div>

      {(tabPsicologico === 1 || tabPsicologico === 2) && dataPsicologico && (
      <div style={{
        width: '100%',
      }}
      >
        <Chart
          // @ts-ignore
          data={dataPsicologico}
          type="bar"
          /* options={{
            plugins: {
              legend: { display: false },
            },
            scales: {
              y: {
                suggestedMin: 0,
                suggestedMax: 10,
              },
            },
          }} */
        />
      </div>
      )}

      {(tabPsicologico === 3 || tabPsicologico === 4) && dataPsicologico && (
      <div style={{
        width: '100%',
      }}
      >
        <Chart
          data={dataPsicologico}
          type="line"
          /* options={{
            elements: { point: { pointStyle: "rectRot" } },
            plugins: {
              legend: {
                position: 'bottom',
                labels: { usePointStyle: true, pointStyle: "line" },
              },
            },
            scales: {
              y: {
                suggestedMin: 0,
                suggestedMax: 10,
              },
            },
          }} */
        />
      </div>
      )}

      <div style={{
        display: "flex", marginTop: 20, justifyContent: 'space-between', flexDirection: "column", width: '100%', background: '#f6f6f6',
      }}
      >
        <div style={{ display: "flex", alignItems: 'center', justifyContent: "space-between" }}>
          <h1 style={{
            margin: 10, fontSize: 16, fontWeight: 'bold',
          }}
          >
            Acompanhamento Psicopedagógico
          </h1>
        </div>

        <div style={{
          width: '100%', borderBottom: 2, borderBottomStyle: 'solid', borderBottomColor: '#1B7E9F',
        }}
        />
      </div>

      <div style={{
        marginTop: 20, marginBottom: 20, display: 'flex', justifyContent: "center", alignItems: "center",
      }}
      >
        <div
          onClick={() => setTabPsicopedagogo(1)}
          style={{
            display: 'flex', borderRadius: 5, height: 40, flexDirection: "column", justifyContent: 'space-between', borderStyle: 'solid', width: 200, marginRight: 5, cursor: 'pointer', borderColor: '#ccc', borderWidth: 1,
          }}
        >
          <div style={{
            display: 'flex', padding: 10, justifyContent: 'center', alignItems: "center", height: tabPsicopedagogo == 1 ? 35 : 40,
          }}
          >
            <h1 style={{ fontSize: 14, marginBottom: 0, marginLeft: 5 }}>Fatores Intelectuais</h1>
          </div>
          {tabPsicopedagogo == 1 && (
          <div style={{
            position: 'relative', bottom: 0, borderEndStartRadius: 5, borderEndEndRadius: 5, background: 'rgba(0,62,93)', width: '100%', height: 5,
          }}
          />
          )}
        </div>

        <div
          onClick={() => setTabPsicopedagogo(2)}
          style={{
            display: 'flex', borderRadius: 5, height: 40, flexDirection: "column", justifyContent: 'space-between', borderStyle: 'solid', width: 200, marginRight: 5, cursor: 'pointer', borderColor: '#ccc', borderWidth: 1,
          }}
        >
          <div style={{
            display: 'flex', padding: 10, justifyContent: 'center', alignItems: "center", height: tabPsicopedagogo == 2 ? 35 : 40,
          }}
          >
            <h1 style={{ fontSize: 14, marginBottom: 0, marginLeft: 5 }}>Fatores Emocionais</h1>
          </div>
          {tabPsicopedagogo == 2 && (
          <div style={{
            position: 'relative', bottom: 0, borderEndStartRadius: 5, borderEndEndRadius: 5, background: 'rgba(0,62,93)', width: '100%', height: 5,
          }}
          />
          )}
        </div>

        <div
          onClick={() => setTabPsicopedagogo(3)}
          style={{
            display: 'flex', borderRadius: 5, height: 40, flexDirection: "column", justifyContent: 'space-between', borderStyle: 'solid', width: 200, marginRight: 5, cursor: 'pointer', borderColor: '#ccc', borderWidth: 1,
          }}
        >
          <div style={{
            display: 'flex', padding: 10, justifyContent: 'center', alignItems: "center", height: tabPsicopedagogo == 3 ? 35 : 40,
          }}
          >
            <h1 style={{ fontSize: 14, marginBottom: 0, marginLeft: 5 }}>Fatores Físicos</h1>
          </div>
          {tabPsicopedagogo == 3 && (
          <div style={{
            position: 'relative', bottom: 0, borderEndStartRadius: 5, borderEndEndRadius: 5, background: 'rgba(0,62,93)', width: '100%', height: 5,
          }}
          />
          )}
        </div>

        <div
          onClick={() => setTabPsicopedagogo(4)}
          style={{
            display: 'flex', borderRadius: 5, height: 40, flexDirection: "column", justifyContent: 'space-between', borderStyle: 'solid', width: 200, marginRight: 5, cursor: 'pointer', borderColor: '#ccc', borderWidth: 1,
          }}
        >
          <div style={{
            display: 'flex', padding: 10, justifyContent: 'center', alignItems: "center", height: tabPsicopedagogo == 3 ? 35 : 40,
          }}
          >
            <h1 style={{ fontSize: 14, marginBottom: 0, marginLeft: 5 }}>Queixas</h1>
          </div>
          {tabPsicopedagogo == 4 && (
          <div style={{
            position: 'relative', bottom: 0, borderEndStartRadius: 5, borderEndEndRadius: 5, background: 'rgba(0,62,93)', width: '100%', height: 5,
          }}
          />
          )}
        </div>

        <div
          onClick={() => setTabPsicopedagogo(5)}
          style={{
            display: 'flex', borderRadius: 5, height: 40, flexDirection: "column", justifyContent: 'space-between', borderStyle: 'solid', width: 200, marginRight: 5, cursor: 'pointer', borderColor: '#ccc', borderWidth: 1,
          }}
        >
          <div style={{
            display: 'flex', padding: 10, justifyContent: 'center', alignItems: "center", height: tabPsicopedagogo == 3 ? 35 : 40,
          }}
          >
            <h1 style={{ fontSize: 14, marginBottom: 0, marginLeft: 5 }}>Observações</h1>
          </div>
          {tabPsicopedagogo == 5 && (
          <div style={{
            position: 'relative', bottom: 0, borderEndStartRadius: 5, borderEndEndRadius: 5, background: 'rgba(0,62,93)', width: '100%', height: 5,
          }}
          />
          )}
        </div>
      </div>

      {(tabPsicopedagogo === 1 || tabPsicopedagogo === 2) && dataPsicopedagogo && (
      <div style={{
        width: '100%',
      }}
      >
        <Chart
          // @ts-ignore
          data={dataPsicopedagogo}
          type="bar"
          /* options={{
            plugins: {
              legend: { display: false },
            },
            scales: {
              y: {
                suggestedMin: 0,
                suggestedMax: 10,
              },
            },
          }} */
        />
      </div>
      )}

      {(tabPsicopedagogo === 3) && (
      <>
        {egresse?.acompanhamentos_psicopedagogicos?.concluidos.map((item) => (
          <div style={{
            background: "#f6f6f6", marginBottom: 20, borderRadius: 5, padding: 10,
          }}
          >
            <h1 style={{ fontSize: 16, marginBottom: 20 }}>{`Data de Finalização: ${moment(item.data_finalizacao).format("DD/MM/YYYY")}`}</h1>
            <div style={{ marginBottom: 10 }}>
              <h1 style={{ fontSize: 16 }}>1. Já fez um tratamento específico?</h1>
              <h1 style={{ fontSize: 14, fontWeight: 'normal' }}>
                R:
                {' '}
                {item.tratamento_especifico || 'Não respondido'}
              </h1>
              {item.especificacao_tratamento_especifico && (
              <h1 style={{ fontSize: 14, fontWeight: 'normal' }}>
                Especificação:
                {' '}
                {item.especificacao_tratamento_especifico}
              </h1>
              )}
            </div>

            <div style={{ marginBottom: 10 }}>
              <h1 style={{ fontSize: 16 }}>2. Toma algum medicamento no horário da aula?</h1>
              <h1 style={{ fontSize: 14, fontWeight: 'normal' }}>
                R:
                {' '}
                {item.toma_medicamento_em_aula || 'Não respondido'}
              </h1>
              {item.especificacao_toma_medicamento_em_aula && (
              <h1 style={{ fontSize: 14, fontWeight: 'normal' }}>
                Especificação:
                {' '}
                {item.especificacao_toma_medicamento_em_aula}
              </h1>
              )}
            </div>

            <div style={{ marginBottom: 10 }}>
              <h1 style={{ fontSize: 16 }}>3. Aproxima muito os olhos para fazer leitura?</h1>
              <h1 style={{ fontSize: 14, fontWeight: 'normal' }}>
                R:
                {' '}
                {item.aproxima_olhos_para_leitura || 'Não respondido'}
              </h1>
            </div>

            <div style={{ marginBottom: 10 }}>
              <h1 style={{ fontSize: 16 }}>4. Usa óculos? </h1>
              <h1 style={{ fontSize: 14, fontWeight: 'normal' }}>
                R:
                {' '}
                {item.usa_oculos || 'Não respondido'}
              </h1>
            </div>
          </div>
        ))}
      </>
      )}

      {(tabPsicopedagogo === 4 || tabPsicopedagogo === 5) && dataPsicopedagogo && (
      <div style={{
        width: '100%',
      }}
      >
        <Chart
          data={dataPsicopedagogo}
          type="line"
          /* options={{
            elements: { point: { pointStyle: "rectRot" } },
            plugins: {
              legend: {
                position: 'bottom',
                labels: { usePointStyle: true, pointStyle: "line" },
              },
            },
            scales: {
              y: {
                suggestedMin: 0,
                suggestedMax: 10,
              },
            },
          }} */
        />
      </div>
      )}

      <div style={{
        display: "flex", marginTop: 20, justifyContent: 'space-between', flexDirection: "column", width: '100%', background: '#f6f6f6',
      }}
      >
        <div style={{ display: "flex", alignItems: 'center', justifyContent: "space-between" }}>
          <h1 style={{
            margin: 10, fontSize: 16, fontWeight: 'bold',
          }}
          >
            Acompanhamento Social
          </h1>
        </div>

        <div style={{
          width: '100%', borderBottom: 2, borderBottomStyle: 'solid', borderBottomColor: '#1B7E9F',
        }}
        />
      </div>

      <div style={{
        marginTop: 20, marginBottom: 20, display: 'flex', justifyContent: "center", alignItems: "center",
      }}
      >
        <div
          onClick={() => setTabSocial(1)}
          style={{
            display: 'flex', borderRadius: 5, height: 40, flexDirection: "column", justifyContent: 'space-between', borderStyle: 'solid', width: 200, marginRight: 5, cursor: 'pointer', borderColor: '#ccc', borderWidth: 1,
          }}
        >
          <div style={{
            display: 'flex', padding: 10, justifyContent: 'center', alignItems: "center", height: tabSocial == 1 ? 35 : 40,
          }}
          >
            <h1 style={{ fontSize: 14, marginBottom: 0, marginLeft: 5 }}>Aspectos Sociais</h1>
          </div>
          {tabSocial == 1 && (
          <div style={{
            position: 'relative', bottom: 0, borderEndStartRadius: 5, borderEndEndRadius: 5, background: 'rgba(0,62,93)', width: '100%', height: 5,
          }}
          />
          )}
        </div>

        <div
          onClick={() => setTabSocial(2)}
          style={{
            display: 'flex', borderRadius: 5, height: 40, flexDirection: "column", justifyContent: 'space-between', borderStyle: 'solid', width: 200, marginRight: 5, cursor: 'pointer', borderColor: '#ccc', borderWidth: 1,
          }}
        >
          <div style={{
            display: 'flex', padding: 10, justifyContent: 'center', alignItems: "center", height: tabSocial == 2 ? 35 : 40,
          }}
          >
            <h1 style={{ fontSize: 14, marginBottom: 0, marginLeft: 5 }}>Socio-ecocômicos</h1>
          </div>
          {tabSocial == 2 && (
          <div style={{
            position: 'relative', bottom: 0, borderEndStartRadius: 5, borderEndEndRadius: 5, background: 'rgba(0,62,93)', width: '100%', height: 5,
          }}
          />
          )}
        </div>

        <div
          onClick={() => setTabSocial(3)}
          style={{
            display: 'flex', borderRadius: 5, height: 40, flexDirection: "column", justifyContent: 'space-between', borderStyle: 'solid', width: 200, marginRight: 5, cursor: 'pointer', borderColor: '#ccc', borderWidth: 1,
          }}
        >
          <div style={{
            display: 'flex', padding: 10, justifyContent: 'center', alignItems: "center", height: tabSocial == 3 ? 35 : 40,
          }}
          >
            <h1 style={{ fontSize: 14, marginBottom: 0, marginLeft: 5 }}>Queixas</h1>
          </div>
          {tabSocial == 3 && (
          <div style={{
            position: 'relative', bottom: 0, borderEndStartRadius: 5, borderEndEndRadius: 5, background: 'rgba(0,62,93)', width: '100%', height: 5,
          }}
          />
          )}
        </div>

        <div
          onClick={() => setTabSocial(4)}
          style={{
            display: 'flex', borderRadius: 5, height: 40, flexDirection: "column", justifyContent: 'space-between', borderStyle: 'solid', width: 200, marginRight: 5, cursor: 'pointer', borderColor: '#ccc', borderWidth: 1,
          }}
        >
          <div style={{
            display: 'flex', padding: 10, justifyContent: 'center', alignItems: "center", height: tabSocial == 3 ? 35 : 40,
          }}
          >
            <h1 style={{ fontSize: 14, marginBottom: 0, marginLeft: 5 }}>Observações</h1>
          </div>
          {tabSocial == 4 && (
          <div style={{
            position: 'relative', bottom: 0, borderEndStartRadius: 5, borderEndEndRadius: 5, background: 'rgba(0,62,93)', width: '100%', height: 5,
          }}
          />
          )}
        </div>
      </div>

      {(tabSocial === 1) && (
      <>
        {egresse?.acompanhamentos_sociais?.concluidos.map((item) => (
          <div style={{
            background: "#f6f6f6", marginBottom: 20, borderRadius: 5, padding: 10,
          }}
          >
            <h1 style={{ fontSize: 16, marginBottom: 20 }}>{`Data de Finalização: ${moment(item.data_finalizacao).format("DD/MM/YYYY")}`}</h1>
            <div style={{ marginBottom: 10 }}>
              <h1 style={{ fontSize: 16 }}>1. Qual a composição do núcleo familiar?</h1>
              <h1 style={{ fontSize: 14, fontWeight: 'normal' }}>
                R:
                {' '}
                {item.composicao_nucleo_familiar || 'Não respondido'}
              </h1>
            </div>

            <div style={{ marginBottom: 10 }}>
              <h1 style={{ fontSize: 16 }}>2. Identifica-se com alguma tradição religiosa?</h1>
              <h1 style={{ fontSize: 14, fontWeight: 'normal' }}>
                R:
                {' '}
                {item.tradicao_religiosa || 'Não respondido'}
              </h1>
              {item.especificacao_tradicao_religiosa && (
              <h1 style={{ fontSize: 14, fontWeight: 'normal' }}>
                Especificação:
                {' '}
                {item.especificacao_tradicao_religiosa}
              </h1>
              )}
            </div>

            <div style={{ marginBottom: 10 }}>
              <h1 style={{ fontSize: 16 }}>3. Tipo de moradia?</h1>
              <h1 style={{ fontSize: 14, fontWeight: 'normal' }}>
                R:
                {' '}
                {item.tipo_moradia || 'Não respondido'}
              </h1>
            </div>

            <div style={{ marginBottom: 10 }}>
              <h1 style={{ fontSize: 16 }}>4. Tipo de zona?</h1>
              <h1 style={{ fontSize: 14, fontWeight: 'normal' }}>
                R:
                {' '}
                {item.tipo_zona || 'Não respondido'}
              </h1>
            </div>

            <div style={{ marginBottom: 10 }}>
              <h1 style={{ fontSize: 16 }}>5. Considera o local que mora violento? Por que?</h1>
              <h1 style={{ fontSize: 14, fontWeight: 'normal' }}>
                R:
                {' '}
                {item.local_violento || 'Não respondido'}
              </h1>
              {item.especificacao_local_violento && (
              <h1 style={{ fontSize: 14, fontWeight: 'normal' }}>
                Especificação:
                {' '}
                {item.especificacao_local_violento}
              </h1>
              )}
            </div>

            <div style={{ marginBottom: 10 }}>
              <h1 style={{ fontSize: 16 }}>6. Faz uso de bebida alcoolica?</h1>
              <h1 style={{ fontSize: 14, fontWeight: 'normal' }}>
                R:
                {' '}
                {item.uso_bebida || 'Não respondido'}
              </h1>
              {item.frequencia_uso_bebida && (
              <h1 style={{ fontSize: 14, fontWeight: 'normal' }}>
                Frequência:
                {' '}
                {item.frequencia_uso_bebida}
              </h1>
              )}
            </div>

            <div style={{ marginBottom: 10 }}>
              <h1 style={{ fontSize: 16 }}>7. Apresenta alguma deficiencia ou necessidade educional especial?</h1>
              <h1 style={{ fontSize: 14, fontWeight: 'normal' }}>
                R:
                {' '}
                {item.deficiencia_ou_necessidade || 'Não respondido'}
              </h1>
              {item.especificacao_deficiencia_ou_necessidade && (
              <h1 style={{ fontSize: 14, fontWeight: 'normal' }}>
                Especificação:
                {' '}
                {item.especificacao_deficiencia_ou_necessidade}
              </h1>
              )}
            </div>

            <div style={{ marginBottom: 10 }}>
              <h1 style={{ fontSize: 16 }}>8. Possui algum membro da família em vulnerabilidade social?</h1>
              <h1 style={{ fontSize: 14, fontWeight: 'normal' }}>
                R:
                {' '}
                {item.membro_com_vulnerabilidade || 'Não respondido'}
              </h1>
            </div>
          </div>
        ))}
      </>
      )}

      {(tabSocial === 2) && (
      <>
        {egresse?.acompanhamentos_sociais?.concluidos.map((item) => (
          <div style={{
            background: "#f6f6f6", marginBottom: 20, borderRadius: 5, padding: 10,
          }}
          >
            <h1 style={{ fontSize: 16, marginBottom: 20 }}>{`Data de Finalização: ${moment(item.data_finalizacao).format("DD/MM/YYYY")}`}</h1>
            <div style={{ marginBottom: 10 }}>
              <h1 style={{ fontSize: 16 }}>1. Renda de trabalho?</h1>
              <h1 style={{ fontSize: 14, fontWeight: 'normal' }}>
                R:
                {' '}
                {item.renda_trabalho || 'Não respondido'}
              </h1>
            </div>

            <div style={{ marginBottom: 10 }}>
              <h1 style={{ fontSize: 16 }}>2. Participa de algum programa de auxílio financeiro?</h1>
              <h1 style={{ fontSize: 14, fontWeight: 'normal' }}>
                R:
                {' '}
                {item.participa_programa_auxilio_financeiro || 'Não respondido'}
              </h1>
              {item.especificacao_participa_programa_auxilio_financeiro && (
              <h1 style={{ fontSize: 14, fontWeight: 'normal' }}>
                Especificação:
                {' '}
                {item.especificacao_participa_programa_auxilio_financeiro}
              </h1>
              )}
            </div>

            <div style={{ marginBottom: 10 }}>
              <h1 style={{ fontSize: 16 }}>3. Possui membro (s) da família que possui benefício do bolsa família?</h1>
              <h1 style={{ fontSize: 14, fontWeight: 'normal' }}>
                R:
                {' '}
                {item.possui_membro_bolsa_familia || 'Não respondido'}
              </h1>
              {item.valor_bolsa_familia && (
              <h1 style={{ fontSize: 14, fontWeight: 'normal' }}>
                Valor:
                {' '}
                {item.valor_bolsa_familia}
              </h1>
              )}
              {item.valor_outros_membros_bolsa_familia && (
              <h1 style={{ fontSize: 14, fontWeight: 'normal' }}>
                Valor de outros membros:
                {' '}
                {item.valor_outros_membros_bolsa_familia}
              </h1>
              )}
            </div>

            <div style={{ marginBottom: 10 }}>
              <h1 style={{ fontSize: 16 }}>4. Possui membro (s) da família que possui benefício de prestação continuada?</h1>
              <h1 style={{ fontSize: 14, fontWeight: 'normal' }}>
                R:
                {' '}
                {item.possui_membro_beneficio_continuo || 'Não respondido'}
              </h1>
              {item.valor_beneficio_continuo && (
              <h1 style={{ fontSize: 14, fontWeight: 'normal' }}>
                Valor:
                {' '}
                {item.valor_beneficio_continuo}
              </h1>
              )}
              {item.valor_outros_membros_beneficio_continuo && (
              <h1 style={{ fontSize: 14, fontWeight: 'normal' }}>
                Valor de outros membros:
                {' '}
                {item.valor_outros_membros_beneficio_continuo}
              </h1>
              )}
            </div>

            <div style={{ marginBottom: 10 }}>
              <h1 style={{ fontSize: 16 }}>
                5. Possui membro (s) da família que possui vínculo empregatício (federal, estadual ou municipal e/ou
                contrato de trabalho na inicitiva privada) ?
              </h1>
              <h1 style={{ fontSize: 14, fontWeight: 'normal' }}>
                R:
                {' '}
                {item.possui_membro_vinculo_empregaticio || 'Não respondido'}
              </h1>
            </div>

            <div style={{ marginBottom: 10 }}>
              <h1 style={{ fontSize: 16 }}>
                6. Possui membro (s) da família são beneficiários de aposentadoria, auxílio doença, seguro desemprego e
                pensão previdenciária?
              </h1>
              <h1 style={{ fontSize: 14, fontWeight: 'normal' }}>
                R:
                {' '}
                {item.possui_membro_aposentadoria_outros || 'Não respondido'}
              </h1>
            </div>
          </div>
        ))}
      </>
      )}

      {(tabSocial === 3 || tabSocial === 4) && dataSocial && (
      <div style={{
        width: '100%',
      }}
      >
        <Chart
          data={dataSocial}
          type="line"
          /* options={{
            elements: { point: { pointStyle: "rectRot" } },
            plugins: {
              legend: {
                position: 'bottom',
                labels: { usePointStyle: true, pointStyle: "line" },
              },
            },
            scales: {
              y: {
                suggestedMin: 0,
                suggestedMax: 10,
              },
            },
          }} */
        />
      </div>
      )}
    </>
  );
}
