/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from 'react';
import styled from 'styled-components';
import { SpinnerLoading } from '../SpinnerLoading';

type PropsSearchField = {
  // eslint-disable-next-line no-unused-vars
  handleSetSearch(page?: number, search?: string): Promise<void>
  option: any[] | undefined | null
  keyNameOptions: string
  stateValue: [string, React.Dispatch<React.SetStateAction<string>>]
  describe?: string
  label?:string
  placeholder?: string
}

export function SearchField({
  handleSetSearch, option, stateValue, describe, label, placeholder, keyNameOptions,
}: PropsSearchField) {
  const [inputValue, setInputValue] = stateValue;
  const [focus, setFocus] = useState(false);
  const optionsFilter = option
  && option.filter((item: { [x: string]: string; }) => item[keyNameOptions].toLowerCase().includes(inputValue.toLowerCase()));

  function handleInputValue(e: any) {
    setInputValue(e.target.value);
    // if (e.target.value === "") {
    //   return setFocus(false);
    // }
    return setFocus(true);
  }

  function handleFocus() {
    // if (inputValue === "") {
    //   return setFocus(false);
    // }
    return setFocus(true);
  }

  function handleOnblur() {
    setTimeout(() => {
      setFocus(false);
    }, 150);
  }

  function handleKey(e: any) {
    if (e.key === "Enter") {
      handleSetSearch(1, inputValue);
      handleOnblur();
    }
  }

  return (
    <div style={{ width: "100%" }}>
      <Label htmlFor="search">{label}</Label>
      <WrapperInputAndButton>
        <div style={{ width: "100%", position: "relative" }}>
          <input
            type="text"
            className="form-control form-control-lg"
            onChange={handleInputValue}
            onFocus={handleFocus}
            onBlur={handleOnblur}
            value={inputValue}
            onKeyUp={handleKey}
            placeholder={placeholder || undefined}
            name="search"
            autoComplete="off"
          />
          {focus && (
          <ContainerOptions>
            {!option
            && (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <SpinnerLoading />
            </div>
            )}
            {
              optionsFilter?.map((item: { [x: string]: string }, i: React.Key | null | undefined) => (
                <Option
                  key={i}
                  onClick={() => {
                    setInputValue(item[keyNameOptions]);
                    handleSetSearch(1, item[keyNameOptions]);
                  }}
                >
                  {item[keyNameOptions]}
                </Option>
              ))
        }
          </ContainerOptions>
          )}
        </div>
        <button
          onClick={() => handleSetSearch(1, inputValue)}
          className="btn btn-primary btn-lg"
          style={{ height: "50px" }}
        >
          Pesquisar
        </button>
      </WrapperInputAndButton>
      <p style={{ fontSize: "14px" }}>{describe}</p>
    </div>
  );
}

const Label = styled.label`
  font-size: 23px;
  font-weight: 700;
`;

const WrapperInputAndButton = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  width: 100%;
`;

const ContainerOptions = styled.div`
  width: 100%;
  max-height: 250px;
  overflow: auto;
  border: gray solid 1px;
  position: absolute;
  background-color: white;
`;

const Option = styled.p`
  font-size: 18px;
  padding: 10px;
  cursor: pointer;
  :hover {
    background-color: rgb(130, 200, 255);
  }
`;
