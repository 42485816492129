import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { ArrayFormValues, FormAcompanhamentos } from '../../../../components/develop-nilton-components/FormAcompanhamentos';
import { SpinnerLoading } from '../../../../components/SpinnerLoading';

import { moneyMask } from '../../../../components/develop-nilton-components/hooks/time_options';

import api from '../../../../services/api';
import { IFatores } from '../type';

export function SocioEco({
  getValues, setValue, setTab,
}: IFatores) {
  const arrayFormValues: ArrayFormValues["formValues"] = [
    {
      label: "1. Renda de trabalho?",
      type: "renda",
      name: "renda_trabalho",
      onChange: (e:any) => {
        setValue('renda_trabalho', e.target.value);
        set_renda_trabalho(e.target.value);
      },
    },
    {
      label: "2. Participa de algum auxílio financeiro?",
      type: "financeiro",
      name: "participa_programa_auxilio_financeiro",
      especificacao: 'especificacao_participa_programa_auxilio_financeiro',
      onChange: (e:any) => {
        setValue('participa_programa_auxilio_financeiro', e.target.value);
        set_participa_programa_auxilio_financeiro(e.target.value);
      },
      onChangeEspecificacao: (e:any) => {
        setValue("especificacao_participa_programa_auxilio_financeiro", e.target.value);
      },
    },
    {
      label: "3. Possui membro(s) da família que possui benefício do bolsa família?",
      type: "beneficio",
      name: "possui_membro_bolsa_familia",
      especificacao: 'valor_bolsa_familia',
      espeficacao2: "valor_outros_membros_bolsa_familia",
      onChange: (e:any) => {
        set_possui_membro_bolsa_familia(e.target.value);
        setValue('possui_membro_bolsa_familia', e.target.value);
      },
      onChangeEspecificacao: (e:any) => {
        setValue("valor_bolsa_familia", moneyMask(e.target.value));
      },
      onChangeEspecificacao2: (e:any) => {
        setValue("valor_outros_membros_bolsa_familia", e.target.value);
      },
    },
    {
      label: "4. Possui membro(s) da família que possui benefício de prestação continuada?  ",
      type: "continuada",
      name: "possui_membro_beneficio_continuo",
      especificacao: 'valor_beneficio_continuo',
      espeficacao2: 'valor_outros_membros_beneficio_continuo',
      onChange: (e:any) => {
        set_possui_membro_beneficio_continuo(e.target.value);
        setValue('possui_membro_beneficio_continuo', e.target.value);
      },
      onChangeEspecificacao: (e:any) => {
        setValue("valor_beneficio_continuo", moneyMask(e.target.value));
      },
      onChangeEspecificacao2: (e:any) => {
        setValue("valor_outros_membros_beneficio_continuo", e.target.value);
      },
    },
    {
      label: "5. Possui membro (s) da família que possui vínculo empregatício (federal, estadual ou municipal e/ou contrato de trabalho na inicitiva privada) ?",
      type: "empregaticio",
      name: "possui_membro_vinculo_empregaticio",
      onChange: (e:any) => {
        setValue('possui_membro_vinculo_empregaticio', e.target.value);
        set_possui_membro_vinculo_empregaticio(e.target.value);
      },
    },
    {
      label: "6. Possui membro (s) da família são beneficiários de aposentadoria, auxílio doença, seguro desemprego e pensão previdenciária?",
      type: "outros",
      name: "possui_membro_aposentadoria_outros",
      onChange: (e:any) => {
        setValue('possui_membro_aposentadoria_outros', e.target.value);
        set_possui_membro_aposentadoria_outros(e.target.value);
      },
    },
  ];

  console.log(getValues().valor_outros_membros_beneficio_continuo);

  const [load, setLoad] = useState(false);
  const onSubmit = async (data:any) => {
    setLoad(true);
    try {
      await api.put(`/social/${getValues().id}`, {
        especificacao_participa_programa_auxilio_financeiro: getValues().participa_programa_auxilio_financeiro === "Sim" ? getValues().especificacao_participa_programa_auxilio_financeiro : null,
        participa_programa_auxilio_financeiro: getValues().participa_programa_auxilio_financeiro,
        possui_membro_aposentadoria_outros: getValues().possui_membro_aposentadoria_outros,
        possui_membro_beneficio_continuo: getValues().possui_membro_beneficio_continuo,
        possui_membro_bolsa_familia: getValues().possui_membro_bolsa_familia,
        possui_membro_vinculo_empregaticio: getValues().possui_membro_vinculo_empregaticio,
        renda_trabalho: getValues().renda_trabalho,
        valor_beneficio_continuo: getValues().valor_beneficio_continuo,
        valor_bolsa_familia: getValues().valor_bolsa_familia,
        valor_outros_membros_beneficio_continuo: getValues().valor_outros_membros_beneficio_continuo,
        valor_outros_membros_bolsa_familia: getValues().possui_membro_bolsa_familia === "Sim" ? getValues().valor_outros_membros_bolsa_familia : null,
      });
      setTab(3);
      window.scrollTo({ top: 0 });
    } catch (error) {
      // @ts-ignore
      toast.error(error.response.data.message);
    }
    setLoad(false);
  };

  const onSubmitError = (errors:any) => {
    const error = Object.keys(errors);
    for (let i = 0; i < error.length; i + 1) {
      toast.error(errors[error[i] as keyof any]?.message);
      break;
    }
  };

  console.log(getValues().tradicao_religiosa);

  const [renda_trabalho, set_renda_trabalho] = useState(getValues().renda_trabalho || "");
  const [participa_programa_auxilio_financeiro, set_participa_programa_auxilio_financeiro] = useState(getValues().participa_programa_auxilio_financeiro || "");
  const [possui_membro_bolsa_familia, set_possui_membro_bolsa_familia] = useState(getValues().possui_membro_bolsa_familia || "");
  const [possui_membro_beneficio_continuo, set_possui_membro_beneficio_continuo] = useState(getValues().possui_membro_beneficio_continuo || "");
  const [possui_membro_vinculo_empregaticio, set_possui_membro_vinculo_empregaticio] = useState(getValues().possui_membro_vinculo_empregaticio || "");
  const [possui_membro_aposentadoria_outros, set_possui_membro_aposentadoria_outros] = useState(getValues().possui_membro_aposentadoria_outros || "");
  const { register, handleSubmit } = useForm({
    defaultValues: {
      ...getValues(),
    },
  });

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit, onSubmitError)}>
        <div>
          <FormAcompanhamentos
            formValues={arrayFormValues}
            register={register}
            getValues={getValues}
            keysEspecificacao={{
              participa_programa_auxilio_financeiro,
              possui_membro_bolsa_familia,
              possui_membro_beneficio_continuo,
            }}
          />
        </div>
        <div className="d-flex justify-content-between mt-5 mb-5 w-75">
          <button className="btn btn-primary btn-lg" onClick={() => setTab(1)} type="button" data-testid="back">Voltar</button>
          {load ? (
            <button
              className="btn btn-primary btn-lg"
              type="button"
            >
              <SpinnerLoading />
            </button>
          ) : (
            <button
              className="btn btn-primary btn-lg"
              type="submit"
              data-testid="next-3"
            >
              Avançar
            </button>
          )}
        </div>
      </form>
    </div>
  );
}
