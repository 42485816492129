import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import ReactTooltip from 'react-tooltip';
import { Badge } from '../../../../components/develop-nilton-components/Badge';
import { IconAdd } from '../../../../components/develop-nilton-components/IconAdd';
import { IconDetails } from '../../../../components/develop-nilton-components/IconDetails';
import { ModalDetailsEgresse } from '../../../../components/develop-nilton-components/ModalDetailsEgresses';
import { Td, Tr } from '../../../../components/develop-nilton-components/TablePadrão';
import { useAuth } from '../../../../hooks/auth';
import { ITbodyGeral } from '../type';

export function TbodyGeral({ headWidth, index, egress }: ITbodyGeral) {
  const [isOpen, setIsOpen] = useState(false);
  function handleIsOpen() {
    setIsOpen(!isOpen);
  }

  const history = useHistory();
  async function handleRedirectPageForm() {
    history.push({
      pathname: '/acompanhamento/consulta',
      state: egress,
    });
  }
  const logId = useAuth().user.id;

  console.log(egress.acompanhamentos_sociais.em_acompanhamento?.assistente_social);

  return (
    <Tr data-testid="table_geral">
      <Td width={headWidth[0]}>{index}</Td>
      <Td width={headWidth[1]}>{egress.nome}</Td>
      <Td width={headWidth[2]}>
        {(egress.acompanhamentos_sociais.situacao === "Sem acompanhamento" || egress.acompanhamentos_sociais.situacao === "Acompanhamento concluído") && (
        <Badge type="primary">Sem acompanhamento</Badge>
        )}
        {egress.acompanhamentos_sociais.situacao === "Em acompanhamento" && (
          // @ts-ignore
          <div data-tip={egress.acompanhamentos_sociais.em_acompanhamento?.assistente_social.name}>
            <ReactTooltip />
            <Badge type="warning">{egress.acompanhamentos_sociais.situacao}</Badge>
          </div>
        )}
      </Td>
      <Td width={headWidth[2]}>
        <div className="mr-3" onClick={handleIsOpen}>
          <IconDetails cursor />
        </div>
        <ModalDetailsEgresse
          egresse={egress}
          isOpen={isOpen}
          handleIsOpen={handleIsOpen}
        />
        {egress.acompanhamentos_sociais.situacao === "Em acompanhamento" ? (
          <div
            onClick={() => {
              if (egress.acompanhamentos_sociais?.em_acompanhamento?.assistente_social.id === logId) {
                handleRedirectPageForm();
              } else {
                toast.error('Egresso acompanhado por outro assistente social');
              }
            }}
            data-testid="redirect_page"
          >
            <IconAdd cursor color="text-primary" />
          </div>
        ) : (
          <div
            onClick={handleRedirectPageForm}
            data-testid="redirect_page"
          >
            <IconAdd cursor color="text-primary" />
          </div>
        )}
      </Td>
    </Tr>
  );
}
