import React, { useState } from 'react';
import api from '../../../../services/api';

type IDocument = {
  url: string
}

export function useGenerate() {
  function download(url: string, filenane: string) {
    fetch(url).then((t) => t.blob().then((b) => {
      const a = document.createElement("a");
      a.href = URL.createObjectURL(b);
      a.setAttribute("download", filenane);
      a.click();
    }));
  }

  async function generate() {
    const { data } = await api.get('/reports/generate/ocurrences');
    download(data.url, String(data.url.split("/").pop()));
  }

  return generate;
}
