import React, { useState } from 'react';
import { AiFillPlusCircle } from 'react-icons/ai';
import { BsPersonPlusFill } from 'react-icons/bs';
import { MdOutlinePlaylistPlay } from 'react-icons/md';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

type IPropsButtonAddEgressos = {
  item: any
  disable?:boolean
}

export function ButtonEditFigma({ item, disable }:IPropsButtonAddEgressos) {
  const [egressosColor, setEgressosColor] = useState(false);
  function handleSetEgressosColor() {
    setEgressosColor(!egressosColor);
  }

  const history = useHistory();

  function handleHistory() {
    history.push({ state: item, pathname: '/cadastrar-ronda/adicionar-egressos' });
  }

  return (

    <Wrapper
      id={`egressos${item.id}`}
      data-tip="Adicionar Egresso(s)"
      onClick={disable ? undefined : handleHistory}
    >
      <AiFillPlusCircle
        size={20}
        cursor={disable ? undefined : "pointer"}
        color={egressosColor ? "#005cbe" : undefined}
        // onMouseOver={disable ? undefined : handleSetEgressosColor}
        // onMouseOut={disable ? undefined : handleSetEgressosColor}
        className="icon"
      />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  font-size: 20px;
  @media (max-width: 1024px) {
    font-size: 15px;
  }
`;
