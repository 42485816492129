import React, { useEffect, useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import { eachYearOfInterval, eachMonthOfInterval, format } from 'date-fns';

import styled from 'styled-components';
import api from '../../../../services/api';
import { SpinnerLoading } from '../../../../components/SpinnerLoading';
import { InputField } from '../../../../components/new-components/InputField';
import { Spinner, Input } from 'reactstrap';
import apiMunicipios from '../../../../services/apiMunicipios';

interface IProps {
  title: string;
  optionsEgress: boolean;
  optionsEgressStatusOnly: boolean;
  optionsEgressGender: boolean;
  dateFilter: boolean;
}

interface ISelected {
  day: undefined | string;
  month: undefined | string;
  year: undefined | string;
  options: undefined | string;
  optionsGender: undefined | string;
  dateFilter: undefined | string;
}

function CardReport({
  title,
  optionsEgress,
  optionsEgressStatusOnly,
  optionsEgressGender,
  dateFilter,
}: IProps) {
  const [loading, setLoading] = useState(false);
  const [errorOptions, setErrorOptions] = useState('');
  const [state, setState] = useState('');
  const [zone, setZone] = useState('');
  const [zones, setZones] = useState([
    'Selecione uma zona',
    'Norte',
    'Sul',
    'Leste',
    'Oeste',
  ]);
  const [municipio, setMunicipio] = useState('');
  const [states, setStates] = useState([
    'Escolha um estado',
    'AC',
    'AL',
    'AP',
    'AM',
    'BA',
    'CE',
    'DF',
    'ES',
    'GO',
    'MA',
    'MT',
    'MS',
    'MG',
    'PA',
    'PB',
    'PR',
    'PE',
    'PI',
    'RJ',
    'RN',
    'RS',
    'RO',
    'RR',
    'SC',
    'SP',
    'SE',
    'TO',
  ]);

  const [municipiosOptions, setMunicipiosOptions] = useState([]);
  const [filterMunicipios, setFilterMunicipios] = useState([]);
  const [loadingMunicipios, setLoadingMunicipios] = useState(false);
  const searchMunicipio = (uf: string) => {
    setState(uf);
    if (!uf.startsWith('Escolha')) {
      setLoadingMunicipios(true);
      api.get(apiMunicipios(uf)).then(res => {
        const { data } = res;
        if (data.erro === 'true') {
          toast.error(
            'Não foi possível encontrar os municípios relacionados a este estado',
          );
        }
        setMunicipiosOptions(data.map((end: any) => end.nome));

        setLoadingMunicipios(false);
        return data;
      });
    }
  };

  const searchMunicipioOptions = (municipio: string) => {
    setMunicipio(municipio);
    if (municipio.length > 2) {
      const filter = municipiosOptions?.filter(value =>
        String(value).toUpperCase().includes(String(municipio).toUpperCase()),
      );
      setFilterMunicipios(filter);
    } else {
      setFilterMunicipios([]);
    }
  };

  const selectMunicipioOption = (municipio: string) => {
    setMunicipio(municipio);

    setFilterMunicipios([]);
  };

  const selectZonaOption = (zone: string) => {
    if (!zone.startsWith('Selecione')) {
      setZone(zone);
    } else {
      setZone('');
    }
  };

  const months = eachMonthOfInterval({
    start: new Date(new Date().getFullYear() - 1, 0),
    end: new Date(new Date().getFullYear() - 1, 11),
  });

  const options = [
    'Entrada no IAPEN',
    'Novo crime',
    'Regressão de regime',
    'Término da pena',
    'Falecimento',
    'Mudança da comarca',
  ];

  const optionsGender = ['Masculino', 'Feminino'];

  const years = eachYearOfInterval({
    start: new Date(1000),
    end: new Date(),
  });

  const [selected, setSelected] = useState<ISelected>({
    day: undefined,
    month: undefined,
    year: undefined,
    options: undefined,
    optionsGender: undefined,
    dateFilter: undefined,
  });

  useEffect(() => {
    // console.log(selected);
  }, [selected]);

  function download(url: any, filename: any) {
    fetch(url).then(t =>
      t.blob().then(b => {
        const a = document.createElement('a');
        a.href = URL.createObjectURL(b);
        a.setAttribute('download', filename);
        a.click();

        setLoading(false);

        toast('Relatório gerado com sucesso!', {
          type: 'success',
        });
      }),
    );
  }

  async function generatePDF() {
    if (optionsEgress && selected.options === undefined) {
      setErrorOptions('Campo obrigatório.');
    } else {
      setErrorOptions('');
      setLoading(true);

      api
        .get(`/reports`, {
          params: {
            day: selected.day,
            month: selected.month === 'undefined' ? undefined : selected.month,
            year: selected.year === 'undefined' ? undefined : selected.year,
            round: title === 'Fiscalização',
            pad: title === 'Notificação do Número do Processo',
            license: title === 'Cumprimento de Alvará',
            egressReports:
              title ===
                'Egresso - (Habilitado) com o regime aberto domiciliar' ||
              title === 'Egresso - Não estava em casa' ||
              title === 'Egresso - Fiscalização' ||
              title === 'Egresso - (Desabilitado)' ||
              title === 'Egresso - Estado/Município',
            egressHabilitadoComRegimeAbertoDomiciliar:
              title === 'Egresso - (Habilitado) com o regime aberto domiciliar',
            egressNaoEstavamEmCasa: title === 'Egresso - Não estava em casa',
            egressNumeroFiscalizacao: title === 'Egresso - Fiscalização',
            egressDesabilitado: title === 'Egresso - (Desabilitado)',
            dateFilter: title === 'Egresso - Estado/Município',
            estado: state,
            municipio: municipio,
            zona: zone,
            motivo: selected.options,
            genero:
              selected?.optionsGender === undefined
                ? 'vazio'
                : selected.optionsGender,
          },
        })
        .then(response => {
          download(
            response.data.url,
            String(response.data.url).split('/').pop(),
          );
        })
        .catch(error => {
          toast(error.response.data.message, {
            type: 'error',
          });
        });
    }
  }

  return (
    <>
      <div
        style={{
          backgroundColor: '#efefef',
          padding: 10,
          borderRadius: 5,
          marginBottom: 20,
        }}
      >
        <h1 style={{ fontSize: 20, marginBottom: 20 }}>{title}</h1>
        {dateFilter === true && (
          // <Form onSubmit={handleSubmit}>
          <div style={{ width: '100%', marginBottom: 15 }}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                width: '100%',
              }}
            >
              <div style={{ width: '33%', marginRight: 5 }}>
                <h1 style={{ fontSize: 20 }}>Estado</h1>
                <Input
                  label="Estado"
                  type={'select'}
                  //value={states}
                  onChange={e => {
                    searchMunicipio(e.target.value);
                  }}
                >
                  {states?.map(value => (
                    <option value={value}>{value}</option>
                  ))}
                </Input>
                {loadingMunicipios && (
                  <Spinner size="sm" style={{ marginLeft: 5 }} />
                )}
              </div>
              <div
                style={{
                  width: '33%',
                  marginRight: 5,
                  position: 'relative',
                }}
              >
                <h1 style={{ fontSize: 20 }}>Município</h1>
                <Input
                  value={municipio}
                  placeholder={
                    'Após escolher um estado, insira o nome do município'
                  }
                  onChange={e => searchMunicipioOptions(e.target.value)}
                />{' '}
                {filterMunicipios?.length > 0 && (
                  <div
                    style={{
                      borderWidth: 1,
                      borderColor: '#F2F1F7',
                      borderStyle: 'solid',
                      marginTop: 0,
                      position: 'absolute',
                      width: '100%',
                    }}
                  >
                    {filterMunicipios.map((value, i) => (
                      <div
                        style={{
                          backgroundColor: '#fff',
                          paddingTop: 4,
                          paddingLeft: 5,
                          paddingRight: 5,
                          cursor: 'pointer',
                        }}
                        onClick={() => selectMunicipioOption(value)}
                      >
                        {value}
                      </div>
                    ))}
                  </div>
                )}
              </div>
              <div style={{ width: '33%', marginRight: 5 }}>
                <h1 style={{ fontSize: 20 }}>Zona</h1>
                <Input
                  label="Zona"
                  type={'select'}
                  //value={states}
                  onChange={e => {
                    selectZonaOption(e.target.value);
                  }}
                >
                  {zones?.map(value => (
                    <option value={value}>{value}</option>
                  ))}
                </Input>
              </div>
            </div>
          </div>
          // </Form>
        )}
        {optionsEgressGender === true && (
          <div style={{ marginLeft: 20 }}>
            <div style={{ marginTop: 20 }}>
              <h1 style={{ fontSize: 20 }}>Escolha o gênero</h1>
              <div className="content" style={{ marginTop: 10 }}>
                <select
                  value={selected.optionsGender}
                  onChange={e =>
                    setSelected({
                      ...selected,
                      optionsGender: e.target.value,
                    })
                  }
                  style={{
                    width: '50%',
                    padding: 10,
                    marginRight: 10,
                    borderRadius: 5,
                  }}
                >
                  <option key="0" value="undefined">
                    Selecione o gênero
                  </option>
                  {optionsGender.map((item, i) => (
                    <option key={`${item}`} value={item}>
                      {item}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <Description
              style={{ fontSize: 10, fontWeight: 'normal', marginTop: 5 }}
            >
              Caso fique em branco será gerado um relatório considerando ambos
              os gêneros.
            </Description>
          </div>
        )}
        {optionsEgressStatusOnly === false && (
          <>
            <div style={{ marginLeft: 20 }}>
              <div className="content" style={{ marginTop: 10 }}>
                <h1 style={{ fontSize: 20 }}>Relatório do dia</h1>
                <input
                  style={{
                    padding: 10,
                    borderRadius: 5,
                    height: 40,
                    width: '50%',
                  }}
                  type="number"
                  min={0}
                  max={31}
                  placeholder="Relatório do dia"
                />
              </div>
              <Description
                style={{ fontSize: 10, fontWeight: 'normal', marginTop: 5 }}
              >
                Caso fique em branco será gerado um relatório mensal ou anual
              </Description>

              <div style={{ marginTop: 20 }}>
                <h1 style={{ fontSize: 20 }}>Relatório do mês</h1>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    marginBottom: 5,
                  }}
                >
                  <select
                    value={selected.month}
                    onChange={e =>
                      setSelected({ ...selected, month: e.target.value })
                    }
                    style={{
                      width: '50%',
                      padding: 10,
                      marginRight: 10,
                      borderRadius: 5,
                    }}
                  >
                    <option key="0" value="undefined">
                      Selecione o mês
                    </option>
                    {months.map((item, i) => (
                      <option key={`${item}`} value={format(item, 'MM')}>
                        {format(item, 'MM')}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <Description style={{ fontSize: 10, fontWeight: 'normal' }}>
                Caso fique em branco será gerado um relatório de um dia
                específico ou anual. Se for escolhido somente o mês será o do
                ano atual
              </Description>
              {optionsEgress === true && (
                <>
                  <div style={{ marginTop: 20 }}>
                    <h1 style={{ fontSize: 20 }}>Escolha o motivo *</h1>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        marginBottom: 5,
                      }}
                    >
                      <select
                        value={selected.options}
                        onChange={e =>
                          setSelected({ ...selected, options: e.target.value })
                        }
                        style={{
                          width: '50%',
                          padding: 10,
                          marginRight: 10,
                          borderRadius: 5,
                          borderColor:
                            errorOptions.length > 0 ? 'red' : 'black',
                        }}
                      >
                        <option key="0" value="undefined">
                          Selecione o motivo
                        </option>
                        {options.map((item, i) => (
                          <option key={`${item}`} value={item}>
                            {item}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <Description
                    style={{ fontSize: 12, fontWeight: 'normal', color: 'red' }}
                  >
                    {errorOptions}
                  </Description>
                </>
              )}

              <div style={{ marginTop: 20 }}>
                <h1 style={{ fontSize: 20 }}>Relatório do ano</h1>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    marginBottom: 5,
                  }}
                >
                  <select
                    value={selected.year}
                    onChange={e =>
                      setSelected({ ...selected, year: e.target.value })
                    }
                    style={{
                      width: '50%',
                      padding: 10,
                      marginRight: 10,
                      borderRadius: 5,
                    }}
                  >
                    <option key="0" value="undefined">
                      Selecione o ano
                    </option>
                    {years.reverse().map(item => (
                      <option key={`${item}`} value={format(item, 'yyyy')}>
                        {format(item, 'yyyy')}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <Description style={{ fontSize: 10, fontWeight: 'normal' }}>
                Caso fique em branco será gerado um relatório de um dia
                específico ou do mês escolhido. Se for escolhido somente o ano
                este terá prioridade
              </Description>
            </div>
          </>
        )}

        {optionsEgress === true && (
          <>
            <div style={{ marginLeft: 20 }}>
              <div style={{ marginTop: 20 }}>
                <h1 style={{ fontSize: 20 }}>Escolha o motivo *</h1>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    marginBottom: 5,
                  }}
                >
                  <select
                    value={selected.options}
                    onChange={e =>
                      setSelected({ ...selected, options: e.target.value })
                    }
                    style={{
                      width: '50%',
                      padding: 10,
                      marginRight: 10,
                      borderRadius: 5,
                      borderColor: errorOptions.length > 0 ? 'red' : 'black',
                    }}
                  >
                    <option key="0" value="undefined">
                      Selecione o motivo
                    </option>
                    {options.map((item, i) => (
                      <option key={`${item}`} value={item}>
                        {item}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <Description
                style={{ fontSize: 12, fontWeight: 'normal', color: 'red' }}
              >
                {errorOptions}
              </Description>
            </div>
          </>
        )}

        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button onClick={generatePDF} className="btn btn-success">
            Gerar relatório
          </Button>
        </div>
      </div>
      <ToastContainer />
    </>
  );
}

export default CardReport;

const Description = styled.h3`
  @media (max-width: 768px) {
    display: none;
  }
`;
const Button = styled.button`
  @media (max-width: 768px) {
    font-size: 10px;
  }
`;
