import React, { useEffect, useState } from 'react';
import { AbaFilterButtons, AbaFilterContent } from '../../../components/develop-nilton-components/AbaFiltro';
import { SearchField } from '../../../components/develop-nilton-components/SearchField';
import { SectionHeader } from '../../../components/SectionHeader';
import api from '../../../services/api';
import { TableConcluido } from './components/TableConcluido';
import { TableGeral } from './components/TableGeral';
import { TablePendente } from './components/TablePendente';
import { useGetProjetosConcluidos } from './hooks/useGetProjetosConcluidos';
import { useGetProjetosGeral } from './hooks/useGetProjetosGeral';
import { useGetProjetosPendentes } from './hooks/useGetProjetosPendentes';
import { IProjetos } from './types';

export function HomologarProjeto() {
  const { projetosGeral, handleProjetosGeral, load } = useGetProjetosGeral<IProjetos>();
  const { projetosPendentes, handleProjetosPendentes, loadPendente } = useGetProjetosPendentes<IProjetos>();
  const { projetosConcluidos, handleProjetosConcluidos, loadConcluidos } = useGetProjetosConcluidos<IProjetos>();
  const [optionsProjetoSearch, setOptionsProjetoSearch] = useState(null);

  async function handleProjetos(page = 1, search?: string) {
    try {
      await handleProjetosGeral(page, search);
      await handleProjetosPendentes(page, search);
      await handleProjetosConcluidos(page, search);
    } catch (error) {
      // @ts-ignore
      console.log(error.message);
    }
  }

  const [tab, setTab] = useState(1);
  const [inputValue, setInputValue] = useState("");

  useEffect(() => {
    api.get('/projects')
      .then(({ data }) => setOptionsProjetoSearch(data.data));
  }, []);
  return (
    <div style={{ width: "100%" }}>
      <SectionHeader subtitle="Homologar Projeto" />

      <SearchField
        option={optionsProjetoSearch}
        handleSetSearch={handleProjetos}
        stateValue={[inputValue, setInputValue]}
        keyNameOptions="nome"
        label="Pesquisar projeto"
        describe="Digite o nome do projeto."
      />

      <AbaFilterButtons
        useStateTab={[tab, setTab]}
        buttons={['Geral', 'Pendentes', 'Concluídos']}
      />

      <AbaFilterContent
        tab={tab}
        components={[
          <TableGeral
            data={projetosGeral?.data}
            meta={projetosGeral?.meta}
            handleProjetos={handleProjetos}
            load={load}
          />,
          <TablePendente
            data={projetosPendentes?.data}
            meta={projetosPendentes?.meta}
            handleProjetos={handleProjetos}
            load={loadPendente}
          />,
          <TableConcluido
            data={projetosConcluidos?.data}
            meta={projetosConcluidos?.meta}
            handleProjetos={handleProjetos}
            load={loadConcluidos}
          />,
        ]}
      />
    </div>
  );
}
