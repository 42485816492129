import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  // height: 100%;
  padding-left: 100px;
  padding-right: 100px;

  td {
    text-align: center;
  }

  table {
    width: 100%;
    border-collapse: collapse;
  }

  & h2 {
    color: #182339;
    font-size: 26px;
  }

  select:focus {
    outline: none;
  }

  textarea:focus {
    outline: none;
  }

  .content {
    display: flex;
    flex-direction: column;
    padding: 20px;

    select:focus {
      outline: none;
    }

    textarea:focus {
      outline: none;
    }

    & input {
      width: 100%;
    }

    & input:focus {
      outline: none !important;
      border-color: #007BFF;
    }

    .inputfile {
      /* visibility: hidden etc. wont work */
      width: 0.1px;
      height: 0.1px;
      opacity: 0;
      overflow: hidden;
      position: absolute;
      z-index: -1;
    }
    .inputfile:focus + label {
      /* keyboard navigation */
      outline: 1px dotted #000;
      outline: -webkit-focus-ring-color auto 5px;
    }
    .inputfile + label * {
      pointer-events: none;
    }
  }
`;
