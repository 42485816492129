import React, { useEffect, useState, useMemo } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { debounce } from 'lodash';
import { SectionHeader } from '../../../components/SectionHeader';
import { AbaFilterContent } from '../../../components/develop-nilton-components/AbaFiltro';
import { TableGeral } from './components/TableGeral';
import { useRounds } from './hooks/useRounds';
import { useRoundsPendentes } from './hooks/useRoundsPendentes';
import { useRoundsEmAndamento } from './hooks/useRoundsEmAndamento';
import { useRoundsConcluidos } from './hooks/useRoundsConcluidos';
import { Rounds } from './types';

import InputSearch from '../components/InputSearch';
import { ButtonAdd } from '../../../components/new-components/ButtonAdd';

export function CadastrarRonda() {
  const history = useHistory();
  const roundsGeral = useRounds<Rounds>();
  const roundsPendentes = useRoundsPendentes<Rounds>();
  const roundsEmAndamento = useRoundsEmAndamento<Rounds>();
  const roundsConcluidos = useRoundsConcluidos<Rounds>();

  const [loadingSearch, setLoadingSearch] = useState(false);

  async function handleUpdate(page?: number) {
    roundsGeral.handleSetRounds(page);
    roundsPendentes.handleSetRounds(page);
    roundsEmAndamento.handleSetRounds(page);
    roundsConcluidos.handleSetRounds(page);
  }

  function handleOpenModalAdicionarRonda() {
    history.push({ pathname: "/cadastrar-ronda/create/adicionar-egressos" });
  }

  const [searchTerm, setSearchTerm] = useState("");

  async function search(page: number, ronda: string) {
    setLoadingSearch(true);
    // await roundsGeral.handleSetRounds(page, undefined, ronda);
    setSearchTerm(ronda);
    setLoadingSearch(false);
  }

  const debounceLoadData = useMemo(() => debounce(roundsGeral.handleSetRounds, 700), []);

  React.useEffect(() => {
    // @ts-ignore
    window.addEventListener("keydown", debounceLoadData(1, undefined, searchTerm));
    return () => {
      // @ts-ignore
      window.removeEventListener("keydown", debounceLoadData(1, searchTerm));
    };
  }, [searchTerm]);

  return (
    <WrapperContainer>
      <SectionHeader subtitle="Rondas" />

      <div className="content">
        {/* <div style={{ marginBottom: 10 }} /> */}
        <InputSearch
          loading={loadingSearch}
          setSearch={search}
          autosearch
          title="Pesquisar Ronda"
          placeholder="Digite o referencial da ronda"
          buttonText="Buscar"
          description=""
          button={(
            <ButtonAdd
              style={{ marginLeft: 10, background: '#68996D' }}
              onClick={() => {
                handleOpenModalAdicionarRonda();
              }}
            />
          )}
        />
      </div>

      <div style={{ width: "100%" }}>
        <div style={{ display: "flex", justifyContent: "space-between", padding: "20px" }} />
        <AbaFilterContent
          tab={1}
          components={
          [
            <TableGeral roundsGeral={roundsGeral} rounds={roundsGeral.rounds} handleUpdate={handleUpdate} />,
          ]
        }
        />
      </div>

    </WrapperContainer>
  );
}

const WrapperContainer = styled.div`
  width: 100%;

  .content {
    width: 100%;

    & div {
      display: flex;
    }

    select:focus {
      outline: none;
    }

    textarea:focus {
      outline: none;
    }

    & input {
      width: 100%;
    }

    & input:focus {
      outline: none !important;
    }

    .inputfile {
      width: 0.1px;
      height: 0.1px;
      opacity: 0;
      overflow: hidden;
      position: absolute;
      z-index: -1;
    }
    .inputfile:focus + label {
      outline: 1px dotted #000;
      outline: -webkit-focus-ring-color auto 5px;
    }
    .inputfile + label * {
      pointer-events: none;
    }
  }
`;
