import React, { useState } from 'react';
import { BsPersonPlusFill } from 'react-icons/bs';
import { MdOutlinePlaylistAddCheck } from 'react-icons/md';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { ModalHistoryComprovanteFigma } from './ModalHistoryComprovanteFigma';
import { ModalHistoryComprovanteFigmaEditable } from './ModalHistoryComprovanteFigmaEditable';

type IPropsButtonAddEgressos = {
  item: any;
  disable?: boolean;
  update(): Promise<void>;
};

export function ButtonHistoryDetailsFigma({
  item,
  disable,
  update,
}: IPropsButtonAddEgressos) {
  const [egressosColor, setEgressosColor] = useState(false);
  function handleSetEgressosColor() {
    setEgressosColor(!egressosColor);
  }
  const [isOpenModalComprovante, setIsOpenModalComprovante] = useState(false);
  const [isOpenComprovanteAddModal, setIsOpenComprovanteAddModal] =
    React.useState(false);
  const history = useHistory();

  function handleSetIsOpenModalComprovante() {
    setIsOpenModalComprovante(!isOpenModalComprovante);
  }

  return (
    <>
      <Wrapper
        id={`egressos${item.id}`}
        data-tip="Comprovante detalhes"
        onClick={() => {
          setIsOpenComprovanteAddModal(true);
        }}
      >
        <MdOutlinePlaylistAddCheck
          size={26}
          cursor={disable ? undefined : 'pointer'}
          color={egressosColor ? '#005cbe' : undefined}
          onMouseOver={disable ? undefined : handleSetEgressosColor}
          onMouseOut={disable ? undefined : handleSetEgressosColor}
          className="icon"
        />
      </Wrapper>

      {item?.ronda?.homologado === 'T' ? (
        <ModalHistoryComprovanteFigma
          round_egresses_egressos={item}
          handleSetIsOpen={[
            isOpenComprovanteAddModal,
            setIsOpenComprovanteAddModal,
          ]}
          egresse={item.egresso}
        />
      ) : (
        <ModalHistoryComprovanteFigmaEditable
          round_egresses_egressos={item}
          handleSetIsOpen={[
            isOpenComprovanteAddModal,
            setIsOpenComprovanteAddModal,
          ]}
          handleUpdate={update}
          egresse={item.egresso}
        />
      )}
    </>
  );
}

const Wrapper = styled.div`
  font-size: 20px;
  @media (max-width: 1024px) {
    font-size: 15px;
  }
`;
