import React from 'react';
import { Chart } from 'react-chartjs-2';
import { eachYearOfInterval, format } from 'date-fns';
import { SpinnerLoading } from '../../../../components/SpinnerLoading';
import { useGetTurmaSituacao_das_turmas_no_ano } from '../hooks/useGetTurmaSituacao_das_turmas_no_ano';
import { useGetTurmaEvasao_nas_turma_no_ano } from '../hooks/useGetTurmaEvasao_nas_turma_no_ano';
import { useGetProjetos_por_mes_no_ano } from '../hooks/useGetProjetos_por_mes_no_ano';
import { useGetProjetosInfraestrutura_utilizada_por_projeto_no_ano } from '../hooks/useGetProjetoInfraestrutura_utilizada_por_projeto_no_ano';
import { useGetProjetoSituacao_dos_projetos_no_ano } from '../hooks/useGetProjetoSituacao_dos_projetos_no_ano';
import { useGetProjetoFontes_por_projeto_no_ano } from '../hooks/useGetProjetoFontes_por_projeto_no_ano';

export function Projeto() {
  const { projetos_por_mes_no_ano, handleProjetos_por_mes_no_ano } = useGetProjetos_por_mes_no_ano();
  const { infraestrutura_utilizada_por_projeto_no_ano, handleInfraestrutura_utilizada_por_projeto_no_ano } = useGetProjetosInfraestrutura_utilizada_por_projeto_no_ano();
  const { situacao_dos_projetos_no_ano, handleSituacao_dos_projetos_no_ano } = useGetProjetoSituacao_dos_projetos_no_ano();
  const { fontes_por_projeto_no_ano, handleFontes_por_projeto_no_ano } = useGetProjetoFontes_por_projeto_no_ano();

  const years = eachYearOfInterval({ start: new Date(0), end: new Date() }).map((item) => format(new Date(item), "yyyy"));

  const load = (projetos_por_mes_no_ano && infraestrutura_utilizada_por_projeto_no_ano && situacao_dos_projetos_no_ano && fontes_por_projeto_no_ano);
  console.log(load);

  const options_pie = {
    plugins: {
      tooltip: {
        callbacks: {
          label(context: any) {
            return `${context.raw}%`;
          },
        },
      },
    },
  };

  const options_bar = {
    indexAxis: 'y' as const,
    elements: {
      bar: {
        borderWidth: 2,
      },
    },
    responsive: true,
    plugins: {
      legend: {
        position: 'right' as const,
      },
    },
  };

  const options_line = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top' as const,
      },
    },
  };

  return (
    <div>
      {!load ? (
        <div className="d-flex justify-content-center m-5">
          <SpinnerLoading size={50} />
        </div>
      ) : (
        <div className="d-flex flex-column" style={{ gap: "30px" }}>
          {projetos_por_mes_no_ano && (
          <div>
            <div className="d-flex align-items-center justify-content-center mt-2">
              <p className="m-0 mr-3 h4">Número de projetos por mês em:</p>
              <select onChange={(e) => handleProjetos_por_mes_no_ano(e.target.value)}>
                {years.map((year) => {
                  if (year === format(new Date(), 'yyyy')) {
                    return <option value={year} selected>{year}</option>;
                  } return <option value={year}>{year}</option>;
                })}
              </select>
            </div>
            <div className="d-flex justify-content-center">
              <div className="w-100">
                <Chart
                  data={projetos_por_mes_no_ano}
                  type="line"
                  options={options_line}
                  height={95}
                />
              </div>
            </div>
          </div>
          )}

          {infraestrutura_utilizada_por_projeto_no_ano && (
          <div>
            <div className="d-flex align-items-center justify-content-center mt-2">
              <p className="m-0 mr-3 h4">Infraestrutura utilizada por projeto no ano:</p>
              <select onChange={(e) => handleInfraestrutura_utilizada_por_projeto_no_ano(e.target.value)}>
                {years.map((year) => {
                  if (year === format(new Date(), 'yyyy')) {
                    return <option value={year} selected>{year}</option>;
                  } return <option value={year}>{year}</option>;
                })}
              </select>
            </div>
            <div className="d-flex justify-content-center">
              <div className="w-100">
                <Chart
                  data={infraestrutura_utilizada_por_projeto_no_ano}
                  type="bar"
                  options={options_bar}
                  height={95}
                />
              </div>
            </div>
          </div>
          )}

          {situacao_dos_projetos_no_ano && (
          <div>
            <div className="d-flex align-items-center justify-content-center mt-2">
              <p className="m-0 mr-3 h4">Situação dos projetos no ano:</p>
              <select onChange={(e) => handleSituacao_dos_projetos_no_ano(e.target.value)}>
                {years.map((year) => {
                  if (year === format(new Date(), 'yyyy')) {
                    return <option value={year} selected>{year}</option>;
                  } return <option value={year}>{year}</option>;
                })}
              </select>
            </div>
            <div className="d-flex justify-content-center">
              <div className="w-50">
                <Chart
                  data={situacao_dos_projetos_no_ano}
                  type="pie"
                  options={options_pie}
                  height={95}
                />
              </div>
            </div>
          </div>
          )}

          {fontes_por_projeto_no_ano && (
          <div>
            <div className="d-flex align-items-center justify-content-center mt-2">
              <p className="m-0 mr-3 h4">Fontes por projeto no ano:</p>
              <select onChange={(e) => handleFontes_por_projeto_no_ano(e.target.value)}>
                {years.map((year) => {
                  if (year === format(new Date(), 'yyyy')) {
                    return <option value={year} selected>{year}</option>;
                  } return <option value={year}>{year}</option>;
                })}
              </select>
            </div>
            <div className="d-flex justify-content-center">
              <div className="w-50">
                <Chart
                  data={fontes_por_projeto_no_ano}
                  type="pie"
                  options={options_pie}
                  height={95}
                />
              </div>
            </div>
          </div>
          )}
        </div>
      )}

    </div>
  );
}
