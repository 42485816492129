import React, { useEffect, useState } from 'react';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Form,
  FormGroup,
  Label,
  Spinner,
} from 'reactstrap';
import { DatePicker } from 'reactstrap-date-picker';
import LocationPicker from 'react-leaflet-location-picker';
import { toast } from 'react-toastify';
import { MapContainer } from 'react-leaflet';
import { api } from '../../../services/api';
import { useEvent } from '../../../hooks/useEvent';
import { Map } from '../map';

const DefaultLocation = { lat: -0.04268524821259019, lng: -51.108055437415 };
const DefaultZoom = 18;

interface Event {
  id: string;
  title: string;
  description: string;
  latitude: string;
  longitude: string;
  scheduleDate: Date;
  location: string;
  startTime: Date;
  endTime: Date;
  deleted_at: Date | null;
  deleted: false;
  createdAt: Date;
  updatedAt: Date;
}

interface PropsEvent {
  isOpen: boolean;
  handleIsOpen: () => void;
  action: (motivo?: string) => void;
  title: string;
  status: 'Habilitado' | 'Desabilitado';
}

export function ModalConfirmEgressDisable({
  isOpen,
  handleIsOpen,
  title,
  action,
  status,
}: PropsEvent): JSX.Element {
  const [motivo, setMotivo] = useState('');

  const [errorMotivo, setErrorMotivo] = useState('');
  function isInputFilled() {
    if (status === 'Habilitado') {
      if (motivo === '' || motivo === 'default') {
        setErrorMotivo('Campo obrigatório');
      } else {
        action(motivo);
        handleIsOpen();
        setMotivo('');
        setErrorMotivo('');
      }
    } else {
      action();
      handleIsOpen();
      setMotivo('');
      setErrorMotivo('');
    }
  }
  return (
    <div>
      <Modal isOpen={isOpen} toggle={handleIsOpen} size="md">
        <ModalHeader>
          {status === 'Habilitado' ? title : 'Confirme a habilitação'}
        </ModalHeader>
        {status === 'Habilitado' && (
          <Form>
            <FormGroup>
              <Input
                style={{
                  borderColor: errorMotivo?.length > 1 ? '#E44B39' : '#DEE2E6',
                }}
                type="select"
                name="select"
                id="exampleSelect"
                value={motivo}
                onChange={e => {
                  setMotivo(e.target.value);
                }}
              >
                <option key="default" value="default">
                  Selecione o motivo
                </option>
                <option key="Entrada no IAPEN" value="Entrada no IAPEN">
                  Entrada no IAPEN
                </option>
                <option key="Novo crime" value="Novo crime">
                  Novo crime
                </option>
                <option key="Regressão de regime" value="Regressão de regime">
                  Regressão de regime
                </option>
                <option key="Término da pena" value="Término da pena">
                  Término da pena
                </option>
                <option key="Falecimento" value="Falecimento">
                  Falecimento
                </option>
                <option key="Mudança da comarca" value="Mudança da comarca">
                  Mudança da comarca
                </option>
              </Input>
              {errorMotivo && (
                <text
                  style={{
                    fontSize: 14,
                    color: '#E44B39',
                    marginBottom: 5,
                    marginLeft: 5,
                  }}
                >
                  {errorMotivo}
                </text>
              )}
            </FormGroup>
          </Form>
        )}

        <ModalFooter>
          <Button
            color="primary"
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            onClick={() => {
              isInputFilled();
            }}
            style={{ minWidth: 120 }}
            disabled={!isOpen}
          >
            Confirmar
          </Button>{' '}
          <Button color="secondary" onClick={handleIsOpen}>
            Cancelar
          </Button>{' '}
        </ModalFooter>
      </Modal>
    </div>
  );
}
