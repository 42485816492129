import React, { useState } from 'react';
import Modal from 'react-modal';

import { eachYearOfInterval, eachMonthOfInterval, format } from 'date-fns';
import Calendar from '../../../../../assets/calendar.png';

interface IProps {
  modalIsOpen: boolean;
  closeModal: () => void;
  setMonth?: React.Dispatch<React.SetStateAction<string>>;
  setYear?: React.Dispatch<React.SetStateAction<string | number>>;
  tab?: 'mensal' | 'trimestral';
  periodo?: {
    month: string;
    trimestre: string | undefined;
    year: string;
  };
  setPeriodo?:(periodo: any) => void;
}

function Period({
  modalIsOpen, closeModal, setMonth, setYear, periodo, setPeriodo, tab,
} : IProps) {
  const years = eachYearOfInterval({
    start: new Date(1000),
    end: new Date(),
  });

  const months = eachMonthOfInterval({
    start: new Date(new Date().getFullYear() - 1, 0),
    end: new Date(new Date().getFullYear() - 1, 11),
  });

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
    },
  };

  return (
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
      style={customStyles}
      contentLabel=""
    >
      <div style={{ display: 'flex' }}>
        <h2 style={{ marginBottom: 10 }}>Qual o período desejado?</h2>
      </div>

      <div style={{
        width: '100%',
        borderStyle: 'solid',
        borderWidth: 1,
        borderColor: '#595959',
        marginBottom: 20,
      }}
      />

      <div style={{ display: "flex", alignItems: 'center', justifyContent: 'space-between' }}>
        <img style={{ width: 128, height: 128 }} src={Calendar} alt="egresso" />

        <div style={{ width: '100%', marginLeft: 20 }}>
          <div style={{ marginTop: 20 }}>
            <h1 style={{ fontSize: 20 }}>{tab === 'mensal' ? 'Mês' : 'Período'}</h1>
            <div style={{ display: 'flex', alignItems: 'center', marginBottom: 10 }}>
              <select
                style={{
                  width: '100%', padding: 10, marginRight: 10, borderRadius: 5,
                }}
                value={periodo ? tab === 'mensal' ? periodo.month : periodo.trimestre : undefined}
                onChange={(e) => {
                  if (setMonth) { setMonth(e.target.value); }
                  if (setPeriodo) {
                    if (tab === 'mensal') {
                      setPeriodo({ ...periodo, month: e.target.value });
                    } else {
                      setPeriodo({ ...periodo, trimestre: e.target.value });
                    }
                  }
                }}
              >
                {/* <option value="">Selecione</option> */}
                {tab === 'mensal' ? (
                  months.map((item, i) => (
                    <option key={`${item}`} value={format(item, "MM")}>{format(item, "MM")}</option>
                  ))
                ) : (
                  <>
                    <option key="01-03" value="01-03">Primeiro Trimestre</option>
                    <option key="04-06" value="04-06">Segundo Trimestre</option>
                    <option key="07-09" value="07-09">Terceiro Trimestre</option>
                    <option key="10-12" value="10-12">Quarto Trimestre</option>
                  </>
                )}

              </select>
            </div>
          </div>

          <h2 style={{
            fontSize: 11, color: '#595959', fontWeight: "normal",
          }}
          >
            Escolha o mês do ano
          </h2>

          <div style={{ marginTop: 20 }}>
            <h1 style={{ fontSize: 20 }}>Ano</h1>
            <div style={{ display: 'flex', alignItems: 'center', marginBottom: 10 }}>
              <select
                style={{
                  width: '100%', padding: 10, marginRight: 10, borderRadius: 5,
                }}
                value={periodo ? periodo.year : undefined}
                onChange={(e) => {
                  if (setYear) { setYear(e.target.value); }
                  if (setPeriodo) { setPeriodo({ ...periodo, year: e.target.value }); }
                }}

              >
                {/* <option value="">Selecione</option> */}
                {years.reverse().map((item) => (
                  <option key={`${item}`} value={format(item, "yyyy")}>{format(item, "yyyy")}</option>
                ))}
              </select>
            </div>
          </div>

          <h2 style={{
            fontSize: 11, color: '#595959', fontWeight: "normal",
          }}
          >
            Escolha o ano
          </h2>
        </div>
      </div>

      <div style={{
        width: '100%',
        borderStyle: 'solid',
        borderWidth: 0.5,
        borderColor: '#595959',
        marginBottom: 10,
        marginTop: 20,
      }}
      />

      <div style={{ display: "flex", alignItems: 'center', justifyContent: 'flex-end' }}>
        <div
          onClick={closeModal}
          style={{
            marginTop: 10, cursor: 'pointer', borderRadius: 5, display: "flex", alignItems: 'center', justifyContent: 'center', backgroundColor: '#007BFF', padding: 5, width: 100,
          }}
        >
          <h3 style={{ color: '#fff', fontSize: 14, marginTop: 5 }}>Fechar</h3>
        </div>
      </div>
    </Modal>
  );
}

export default Period;
