import React, { useState } from 'react';
import { AiFillMinusCircle } from 'react-icons/ai';
import ReactTooltip from 'react-tooltip';
import { Td, Tr } from '../../../../components/develop-nilton-components/TablePadrão';
import { SpinnerLoading } from '../../../../components/SpinnerLoading';
import { useDeleteEgressesOfRounds } from '../hooks/useDeleteEgressesOfRounds';
import { UseRoundEgressesOfFical } from '../hooks/useRoundEgressesOfFiscal';
import { useEgresses } from '../hooks/useEgresses';
import api from '../../../../services/api';

type PropsTbodyAdicionarEgressoNaRonda = {
  itemEgresso: any
  headWidth: string[]
  roundsEgresses?: any;
  setRoundEgresses?(value: any): void
  handleUpdate?(): Promise<void>
  handleUpdateEgresses?(): Promise<void>
  isLoad?: boolean
}

export function TbodyAlreadyAddsInTheRounds({
  headWidth, itemEgresso, roundsEgresses, setRoundEgresses, handleUpdate, handleUpdateEgresses, isLoad,
}:PropsTbodyAdicionarEgressoNaRonda) {
  // const { setRoundEgressesOfFiscal } = UseRoundEgressesOfFical();
  // const { handleSetEgresses } = useEgresses();
  const { handleDeleteEgressesOfRounds } = useDeleteEgressesOfRounds();
  const [load, setLoad] = useState(false);

  async function handleDelete() {
    // setLoad(true);
    // await handleUseRoundEgressesOfFical();
    // const { data } = await api.get(`/rounds/${itemEgresso.round_id}`);
    // setRoundEgressesOfFiscal(data.egresses);

    if (setRoundEgresses && roundsEgresses) {
      setRoundEgresses(roundsEgresses.filter((item: any) => item.egress_id != itemEgresso.egress_id));
      await handleDeleteEgressesOfRounds(itemEgresso.id);
    }

    // setLoad(false);
    // await handleSetEgresses();

    // if (handleUpdateEgresses) {
    // await handleUpdateEgresses();
    // }
  }

  return (
    <Tr>
      <Td width={headWidth[0]}>{itemEgresso.egresso.nome}</Td>
      <Td width={headWidth[1]}>
        {load && <SpinnerLoading size={30} />}
        {!load && (
          <>
            {!isLoad && (
            <div
              data-tip="Remover da ronda"
              onClick={handleDelete}
            >
              <AiFillMinusCircle
                color="rgba(201, 19, 19, 0.6)"
                size={30}
                cursor="pointer"
              />
            </div>
            )}
          </>
        )}
        <ReactTooltip />
      </Td>
    </Tr>
  );
}
