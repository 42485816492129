import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { AbaFilterContent } from '../../../../components/develop-nilton-components/AbaFiltro';
import { AbaProgress } from '../../../../components/develop-nilton-components/AbaProgress';
import { SectionHeader } from '../../../../components/SectionHeader';
import { SpinnerLoading } from '../../../../components/SpinnerLoading';
import api from '../../../../services/api';
import { FatoresEmo } from './FatoresEmo';
import { FatoresInt } from './FatoresInt';
import { FatoresFis } from './FatotesFis';
import { Observacao } from './Observacao';
import { Queixa } from './Queixa';

export function PageFormPsicopedagogo() {
  const [tab, setTab] = useState(1);
  const { state } = useLocation<any>();
  const history = useHistory();
  const [load, setLoad] = useState(false);

  const [acompanhemento, set_acompanhamento] = useState<any>(null);

  console.log(acompanhemento);

  const { getValues, setValue } = useForm({ defaultValues: acompanhemento });

  console.log(getValues());

  async function handleCancelar() {
    setLoad(true);
    try {
      const { data } = await api.delete(`/psicopedagogue/${getValues().id}`);
      history.push('/acompanhamento');
      toast.success(data.message);
    } catch (error) {
      // @ts-ignore
      toast.error(error.response.data.message);
    }
    setLoad(false);
  }

  useEffect(() => {
    (async () => {
      try {
        const { data } = await api.post('/psicopedagogue', { egress_id: state.id });
        const keys = Object.keys(data);
        keys.forEach((key) => {
          setValue(key, data[key]);
        });

        set_acompanhamento(data);
      } catch (error) {
        // @ts-ignore
        const errors = error.response.data.acompanhamento;
        const keys = Object.keys(errors);
        keys.forEach((key) => {
          setValue(key, errors[key]);
        });
        set_acompanhamento(errors);
      }
    })();
  }, []);

  return (
    <div className="w-100" data-testid="page_consulta">
      <SectionHeader subtitle="Perfil psicopedagogico do egresso" backPage="/acompanhamento" />

      {!getValues().id && (
        <div className="d-flex justify-content-center m-5">
          <SpinnerLoading />
        </div>
      )}

      {getValues().egress_id && (
        <div>
          <p className="text-primary h2">{`Nome: ${state.nome}`}</p>
          <div className="d-flex justify-content-between" style={{ width: "100%" }}>
            <AbaProgress
              abas={[
                'Fatores Intelectuais',
                'Fatores Emocionais',
                'Fatores Físicos',
                'Queixa',
                'Observações',
              ]}
              useStateTab={[tab, setTab]}
              size="medium"
            />
            {!load ? (
              <button
                className="btn btn-danger m-1"
                onClick={handleCancelar}
              >
                Cancelar
              </button>
            ) : (
              <button
                className="btn btn-danger m-1"
              >
                <SpinnerLoading />
              </button>
            )}
          </div>

          <AbaFilterContent
            tab={tab}
            components={[
              <FatoresInt
                getValues={getValues}
                setValue={setValue}
                setTab={setTab}
              />,
              <FatoresEmo
                getValues={getValues}
                setValue={setValue}
                setTab={setTab}
              />,
              <FatoresFis
                getValues={getValues}
                setValue={setValue}
                setTab={setTab}
              />,
              <Queixa
                getValues={getValues}
                setValue={setValue}
                setTab={setTab}
              />,
              <Observacao
                getValues={getValues}
                setValue={setValue}
                setTab={setTab}
              />,
            ]}
          />
        </div>
      )}

    </div>
  );
}
