import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { AbaFilterButtons, AbaFilterContent } from '../../../components/develop-nilton-components/AbaFiltro';
import { IconAdd } from '../../../components/develop-nilton-components/IconAdd';
import { SearchField } from '../../../components/develop-nilton-components/SearchField';
import { SectionHeader } from '../../../components/SectionHeader';
import { TableFinalizado } from './components/TableFinalizado';
import { TableEmAndamento } from './components/TableEmAndamento';
import { useCursosAndamento } from './Hooks/useCursosEmAndamento';
import { useCursosFinalizados } from './Hooks/useCursosFinalizado';
import { useOptionSearch } from './Hooks/useOptionSearch';
import { ICursos } from './types';
import { useCursosPendente } from './Hooks/useCursoPendente';
import { TablePendente } from './components/TablePendente';
import { useCursosCancelado } from './Hooks/useCursoCancelado';
import { TableCancelado } from './components/TableCancelado';

function GerirTurma() {
  const { cursos, handleSetCursosAndamento, load } = useCursosAndamento<ICursos>();
  const { finalizado, handleSetCursosFinalizado, load_finalizado } = useCursosFinalizados<ICursos>();
  const { handleSetCursosPendente, load_pendente, pendente } = useCursosPendente<ICursos>();
  const { cancelado, handleSetCursosCancelado, load_cancelado } = useCursosCancelado<ICursos>();

  const { options } = useOptionSearch();
  const [inputSearchValue, setInputSearchValue] = useState('');
  const [tab, setTab] = useState(1);
  const history = useHistory();

  function handleRedirectCadastrarTurma() {
    history.push("/gerir-turmas/cadastrar");
  }

  async function handleUpdate(page?:number) {
    await handleSetCursosAndamento(page);
    await handleSetCursosFinalizado(page);
    await handleSetCursosPendente(page);
    await handleSetCursosCancelado(page);
  }

  return (
    <div style={{ width: '100%' }}>
      <SectionHeader subtitle="Gerir Turmas" />

      <AbaFilterContent
        tab={tab}
        components={[
          <div style={{ width: '100%' }}>
            <SearchField
              option={options?.data}
              keyNameOptions="title"
              stateValue={[inputSearchValue, setInputSearchValue]}
              handleSetSearch={handleSetCursosAndamento}
              placeholder="Pesquise..."
              label="Pesquise a turma desejada ou selecione as que estão em andamento:"
              describe="Após digitar uma pista, clique no botão pesquisar e visualize dados de desempenho e frequência do egresso por curso"
            />
          </div>,
          <div style={{ width: '100%' }}>
            <SearchField
              option={options?.data}
              keyNameOptions="title"
              stateValue={[inputSearchValue, setInputSearchValue]}
              handleSetSearch={handleSetCursosPendente}
              placeholder="Pesquise..."
              label="Pesquise a turma desejada ou selecione as que estão em andamento:"
              describe="Após digitar uma pista, clique no botão pesquisar e visualize dados de desempenho e frequência do egresso por curso"
            />
          </div>,
          <div style={{ width: '10%' }}>
            <SearchField
              option={options?.data}
              keyNameOptions="title"
              stateValue={[inputSearchValue, setInputSearchValue]}
              handleSetSearch={handleSetCursosFinalizado}
              placeholder="Pesquise..."
              label="Pesquise a turma desejada ou selecione as que estão em andamento:"
              describe="Após digitar uma pista, clique no botão pesquisar e visualize dados de desempenho e frequência do egresso por curso"
            />
          </div>,
          <div style={{ width: '100%' }}>
            <SearchField
              option={options?.data}
              keyNameOptions="title"
              stateValue={[inputSearchValue, setInputSearchValue]}
              handleSetSearch={handleSetCursosCancelado}
              placeholder="Pesquise..."
              label="Pesquise a turma desejada ou selecione as que estão em andamento:"
              describe="Após digitar uma pista, clique no botão pesquisar e visualize dados de desempenho e frequência do egresso por curso"
            />
          </div>,

        ]}
      />

      <div className="d-flex justify-content-between mb-1" style={{ width: "100%" }}>
        <AbaFilterButtons
          buttons={["Em andamento", "Pendente", "Finalizados", "Cancelado"]}
          useStateTab={[tab, setTab]}
        />
        <button
          className="btn btn-success d-flex align-items-center justify-content-between"
          onClick={handleRedirectCadastrarTurma}
        >
          <IconAdd color="#fff" />
          {" "}
          Adicionar turma
        </button>
      </div>

      <AbaFilterContent
        tab={tab}
        components={[
          <TableEmAndamento
            data={cursos?.data}
            meta={cursos?.meta}
            load={load}
            handleUpdate={handleUpdate}
          />,
          <TablePendente
            data={pendente?.data}
            meta={pendente?.meta}
            load={load_pendente}
            handleUpdate={handleUpdate}
          />,
          <TableFinalizado
            data={finalizado?.data}
            meta={finalizado?.meta}
            load={load_finalizado}
            handleUpdate={handleUpdate}
          />,
          <TableCancelado
            data={cancelado?.data}
            meta={cancelado?.meta}
            load={load_cancelado}
            handleUpdate={handleUpdate}
          />,
        ]}
      />

    </div>
  );
}

export default GerirTurma;
