import React, { useState } from 'react';

import { AiFillMinusCircle } from 'react-icons/ai';
import { BsCheckLg } from 'react-icons/bs';

import { Container } from './styles';

import EgressesRound from '../components/EgressesRound';

import Header from '../components/Header';

export function RondaDiaria() {
  const [egresses, setEgresses] = useState([
    {
      id: 1,
      nome: 'Fulano',
    },
    {
      id: 2,
      nome: 'Beltrano',
    },
  ]);

  return (
    <Container>
      <Header route="listar-ronda" name="Ronda diária" />

      <div style={{ marginTop: 10, marginBottom: 20 }}>
        <h1 style={{ fontSize: 11, color: '#595959', fontWeight: 'normal' }}>
          Clique no nome do egresso para ver os detalhes, se for postada uma foto do alvará com assinatura do egresso, este será sinalizado como cumprido
          {' '}
          <BsCheckLg color="#080" style={{ fontSize: 11 }} />
        </h1>
        <h1 style={{ fontSize: 11, color: '#595959', fontWeight: 'normal' }}>
          Caso seja feito algum registro de IMPEDIMENTO será sinalizado como não cumprido
          {' '}
          <AiFillMinusCircle color="#F00" style={{ fontSize: 11 }} />
        </h1>
      </div>

      {/* {egresses.map((item) => (
        <EgressesRound egress={item} />
      ))} */}
    </Container>
  );
}
