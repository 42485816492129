import React from 'react';
import {
  Control, Controller, FormState, UseFormRegister,
} from 'react-hook-form';
import Select from 'react-select';
import { IProjeto } from '../hooks/Model/Projeto/type';
import { Errors } from './Erro';

type INameField = {
  formState: FormState<any>
  options: {value: string, label: string }[]
  register: UseFormRegister<any>
  name: string
}

export function SelectSala({
  options, formState, name, register,
}:INameField) {
  return (
    <>
      <label htmlFor="">Sala de aula</label>
      <div className="flex-column">
        <select className="form-control" {...register(name)} required>
          <option value="">Selecione</option>
          {options.map((item) => (
            <option value={item.value} key={item.value}>{item.label}</option>
          ))}
        </select>
        {formState.errors.laboratorio && (
        <Errors />
        )}
      </div>
    </>
  );
}
