import { format } from 'date-fns';
import React, { useState } from 'react';
import ReactModal from 'react-modal';
import styled from 'styled-components';
import { TablePadrão, Td, Tr } from '../../../../components/develop-nilton-components/TablePadrão';
import { SpinnerLoading } from '../../../../components/SpinnerLoading';
import { useGetByIdProjeto } from '../hooks/useGetByIdProjeto';
import { data } from '../types';

type ProjetosModal = {
  isOpen: boolean
  handleIsOpen(): void
  id:number
}

const styleModal = {
  content: {
    width: "60%",
    margin: 'auto auto',
  },
  overlay: {
    background: "rgba(1,1,1,.1)",
  },
};

export function ModalVisualizarProjeto({ isOpen, handleIsOpen, id }:ProjetosModal) {
  const { projetos: projeto, load } = useGetByIdProjeto<data>(id);
  const [isOpenModalDetails, setIsOpenModalDetails] = useState(false);

  function handleIsOpenModalDetails() {
    setIsOpenModalDetails(!isOpenModalDetails);
  }

  return (
    <ReactModal
      isOpen={isOpen}
      ariaHideApp={false}
      onRequestClose={handleIsOpen}
      style={styleModal}
    >
      {load && <SpinnerLoading size={30} />}
      {projeto && (
        <div>
          <h2 className="border-bottom">
            Homologar projeto:
            {" "}
            <span className="text-danger">{projeto.nome}</span>
          </h2>
          <div>
            <p className="h5">
              <strong>Nome</strong>
              :
              {' '}
              {projeto?.nome}
            </p>
            <p className="h5">
              <strong>Data inicio</strong>
              :
              {' '}
              {format(new Date(projeto?.data_inicio), "dd/MM/yyyy")}
            </p>
            <p className="h5">
              <strong>Data final</strong>
              :
              {' '}
              {format(new Date(projeto?.data_fim), "dd/MM/yyyy")}
            </p>
            <p className="h5">
              <strong>Parceiro</strong>
              :
              {' '}
              {projeto?.parceiro}
            </p>
            <p className="h5">
              <strong>Objetivo</strong>
              :
              {' '}
              {projeto?.objetivo}
            </p>
            <p className="h5">
              <strong>Origem do recurso</strong>
              :
              {' '}
              {projeto?.origem_recurso}
            </p>
            <p className="h5">
              <strong>Valor envolvido</strong>
              :
              {' '}
              {projeto?.valor_envolvido}
            </p>
            <p className="h5">
              <strong>Laboratorio</strong>
              :
              {' '}
              {projeto?.laboratorio}
            </p>
            <p className="h5">
              <strong>Sala de aula</strong>
              :
              {' '}
              {projeto?.sala_aula === "F" ? "Não" : "Sim"}
            </p>
            <p className="h5">
              <strong>Materiais:</strong>
              {" "}
              <span
                className="text-primary"
                style={{ cursor: "pointer" }}
                onClick={handleIsOpenModalDetails}
              >
                (Ver materiais)

              </span>
            </p>
            <ReactModal
              isOpen={isOpenModalDetails}
              onRequestClose={handleIsOpenModalDetails}
              style={{
                content: {
                  width: "60%",
                  margin: "0 auto",
                },
                overlay: {
                  background: "rgba(0,0,0,.1)",
                },
              }}
            >
              <div>
                <h2>
                  Materiais
                </h2>
                <TablePadrão thead={["#", "Nome do material", "qtd"]} headWidth={["10%", "50%", "30%"]}>
                  {projeto?.materiais.map((item, i:number) => (
                    <Tr>
                      <Td width="10%">{i + 1}</Td>
                      <Td width="50%">{item.material.title}</Td>
                      <Td width="30%">{item.qtd}</Td>
                    </Tr>
                  ))}
                </TablePadrão>
              </div>
            </ReactModal>
          </div>
        </div>
      )}
    </ReactModal>
  );
}

const Title = styled.h2`
  padding: 0 0 10px 0;
  border-bottom: 1px solid black;
`;
