import { Formik } from 'formik';
import React, { useEffect } from 'react';
import {
  Button, Form, FormGroup, Input, Label, ModalFooter,
} from 'reactstrap';
import Select from 'react-select';
import { InputField } from "../../../InputField";

type FormPart6Props = {
  initialValues: any
  handleNextStep: (newData: any) => void
  handleBackStep: () => void
  useStateComportamental: any
  useStatePersonalidade: any
  useStateComportamental_outros: any
  isCreate: boolean
  doencas_existentes?: any
  setDoencasExistentes?: (value: any) => void
  doencas_adquiridas?: any
  setDoencasAdquiridas?: (value: any) => void
}

export function FormPart6(props: FormPart6Props) {
  const {
    handleBackStep, handleNextStep, isCreate, initialValues, useStateComportamental_outros, useStateComportamental, useStatePersonalidade,
    doencas_existentes, setDoencasExistentes, doencas_adquiridas, setDoencasAdquiridas,
  } = props;

  const [comportamental, setComportamental] = useStateComportamental;
  const [personalidade, setPersonalidade] = useStatePersonalidade;
  const [comportamento_outros, set_comportamento_outros] = useStateComportamental_outros;

  const componentSocialForm = [
    { label: 'Necessita de Aluguel Social?', name: 'necessita_aluguel' },
    { label: 'Necessita de Moradia?', name: 'necessita_moradia' },
    { label: 'Possui Residência Fora do Estado', name: 'possui_residencia_fora_estado' },
    // { label: 'Componente  Psicopedagógico', name: 'possui_dislexia' },
    // { label: 'Possui Transtorno de Deficit de atenção e hiperatividade (TDAH)?', name: 'possui_tdah' },
    // { label: 'Possui Déficit de processamento auditivo (DPA)?', name: 'possui_dpa' },
    // { label: 'Possui Discalculia?', name: 'possui_discalculia' },
  ];

  React.useEffect(() => {
    if (initialValues.doencas_pre_existentes && doencas_existentes.length == 0) {
      // @ts-ignore
      setDoencasExistentes(initialValues.doencas_pre_existentes.split('&').map((item: any) => item.trim()));
    }

    if (initialValues.doencas_adquiridas && doencas_adquiridas.length == 0) {
      // @ts-ignore
      setDoencasAdquiridas(initialValues.doencas_adquiridas.split('&').map((item: any) => item.trim()));
    }
  }, [initialValues]);

  React.useEffect(() => {
    console.log(comportamental);
    console.log(personalidade);
  }, [comportamental, personalidade]);

  return (
    <Formik
      initialValues={{ ...initialValues, doencas_pre_existentes: null, doencas_adquiridas: null }}
      onSubmit={handleNextStep}
    >
      {(props) => {
        const {
          handleSubmit, values, setFieldValue,
        } = props;
        return (
          <Form onSubmit={handleSubmit}>
            <div>
              <div>
                <Label style={{ fontWeight: 'bold', fontSize: "20px" }}>Componente Biológicos</Label>

                <InputField
                  iconPlus
                  setFieldValue={setFieldValue}
                  data={doencas_existentes}
                  setData={setDoencasExistentes}
                  label="Doença Pré-Existente"
                  InputHTMLAttributes={{
                    id: 'doencas_pre_existentes',
                    name: 'doencas_pre_existentes',
                    placeholder: 'Insira a doença pré-existente',
                  }}
                />

                {doencas_existentes?.map((doenca: any, index: number) => (
                  <InputField
                    iconTrash
                    data={doencas_existentes}
                    setData={setDoencasExistentes}
                    label={`Doença Pré-Existente ${index + 1}`}
                    InputHTMLAttributes={{
                      id: doenca,
                      name: `doencas_pre_existentes_${index + 1}`,
                      placeholder: 'Insira a doença pré-existente',
                      value: doenca,
                      disabled: true,
                    }}
                  />
                ))}

                <InputField
                  iconPlus
                  setFieldValue={setFieldValue}
                  data={doencas_adquiridas}
                  setData={setDoencasAdquiridas}
                  label="Doença Adquirida"
                  InputHTMLAttributes={{
                    id: 'doencas_adquiridas',
                    name: 'doencas_adquiridas',
                    placeholder: 'Insira a doença adquirida',
                  }}
                />

                {doencas_adquiridas?.map((doenca: any, index: number) => (
                  <InputField
                    iconTrash
                    data={doencas_adquiridas}
                    setData={setDoencasAdquiridas}
                    label={`Doença Adquirida ${index + 1}`}
                    InputHTMLAttributes={{
                      id: doenca,
                      name: `doencas_adquiridas_${index + 1}`,
                      placeholder: 'Insira a doença adquirida',
                      value: doenca,
                      disabled: true,
                    }}
                  />
                ))}
              </div>

              <Label style={{ fontWeight: 'bold', fontSize: "20px" }}>Componentes Psicológicos</Label>
              <div style={{ marginBottom: 10 }}>
                <Label style={{ fontWeight: 'bold', fontSize: "16px" }}>Comportamental</Label>
                <Select
                  closeMenuOnSelect={false}
                  name="componente_psicologico_comportamental"
                  options={comportamental.map((item: any) => ({ ...item, label: item.title }))}
                  defaultValue={comportamental?.filter((comportamental: any) => comportamental.checked)?.map((item: any) => ({ ...item, value: item.title, label: item.title }))}
                  placeholder="Selecione"
                  onChange={(values) => {
                    const aux = values.map((value) => String(value.value));
                    // @ts-ignore
                    setComportamental(comportamental?.map((comportamental) => (aux.includes(String(comportamental.title)) ? ({ ...comportamental, checked: true }) : ({ ...comportamental, checked: false }))));
                  }}
                  noOptionsMessage={({ inputValue }) => "Sem opções"}
                  isMulti
                  theme={(theme) => ({
                    ...theme,
                    borderRadius: 5,
                    borderWidth: 5,
                    colors: {
                      ...theme.colors,
                      // primary25: "#080",
                      primary: "#cce5ff",
                    },
                  })}
                  styles={{
                    // @ts-ignore
                    control: (base, state) => ({
                      ...base,
                      // background: "#023950",
                      // Overwrittes the different states of border
                      borderColor: state.isFocused ? "#c2dbfe" : '#dee2e6',
                      borderWidth: state.isFocused ? 5 : 1,

                      // Removes weird border around container
                      boxShadow: state.isFocused ? null : null,
                      "&:hover": {
                        borderColor: state.isFocused ? "#c2dbfe" : '#dee2e6',
                      },
                      "&:focus": {
                        // Overwrittes the different states of border
                        borderColor: "#c2dbfe",
                      },
                    }),
                  }}
                />
                {/* {comportamental.map((item: any) => (
                  <FormGroup check>
                    <Input
                      type="checkbox"
                      name="componente_psicologico_comportamental"
                      id={`componente_psicologico_comportamental_${item.title}`}
                      checked={item.checked}
                      value={item.value}
                      onChange={() => {
                        setComportamental(comportamental.map((persona:any) => (persona.title == item.title ? ({ ...item, checked: !item.checked }) : persona)));
                      }}
                    />
                    {' '}
                    <Label check for={`componente_psicologico_comportamental_${item.title}`}>
                      {item.title}
                    </Label>
                    {item.title === 'Outros' && item.checked && (
                    <InputField
                      InputHTMLAttributes={{
                        name: 'outro',
                        id: 'outros',
                        onChange: (e) => {
                          set_comportamento_outros(e.target.value);
                        },
                        value: comportamento_outros,
                        placeholder: 'Insira os componentos psicologicos comportamental',
                      }}
                    />
                    )}
                  </FormGroup>
                ))} */}
              </div>

              <div style={{ marginBottom: 10 }}>
                <Label style={{ fontWeight: 'bold', fontSize: "16px" }}>Personalidade</Label>
                <Select
                  closeMenuOnSelect={false}
                  name="componente_psicologico_personalidade"
                  options={personalidade.map((item: any) => ({ ...item, label: item.title }))}
                  defaultValue={personalidade?.filter((personalidade: any) => personalidade.checked)?.map((item: any) => ({ ...item, value: item.title, label: item.title }))}
                  placeholder="Selecione"
                  onChange={(values) => {
                    const aux = values.map((value) => String(value.value));
                    // @ts-ignore
                    setPersonalidade(personalidade?.map((personalidade) => (aux.includes(String(personalidade.title)) ? ({ ...personalidade, checked: true }) : ({ ...personalidade, checked: false }))));
                  }}
                  noOptionsMessage={({ inputValue }) => "Sem opções"}
                  isMulti
                  theme={(theme) => ({
                    ...theme,
                    borderRadius: 5,
                    borderWidth: 5,
                    colors: {
                      ...theme.colors,
                      // primary25: "#080",
                      primary: "#cce5ff",
                    },
                  })}
                  styles={{
                    // @ts-ignore
                    control: (base, state) => ({
                      ...base,
                      // background: "#023950",
                      // Overwrittes the different states of border
                      borderColor: state.isFocused ? "#c2dbfe" : '#dee2e6',
                      borderWidth: state.isFocused ? 5 : 1,

                      // Removes weird border around container
                      boxShadow: state.isFocused ? null : null,
                      "&:hover": {
                        borderColor: state.isFocused ? "#c2dbfe" : '#dee2e6',
                      },
                      "&:focus": {
                        // Overwrittes the different states of border
                        borderColor: "#c2dbfe",
                      },
                    }),
                  }}
                />
                {/* {personalidade.map((item: any) => (
                  <FormGroup check>
                    <Input
                      type="checkbox"
                      name="componente_psicologico_personalidade"
                      id={`componente_psicologico_personalidade${item.title}`}
                      checked={item.checked}
                      value={item.value}
                      onChange={() => {
                        setPersonalidade(personalidade.map((persona:any) => (persona.title == item.title ? ({ ...item, checked: !item.checked }) : persona)));
                      }}
                    />
                    {' '}
                    <Label check for={`componente_psicologico_personalidade${item.title}`}>
                      {item.title}
                    </Label>
                  </FormGroup>
                ))} */}
              </div>

              <div>
                <Label style={{ fontWeight: 'bold', fontSize: "20px" }}>Componente Social</Label>
                <InputField
                  label="Status Econômico"
                  InputHTMLAttributes={{
                    id: 'status_economico',
                    name: 'status_economico',
                    placeholder: 'Insira o status econômico',
                  }}
                />
              </div>

              <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                {componentSocialForm.map((form, index) => (
                  <div style={{ width: ((index + 1) == componentSocialForm.length) ? '34%' : '33%', marginRight: ((index + 1) == componentSocialForm.length) ? 0 : 5 }}>
                    <InputField
                      label={form.label}
                      InputHTMLAttributes={{
                        id: form.name,
                        name: form.name,
                        type: "select",
                        value: values[form.name] || '-',
                        style: { marginRight: (index + 1) == componentSocialForm.length ? 0 : 10 },
                      }}
                      LabelHTMLAttributes={{
                        className: 'mr-3',
                      }}
                      options={(
                        <>
                          <option value="-">
                            -
                          </option>
                          <option value="T">
                            Sim
                          </option>
                          <option value="F">
                            Não
                          </option>
                        </>
                    )}
                    />
                  </div>
                ))}
              </div>

            </div>
            <ModalFooter className="d-flex justify-content-between">
              <div>
                <Button type="button" size="lg" onClick={handleBackStep}>Voltar</Button>
              </div>
              <div>
                <Button style={{ borderColor: '#1B7E9F', background: '#1B7E9F' }} type="submit" color="primary" size="lg">Salvar</Button>
              </div>
            </ModalFooter>
          </Form>
        );
      }}
    </Formik>
  );
}
