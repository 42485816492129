import React from 'react';
import { format } from 'date-fns';
import { IoIosDocument } from 'react-icons/io';
import ReactTooltip from 'react-tooltip';
import { useHistory } from 'react-router-dom';
import { Td, Tr } from '../../../../components/develop-nilton-components/TablePadrão';
import { ITbodyPageHistorico } from '../type';

export function TbodyPageHistorico({
  egresso,
  headWidth,
  acompanhamento_concluidos,
  index,
}:ITbodyPageHistorico) {
  const history = useHistory();
  function handleRedirectIndicadores() {
    history.push({ pathname: "/acompanhamento/indicadores", state: { egresso, acompanhamento_concluidos } });
  }

  return (
    <Tr>
      <Td width={headWidth[0]}>{index}</Td>
      <Td width={headWidth[1]}>{acompanhamento_concluidos.psicopedagogo?.name}</Td>
      <Td width={headWidth[2]}>
        {format(new Date(acompanhamento_concluidos?.data_finalizacao), "dd/MM/yyyy")}
      </Td>
      <Td width={headWidth[3]}>
        <div onClick={handleRedirectIndicadores} data-tip="Realizar acompanhamento">
          <IoIosDocument
            cursor="pointer"
            size={30}
          />
          <ReactTooltip />
        </div>
      </Td>

    </Tr>
  );
}
