import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { BiArrowBack } from 'react-icons/bi';
import { SideBarContainer } from './SideBarEgress.styled';

import persona from '../../assets/personal.png';
import processual from '../../assets/processual.png';
import family from '../../assets/family.png';
import address from '../../assets/address.png';
import professional from '../../assets/professional.png';
import bio from '../../assets/bio.png';
import back from '../../assets/back.png';
import { SectionHeader } from '../SectionHeader';

function SideBar({
  isCreate, tab, setTab, children, setIsOpen,
}: any) {
  const list = {
    title: 'Administrador',
    functions: [
      {
        icon: persona,
        title: 'Dados Pessoais',
      },
      {
        icon: processual,
        title: 'Dados Processuais',
      },
      {
        icon: family,
        title: 'Dados Familiares',
      },
      {
        icon: address,
        title: 'Endereço',
      },
      {
        icon: professional,
        title: 'Perfil Profissional',
      },
      {
        icon: bio,
        title: 'Perfil Biopsicossocial',
      },
      /* {
        icon: back,
        title: 'Retonar aos Egressos',
      }, */
    ],
  };

  const [selected, setSelected] = React.useState<any>(
    {
      icon: '/assets/dashboard.png',
      title: 'Dados Pessoais',
    },
  );

  useEffect(() => {
    if (tab === 1) {
      setSelected({
        icon: '/assets/dashboard.png',
        title: 'Dados Pessoais',
      });
    }
    if (tab === 2) {
      setSelected({
        icon: '/assets/dashboard.png',
        title: 'Dados Processuais',
      });
    }
    if (tab === 3) {
      setSelected({
        icon: '/assets/dashboard.png',
        title: 'Dados Familiares',
      });
    }
    if (tab === 4) {
      setSelected({
        icon: '/assets/dashboard.png',
        title: 'Endereço',
      });
    }
    if (tab === 5) {
      setSelected({
        icon: '/assets/dashboard.png',
        title: 'Perfil Profissional',
      });
    }
    if (tab === 6) {
      setSelected({
        icon: '/assets/dashboard.png',
        title: 'Perfil Biopsicossocial',
      });
    }
  }, [tab]);

  const [actualWidth, setActualWidth] = React.useState(window.innerWidth);

  function resize() {
    // console.log("height: ", window.innerHeight, "px");
    // console.log("width: ", window.innerWidth, "px");
    setActualWidth(window.innerWidth);
  }

  React.useEffect(() => {
    window.onresize = resize;
  }, []);

  return (
    <SideBarContainer>
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <div className="menu-section" style={{ height: '100vh', marginTop: 20 }}>
          {list.functions.map((item, i) => (
            <div
              key={item.title}
              style={{ textDecoration: 'none' }}
            >
              <button
                className="list-menu"
                onClick={() => {
                  if (item.title === 'Retonar aos Egressos') {
                    setIsOpen(false);
                  } else if (!isCreate) {
                    setTab(i + 1);
                    setSelected(item);
                  }
                }}
                style={{ background: selected?.title == item.title ? 'rgba(0,62,93, 0.3)' : '#fff' }}
              >
                {selected?.title == item.title && (
                <div style={{
                  position: 'relative', right: 0, background: 'rgba(0,62,93)', width: 10, height: '100%',
                }}
                />
                )}
                <div
                  style={{
                    paddingLeft: selected?.title == item.title ? 10 : 20, display: "flex", flexDirection: 'row', alignItems: 'center',
                  }}
                >
                  <img src={item.icon} alt={item.title} />
                  <h1 className="text">{item.title}</h1>
                </div>
              </button>
            </div>
          ))}
        </div>

        <div className="vertical" />

        <div
          style={{
            width: `calc(100% - ${actualWidth > 1024 ? '335' : '176'}px)`, paddingLeft: 20, paddingBottom: 20, paddingRight: 20,
          }}
        >
          <SectionHeader
            subtitle={isCreate ? 'Cadastrar Egresso' : 'Editar Egresso'}
            isIconBack={(
              <div onClick={() => setIsOpen(false)} style={{ cursor: "pointer", marginRight: 10 }}>
                <BiArrowBack size={30} />
              </div>
        )}
          />

          {children}
        </div>
      </div>
    </SideBarContainer>
  );
}

export default SideBar;
