import React from 'react';
import styled from 'styled-components';
import { TablePadrão, Td, Tr } from '../../../../components/develop-nilton-components/TablePadrão';
import { Pagination } from '../../../../components/Pagination';
import { SpinnerLoading } from '../../../../components/SpinnerLoading';
import { SubmitResponse } from '../../../../components/SubmitResponse';
import { IProjetos } from '../types';
import { TbodyTableGeral } from './TbodyTableGeral';

export function TableGeral({
  data, meta, handleProjetos, load,
}:IProjetos) {
  return (
    <div>
      <TablePadrão
        thead={['#', 'Nome do projeto', "Nome do pedagogo", "Opções"]}
        headWidth={['10%', '50%', '20%', '20%']}
      >
        {load && (
        <Tr><Td width="100%"><SpinnerLoading /></Td></Tr>
        )}
        {data?.length === 0 && (
          <Tr>
            <Td width="100%">
              <div className="w-100">
                <SubmitResponse submitResponse={{ message: "Não há projeto.", status: "danger", title: "Aviso" }} />
              </div>
            </Td>
          </Tr>
        )}
        {data?.map((projetos, i: number) => (
          <TbodyTableGeral
            key={i}
            index={i + 1}
            headWidth={['10%', '50%', '20%', '20%']}
            projetos={projetos}
            handleProjetos={handleProjetos}
            meta={meta}
          />
        ))}
      </TablePadrão>

      {data && <Pagination meta={meta} setPage={handleProjetos} />}
    </div>
  );
}
