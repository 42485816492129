import React, { useState } from 'react';
import { AiOutlineMenu } from 'react-icons/ai';
import { Table } from 'reactstrap';
import moment from 'moment';
import styled from 'styled-components';
import { IEgresses } from '../../../../../types/IEgresses';

type EgressesFormModal = {
  useStateEgresse: [IEgresses | null, (egresse: IEgresses | null) => void];
};

export default function Form1({ useStateEgresse }: EgressesFormModal) {
  const [isOpenOccurrence, setIsOpenOccurrence] = useState(true);
  const [isOpenRounds, setIsOpenRounds] = useState(true);

  const [egresse, setEgresse] = useStateEgresse;

  React.useEffect(() => {
    console.log(egresse);
  }, []);

  return (
    <div>
      <div
        style={{
          display: 'flex',
          marginTop: 20,
          justifyContent: 'space-between',
          flexDirection: 'column',
          width: '100%',
          background: '#f6f6f6',
        }}
      >
        <h1
          style={{
            margin: 10,
            fontSize: 16,
            fontWeight: 'bold',
          }}
        >
          Fiscalização
        </h1>

        <div
          style={{
            width: '100%',
            borderBottom: 2,
            borderBottomStyle: 'solid',
            borderBottomColor: '#1B7E9F',
          }}
        />
      </div>

      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div
          style={{
            display: 'flex',
            padding: 10,
            alignItems: 'center',
            justifyContent: 'center',
            marginTop: 20,
            width: '25%',
            marginRight: 10,
            borderStyle: 'solid',
            borderColor: '#1B7E9F',
            borderWidth: 2,
            borderRadius: 5,
          }}
        >
          <CardStyle>
            <h1
              style={{
                marginBottom: 0,
                fontSize: 24,
                fontWeight: 'bold',
              }}
            >
              {egresse?.rondas?.total}
            </h1>
            <h2
              style={{
                marginBottom: 0,
                marginLeft: 10,
                marginRight: 10,
                fontSize: 14,
                fontWeight: 'normal',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              Fiscalizações Cumpridas
            </h2>
          </CardStyle>
        </div>

        <div
          style={{
            display: 'flex',
            padding: 10,
            alignItems: 'center',
            justifyContent: 'center',
            marginTop: 20,
            width: '25%',
            marginRight: 10,
            borderStyle: 'solid',
            borderColor: '#1B7E9F',
            borderWidth: 2,
            borderRadius: 5,
          }}
        >
          <CardStyle>
            <h1
              style={{
                marginBottom: 0,
                fontSize: 24,
                fontWeight: 'bold',
              }}
            >
              {egresse?.alvaras?.filter(item => item.executado == 'T').length}
            </h1>
            <h2
              style={{
                marginBottom: 0,
                marginLeft: 10,
                fontSize: 14,
                fontWeight: 'normal',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              Alvarás Cumpridos
            </h2>
          </CardStyle>
        </div>

        <div
          style={{
            display: 'flex',
            padding: 10,
            alignItems: 'center',
            justifyContent: 'center',
            marginTop: 20,
            width: '25%',
            marginRight: 10,
            borderStyle: 'solid',
            borderColor: '#1B7E9F',
            borderWidth: 2,
            borderRadius: 5,
          }}
        >
          <CardStyle>
            <h1
              style={{
                marginBottom: 0,
                fontSize: 24,
                fontWeight: 'bold',
              }}
            >
              {egresse?.pads?.filter(item => item.encaminhado == 'T').length}
            </h1>
            <h2
              style={{
                marginBottom: 0,
                marginLeft: 10,
                fontSize: 14,
                fontWeight: 'normal',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              Notificações Cumpridas
            </h2>
          </CardStyle>
        </div>

        <div
          style={{
            display: 'flex',
            padding: 10,
            alignItems: 'center',
            justifyContent: 'center',
            marginTop: 20,
            width: '25%',
            borderStyle: 'solid',
            borderColor: '#1B7E9F',
            borderWidth: 2,
            borderRadius: 5,
          }}
        >
          <CardStyle>
            <h1
              style={{
                marginBottom: 0,
                fontSize: 24,
                fontWeight: 'bold',
              }}
            >
              {egresse?.rondas?.com_ocorrencia.length}
            </h1>
            <h2
              style={{
                marginBottom: 0,
                marginLeft: 10,
                fontSize: 14,
                fontWeight: 'normal',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              Ocorrências Negativas
            </h2>
          </CardStyle>
        </div>
      </div>

      <div
        style={{
          display: 'flex',
          marginTop: 20,
          justifyContent: 'space-between',
          flexDirection: 'column',
          width: '100%',
          background: '#f6f6f6',
        }}
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <h1
            style={{
              margin: 10,
              fontSize: 16,
              fontWeight: 'bold',
            }}
          >
            Ocorrências Negativas
          </h1>
          <AiOutlineMenu
            onClick={() => setIsOpenOccurrence(!isOpenOccurrence)}
            style={{ marginRight: 10, cursor: 'pointer' }}
          />
        </div>

        <div
          style={{
            width: '100%',
            borderBottom: 2,
            borderBottomStyle: 'solid',
            borderBottomColor: '#1B7E9F',
          }}
        />
      </div>

      {isOpenOccurrence && (
        <>
          <Table style={{ marginTop: 20 }}>
            <tbody>
              {egresse?.rondas?.com_ocorrencia.map(item => (
                <tr>
                  <td style={{ textAlign: 'start' }}>
                    {item?.ocorrencia?.name}
                  </td>
                  <td style={{ textAlign: 'center' }}>{item?.ronda?.title}</td>
                  <td style={{ textAlign: 'end' }}>
                    {item?.data_ocorrencia
                      ? `${moment(item?.data_ocorrencia).format(
                          'DD/MM/YYYY',
                        )} às ${moment(item?.data_ocorrencia).format(
                          'HH[h]:mm[min]',
                        )}`
                      : 'DD/MM/YYYY'}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </>
      )}

      <div
        style={{
          display: 'flex',
          marginTop: 20,
          justifyContent: 'space-between',
          flexDirection: 'column',
          width: '100%',
          background: '#f6f6f6',
        }}
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <h1
            style={{
              margin: 10,
              fontSize: 16,
              fontWeight: 'bold',
            }}
          >
            Histórico de Fiscalizações
          </h1>
          <AiOutlineMenu
            onClick={() => setIsOpenRounds(!isOpenRounds)}
            style={{ marginRight: 10, cursor: 'pointer' }}
          />
        </div>

        <div
          style={{
            width: '100%',
            borderBottom: 2,
            borderBottomStyle: 'solid',
            borderBottomColor: '#1B7E9F',
          }}
        />
      </div>

      {isOpenRounds && (
        <>
          <Table style={{ marginTop: 20 }}>
            <tbody>
              {egresse?.rondas?.rondasTodas.map(item => (
                <>
                  {item?.ronda && (
                    <tr>
                      <td style={{ textAlign: 'start' }}>
                        {item?.ronda?.title}
                      </td>
                      <td style={{ textAlign: 'center' }}>
                        {item.ronda?.responsavel?.name}
                      </td>
                      <td style={{ textAlign: 'end' }}>
                        {item?.data_ocorrencia
                          ? `${moment(item?.data_ocorrencia).format(
                              'DD/MM/YYYY',
                            )} às ${moment(item?.data_ocorrencia).format(
                              'HH[h]:mm[min]',
                            )}`
                          : 'DD/MM/YYYY'}
                      </td>
                    </tr>
                  )}
                </>
              ))}
            </tbody>
          </Table>
        </>
      )}
    </div>
  );
}

const CardStyle = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-itens: center;
  @media only screen and (max-width: 800px) {
    flex-direction: column;
    align-items: center;
  }
`;
