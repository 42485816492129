import React, { useEffect, useState } from 'react';
import api from '../../../../services/api';

export function useReportSocioEconomico(url: string, type: any, year?: string, trimestre?: string) {
  const [report, setReport] = useState<any>(null);

  async function handleSetReport(year?: string, trimestre?: string) {
    const { data } = await api.get(url, {
      params: {
        type,
        trimestre,
        year,
      },
    });
    setReport(data);
  }

  useEffect(() => {
    handleSetReport(year, trimestre);
  }, []);
  return { report, handleSetReport };
}
