/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import api from '../../../../services/api';

export function useGetRoundsConcluidos() {
  const [roundsConcluidos, setRoundsConcluidos] = useState<any>(null);

  async function handleSetRoundsConcluidos(page = 1) {
    try {
      const { data } = await api.get('/rounds', { params: { homologado: "T", page, perPage: 15 } });
      if (data.meta.last_page < page) {
        handleSetRoundsConcluidos(data.meta.last_page);
      } else {
        setRoundsConcluidos(data);
      }
    } catch (error) {
      // @ts-ignore
      throw new Error(error.response.data.message);
    }
  }
  useEffect(() => {
    handleSetRoundsConcluidos();
  }, []);
  return { roundsConcluidos, handleSetRoundsConcluidos };
}
