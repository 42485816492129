import React from 'react';
import { toast, ToastContainer } from 'react-toastify';
import api from '../../../../services/api';

export function useCheckboxAll(checkboxAll:any, handleUpdate: () => Promise<void>, setLoad:any, setTab: any) {
  async function handleAddEgresses() {
    await Promise.all(
      checkboxAll.forEach(async ({ round_id, egress_id, checked }:any) => {
        setLoad(true);
        if (checked) {
          try {
            await api.post('/rounds_egresses', { round_id, egress_id });
            await handleUpdate();
            setTab(2);
            // toast("Egresso adicionado com sucesso", { type: "success" });
          } catch (error) {
            // @ts-ignore
            toast(error.response.data.message, { type: "error" });
          }
          setLoad(false);
        }
      }),
    );

    console.log('terminaro');
  }

  return { handleAddEgresses };
}
