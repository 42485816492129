import React from 'react';
import {
  Modal, ModalBody, ModalHeader, Button, ModalFooter, Form, FormGroup, Label, Input,
} from 'reactstrap';
import { toast } from 'react-toastify';
import { InputSearchNotifyEgresso } from './InputSearchNotifyEgresso';
import { IEgresses } from '../../../../types/IEgresses';
import { Loading } from '../../../../components/new-components/Loading';

import { useEgresses } from '../../../../hooks/Egresses';
import api from '../../../../services/api';

type PadAddModalParams = {
    useStateIsOpen: [boolean, (boolean: boolean) => void];
}

export function PadAddModal(props: PadAddModalParams) {
  const {
    egresses: provider, page, handlePage, search, handleSearch,
  } = useEgresses();

  const {
    useStateIsOpen,
  } = props;

  const [isOpen, setIsOpen] = useStateIsOpen;
  const [egresse, setEgresse] = React.useState<IEgresses | null>(null);
  const [pageP, setPageP] = React.useState(0);
  const [pads, setPads] = React.useState<any>([]);
  const [tab, setTab] = React.useState('1');
  const [isSearch, setInSearch] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const [loadingInitial, setLoadingInitial] = React.useState(false);
  const [id, setId] = React.useState<string | undefined>('');
  const [cpf, setCpf] = React.useState<string | undefined>('');

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  const [pad, setPad] = React.useState({
    processo_pad: "",
    encaminhado: 'F',
  });

  function getPads(page = 0) {
    setLoadingInitial(true);
    api.get(`pads`, {
      params: {
        page: page + 1,
        encaminhado: tab === '1' ? undefined : tab === '2' ? 'F' : tab === '3' ? 'T' : undefined,
        processo_pad: isSearch !== '' ? isSearch : undefined,
        perPage: 15,
      },
    }).then(({ data }) => {
      setPads(data);
      setPageP(data.meta.current_page);
      setLoadingInitial(false);
    });
  }

  function notify() {
    console.log(id, 'oid');
    setLoading(true);

    const formData = new FormData();
    formData.append('data', JSON.stringify({
      egresso_id: id,
      ...pad,
    }));

    api.post(`pads`, formData).then(({ data }) => {
      getPads();
      setLoading(false);
      setPad({
        processo_pad: "",
        encaminhado: 'F',
      });
      setEgresse(null);

      const message = 'Notificação cadastrada com sucesso!';

      toast(message, {
        type: 'success',
      });

      getPads();

      window.location.reload();
    }).catch((error) => {
      setLoading(false);
      const message = error.response.data.message || 'Erro ao cadasrar notificação!';

      toast(message, {
        type: 'error',
      });
    });
  }

  function handleLimit(value:any) {
    if (value.target.value.length > value.target.maxLength) {
      value.target.value = value.target.value.slice(0, value.target.maxLength);
      setPad({ ...pad, processo_pad: value.target.value });
    }
  }

  const callback = (id: string | undefined, cpf: string | undefined) => {
    setId(id);
    setCpf(cpf);
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle} size="lg">
      <ModalHeader toggle={toggle}>Cadastro de Notificação</ModalHeader>

      <Form>
        <ModalBody>

          <InputSearchNotifyEgresso
            data={provider?.data}
            keySearch="nome"
            useStateSearch={[search, handleSearch]}
            id="search"
            onClick={callback}
            label="Nome do egresso"
            placeholder="Pesquise por nome ou CPF"
          />

          <FormGroup>
            <div style={{ marginTop: 20 }}>
              <Label style={{ fontSize: 23, fontWeight: 'bold' }}>
                CPF
              </Label>
              <Input
                placeholder="000.000.000-00"
                disabled
                value={cpf === null ? "000.000.000-00" : cpf}
                onChange={(e) => setCpf(e.target.value)}
              />
            </div>

            <div style={{ marginTop: 20 }}>
              <Label style={{ fontSize: 23, fontWeight: 'bold' }}>
                Número do processo
              </Label>
              <Input
                placeholder="Digite o número do processo"
                type="number"
                maxLength={30}
                onChange={handleLimit}
              />
            </div>
          </FormGroup>

        </ModalBody>
        <ModalFooter className="d-flex justify-content-between">
          <Button color="secondary" onClick={toggle}>
            Voltar
          </Button>
          {' '}
          <Button color="primary" onClick={() => { notify(); }}>
            Concluir
          </Button>
        </ModalFooter>
      </Form>

    </Modal>
  );
}
