import { PageError } from "../../components/PageError";
import { Egressos } from "../../pages/Adm/Egressos";
import { HomologarProjeto } from "../../pages/Adm/HomologarProjeto";
import { PageEditarProjeto } from "../../pages/Adm/HomologarProjeto/components/PageEditarProjeto";
import { HomologarRondaAdm } from "../../pages/Adm/HomologarRonda";
import { PageEgresses } from "../../pages/Adm/HomologarRonda/components/PageEgresses";
import { PageHomogarRound } from "../../pages/Adm/HomologarRonda/components/PageHomogarRound";
import { Usuarios } from "../../pages/Adm/Usuarios";
import { Dashboard } from "../../pages/Dashboard";
import { CadastrarAlvara } from "../../pages/Fiscal/CadastrarAlvara";
import { NotificarEgresso } from "../../pages/Fiscal/NotificarEgresso";

export const admRoutes = [
  { path: "/", component: Dashboard },
  { path: "/dashboard", component: Dashboard },
  { path: "/usuarios", component: Usuarios },
  { path: "/egressos", component: Egressos },
  { path: "/homologar-ronda", component: HomologarRondaAdm },
  { path: "/homologar-ronda/confirmar-egressos", component: PageHomogarRound },
  { path: "/homologar-ronda/egressos", component: PageEgresses },
  { path: "/homologar_pro", component: HomologarProjeto },
  { path: "/homologar_pro/editar", component: PageEditarProjeto },
  { path: "/notificar-egresso", component: NotificarEgresso },
  { path: "/cadastrar-alvara", component: CadastrarAlvara },
  { path: "*", component: PageError },
];
