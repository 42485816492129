import React, { useState } from 'react';
import ReactLoading from "react-loading";

import { Container } from './styles';
import LoginImg from '../../assets/login.png';
import footerIcone from '../../assets/logo-ap.png';
import { useAuth } from '../../hooks/auth';

import './style.css';

export const Login = () => {
  const [cpf, setCPF] = useState('');
  const [password, setPassword] = useState('');
  const [errorCPF, setErrorCPF] = useState("");
  const [errorPassword, setErrorPassword] = useState("");
  const { signIn, loading } = useAuth();
  async function handleSubmitLogin() {
    if (cpf.length !== 14) {
      setErrorCPF('CPF obrigatório.');
    } else {
      setErrorCPF('');
    }
    if (password == "") {
      setErrorPassword('Senha obrigatória.');
    } else if (password.length < 8) {
      setErrorPassword('Mínimo 8 caracteres.');
    } else {
      setErrorPassword('');
    }

    if (cpf.length === 14 && password != "" && password.length >= 8) {
      signIn({ cpf, password });
    }
  }

  function cpf_mask(value: string) {
    return value
      .replace(/\D/g, '')
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d{1,2})/, '$1-$2')
      .replace(/(-\d{2})\d+?$/, '$1');
  }

  return (
    <div
      style={{ backgroundColor: "#FFF" }}
      className="PageLogin container-full"
    >
      <div
        className="side-login"
        style={{
          display: 'flex', flexDirection: 'column', justifyContent: 'space-between',
        }}
      >
        <div style={{
          marginLeft: 20, marginTop: 20, display: "flex", flexDirection: 'row', alignItems: 'center',
        }}
        >
          <img src="/assets/social_icon.png" alt="icone-albergado" />
          <div style={{ marginLeft: 10, marginTop: 10 }}>
            <h1 style={{ color: '#1B7E9F', fontSize: 18, fontWeight: 'bold' }}>E-ALBERGADO</h1>
            <h2 style={{
              color: '#1B7E9F', fontSize: 16, fontWeight: 'normal', marginTop: -5,
            }}
            >
              Sistema de controle de egressos
            </h2>
          </div>
        </div>
        <div>
          <Container>
            <div className="login-form">
              <div style={{ justifyContent: 'center', width: '100%' }}>
                <div style={{
                  display: "flex",
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  width: '100%',
                }}
                >

                  <div
                    className="content"
                  >
                    <div style={{
                      display: "flex", flexDirection: 'column', alignItems: 'center', paddingBottom: 15,
                    }}
                    >
                      <h1 style={{ fontWeight: 'bold', fontSize: 24, color: '#1B7E9F' }}>Bem-vindo de volta!</h1>
                      <h2 style={{ fontSize: 12, color: '#1B7E9F' }}>Por favor, insira suas credenciais.</h2>
                    </div>

                    <label style={{
                      fontWeight: 'bold', color: errorCPF ? '#E44B39' : '#1B7E9F',
                    }}
                    >
                      CPF
                    </label>
                    <div className="input-size">
                      <input
                        style={{
                          padding: 10, borderRadius: 5, height: 35, marginBottom: 3, borderColor: errorCPF ? '#E44B39' : '#1B7E9F',
                        }}
                        type="text"
                        value={cpf}
                        onChange={(e) => setCPF(cpf_mask(e.target.value))}
                        placeholder="Login"
                        onKeyPress={(e) => {
                          if (e.charCode == 13) {
                            if (!loading) {
                              handleSubmitLogin();
                            }
                          }
                        }}
                      />
                      <text style={{ fontSize: 14, color: '#E44B39', marginBottom: 5 }}>{errorCPF}</text>
                    </div>
                    <label style={{ fontWeight: 'bold', color: errorPassword ? '#E44B39' : '#1B7E9F' }}>Senha</label>
                    <input
                      className="input-size"
                      style={{
                        padding: 10, borderRadius: 5, height: 35, marginRight: 10, marginBottom: 3, borderColor: errorPassword ? '#E44B39' : '#1B7E9F',
                      }}
                      value={password}
                      type="password"
                      onChange={(e) => setPassword(e.target.value)}
                      placeholder="Senha"
                      onKeyPress={(e) => {
                        if (e.charCode == 13) {
                          if (!loading) {
                            handleSubmitLogin();
                          }
                        }
                      }}
                    />
                    <text style={{ fontSize: 14, color: '#E44B39', marginBottom: 5 }}>{errorPassword}</text>
                  </div>

                  <div style={{ paddingRight: 20, paddingLeft: 20, marginTop: -30 }}>
                    <div style={{ display: "flex", flexDirection: 'row', alignItems: 'center' }}>
                      <input style={{ color: '#1B7E9F' }} type="checkbox" id="remember" name="remember" />
                      <label style={{ color: '#1B7E9F', marginLeft: 5, marginTop: 7 }}>Lembrar conta</label>
                    </div>

                    {/* {loading ? (
<ReactLoading type="spin" height="5%" width="5%" color="#1B7E9F" />
)
: ( */}
                    {/* <div
                    className="input-size"
                    style={{
                      display: "flex", height: 35, alignItems: 'center', justifyContent: "center", cursor: 'pointer', backgroundColor: "#1B7E9F", padding: 10, borderRadius: 5,
                    }}

                    onClick={!loading ? () => handleSubmit(handleSubmitLogin) : () => {}}
                  >
                    {loading ? <div style={{ marginBottom: 2 }}><ReactLoading type="spin" height="25px" width="25px" color="#FFF" /></div> : <h1 style={{ color: '#fff', fontSize: 14, marginTop: 10 }}>Entrar</h1>}
                  </div> */}
                    {/* )} */}
                    <button
                      onClick={() => handleSubmitLogin()}
                      className="input-size"
                      style={{
                        display: "flex", height: 35, alignItems: 'center', justifyContent: "center", cursor: 'pointer', backgroundColor: "#1B7E9F", padding: 10, borderRadius: 5, color: '#fff', borderColor: '#FFF',
                      }}
                    >
                      {loading ? <div style={{ marginBottom: 2 }}><ReactLoading type="spin" height="25px" width="25px" color="#FFF" /></div> : <h1 style={{ color: '#fff', fontSize: 14, marginTop: 10 }}>Entrar</h1>}
                    </button>
                  </div>

                </div>

              </div>

            </div>

          </Container>

        </div>

        <div />
      </div>

      <div style={{
        display: 'flex', flexDirection: 'column', justifyContent: "space-between", backgroundColor: "#1B7E9F",
      }}
      >
        <div style={{ height: '20%', display: 'flex', justifyContent: 'flex-end' }}>
          <img
            style={{
              width: 120, height: 28, margin: 20,
            }}
            src={footerIcone}
            alt="footer"
          />
        </div>
        <div style={{ height: '60%', display: 'flex', justifyContent: 'center' }}>
          <img style={{ width: '80%', height: '100%' }} src={LoginImg} alt="login" />
        </div>
        <div style={{ height: '20%' }} />
      </div>
    </div>
  );
};
