import React, { useEffect, useState } from 'react';
import api from '../../../../services/api';

export function useGetCourses() {
  const [courses, setCourses] = useState<any>(null);
  const [load, setLoad] = useState(false);

  async function handleSetCourses(page = 1, habilidadeOrNameOrAreaOrBairroOrLocalidadeOrMunicipio?:string) {
    setLoad(true);
    const { data } = await api.get('/courses_egresses', { params: { page, habilidadeOrNameOrAreaOrBairroOrLocalidadeOrMunicipio } });
    setCourses(data);
    setLoad(false);
  }

  useEffect(() => {
    handleSetCourses();
  }, []);
  return { courses, handleSetCourses, load };
}
