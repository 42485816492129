import React, { useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import api from '../../../../../services/api';
import Header from '../../../components/Header';

import { SpinnerLoading } from '../../../../../components/SpinnerLoading';
import { useAuth } from '../../../../../hooks/auth';

interface IPad {
  id: number;
  processo_pad: number;
  egress: {
    nome: string;
    cep: string;
    endereco: string;
    bairro: string;
    estado: string;
    municipio: string;
  };
  responsavel_execucao: any;
  encaminhado: string;
  data_cumprimento: string;
  registro_seuu: string;
  attachment: {
    name:string;
    url_file: string;
  };
  observacao: string;
}

interface IProps {
  pad: any;
  setPad: (value: IPad | null) => void;
}

function PadDetail({ pad, setPad } : IProps) {
  const { user } = useAuth();

  const [file, setFile] = useState<any>(null);
  const [loading, setLoading] = useState(false);

  function cadastrar(): void {
    setLoading(true);
    const formData = new FormData();
    formData.append('data', JSON.stringify({ ...pad, responsable_id_execucao: user.id }));
    // @ts-ignore
    formData.append('anexo', file);

    api.put(`pads/${pad.processo_pad}`, formData).then(({ data }) => {
      const message = `PAD ${pad.encaminhado == 'T' ? 'encaminhado' : 'atualizado'} com sucesso!`;

      toast(message, {
        type: 'success',
      });

      setPad(null);
      setFile(null);

      setLoading(false);
    }).catch((error) => {
      setLoading(false);
      const message = error.response.data.message || `Erro ao ${pad.encaminhado == 'T' ? 'encaminhar' : 'atualizar'} PAD!`;

      toast(message, {
        type: 'error',
      });
    });
  }

  return (
    <>
      <Header route="notificar-egresso" name="Encaminhar número do processo" pad={pad} />

      <div>
        <div style={{ display: 'flex', marginTop: 40 }}>
          <h1 style={{ fontSize: 20 }}>Nome do Egresso:</h1>
          <h1 style={{ fontSize: 20, marginLeft: 10, fontWeight: 'normal' }}>{pad?.egress?.nome}</h1>
        </div>

        <div style={{ display: 'flex' }}>
          <h1 style={{ fontSize: 20 }}>Endereço do Egresso:</h1>
          <h1 style={{ fontSize: 20, marginLeft: 10, fontWeight: 'normal' }}>
            {`${pad?.egress?.endereco}, ${pad?.egress?.bairro}, ${pad?.egress?.municipio} - ${pad?.egress?.estado}`}
          </h1>
        </div>

        <div style={{ display: 'flex', marginTop: 10, alignItems: 'center' }}>
          <h1 style={{ fontSize: 20 }}>Executar?</h1>
          <div style={{
            display: 'flex', alignItems: 'center', justifyContent: 'center', marginLeft: 10,
          }}
          >
            <input
              onClick={() => {
                setPad({ ...pad, encaminhado: 'T' });
              }}
              style={{ marginBottom: 5 }}
              checked={pad.encaminhado == 'T'}
              type="radio"
              id="encaminhado"
              name="encaminhado"
              value="encaminhado"
            />
            <label htmlFor="encaminhado">Sim</label>

            <input
              onClick={() => {
                setPad({ ...pad, encaminhado: 'F' });
              }}
              style={{ marginBottom: 5, marginLeft: 10 }}
              checked={pad.encaminhado == 'F'}
              type="radio"
              id="encaminhado"
              name="encaminhado"
              value="encaminhado"
            />
            <label htmlFor="encaminhado">Não</label>
          </div>
        </div>

        <h2 style={{ fontSize: 11, color: '#595959', fontWeight: "normal" }}>Ao marcar SIM e confirmando no botão ENCAMINHAR a situação do processo muda no sistema e a data será registrada também</h2>

        {/* <div style={{ display: 'flex', marginTop: 20, alignItems: 'center' }}>
          <h1 style={{ fontSize: 20 }}>Registrado no sistema SEUU?</h1>
          <div style={{
            display: 'flex', alignItems: 'center', justifyContent: 'center', marginLeft: 10,
          }}
          >
            <input
              onClick={() => {
                setPad({ ...pad, registro_seuu: 'T' });
              }}
              style={{ marginBottom: 8 }}
              checked={pad.registro_seuu == 'T'}
              type="radio"
              id="seuu"
              name="seuu"
              value="seuu"
            />
            <label htmlFor="seuu">Sim</label>

            <input
              onClick={() => {
                setPad({ ...pad, registro_seuu: 'F' });
              }}
              style={{ marginBottom: 8, marginLeft: 10 }}
              checked={pad.registro_seuu == 'F'}
              type="radio"
              id="seuu"
              name="seuu"
              value="seuu"
            />
            <label htmlFor="seuu">Não</label>
          </div>
        </div> */}

        <h2 style={{ fontSize: 11, color: '#595959', fontWeight: "normal" }}>Sinalize se este registro consta no sistema</h2>

        <h1 style={{ fontSize: 20, marginTop: 20 }}>Arquivos que fazem parte deste processo:</h1>

        <div
          style={{
            background: '#efefef', padding: 20,
          }}
          className="content"
        >
          <div>
            <input
              onChange={(e) => {
              // @ts-ignore
                setFile(e.target.files[0]);
              }}
              disabled={!!pad.data_cumprimento}
              type="file"
              name="file"
              id="file"
              className="inputfile"
            />
            <div style={{
              display: 'flex', width: '40%', justifyContent: 'center', backgroundColor: "#ccc", padding: 10, borderRadius: 5,
            }}
            >
              <label style={{ cursor: "pointer", marginTop: 10 }} htmlFor="file">{file ? file.name : 'Clique para anexar arquivos'}</label>
            </div>
          </div>

          {pad.attachment && (
          <>
            <h1 style={{ fontSize: 14, marginTop: 20 }}>Clique no link abaixo para visualizar o arquivo anexado (caso queira alterar o arquivo só clicar no botão acima):</h1>
            <label onClick={() => window.open(pad.attachment.url_file, '_blank')} style={{ cursor: "pointer", color: '#007BFF' }} htmlFor="file">{pad.attachment.name ? pad.attachment.name : 'Clique para anexar arquivos'}</label>
          </>
          )}

        </div>

        <h2 style={{
          fontSize: 11, color: '#595959', fontWeight: "normal", marginTop: 10,
        }}
        >
          Arquivos utilizados para apoiar o processo, podem ser usados documentos até o limite de 100 MB no total, caso passe o sistema avisará
        </h2>

        <h1 style={{ fontSize: 20, marginBottom: 5, marginTop: 20 }}>Observação</h1>
        <h2 style={{ fontSize: 11, color: '#595959', fontWeight: "normal" }}>Registro de problemas que impediram o encaminhamento</h2>

        <textarea
          style={{
            marginBottom: 20, borderRadius: 5, padding: 10, width: '100%',
          }}
          value={pad.observacao}
          onChange={(e) => setPad({ ...pad, observacao: e.target.value })}
          rows={5}
        />

        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <div
            onClick={() => setPad(null)}
            style={{
              display: "flex", width: '20%', justifyContent: "center", cursor: 'pointer', backgroundColor: "#f00", padding: 10, borderRadius: 5,
            }}
          >
            <h1 style={{ color: '#fff', fontSize: 14, marginTop: 10 }}>{!pad.data_cumprimento ? 'Cancelar' : 'Fechar'}</h1>
          </div>

          {!pad.data_cumprimento
          && (
          <div
            onClick={cadastrar}
            style={{
              display: "flex", width: '20%', marginLeft: 10, justifyContent: "center", cursor: 'pointer', backgroundColor: "#080", padding: 10, borderRadius: 5,
            }}
          >
            {!loading ? <h1 style={{ color: '#fff', fontSize: 14, marginTop: 10 }}>{pad.encaminhado == 'T' ? 'Encaminhar' : 'Atualizar'}</h1> : <SpinnerLoading color="#fff" size={30} />}
          </div>
          )}
        </div>
      </div>
      <ToastContainer />
    </>
  );
}

export default PadDetail;
