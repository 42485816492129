import React, { useEffect, useState } from 'react';
import { format } from 'date-fns';
import ReactTooltip from 'react-tooltip';
import { AiFillMinusCircle } from 'react-icons/ai';
import { toast } from 'react-toastify';
import {
  Td,
  Tr,
} from '../../../../components/develop-nilton-components/TablePadrão';
import { OptionsTable } from './OptionsTable';
import { useAuth, usePermission } from '../../../../hooks/auth';
import api from '../../../../services/api';
import { ModalConfirmRemoverRonda } from './ModalConfirmRemoverRonda';

type PropsTable = {
  item: any;
  handleCondicionStatus(item: any): React.ReactNode;
  handleCondicionSendHomologar(item: any): React.ReactNode;
  widthBody: (string | number)[];
  handleUpdate(): void | Promise<void>;
  roundsGeral?: any;
};

export function TbodyHomologaçãoGeral({
  item,
  handleCondicionStatus,
  widthBody,
  handleCondicionSendHomologar,
  handleUpdate,
  roundsGeral,
}: PropsTable) {
  const { user } = useAuth();
  const { title } = usePermission();
  const [removeWarning, setRemoveWarning] = useState(false);
  function handleRemoveWarning() {
    setRemoveWarning(!removeWarning);
  }

  const [isLoad, setIsLoad] = useState(false);

  async function deleteRound(id: string) {
    // const resultado =
    //   title === 'master' && user.id !== item.responsavel.id
    //     ? window.confirm(`Deseja remover uma ronda de outro fiscal ?`)
    //     : true;
    // if (resultado == true) {
    const functionThatReturnPromise = (id: string) =>
      new Promise(resolve => {
        setIsLoad(true);

        if (roundsGeral) {
          api.delete(`/rounds/${id}`).then(({ data }) => {
            setIsLoad(false);
            roundsGeral.setRounds({
              ...roundsGeral.rounds,
              data: roundsGeral.rounds.data.filter(
                (item: any) => item.id != id,
              ),
            });
            resolve(true);
          });
        }
      });
    handleRemoveWarning();
    toast.promise(functionThatReturnPromise(id), {
      pending: 'Removendo...',
      error: { render: ({ data }: any) => data },
      success: 'Ronda removida com sucesso',
    });
    // }
  }

  return (
    <Tr key={item.id}>
      <Td
        data-tip={
          title === 'master' && user.id !== item.responsavel.id
            ? `Ronda pertencente ao fiscal: ${item.responsavel.name}`
            : undefined
        }
        style={{
          color:
            title === 'master' && user.id !== item.responsavel.id
              ? 'rgba(201, 19, 19, 0.6)'
              : undefined,
        }}
        width={widthBody[0]}
      >
        {item.title?.substring(0, 20)}
        <ReactTooltip />
      </Td>
      <Td width={widthBody[1]}>{item.egressos.length}</Td>
      <Td width={widthBody[2]}>
        {format(new Date(item.data_ronda), 'dd/MM/yyyy')}
      </Td>
      <Td width={widthBody[3]}>{handleCondicionStatus(item)}</Td>
      <Td style={{ alignItems: 'center' }} width={widthBody[4]}>
        {handleCondicionSendHomologar(item)}
        {item.homologado == 'T' ||
        (item.em_homologacao === 'T' && item.homologado === 'F') ? (
          <OptionsTable item={item} handleUpdate={handleUpdate} disable />
        ) : (
          <OptionsTable item={item} handleUpdate={handleUpdate} />
        )}
        {(title === 'master' ||
          (title === 'Fiscal' && item?.em_homologacao === 'F')) && (
          <div
            data-tip="Remover ronda"
            style={{ marginLeft: 10 }}
            onClick={handleRemoveWarning}
          >
            <AiFillMinusCircle
              color="rgba(201, 19, 19, 0.6)"
              size={22}
              cursor="pointer"
            />
          </div>
        )}
      </Td>
      <ReactTooltip />
      <ModalConfirmRemoverRonda
        title="Confirmar reamoção?"
        action={() =>
          !isLoad &&
          deleteRound(item.id)
            .then(() => {})
            .catch(() => {})
        }
        handleIsOpen={handleRemoveWarning}
        isOpen={removeWarning}
      />
    </Tr>
  );
}
