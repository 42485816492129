import React, { useState, useEffect } from 'react';
import { Formik } from 'formik';
import {
  Button,
  Form,
  FormGroup,
  Input,
  Label,
  ModalFooter,
  Spinner,
} from 'reactstrap';
import { toast } from 'react-toastify';
import { v4 as uuidv4, validate as uuidValidate } from 'uuid';
import { te } from 'date-fns/locale';
import {
  AiFillPlusCircle,
  AiFillSave,
  AiOutlineArrowDown,
} from 'react-icons/ai';
import { FaTrashAlt } from 'react-icons/fa';
import moment from 'moment';
import { IoIosArrowDropdown, IoIosArrowDropup } from 'react-icons/io';
import { InputField } from '../../../InputField';
import { mask_cep } from '../../../../../utils/mask_cep';
import apiCep from '../../../../../services/apiCep';
import apiMunicipios from '../../../../../services/apiMunicipios';
import api from '../../../../../services/api';

import { estados } from '../../../../../utils/Estados';
import { municipios } from '../../../../../utils/Municipios';

type FormPart1Props = {
  initialValues: any;
  handleNextStep: (newData: any) => void;
  handleBackStep: () => void;
  useStateCourses: [any[] | undefined, (courses: any[]) => void];
  enderecos: any;
  setEnderecos: (addr: any) => void;
  isCreate: boolean;
};

export function FormPart4(props: FormPart1Props) {
  const {
    initialValues,
    isCreate,
    handleNextStep,
    handleBackStep,
    useStateCourses,
    enderecos,
    setEnderecos,
  } = props;

  const [cursos, setCursos] = useStateCourses;

  const [states, setStates] = useState([
    'Escolha um estado',
    'AC',
    'AL',
    'AP',
    'AM',
    'BA',
    'CE',
    'DF',
    'ES',
    'GO',
    'MA',
    'MT',
    'MS',
    'MG',
    'PA',
    'PB',
    'PR',
    'PE',
    'PI',
    'RJ',
    'RN',
    'RS',
    'RO',
    'RR',
    'SC',
    'SP',
    'SE',
    'TO',
  ]);

  const [zones, setZones] = useState([
    'Selecione uma zona',
    'Norte',
    'Sul',
    'Leste',
    'Oeste',
  ]);

  async function deleteAddress(address: any) {
    try {
      if (uuidValidate(address)) {
        setEnderecos(enderecos.filter((item: any) => item.id != address));
      } else {
        const { data } = await api.delete(`/address/${address}`);
        toast.success(data.message);
        setEnderecos(
          enderecos.filter((item: any) => Number(item.id) != Number(address)),
        );
      }
    } catch (error) {
      // @ts-ignore
      toast.error(error.response.data.message);
    }
  }

  async function editAddress(address: any) {
    try {
      if (uuidValidate(address.id)) {
        const { data } = await api.post(`/address`, {
          nome_endereco: address.nome_endereco,
          endereco: address.endereco,
          complemento: address.complemento,
          cep: address.cep,
          zona: address.zona,
          bairro: address.bairro,
          estado: address.estado,
          municipio: address.municipio,
          numero_endereco: address.numero_endereco,
          egress_id: initialValues.id,
          atual: address?.atual || false,
        });
        toast.success('Salvo com sucesso!');
        setEnderecos(
          enderecos.map((item: any) => (item.id == address.id ? data : item)),
        );
      } else {
        // delete address.checked;
        const { data } = await api.put(`/address/${address.id}`, {
          nome_endereco: address.nome_endereco,
          bairro: address.bairro,
          estado: address.estado,
          zona: address.zona,
          municipio: address.municipio,
          complemento: address.complemento,
          endereco: address.endereco,
          numero_endereco: address.numero_endereco,
          cep: address.cep,
          atual: address.atual,
        });
        toast.success('Editado com sucesso!');
      }
    } catch (error) {
      // @ts-ignore
      toast.error(error.response.data.message);
    }
  }

  useEffect(() => {
    if (localStorage.getItem('enderecos') != null) {
      setEnderecos(JSON.parse(localStorage.getItem('enderecos') as string));
    } else {
      setEnderecos(
        initialValues?.endereco?.map((item: any) => ({
          ...item,
          checked: false,
        })) || [],
      );
    }
  }, [initialValues]);

  async function editActualAddress(address: any) {
    try {
      if (!uuidValidate(address.id)) {
        // delete address.checked;
        await api.put(`/address/${address.id}`, {
          nome_endereco: address.nome_endereco,
          bairro: address.bairro,
          estado: address.estado,
          municipio: address.municipio,
          complemento: address.complemento,
          endereco: address.endereco,
          zona: address.zona,
          numero_endereco: address.numero_endereco,
          cep: address.cep,
          atual: address.atual,
        });
      }
    } catch (error) {
      //
    }
  }

  useEffect(() => {
    enderecos.map((item: any) => editActualAddress(item));
    localStorage.setItem('enderecos', JSON.stringify(enderecos));
  }, [enderecos]);

  const searchCep = (cep: string, addr: any) => {
    if (cep.length === 9) {
      api.get(apiCep(cep)).then(res => {
        const { data } = res;
        if (data.erro === 'true') {
          toast.error('Preencha o endereço manualmente');
        }

        setEnderecos(
          enderecos.map((end: any) =>
            end.id == addr.id
              ? {
                  ...end,
                  cep: mask_cep(cep),
                  municipio: data?.localidade || '',
                  endereco: data?.logradouro || '',
                  bairro: data?.bairro,
                  estado: data?.uf,
                }
              : end,
          ),
        );
        // setFieldValue('endereco', data.logradouro ?? "");
        // setFieldValue('estado', data.uf ?? "");
        // setFieldValue('bairro', data.bairro ?? "");

        return data;
      });
    } else {
      setEnderecos(
        enderecos.map((end: any) =>
          end.id == addr.id
            ? {
                ...end,
                cep: mask_cep(cep),
              }
            : end,
        ),
      );
    }
  };
  const [municipiosOptions, setMunicipiosOptions] = useState([]);
  const [filterMunicipios, setFilterMunicipios] = useState([]);
  const [loadingMunicipios, setLoadingMunicipios] = useState(false);
  const searchMunicipio = (uf: string, addr: any) => {
    if (!uf.startsWith('Escolha')) {
      setEnderecos(
        enderecos.map((end: any) =>
          end.id == addr.id ? { ...end, estado: uf } : end,
        ),
      );
      setLoadingMunicipios(true);
      api.get(apiMunicipios(uf)).then(res => {
        const { data } = res;
        if (data.erro === 'true') {
          toast.error(
            'Não foi possível encontrar os municípios relacionados a este estado',
          );
        }
        setMunicipiosOptions(data.map((end: any) => end.nome));

        setLoadingMunicipios(false);
        return data;
      });
    }
  };

  const searchMunicipioOptions = (municipio: string, addr: any) => {
    setEnderecos(
      enderecos.map((end: any) =>
        end.id == addr.id ? { ...end, municipio } : end,
      ),
    );
    if (municipio.length > 2) {
      const filter = municipiosOptions?.filter(value =>
        String(value).toUpperCase().includes(String(municipio).toUpperCase()),
      );
      setFilterMunicipios(filter);
    } else {
      setFilterMunicipios([]);
    }
  };

  const selectMunicipioOption = (municipio: string, addr: any) => {
    setEnderecos(
      enderecos.map((end: any) =>
        end.id == addr.id ? { ...end, municipio } : end,
      ),
    );

    setFilterMunicipios([]);
  };

  const selectZonaOption = (zona: string, addr: any) => {
    if (!zona.startsWith('Selecione')) {
      setEnderecos(
        enderecos.map((end: any) =>
          end.id == addr.id ? { ...end, zona } : end,
        ),
      );
    } else {
      setEnderecos(
        enderecos.map((end: any) =>
          end.id == addr.id ? { ...end, zona: '' } : end,
        ),
      );
    }

    setFilterMunicipios([]);
  };

  return (
    <Formik initialValues={initialValues} onSubmit={handleNextStep}>
      {props => {
        const { handleSubmit, values, setFieldValue } = props;

        return (
          <Form onSubmit={handleSubmit}>
            <div>
              <div
                onClick={() =>
                  setEnderecos([
                    {
                      id: uuidv4(),
                      nome_endereco: '',
                      cep: '',
                      municipio: '',
                      endereco: '',
                      bairro: '',
                      estado: '',
                      numero_endereco: '',
                      complemento: '',
                      atual: false,
                      checked: true,
                    },
                    ...enderecos,
                  ])
                }
                style={{
                  cursor: 'pointer',
                  marginBottom: 20,
                  display: 'flex',
                  flexDirection: 'row',
                  padding: 10,
                  background: '#f1f1f1',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <AiFillPlusCircle size={20} color="#ccc" />
                <h1
                  style={{
                    marginLeft: 5,
                    marginBottom: 0,
                    color: '#1B7E9F',
                    fontSize: 14,
                  }}
                >
                  Insira um endereço
                </h1>
              </div>

              {enderecos?.map((addr: any) => (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: '100%',
                    background: '#f1f1f1',
                    padding: 10,
                    marginBottom: 20,
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      padding: 10,
                      justifyContent: 'space-between',
                      alignItems: 'flex-start',
                    }}
                  >
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <input
                        style={{ zoom: 1.5 }}
                        onClick={() => {
                          setEnderecos(
                            enderecos.map((end: any) =>
                              end.id == addr.id
                                ? {
                                    ...end,
                                    atual: true,
                                  }
                                : { ...end, atual: false },
                            ),
                          );
                        }}
                        type="checkbox"
                        id="scales"
                        name="scales"
                        checked={addr.atual}
                      />
                      <h1
                        style={{
                          fontSize: 16,
                          fontWeight: 'bold',
                          marginLeft: 5,
                          marginBottom: 0,
                        }}
                      >
                        {addr.nome_endereco}
                      </h1>
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <h1
                        style={{
                          fontSize: 14,
                          fontWeight: 'bold',
                          marginBottom: 0,
                          marginRight: 10,
                        }}
                      >
                        {`Endereço registrado em: ${moment(
                          addr?.created_at,
                        ).format('DD/MM/YYYY')}`}
                      </h1>

                      {addr.checked && (
                        <IoIosArrowDropdown
                          style={{ cursor: 'pointer' }}
                          size={20}
                          onClick={() =>
                            setEnderecos(
                              enderecos.map((ad: any) =>
                                ad.id == addr.id
                                  ? { ...ad, checked: !ad.checked }
                                  : ad,
                              ),
                            )
                          }
                        />
                      )}
                      {!addr.checked && (
                        <IoIosArrowDropup
                          style={{ cursor: 'pointer' }}
                          size={20}
                          onClick={() =>
                            setEnderecos(
                              enderecos.map((ad: any) =>
                                ad.id == addr.id
                                  ? { ...ad, checked: !ad.checked }
                                  : ad,
                              ),
                            )
                          }
                        />
                      )}
                    </div>
                  </div>

                  {addr.checked && (
                    <div style={{ padding: 10 }}>
                      <InputField
                        label="Nome do Endereço"
                        InputHTMLAttributes={{
                          id: 'endereco',
                          name: 'endereco',
                          placeholder: 'Nome do endereço',
                          value: addr.nome_endereco,
                          onChange: e => {
                            setEnderecos(
                              enderecos.map((end: any) =>
                                end.id == addr.id
                                  ? { ...end, nome_endereco: e.target.value }
                                  : end,
                              ),
                            );
                          },
                        }}
                        LabelHTMLAttributes={{
                          style: {},
                        }}
                      />

                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          width: '100%',
                        }}
                      >
                        <div style={{ width: '50%', marginRight: 5 }}>
                          <InputField
                            label="CEP"
                            InputHTMLAttributes={{
                              id: 'cep',
                              name: 'cep',
                              onChange: e => {
                                // setFieldValue('cep', mask_cep(e.target.value));
                                searchCep(e.target.value, addr);
                              },
                              maxLength: 9,
                              placeholder: '00000-000',
                              value: addr.cep,
                              style: { marginRight: 10 },
                            }}
                            LabelHTMLAttributes={{
                              style: {},
                            }}
                          />
                        </div>
                        <div style={{ width: '50%' }}>
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'row',
                              width: '100%',
                            }}
                          >
                            <div style={{ width: '50%', marginRight: 5 }}>
                              <InputField
                                label="Estado "
                                options={states.map(estado => (
                                  <option value={estado}>{estado}</option>
                                ))}
                                InputHTMLAttributes={{
                                  id: 'municipio',
                                  name: 'municipio',
                                  placeholder: 'Insira o município',
                                  type: 'select',
                                  value: addr.estado,
                                  onChange: e => {
                                    searchMunicipio(e.target.value, addr);
                                    // setEnderecos(
                                    //   enderecos.map((end: any) =>
                                    //     end.id == addr.id
                                    //       ? { ...end, estado: e.target.value }
                                    //       : end,
                                    //   ),
                                    // );
                                  },
                                }}
                                LabelHTMLAttributes={{
                                  style: {},
                                }}
                              />
                              {loadingMunicipios && (
                                <Spinner
                                  size="sm"
                                  style={{
                                    marginTop: -45,
                                    position: 'absolute',
                                    marginLeft: 226,
                                  }}
                                />
                              )}

                              {/* <InputField
                                label="Estado"
                                InputHTMLAttributes={{
                                  id: 'estado',
                                  name: 'estado',
                                  placeholder: 'Estado',
                                  value: addr.estado,
                                  onChange: e => {
                                    searchMunicipio(e.target.value, addr);
                                    // setEnderecos(
                                    //   enderecos.map((end: any) =>
                                    //     end.id == addr.id
                                    //       ? { ...end, estado: e.target.value }
                                    //       : end,
                                    //   ),
                                    // );
                                  },
                                }}
                                LabelHTMLAttributes={{
                                  style: {},
                                }}
                              /> */}
                            </div>
                            <div
                              style={{
                                width: '50%',
                                marginRight: 5,
                                position: 'relative',
                              }}
                            >
                              <InputField
                                label="Município "
                                // options={municipios.map(municipio => (
                                //   <option value={municipio}>{municipio}</option>
                                // ))}
                                InputHTMLAttributes={{
                                  id: 'municipio',
                                  name: 'municipio',
                                  placeholder: 'Insira o município',
                                  // type: 'select',
                                  value: addr.municipio,
                                  onChange: e => {
                                    searchMunicipioOptions(
                                      e.target.value,
                                      addr,
                                    );
                                    // setEnderecos(
                                    //   enderecos.map((end: any) =>
                                    //     end.id == addr.id
                                    //       ? {
                                    //           ...end,
                                    //           municipio: e.target.value,
                                    //         }
                                    //       : end,
                                    //   ),
                                    // );
                                  },
                                }}
                                LabelHTMLAttributes={{
                                  style: {},
                                }}
                              />
                              {filterMunicipios?.length > 0 && (
                                <div
                                  style={{
                                    borderWidth: 1,
                                    borderColor: '#F2F1F7',
                                    borderStyle: 'solid',
                                    marginTop: -15,
                                    position: 'absolute',
                                    width: '100%',
                                  }}
                                >
                                  {filterMunicipios.map((value, i) => (
                                    <div
                                      style={{
                                        backgroundColor: '#fff',
                                        paddingTop: 4,
                                        paddingLeft: 5,
                                        paddingRight: 5,
                                        cursor: 'pointer',
                                      }}
                                      onClick={() =>
                                        selectMunicipioOption(value, addr)
                                      }
                                    >
                                      {value}
                                    </div>
                                  ))}
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          width: '100%',
                        }}
                      >
                        <div style={{ width: '100%' }}>
                          <InputField
                            label="Bairro"
                            InputHTMLAttributes={{
                              id: 'bairro',
                              name: 'bairro',
                              placeholder: 'Bairro',
                              value: addr.bairro,
                              onChange: e => {
                                setEnderecos(
                                  enderecos.map((end: any) =>
                                    end.id == addr.id
                                      ? { ...end, bairro: e.target.value }
                                      : end,
                                  ),
                                );
                              },
                              style: { marginRight: 10 },
                            }}
                            LabelHTMLAttributes={{
                              style: {},
                            }}
                          />
                        </div>
                        <div style={{ width: '50%' }}>
                          <InputField
                            label="Zona"
                            options={zones.map(zona => (
                              <option value={zona}>{zona}</option>
                            ))}
                            InputHTMLAttributes={{
                              id: 'zona',
                              name: 'zona',
                              placeholder: 'Insira a zona',
                              type: 'select',
                              value: addr.zona,
                              onChange: e => {
                                selectZonaOption(e.target.value, addr);
                                // setEnderecos(
                                //   enderecos.map((end: any) =>
                                //     end.id == addr.id
                                //       ? {
                                //           ...end,
                                //           zona: e.target.value,
                                //         }
                                //       : end,
                                //   ),
                                // );
                              },
                            }}
                            LabelHTMLAttributes={{
                              style: {},
                            }}
                          />
                        </div>
                      </div>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          width: '100%',
                        }}
                      >
                        <div style={{ width: '80%', marginRight: 5 }}>
                          <InputField
                            label="Endereço"
                            InputHTMLAttributes={{
                              id: 'endereco',
                              name: 'endereco',
                              style: { width: '100%', marginRight: 10 },
                              placeholder: 'Insira o endereço',
                              value: addr.endereco,
                              onChange: e => {
                                setEnderecos(
                                  enderecos.map((end: any) =>
                                    end.id == addr.id
                                      ? { ...end, endereco: e.target.value }
                                      : end,
                                  ),
                                );
                              },
                            }}
                            LabelHTMLAttributes={{
                              style: {},
                            }}
                          />
                        </div>
                        <div style={{ width: '20%' }}>
                          <InputField
                            label="Nº"
                            InputHTMLAttributes={{
                              id: 'numero_endereco',
                              name: 'numero_endereco',
                              placeholder: 'Nº',
                              style: { width: '100%' },
                              value: addr.numero_endereco,
                              onChange: e => {
                                setEnderecos(
                                  enderecos.map((end: any) =>
                                    end.id == addr.id
                                      ? {
                                          ...end,
                                          numero_endereco: e.target.value,
                                        }
                                      : end,
                                  ),
                                );
                              },
                            }}
                            LabelHTMLAttributes={{
                              style: {},
                            }}
                          />
                        </div>
                      </div>

                      <InputField
                        label="Complemento"
                        InputHTMLAttributes={{
                          id: 'complemento_endereco',
                          name: 'complemento_endereco',
                          placeholder: 'Complemento',
                          value: addr.complemento,
                          onChange: e => {
                            setEnderecos(
                              enderecos.map((end: any) =>
                                end.id == addr.id
                                  ? { ...end, complemento: e.target.value }
                                  : end,
                              ),
                            );
                          },
                        }}
                        LabelHTMLAttributes={{
                          style: {},
                        }}
                      />

                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'flex-end',
                        }}
                      >
                        {/* {initialValues?.endereco && <AiFillSave onClick={() => editAddress(addr)} size={35} color="#ffd700" style={{ cursor: 'pointer' }} />}
                  <FaTrashAlt onClick={() => deleteAddress(addr.id)} size={30} color="#8b0000" style={{ marginLeft: 10, cursor: 'pointer' }} /> */}

                        <div
                          onClick={() => deleteAddress(addr.id)}
                          style={{
                            display: 'flex',
                            cursor: 'pointer',
                            height: 37,
                            justifyContent: 'center',
                            background: '#6c757d',
                            padding: 10,
                            borderRadius: 5,
                          }}
                        >
                          <h1 style={{ fontSize: 14, color: '#fff' }}>
                            Excluir
                          </h1>
                        </div>

                        {initialValues?.endereco && (
                          <div
                            onClick={() => editAddress(addr)}
                            style={{
                              display: 'flex',
                              marginLeft: 10,
                              cursor: 'pointer',
                              height: 37,
                              justifyContent: 'center',
                              background: '#1B7E9F',
                              padding: 10,
                              borderRadius: 5,
                            }}
                          >
                            <h1 style={{ fontSize: 14, color: '#fff' }}>
                              Salvar
                            </h1>
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              ))}
            </div>

            <ModalFooter className="d-flex justify-content-between">
              <div>
                <Button type="button" size="lg" onClick={handleBackStep}>
                  Voltar
                </Button>
              </div>
              <div>
                <Button
                  style={{ borderColor: '#1B7E9F', background: '#1B7E9F' }}
                  type="submit"
                  color="primary"
                  size="lg"
                >
                  {isCreate ? 'Avançar' : 'Salvar'}
                </Button>
              </div>
            </ModalFooter>
          </Form>
        );
      }}
    </Formik>
  );
}
