import React from 'react';
import { addHours, format } from 'date-fns';
import { useHistory } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import { Badge } from '../../../../components/develop-nilton-components/Badge';
import { IconAdd } from '../../../../components/develop-nilton-components/IconAdd';
import { Td, Tr } from '../../../../components/develop-nilton-components/TablePadrão';
import { IconDetails } from '../../../../components/develop-nilton-components/IconDetails';

type PropsTbodyTable = {
  headWidth: string[]
  roundsItems: any
  index: number
}

export function TbodyTodos({ roundsItems, headWidth, index }:PropsTbodyTable) {
  const history = useHistory();

  function handleHistoryToPageEgressesInTheRounds() {
    history.push({ state: roundsItems, pathname: "/homologar-ronda/confirmar-egressos" });
  }

  return (
    <Tr>
      <Td width={headWidth[0]}>
        <ReactTooltip />
        {index}
      </Td>
      <Td width={headWidth[1]}>
        {roundsItems.responsavel.name}
      </Td>
      <Td width={headWidth[2]}>
        {roundsItems.title}
      </Td>
      <Td width={headWidth[3]}>
        {format(addHours(new Date(roundsItems.data_ronda), 0), "dd/MM/yyyy")}
      </Td>
      <Td width={headWidth[4]}>
        <>
          {roundsItems.homologado === "T" && <Badge type="success">Concluído</Badge>}
          {(roundsItems.homologado === "F" || null) && <Badge type="info">Pendente</Badge>}
        </>
      </Td>
      <Td width={headWidth[5]}>
        <>
          {roundsItems.homologado === "T" && (
          <div className="d-inline-flex p-2">
            <div
              // className="mr-3"
              onClick={() => {
                history.push({ state: roundsItems, pathname: "/homologar-ronda/egressos" });
              }}
            >
              <IconDetails cursor tooltipMessage="Visualizar ronda" />
            </div>
          </div>
          )}

          {(!roundsItems.homologado || roundsItems.homologado === "F") && (
          <div onClick={() => {
            history.push({ state: roundsItems, pathname: "/homologar-ronda/confirmar-egressos" });
          }}
          >
            <IconAdd cursor tooltipMessage="Homologar ronda" color="text-primary" />
          </div>
          )}
          <ReactTooltip />
        </>
      </Td>
    </Tr>
  );
}
