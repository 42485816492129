/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import ReactModal from 'react-modal';
import {
  Modal, ModalBody, ModalHeader, Button, ModalFooter, Form, FormGroup, Label, Input, Row, Col,
} from 'reactstrap';
import { toast, ToastContainer } from 'react-toastify';
import styled from 'styled-components';
import { VoidExpression } from 'typescript';
import { ButtonAdicionar } from '../../../../components/develop-nilton-components/ButtonAdicionar';
import { DateField } from '../../../../components/develop-nilton-components/DateField';
import { useFormValidate } from '../../../../components/develop-nilton-components/hooks/useFormValidate';
import { InputField } from '../../../../components/develop-nilton-components/inputField';
import { useAuth } from '../../../../hooks/auth';
import api from '../../../../services/api';
import { TablePadrão } from '../../../../components/develop-nilton-components/TablePadrão';
import { TbodyToBeAddInTheRounds } from './TbodyToBeAddInTheRounds';
import { useCheckboxAll } from '../hooks/useCheckboxAll';
import { SpinnerLoading } from '../../../../components/SpinnerLoading';

type PropsModalAdicionarRonda = {
  handleOpen(): void | Promise<void>
  stateModalIsOpen: [boolean, React.Dispatch<React.SetStateAction<boolean>>]
  useStateCheckboxAll: [any, React.Dispatch<any>]
  handleUpdateTable(): Promise<void>
}

const styleReactModalAdicionarRonda = {
  content: {
    width: "300px",
    height: "280px",
    margin: "auto auto",
    borderRadius: "10px",
    overflow: "none",
  },
  overlay: {
    background: "rgba(1,1,1,.5)",
    overflow: "hidden",
  },
};

const styleForm:React.CSSProperties = {
  width: "100%",
  height: "100%",
  display: "flex",
  flexDirection: "column",
  gap: "20px",
  justifyContent: "center",
  alignItems: "center",
};

export function ModalAdicionarRonda({
  handleOpen, stateModalIsOpen, handleUpdateTable, useStateCheckboxAll,
}:PropsModalAdicionarRonda) {
  const [isOpen, setIsOpen] = stateModalIsOpen;
  const [isLoad, setLoad] = useState(false);
  const history = useHistory();
  const { user } = useAuth();
  const headWidth = ["70%", "30%"];
  const [checkboxAll, setCheckboxAll] = useStateCheckboxAll;
  const isDisableAddEgresses = checkboxAll?.find((item:any) => item.checked === true);
  const [roundActualTitle, setRoundActualTitle] = useState<any>(null);
  const [tab, setTab] = useState(1);
  const myDate = new Date(Date.now()).toLocaleString().split(',')[0];
  const {
    register, handleSubmit, formState, clearErrors, reset,
  } = useForm({ defaultValues: { title: null, data_ronda: null, responsable_id: user?.id } });

  const { handleAddEgresses } = useCheckboxAll(checkboxAll, handleUpdateTable, setLoad, setTab);
  const formValues = ["title", "data_ronda"];
  const formStateErrors = [formState.errors.title, formState.errors.data_ronda];
  const { formIsValidated } = useFormValidate({ formStateErrors, formValues });

  function getRounds() {
    api.get(`round_actual_title`, {
    }).then(({ data: rounds }) => {
      setRoundActualTitle(rounds);
    }).catch((error) => {
      setRoundActualTitle("FISCALIZAÇÃO - 1");
    });
  }

  useEffect(() => {
    getRounds();
  }, [isOpen]);

  useEffect(() => {
    if (checkboxAll?.length > 0 && checkboxAll[0]?.round_id !== "") {
      // console.log(checkboxAll, 'checkbox atualizado');
      // handleAddEgresses();
    }
  }, [checkboxAll]);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  // function notify1() {
  //   console.log(checkboxAll);
  // }

  const [ronda, setRonda] = React.useState({
    data_ronda: myDate,
  });

  async function notify() {
    setLoad(true);
    try {
      // console.log('entrei aqui');
      const formData = new FormData();
      formData.append('data', JSON.stringify({
        ...ronda,
        responsable_id: user?.id,
        egresses: checkboxAll
          .filter((item: any) => item.checked === true)
          .map((item:any) => (Number(item.egress_id))),
      }));

      const response = await api.post("/rounds", {
        responsable_id: user?.id,
        data_ronda: new Date(),
        egresses: checkboxAll
          .filter((item: any) => item.checked === true)
          .map((item:any) => (Number(item.egress_id))),
      });
      // console.log(response.data);
      // console.log(response.data.id);
      setCheckboxAll(
        checkboxAll
          .filter((item:any) => item.checked === true)
          .map((item:any) => ({
            round_id: response.data.id,
            nome: item.nome,
            egress_id: item.egress_id,
            checked: true,
          })),
      );

      toast("Ronda cadastrada com sucesso..", { type: "success" });
      setIsOpen(false);
      history.push({ pathname: "/cadastrar-ronda" });
    } catch (error) {
      toast("Erro ao cadastrar ronda.", { type: "error", autoClose: 1000 });
      // console.log(error, 'o erro');
      setLoad(false);
    }
    setLoad(false);
  }
  // async function onSubmit(data: any) {
  //   setLoad(true);
  //   try {
  //     const response = await api.post("/rounds", data);
  //     handleUpdateTable();
  //     console.log(response);
  //     toast("Ronda cadastrada com sucesso..", { type: "success" });
  //     setIsOpen(false);
  //     history.push({ pathname: "/cadastrar-ronda" });
  //   } catch (error) {
  //     toast("Erro ao cadastrar ronda.", { type: "error", autoClose: 1000 });
  //   }
  //   setLoad(false);
  // }

  return (
    <Modal isOpen={isOpen} toggle={toggle} size="lg">
      <ModalHeader toggle={toggle}>Confirmação de Rondas</ModalHeader>

      <Form>
        <ModalBody>
          <Row>
            <Col md={6}>
              <FormGroup>
                <Label for="referencialRonda" style={{ fontWeight: 'bold' }}>
                  Referencial da Ronda
                </Label>
                <Input
                  id="referencialRondaId"
                  name="referencialRonda"
                  placeholder={roundActualTitle}
                  type="text"
                  disabled
                />
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label for="dataCriacao" style={{ fontWeight: 'bold' }}>
                  Data de Criação
                </Label>
                <Input
                  id="dataCriacaoId"
                  name="data_ronda"
                  placeholder={myDate}
                  type="text"
                  disabled
                />
              </FormGroup>
            </Col>
          </Row>

          {checkboxAll?.filter((item:any) => item.checked === true).length > 0 && (
          <div style={{
            display: "flex", marginBottom: 20, width: '100%', justifyContent: "space-between", alignItems: 'center', fontWeight: 'bold',
          }}
          >
            <div className="horizontal" />
            <h1 style={{
              fontSize: 16, width: "20%", fontWeight: 'bold', marginBottom: 0, marginLeft: 10,
            }}
            >
              Egressos Inseridos
            </h1>
            <div className="horizontal" />
          </div>
          )}

          <Row>
            <Col md={12}>
              {/* <Label style={{ marginTop: 5, fontWeight: 'bold' }}>
                Egressos a serem adicionados
              </Label> */}
              <div>
                <TablePadrão
                  thead={[]}
                  headWidth={headWidth}
                >
                  {checkboxAll?.filter((item:any) => item.checked === true).map((item: any, i: React.Key | null | undefined) => (
                    <TbodyToBeAddInTheRounds
                      key={i}
                      itemEgresso={item}
                      headWidth={headWidth}
                      checkboxAll={checkboxAll}
                      setCheckboxAll={setCheckboxAll}
                      isLoad={isLoad}
                    />
                  ))}
                  {/* {checkboxAll?.map((item: any, i: React.Key | null | undefined) => (
                  <TbodyToBeAddInTheRounds
                    key={i}
                    itemEgresso={item}
                    handleUpdate={handleUpdate}
                    headWidth={headWidth}
                  />
                ))} */}
                </TablePadrão>
              </div>
            </Col>
          </Row>

        </ModalBody>
        <ModalFooter className="d-flex justify-content-between">
          <Button style={{ background: 'rgba(201, 19, 19, 0.6)' }} className="btn btn-danger" onClick={toggle}>
            Cancelar
          </Button>
          {' '}
          {/* <Button className="btn btn-success" onClick={() => { notify(); }}>
          Finalizar
        </Button> */}
          <Button style={{ background: 'rgba(4, 85, 12, 0.6)' }} className="btn btn-success" onClick={() => { notify(); }} disabled={!isDisableAddEgresses || isLoad}>
            {isLoad && <SpinnerLoading />}
            {!isLoad && (
              'Finalizar'
            )}
          </Button>
          {/* <Button className="btn btn-success" onClick={() => notify1()}>teste</Button> */}
        </ModalFooter>
      </Form>

    </Modal>

  // <ReactModal
  //   isOpen={isOpen}
  //   ariaHideApp={false}
  //   style={styleReactModalAdicionarRonda}
  //   shouldCloseOnOverlayClick
  //   onRequestClose={() => {
  //     handleOpen();
  //     clearErrors();
  //     reset();
  //   }}
  // >
  //   <form
  //     style={styleForm}
  //     className={formIsValidated}
  //     noValidate
  //     onSubmit={handleSubmit(onSubmit)}
  //   >
  //     {/* <div>
  //     <label htmlFor="" className="form-label"><strong>Nome da ronda</strong></label>
  //     <InputField formState={formState} register={register} name="title" width="210px" max={20} placeholder="Digite o nome da ronda..." fontSize="16px" />
  //   </div> */}
  //     <div>
  //       <label htmlFor="" className="form-label"><strong>Data da ronda</strong></label>
  //       <DateField formState={formState} register={register} name="data_ronda" width="210px" />
  //     </div>
  //     <button className="btn" type="submit" disabled={isLoad}>
  //       <ButtonAdicionar load={isLoad}>Adicionar ronda</ButtonAdicionar>
  //     </button>
  //   </form>
  // </ReactModal>
  );
}
