import { eachYearOfInterval, format } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { Chart } from 'react-chartjs-2';
import { useLocation } from 'react-router-dom';
import { TablePadrão, Td, Tr } from '../../../../components/develop-nilton-components/TablePadrão';
import { SectionHeader } from '../../../../components/SectionHeader';
import { SpinnerLoading } from '../../../../components/SpinnerLoading';
import { SubmitResponse } from '../../../../components/SubmitResponse';
import api from '../../../../services/api';
import { TbodyPageHistorico } from './TbodyPageHistorico';

export function PageHistorico() {
  const headWidth = ["5%", "50%", "30%", "30%"];
  const [data, set_data] = useState(null);
  const [load, setLoad] = useState(false);
  const types = [
    { label: "Mudar de humor", value: "muda_humor_facilmente" },
    { label: "Agressividade", value: "fica_nervoso_constantemente" },
    { label: "Comp.bipolar", value: "fica_triste_euforico" },
    { label: "Raiva ou hostilidade", value: "fica_raiva_hostil" },
    { label: "Automutilação ou suicídio", value: "pensamentos_suicidas" },
    { label: "Cansaço", value: "sente_cansaco" },
    { label: "Concentração", value: "consegue_concentrar" },
    { label: "Fadiga matinal", value: "fadiga_matinal" },
    { label: "Atenção", value: "manter_atencao_tarefas_especificas" },
    { label: "Toma decisões", value: "dificuldade_tomar_decisao" },
    { label: "Queixas", value: "queixas" },
    { label: "Observações", value: "observacoes" },
  ];

  const [year, setYear] = useState(new Date().toLocaleDateString("pt-BR", { year: "numeric" }));
  const [type, setType] = useState("muda_humor_facilmente");

  const year_options = eachYearOfInterval({ start: new Date(0), end: new Date() }).map((year) => format(year, "yyyy")).reverse();

  function handle_set_data(type:string, year: string) {
    setLoad(true);
    api.get('/reports/acompanhamento_psicologico_anual_egresso', {
      params: {
        egress_id: state.id,
        year,
        type,
      },
    }).then(({ data }) => {
      set_data(data);
      setLoad(false);
    });
  }

  const { state } = useLocation<any>();

  useEffect(() => {
    handle_set_data(type, year);
  }, [state.id, type, year]);

  console.log(data);

  return (
    <div className="w-100">
      <SectionHeader subtitle="Relatórios" backPage="/perfil" />

      <div>
        {load && !data && <div className="d-flex justify-content-center m-5"><SpinnerLoading /></div>}
        {data && (
          <div className="d-flex align-items-center flex-column">
            <div>
              <h3 className="d-flex" style={{ gap: 10 }}>
                Tipo de gráfico anual:
                <select style={{ width: 300 }} className="form-control" onChange={(e) => setType(e.target.value)}>
                  {types.map((type, i) => (
                    <option key={i} value={type.value}>{type.label}</option>
                  ))}
                </select>
                {load && <div className="m-0"><SpinnerLoading size={10} /></div>}
              </h3>
              {/* <h3 className="d-flex" style={{ gap: 10 }}>
                Ano:
                <select style={{ width: 300 }} className="form-control" onChange={(e) => setYear(e.target.value)}>
                  {year_options.map((year, i) => (
                    <option key={i} value={year}>{year}</option>
                  ))}
                </select>
                {load && <div className="m-0"><SpinnerLoading size={10} /></div>}
              </h3> */}
            </div>

            <div style={{ width: "100%" }}>
              <Chart
                type="line"
                data={data}
                options={{
                  plugins: {
                    legend: { display: false },
                  },
                  scales: {
                    y: {
                      suggestedMax: 10,
                      suggestedMin: 0,
                    },
                  },
                }}
              />
            </div>
          </div>
        )}
      </div>

      <div>
        <h4 className="text-primary mt-5">
          Nome do egresso:
          {' '}
          {state?.nome}
        </h4>
      </div>

      <TablePadrão headWidth={headWidth} thead={["#", "Nome do psicólogo", "Data da consulta", "Relatório"]}>
        {state?.acompanhamentos_psicologogico.concluidos.length === 0 && (
          <Tr>
            <Td width="100%">
              <div className="w-100">
                <SubmitResponse submitResponse={{
                  message: "Não há acompanhamentos.",
                  status: "danger",
                  title: "Aviso",
                }}
                />
              </div>
            </Td>
          </Tr>
        )}
        {state?.acompanhamentos_psicologogico.concluidos.map((item:any, i:number) => (
          <TbodyPageHistorico
            index={i + 1}
            egresso={state}
            headWidth={headWidth}
            acompanhamento_concluidos={item}
          />
        ))}
      </TablePadrão>

    </div>
  );
}
