import React, { useEffect, useState } from 'react';
import { IOnSubmitResponse } from '../../../../components/SubmitResponse/type';
import api from '../../../../services/api';

export function useGetProjetosGeral<T>() {
  const [load, setLoad] = useState(false);
  const [projetosGeral, setProjetosGeral] = useState< T | null>(null);

  async function handleProjetosGeral(page = 1, search?: string) {
    setLoad(true);
    const { data } = await api.get('/projects', {
      params: {
        page,
        nome: search,
      },
    });

    if (data.data.length === 0 && page > 1) {
      handleProjetosGeral(page - 1);
    } else {
      setProjetosGeral(data);
    }
    setLoad(false);
  }

  useEffect(() => {
    handleProjetosGeral();
  }, []);

  return {
    projetosGeral,
    handleProjetosGeral,
    load,
  };
}
