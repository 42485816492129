import React, { useEffect, useState } from 'react';
import { Chart } from 'react-chartjs-2';
import { SpinnerLoading } from '../../../../components/SpinnerLoading';
import { trimestral_options, handleTrimestre } from '../../../../components/develop-nilton-components/hooks/time_options';
import { useGetIndicadores } from '../hooks/useGetIndicadores';

export function ObservaçãoTrimestral({ tab, tabType }: {tab:number, tabType: number}) {
  const { indicador: observacoes, handleSetIndicador: handle_set_observacoes } = useGetIndicadores();

  const options_graphics = [
    {
      title: "Obsevações",
      type: "line",
      options: {
        plugins: {
          legend: { display: false, position: "left" },
        },
      },
      data: observacoes,
      onChange: (e:any) => {
        handle_set_observacoes("queixas", undefined, e.target.value);
      },
    },
  ];

  const [load, set_load] = useState(false);

  useEffect(() => {
    (async () => {
      set_load(false);
      await handle_set_observacoes("queixas", undefined, handleTrimestre());
      set_load(true);
    })();
  }, [tab, tabType]);

  return (
    <div>
      {!load && (
        <div className="d-flex justify-content-center m-5">
          <SpinnerLoading />
        </div>
      )}
      {load && options_graphics.map(({
        data, type, options, title, onChange,
      }, i) => (
        <div key={i}>
          <div className="mt-3 border-bottom border-secondary d-flex">
            <h3 className="mr-2">{title}</h3>
            <select
              style={{ height: 35, width: 200, cursor: "pointer" }}
              className="form-control"
              onChange={onChange}
              defaultValue={handleTrimestre()}
            >
              {trimestral_options.map((trimestral, i) => (
                <option key={i} value={trimestral.value}>{trimestral.label}</option>
              ))}
            </select>
          </div>
          <div className="d-flex align-items-center flex-column w-100">
            <div>
              <Chart
                // @ts-ignore
                type={type}
                data={data}
                // @ts-ignore
                options={options}
                style={{ width: type === "line" ? 900 : 600 }}
              />
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}
