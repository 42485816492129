import React from 'react';
import ReactLoading from 'react-loading';

type LoadingProps = {
  size?: number
}

export function Loading(props: LoadingProps) {
  const { size } = props;
  const sizeClass = () => {
    if (!size) {
      return `d-flex justify-content-center ${!size && 'm-5'}`;
    }
    return 'm-3';
  };
  return (
    <div className={sizeClass()}>
      <ReactLoading type="spin" color="black" width={size ?? undefined} height={size ?? undefined} />
    </div>
  );
}
