import React, { useEffect, useState } from "react";
import api from "../../../../services/api";

export function useGetProjetosOptions<T>() {
  const [projetoOptions, setProjetosOptions] = useState<T | null>(null);
  const [load, setLoad] = useState(true);

  async function handleSetProjetosOptions() {
    setLoad(true);
    const { data } = await api.get('/projects', {
      params: {
        nopaginate: true,
      },
    });
    const filterQuery = data.filter((project:any) => {
      if (project.situacao === "Homologado/Não Iniciado" || project.situacao === "Homologado/Em andamento") {
        return true;
      } return false;
    });
    setProjetosOptions(filterQuery);
    setLoad(false);
  }

  useEffect(() => {
    handleSetProjetosOptions();
  }, []);
  return { projetoOptions, handleSetProjetosOptions, load };
}
