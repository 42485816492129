import React from 'react';
import { SectionHeader } from '../../../components/SectionHeader';
import { EgressoRelatorio } from './components/EgressoRelatorio';

export function RelatorioPsicologo() {
  return (
    <div style={{ width: "100%" }}>
      <SectionHeader subtitle="Relatórios" />

      <EgressoRelatorio />
    </div>
  );
}
