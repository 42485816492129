import React, { useEffect, useState } from 'react';
import api from '../../../../services/api';

export function useReport(url: string) {
  const [report, setReport] = useState<any>(null);

  async function handleSetReport(year?: string) {
    const { data } = await api.get(url, {
      params: {
        year,
      },
    });
    setReport(data);
  }

  useEffect(() => {
    async function handleSetReport() {
      const { data } = await api.get(url);
      setReport(data);
    }
    handleSetReport();
  }, [url]);
  return { report, handleSetReport };
}
