import React, { useEffect } from 'react';
import styled from 'styled-components';
import { TablePadrão, Td, Tr } from '../../../../components/develop-nilton-components/TablePadrão';
import { SpinnerLoading } from '../../../../components/SpinnerLoading';
import { SubmitResponse } from '../../../../components/SubmitResponse';
import { TbodyAlreadyAddsInTheRounds } from './TbodyAlreadyAddsInTheRounds';

type PropsTableGeralRoundEgresses = {
  roundsEgresses: any
  handleUpdate(): void | Promise<void>
  useStateCheckboxAll: [any, React.Dispatch<any>]
  loadEgress: boolean
}

export function TableAlreadyAddsInARounds({
  roundsEgresses, handleUpdate, useStateCheckboxAll, loadEgress,
}:PropsTableGeralRoundEgresses) {
  const headWidth = ["5%", "30%", "33%", "33%"];
  const [checkboxAll, setCheckboxAll] = useStateCheckboxAll;

  useEffect(() => {
    setCheckboxAll(
      roundsEgresses?.map((item:any) => ({
        id: item.id,
        checked: false,
      })),
    );
  }, [roundsEgresses, setCheckboxAll]);

  return (
    <div>
      <TablePadrão
        thead={["#", "Nome", "Detalhes", "Remover"]}
        headWidth={headWidth}
      >
        {loadEgress && <Tr><Td width="100%"><SpinnerLoading /></Td></Tr>}
        {roundsEgresses?.map((item: any, i: React.Key | null | undefined) => (
          <TbodyAlreadyAddsInTheRounds
            key={i}
            itemEgresso={item}
            headWidth={headWidth}
          />
        ))}
      </TablePadrão>

      {roundsEgresses && roundsEgresses.length === 0
        && <SubmitResponse submitResponse={{ message: "Não há egressos na ronda.", status: "danger", title: "Aviso" }} />}
    </div>
  );
}
