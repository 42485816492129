import React from 'react';
import { PropsAbaContainer } from '../types';

export function AbaContainer({ components, tab }:PropsAbaContainer) {
  return (
    <div>
      {components.map((item, i: number) => (
        <div
          key={i}
          style={{ display: tab === i + 1 ? "" : 'none', marginTop: "10px" }}
        >
          {item}
        </div>
      ))}
    </div>
  );
}
