import React, { useState } from 'react';
import { AiFillPlusCircle } from 'react-icons/ai';
// import ReactTooltip from 'react-tooltip';

type PropsIconAdd = {
  cursor?: boolean
  size?: number
  tooltipMessage?: string
  color?: "text-primary" | "text-warning" | "text-danger" | "text-success" | string
  mouseOver?: boolean
}

export function IconAdd({
  cursor, size, tooltipMessage, color, mouseOver,
}:PropsIconAdd) {
  const [over, setOver] = useState(false);

  function handleSetOver() {
    setOver(!over);
  }

  return (
    <div
      data-tip={tooltipMessage || ""}
      onMouseEnter={mouseOver ? handleSetOver : undefined}
      onMouseLeave={mouseOver ? handleSetOver : undefined}
      className={mouseOver ? (over ? color : undefined) : (color || undefined)}
      style={{ marginRight: 5 }}
    >
      <AiFillPlusCircle
        size={size || 30}
        color={color || "#1B7E9F"}
        cursor={cursor ? "pointer" : undefined}
      />
    </div>
  );
}
