import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import { IconAdd } from '../../../../components/develop-nilton-components/IconAdd';
import { IconDetails } from '../../../../components/develop-nilton-components/IconDetails';
import { IconEdit } from '../../../../components/develop-nilton-components/IconEdit';
import { Td, Tr } from '../../../../components/develop-nilton-components/TablePadrão';
import { SpinnerLoading } from '../../../../components/SpinnerLoading';
import api from '../../../../services/api';
import { PropsTbodyTable } from '../types';
import { ModalHomologar } from './ModalHomologar';
import { ModalVisualizarProjeto } from './ModalVisualizarProjeto';

export function TbodyTablePendente({
  headWidth, projetos, index, handleProjetos, meta,
}:PropsTbodyTable) {
  const history = useHistory();
  const projeto = { ...projetos, materials: projetos?.materiais.map((item) => ({ qtd: item.qtd, material: item.material })) };

  const [isOpenModalHomologar, setIsOpenModalHomologar] = useState(false);

  function handleRedirect() {
    history.push({
      pathname: '/homologar_pro/editar',
      state: { projeto },
    });
  }

  const [load, setLoad] = useState(false);

  async function handleHomologar() {
    setLoad(true);
    await api.put(`/projects/${projeto.id}`, { homologado: "T" });
    if (meta?.current_page) {
      await handleProjetos(meta?.current_page);
    }
    setLoad(false);
  }

  const [isOpenModalVisualizarProjeto, setIsOpenModalVisualizarProjeto] = useState(false);

  function handleIsOpenModalVisualizarProjeto() {
    setIsOpenModalVisualizarProjeto(!isOpenModalVisualizarProjeto);
  }

  return (
    <Tr>
      <Td width={headWidth[0]}>{index}</Td>
      <Td width={headWidth[1]}>{projetos?.nome}</Td>
      <Td width={headWidth[2]}>{projetos?.pedagogo.name}</Td>
      <Td width={headWidth[3]}>
        <div className="mr-3" onClick={handleIsOpenModalVisualizarProjeto}>
          <IconDetails tooltipMessage="Detalhes do projeto" cursor />
        </div>
        <div>
          {load ? <SpinnerLoading /> : (
            <div onClick={handleHomologar}>
              <IconAdd
                cursor
                size={30}
                tooltipMessage="Homologar"
                color="text-primary"
              />
            </div>
          )}
        </div>
        <ModalVisualizarProjeto id={projetos.id} isOpen={isOpenModalVisualizarProjeto} handleIsOpen={handleIsOpenModalVisualizarProjeto} />
      </Td>
    </Tr>
  );
}
