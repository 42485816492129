import React from 'react';
import { FormState, UseFormRegister } from 'react-hook-form';
import { IProjeto } from '../hooks/Model/Projeto/type';
import { InputField } from '../styles/InputField';
import { Errors } from './Erro';

type INameField = {
    register: UseFormRegister<any>
    formState: FormState<any>
    placeholder?:string
}

export function OrigemRecursosField({ register, formState, placeholder }:INameField) {
  return (
    <InputField>
      <label htmlFor="">Origem do recurso</label>
      <div className="flex-column">
        <input
          autoComplete="off"
          type="text"
          {...register("origem_recurso", { required: true })}
          className="form-control form-control-lg"
          placeholder={placeholder}
          required
        />
        {formState.errors.origem_recurso && (
        <Errors />
        )}
      </div>
    </InputField>
  );
}
