import React from 'react';
import CardItem from '../CardItem/CardItem';
import { GridAdmContainer } from './GridAdm.styled';

type Props = {
  cardItems: {
    url: string;
    pathPainel: string;
    title: string;
    component?: JSX.Element;
  }[]
}

function GridAdm({ cardItems }: Props) {
  return (
    <GridAdmContainer>
      {cardItems.map((item) => (
        <CardItem key={item.title} title={item.title} url={item.url} pathPainel={item.pathPainel} />
      ))}
    </GridAdmContainer>
  );
}

export default GridAdm;
