import React from 'react';
import { toast } from 'react-toastify';
import api from '../../../../services/api';

export function useDeleteEgressesOfRounds() {
  async function handleDeleteEgressesOfRounds(roundEgressesId: number) {
    try {
      const { data } = await api.delete(`/rounds_egresses/${roundEgressesId}`);
      // toast(data.message, { type: "success" });
    } catch (error) {
      // @ts-ignore
      toast(error.response.data.message, { type: "error" });
    }
  }
  return { handleDeleteEgressesOfRounds };
}
