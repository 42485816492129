import styled from 'styled-components';

export const WrapperMateriais = styled.div`
        width: 800px;
        border-radius: 20px;
        border: 1px solid #000000;
        padding: 35px ;
        display: flex;
        flex-direction: column;
        gap: 15px;
`;
