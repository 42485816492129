import React, { useState } from 'react';
import { AbaFilterButtons, AbaFilterContent } from '../../../components/develop-nilton-components/AbaFiltro';
import { SearchEgresses } from '../../../components/develop-nilton-components/SearchEgresses';
import { SectionHeader } from '../../../components/SectionHeader';
import { TableDesempenho } from './components/TableDesempenho';
import { useGetCourses } from './hooks/useGetCourses';

export function AnalisarEgresso() {
  const { courses, handleSetCourses, load: loadCourses } = useGetCourses();

  const [tab, setTab] = useState(1);
  return (
    <div className="w-100">
      <SectionHeader subtitle="Analisar Egresso" />

      <div className="w-100">
        <SearchEgresses handleSetSearch={handleSetCourses} />
      </div>

      <AbaFilterButtons
        buttons={["Desempenho"]}
        useStateTab={[tab, setTab]}
      />

      <AbaFilterContent
        tab={tab}
        components={[
          <TableDesempenho
            data={courses?.data}
            meta={courses?.meta}
            load={loadCourses}
            handleUpdate={handleSetCourses}
          />,
          "Relatorio",
        ]}
      />
    </div>
  );
}
