import React, { useState, useRef } from 'react';
// @ts-ignore
import ServerTable from 'react-strap-table';

import { ToastContainer, toast } from 'react-toastify';

import { AiFillMinusCircle } from 'react-icons/ai';
import { BsCheckLg } from 'react-icons/bs';

import moment from 'moment';
import { Container } from './styles';

import Header from '../components/Header';
import api from '../../../services/api';

import { useAuth } from '../../../hooks/auth';

export function ListarRonda() {
  const ref = useRef<any>();

  const { user } = useAuth();

  function handleSubmit(round: any) {
    api.put(`/rounds/${round.id}`, { homologado: "T" }).then(() => {
      const message = 'Ronda homologada com sucesso!';

      toast(message, {
        type: 'success',
      });

      ref?.current?.refreshData();
    });
  }

  return (
    <>
      <Container>
        <Header route="listar-ronda" name="Listar ronda diária" />

        <div style={{ marginTop: 10, marginBottom: 20 }}>
          <div style={{ display: 'flex' }}>
            <h1 style={{ fontSize: 16, color: '#595959', fontWeight: 'normal' }}>
              Abaixo será exibido a lista de rondas realizadas

              {/* no mês anterior */}
              , sinalizadas como homologadas (
            </h1>
            <BsCheckLg color="#080" style={{ fontSize: 16, marginTop: 2 }} />
            <h1 style={{ fontSize: 16, color: '#595959', fontWeight: 'normal' }}>
              ) ou não (
            </h1>
            <AiFillMinusCircle color="#F00" style={{ fontSize: 16, marginTop: 2 }} />
            <h1 style={{ fontSize: 16, color: '#595959', fontWeight: 'normal' }}>
              ),
              {/* para ver outro período */}
            </h1>
          </div>

          <div style={{ display: 'flex' }}>
            {/* <h1 style={{
              fontSize: 11, color: '#1400FF', fontWeight: 'normal', cursor: 'pointer',
            }}
            >
              Clique aqui
            </h1> */}
            {/* <h1 style={{
              display: 'flex', fontSize: 11, color: '#595959', fontWeight: 'normal',
            }}
            >
              para homologar retroativamente uma ronda, basta clicar na coluna
            </h1>
            <h1 style={{
              display: 'flex', fontSize: 11, color: '#595959', textTransform: "uppercase", fontWeight: 'bold', marginLeft: 5,
            }}
            >
              HOMOLOGADO
            </h1>
            <h1 style={{
              display: 'flex', fontSize: 11, color: '#595959', fontWeight: 'normal', marginLeft: 5,
            }}
            >
              da ronda desejada
            </h1> */}
          </div>
        </div>

        <ServerTable
          ref={ref}
          columns={['data_ronda', 'egressos', 'responsavel', 'homologado']}
          url={`https://api-albergado.msbtec.com.br/rounds?responsable_id=${user.id}`}
          options={{
            perPageValues: [30, 60, 90],
            perPage: 30,
            requestParametersNames: { query: 'responsavel' },
            // @ts-ignore
            responseAdapter(resp_data) {
              return { data: resp_data.data.map((subitem: any) => ({ ...subitem, responsavel: subitem?.responsavel?.name || 'Sem responsável', egressos: subitem.egressos.length })), total: resp_data.meta.total };
            },
            headings: {
              data_ronda: 'Data', egressos: 'Fiscalizados', responsavel: 'Responsável', homologado: 'Homologado',
            },
            columnsAlign: {
              data_ronda: 'center', egressos: 'center', responsavel: 'center', homologado: 'center',
            },
            texts: {
              show: 'Mostrar', entries: 'resultados', showing: 'Mostrando', to: 'até', of: 'de', search: 'Pesquisar', empty: 'Sem resultados',
            },
            loading: (
              <div style={{ fontSize: 18, display: "initial" }}>
                <span className="fa fa-spinner fa-spin" />
                {' '}
                Carregando...
              </div>
            ),
          }}
        >
          {
        // @ts-ignore
        (row, column) => {
          switch (column) {
            case 'homologado':
              return (
                <h1>{ row.homologado == 'T' ? <BsCheckLg color="#080" style={{ fontSize: 30, marginTop: 2, cursor: 'pointer' }} /> : <AiFillMinusCircle color="#F00" style={{ fontSize: 30, marginTop: 2, cursor: 'pointer' }} /> }</h1>
              );
            case 'data_ronda':
              return moment(row[column]).format('DD/MM/YYYY');
            default:
              return (row[column]);
          }
        }
    }
        </ServerTable>
      </Container>

      <ToastContainer />
    </>
  );
}
