import React, { useState } from 'react';
import { Chart } from 'react-chartjs-2';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { ArrayFormValues, FormAcompanhamentos } from '../../../../components/develop-nilton-components/FormAcompanhamentos';
import { SpinnerLoading } from '../../../../components/SpinnerLoading';

import api from '../../../../services/api';
import { IFatores } from '../type';

export function FatoresInt({
  getValues, setValue, setTab,
}: IFatores) {
  const [obdece_comandos_primeira_vez, set_obdece_comandos_primeira_vez] = useState(getValues().obdece_comandos_primeira_vez || 0);
  const [dificuldade_memorizacao_concentracao, set_dificuldade_memorizacao_concentracao] = useState(getValues().dificuldade_memorizacao_concentracao || 0);
  const [consegue_expressar_pensamentos, set_consegue_expressar_pensamentos] = useState(getValues().consegue_expressar_pensamentos || 0);
  const [dificuldade_aprendizagem, set_dificuldade_aprendizagem] = useState(getValues().dificuldade_aprendizagem || 0);

  const dataset = {
    labels: [
      'Obdiência',
      'Concentração',
      'Expressão',
      'Aprendizagem'],
    datasets: [{
      label: '',
      data: [obdece_comandos_primeira_vez, dificuldade_memorizacao_concentracao, consegue_expressar_pensamentos, dificuldade_aprendizagem],
      borderColor: '#FFA500',
      backgroundColor: '#FFA500',
    }],
  };
  const optionsChart = {
    scales: {
      y: {
        suggestedMax: 10,
        suggestedMin: 0,
      },
    },
    plugins: { legend: { display: false } },
  };

  const arrayFormValues: ArrayFormValues["formValues"] = [
    {
      label: "1. Obedece aos comandos dados na primeira vez?",
      type: "number",
      name: "obdece_comandos_primeira_vez",
      onChange: (e:any) => {
        set_obdece_comandos_primeira_vez(e.target.value);
        setValue('obdece_comandos_primeira_vez', Number(e.target.value));
      },
    },
    {
      label: "2. Interage em Equipe?",
      type: "boolean",
      name: "interage_com_equipe",
      onChange: (e:any) => {
        setValue('interage_com_equipe', e.target.value);
      },
    },
    {
      label: "3. Tem espiríto de Iniciativa?",
      type: "boolean",
      name: "espirito_iniciativa",
      onChange: (e:any) => {
        setValue('espirito_iniciativa', e.target.value);
      },
    },
    {
      label: "4. Compreende com rapidez?",
      type: "boolean",
      name: "compreende_com_rapidez",
      onChange: (e:any) => {
        setValue('compreende_com_rapidez', e.target.value);
      },
    },
    {
      label: "5. Tem dificuldade de memorização ou concentração? ",
      type: "number",
      name: "dificuldade_memorizacao_concentracao",
      onChange: (e:any) => {
        set_dificuldade_memorizacao_concentracao(e.target.value);
        setValue('dificuldade_memorizacao_concentracao', Number(e.target.value));
      },
    },
    {
      label: "6. Ritmo de trabalho?",
      type: "time",
      name: "ritmo_trabalho",
      onChange: (e:any) => {
        setValue('ritmo_trabalho', e.target.value);
      },
    },
    {
      label: "7. Consegue expressar seus pensamentos?",
      type: "number",
      name: "consegue_expressar_pensamentos",
      onChange: (e:any) => {
        set_consegue_expressar_pensamentos(e.target.value);
        setValue('consegue_expressar_pensamentos', Number(e.target.value));
      },
    },
    {
      label: "8. Tem dificuldades de aprendizagem?",
      type: "number",
      name: "dificuldade_aprendizagem",
      especificacao: 'especificacao_dificuldade_aprendizagem',
      onChange: (e:any) => {
        set_dificuldade_aprendizagem(e.target.value);
        setValue('dificuldade_aprendizagem', e.target.value);
        setValue('especificacao_dificuldade_aprendizagem', Number(e.target.value));
      },
      onChangeEspecificacao: (e:any) => {
        setValue('especificacao_dificuldade_aprendizagem', e.target.value);
      },
    },
  ];

  const { register, handleSubmit } = useForm({ defaultValues: getValues() });
  console.log(getValues());

  const [load, setLoad] = useState(false);
  const onSubmit = async (data:any) => {
    setLoad(true);
    try {
      await api.put(`/psicopedagogue/${getValues().id}`, data);
      setTab(2);
      window.scrollTo({ top: 0 });
    } catch (error) {
      // @ts-ignore
      toast.error(error.response.data.message);
    }
    setLoad(false);
  };

  const onSubmitError = (errors:any) => {
    const error = Object.keys(errors);
    for (let i = 0; i < error.length; i + 1) {
      toast.error(errors[error[i] as keyof any]?.message);
      break;
    }
  };

  return (
    <div>
      <div style={{ width: '100%' }}>
        <Chart
          type="bar"
          data={dataset}
          options={optionsChart}
        />
      </div>

      <form onSubmit={handleSubmit(onSubmit, onSubmitError)}>
        <div>
          <FormAcompanhamentos
            formValues={arrayFormValues}
            register={register}
          />
        </div>
        <div className="d-flex justify-content-center mt-5 mb-5">
          {load ? (
            <button className="btn btn-primary btn-lg" type="button">
              <SpinnerLoading />
            </button>
          ) : (
            <button className="btn btn-primary btn-lg" type="submit" data-testid="next">Avança</button>
          )}
        </div>
      </form>
    </div>
  );
}
