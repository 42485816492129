import React, { useState } from 'react';
import ReactModal from 'react-modal';
import styled from 'styled-components';
import { AiOutlineCloseCircle } from 'react-icons/ai';
import { ImLocation } from 'react-icons/im';
import moment from 'moment';
import {
  Col,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupText,
  Label,
  Row,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Badge,
} from 'reactstrap';
import { BsFillArrowRightSquareFill } from 'react-icons/bs';
import { ModalOccurrences } from './ModalOccurrences';
import { ModalRound } from './ModalRound';
import { SectionHeader } from '../SectionHeader';
import { IEgresses } from '../../types/IEgresses';

import { mask_phone } from '../../utils/mask_phone';
import { useGetEgressesById } from '../../pages/Adm/HomologarRonda/hooks/useGetEgressesById';
import { ModalImage } from '../modalImage/modalImage';

type PropsDetailsEgresse = {
  egresse: any;
  isOpen: boolean;
  handleIsOpen(): void;
  useStateEgresse?: [IEgresses | null, (egresse: IEgresses | null) => void];
  useStateIsOpen?: [boolean, (boolean: boolean) => void];
};

const styleModal = {
  content: {
    width: '50%',
    margin: 'auto auto',
  },
  overlay: {
    background: 'rgba(1,1,1,.1)',
    overflow: 'hidden',
  },
};

export function ModalDetailsEgresse({
  egresse,
  isOpen,
  handleIsOpen,
  useStateEgresse,
  useStateIsOpen,
}: PropsDetailsEgresse) {
  const { egresse: provider } = useGetEgressesById(egresse.id);

  const [isOpenModalOccurrences, setIsOpenModalOccurrences] = useState(false);
  const [isOpenModalRound, setIsOpenModalRound] = useState(false);
  const [avatar, setAvatar] = useState(null);
  const [isOpenImage, setIsOpenImage] = useState(false);
  function handleOpenImage() {
    setIsOpenImage(!isOpenImage);
  }
  // @ts-ignore
  const [egresse1, setEgresse] = useStateEgresse || [];
  // @ts-ignore
  const [isWatchEgresse, setIsWatchEgresse] = useStateIsOpen || [];

  function handleIsOpenModalOccurrences() {
    setIsOpenModalOccurrences(!isOpenModalOccurrences);
    handleIsOpen();
  }

  function handleIsOpenModalRound() {
    setIsOpenModalRound(!isOpenModalRound);
    handleIsOpen();
  }

  return (
    <div>
      <Modal
        isOpen={isOpen}
        ariaHideApp={false}
        shouldCloseOnOverlayClick
        // onRequestClose={handleIsOpen}
        // style={styleModal}
        toggle={handleIsOpen}
        // tyle={styleModal}
        size="lg"
      >
        <ModalHeader toggle={handleIsOpen}>Resumo do egresso</ModalHeader>
        <ModalBody>
          <HeaderStyle>
            <HeaderStyle>
              <div
                style={{
                  cursor: 'pointer',
                  width: '30%',
                  height: '100%',
                  marginRight: 10,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <label
                  className="label-title"
                  htmlFor="img-input"
                  style={{ cursor: 'pointer' }}
                >
                  {avatar ? (
                    <div>
                      <img
                        onClick={handleOpenImage}
                        style={{ borderRadius: '50%' }}
                        id="preview"
                        src={URL.createObjectURL(avatar)}
                        width={150}
                        height={150}
                      />
                    </div>
                  ) : (
                    <div>
                      <img
                        onClick={handleOpenImage}
                        style={{ borderRadius: '50%' }}
                        id="preview"
                        src={
                          !avatar && provider?.avatar
                            ? provider?.avatar?.url_file
                            : 'https://static.vecteezy.com/ti/vetor-gratis/p1/1840618-imagem-perfil-icone-masculino-icone-humano-ou-pessoa-sinal-e-simbolo-gr%C3%A1tis-vetor.jpg'
                        }
                        width={150}
                        height={150}
                      />
                    </div>
                  )}
                </label>
                <input
                  id="img-input"
                  type="file"
                  name="avatar"
                  disabled
                  className="d-none"
                  onChange={e => {
                    // @ts-ignore
                    setAvatar(e.target.files[0]);
                  }}
                />
              </div>

              <div
                style={{
                  flexDirection: 'column',
                  alignContent: 'center',
                  justifyContent: 'center',
                  width: '70%',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    height: 25,
                    justifyContent: 'end',
                    alignContent: 'end',
                    alignItems: 'end',
                    marginTop: 10,
                    marginBottom: 10,
                  }}
                >
                  <div
                  // style={{
                  //   background: "#1B7E9F", marginBottom: 20, padding: 5, borderRadius: 20, display: "flex", alignItems: "center", justifyContent: "center",
                  // }}
                  // eslint-disable-next-line react/jsx-closing-bracket-location
                  >
                    <Badge style={{ fontSize: 15 }} color="primary">
                      {egresse?.tipo_regime || 'Não informado'}
                    </Badge>
                    {/* <h1 style={{ marginBottom: 0, fontSize: 16, color: '#fff' }}>{egresse?.tipo_regime || 'Não informado'}</h1> */}
                  </div>
                </div>
                <div style={{ justifyContent: 'start' }}>
                  <h1
                    style={{
                      fontSize: 20,
                      fontWeight: 'bold',
                      marginBottom: 20,
                    }}
                  >
                    {egresse?.nome}
                  </h1>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      marginBottom: 5,
                    }}
                  >
                    <h1 style={{ fontSize: 14, fontWeight: 'bold' }}>
                      Alcunha:
                    </h1>
                    <h2
                      style={{
                        fontSize: 14,
                        marginLeft: 5,
                        fontWeight: 'normal',
                      }}
                    >
                      {egresse?.apelido || 'Não informado'}
                    </h2>
                  </div>

                  {provider?.endereco
                    ?.filter((addr: any) => addr.atual)
                    ?.map((add: any) => (
                      <div
                        style={{
                          marginBottom: 10,
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        <div>
                          <div style={{ display: 'flex' }}>
                            <ImLocation size={20} color="#E33E09" />
                            <h2
                              style={{
                                marginLeft: 5,
                                marginBottom: 0,
                                fontSize: 14,
                                fontWeight: 'normal',
                              }}
                            >
                              {add.endereco},{add.numero_endereco},{add.bairro},{' '}
                              {add.municipio} -{add.estado},{add.cep}
                              {add?.complemento && `, ${add?.complemento}`}
                            </h2>
                          </div>
                          <div>
                            <span
                              style={{
                                marginLeft: 5,
                                marginBottom: 0,
                                fontSize: 12,
                                fontWeight: 'bold',
                              }}
                            >
                              Endereço atualizado em:{' '}
                              {new Date(add.created_at).toLocaleDateString(
                                'pt-br',
                              )}
                            </span>
                          </div>
                        </div>
                      </div>
                    ))}

                  {provider?.endereco?.filter((addr: any) => addr.atual)
                    ?.length === 0 && (
                    <div
                      style={{
                        marginBottom: 10,
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <ImLocation size={20} color="#E33E09" />
                      <h1
                        style={{
                          marginLeft: 5,
                          fontSize: 14,
                          marginBottom: 0,
                          fontWeight: 'normal',
                        }}
                      >
                        Sem endereço atual
                      </h1>
                    </div>
                  )}

                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      marginTop: 15,
                      marginBottom: 5,
                    }}
                  >
                    <h1 style={{ fontSize: 14, fontWeight: 'bold' }}>
                      Telefone:
                    </h1>
                    <h2
                      style={{
                        fontSize: 14,
                        marginLeft: 5,
                        fontWeight: 'normal',
                      }}
                    >
                      {mask_phone(provider?.telefone_recado) || 'Não informado'}
                    </h2>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      marginBottom: 5,
                    }}
                  >
                    <h1 style={{ fontSize: 14, fontWeight: 'bold' }}>
                      Horário da Extensão:
                    </h1>
                    <h2
                      style={{
                        fontSize: 14,
                        marginLeft: 5,
                        fontWeight: 'normal',
                      }}
                    >
                      {provider?.horario_extensao || 'Sem extensão'}
                    </h2>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                    }}
                  >
                    <h1 style={{ fontSize: 14, fontWeight: 'bold' }}>
                      Observação:
                    </h1>
                    <h2
                      style={{
                        fontSize: 14,
                        marginLeft: 5,
                        fontWeight: 'normal',
                        textAlign: 'justify',
                      }}
                    >
                      {provider?.observacao || 'Sem observação'}
                    </h2>
                  </div>
                </div>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <div
                    style={{
                      display: 'flex',
                    }}
                  >
                    <h1 style={{ fontSize: 14, fontWeight: 'bold' }}>
                      Artigo Penal:
                    </h1>
                    <h2
                      style={{
                        fontSize: 14,
                        marginLeft: 5,
                        fontWeight: 'normal',
                        textAlign: 'justify',
                      }}
                    >
                      {provider?.codigo_penal || 'Sem Artigo Penal'}
                    </h2>
                  </div>

                  <div
                    style={{
                      display: 'flex',
                    }}
                  >
                    <h1 style={{ fontSize: 14, fontWeight: 'bold' }}>
                      Número do Processo:
                    </h1>
                    <h2
                      style={{
                        fontSize: 14,
                        marginLeft: 5,
                        fontWeight: 'normal',
                        textAlign: 'justify',
                      }}
                    >
                      {provider?.numero_processo1 || 'Sem processo'}
                    </h2>
                  </div>
                </div>
              </div>
            </HeaderStyle>
          </HeaderStyle>
          <h1
            style={{
              fontWeight: 'bold',
              marginTop: 20,
              fontSize: 20,
              marginBottom: 20,
            }}
          >
            Fiscalizações
          </h1>

          <Form>
            {provider?.inRound?.length > 0 && (
              <FormGroup>
                <Label style={{ fontWeight: 'bold' }} for="actual_round1">
                  Ronda Atual
                </Label>
                <InputGroup>
                  <Input value={provider?.inRound[0]?.ronda?.title} disabled />
                  <InputGroupText>
                    {moment(provider?.inRound[0]?.ronda?.data_ronda).format(
                      'DD/MM/YYYY',
                    )}
                  </InputGroupText>
                </InputGroup>
              </FormGroup>
            )}

            <Row style={{ alignItems: 'center' }}>
              <Col md={6}>
                <FormGroup>
                  <Label style={{ fontWeight: 'bold' }} for="actual_round2">
                    Número de Fiscalizações
                  </Label>
                  {/* <div style={{ display: "flex", alignItems: "center" }}> */}
                  <Input
                    id="actual_round2"
                    name="actual_round2"
                    value={provider?.rondas?.total}
                    type="text"
                    disabled
                  />
                  {/* <div /> */}
                  {/* <BsFillArrowRightSquareFill onClick={handleIsOpenModalRound} style={{ cursor: 'pointer', marginLeft: 5 }} color="#565656" size={45} /> */}
                  {/* </div> */}
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label style={{ fontWeight: 'bold' }} for="actual_round3">
                    Ocorrências
                  </Label>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Input
                      id="actual_round3"
                      name="actual_round3"
                      type="text"
                      value={
                        provider?.rondas?.rondasTodas?.filter(
                          (item: any) => item.ronda && item.ocorrencia,
                        ).length
                      }
                      disabled
                    />
                    <BsFillArrowRightSquareFill
                      onClick={handleIsOpenModalOccurrences}
                      style={{ cursor: 'pointer', marginLeft: 5 }}
                      color="#565656"
                      size={45}
                    />
                  </div>
                </FormGroup>
              </Col>
            </Row>
          </Form>

          <div
            style={{
              display: 'flex',
              marginTop: 20,
              justifyContent: 'space-between',
              flexDirection: 'column',
              width: '100%',
              background: '#f6f6f6',
            }}
          >
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <h1
                style={{
                  margin: 10,
                  fontSize: 16,
                  fontWeight: 'bold',
                }}
              >
                Últimas Fiscalizações
              </h1>
              <div />
            </div>

            <div
              style={{
                width: '100%',
                borderBottom: 2,
                borderBottomStyle: 'solid',
                borderBottomColor: '#1B7E9F',
              }}
            />
          </div>

          <Table style={{ marginTop: 20 }}>
            <tbody>
              {provider?.rondas?.rondasTodas
                ?.sort(
                  (a: any, b: any) =>
                    Date.parse(b?.data_ocorrencia) -
                    Date.parse(a?.data_ocorrencia),
                )
                ?.slice(0, 3)
                ?.map((item: any) => (
                  <>
                    {item?.ronda && item?.ocorrencia && (
                      <tr>
                        <td style={{ textAlign: 'start' }}>
                          {item?.ronda?.title}
                        </td>
                        <td style={{ textAlign: 'start' }}>
                          {item?.data_ocorrencia
                            ? moment(item?.data_ocorrencia).format(
                                'DD/MM/YYYY [às] HH:mm',
                              )
                            : 'DD/MM/YYYY'}
                        </td>
                        <td style={{ textAlign: 'end' }}>
                          {item?.ocorrencia?.name}
                        </td>
                      </tr>
                    )}
                  </>
                ))}
            </tbody>
          </Table>
        </ModalBody>

        <ModalFooter className="d-flex justify-content-end">
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              marginTop: 50,
              width: '100%',
            }}
          >
            {setEgresse && setIsWatchEgresse ? (
              <div
              // onClick={() => {
              //   handleIsOpen();
              //   setEgresse(egresse);
              //   setIsWatchEgresse(true);
              // }}
              // style={{
              //   display: "flex", cursor: "pointer", borderRadius: 5, width: 145, alignItems: 'center', justifyContent: "center", height: 40, background: '#1B7E9F',
              // }}
              // eslint-disable-next-line react/jsx-closing-bracket-location
              >
                <Button
                  onClick={() => {
                    handleIsOpen();
                    setEgresse(provider);
                    setIsWatchEgresse(true);
                  }}
                  color="primary"
                >
                  Perfil do Egresso
                </Button>
                {/* <h1 style={{ fontSize: 14, marginBottom: 0, color: '#fff' }}>Perfil do Egresso</h1> */}
              </div>
            ) : (
              <div />
            )}

            <h1 style={{ fontSize: 14, marginBottom: 0, color: '#fff' }}>
              Perfil do Egresso
            </h1>
            <Button onClick={handleIsOpen}>Voltar</Button>
            {/* <h1 style={{ fontSize: 14, marginBottom: 0, color: '#fff' }}>Voltar</h1> */}
          </div>
        </ModalFooter>
      </Modal>
      <ModalOccurrences
        isOpen={isOpenModalOccurrences}
        egresse={provider}
        handleIsOpen={handleIsOpenModalOccurrences}
      />

      <ModalRound
        isOpen={isOpenModalRound}
        egresse={provider}
        handleIsOpen={handleIsOpenModalRound}
      />

      <ModalImage
        // @ts-ignore
        image={provider?.avatar?.url_file}
        isOpen={isOpenImage}
        handleIsOpen={handleOpenImage}
      />
    </div>
  );
}

const HeaderStyle = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  @media only screen and (max-width: 800px) {
    flex-direction: column;
    align-items: center;
  }
`;
