import React from 'react';
import { FaPlusCircle } from 'react-icons/fa';
import { Button } from 'reactstrap';
import styled from 'styled-components';

type ButtonAddProps = {
  onClick: React.FormEventHandler<HTMLButtonElement> | undefined
  style?: any
}

export function ButtonAdd(props: ButtonAddProps) {
  const { onClick, style = {} } = props;
  return (
    <ButtonComponent
      style={{ ...style, background: '#68996D' }}
      color="success"
      size="lg"
      onClick={onClick}
    >
      <span><FaPlusCircle /></span>
      <span>Adicionar</span>
    </ButtonComponent>
  );
}

const ButtonComponent = styled(Button)`
  display: flex;
  border-radius: 4px;
  gap: 10px;
  @media (max-width: 768px) {
    span:nth-child(2) {
      display: none;
    }
    span:nth-child(1) {
      display: flex;
    }
  }
`;
