import React, { useEffect, useRef, useState } from 'react';
import {
  Doughnut, Bar, getElementsAtEvent, Line,
} from 'react-chartjs-2';
import { ToastContainer, toast } from 'react-toastify';

import { Input } from 'reactstrap';

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';

// import { faker } from '@faker-js/faker';
import { IoIosArrowForward, IoIosArrowUp } from 'react-icons/io';
import { BsDot } from 'react-icons/bs';
import styled from 'styled-components';
import { AbaFilterContent } from '../../../components/develop-nilton-components/AbaFiltro';
import { TableGeral } from '../../Fiscal/CadastrarRonda/components/TableGeral';

import { useAuth } from '../../../hooks/auth';

import { useRounds } from '../../Fiscal/CadastrarRonda/hooks/useRounds';
import { Rounds } from '../../Fiscal/CadastrarRonda/types';
import api from '../../../services/api';
import { SectionHeader } from '../../../components/SectionHeader';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
);

export const Fiscal = () => {
  const { profile } = useAuth();

  const roundsGeral = useRounds<Rounds>();

  const chartRef = useRef();
  const chartRefLine = useRef();

  const [value, setValue] = useState('0');

  const [dashboard, setDashboard] = useState<any>({
    graph: null,
    rondas: {
      total: 0,
      homologadas: 0,
      andamento: 0,
      pendentes: 0,
    },
    alvaras: {
      total: 0,
      executados: 0,
      nao_executados: 0,
      pendentes: 0,
    },
    pads: {
      total: 0,
      executados: 0,
      nao_executados: 0,
      pendentes: 0,
    },
  });

  const [filtersLine, setFiltersLine] = useState<any>({
    isPad: 'false',
    initial_date: undefined,
    final_date: undefined,
    responsable_id_execucao: undefined,
    isLine: 'true',
  });

  const [filtersDonougth, setFiltersDonougth] = useState<any>({
    isPad: 'false',
    initial_date: undefined,
    final_date: undefined,
    responsable_id_execucao: undefined,
    isLine: 'false',
  });

  const [dataLine, setDataLine] = useState({
    labels: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun'],
    datasets: [
      {
        label: 'Total',
        data: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun'].map(() => 0),
        borderColor: '#EFC903',
        backgroundColor: '#EFC903',
      },
      {
        label: 'Executados',
        data: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun'].map(() => 0),
        borderColor: '#0796D7',
        backgroundColor: '#0796D7',
      },
      {
        label: 'Não Executados',
        data: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun'].map(() => 0),
        borderColor: '#DC7070',
        backgroundColor: '#DC7070',
      },
    ],
  });

  const [dataDonougth, setDataDonougth] = useState({
    labels: ['Executados', 'Não Executados'],
    datasets: [
      {
        label: '',
        data: [70, 30],
        borderColor: ['#fff'],
        backgroundColor: [
          '#0796D7',
          'rgba(201, 19, 19, 0.6)',
        ],
        pointBackgroundColor: '#fff',
      },

    ],
  });

  const [fiscais, setFiscais] = useState([]);

  const [errorGraphLine, setErrorGraphLine] = useState("");
  const [errorGraphDonougth, setErrorGraphDonougth] = useState("");

  async function getDashboard() {
    api.get('/users', {
      params: {
        profile_id: 3,
        nopaginate: true,
      },
    }).then(({ data }) => {
      setFiscais(data);
    });

    api.get('/dashboard', {
      params: filtersLine,
    }).then(({ data }) => {
      setDashboard(data);
      setDataLine(data.graph);
      setErrorGraphLine("");
    }).catch((error) => {
      if (filtersLine.initial_date && filtersLine.final_date) {
        setErrorGraphLine(error?.response?.data?.message);
        /* toast(error?.response?.data?.message, {
          type: 'warning',
        }); */
      }
    });

    api.get('/dashboard', {
      params: filtersDonougth,
    }).then(({ data }) => {
      setDashboard(data);
      setDataDonougth(data.graph);
      setValue(data.graph.total);
      setErrorGraphDonougth("");
    }).catch((error) => {
      if (filtersDonougth.initial_date && filtersDonougth.final_date) {
        setErrorGraphDonougth(error?.response?.data?.message);
        /* toast(error?.response?.data?.message, {
          type: 'warning',
        }); */
      }
    });
  }

  useEffect(() => {
    getDashboard();
  }, [filtersLine, filtersDonougth]);

  async function handleUpdate(page?: number) {
    roundsGeral.handleSetRounds(page);
  }

  const [open, setOpen] = useState({
    form1: false,
    form2: false,
    form3: false,
  });

  const onClick = (event: any) => {
    // @ts-ignore
    const clickedElements = getElementsAtEvent(chartRef?.current, event);
    if (clickedElements.length > 0) {
      // @ts-ignore
      // setValue(clickedElements[0].element.$context.raw);
    }
  };

  const onClickLine = (event: any) => {
    // @ts-ignore
    const clickedElements = getElementsAtEvent(chartRefLine?.current, event);
    if (clickedElements.length > 0) {
      // @ts-ignore
      console.log(clickedElements[0].element.$context.raw);
    }
  };

  const options = {
    plugins: {
      tooltip: {
        enabled: true,
      },
      legend: {
        display: false,
        position: 'top' as const,
      },
    },
    value,
  };

  const optionsLine = {
    responsive: true,
    scales: {
      x: {
        grid: {
          display: true,
        },
      },
      y: {
        grid: {
          display: false,
        },
      },
    },
    plugins: {
      legend: {
        display: false,
        position: 'top' as const,
      },
      tooltip: {
        enabled: true,
      },
      title: {
        display: false,
        text: 'Chart.js Line Chart',
      },
    },
  };

  return (
    <>
      <SectionHeader subtitle="Dashboard" />

      <WrapperContainer id="painel-container">
        <div
          className="header"
        >

          <div
            className="subheader"
            style={{
              display: 'flex', height: open.form1 ? 200 : 88, alignItems: 'center', justifyContent: "space-between", borderStyle: "solid", borderColor: '#1B7E9F', borderWidth: 2, borderRadius: 8,
            }}
          >
            <div style={{ width: '100%', display: "flex", flexDirection: 'column' }}>
              <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <h1 style={{
                    marginLeft: 20, marginBottom: 0, color: '#1B7E9F', fontSize: 28, marginRight: 16,
                  }}
                  >
                    {String(dashboard.rondas.total).padStart(2, '0')}
                  </h1>
                  <h1 style={{ marginBottom: 0, color: '#2C2C2C', fontSize: 22 }}>Fiscalizações Gerais</h1>
                </div>
                {!open.form1 && profile?.title === 'Fiscal' && <IoIosArrowForward onClick={() => setOpen({ ...open, form1: true })} size={30} color="#1B7E9F" style={{ marginRight: 10, cursor: 'pointer' }} />}
              </div>
              {open.form1 && profile?.title === 'Fiscal' && (
              <>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <h1 style={{
                    marginLeft: 20, marginBottom: 0, fontWeight: 'normal', color: 'rgba(4, 85, 12, 0.6)', fontSize: 24, marginRight: 16,
                  }}
                  >
                    {String(dashboard.rondas.homologadas).padStart(2, '0')}
                  </h1>
                  <h1 style={{
                    marginBottom: 0, color: '#2C2C2C', fontWeight: 'normal', fontSize: 18,
                  }}
                  >
                    Homologadas
                  </h1>
                </div>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <h1 style={{
                    marginLeft: 20, marginBottom: 0, fontWeight: 'normal', color: '#EFC903', fontSize: 24, marginRight: 16,
                  }}
                  >
                    {String(dashboard.rondas.andamento).padStart(2, '0')}
                  </h1>
                  <h1 style={{
                    marginBottom: 0, color: '#2C2C2C', fontWeight: 'normal', fontSize: 18,
                  }}
                  >
                    Em Andamento
                  </h1>
                </div>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <h1 style={{
                    marginLeft: 20, marginBottom: 0, fontWeight: 'normal', color: '#E33E09', fontSize: 24, marginRight: 16,
                  }}
                  >
                    {String(dashboard.rondas.pendentes).padStart(2, '0')}
                  </h1>
                  <h1 style={{
                    marginBottom: 0, color: '#2C2C2C', fontWeight: 'normal', fontSize: 18,
                  }}
                  >
                    Pendente de Homologação
                  </h1>
                </div>
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                  <IoIosArrowUp
                    onClick={() => setOpen({ ...open, form1: false })}
                    size={30}
                    color="#1B7E9F"
                    style={{
                      marginRight: 10, cursor: 'pointer',
                    }}
                  />
                </div>
              </>
              )}
            </div>
          </div>

          <div
            className="subheader"
            style={{
              display: 'flex', height: open.form2 ? 200 : 88, alignItems: 'center', justifyContent: "space-between", borderStyle: "solid", borderColor: '#1B7E9F', borderWidth: 2, borderRadius: 8,
            }}
          >
            <div style={{ width: '100%', display: "flex", flexDirection: 'column' }}>
              <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <h1 style={{
                    marginLeft: 20, marginBottom: 0, color: '#1B7E9F', fontSize: 28, marginRight: 16,
                  }}
                  >
                    {String(dashboard.alvaras.total).padStart(2, '0')}
                  </h1>
                  <h1 style={{ marginBottom: 0, color: '#2C2C2C', fontSize: 22 }}>Alvarás Gerais</h1>
                </div>
                {!open.form2 && profile?.title === 'Fiscal' && <IoIosArrowForward onClick={() => setOpen({ ...open, form2: true })} size={30} color="#1B7E9F" style={{ marginRight: 10, cursor: 'pointer' }} />}
              </div>
              {open.form2 && profile?.title === 'Fiscal' && (
              <>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <h1 style={{
                    marginLeft: 20, marginBottom: 0, fontWeight: 'normal', color: 'rgba(4, 85, 12, 0.6)', fontSize: 24, marginRight: 16,
                  }}
                  >
                    {String(dashboard.alvaras.executados).padStart(2, '0')}
                  </h1>
                  <h1 style={{
                    marginBottom: 0, color: '#2C2C2C', fontWeight: 'normal', fontSize: 18,
                  }}
                  >
                    Executados
                  </h1>
                </div>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <h1 style={{
                    marginLeft: 20, marginBottom: 0, fontWeight: 'normal', color: '#EFC903', fontSize: 24, marginRight: 16,
                  }}
                  >
                    {String(dashboard.alvaras.nao_executados).padStart(2, '0')}
                  </h1>
                  <h1 style={{
                    marginBottom: 0, color: '#2C2C2C', fontWeight: 'normal', fontSize: 18,
                  }}
                  >
                    Não Executado
                  </h1>
                </div>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <h1 style={{
                    marginLeft: 20, marginBottom: 0, fontWeight: 'normal', color: '#E33E09', fontSize: 24, marginRight: 16,
                  }}
                  >
                    {String(dashboard.alvaras.pendentes).padStart(2, '0')}
                  </h1>
                  <h1 style={{
                    marginBottom: 0, color: '#2C2C2C', fontWeight: 'normal', fontSize: 18,
                  }}
                  >
                    Pendente de Execução
                  </h1>
                </div>
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                  <IoIosArrowUp
                    onClick={() => setOpen({ ...open, form2: false })}
                    size={30}
                    color="#1B7E9F"
                    style={{
                      marginRight: 10, cursor: 'pointer',
                    }}
                  />
                </div>
              </>
              )}
            </div>
          </div>

          <div
            className="subheader"
            style={{
              display: 'flex', height: open.form3 ? 200 : 88, alignItems: 'center', justifyContent: "space-between", borderStyle: "solid", borderColor: '#1B7E9F', borderWidth: 2, borderRadius: 8,
            }}
          >
            <div style={{ width: '100%', display: "flex", flexDirection: 'column' }}>
              <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <h1 style={{
                    marginLeft: 20, marginBottom: 0, color: '#1B7E9F', fontSize: 28, marginRight: 16,
                  }}
                  >
                    {String(dashboard.pads.total).padStart(2, '0')}
                  </h1>
                  <h1 style={{ marginBottom: 0, color: '#2C2C2C', fontSize: 22 }}>Notificações Gerais</h1>
                </div>
                {!open.form3 && profile?.title === 'Fiscal' && <IoIosArrowForward onClick={() => setOpen({ ...open, form3: true })} size={30} color="#1B7E9F" style={{ marginRight: 10, cursor: 'pointer' }} />}
              </div>
              {open.form3 && profile?.title === 'Fiscal' && (
              <>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <h1 style={{
                    marginLeft: 20, marginBottom: 0, fontWeight: 'normal', color: 'rgba(4, 85, 12, 0.6)', fontSize: 24, marginRight: 16,
                  }}
                  >
                    {String(dashboard.pads.executados).padStart(2, '0')}
                  </h1>
                  <h1 style={{
                    marginBottom: 0, color: '#2C2C2C', fontWeight: 'normal', fontSize: 18,
                  }}
                  >
                    Executados
                  </h1>
                </div>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <h1 style={{
                    marginLeft: 20, marginBottom: 0, fontWeight: 'normal', color: '#EFC903', fontSize: 24, marginRight: 16,
                  }}
                  >
                    {String(dashboard.pads.nao_executados).padStart(2, '0')}
                  </h1>
                  <h1 style={{
                    marginBottom: 0, color: '#2C2C2C', fontWeight: 'normal', fontSize: 18,
                  }}
                  >
                    Não Executado
                  </h1>
                </div>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <h1 style={{
                    marginLeft: 20, marginBottom: 0, fontWeight: 'normal', color: '#E33E09', fontSize: 24, marginRight: 16,
                  }}
                  >
                    {String(dashboard.pads.pendentes).padStart(2, '0')}
                  </h1>
                  <h1 style={{
                    marginBottom: 0, color: '#2C2C2C', fontWeight: 'normal', fontSize: 18,
                  }}
                  >
                    Pendente de Execução
                  </h1>
                </div>
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                  <IoIosArrowUp
                    onClick={() => setOpen({ ...open, form3: false })}
                    size={30}
                    color="#1B7E9F"
                    style={{
                      marginRight: 10, cursor: 'pointer',
                    }}
                  />
                </div>
              </>
              )}
            </div>
          </div>

        </div>

        <div className="content" style={{ display: "flex" }}>
          <div
            className="subcontent1"
            style={{
              display: "flex", flexDirection: "column", justifyContent: "space-between", background: '#FBFAFA', padding: 10,
            }}
          >
            <div>
              <div
                className="inputs"
              >
                {/* <Input className="subinputs" style={{ marginRight: 5 }} onChange={(e) => setFiltersLine({ ...filtersLine, responsable_id_execucao: e.target.value == 'Todos' ? undefined : e.target.value })} type="select" name="user" id="user">
                  <option value="Todos">Todos</option>
                  {fiscais.map((item: any) => (
                    <option value={item.id}>{item.name}</option>
                  ))}
                </Input>
                <Input className="subinputs" style={{ marginRight: 5 }} onChange={(e) => setFiltersLine({ ...filtersLine, isPad: e.target.value == 'Alvarás' ? 'false' : 'true' })} type="select" name="user" id="user">
                  <option value="Alvarás">Alvarás</option>
                  <option value="Notificações">Notificações</option>
                </Input> */}

                <div>
                  <Input className="subinputs" type="select" name="user" id="user">
                    <option value="Fiscalizações">Fiscalizações</option>
                  </Input>
                </div>

                <div style={{ display: "flex" }}>
                  <Input className="subinputs" onChange={(e) => setFiltersLine({ ...filtersLine, initial_date: e.target.value })} style={{ marginRight: 5 }} type="date" />
                  <Input className="last-date" onChange={(e) => setFiltersLine({ ...filtersLine, final_date: e.target.value })} type="date" />
                </div>
              </div>
              <div style={{ marginTop: 5, marginBottom: 5 }}>
                <span style={{ color: '#f00' }}>{errorGraphLine}</span>
              </div>
            </div>

            <div>
              <Bar ref={chartRefLine} onClick={onClickLine} options={optionsLine} data={dataLine} />
            </div>

            <div>
              <div style={{ display: "flex", alignItems: "center" }}>
                <BsDot size={30} color="#0796D7" />
                <h1 style={{ fontSize: 12, marginBottom: 0 }}>Total</h1>
              </div>
              <div style={{ display: "flex", alignItems: "center", marginTop: -10 }}>
                <BsDot size={30} color="#EFC903" />
                <h1 style={{ fontSize: 12, marginBottom: 0 }}>Sem Problemas</h1>
              </div>
              <div style={{ display: "flex", alignItems: "center", marginTop: -10 }}>
                <BsDot size={30} color="#DC7070" />
                <h1 style={{ fontSize: 12, marginBottom: 0 }}>Com Problemas</h1>
              </div>
            </div>
          </div>

          <div className="separate" style={{ width: 10, background: '#fff' }} />

          <div
            className="subcontent2"
            style={{
              display: "flex", flexDirection: "column", justifyContent: "space-between", background: '#FBFAFA', padding: 10,
            }}
          >
            <div>
              <div className="inputs">
                <div />
                <Input style={{ width: 150 }} className="last-date" type="select" onChange={(e) => setFiltersDonougth({ ...filtersDonougth, isPad: e.target.value == 'Alvarás' ? 'false' : 'true' })} name="round" id="round">
                  <option value="Alvarás">Alvarás</option>
                  <option value="Notificações">Notificações</option>
                </Input>
              </div>
              {/* <div className="dates" style={{ display: "flex", marginTop: 10 }}>
                <Input onChange={(e) => setFiltersDonougth({ ...filtersDonougth, initial_date: e.target.value })} style={{ marginRight: 5 }} type="date" />
                <Input onChange={(e) => setFiltersDonougth({ ...filtersDonougth, final_date: e.target.value })} type="date" />
              </div> */}
            </div>

            <div style={{ marginTop: 5, marginBottom: 20 }}>
              <span style={{ color: '#f00' }}>{errorGraphDonougth}</span>
            </div>

            <div>
              <Doughnut
                ref={chartRef}
                onClick={onClick}
                plugins={
              [{
                id: 'here comes your id for the specific plugin',
                beforeDraw(chart) {
                  const { width } = chart;
                  const { height } = chart;
                  const { ctx } = chart;
                  ctx.restore();
                  const fontSize = (height / 160).toFixed(2);
                  ctx.font = `${fontSize}em sans-serif`;
                  ctx.textBaseline = "top";
                  ctx.fillStyle = '#1B7E9F';
                  // @ts-ignore
                  const text = chart?.config?.options?.value || (Number(chart?.config?.data?.datasets[0].data[0]) + Number(chart?.config?.data?.datasets[0].data[1]));
                  const textX = Math.round((width - ctx.measureText(text).width) / 2);
                  const textY = height / 2 - 10;
                  ctx.fillText(text, textX, textY);
                  ctx.save();
                },
              }]
            }
                data={dataDonougth}
                options={options}
              />
            </div>

            <div>
              <div style={{ display: "flex", alignItems: "center" }}>
                <BsDot size={30} color="#0796D7" />
                <h1 style={{ fontSize: 12, marginBottom: 0 }}>Executados</h1>
              </div>
              <div style={{ display: "flex", alignItems: "center", marginTop: -10 }}>
                <BsDot size={30} color="rgba(201, 19, 19, 0.6)" />
                <h1 style={{ fontSize: 12, marginBottom: 0 }}>Não Executados</h1>
              </div>
            </div>
          </div>
        </div>

        <div style={{ width: "100%", marginTop: 50 }}>
          <h1 style={{ color: '#2C2C2C', fontSize: 25, marginBottom: 20 }}>Últimas Fiscalizações</h1>
          <AbaFilterContent
            tab={1}
            components={
          [
            // @ts-ignore
            <TableGeral isDashboard roundsGeral={roundsGeral} rounds={roundsGeral?.rounds && roundsGeral?.rounds?.data.length > 0 ? { ...roundsGeral.rounds, data: roundsGeral.rounds?.data.slice(0, 5) } : null} handleUpdate={handleUpdate} />,
          ]
        }
          />
        </div>

      </WrapperContainer>
    </>
  );
};

const WrapperContainer = styled.div`
  .header {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 50px;

    @media (max-width: 1024px) {
      flex-direction: column;
      margin-bottom: 20px;
    }
  }

  .subheader {
    width: 33%;

    @media (max-width: 1024px) {
      width: 100%;
      margin-bottom: 20px;
    }
  }

  .content {
    @media (max-width: 1024px) {
      flex-direction: column;
    }
  }

  .separate {
    height: 450px;

    @media (max-width: 1024px) {
      height: 20px;
    }
  }

  .subcontent1 {
    width: 65%;

    @media (max-width: 1024px) {
      width 100%;
    }
  }

  .subcontent2 {
    width: 35%;

    @media (max-width: 1024px) {
      width 100%;
    }
  }

  .inputs {
    background: #FBFAFA;
    display: flex;
    align-items: center;
    justify-content: space-between;
    // margin-bottom: 20px;

    @media (max-width: 1024px) {
      flex-direction: column;
    }
  }

  .subinputs{
    @media (max-width: 1024px) {
      margin-top: 10px;
    }
  }

  .last-date{
    @media (max-width: 1024px) {
      margin-right: 5px;
      margin-top: 10px;
    }
  }

  .dates{
    @media (max-width: 400px) {
      width: 49%;
    }
  }
`;
