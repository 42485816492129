import React from 'react';
import { TablePadrão, Td, Tr } from '../../../../components/develop-nilton-components/TablePadrão';
import { SpinnerLoading } from '../../../../components/SpinnerLoading';
import { SubmitResponse } from '../../../../components/SubmitResponse';
import { Table } from '../types';
import { TbodyAdicionado } from './TbodyAdicionado';

export function TableAdicionado({
  handleUpdate, load, data, meta, addEgresses,
}:Table) {
  const headWidth = ["10%", "50%", "20%", "20%"];
  const thead = ["#", "Nome", "Detalhes", "Remover"];
  return (
    <div>
      <TablePadrão headWidth={headWidth} thead={thead}>
        {load && <Tr><Td width="100%"><SpinnerLoading /></Td></Tr>}
        {data?.length === 0 && (
          <Tr>
            <Td width="100%">
              <div className="w-100">
                <SubmitResponse submitResponse={{ message: "Não há egresses adicionado na turma", status: "danger", title: "Aviso" }} />
              </div>
            </Td>
          </Tr>
        )}

        {data?.length !== 0 && data?.map((item, i) => (
          <TbodyAdicionado
            key={i}
            handleUpdate={handleUpdate}
            egress={item}
            headWidth={headWidth}
            index={i + 1}
            addEgresses={addEgresses}
          />
        ))}
      </TablePadrão>
    </div>
  );
}
