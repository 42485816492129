import React, { useState } from 'react';
import ReactModal from 'react-modal';
import { useHistory } from 'react-router-dom';
import { Badge } from '../../../../components/develop-nilton-components/Badge';
import { IconAdd } from '../../../../components/develop-nilton-components/IconAdd';
import { IconConcluido } from '../../../../components/develop-nilton-components/IconConcluido';
import { IconDetails } from '../../../../components/develop-nilton-components/IconDetails';
import { Td, Tr } from '../../../../components/develop-nilton-components/TablePadrão';
import { ITbody } from '../types';

export function TbodyDesempenho({ courses_egresses, index, headWidth }: ITbody) {
  const history = useHistory();
  function handleRedirectAvaliacao() {
    history.push({
      pathname: '/analisar-egresso/avaliação',
      state: {
        id: courses_egresses.id,
        course_title: courses_egresses.course.title,
      },
    });
  }

  const [isOpen, setIsOpen] = useState(false);

  function handleIsOpen() {
    setIsOpen(!isOpen);
  }

  const dataDetalhes = [
    {
      title: 'Aspectos cognitivos',
      nota: courses_egresses.aspectos_cognitivos,
      text: courses_egresses.aspectos_cognitivos_text,
    },
    {
      title: 'Socialização',
      nota: courses_egresses.socializacao,
      text: courses_egresses.socializacao_text,
    },
    {
      title: 'Assiduidade / Pontualidade',
      nota: courses_egresses.assiduidade_pontualidade,
      text: courses_egresses.assiduidade_pontualidade_text,
    },
    {
      title: 'Aproveitamento prático',
      nota: courses_egresses.aproveitamentos_pratico,
      text: courses_egresses.aproveitamentos_pratico_text,
    },
  ];

  return (
    <Tr>
      <Td width={headWidth[0]}>{index}</Td>

      <Td width={headWidth[1]}>{courses_egresses.course.title}</Td>
      <Td width={headWidth[2]}>
        {courses_egresses.course.situacao === "Em andamento" && (
        <Badge type="warning">{courses_egresses.course.situacao}</Badge>
        )}
        {courses_egresses.course.situacao === "Pendente" && (
        <Badge type="info">{courses_egresses.course.situacao}</Badge>
        )}
        {courses_egresses.course.situacao === "Finalizado" && (
        <Badge type="success">{courses_egresses.course.situacao}</Badge>
        )}
        {courses_egresses.course.situacao === "Cancelado" && (
        <Badge type="danger">{courses_egresses.course.situacao}</Badge>
        )}
      </Td>
      <Td width={headWidth[3]}>{courses_egresses.egress.nome}</Td>
      <Td width={headWidth[4]}>
        <div className="d-flex justify-content-between">
          <div>
            {/* {courses_egresses.aproveitamentos_pratico_text && (
              <IconConcluido />
            )} */}
            {!courses_egresses.aproveitamentos_pratico_text && (
              <div onClick={handleRedirectAvaliacao} style={{ cursor: "pointer" }}>
                <IconAdd color="text-primary" />
              </div>
            )}
          </div>
          <div>
            {courses_egresses.aproveitamentos_pratico_text && (
            <div onClick={handleIsOpen}>
              <IconDetails tooltipMessage="Detalhes" cursor />
            </div>
            )}
            <ReactModal
              isOpen={isOpen}
              ariaHideApp={false}
              onRequestClose={handleIsOpen}
              style={{
                content: {
                  width: "80%",
                  margin: "auto auto",
                },
                overlay: {
                  background: "rgba(1,1,1,.1)",
                },
              }}
            >
              <div>
                <h2 className="border-bottom border-secondary">Relatório individual</h2>
                {dataDetalhes.map((item) => (
                  <div>
                    <h4 className="text-primary">
                      <span>{item.title}</span>
                      <span className={item.nota < 7 ? "text-danger" : "text-primary"}>
                        (Nota:
                        {' '}
                        {item.nota}
                        )
                      </span>
                    </h4>
                    <p>
                      {item.text}
                    </p>
                  </div>
                ))}
              </div>
            </ReactModal>
          </div>
        </div>

      </Td>
    </Tr>
  );
}
