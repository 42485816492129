import { Formik } from 'formik';
import React from 'react';
import * as yup from 'yup';
import {
  Button,
  Form,
  Label,
  ModalFooter,
  InputGroup,
  InputGroupText,
  Input,
} from 'reactstrap';
import { toast } from 'react-toastify';
import { AiFillPlusCircle } from 'react-icons/ai';
import { InputField } from '../../../InputField';
import { mask_only_number } from '../../../../../utils/mask_only_number';
import { mask_cpf } from '../../../../../utils/mask_cpf';
import { mask_cep } from '../../../../../utils/mask_cep';
import apiCep from '../../../../../services/apiCep';
import api from '../../../../../services/api';
import { estados } from '../../../../../utils/Estados';
import { municipios } from '../../../../../utils/Municipios';
import { mask_phone } from '../../../../../utils/mask_phone';

type FormPart1Props = {
  initialValues: any;
  handleNextStep: (newData: any) => void;
  handleBackStep: () => void;
  processos?: any;
  setProcessos?: (value: any) => void;
  artigos?: any;
  setArtigos?: (value: any) => void;
  isCreate: boolean;
};

export function FormPart2(props: FormPart1Props) {
  const {
    processos,
    artigos,
    setArtigos,
    isCreate,
    setProcessos,
    initialValues,
    handleNextStep,
    handleBackStep,
  } = props;

  const handleSubmit = (values: any) => {
    handleNextStep(values);
  };

  const validationSchema = yup.object({
    cadastro_calb: yup.string().nullable(),
    numero_cadastro: yup
      .string()
      .required('Insira o valor de cadastro')
      .nullable(),
    data_alvara_soltura: yup
      .string()
      .required('Insira a data de alvará de soltura')
      .nullable(),
    // extensao_horario: yup.string().required("Selecione se terá extensão do horário").nullable(),
    // data_entrada_sistema_penitenciario: yup
    //   .string()
    //   .required('Insira a data de entrada no sistema penitenciário')
    //   .nullable(),
  });

  React.useEffect(() => {
    if (initialValues.numero_processo1 && processos.length == 0) {
      // @ts-ignore
      setProcessos(
        initialValues.numero_processo1
          .split(',')
          .map((item: any) => item.trim()),
      );
    }
    if (initialValues.codigo_penal && artigos.length == 0) {
      // @ts-ignore
      setArtigos(
        initialValues.codigo_penal.split(',').map((item: any) => item.trim()),
      );
    }
  }, [initialValues]);

  return (
    <Formik
      initialValues={{
        ...initialValues,
        numero_processo1: null,
        codigo_penal: null,
      }}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
    >
      {props => {
        const { handleSubmit, setFieldValue, values } = props;
        const searchCep = (cep: string) => {
          if (cep.length === 9) {
            api.get(apiCep(cep)).then(res => {
              const { data } = res;
              if (data.erro === 'true') {
                toast.error('Preencha o endereço manualmente');
              }
              setFieldValue('endereco', data.logradouro ?? '');
              setFieldValue('estado', data.uf ?? '');
              setFieldValue('bairro', data.bairro ?? '');
            });
          }
        };

        const searchTrabalhaCep = (cep: string) => {
          if (cep.length === 9) {
            api.get(apiCep(cep)).then(res => {
              const { data } = res;
              if (data.erro === 'true') {
                toast.error('Preencha o endereço manualmente');
              }
              setFieldValue('endereco_trabalho', data.logradouro ?? '');
              setFieldValue('estado_trabalho', data.uf ?? '');
              setFieldValue('bairro_trabalho', data.bairro ?? '');
            });
          }
        };
        return (
          <Form onSubmit={handleSubmit}>
            <div>
              <div
                style={{ display: 'flex', flexDirection: 'row', width: '100%' }}
              >
                <div style={{ width: '50%', marginRight: 5 }}>
                  <InputField
                    label="Cadastro (CALBE)"
                    InputHTMLAttributes={{
                      id: 'cadastro_calb',
                      name: 'cadastro_calb',
                      placeholder: 'Esse número será gerado automaticamente',
                      disabled: true,
                      style: { marginRight: 10 },
                    }}
                  />
                </div>
                <div style={{ width: '50%' }}>
                  <InputField
                    label="Matrícula (IAPEN)*"
                    InputHTMLAttributes={{
                      id: 'numero_cadastro',
                      name: 'numero_cadastro',
                      placeholder: 'Insira o número de cadastro',
                    }}
                  />
                </div>
              </div>

              <div
                style={{ display: 'flex', flexDirection: 'row', width: '100%' }}
              >
                <div style={{ width: '50%', marginRight: 5 }}>
                  <div>
                    <InputField
                      label="Tipo de Regime Aberto"
                      InputHTMLAttributes={{
                        id: 'tipo_regime',
                        name: 'tipo_regime',
                        type: 'select',
                        value: values.tipo_regime || '-',
                        style: { marginRight: 10 },
                      }}
                      LabelHTMLAttributes={{
                        className: 'mr-3',
                      }}
                      options={
                        <>
                          <option value="-">-</option>
                          <option value="Domiciliar">Domiciliar</option>
                          <option value="Novo Crime">Novo Crime</option>
                          <option value="Descumprimento">Descumprimento</option>
                        </>
                      }
                    />
                    {/* <Label style={{ fontWeight: 'bold' }}>Tipo de Regime Aberto</Label>
                    <div className="d-flex">
                      <InputField
                        label="Domiciliar"
                        InputHTMLAttributes={{
                          id: 'Domiciliar',
                          name: 'tipo_regime',
                          type: "radio",
                          value: "Domiciliar",
                          defaultChecked: initialValues.tipo_regime === "Domiciliar",
                        }}
                        LabelHTMLAttributes={{
                          className: 'mr-3',
                        }}
                      />
                      <InputField
                        label="Novo crime"
                        InputHTMLAttributes={{
                          id: 'Novo crime',
                          name: 'tipo_regime',
                          type: "radio",
                          value: "Novo Crime",
                          defaultChecked: values.tipo_regime === "Novo Crime",
                        }}
                        LabelHTMLAttributes={{
                          className: 'mr-3',
                        }}
                      />
                      <InputField
                        label="Descumprimento"
                        InputHTMLAttributes={{
                          id: 'Descumprimento',
                          name: 'tipo_regime',
                          type: "radio",
                          value: "Descumprimento",
                          defaultChecked: values.tipo_regime === "Descumprimento",
                        }}
                      />
                    </div> */}
                  </div>
                </div>
                <div style={{ width: '50%' }}>
                  <InputField
                    label="Alvará de Soltura*"
                    InputHTMLAttributes={{
                      id: 'data_alvara_soltura',
                      name: 'data_alvara_soltura',
                      type: 'date',
                    }}
                  />
                </div>
              </div>

              <div
                style={{ display: 'flex', flexDirection: 'row', width: '100%' }}
              >
                <div style={{ width: '50%', marginRight: 5 }}>
                  <InputField
                    label="Entrada no Sistema Penitenciário"
                    InputHTMLAttributes={{
                      id: 'data_entrada_sistema_penitenciario',
                      name: 'data_entrada_sistema_penitenciario',
                      type: 'date',
                      style: { marginRight: 10 },
                    }}
                  />
                </div>
                <div style={{ width: '50%' }}>
                  <InputField
                    label="Término da pena"
                    InputHTMLAttributes={{
                      id: 'data_termino_pena',
                      name: 'data_termino_pena',
                      type: 'date',
                    }}
                  />
                </div>
              </div>

              <div
                style={{ display: 'flex', flexDirection: 'row', width: '100%' }}
              >
                <div
                  style={{
                    width:
                      values.extensao_horario === 'T' ||
                      values.extensao_horario === true
                        ? '50%'
                        : '100%',
                    marginRight:
                      values.extensao_horario == true ||
                      values.extensao_horario == 'T'
                        ? 5
                        : 0,
                  }}
                >
                  <div>
                    <InputField
                      label="Extensão de Horário"
                      InputHTMLAttributes={{
                        id: 'extensao_horario',
                        name: 'extensao_horario',
                        type: 'select',
                        value: values.extensao_horario
                          ? values.extensao_horario == '-'
                            ? '-'
                            : values.extensao_horario == true ||
                              values.extensao_horario == 'T'
                            ? 'T'
                            : 'F'
                          : '-',
                        style: {
                          marginRight:
                            values.extensao_horario == true ||
                            values.extensao_horario == 'T'
                              ? 10
                              : 0,
                        },
                      }}
                      LabelHTMLAttributes={{
                        className: 'mr-3',
                      }}
                      options={
                        <>
                          <option value="-">-</option>
                          <option value="T">Sim</option>
                          <option value="F">Não</option>
                        </>
                      }
                    />
                    {/* <Label style={{ fontWeight: 'bold' }}>Extensão de Horário *</Label>
                <div className="d-flex">
                  <InputField
                    label="Sim"
                    InputHTMLAttributes={{
                      id: 'extensao_horario1',
                      name: 'extensao_horario',
                      type: "radio",
                      value: "T",
                      defaultChecked: (values.extensao_horario === 'T' || values.extensao_horario === true),
                    }}
                    LabelHTMLAttributes={{
                      className: 'mr-3',
                    }}
                  />
                  <InputField
                    label="Não"
                    InputHTMLAttributes={{
                      id: 'extensao_horario2',
                      name: 'extensao_horario',
                      type: "radio",
                      value: "F",
                      defaultChecked: (values.extensao_horario === 'F' || values.extensao_horario === false),
                    }}
                  />
                </div> */}
                  </div>
                </div>
                {(values.extensao_horario === 'T' ||
                  values.extensao_horario === true) && (
                  <div style={{ width: '50%' }}>
                    <InputField
                      label="Horário da Extensão"
                      InputHTMLAttributes={{
                        id: 'horario_extensao',
                        name: 'horario_extensao',
                        placeholder: 'Insira o horário da extensão',
                        type: 'time',
                        // style: { marginLeft: 10 },
                      }}
                    />
                  </div>
                )}
              </div>

              {(values.extensao_horario === 'T' ||
                values.extensao_horario === true) && (
                <>
                  <InputField
                    label="Motivo da Extensão"
                    InputHTMLAttributes={{
                      id: 'motivo_extensao',
                      name: 'motivo_extensao',
                      placeholder: 'Insira o motivo da extensão',
                      type: 'textarea',
                      // @ts-ignore
                      rows: 4,
                    }}
                  />
                </>
              )}
              <InputField
                iconPlus
                setFieldValue={setFieldValue}
                data={artigos}
                setData={setArtigos}
                label="Código Penal"
                InputHTMLAttributes={{
                  id: `codigo_penal`,
                  name: `codigo_penal`,
                  placeholder: 'Insira o código penal',
                  onChange: e => setFieldValue('codigo_penal', e.target.value),
                }}
              />

              {artigos?.map((artigo: any, index: number) => (
                <InputField
                  iconTrash
                  data={artigos}
                  setData={setArtigos}
                  label={`Código ${index + 1}`}
                  InputHTMLAttributes={{
                    id: artigo,
                    name: `codigo_penal_${index + 1}`,
                    placeholder: 'Insira o código penal',
                    value: artigo,
                    disabled: true,
                  }}
                />
              ))}
              <InputField
                iconPlus
                setFieldValue={setFieldValue}
                data={processos}
                setData={setProcessos}
                label="Processo"
                InputHTMLAttributes={{
                  id: `numero_processo1`,
                  name: `numero_processo1`,
                  placeholder: 'Insira o número de processo',
                  onChange: e =>
                    setFieldValue(
                      'numero_processo1',
                      mask_only_number(e.target.value),
                    ),
                }}
              />

              {processos?.map((processo: any, index: number) => (
                <InputField
                  iconTrash
                  data={processos}
                  setData={setProcessos}
                  label={`Processo ${index + 1}`}
                  InputHTMLAttributes={{
                    id: processo,
                    name: `numero_processo_${index + 1}`,
                    placeholder: 'Insira o número de processo',
                    value: processo,
                    disabled: true,
                  }}
                />
              ))}

              {/*  <InputField
              label="RG"
              InputHTMLAttributes={{
                id: 'rg',
                name: 'rg',
                placeholder: 'Insira o RG',
                onChange: (e) => setFieldValue('rg', mask_only_number(e.target.value)),
              }}
            />
            <InputField
              label="CPF"
              InputHTMLAttributes={{
                id: 'cpf',
                name: 'cpf',
                placeholder: 'Insira o CPF',
                onChange: (e) => setFieldValue('cpf', mask_cpf(e.target.value)),
              }}
            /> */}
              {/* <InputField
              label="NIS (número social)"
              InputHTMLAttributes={{
                id: 'nis',
                name: 'nis',
                placeholder: 'Insira o NIS',
                onChange: (e) => setFieldValue('nis', mask_only_number(e.target.value)),
              }}
            />
            <InputField
              label="Carteira de trabalho"
              InputHTMLAttributes={{
                id: 'carteira_trabalho',
                name: 'carteira_trabalho',
                placeholder: 'Insira a carteira de trabalho',
                onChange: (e) => setFieldValue('carteira_trabalho', mask_only_number(e.target.value)),
              }}
            />
            <InputField
              label="Título de eleitor"
              InputHTMLAttributes={{
                id: 'titulo_eleitor',
                name: 'titulo_eleitor',
                placeholder: 'Insira o titulo de eleito',
                onChange: (e) => setFieldValue('titulo_eleitor', mask_only_number(e.target.value)),
              }}
            />
            <InputField
              label="Telefone de recado"
              InputHTMLAttributes={{
                id: 'telefone_recado',
                name: 'telefone_recado',
                placeholder: 'Telefone',
                onChange: (e) => setFieldValue('telefone_recado', mask_phone(e.target.value)),
              }}
            /> */}
              {/* <div>
              <Label style={{ fontWeight: 'bold' }}>Cor ou raça</Label>
              <div className="d-flex">
                <InputField
                  label="Branco"
                  InputHTMLAttributes={{
                    id: 'Branco',
                    name: 'cor_raca',
                    type: 'radio',
                    value: 'Branco',
                    defaultChecked: values.cor_raca === 'Branco',
                  }}
                  LabelHTMLAttributes={{
                    className: "mr-3",
                  }}
                />
                <InputField
                  label="Negro"
                  InputHTMLAttributes={{
                    id: 'Negro',
                    name: 'cor_raca',
                    type: 'radio',
                    value: 'Negro',
                    defaultChecked: values.cor_raca === 'Negro',
                  }}
                  LabelHTMLAttributes={{
                    className: "mr-3",
                  }}
                />
                <InputField
                  label="Pardo"
                  InputHTMLAttributes={{
                    id: 'Pardo',
                    name: 'cor_raca',
                    type: 'radio',
                    value: 'Pardo',
                    defaultChecked: values.cor_raca === 'Pardo',
                  }}
                  LabelHTMLAttributes={{
                    className: "mr-3",
                  }}
                />
                <InputField
                  label="Indígena"
                  InputHTMLAttributes={{
                    id: 'Indígena',
                    name: 'cor_raca',
                    type: 'radio',
                    value: 'Indígena',
                    defaultChecked: values.cor_raca === 'Indígena',
                  }}
                  LabelHTMLAttributes={{
                    className: "mr-3",
                  }}
                />
                <InputField
                  label="Amarelo"
                  InputHTMLAttributes={{
                    id: 'Amarelo',
                    name: 'cor_raca',
                    type: 'radio',
                    value: 'Amarelo',
                    defaultChecked: values.cor_raca === 'Amarelo',
                  }}
                  LabelHTMLAttributes={{
                    className: "mr-3",
                  }}
                />
                <InputField
                  label="Outros"
                  InputHTMLAttributes={{
                    id: 'cor_raca_Outro',
                    name: 'cor_raca',
                    type: 'radio',
                    value: 'Outros',
                    defaultChecked: values.cor_raca === 'Outros',
                  }}
                  LabelHTMLAttributes={{
                    className: "mr-3",
                  }}
                />
              </div>
            </div> */}
              {/* <div>
              <Label style={{ fontWeight: 'bold' }}>Estado Civil</Label>
              <div className="d-flex">
                <InputField
                  label="Solteiro"
                  InputHTMLAttributes={{
                    id: 'estado_civil1',
                    name: 'estado_civil',
                    type: 'radio',
                    value: 'Solteiro',
                    defaultChecked: values.estado_civil === 'Solteiro',
                  }}
                  LabelHTMLAttributes={{
                    className: "mr-3",
                  }}
                />
                <InputField
                  label="Casado"
                  InputHTMLAttributes={{
                    id: 'estado_civil2',
                    name: 'estado_civil',
                    type: 'radio',
                    value: 'Casado',
                    defaultChecked: values.estado_civil === 'Casado',
                  }}
                  LabelHTMLAttributes={{
                    className: "mr-3",
                  }}
                />
                <InputField
                  label="Divorciado"
                  InputHTMLAttributes={{
                    id: 'estado_civil3',
                    name: 'estado_civil',
                    type: 'radio',
                    value: 'Divorciado',
                    defaultChecked: values.estado_civil === 'Divorciado',
                  }}
                  LabelHTMLAttributes={{
                    className: "mr-3",
                  }}
                />
                <InputField
                  label="Viúvo"
                  InputHTMLAttributes={{
                    id: 'estado_civil4',
                    name: 'estado_civil',
                    type: 'radio',
                    value: 'Viúvo',
                    defaultChecked: values.estado_civil === 'Viúvo',
                  }}
                  LabelHTMLAttributes={{
                    className: "mr-3",
                  }}
                />
                <InputField
                  label="Outros "
                  InputHTMLAttributes={{
                    id: 'estado_civil5',
                    name: 'estado_civil',
                    type: 'radio',
                    value: 'Outros',
                    defaultChecked: values.estado_civil === 'Outros',
                  }}
                  LabelHTMLAttributes={{
                    className: "mr-3",
                  }}
                />
              </div>
            </div> */}
              {/* <Label style={{ fontWeight: 'bold' }}>Endereço do Egresso</Label>
            <InputField
              label="CEP"
              InputHTMLAttributes={{
                id: 'cep',
                name: 'cep',
                onChange: (e) => {
                  setFieldValue('cep', mask_cep(e.target.value));
                  searchCep(e.target.value);
                },
                maxLength: 9,
                placeholder: 'Insira o cep',
              }}
              LabelHTMLAttributes={{
                style: { },
              }}
            />
            <div className="d-flex" style={{ gap: 10 }}>
              <InputField
                label="Endereço"
                InputHTMLAttributes={{
                  id: 'endereco',
                  name: 'endereco',
                  style: { width: "500px" },
                  placeholder: 'Insira o endereço',
                }}
                LabelHTMLAttributes={{
                  style: { },
                }}
              />
              <InputField
                label="Nº"
                InputHTMLAttributes={{
                  id: 'numero_endereco',
                  name: 'numero_endereco',
                  className: 'w-100',
                  placeholder: 'Nº',
                }}
                LabelHTMLAttributes={{
                  style: { },
                }}
              />
            </div>
            <div className="d-flex" style={{ gap: 10 }}>
              <InputField
                label="Bairro"
                InputHTMLAttributes={{
                  id: 'bairro',
                  name: 'bairro',
                  placeholder: 'Insira o bairro',
                }}
                LabelHTMLAttributes={{
                  style: { },
                }}
              />
              <InputField
                label="Município "
                options={municipios.map((municipio) => <option value={municipio}>{municipio}</option>)}
                InputHTMLAttributes={{
                  id: 'municipio',
                  name: 'municipio',
                  placeholder: 'Insira o município',
                  type: 'select',
                }}
                LabelHTMLAttributes={{
                  style: { },
                }}
              />
              <InputField
                label="Estado"
                options={estados.map((estado) => {
                  const entries = Object.entries(estado);
                  return <option value={entries[0][0]}>{entries[0][1]}</option>;
                })}
                InputHTMLAttributes={{
                  id: 'estado',
                  name: 'estado',
                  placeholder: 'Insira o Estado',
                  type: "select",
                }}
                LabelHTMLAttributes={{
                  style: { },
                }}
              />
            </div>
            <InputField
              label="Complemento"
              InputHTMLAttributes={{
                id: 'complemento_endereco',
                name: 'complemento_endereco',
                placeholder: 'Complemento',
              }}
              LabelHTMLAttributes={{
                style: { },
              }}
            /> */}

              <div>
                {/* <Label style={{ fontWeight: 'bold' }}>Trabalha?</Label>
              <div className="d-flex">
                <InputField
                  label="Sim"
                  InputHTMLAttributes={{
                    type: 'radio',
                    id: "trabalha_1",
                    name: 'trabalha',
                    value: 'Sim',
                    defaultChecked: values.endereco_trabalho,
                  }}
                  LabelHTMLAttributes={{
                    className: "mr-3",
                  }}
                />
                <InputField
                  label="Não"
                  InputHTMLAttributes={{
                    type: 'radio',
                    id: "trabalha_2",
                    name: 'trabalha',
                    value: 'Não',
                  }}
                />
              </div> */}
                {/* <fieldset>
                <Label style={{ fontWeight: 'bold' }}>Endereço do trabalho</Label>
                <InputField
                  label="CEP"
                  InputHTMLAttributes={{
                    id: 'cep_trabalho',
                    name: 'cep_trabalho',
                    onChange: (e) => {
                      setFieldValue('cep_trabalho', mask_cep(e.target.value));
                      searchTrabalhaCep(e.target.value);
                    },
                    maxLength: 9,
                    placeholder: 'Insira o cep',
                  }}
                  LabelHTMLAttributes={{
                    style: { },
                  }}
                />
                <div className="d-flex" style={{ gap: 10 }}>
                  <InputField
                    label="Endereço"
                    InputHTMLAttributes={{
                      id: 'endereco_trabalho',
                      name: 'endereco_trabalho',
                      style: { width: "500px" },
                      placeholder: 'Insira o endereço',
                    }}
                    LabelHTMLAttributes={{
                      style: { },
                    }}
                  />
                  <InputField
                    label="Nº"
                    InputHTMLAttributes={{
                      id: 'numero_endereco_trabalho',
                      name: 'numero_endereco_trabalho',
                      className: 'w-100',
                      placeholder: 'Nº',
                    }}
                    LabelHTMLAttributes={{
                      style: { },
                    }}
                  />
                </div>
                <div className="d-flex" style={{ gap: 10 }}>
                  <InputField
                    label="Estado"
                    InputHTMLAttributes={{
                      id: 'estado_trabalho',
                      name: 'estado_trabalho',
                      placeholder: 'Insira o Estado',
                    }}
                    LabelHTMLAttributes={{
                      style: { },
                    }}
                  />
                  <InputField
                    label="Bairro"
                    InputHTMLAttributes={{
                      id: 'bairro_trabalho',
                      name: 'bairro_trabalho',
                      placeholder: 'Insira o bairro',
                    }}
                    LabelHTMLAttributes={{
                      style: { },
                    }}
                  />
                  <InputField
                    label="Município "
                    InputHTMLAttributes={{
                      id: 'municipio_trabalho',
                      name: 'municipio_trabalho',
                      placeholder: 'Insira o município',
                    }}
                    LabelHTMLAttributes={{
                      style: { },
                    }}
                  />
                  <InputField
                    label="Estado"
                    options={estados.map((estado) => {
                      const entries = Object.entries(estado);
                      return <option value={entries[0][0]}>{entries[0][1]}</option>;
                    })}
                    InputHTMLAttributes={{
                      id: 'estado_trabalho',
                      name: 'estado_trabalho',
                      placeholder: 'Insira o Estado',
                      type: "select",
                    }}
                    LabelHTMLAttributes={{
                      style: { },
                    }}
                  />
                </div>
                <InputField
                  label="Complemento"
                  InputHTMLAttributes={{
                    id: 'complemento_endereco_trabalho',
                    name: 'complemento_endereco_trabalho',
                    placeholder: 'Complemento',
                  }}
                  LabelHTMLAttributes={{
                    style: { },
                  }}
                />
              </fieldset> */}
              </div>
              {/* <InputField
              label="Nome do pai"
              InputHTMLAttributes={{
                id: 'nome_pai',
                name: 'nome_pai',
                placeholder: 'Insira o nome do pai',
              }}
            />
            <InputField
              label="Nome do mãe*"
              InputHTMLAttributes={{
                id: 'nome_mae',
                name: 'nome_mae',
                placeholder: 'Insira o nome da mãe',
              }}
            />
            <InputField
              label="Quantidade de pessoas que compõem o núcleo familiar"
              InputHTMLAttributes={{
                id: 'quantidade_pessoa_nucleo_familiar',
                name: 'quantidade_pessoa_nucleo_familiar',
                placeholder: 'Insira a quantidade do nucleo familiar',
                onChange: (e) => setFieldValue('quantidade_pessoa_nucleo_familiar', mask_only_number(e.target.value)),
              }}
            /> */}
              {/* <div>
              <Label style={{ fontWeight: 'bold' }}>Possui filhos?</Label>
              <div className="d-flex">
                <InputField
                  label="Sim"
                  InputHTMLAttributes={{
                    id: 'possui_filhos1',
                    name: 'possui_filhos',
                    type: "radio",
                    value: "T",
                    defaultChecked: values.possui_filhos === 'T',
                  }}
                  LabelHTMLAttributes={{
                    className: 'mr-3',
                  }}
                />
                <InputField
                  label="Não"
                  InputHTMLAttributes={{
                    id: 'possui_filhos2',
                    name: 'possui_filhos',
                    type: "radio",
                    value: "F",
                    defaultChecked: values.possui_filhos === 'F',
                  }}
                />
              </div>
            </div>
            {values.possui_filhos === "T" && (
              <div>
                <InputField
                  label="Quantos?"
                  InputHTMLAttributes={{
                    id: 'quantidade_filhos',
                    name: 'quantidade_filhos',
                    placeholder: 'Insira a quantidade de filho',
                    disabled: !!(values.possui_filhos === '' || values.possui_filhos === 'F' || values.possui_filhos === null),
                    onChange: (e) => setFieldValue('quantidade_filhos', mask_only_number(e.target.value)),
                  }}
                />

                <div>
                  <Label style={{ fontWeight: 'bold' }}>Idade dos filhos</Label>
                  <div className="d-flex">
                    <InputField
                      label="0 à 6 anos"
                      InputHTMLAttributes={{
                        id: 'idade_filhos1',
                        name: 'idade_filhos',
                        type: "radio",
                        value: "0 a 6",
                        defaultChecked: values.idade_filhos === '0 a 6',
                      }}
                      LabelHTMLAttributes={{
                        className: 'mr-3',
                      }}
                    />
                    <InputField
                      label="07 à 12 anos"
                      InputHTMLAttributes={{
                        id: 'idade_filhos2',
                        name: 'idade_filhos',
                        type: "radio",
                        value: "7 a 12",
                        defaultChecked: values.idade_filhos === "7 a 12",
                      }}
                      LabelHTMLAttributes={{
                        className: 'mr-3',
                      }}
                    />
                    <InputField
                      label="13 à 17 anos"
                      InputHTMLAttributes={{
                        id: 'idade_filhos3',
                        name: 'idade_filhos',
                        type: "radio",
                        value: "13 a 17",
                        defaultChecked: values.idade_filhos === "13 a 17",
                      }}
                      LabelHTMLAttributes={{
                        className: 'mr-3',
                      }}
                    />
                    <InputField
                      label="Maiores de 18 anos"
                      InputHTMLAttributes={{
                        id: 'idade_filhos4',
                        name: 'idade_filhos',
                        type: "radio",
                        value: "maior de 18",
                        defaultChecked: values.idade_filhos === "maior de 18",
                      }}
                    />
                  </div>
                </div>
              </div>
            )} */}
            </div>
            <ModalFooter className="d-flex justify-content-between">
              <div>
                <Button type="button" size="lg" onClick={handleBackStep}>
                  Voltar
                </Button>
              </div>
              <div>
                <Button
                  style={{ borderColor: '#1B7E9F', background: '#1B7E9F' }}
                  type="submit"
                  color="primary"
                  size="lg"
                >
                  {isCreate ? 'Avançar' : 'Salvar'}
                </Button>
              </div>
            </ModalFooter>
          </Form>
        );
      }}
    </Formik>
  );
}
