import { eachYearOfInterval, format } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { Chart } from 'react-chartjs-2';
import { useLocation } from 'react-router-dom';
import { TablePadrão, Td, Tr } from '../../../../components/develop-nilton-components/TablePadrão';
import { SectionHeader } from '../../../../components/SectionHeader';
import { SpinnerLoading } from '../../../../components/SpinnerLoading';
import { SubmitResponse } from '../../../../components/SubmitResponse';
import api from '../../../../services/api';
import { TbodyPageHistorico } from './TbodyPageHistorico';

export function PageHistoricoAs() {
  const headWidth = ["5%", "50%", "30%", "30%"];
  const thead = ["#", "Nome do assistente social", "Data da consulta", "Relatório"];

  const { state } = useLocation<any>();

  return (
    <div className="w-100" data-testid="page-historico">
      <SectionHeader backPage="/acompanhamento" subtitle="Indicadores Individuais" />

      <h3 className="text-primary mt-5">
        Nome do egresso:
        {' '}
        {state.nome}
      </h3>

      <TablePadrão
        headWidth={headWidth}
        thead={thead}
      >
        {state?.acompanhamentos_sociais.concluidos.length === 0 && (
          <Tr>
            <Td width="100%">
              <div className="w-100">
                <SubmitResponse submitResponse={{
                  message: "Não há acompanhamentos.",
                  status: "danger",
                  title: "Aviso",
                }}
                />
              </div>
            </Td>
          </Tr>
        )}
        {state.acompanhamentos_sociais.concluidos.map((item:any, i:number) => (
          <TbodyPageHistorico
            egresso={state}
            headWidth={headWidth}
            index={i + 1}
            acompanhamento_concluidos={item}
          />
        ))}
      </TablePadrão>
    </div>
  );
}
