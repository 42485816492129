import React, { useEffect, useState } from 'react';
import api from '../../../../services/api';

export function useGetProjetos_por_mes_no_ano() {
  const [projetos_por_mes_no_ano, set_media_alunos_por_turma_no_ano] = useState<any>(null);

  async function handleProjetos_por_mes_no_ano(year?:string) {
    const { data } = await api.get('/reports/projetos_por_mes_no_ano', {
      params: {
        year,
      },
    });
    set_media_alunos_por_turma_no_ano(data);
  }

  useEffect(() => {
    handleProjetos_por_mes_no_ano();
  }, []);
  return { projetos_por_mes_no_ano, handleProjetos_por_mes_no_ano };
}
