import React from 'react';
import { BsPersonLinesFill } from 'react-icons/bs';
import ReactTooltip from 'react-tooltip';

type PropsIconDetails = {
  cursor?: boolean
  tooltipMessage?: string
  size?: number
}

export function IconDetails({ cursor, tooltipMessage, size }:PropsIconDetails) {
  return (
    <div data-tip={tooltipMessage || ""}>
      <BsPersonLinesFill
        size={size || 30}
        cursor={cursor ? "pointer" : undefined}
      />
    </div>
  );
}
