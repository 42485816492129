import React, { useEffect, useState } from 'react';
import { FieldArrayWithId } from 'react-hook-form';
import api from '../../../../../../services/api';
import { IProjeto, IProjetoEdit } from '../Projeto/type';

type IOptionsMaterial = {
    fields: any
}

export function OptionsMaterial({ fields } : IOptionsMaterial) {
  const [option, setOption] = useState<{id: number, title: string}[] | []>([]);

  useEffect(() => {
    api.get("materials")
      .then((res) => {
        const materiais = res.data
          .map(({ title, id }:{title: string, id: number}) => ({ title, id }));
        setOption(materiais);
      });
  }, [fields]);
  return option;
}
