import React from 'react';
import { addHours, format } from 'date-fns';
import { useHistory } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import { Badge } from '../../../../components/develop-nilton-components/Badge';
import { IconAdd } from '../../../../components/develop-nilton-components/IconAdd';
import { Td, Tr } from '../../../../components/develop-nilton-components/TablePadrão';

type PropsTbodyTable = {
  headWidth: string[]
  roundsItems: any
  index: number
}

export function TbodyTablePendente({ roundsItems, headWidth, index }:PropsTbodyTable) {
  const history = useHistory();

  function handleHistoryToPageEgressesInTheRounds() {
    history.push({ state: roundsItems, pathname: "/homologar-ronda/confirmar-egressos" });
  }

  return (
    <Tr>
      <Td width={headWidth[0]}>
        <ReactTooltip />
        {index}
      </Td>
      <Td width={headWidth[1]}>
        {roundsItems.responsavel.name}
      </Td>
      <Td width={headWidth[2]}>
        {roundsItems.title}
      </Td>
      <Td width={headWidth[3]}>
        {format(addHours(new Date(roundsItems.data_ronda), 0), "dd/MM/yyyy")}
      </Td>
      <Td width={headWidth[4]}>
        <Badge type="info">Pendente</Badge>
      </Td>
      <Td width={headWidth[5]}>
        <div onClick={handleHistoryToPageEgressesInTheRounds}>
          <IconAdd cursor tooltipMessage="Homologar ronda" color="text-primary" />
        </div>
      </Td>
    </Tr>
  );
}
