import React, { useEffect, useState } from 'react';
import api from '../../../../services/api';

type PropsOcurrences = {
  value:number
  label:string
}

export function useGetOcurrences() {
  const [ocurrences, setOcurrences] = useState<PropsOcurrences[] | null>(null);

  async function handleSetOcurrences() {
    const { data } = await api.get("/occurrences");

    const filterData = data.map((item:any) => ({ label: item.name, value: item.id }));

    setOcurrences(filterData);
  }

  useEffect(() => {
    handleSetOcurrences();
  }, []);

  return { ocurrences };
}
