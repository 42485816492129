import React, { useState } from 'react';
// @ts-ignore
import { CKEditor } from '@ckeditor/ckeditor5-react';
// @ts-ignore
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { toast } from 'react-toastify';
import api from '../../../../services/api';
import { IFatores } from '../type';
import { SpinnerLoading } from '../../../../components/SpinnerLoading';

export function Queixa({ setTab, getValues }:IFatores) {
  const [text, setText] = useState(getValues().queixas);
  const [load, setLoad] = useState(false);
  return (
    <div className="w-75">
      <CKEditor
        editor={ClassicEditor}
        data={text || ""}
        // onReady={(editor: any) => {
        //   // You can store the "editor" and use when it is needed.
        //   console.log(editor);
        // }}
        onChange={(event: any, editor: { getData: () => any; }) => {
          const data = editor.getData();
          setText(data);
        }}
        // onBlur={(event: any, editor: any) => {
        //   console.log('Blur.', editor);
        // }}
        // onFocus={(event: any, editor: any) => {
        //   console.log('Focus.', editor);
        // }}
      />
      <div className="d-flex justify-content-between mt-5 mb-5">
        <button className="btn btn-primary btn-lg" onClick={() => setTab(2)} type="button">Voltar</button>

        {load ? (
          <button
            className="btn btn-primary btn-lg"
          >
            <SpinnerLoading />
          </button>
        ) : (
          <button
            className="btn btn-primary btn-lg"
            onClick={async () => {
              setLoad(true);
              try {
                if (text !== "") {
                  await api.put(`/social/${getValues().id}`, { queixas: text });
                }
                setTab(4);
                window.scrollTo({ top: 0 });
              } catch (error) {
              // @ts-ignore
                toast.error(error.response.data.message);
              }
              setLoad(false);
            }}
            data-testid="next-4"
          >
            Avançar
          </button>
        )}
      </div>
    </div>
  );
}
