import React, { useState } from 'react';
import ReactModal from 'react-modal';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { useAuth, usePermission } from '../../hooks/auth';

export function SelecionarPerfil() {
  const permission = usePermission();
  const { setProfile, signOut } = useAuth();
  const [isOpen, setIsOpen] = useState(true);
  const history = useHistory();
  const { user, profile } = useAuth();
  return (
    <ReactModal
      isOpen={isOpen}
      ariaHideApp={false}
      style={{
        content: {
          width: "320px",
          overflow: 'auto',
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          marginRight: '-50%',
          transform: 'translate(-50%, -50%)',
          borderRadius: '.6rem',
        },
        overlay: {
          background: 'white',
          overflow: user?.profile.title === 'MASTER' ? (profile ? 'none' : 'auto') : 'auto',
        },
      }}
    >
      <h1 className="pb-2 mb-3 h3 border-bottom border-primary">Selecione o perfil</h1>
      <div className="d-flex justify-content-center flex-column">
        {
          // @ts-ignore
          permission?.perfis.map((perfil, i) => (
            <Button
              key={i}
              onClick={() => {
                setProfile?.(perfil);
                history.push('/');
                setIsOpen(false);
              }}
              type="button"
            >
              {perfil.title}
            </Button>
          ))
}
        <ButtonBack
          type="button"
          onClick={signOut}
        >
          Sair

        </ButtonBack>
      </div>
    </ReactModal>
  );
}

const Button = styled.button`
  background-color: white;
  cursor: pointer;
  border: none;
  border-radius: .3rem;
  padding: .5rem;
  :hover {
    background-color: #E5E5E5;
  }
`;
const ButtonBack = styled.button`
  background-color: white;
  color: #007BFF;
  cursor: pointer;
  border: none;
  border-radius: .3rem;
  padding: .5rem;
  :hover {
    background-color: #E5E5E5;
  }
`;
