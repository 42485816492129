import React, { useState, useEffect } from 'react';
import {
  Chart as ChartJS, ArcElement, Tooltip, Legend,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  PointElement,
  LineElement,
} from 'chart.js';
import { Pie, Bar, Line } from 'react-chartjs-2';

import styled from 'styled-components';
import {
  Subtitle,
  Button, WrapperButton,
} from "../../Indicadores/styles";

import Period from '../Modal/Period';
import api from '../../../../services/api';

ChartJS.register(
  ArcElement,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
);

type IChartProps = {
  tab: 'mensal' | 'trimestral';
  report?(): void
}

type IPeriod = {
  month: string;
  trimestre: undefined | string;
  year: string;
}

function Chart({ report, tab }: IChartProps) {
  const [modalIsOpen, setIsOpen] = useState(false);
  const [modalIsOpen2, setIsOpen2] = useState(false);
  const [modalIsOpen3, setIsOpen3] = useState(false);
  const [modalIsOpen4, setIsOpen4] = useState(false);
  // const [reportIsOpen, setReportIsOpen] = useState(report);

  const [periodo, setPeriodo] = useState<IPeriod>({
    month: (new Date().getMonth() + 1).toString().padStart(2, '0'),
    trimestre: undefined,
    year: new Date().getFullYear().toString(),
  });

  const [periodo2, setPeriodo2] = useState<IPeriod>({
    month: (new Date().getMonth() + 1).toString().padStart(2, '0'),
    trimestre: undefined,
    year: new Date().getFullYear().toString(),
  });

  const [periodo3, setPeriodo3] = useState<IPeriod>({
    month: (new Date().getMonth() + 1).toString().padStart(2, '0'),
    trimestre: undefined,
    year: new Date().getFullYear().toString(),
  });

  const [periodo4, setPeriodo4] = useState<IPeriod>({
    month: (new Date().getMonth() + 1).toString().padStart(2, '0'),
    trimestre: undefined,
    year: new Date().getFullYear().toString(),
  });

  useEffect(() => {
    if (tab === 'mensal') {
      setPeriodo({
        month: (new Date().getMonth() + 1).toString().padStart(2, '0'),
        trimestre: undefined,
        year: new Date().getFullYear().toString(),
      });
      setPeriodo2({
        month: (new Date().getMonth() + 1).toString().padStart(2, '0'),
        trimestre: undefined,
        year: new Date().getFullYear().toString(),
      });
      setPeriodo3({
        month: (new Date().getMonth() + 1).toString().padStart(2, '0'),
        trimestre: undefined,
        year: new Date().getFullYear().toString(),
      });
      setPeriodo4({
        month: (new Date().getMonth() + 1).toString().padStart(2, '0'),
        trimestre: undefined,
        year: new Date().getFullYear().toString(),
      });
    } else {
      setPeriodo({
        month: (new Date().getMonth() + 1).toString().padStart(2, '0'),
        trimestre: '01-03',
        year: new Date().getFullYear().toString(),
      });
      setPeriodo2({
        month: (new Date().getMonth() + 1).toString().padStart(2, '0'),
        trimestre: '01-03',
        year: new Date().getFullYear().toString(),
      });
      setPeriodo3({
        month: (new Date().getMonth() + 1).toString().padStart(2, '0'),
        trimestre: '01-03',
        year: new Date().getFullYear().toString(),
      });
      setPeriodo4({
        month: (new Date().getMonth() + 1).toString().padStart(2, '0'),
        trimestre: '01-03',
        year: new Date().getFullYear().toString(),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tab]);

  const [data_pie_license, setData_pie_license] = useState({
    labels: [
      "Cumprimento de Alvará",
      "Alvarás",
    ],
    datasets: [
      {
        label: "Quadro geral",
        data: [
          0,
          0,
        ],
        backgroundColor: [
          "#FFA500",
          "#DF362D",
        ],
        borderColor: [
          "#FFA500",
          "#DF362D",
        ],
        borderWidth: 1,
      },
    ],
  });

  const [data_pie_pad, setData_pie_pad] = useState({
    labels: [
      "Execução de PADs",
      "PADs",
    ],
    datasets: [
      {
        label: "Quadro geral",
        data: [
          66.66666666666667,
          33.33333333333333,
        ],
        backgroundColor: [
          "#FFA500",
          "#DF362D",
        ],
        borderColor: [
          "#FFA500",
          "#DF362D",
        ],
        borderWidth: 1,
      },
    ],
  });

  const [data_pie_round, setData_pie_round] = useState({
    labels: [
      "Homologação de Rondas ",
      "Rondas",
    ],
    datasets: [
      {
        label: "Quadro geral",
        data: [
          100,
          0,
        ],
        backgroundColor: [
          "#FFA500",
          "#DF362D",
        ],
        borderColor: [
          "#FFA500",
          "#DF362D",
        ],
        borderWidth: 1,
      },
    ],
  });

  const [data_bar, setData_bar] = useState({
    labels: [
      "Com problemas",
      "Sem problemas",
      "Total",
    ],
    datasets: [
      {
        label: "",
        data: [
          0,
          0,
          0,
        ],
        borderColor: "#FFA500",
        backgroundColor: "#FFA500",
      },
    ],
  });

  const [data_pie, setData_pie] = useState({
    labels: ['Não encaminhados', 'Encaminhados'],
    datasets: [
      {
        label: 'Quadro geral',
        data: [0, 0],
        backgroundColor: [
          '#FFA500',
          '#DF362D',
        ],
        borderColor: [
          '#FFA500',
          '#DF362D',
        ],
        borderWidth: 1,
      },
    ],
  });

  const [data_line, setData_line] = useState({
    labels: ['Semana 1', 'Semana 2', 'Semana 3', 'Semana 4'],
    datasets: [
      {
        label: 'Cumpridos',
        data: [0, 0, 0, 0],
        borderColor: '#FFA500',
        backgroundColor: '#FFA500',
      },
      {
        label: 'Registrados (SEUU)',
        data: [0, 0, 0, 0],
        borderColor: '#080',
        backgroundColor: '#080',
      },
      {
        label: 'Com arquivos',
        data: [0, 0, 0, 0],
        borderColor: '#DF362D',
        backgroundColor: '#DF362D',
      },
      {
        label: 'Com observações',
        data: [0, 0, 0, 0],
        borderColor: 'rgba(53, 162, 235, 0.5)',
        backgroundColor: 'rgba(53, 162, 235, 0.5)',
      },
    ],
  });

  function closeModal() {
    setIsOpen(false);
  }

  function closeModal2() {
    setIsOpen2(false);
  }

  function closeModal3() {
    setIsOpen3(false);
  }

  function closeModal4() {
    setIsOpen4(false);
  }

  // function closeAndOpenRelatorio() {
  //   setReportIsOpen(!reportIsOpen);
  // }

  useEffect(() => {
    api.get('/reports/all', {
      params: periodo,
    }).then(({ data }) => {
      setData_pie_license(data.licenses);
      setData_pie_pad(data.pads);
      setData_pie_round(data.rounds);
    });
  }, [periodo]);

  useEffect(() => {
    api.get('/reports/round', {
      params: periodo2,
    }).then(({ data }) => {
      setData_bar(data);
    });
  }, [periodo2]);

  useEffect(() => {
    api.get('/reports/pad', {
      params: periodo3,
    }).then(({ data }) => {
      setData_pie(data);
    });
  }, [periodo3]);

  useEffect(() => {
    api.get('/reports/license', {
      params: periodo4,
    }).then(({ data }) => {
      setData_line(data);
    });
  }, [periodo4]);

  const options_bar = {
    indexAxis: 'y' as const,
    elements: {
      bar: {
        borderWidth: 2,
      },
    },
    responsive: true,
    plugins: {
      legend: {
        position: 'right' as const,
      },
      title: {
        display: true,
        text: 'Fiscalizações do mês atual',
      },
    },
  };

  const options_line = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top' as const,
      },
      title: {
        display: true,
        text: 'Cumprimento de alvará do mês atual',
      },
    },
    scales: {
      y: {
        suggestedMax: 50,
        suggestedMin: 0,
      },
    },
  };

  const options_pie = {
    plugins: {
      tooltip: {
        callbacks: {
          label(context: any) {
            return `${context.raw}%`;
          },
        },
      },
    },
  };

  function formatString(value: any) {
    return value === '01-03' ? 'Primeiro Trimestre' : value === '04-06' ? 'Segundo Trimestre' : value == '07-09' ? "Terceiro Trimestre" : 'Quarto Trimestre';
  }

  return (
    <>
      <div>
        <div style={{ display: 'flex', marginTop: 10 }}>
          <WrapperTitleChart>
            {`Quadro geral - ${tab === 'mensal' ? periodo.month : formatString(periodo.trimestre)}/${periodo.year}`}
          </WrapperTitleChart>
          <WrapperPeriodChart
            onClick={() => setIsOpen(true)}
          >
            (ver outro período)
          </WrapperPeriodChart>
        </div>

        <div style={{
          width: '100%',
          borderStyle: 'solid',
          borderWidth: 0.5,
          borderColor: '#595959',
          marginBottom: 20,
        }}
        />

        <div style={{
          display: "flex", width: '100%', flexDirection: "row", justifyContent: 'space-around',
        }}
        >
          <div style={{ width: '30%', height: '30%' }}>
            <Pie
              options={options_pie}
              data={data_pie_license}
            />
          </div>
          <div style={{ width: '30%', height: '30%' }}>
            <Pie options={options_pie} data={data_pie_pad} />
          </div>
          <div style={{ width: '30%', height: '30%' }}>
            <Pie options={options_pie} data={data_pie_round} />
          </div>
        </div>
      </div>

      <div>
        <div style={{ display: 'flex', marginTop: 10 }}>
          <WrapperTitleChart>{`Fiscalizações - ${tab === 'mensal' ? periodo2.month : formatString(periodo2.trimestre)}/${periodo2.year}`}</WrapperTitleChart>
          <WrapperPeriodChart>
            <span onClick={() => setIsOpen2(true)}>(ver outro período)</span>
            {' '}
            <span onClick={report}>(visualizar detalhes)</span>
          </WrapperPeriodChart>
        </div>

        <div style={{
          width: '100%',
          borderStyle: 'solid',
          borderWidth: 0.5,
          borderColor: '#595959',
          marginBottom: 10,
        }}
        />

        {/* <h1 style={{ fontSize: 20 }}>Clique no tipo de ocorrência para ver o detalhe</h1> */}

        <div style={{
          display: "flex", flexDirection: "column", alignItems: "center", justifyContent: 'center',
        }}
        >
          <div style={{ width: '100%', height: '100%' }}>
            <Bar options={options_bar} data={data_bar} />
          </div>
        </div>
      </div>

      <div>
        <div style={{ display: 'flex', marginTop: 10 }}>
          <WrapperTitleChart>{`Notificações PAD - ${tab === 'mensal' ? periodo3.month : formatString(periodo3.trimestre)}/${periodo3.year}`}</WrapperTitleChart>
          <WrapperPeriodChart
            onClick={() => setIsOpen3(true)}
          >
            (ver outro período)
          </WrapperPeriodChart>
        </div>

        <div style={{
          width: '100%',
          borderStyle: 'solid',
          borderWidth: 0.5,
          borderColor: '#595959',
          marginBottom: 10,
        }}
        />

        {/* <h1 style={{ fontSize: 20 }}>Clique no tipo de ocorrência para ver o detalhe</h1> */}

        <div style={{
          display: "flex", flexDirection: "column", alignItems: "center", justifyContent: 'center',
        }}
        >
          <div style={{ width: '50%', height: '50%' }}>
            <Pie options={options_pie} data={data_pie} />
          </div>
        </div>
      </div>

      <div>
        <div style={{ display: 'flex', marginTop: 10 }}>
          <WrapperTitleChart>{`Cumprimento de Alvará - ${tab === 'mensal' ? periodo4.month : formatString(periodo4.trimestre)}/${periodo4.year}`}</WrapperTitleChart>
          <WrapperPeriodChart
            onClick={() => setIsOpen4(true)}
          >
            (ver outro período)
          </WrapperPeriodChart>
        </div>

        <div style={{
          width: '100%',
          borderStyle: 'solid',
          borderWidth: 0.5,
          borderColor: '#595959',
          marginBottom: 20,
        }}
        />

        <div style={{
          display: "flex", flexDirection: "column", alignItems: "center", justifyContent: 'center',
        }}
        >
          <div style={{ width: '100%', height: '100%' }}>
            <Line options={options_line} data={data_line} />
          </div>
        </div>
      </div>

      <Period tab={tab} modalIsOpen={modalIsOpen} closeModal={closeModal} periodo={periodo} setPeriodo={setPeriodo} />
      <Period tab={tab} modalIsOpen={modalIsOpen2} closeModal={closeModal2} periodo={periodo2} setPeriodo={setPeriodo2} />
      <Period tab={tab} modalIsOpen={modalIsOpen3} closeModal={closeModal3} periodo={periodo3} setPeriodo={setPeriodo3} />
      <Period tab={tab} modalIsOpen={modalIsOpen4} closeModal={closeModal4} periodo={periodo4} setPeriodo={setPeriodo4} />

      <WrapperButton style={{ marginTop: 40 }}>
        <Button backgroundr="#FFAC0C" color="white" fontSize="20px" onClick={() => window.print()}>Imprimir</Button>
      </WrapperButton>
    </>
  );
}

export default Chart;

const WrapperTitleChart = styled.h1`
  font-size: 26px;

  @media (max-width: 768px) {
    font-size: 14px;
  }
`;

const WrapperPeriodChart = styled.h1`
  font-size: 26px;
  margin-left: 10px;
  color: #007BFF;
  cursor: pointer;

  @media (max-width: 768px) {
    font-size: 14px;
  }
`;
