import React, { useState } from 'react';
import { Chart } from 'react-chartjs-2';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { ArrayFormValues, FormAcompanhamentos } from '../../../../components/develop-nilton-components/FormAcompanhamentos';
import { SpinnerLoading } from '../../../../components/SpinnerLoading';

import api from '../../../../services/api';
import { IFatores } from '../type';

export function FatoresFis({
  getValues, setValue, setTab,
}: IFatores) {
  const arrayFormValues:ArrayFormValues["formValues"] = [
    {
      label: "1. Já fez um tratamento específico?",
      type: "boolean",
      name: "tratamento_especifico",
      especificacao: "especificacao_tratamento_especifico",
      onChange: (e:any) => {
        setValue('tratamento_especifico', e.target.value);
        set_tratamento_especifico(e.target.value);
      },
      onChangeEspecificacao: (e:any) => {
        setValue("especificacao_tratamento_especifico", e.target.value);
      },
    },
    {
      label: "2. Toma algum medicamento no horário da aula?",
      type: "boolean",
      name: "toma_medicamento_em_aula",
      especificacao: "especificacao_toma_medicamento_em_aula",
      useComma: true,
      onChange: (e:any) => {
        setValue('toma_medicamento_em_aula', e.target.value);
        set_toma_medicamento_em_aula(e.target.value);
      },
      onChangeEspecificacao: (e) => {
        setValue('especificacao_toma_medicamento_em_aula', e.target.value);
      },
    },
    {
      label: "3. Aproxima muito os olhos para fazer leitura? ",
      type: "boolean",
      name: "aproxima_olhos_para_leitura",
      onChange: (e:any) => {
        setValue('aproxima_olhos_para_leitura', e.target.value);
      },
    },
    {
      label: "4. Usa óculos? ",
      type: "boolean",
      name: "usa_oculos",
      onChange: (e:any) => {
        setValue('usa_oculos', e.target.value);
      },
    },
  ];

  const [load, setLoad] = useState(false);
  const onSubmit = async (data:any) => {
    setLoad(true);
    try {
      await api.put(`/psicopedagogue/${getValues().id}`, data);
      setTab(4);
      window.scrollTo({ top: 0 });
    } catch (error) {
      // @ts-ignore
      toast.error(error.response.data.message);
    }
    setLoad(false);
  };

  const onSubmitError = (errors:any) => {
    const error = Object.keys(errors);
    for (let i = 0; i < error.length; i + 1) {
      toast.error(errors[error[i] as keyof any]?.message);
      break;
    }
  };

  const [tratamento_especifico, set_tratamento_especifico] = useState(getValues('tratamento_especifico'));
  const [toma_medicamento_em_aula, set_toma_medicamento_em_aula] = useState(getValues('toma_medicamento_em_aula'));

  const { register, handleSubmit } = useForm({ defaultValues: { ...getValues(), tratamento_especifico } });

  return (
    <div>

      <form onSubmit={handleSubmit(onSubmit, onSubmitError)}>
        <div>
          <FormAcompanhamentos
            formValues={arrayFormValues}
            register={register}
            keysEspecificacao={{
              tratamento_especifico,
              toma_medicamento_em_aula,
            }}
          />
        </div>
        <div className="d-flex justify-content-between mt-5 mb-5 w-75">
          <button className="btn btn-primary btn-lg" onClick={() => setTab(2)} type="button">Voltar</button>

          {load ? (
            <button
              className="btn btn-primary btn-lg"
              type="button"
            >
              <SpinnerLoading />
            </button>
          ) : (
            <button
              className="btn btn-primary btn-lg"
              type="submit"
              data-testid="next-3"
            >
              Avançar
            </button>
          )}
        </div>
      </form>
    </div>
  );
}
