import React from 'react';
import { FormState, UseFormRegister } from 'react-hook-form';
import { Errors } from './Erro';

type INameField = {
    formState: FormState<any>
    options: {value: string, label: string }[]
    register: UseFormRegister<any>
    name: string
}

export function SelectLaboratorio({
  options, formState, register, name,
}:INameField) {
  return (
    <>
      <label htmlFor="">Laboratório</label>
      <div className="flex-column">
        <select
          className="form-control"
          {...register(name)}
          required
        >
          <option value="">Selecione</option>
          {options.map((item, i) => (
            <option value={item.value} key={i}>{item.label}</option>
          ))}
        </select>
        {formState.errors.laboratorio && (
        <Errors />
        )}
      </div>
    </>
  );
}
