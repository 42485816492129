import React, { useEffect, useState } from 'react';
import ReactTooltip from 'react-tooltip';
import { IconAdd } from '../../../../components/develop-nilton-components/IconAdd';
import { IconDetails } from '../../../../components/develop-nilton-components/IconDetails';
import { ModalDetailsEgresse } from '../../../../components/develop-nilton-components/ModalDetailsEgresses';
import { Td, Tr } from '../../../../components/develop-nilton-components/TablePadrão';
import { Tbody } from '../types';

export function TbodyGeral({
  egress, handleUpdate, headWidth, index, addEgresses,
}: Tbody) {
  const [isOpenDetails, setIsOpenDetails] = useState(false);

  function handleIsOpenDetails() {
    setIsOpenDetails(!isOpenDetails);
  }

  return (
    <Tr>
      <Td width={headWidth[0]}>{index}</Td>
      <Td width={headWidth[1]}>{egress?.nome}</Td>
      <Td width={headWidth[2]}>
        <div onClick={handleIsOpenDetails}>
          <IconDetails cursor />
        </div>
        <ModalDetailsEgresse
          isOpen={isOpenDetails}
          egresse={egress}
          handleIsOpen={handleIsOpenDetails}
        />
      </Td>
      <Td width={headWidth[2]}>
        {
        // @ts-ignore
        (egress.cursos.em_andamento.length === 0 || egress.cursos.pendentes.length === 0) ? (
          <div onClick={() => {
            if (egress?.id) {
              addEgresses(egress?.id);
            }
          }}
          >
            <IconAdd color="text-primary" cursor />
          </div>
        ) : (
          <div>
            <IconAdd tooltipMessage="Egresso já está em uma turma" />
            <ReactTooltip />
          </div>
        )
        }
      </Td>
    </Tr>
  );
}
