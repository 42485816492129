import React from 'react';
import moment from 'moment';
import { IEgresses } from '../../../../../types/IEgresses';

type EgressesFormModal = {
  useStateEgresse: [IEgresses | null, (egresse:IEgresses | null) => void]
}

export default function Form3({ useStateEgresse }: EgressesFormModal) {
  const [egresse, setEgresse] = useStateEgresse;

  return (
    <>
      <div style={{
        display: "flex", marginTop: 20, justifyContent: 'space-between', flexDirection: "column", width: '100%', background: '#f6f6f6',
      }}
      >
        <div style={{ display: "flex", alignItems: 'center', justifyContent: "space-between" }}>
          <h1 style={{
            margin: 10, fontSize: 16, fontWeight: 'bold',
          }}
          >
            Dados Pessoais
          </h1>
        </div>

        <div style={{
          width: '100%', borderBottom: 2, borderBottomStyle: 'solid', borderBottomColor: '#1B7E9F',
        }}
        />
      </div>

      <div style={{ display: "flex" }}>
        <div style={{ width: '50%' }}>
          <div style={{
            marginTop: 20, display: 'flex', alignItems: "center",
          }}
          >
            <h1 style={{ fontSize: 14 }}>CPF:</h1>
            <h2 style={{ fontSize: 14, fontWeight: "normal", marginLeft: 5 }}>{egresse?.cpf}</h2>
          </div>
          <div style={{
            display: 'flex', alignItems: "center",
          }}
          >
            <h1 style={{ fontSize: 14 }}>RG:</h1>
            <h2 style={{ fontSize: 14, fontWeight: "normal", marginLeft: 5 }}>{egresse?.rg}</h2>
          </div>
          <div style={{
            display: 'flex', alignItems: "center",
          }}
          >
            <h1 style={{ fontSize: 14 }}>Nacionalidade:</h1>
            <h2 style={{ fontSize: 14, fontWeight: "normal", marginLeft: 5 }}>{egresse?.nacionalidade}</h2>
          </div>
          <div style={{
            display: 'flex', alignItems: "center",
          }}
          >
            <h1 style={{ fontSize: 14 }}>Naturalidade:</h1>
            <h2 style={{ fontSize: 14, fontWeight: "normal", marginLeft: 5 }}>{egresse?.naturalidade}</h2>
          </div>
          <div style={{
            display: 'flex', alignItems: "center",
          }}
          >
            <h1 style={{ fontSize: 14 }}>Carteira de Trabalho:</h1>
            <h2 style={{ fontSize: 14, fontWeight: "normal", marginLeft: 5 }}>{egresse?.carteira_trabalho}</h2>
          </div>
          <div style={{
            display: 'flex', alignItems: "center",
          }}
          >
            <h1 style={{ fontSize: 14 }}>Título de Eleitor:</h1>
            <h2 style={{ fontSize: 14, fontWeight: "normal", marginLeft: 5 }}>{egresse?.titulo_eleitor}</h2>
          </div>
          <div style={{
            display: 'flex', alignItems: "center",
          }}
          >
            <h1 style={{ fontSize: 14 }}>Sexo:</h1>
            <h2 style={{ fontSize: 14, fontWeight: "normal", marginLeft: 5 }}>{egresse?.sexo ? egresse?.sexo == 'F' ? 'Feminino' : 'Masculino' : 'Não informado'}</h2>
          </div>
          <div style={{
            display: 'flex', alignItems: "center",
          }}
          >
            <h1 style={{ fontSize: 14 }}>Nome Social:</h1>
            <h2 style={{ fontSize: 14, fontWeight: "normal", marginLeft: 5 }}>{egresse?.nome_social}</h2>
          </div>
        </div>
        <div style={{ width: '50%' }}>
          <div>
            <div style={{
              marginTop: 20, display: 'flex', alignItems: "center",
            }}
            >
              <h1 style={{ fontSize: 14 }}>Estado Civil:</h1>
              <h2 style={{ fontSize: 14, fontWeight: "normal", marginLeft: 5 }}>{egresse?.estado_civil}</h2>
            </div>
          </div>
          <div style={{
            display: 'flex', alignItems: "center",
          }}
          >
            <h1 style={{ fontSize: 14 }}>Etnia:</h1>
            <h2 style={{ fontSize: 14, fontWeight: "normal", marginLeft: 5 }}>{egresse?.cor_raca}</h2>
          </div>
          <div style={{
            display: 'flex', alignItems: "center",
          }}
          >
            <h1 style={{ fontSize: 14 }}>Data de Nascimento:</h1>
            <h2 style={{ fontSize: 14, fontWeight: "normal", marginLeft: 5 }}>{moment(egresse?.data_nascimento).format('DD/MM/YYYY')}</h2>
          </div>
          <div style={{
            display: 'flex', alignItems: "center",
          }}
          >
            <h1 style={{ fontSize: 14 }}>E-mail:</h1>
            <h2 style={{ fontSize: 14, fontWeight: "normal", marginLeft: 5 }}>{egresse?.email}</h2>
          </div>
          <div style={{
            display: 'flex', alignItems: "center",
          }}
          >
            <h1 style={{ fontSize: 14 }}>Telefone (Recardo):</h1>
            <h2 style={{ fontSize: 14, fontWeight: "normal", marginLeft: 5 }}>{egresse?.telefone_recado}</h2>
          </div>
          <div style={{
            display: 'flex', alignItems: "center",
          }}
          >
            <h1 style={{ fontSize: 14 }}>Residência Fixa:</h1>
            <h2 style={{ fontSize: 14, fontWeight: "normal", marginLeft: 5 }}>{egresse?.possui_residencia_fixa ? egresse?.possui_residencia_fixa == 'T' ? 'Sim' : 'Não' : 'Não informado'}</h2>
          </div>
          <div style={{
            display: 'flex', alignItems: "center",
          }}
          >
            <h1 style={{ fontSize: 14 }}>Escolaridade:</h1>
            <h2 style={{ fontSize: 14, fontWeight: "normal", marginLeft: 5 }}>{egresse?.escolaridade}</h2>
          </div>
          <div style={{
            display: 'flex', alignItems: "center",
          }}
          >
            <h1 style={{ fontSize: 14 }}>Alcunha:</h1>
            <h2 style={{ fontSize: 14, fontWeight: "normal", marginLeft: 5 }}>{egresse?.apelido}</h2>
          </div>
        </div>
      </div>

      <div style={{
        display: "flex", marginTop: 20, justifyContent: 'space-between', flexDirection: "column", width: '100%', background: '#f6f6f6',
      }}
      >
        <div style={{ display: "flex", alignItems: 'center', justifyContent: "space-between" }}>
          <h1 style={{
            margin: 10, fontSize: 16, fontWeight: 'bold',
          }}
          >
            Dados Processuais
          </h1>
        </div>

        <div style={{
          width: '100%', borderBottom: 2, borderBottomStyle: 'solid', borderBottomColor: '#1B7E9F',
        }}
        />
      </div>

      <div style={{ display: "flex" }}>
        <div style={{ width: '50%' }}>
          <div style={{
            marginTop: 20, display: 'flex', alignItems: "center",
          }}
          >
            <h1 style={{ fontSize: 14 }}>Cadastro (CALBE):</h1>
            <h2 style={{ fontSize: 14, fontWeight: "normal", marginLeft: 5 }}>{egresse?.cadastro_calb}</h2>
          </div>
          <div style={{
            display: 'flex', alignItems: "center",
          }}
          >
            <h1 style={{ fontSize: 14 }}>Matrícula (IAPEN):</h1>
            <h2 style={{ fontSize: 14, fontWeight: "normal", marginLeft: 5 }}>{egresse?.numero_cadastro}</h2>
          </div>
          <div style={{
            display: 'flex', alignItems: "center",
          }}
          >
            <h1 style={{ fontSize: 14 }}>Tipo de Regime:</h1>
            <h2 style={{ fontSize: 14, fontWeight: "normal", marginLeft: 5 }}>{egresse?.tipo_regime ? egresse?.tipo_regime : 'Não informado'}</h2>
          </div>
        </div>
        <div style={{ width: '50%' }}>
          <div style={{
            marginTop: 20, display: 'flex', alignItems: "center",
          }}
          >
            <h1 style={{ fontSize: 14 }}>Alvará de Soltura:</h1>
            <h2 style={{ fontSize: 14, fontWeight: "normal", marginLeft: 5 }}>{egresse?.data_alvara_soltura ? moment(egresse?.data_alvara_soltura).format('DD/MM/YYYY') : 'Não informado'}</h2>
          </div>
          {egresse?.numero_processo1?.split(',').map((item: string) => (
            <div style={{
              display: 'flex', alignItems: "center",
            }}
            >
              <h1 style={{ fontSize: 14 }}>Processo:</h1>
              <h2 style={{ fontSize: 14, fontWeight: "normal", marginLeft: 5 }}>{item}</h2>
            </div>
          ))}
        </div>
      </div>

      <div style={{
        display: "flex", marginTop: 20, justifyContent: 'space-between', flexDirection: "column", width: '100%', background: '#f6f6f6',
      }}
      >
        <div style={{ display: "flex", alignItems: 'center', justifyContent: "space-between" }}>
          <h1 style={{
            margin: 10, fontSize: 16, fontWeight: 'bold',
          }}
          >
            Dados Familiares
          </h1>
        </div>

        <div style={{
          width: '100%', borderBottom: 2, borderBottomStyle: 'solid', borderBottomColor: '#1B7E9F',
        }}
        />
      </div>

      <div style={{ display: "flex" }}>
        <div style={{ width: '50%' }}>
          <div style={{
            marginTop: 20, display: 'flex', alignItems: "center",
          }}
          >
            <h1 style={{ fontSize: 14 }}>Nome da Mãe:</h1>
            <h2 style={{ fontSize: 14, fontWeight: "normal", marginLeft: 5 }}>{egresse?.nome_mae}</h2>
          </div>
          <div style={{
            display: 'flex', alignItems: "center",
          }}
          >
            <h1 style={{ fontSize: 14 }}>Nome do Pai:</h1>
            <h2 style={{ fontSize: 14, fontWeight: "normal", marginLeft: 5 }}>{egresse?.nome_pai}</h2>
          </div>
          <div style={{
            display: 'flex', alignItems: "center",
          }}
          >
            <h1 style={{ fontSize: 14 }}>Núcleo Familiar:</h1>
            <h2 style={{ fontSize: 14, fontWeight: "normal", marginLeft: 5 }}>{egresse?.quantidade_pessoa_nucleo_familiar}</h2>
          </div>
        </div>
        <div style={{ width: '50%' }}>
          <div style={{
            marginTop: 20, display: 'flex', alignItems: "center",
          }}
          >
            <h1 style={{ fontSize: 14 }}>Possui Filho(s):</h1>
            <h2 style={{ fontSize: 14, fontWeight: "normal", marginLeft: 5 }}>{egresse?.possui_filhos ? egresse?.possui_filhos == 'T' ? 'Sim' : "Não" : "Não informado"}</h2>
          </div>
          <div style={{
            display: 'flex', alignItems: "center",
          }}
          >
            <h1 style={{ fontSize: 14 }}>Quantidade de Filho(s):</h1>
            <h2 style={{ fontSize: 14, fontWeight: "normal", marginLeft: 5 }}>{egresse?.quantidade_filhos}</h2>
          </div>
          <div style={{
            display: 'flex', alignItems: "center",
          }}
          >
            <h1 style={{ fontSize: 14 }}>Faixa Etária do(s) Filho(s):</h1>
            <h2 style={{ fontSize: 14, fontWeight: "normal", marginLeft: 5 }}>{egresse?.idade_filhos ? egresse?.idade_filhos == '0 a 6' ? '0 à 6 anos' : egresse?.idade_filhos == '7 a 12' ? '7 à 12 anos' : egresse?.idade_filhos == '13 a 17' ? '13 à 17 anos' : 'Maior que 18 anos' : 'Não informado'}</h2>
          </div>
        </div>
      </div>

      <div style={{
        display: "flex", marginTop: 20, justifyContent: 'space-between', flexDirection: "column", width: '100%', background: '#f6f6f6',
      }}
      >
        <div style={{ display: "flex", alignItems: 'center', justifyContent: "space-between" }}>
          <h1 style={{
            margin: 10, fontSize: 16, fontWeight: 'bold',
          }}
          >
            Endereço
          </h1>
        </div>

        <div style={{
          width: '100%', borderBottom: 2, borderBottomStyle: 'solid', borderBottomColor: '#1B7E9F',
        }}
        />
      </div>

      <div>
        {egresse?.endereco?.map((item: any) => {
          const entries = Object.entries(item);
          console.log(entries);

          return (
            <div style={{ display: "flex" }}>
              <div style={{ width: '50%' }}>
                {entries.map(([key, val]) => {
                  if (key == 'nome_endereco') {
                    return (
                      <div style={{
                        marginTop: 20, display: 'flex', alignItems: "center",
                      }}
                      >
                        <h1 style={{ fontSize: 14 }}>Nome:</h1>
                        <h2 style={{ fontSize: 14, fontWeight: "normal", marginLeft: 5 }}>{item?.nome_endereco}</h2>
                      </div>
                    );
                  }
                  if (key == 'endereco') {
                    return (
                      <div style={{
                        display: 'flex', alignItems: "center",
                      }}
                      >
                        <h1 style={{ fontSize: 14 }}>Endereço:</h1>
                        <h2 style={{ fontSize: 14, fontWeight: "normal", marginLeft: 5 }}>{item?.endereco}</h2>
                      </div>
                    );
                  }
                  if (key == 'bairro') {
                    return (
                      <div style={{
                        display: 'flex', alignItems: "center",
                      }}
                      >
                        <h1 style={{ fontSize: 14 }}>Bairro:</h1>
                        <h2 style={{ fontSize: 14, fontWeight: "normal", marginLeft: 5 }}>{item?.bairro}</h2>
                      </div>
                    );
                  }
                })}
              </div>
              <div style={{ width: '50%' }}>
                {entries.map(([key, val]) => {
                  if (key == 'cep') {
                    return (
                      <div style={{
                        display: 'flex', alignItems: "center",
                      }}
                      >
                        <h1 style={{ fontSize: 14 }}>CEP:</h1>
                        <h2 style={{ fontSize: 14, fontWeight: "normal", marginLeft: 5 }}>{item?.cep}</h2>
                      </div>
                    );
                  }
                  if (key == 'municipio') {
                    return (
                      <div style={{
                        marginTop: 20, display: 'flex', alignItems: "center",
                      }}
                      >
                        <h1 style={{ fontSize: 14 }}>Município:</h1>
                        <h2 style={{ fontSize: 14, fontWeight: "normal", marginLeft: 5 }}>{item?.municipio}</h2>
                      </div>
                    );
                  }
                  if (key == 'numero_endereco') {
                    return (
                      <div style={{
                        display: 'flex', alignItems: "center",
                      }}
                      >
                        <h1 style={{ fontSize: 14 }}>Número:</h1>
                        <h2 style={{ fontSize: 14, fontWeight: "normal", marginLeft: 5 }}>{item?.numero_endereco}</h2>
                      </div>
                    );
                  }
                })}
              </div>
            </div>
          );
        })}
      </div>

      <div style={{
        display: "flex", marginTop: 20, justifyContent: 'space-between', flexDirection: "column", width: '100%', background: '#f6f6f6',
      }}
      >
        <div style={{ display: "flex", alignItems: 'center', justifyContent: "space-between" }}>
          <h1 style={{
            margin: 10, fontSize: 16, fontWeight: 'bold',
          }}
          >
            Perfil Profissional
          </h1>
        </div>

        <div style={{
          width: '100%', borderBottom: 2, borderBottomStyle: 'solid', borderBottomColor: '#1B7E9F',
        }}
        />
      </div>

      <div style={{ display: "flex" }}>
        <div style={{ width: '50%' }}>
          <div style={{
            marginTop: 20, display: 'flex', alignItems: "center",
          }}
          >
            <h1 style={{ fontSize: 14 }}>Formação Profissional:</h1>
            <h2 style={{ fontSize: 14, fontWeight: "normal", marginLeft: 5 }}>{egresse?.formacao_profissional}</h2>
          </div>
          <div style={{
            display: 'flex', alignItems: "center",
          }}
          >
            <h1 style={{ fontSize: 14 }}>Experiência Profissional:</h1>
            <h2 style={{ fontSize: 14, fontWeight: "normal", marginLeft: 5 }}>{egresse?.experiencias_profissional1 && egresse?.experiencias_profissional1.split('&').map((item: string) => item).join(', ')}</h2>
          </div>
          <div style={{
            display: 'flex', alignItems: "center",
          }}
          >
            <h1 style={{ fontSize: 14 }}>Habilidades e Competências:</h1>
            <h2 style={{ fontSize: 14, fontWeight: "normal", marginLeft: 5 }}>{egresse?.habilidades_competencias}</h2>
          </div>
        </div>
        <div style={{ width: '50%' }}>
          <div style={{
            marginTop: 20, display: 'flex', alignItems: "center",
          }}
          >
            <h1 style={{ fontSize: 14 }}>Disponibilidade Quanto ao Trabalho:</h1>
            <h2 style={{ fontSize: 14, fontWeight: "normal", marginLeft: 5 }}>{egresse?.disponibilidade_horario_trabalho}</h2>
          </div>
          <div style={{
            display: 'flex', alignItems: "center",
          }}
          >
            <h1 style={{ fontSize: 14 }}>Disponibilidade Quanto ao Curso:</h1>
            <h2 style={{ fontSize: 14, fontWeight: "normal", marginLeft: 5 }}>{egresse?.disponibilidade_horario_curso}</h2>
          </div>
          <div style={{
            display: 'flex', alignItems: "center",
          }}
          >
            <h2 style={{ fontSize: 14, fontWeight: "normal" }}>
              <strong><h1 style={{ fontSize: 14 }}>Cursos de Interesse: </h1></strong>
              {egresse?.cursos_interesse?.map((item: any) => item.nome).join(', ')}
            </h2>
          </div>
        </div>
      </div>

      <div style={{
        display: "flex", marginTop: 20, justifyContent: 'space-between', flexDirection: "column", width: '100%', background: '#f6f6f6',
      }}
      >
        <div style={{ display: "flex", alignItems: 'center', justifyContent: "space-between" }}>
          <h1 style={{
            margin: 10, fontSize: 16, fontWeight: 'bold',
          }}
          >
            Perfil Biopsicossocial
          </h1>
        </div>

        <div style={{
          width: '100%', borderBottom: 2, borderBottomStyle: 'solid', borderBottomColor: '#1B7E9F',
        }}
        />
      </div>

      <div style={{ display: "flex" }}>
        <div style={{ width: '50%' }}>
          <div>
            <h1 style={{ marginTop: 20, fontSize: 14 }}>Componentes Biológicos</h1>
          </div>
          <div style={{
            display: 'flex', alignItems: "center",
          }}
          >
            <h1 style={{ fontSize: 14 }}>Doenças Pré-existentes:</h1>
            <h2 style={{ fontSize: 14, fontWeight: "normal", marginLeft: 5 }}>{egresse?.doencas_pre_existentes && egresse?.doencas_pre_existentes.split('&').map((item: string) => item).join(', ')}</h2>
          </div>
          <div style={{
            display: 'flex', alignItems: "center",
          }}
          >
            <h1 style={{ fontSize: 14 }}>Doenças Adquiridas:</h1>
            <h2 style={{ fontSize: 14, fontWeight: "normal", marginLeft: 5 }}>{egresse?.doencas_adquiridas && egresse?.doencas_adquiridas.split('&').map((item: string) => item).join(', ')}</h2>
          </div>
          <div>
            <h1 style={{ marginTop: 20, fontSize: 14 }}>Componentes Psicológicos</h1>
          </div>
          <div style={{
            display: 'flex', alignItems: "center",
          }}
          >
            <h1 style={{ fontSize: 14 }}>Comportamental:</h1>
            <h2 style={{ fontSize: 14, fontWeight: "normal", marginLeft: 5 }}>{egresse?.componente_psicologico_comportamental && egresse?.componente_psicologico_comportamental.split('&').map((item: string) => item).join(', ')}</h2>
          </div>
          <div style={{
            display: 'flex', alignItems: "center",
          }}
          >
            <h1 style={{ fontSize: 14 }}>Personalidade:</h1>
            <h2 style={{ fontSize: 14, fontWeight: "normal", marginLeft: 5 }}>{egresse?.componente_psicologico_personalidade && egresse?.componente_psicologico_personalidade.split('&').map((item: string) => item).join(', ')}</h2>
          </div>
        </div>
        <div style={{ width: '50%' }}>
          <div>
            <h1 style={{ marginTop: 20, fontSize: 14 }}>Componente Social</h1>
          </div>
          <div>
            <div style={{
              display: 'flex', alignItems: "center",
            }}
            >
              <h1 style={{ fontSize: 14 }}>Status Econômico:</h1>
              <h2 style={{ fontSize: 14, fontWeight: "normal", marginLeft: 5 }}>{egresse?.status_economico}</h2>
            </div>
          </div>
          <div>
            <div style={{
              display: 'flex', alignItems: "center",
            }}
            >
              <h1 style={{ fontSize: 14 }}>Necessita Aluguel:</h1>
              <h2 style={{ fontSize: 14, fontWeight: "normal", marginLeft: 5 }}>{egresse?.necessita_aluguel ? egresse?.necessita_aluguel == 'T' ? 'Sim' : 'Não' : 'Não informado'}</h2>
            </div>
          </div>
          <div>
            <div style={{
              display: 'flex', alignItems: "center",
            }}
            >
              <h1 style={{ fontSize: 14 }}>Necessita Moradia:</h1>
              <h2 style={{ fontSize: 14, fontWeight: "normal", marginLeft: 5 }}>{egresse?.necessita_moradia ? egresse?.necessita_moradia == 'T' ? 'Sim' : 'Não' : 'Não informado'}</h2>
            </div>
          </div>
          <div>
            <div style={{
              display: 'flex', alignItems: "center",
            }}
            >
              <h1 style={{ fontSize: 14 }}>Possui Residência Fora do Estado:</h1>
              <h2 style={{ fontSize: 14, fontWeight: "normal", marginLeft: 5 }}>{egresse?.possui_residencia_fora_estado ? egresse?.possui_residencia_fora_estado == 'T' ? 'Sim' : 'Não' : 'Não informado'}</h2>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
