import React from 'react';
import styled from 'styled-components';
import { Table } from 'reactstrap';
import { Pagination } from '../Pagination';

type PropsTablePadrão = {
  thead: React.ReactNode[];
  headWidth: (string | number)[];
  children: React.ReactNode;
};

export function TablePadrão({ children, thead, headWidth }: PropsTablePadrão) {
  const width = headWidth || '40%';
  const widthID = '10%';

  return (
    <div style={{ overflow: 'auto' }}>
      <table className="table">
        <thead>
          <tr style={{ display: 'flex' }}>
            {thead.map((item, i) => (
              <th
                key={i}
                scope="col"
                style={{
                  width: headWidth[i],
                  maxWidth: headWidth[i],
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                {item}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {/* exemplo */}
          {/* {tbody.map((item, i) => (
              <Tr key={i}>
                <Td width="8%">{item.id}</Td>
                <Td>{item.nome}</Td>
                <Td>
                  <AiOutlineEye size={30} color="rgba(15, 169, 88, 1)" />
                </Td>
              </Tr>
            ))} */}
          {children}
        </tbody>
      </table>
    </div>
  );
}

type ITd = {
  width?: number | string;
  none?: boolean;
};
type ITr = {
  disable?: boolean;
};

export const Td = styled.td<ITd>`
  width: ${props => props.width || '40%'};
  max-width: ${props => props.width || '40%'};
  display: ${props => (props.none ? 'none' : 'flex')};
  justify-content: center;
  @media (max-width: 1024px) {
    font-size: 14px;
  }
`;
export const Tr = styled.tr<ITr>`
  display: ${props => (props.disable ? 'none' : 'flex')};
  @media (max-width: 1024px) {
    font-size: 14px;
  }
`;

export const TRr = styled.tr`
  display: flex;
  @media (max-width: 1024px) {
    font-size: 14px;
  }
`;
