import React, { useState } from 'react';

import { AbaFilterButtons, AbaFilterContent } from '../../../../components/develop-nilton-components/AbaFiltro';
import { ObservaçãoAnual } from './ObservaçãoAnual';
import { ObservaçãoTrimestral } from './ObservaçãoTrimestral';

export function Observação({ tabType }:{tabType:number}) {
  const [tab, setTab] = useState(1);
  return (
    <div className="mt-3">
      <AbaFilterButtons
        useStateTab={[tab, setTab]}
        buttons={["Anual", "Trimestral"]}
      />
      <AbaFilterContent
        tab={tab}
        components={[
          <ObservaçãoAnual tab={tab} tabType={tabType} />,
          <ObservaçãoTrimestral tab={tab} tabType={tabType} />,
        ]}
      />
    </div>
  );
}
