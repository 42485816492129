import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { IIndicadoresGraficosAcompanhamento, IndicadoresGraficosAcompanhamento } from '../../../../components/develop-nilton-components/IndicadoresGraficosAcompanhamento';
import { SectionHeader } from '../../../../components/SectionHeader';
import { ITbodyGraficos } from '../types';

export function PageIndicadoresGraficos() {
  const history = useHistory();
  const { state } = useLocation<ITbodyGraficos>();
  const { acompanhamento_concluidos, egresso } = state;

  const propsIndicadoresGraficos:IIndicadoresGraficosAcompanhamento["data_sets"] = [
    {
      titleGraphic: "Comportamento",
      data: {
        labels: ['Mudança de humor', 'Agressividade', 'Comp. bipolar', 'Raiva ou hostilidade', 'Automultilação ou suícidio'],
        datasets: [{
          label: "",
          backgroundColor: '#FFA500',
          borderColor: '#FFA500',
          data: [acompanhamento_concluidos.muda_humor_facilmente,
            acompanhamento_concluidos.fica_nervoso_constantemente,
            acompanhamento_concluidos.fica_triste_euforico,
            acompanhamento_concluidos.fica_raiva_hostil,
            acompanhamento_concluidos.pensamentos_suicidas],
        }],
      },
    },
    {
      titleGraphic: "Concentração",
      data: {
        labels: ['Cansaço', 'Concentração', 'Fadiga matinal', 'Atenção', 'Tomar decisões '],
        datasets: [{
          label: "",
          backgroundColor: '#FFA500',
          borderColor: '#FFA500',
          data: [
            acompanhamento_concluidos.sente_cansaco,
            acompanhamento_concluidos.consegue_concentrar,
            acompanhamento_concluidos.fadiga_matinal,
            acompanhamento_concluidos.manter_atencao_tarefas_especificas,
            acompanhamento_concluidos.dificuldade_tomar_decisao,
          ],
        }],
      },
    },
  ];

  function handleBackRedirect() {
    history.push({
      pathname: "/perfil/historico",
      state: egresso,
    });
  }

  const data_set_acompanhamento:IIndicadoresGraficosAcompanhamento = {
    data_sets: propsIndicadoresGraficos,
  };

  console.log(acompanhamento_concluidos);

  return (
    <div className="w-100 mb-5">
      <SectionHeader subtitle="Indicadores gráficos" handleRedirect={handleBackRedirect} />

      <IndicadoresGraficosAcompanhamento {...data_set_acompanhamento} />
    </div>
  );
}
