import React from 'react';
import {
  Nav, NavItem, NavLink, TabContent, TabPane,
} from 'reactstrap';
import styled from 'styled-components';

type PropsAbaFilterButtons = {
  useStateTab: [number, React.Dispatch<React.SetStateAction<number>>]
  buttons: React.ReactNode[]
  updateTable?: (page?:number) => Promise<void>
  updateTableArray?: ((page?:number) => Promise<void>)[]
}

export function AbaFilterButtons({
  useStateTab, buttons, updateTable, updateTableArray,
}:PropsAbaFilterButtons) {
  const [tab, setTab] = useStateTab;
  return (
    <div>
      <Nav tabs>
        {buttons.map((item, i) => (
          <NavItem key={i}>
            <NavLink
              className={tab === i + 1 ? 'active' : ''}
              style={{ cursor: "pointer" }}
              onClick={() => {
                if (updateTableArray) {
                  updateTableArray[i]();
                }
                if (updateTable) { updateTable(); }
                setTab(i + 1);
              }}
            >
              <TextWrapper>
                {item}
              </TextWrapper>
            </NavLink>
          </NavItem>
        ))}
      </Nav>
    </div>
  );
}

type PropsAbaFilterContent = {
  tab: number
  components: React.ReactNode[]
}

export function AbaFilterContent({ tab, components }:PropsAbaFilterContent) {
  return (
    <TabContent activeTab={tab}>
      {components.map((item, i) => (
        <TabPane key={i} tabId={i + 1} style={{ display: i + 1 === tab ? "" : "none" }}>
          {item}
        </TabPane>
      ))}
    </TabContent>
  );
}

const TextWrapper = styled.div`
@media (max-width: 1024px) {
  font-size: 14px;
}
`;
