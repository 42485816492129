import { Formik } from 'formik';
import React from 'react';
import * as yup from 'yup';
import { Button, Form, Label, ModalFooter } from 'reactstrap';
import { format } from 'date-fns';
import { InputField } from '../../../InputField';
import { mask_only_number } from '../../../../../utils/mask_only_number';
import { mask_cpf } from '../../../../../utils/mask_cpf';
import { mask_phone } from '../../../../../utils/mask_phone';

type FormPart1Props = {
  initialValues: any;
  handleNextStep: (newData: any) => void;
  handleBackStep: () => void;
  setIsOpen?: (value: boolean) => void;
  useStateAvatar: any;
  isCreate: boolean;
};

export function FormPart1(props: FormPart1Props) {
  const {
    initialValues,
    setIsOpen,
    isCreate,
    handleNextStep,
    handleBackStep,
    useStateAvatar,
  } = props;

  const [avatar, setAvatar] = useStateAvatar;

  const ufArray = [
    '-',
    'AC',
    'AL',
    'AP',
    'AM',
    'BA',
    'CE',
    'ES',
    'GO',
    'MA',
    'MT',
    'MS',
    'MG',
    'PA',
    'PB',
    'PR',
    'PE',
    'PI',
    'RJ',
    'RN',
    'RS',
    'RO',
    'RR',
    'SC',
    'SP',
    'SE',
    'TO',
    'DF',
  ];

  const handleSubmit = (values: any) => {
    handleNextStep(values);
  };

  const validationSchema = yup.object({
    // numero_cadastro: yup.string().required("Insira o número de cadastro").nullable(),
    nome: yup.string().required('Insira o nome').nullable(),
    // data_entrada_sistema_penitenciario: yup.date().required("Insira a data de entrada no sistema penitenciario").nullable(),
    // data_alvara_soltura: yup.date().required("Insira o alvara de soltura").min(yup.ref('data_entrada_sistema_penitenciario'), 'Não pode ser menor que a data do Data de entrada no sistema peninteciário').nullable(),
    // data_termino_pena: yup.date().min(yup.ref('data_alvara_soltura'), 'Não pode ser menor que a data do alvará de soltura').nullable(),
  });

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
    >
      {props => {
        const { handleSubmit, values, setFieldValue } = props;
        return (
          <Form onSubmit={handleSubmit}>
            <div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                }}
              >
                <div
                  style={{
                    cursor: 'pointer',
                    width: 150,
                    height: 150,
                    marginRight: 10,
                  }}
                >
                  <label
                    className="label-title"
                    htmlFor="img-input"
                    style={{ cursor: 'pointer' }}
                  >
                    {avatar ? (
                      <div>
                        <img
                          style={{ borderRadius: '50%' }}
                          id="preview"
                          src={URL.createObjectURL(avatar)}
                          width={150}
                          height={150}
                        />
                      </div>
                    ) : (
                      <div>
                        <img
                          style={{ borderRadius: '50%' }}
                          id="preview"
                          src={
                            !avatar && initialValues?.avatar
                              ? initialValues?.avatar?.url_file
                              : 'https://static.vecteezy.com/ti/vetor-gratis/p1/1840618-imagem-perfil-icone-masculino-icone-humano-ou-pessoa-sinal-e-simbolo-gr%C3%A1tis-vetor.jpg'
                          }
                          width={150}
                          height={150}
                        />
                      </div>
                    )}
                  </label>
                  <input
                    id="img-input"
                    type="file"
                    name="avatar"
                    className="d-none"
                    onChange={e => {
                      // @ts-ignore
                      setAvatar(e.target.files[0]);
                    }}
                  />
                </div>

                <div style={{ width: '100%' }}>
                  <InputField
                    label="Nome*"
                    InputHTMLAttributes={{
                      id: 'nome',
                      name: 'nome',
                      placeholder: 'Insira o nome',
                    }}
                  />

                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      width: '100%',
                    }}
                  >
                    <div style={{ width: '50%', marginRight: 5 }}>
                      <InputField
                        label="Nome Social"
                        InputHTMLAttributes={{
                          id: 'nome_social',
                          name: 'nome_social',
                          placeholder: 'Insira o nome social',
                          style: { marginRight: 10 },
                        }}
                      />
                    </div>
                    <div style={{ width: '50%' }}>
                      <InputField
                        label="Alcunha"
                        InputHTMLAttributes={{
                          id: 'apelido',
                          name: 'apelido',
                          placeholder: 'Insira a alcunha',
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div
                style={{ display: 'flex', flexDirection: 'row', width: '100%' }}
              >
                <div style={{ width: '50%', marginRight: 5 }}>
                  <InputField
                    label="CPF"
                    InputHTMLAttributes={{
                      id: 'cpf',
                      name: 'cpf',
                      placeholder: 'Insira o CPF',
                      onChange: e =>
                        setFieldValue('cpf', mask_cpf(e.target.value)),
                      style: { marginRight: 10 },
                    }}
                  />
                </div>
                <div style={{ width: '40%' }}>
                  <InputField
                    label="RG"
                    InputHTMLAttributes={{
                      id: 'rg',
                      name: 'rg',
                      placeholder: 'Insira o RG',
                      onChange: e =>
                        setFieldValue('rg', mask_only_number(e.target.value)),
                    }}
                  />
                </div>
                <div style={{ width: '10%', paddingLeft: 10 }}>
                  <InputField
                    label="UF"
                    InputHTMLAttributes={{
                      id: 'rg_uf',
                      name: 'rg_uf',
                      type: 'select',
                      value: values.rg_uf || '-',
                      style: { marginRight: 10 },
                      // onChange: e => setFieldValue('rg_uf', e.target.value),
                    }}
                    LabelHTMLAttributes={{
                      className: 'mr-3',
                    }}
                    options={
                      <>
                        <option value="-">-</option>
                        <option value="AC">AC</option>
                        <option value="AL">AL</option>
                        <option value="AP">AP</option>
                        <option value="AM">AM</option>
                        <option value="BA">BA</option>
                        <option value="CE">CE</option>
                        <option value="ES">ES</option>
                        <option value="GO">GO</option>
                        <option value="MA">MA</option>
                        <option value="MT">MT</option>
                        <option value="MS">MS</option>
                        <option value="MG">MG</option>
                        <option value="PA">PA</option>
                        <option value="PB">PB</option>
                        <option value="PR">PR</option>
                        <option value="PE">PE</option>
                        <option value="PI">PI</option>
                        <option value="RJ">RJ</option>
                        <option value="RN">RN</option>
                        <option value="RS">RS</option>
                        <option value="RO">RO</option>
                        <option value="RR">RR</option>
                        <option value="SC">SC</option>
                        <option value="SP">SP</option>
                        <option value="SE">SE</option>
                        <option value="TO">TO</option>
                        <option value="DF">DF</option>
                      </>
                    }
                  />
                </div>
              </div>

              <div
                style={{ display: 'flex', flexDirection: 'row', width: '100%' }}
              >
                <div style={{ width: '50%', marginRight: 5 }}>
                  <InputField
                    label="Nacionalidade"
                    InputHTMLAttributes={{
                      id: 'nacionalidade',
                      name: 'nacionalidade',
                      placeholder: 'Insira a nacionalidade',
                      style: { marginRight: 10 },
                    }}
                  />
                </div>
                <div style={{ width: '50%' }}>
                  <InputField
                    label="Naturalidade"
                    InputHTMLAttributes={{
                      id: 'naturalidade',
                      name: 'naturalidade',
                      placeholder: 'Insira a naturalidade',
                    }}
                  />
                </div>
              </div>

              <div
                style={{ display: 'flex', flexDirection: 'row', width: '100%' }}
              >
                <div style={{ width: '50%', marginRight: 5 }}>
                  <InputField
                    label="Carteira de Trabalho"
                    InputHTMLAttributes={{
                      id: 'carteira_trabalho',
                      name: 'carteira_trabalho',
                      placeholder: 'Insira a carteira de trabalho',
                      onChange: e =>
                        setFieldValue(
                          'carteira_trabalho',
                          mask_only_number(e.target.value),
                        ),
                      style: { marginRight: 10 },
                    }}
                  />
                </div>
                <div style={{ width: '50%' }}>
                  <InputField
                    label="Título de Eleitor"
                    InputHTMLAttributes={{
                      id: 'titulo_eleitor',
                      name: 'titulo_eleitor',
                      placeholder: 'Insira o titulo de eleito',
                      onChange: e =>
                        setFieldValue(
                          'titulo_eleitor',
                          mask_only_number(e.target.value),
                        ),
                    }}
                  />
                </div>
              </div>

              <div
                style={{ display: 'flex', flexDirection: 'row', width: '100%' }}
              >
                <div style={{ width: '50%', marginRight: 5 }}>
                  <InputField
                    label="Sexo"
                    InputHTMLAttributes={{
                      id: 'sexo',
                      name: 'sexo',
                      type: 'select',
                      value: values.sexo || '-',
                      style: { marginRight: 10 },
                    }}
                    LabelHTMLAttributes={{
                      className: 'mr-3',
                    }}
                    options={
                      <>
                        <option value="-">-</option>
                        <option value="M">Masculino</option>
                        <option value="F">Feminino</option>
                      </>
                    }
                  />
                </div>
                <div style={{ width: '50%' }}>
                  <InputField
                    label="Estado Civil"
                    InputHTMLAttributes={{
                      id: 'estado_civil',
                      name: 'estado_civil',
                      type: 'select',
                      value: values.estado_civil || '-',
                    }}
                    LabelHTMLAttributes={{
                      className: 'mr-3',
                    }}
                    options={
                      <>
                        <option value="-">-</option>
                        <option value="Solteiro">Solteiro</option>
                        <option value="Casado">Casado</option>
                        <option value="Divorciado">Divorciado</option>
                        <option value="Viúvo">Viúvo</option>
                        <option value="Outros">Outros</option>
                      </>
                    }
                  />
                </div>
              </div>

              <div
                style={{ display: 'flex', flexDirection: 'row', width: '100%' }}
              >
                <div style={{ width: '50%', marginRight: 5 }}>
                  <InputField
                    label="Etnia"
                    InputHTMLAttributes={{
                      id: 'cor_raca',
                      name: 'cor_raca',
                      type: 'select',
                      value: values.cor_raca || '-',
                      style: { marginRight: 10 },
                    }}
                    LabelHTMLAttributes={{
                      className: 'mr-3',
                    }}
                    options={
                      <>
                        <option value="-">-</option>
                        <option value="Branco">Branco</option>
                        <option value="Negro">Negro</option>
                        <option value="Pardo">Pardo</option>
                        <option value="Indígena">Indígena</option>
                        <option value="Amarelo">Amarelo</option>
                        <option value="Outros">Outros</option>
                      </>
                    }
                  />
                </div>
                <div style={{ width: '50%' }}>
                  <InputField
                    label="Data de nascimento"
                    InputHTMLAttributes={{
                      id: 'data_nascimento',
                      name: 'data_nascimento',
                      type: 'date',
                    }}
                  />
                </div>
              </div>

              <div
                style={{ display: 'flex', flexDirection: 'row', width: '100%' }}
              >
                <div style={{ width: '50%', marginRight: 5 }}>
                  <InputField
                    label="E-mail"
                    InputHTMLAttributes={{
                      id: 'email',
                      name: 'email',
                      placeholder: 'Insira o email',
                      type: 'email',
                      style: { marginRight: 10 },
                    }}
                  />
                </div>
                <div style={{ width: '50%' }}>
                  <InputField
                    label="Telefone (Recado)"
                    InputHTMLAttributes={{
                      id: 'telefone_recado',
                      name: 'telefone_recado',
                      placeholder: 'Insira o telefone de recado',
                      maxLength: 15,
                      onChange: e =>
                        setFieldValue(
                          'telefone_recado',
                          mask_phone(e.target.value),
                        ),
                    }}
                  />
                </div>
              </div>

              <div
                style={{ display: 'flex', flexDirection: 'row', width: '100%' }}
              >
                <div style={{ width: '50%', marginRight: 5 }}>
                  <InputField
                    label="Residência Fixa"
                    InputHTMLAttributes={{
                      id: 'possui_residencia_fixa',
                      name: 'possui_residencia_fixa',
                      type: 'select',
                      value: values.possui_residencia_fixa || '-',
                      style: { marginRight: 10 },
                    }}
                    LabelHTMLAttributes={{
                      className: 'mr-3',
                    }}
                    options={
                      <>
                        <option value="-">-</option>
                        <option value="T">Sim</option>
                        <option value="F">Não</option>
                      </>
                    }
                  />
                </div>
                <div style={{ width: '50%' }}>
                  <InputField
                    label="Escolaridade"
                    InputHTMLAttributes={{
                      id: 'escolaridade',
                      name: 'escolaridade',
                      type: 'select',
                      value: values.escolaridade || '-',
                    }}
                    LabelHTMLAttributes={{
                      className: 'mr-3',
                    }}
                    options={
                      <>
                        <option value="-">-</option>
                        <option value="Analfabeto">Analfabeto</option>
                        <option value="Fundamental Incompleto">
                          Fundamental Incompleto
                        </option>
                        <option value="Fundamental Completo">
                          Fundamental Completo
                        </option>
                        <option value="Médio Incompleto">
                          Médio Incompleto
                        </option>
                        <option value="Médio Completo">Médio Completo</option>
                        <option value="Superior Incompleto">
                          Superior Incompleto
                        </option>
                        <option value="Superior Completo">
                          Superior Completo
                        </option>
                        <option value="Pós Graduação (Lato senso) Incompleto">
                          Pós Graduação (Lato senso) Incompleto
                        </option>
                        <option value="Pós Graduação (Lato senso)Completo">
                          Pós Graduação (Lato senso)Completo
                        </option>
                        <option value="Pós Graduação (Stricto sensu, nível mestrado) Incompleto">
                          Pós Graduação (Stricto sensu, nível mestrado)
                          Incompleto
                        </option>
                        <option value="Pós Graduação (Stricto sensu, nível mestrado) Completo">
                          Pós Graduação (Stricto sensu, nível mestrado) Completo
                        </option>
                        <option value="Pós Graduação (Stricto sensu, nível doutorado) Incompleto">
                          Pós Graduação (Stricto sensu, nível doutorado)
                          Incompleto
                        </option>
                      </>
                    }
                  />
                </div>
              </div>

              <div
                style={{ display: 'flex', flexDirection: 'row', width: '100%' }}
              >
                <div style={{ width: '50%', marginRight: 5 }}>
                  <InputField
                    label="Religião"
                    InputHTMLAttributes={{
                      id: 'religiao',
                      name: 'religiao',
                      placeholder: 'Insira a religião',
                      style: { marginRight: 10 },
                    }}
                  />
                </div>
                <div style={{ width: '50%' }}>
                  <InputField
                    label="Observação"
                    InputHTMLAttributes={{
                      id: 'observacao',
                      name: 'observacao',
                      placeholder: 'Insira a observação',
                      type: 'textarea',
                      // @ts-ignore
                      rows: 4,
                    }}
                  />
                </div>
              </div>

              <div className="d-flex justify-content-between">
                <div>
                  <Button
                    type="button"
                    size="lg"
                    onClick={() => setIsOpen && setIsOpen(false)}
                  >
                    Cancelar
                  </Button>
                </div>
                <div>
                  <Button
                    style={{ borderColor: '#1B7E9F', background: '#1B7E9F' }}
                    type="submit"
                    color="primary"
                    size="lg"
                  >
                    {isCreate ? 'Avançar' : 'Salvar'}
                  </Button>
                </div>
              </div>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
}
