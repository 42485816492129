import React, { useState } from "react";
import { Chart } from "react-chartjs-2";
import { useLocation } from "react-router-dom";
import { AbaFilterButtons, AbaFilterContent } from "../../../../components/develop-nilton-components/AbaFiltro";
import { SectionHeader } from "../../../../components/SectionHeader";

export function IndicadoresSocioEconomico() {
  const { state } = useLocation();
  const [tab, setTab] = useState(1);

  const optionsChart = {
    plugins: { legend: { display: false } },
    indexAxis: 'y',
  };

  const chartArray = [
    {
      title: "Sobre a habitação do egresso",
      type: "bar",
      data: {
        labels: [
          "Moradia alugado",
          "Zona urbana",
          "Mora em local violento",
        ],
        datasets: [{
          label: '',
          data: [
            1, 2, 3,
          ],
          borderColor: '#FFA500',
          backgroundColor: '#FFA500',
        }],
      },
    },
    {
      title: "Perfil pessoal e familiar",
      type: "bar",
      data: {
        labels: [
          "Familia com 3 a 5 membros",
          "Deficiência ou similar ",
          "Tem religião",
          "Usa frequentemente álcool",
          "Membro em vunerabilidade social",
        ],
        datasets: [{
          label: '',
          data: [
            1, 2, 3, 4, 5,
          ],
          borderColor: '#FFA500',
          backgroundColor: '#FFA500',
        }],
      },
    },
    {
      title: "Renda pessoal e familiar",
      type: "bar",
      data: {
        labels: [
          "Renda infomal",
          "Programa de auxilio financeiro",
          "Familia beneficiada com bolsa familia",
          "Conta com beneficio de prestação continua",
          "Familia possui vinculo empregaticio",
          "Familia recebe algum beneficio",
        ],
        datasets: [{
          label: '',
          data: [
            1, 2, 3, 4, 5,
          ],
          borderColor: '#FFA500',
          backgroundColor: '#FFA500',
        }],
      },
    },
    {
      title: "Comunidade LGBT",
      type: "bar",
      data: {
        labels: [
          "Gay",
          "Lésbica",
          "Bisexual",
          "Transexual",
          "Intersexual",
          "outros",
        ],
        datasets: [{
          label: '',
          data: [
            1, 2, 3, 4, 5,
          ],
          borderColor: '#FFA500',
          backgroundColor: '#FFA500',
        }],
      },
    },
  ];
  return (
    <div className="w-100">
      <SectionHeader subtitle="Indicadores Sociais e Econômicos" />
      <h2 className="text-primary">{state.nome}</h2>

      <AbaFilterButtons buttons={["Anual", "Trimestral"]} useStateTab={[tab, setTab]} />

      <AbaFilterContent
        tab={tab}
        components={[
          <div>
            {chartArray.map((chart) => (
              <div>
                <h3 className="m-2 border-bottom">{chart.title}</h3>
                <Chart
          // @ts-ignore
                  type={chart.type}
                  data={chart.data}
             // @ts-ignore
                  options={optionsChart}
                />
              </div>
            ))}
          </div>,
          <div>
            {chartArray.map((chart) => (
              <div>
                <h3 className="m-2 border-bottom">{chart.title}</h3>
                <Chart
            // @ts-ignore
                  type={chart.type}
                  data={chart.data}
               // @ts-ignore
                  options={optionsChart}
                />
              </div>
            ))}
          </div>,
        ]}
      />

    </div>
  );
}
