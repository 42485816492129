import React, { useState } from 'react';
import { format } from 'date-fns';
import styled from 'styled-components';
import { SubmitResponse } from "../../../../components/SubmitResponse";
import Header from '../../components/Header';
import Period from '../../components/Modal/Period';
import { useGenerate } from '../hook/generateOcurrences';
import { useOcurrences } from '../hook/ocurrences';
import {
  Subtitle,
  Button, WrapperButton,
} from "../styles";
import { Pagination } from '../../../../components/Pagination';

type IProps = {
  back?(): void
}

export function Fiscalizações({ back }:IProps) {
  const {
    ocurrences, setPage, month, setMonth, year, setYear,
  } = useOcurrences();
  const generate = useGenerate();
  const [modalIsOpen, setIsOpen] = useState(false);

  function closeModal() {
    setIsOpen(false);
  }
  return (
    <div>

      <Container>
        <Title style={{ display: 'flex', marginTop: 20 }}>
          <h1>Fiscalizações</h1>
          <h1 style={{
            fontSize: 26, marginLeft: 10, marginRight: 10, color: '#f00',
          }}
          >
            com problemas
          </h1>
          <h1 style={{ fontSize: 26 }}>{`- ${month}/${year}`}</h1>
          <h1
            style={{
              fontSize: 26, marginLeft: 10, color: '#007BFF', cursor: 'pointer', fontWeight: 'normal',
            }}
            onClick={() => setIsOpen(true)}
          >
            (ver outro período)
          </h1>
        </Title>
        {
        ocurrences?.data.length === 0 ? (
          <SubmitResponse submitResponse={{ message: "Não há ocorrencia necesse periodo.", status: "warning", title: "Aviso" }} />
        ) : (
          <>
            <table className="table">
              <thead>
                <tr>
                  <td><strong>Egresso</strong></td>
                  <td><strong>Data da ocorrência</strong></td>
                  <td><strong>Ocorrência</strong></td>
                </tr>
              </thead>
              <tbody>
                {ocurrences?.data.map((item) => (
                  <tr key={item.id}>
                    <td>{item?.egresso?.nome}</td>
                    <td>{format(new Date(item.data_ocorrencia), 'dd/MM/yyyy')}</td>
                    <td>{item.ocorrencia.name}</td>
                  </tr>
                ))}

              </tbody>
            </table>
            {ocurrences && <Pagination meta={ocurrences.meta} setPage={setPage} />}
          </>
        )
      }
        <Period tab="mensal" modalIsOpen={modalIsOpen} closeModal={closeModal} periodo={{ month, trimestre: undefined, year: String(year) }} setMonth={setMonth} setYear={setYear} />
      </Container>
      <WrapperButton>
        <Button backgroundr="white" color="black" fontSize="20px" onClick={back}>Voltar</Button>
        <Button backgroundr="#FFAC0C" color="white" fontSize="20px" onClick={generate}>Imprimir</Button>
      </WrapperButton>
    </div>
  );
}

const Title = styled.h1`
  @media (max-width: 1024px) {
    flex-direction: column;
  }
`;

const Container = styled.div`
    overflow: auto;
  @media (max-width: 768px) {
    width: 250px;
  }
`;
