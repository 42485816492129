import React, { useState, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { BiArrowBack } from 'react-icons/bi';
import { debounce } from 'lodash';
import { AbaFilterContent } from '../../../../components/develop-nilton-components/AbaFiltro';
import { SectionHeader } from '../../../../components/SectionHeader';
import { useEgresses } from '../hooks/useEgresses';
import { UseRoundEgressesOfFical } from '../hooks/useRoundEgressesOfFiscal';
import { TableAlreadyAddsInARounds } from './TableAlreadyAddsInTheRound';
import { TableGeralRoundEgresses } from './TableGeralRoundEgresses';
import { SearchEgresses } from '../../../../components/develop-nilton-components/SearchEgresses';
import { AddEgressInRondasModal } from './ModalAddEgressInRondas';
import { ButtonAdd } from '../../../../components/new-components/ButtonAdd';

import InputSearch from '../../components/InputSearch';
import api from '../../../../services/api';
import { IEgresses } from '../../../../types/IEgresses';
import { Detail } from '../../../Adm/Egressos/Detail';

export function PageAddRoundsEgresses() {
  const history = useHistory();
  const { state } = useLocation<any>();
  const [checkboxAll, setCheckboxAll] = useState<any>(null);
  const [checkboxAllRound, setCheckboxAllRound] = useState<any>(null);
  const [isOpenAddEgressRondasModal, setIsOpenAddEgressRondasModal] = React.useState(false);

  const [egresse, setEgresse] = React.useState<IEgresses |null>(null);
  const [isWatchEgresse, setIsWatchEgresse] = React.useState(false);

  const { egresses, handleSetEgresses, load: loadEgress } = useEgresses();
  const { roundEgressesOfFiscal, setRoundEgressesOfFiscal, handleUseRoundEgressesOfFical } = UseRoundEgressesOfFical();

  const [loadingSearch, setLoadingSearch] = useState(false);

  async function handleUpdate(page?:number) {
    await handleUseRoundEgressesOfFical();
    await handleSetEgresses(page);
  }

  async function handleUpdateEgresses(page?:number) {
    // await handleUseRoundEgressesOfFical();
    await handleSetEgresses(page);
  }

  const [searchTerm, setSearchTerm] = useState("");

  async function search(page: number, search: string) {
    setLoadingSearch(true);

    /* console.log(optionsEgresses);

    // @ts-ignore
    const filter = optionsEgresses.filter((value: any) => String(value.egresso.nome)
      .toUpperCase()
      .includes(String(search).toUpperCase()));
    setRoundEgresses(filter); */

    // await handleSetEgresses(page, search);
    setSearchTerm(search);
    setLoadingSearch(false);
  }

  const debounceLoadData = useMemo(() => debounce(handleSetEgresses, 700), []);

  React.useEffect(() => {
    // @ts-ignore
    window.addEventListener("keydown", debounceLoadData(1, searchTerm));
    return () => {
      // @ts-ignore
      window.removeEventListener("keydown", debounceLoadData(1, searchTerm));
    };
  }, [searchTerm]);

  return (
    <WrapperContainer>
      {isWatchEgresse && (
        <Detail
          useStateEgresse={[egresse, setEgresse]}
          useStateIsOpen={[isWatchEgresse, setIsWatchEgresse]}
        />
      )}
      {/* ) : ( */}
      <div style={{ display: isWatchEgresse ? 'none' : undefined }}>
        <AddEgressInRondasModal
          useStateIsOpen={[isOpenAddEgressRondasModal, setIsOpenAddEgressRondasModal]}
          handleUpdate={handleUpdate}
          handleUpdateEgresses={handleUpdateEgresses}
          roundsEgresses={roundEgressesOfFiscal}
          setRoundEgresses={setRoundEgressesOfFiscal}
          egresseState={state}
          useStateCheckboxAll={[checkboxAll, setCheckboxAll]}
        />

        <SectionHeader
          isIconBack={(
            <div onClick={() => history.push({ pathname: '/cadastrar-ronda' })} style={{ cursor: "pointer", marginRight: 10 }}>
              <BiArrowBack size={30} />
            </div>
        )}
          subtitle={`${state.title}`}
        />
        <div style={{ width: "100%", marginBottom: 50 }}>
          {/* <SearchEgresses
          handleSetSearch={handleSetEgresses}
          button={(
            <ButtonAdd
              onClick={() => {
                setIsOpenAddEgressRondasModal(true);
              }}
            />
          )}
        /> */}
          <div className="content">
            <InputSearch
              loading={loadingSearch}
              setSearch={search}
              autosearch
              title="Pesquisar Egresso"
              placeholder="Digite o nome ou localidade do egresso"
              buttonText="Buscar"
              description=""
              button={(
                <ButtonAdd
                  style={{ marginLeft: 10, background: '#68996D' }}
                  onClick={() => {
                    setIsOpenAddEgressRondasModal(true);
                  }}
                />
          )}
            />
          </div>
        </div>

        <AbaFilterContent
          tab={1}
          components={[
            <TableGeralRoundEgresses
              egresses={egresses}
              handleSetEgresses={handleUpdate}
              useStateCheckboxAll={[checkboxAll, setCheckboxAll]}
              loadEgress={loadEgress}
              useStateEgresse={[egresse, setEgresse]}
              useStateIsOpen={[isWatchEgresse, setIsWatchEgresse]}
            />,
            <TableAlreadyAddsInARounds
              handleUpdate={handleUpdate}
              roundsEgresses={roundEgressesOfFiscal}
              useStateCheckboxAll={[checkboxAllRound, setCheckboxAllRound]}
              loadEgress={loadEgress}
            />,
          ]}
        />
      </div>
      {/* )} */}
    </WrapperContainer>
  );
}

const WrapperContainer = styled.div`
  width: 100%;

  .content {
    width: 100%;

    & div {
      display: flex;
    }

    select:focus {
      outline: none;
    }

    textarea:focus {
      outline: none;
    }

    & input {
      width: 100%;
    }

    & input:focus {
      outline: none !important;
    }

    .inputfile {
      width: 0.1px;
      height: 0.1px;
      opacity: 0;
      overflow: hidden;
      position: absolute;
      z-index: -1;
    }
    .inputfile:focus + label {
      outline: 1px dotted #000;
      outline: -webkit-focus-ring-color auto 5px;
    }
    .inputfile + label * {
      pointer-events: none;
    }
  }
`;
