import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useGetSearchEgresses } from '../../../components/develop-nilton-components/hooks/useGetSearchEgresses';
import { IconDetails } from '../../../components/develop-nilton-components/IconDetails';
import { ModalDetailsEgresse } from '../../../components/develop-nilton-components/ModalDetailsEgresses';
import { SearchEgresses } from '../../../components/develop-nilton-components/SearchEgresses';
import { TablePadrão, Tr, Td } from '../../../components/develop-nilton-components/TablePadrão';
import { Loading } from '../../../components/new-components/Loading';
import { TableComponent } from '../../../components/new-components/Table';
import { Pagination } from '../../../components/Pagination';
import { SectionHeader } from '../../../components/SectionHeader';
import { SpinnerLoading } from '../../../components/SpinnerLoading';
import { orderPage } from '../../../utils/orderPages';

export function VerEgresso() {
  const { egresses, handleSetOptionsEgresses, load } = useGetSearchEgresses();
  const headWidth = ["5%", "50%", "45%"];
  const [isOpen, setIsOpen] = useState(false);

  function handleIsOpen() {
    setIsOpen(!isOpen);
  }

  const [egress, setEgress] = React.useState<any>(null);

  const [page, setPage] = React.useState(1);

  return (
    <div className="w-100">
      <SectionHeader subtitle="Ver Egresso" />

      <SearchEgresses handleSetSearch={handleSetOptionsEgresses} />

      <ModalDetailsEgresse egresse={egress} handleIsOpen={handleIsOpen} isOpen={isOpen} />

      {!egresses ? <Loading /> : (
        <TableComponent data={egresses?.data.map((egress:any, i: number) => ({
          Ordem: orderPage(i, page, egresses?.meta.per_page),
          Nome: egress.nome,
          Detalhes: (
            <>
              <div onClick={() => {
                setEgress(egress);
                handleIsOpen();
              }}
              >
                <IconDetails cursor />
              </div>

            </>
          ),
        }))}
        />
      )}

      {/* <TablePadrão headWidth={headWidth} thead={["#", "Nome", "Indicadores"]}>
        {load && (
          <Tr>
            <Td width="100%">
              <SpinnerLoading />
            </Td>
          </Tr>
        )}
        {egresses?.data.map((egress:any, i: number) => (
          <Tr>
            <Td width={headWidth[0]}>{i + 1}</Td>
            <Td width={headWidth[1]}>{egress.nome}</Td>
            <Td width={headWidth[2]}>
              <div onClick={handleIsOpen}>
                <IconDetails cursor />
              </div>
              <ModalDetailsEgresse egresse={egress} handleIsOpen={handleIsOpen} isOpen={isOpen} />
            </Td>
          </Tr>
        ))}
        {egresses && (
        <Tr>
          <Td width="100%">
            <Pagination meta={egresses?.meta} setPage={handleSetOptionsEgresses} load={load} />
          </Td>
        </Tr>
        )}
      </TablePadrão> */}

    </div>
  );
}
