import React, { useState, useEffect } from 'react';

import {
  Nav, NavItem, NavLink, TabContent, TabPane, Row, Col,
} from 'reactstrap';

import ReactPaginate from 'react-paginate';

import { BsFillHandThumbsDownFill, BsFillHandThumbsUpFill } from 'react-icons/bs';
import { AiOutlineFilePdf, AiFillWarning } from 'react-icons/ai';

import styled from 'styled-components';
import { SpinnerLoading } from '../../../components/SpinnerLoading';
import { Container } from './styles';

import InputSearch from '../components/InputSearch';
import AlvaraDetail from './components/AlvaraDetail';
import api from '../../../services/api';
import { SectionHeader } from '../../../components/SectionHeader';

interface IPagination {
  total: number;
  per_page: number;
  current_page: number;
  last_page: number;
}

interface IAlvara {
  id: number;
  numero_alvara: number;
  egress: {
    nome: string;
    cep: string;
    endereco: string;
    bairro: string;
    estado: string;
    municipio: string;
  };
  responsavel_execucao: any;
  executado: string;
  data_execucao: string;
  registro_seuu: string;
  attachment: {
    name:string;
    url_file: string;
  };
  observacao: string;
}

interface IAlvaras {
  meta: IPagination;
  data: IAlvara[];
}

export function CumprirAlvara() {
  const [tab, setTab] = useState('1');
  const [alvara, setAlvara] = useState<IAlvara | null>(null);

  const [loading, setLoading] = useState(false);
  const [loadingSearch, setLoadingSearch] = useState(false);

  const [isSearch, setInSearch] = useState('');

  const [page, setPage] = useState(0);

  const [alvaras, setAlvaras] = useState<IAlvaras>({
    meta: {
      total: 0,
      per_page: 30,
      current_page: 1,
      last_page: 1,
    },
    data: [],
  });

  function getLicenses(page = 0) {
    setLoading(true);
    api.get(`licenses`, {
      params: {
        page: page + 1,
        executado: tab === '1' ? undefined : tab === '2' ? 'F' : tab === '3' ? 'T' : undefined,
        numero: isSearch !== '' ? isSearch : undefined,
      },
    }).then(({ data: licenses }) => {
      setAlvaras(licenses);
      setPage(licenses.meta.current_page);
      setLoading(false);
    });
  }

  useEffect(() => {
    if (!loadingSearch) {
      getLicenses();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [alvara, tab, isSearch]);

  async function search(page: number, numero_alvara: string) {
    setLoadingSearch(true);
    setInSearch(numero_alvara);
    api.get(`licenses`, {
      params: {
        numero: numero_alvara,
        executado: tab === '1' ? undefined : tab === '2' ? 'F' : tab === '3' ? 'T' : undefined,
      },
    }).then(({ data }) => {
      setAlvaras(data);
      setPage(data.meta.current_page);
      setLoadingSearch(false);
    });
  }

  return (
    <Container>
      {!alvara
        ? (
          <>
            {/* <Header route="cumprir-alvara" name="Cumprir alvará" /> */}
            <SectionHeader subtitle="Cumprir Alvará" />

            <div className="content">
              <div style={{ marginBottom: 10 }} />
              <InputSearch
                loading={loadingSearch}
                setSearch={search}
                title="Pesquise o alvará desejado:"
                placeholder="Digite a identificação do alvará"
                buttonText="Buscar"
                description="Após digitar uma pista, clique no botão BUSCAR e acesse os dados"
              />
            </div>

            <div style={{ marginTop: 40, marginBottom: 20 }}>
              <h1 style={{ fontSize: 26 }}>Últimos alvarás registrados</h1>
              <h1 style={{ fontSize: 11, fontWeight: 'normal' }}>
                Para ver detalhes do alvará, clicar no Número do Alvará
              </h1>
            </div>

            <div>
              <Nav tabs>
                <NavItem>
                  <NavLink
                    className={tab === '1' ? 'active' : ''}
                    style={{ cursor: "pointer" }}
                    onClick={() => setTab("1")}
                  >
                    Geral
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={tab === '2' ? 'active' : ''}
                    style={{ cursor: "pointer" }}
                    onClick={() => setTab("2")}
                  >
                    Pendentes
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={tab === '3' ? 'active' : ''}
                    style={{ cursor: "pointer" }}
                    onClick={() => setTab("3")}
                  >
                    Concluídos
                  </NavLink>
                </NavItem>
              </Nav>
              <TabContent activeTab={tab}>

                <TabPane tabId="1">
                  <Row>
                    <Col sm="12">
                      {loading ? (
                        <div style={{
                          display: "flex", justifyContent: "center", alignItems: 'center', height: '50vh',
                        }}
                        >
                          <SpinnerLoading color="#000" size={50} />
                        </div>
                      ) : (
                        <ContainerTable>
                          <table style={{ marginTop: 10 }}>
                            <tr>
                              <th style={{ textAlign: 'center' }}>#</th>
                              <th style={{ textAlign: 'center' }}>Número do Alvará</th>
                              <th style={{ textAlign: 'center' }}>Egresso</th>
                              <th style={{ textAlign: 'center' }}>Fiscal</th>
                              <th style={{ textAlign: 'center' }}>Executado</th>
                              {/* <th style={{ textAlign: 'center' }}>Registro SEUU</th> */}
                              <th style={{ textAlign: 'center' }}>Arquivo</th>
                              <th style={{ textAlign: 'center' }}>Observação</th>
                            </tr>
                            {alvaras.data.map((alvara, index) => (
                              <tr>
                                <td>{index + 1 + (page > 1 ? (page - 1) * 30 : 0)}</td>
                                <td onClick={() => setAlvara(alvara)} style={{ color: '#f00', cursor: 'pointer' }}>{alvara.numero_alvara}</td>
                                <td>{alvara.egress.nome}</td>
                                <td>{alvara?.responsavel_execucao?.name || 'Sem responsável'}</td>
                                <td>
                                  { alvara.executado == "T" ? <BsFillHandThumbsUpFill color="#080" style={{ fontSize: 30 }} /> : <BsFillHandThumbsDownFill color="#f00" style={{ fontSize: 30 }} /> }
                                </td>
                                {/* <td>
                                  { alvara.registro_seuu == "T" ? <BsFillHandThumbsUpFill color="#080" style={{ fontSize: 30 }} /> : <BsFillHandThumbsDownFill color="#f00" style={{ fontSize: 30 }} /> }
                                </td> */}
                                <td>{ alvara.attachment ? <AiOutlineFilePdf onClick={() => window.open(alvara.attachment.url_file, '_blank')} color="#c7b515" style={{ fontSize: 30, cursor: 'pointer' }} /> : '' }</td>
                                <td>{ alvara.observacao ? <AiFillWarning onClick={() => setAlvara(alvara)} color="#c7b515" style={{ fontSize: 30, cursor: 'pointer' }} /> : '' }</td>
                              </tr>
                            ))}
                          </table>
                        </ContainerTable>
                      )}
                    </Col>
                  </Row>
                </TabPane>

                <TabPane tabId="2">
                  <Row>
                    <Col sm="12">
                      {loading ? (
                        <div style={{
                          display: "flex", justifyContent: "center", alignItems: 'center', height: '50vh',
                        }}
                        >
                          <SpinnerLoading color="#000" size={50} />
                        </div>
                      ) : (
                        <ContainerTable>
                          <table style={{ marginTop: 10 }}>
                            <tr>
                              <th style={{ textAlign: 'center' }}>#</th>
                              <th style={{ textAlign: 'center' }}>Número do Alvará</th>
                              <th style={{ textAlign: 'center' }}>Egresso</th>
                              <th style={{ textAlign: 'center' }}>Fiscal</th>
                              <th style={{ textAlign: 'center' }}>Executado</th>
                              {/* <th style={{ textAlign: 'center' }}>Registro SEUU</th> */}
                              <th style={{ textAlign: 'center' }}>Arquivo</th>
                              <th style={{ textAlign: 'center' }}>Observação</th>
                            </tr>
                            {alvaras.data.filter((item) => item.executado == 'F').map((alvara, index) => (
                              <tr>
                                <td>{index + 1 + (page > 1 ? (page - 1) * 30 : 0)}</td>
                                <td onClick={() => setAlvara(alvara)} style={{ color: '#f00', cursor: 'pointer' }}>{alvara.numero_alvara}</td>
                                <td>{alvara.egress.nome}</td>
                                <td>{alvara?.responsavel_execucao?.name || 'Sem responsável'}</td>
                                <td>
                                  { alvara.executado == "T" ? <BsFillHandThumbsUpFill color="#080" style={{ fontSize: 30 }} /> : <BsFillHandThumbsDownFill color="#f00" style={{ fontSize: 30 }} /> }
                                </td>
                                {/* <td>
                                  { alvara.registro_seuu == "T" ? <BsFillHandThumbsUpFill color="#080" style={{ fontSize: 30 }} /> : <BsFillHandThumbsDownFill color="#f00" style={{ fontSize: 30 }} /> }
                                </td> */}
                                <td>{ alvara.attachment ? <AiOutlineFilePdf onClick={() => window.open(alvara.attachment.url_file, '_blank')} color="#c7b515" style={{ fontSize: 30, cursor: 'pointer' }} /> : '' }</td>
                                <td>{ alvara.observacao ? <AiFillWarning onClick={() => setAlvara(alvara)} color="#c7b515" style={{ fontSize: 30, cursor: 'pointer' }} /> : '' }</td>
                              </tr>
                            ))}
                          </table>
                        </ContainerTable>
                      )}
                    </Col>
                  </Row>
                </TabPane>

                <TabPane tabId="3">
                  <Row>
                    <Col sm="12">
                      {loading ? (
                        <div style={{
                          display: "flex", justifyContent: "center", alignItems: 'center', height: '50vh',
                        }}
                        >
                          <SpinnerLoading color="#000" size={50} />
                        </div>
                      ) : (
                        <ContainerTable>
                          <table style={{ marginTop: 10 }}>
                            <tr>
                              <th style={{ textAlign: 'center' }}>#</th>
                              <th style={{ textAlign: 'center' }}>Número do Alvará</th>
                              <th style={{ textAlign: 'center' }}>Egresso</th>
                              <th style={{ textAlign: 'center' }}>Fiscal</th>
                              <th style={{ textAlign: 'center' }}>Executado</th>
                              {/* <th style={{ textAlign: 'center' }}>Registro SEUU</th> */}
                              <th style={{ textAlign: 'center' }}>Arquivo</th>
                              <th style={{ textAlign: 'center' }}>Observação</th>
                            </tr>
                            {alvaras.data.filter((item) => item.executado == 'T').map((alvara, index) => (
                              <tr>
                                <td>{index + 1 + (page > 1 ? (page - 1) * 30 : 0)}</td>
                                <td onClick={() => setAlvara(alvara)} style={{ color: '#f00', cursor: 'pointer' }}>{alvara.numero_alvara}</td>
                                <td>{alvara.egress.nome}</td>
                                <td>{alvara?.responsavel_execucao?.name || 'Sem responsável'}</td>
                                <td>
                                  { alvara.executado == "T" ? <BsFillHandThumbsUpFill color="#080" style={{ fontSize: 30 }} /> : <BsFillHandThumbsDownFill color="#f00" style={{ fontSize: 30 }} /> }
                                </td>
                                {/* <td>
                                  { alvara.registro_seuu == "T" ? <BsFillHandThumbsUpFill color="#080" style={{ fontSize: 30 }} /> : <BsFillHandThumbsDownFill color="#f00" style={{ fontSize: 30 }} /> }
                                </td> */}
                                <td>{ alvara.attachment ? <AiOutlineFilePdf onClick={() => window.open(alvara.attachment.url_file, '_blank')} color="#c7b515" style={{ fontSize: 30, cursor: 'pointer' }} /> : '' }</td>
                                <td>{ alvara.observacao ? <AiFillWarning onClick={() => setAlvara(alvara)} color="#c7b515" style={{ fontSize: 30, cursor: 'pointer' }} /> : '' }</td>
                              </tr>
                            ))}
                          </table>
                        </ContainerTable>
                      )}
                    </Col>
                  </Row>
                </TabPane>

              </TabContent>

              {alvaras && alvaras?.data?.length > 0
              && (
              <div style={{ display: "flex", alignItems: 'flex-end', justifyContent: "flex-end" }}>
                <ReactPaginate
                  previousLabel="anterior"
                  nextLabel="próximo"
                  breakLabel="..."
                  breakClassName="break-me"
                  pageCount={alvaras ? alvaras?.meta?.last_page : 0}
                  marginPagesDisplayed={4}
                  pageRangeDisplayed={5}
                  forcePage={page - 1}
                  onPageChange={(page) => { getLicenses(page.selected); }}
                  containerClassName="pagination"
                  activeClassName="active"
                />
              </div>
              )}
            </div>
          </>
        ) : (
          <AlvaraDetail alvara={alvara} setAlvara={setAlvara} />
        )}
    </Container>
  );
}

const ContainerTable = styled.div`
  overflow: auto;
  @media (max-width: 1023px) {
    width: 650px;
  }
  @media (max-width: 768px) {
    width: 600px;
  }

  @media (max-width: 640px) {
    width: 230px;
  }
`;
