import React, { useEffect, useState } from 'react';
import api from '../../../../services/api';
import { Egresso } from '../../../../types/Egresso';

export type SearchEgress ={
  meta:any
  data: Egresso[]
}

export function useGetSearchEgresses() {
  const [egresses, setEgresses] = useState<SearchEgress | null>(null);
  const [load, setLoad] = useState(false);

  async function handleSetOptionsEgrsses(page = 1, search?: string) {
    setLoad(true);
    const { data } = await api.get<SearchEgress>("/egresses", {
      params: {
        page,
        habilidadeOrNameOrAreaOrBairroOrLocalidadeOrMunicipio: search,
      },
    });
    setEgresses(data);

    setLoad(false);
  }
  useEffect(() => {
    (async () => {
      await handleSetOptionsEgrsses();
    })();
  }, []);
  return {
    egresses, handleSetOptionsEgrsses, load,
  };
}
