import React, { useEffect, useState } from 'react';
import { IOnSubmitResponse } from '../../../../components/SubmitResponse/type';
import api from '../../../../services/api';

export function useGetByIdProjeto<T>(id: number) {
  const [load, setLoad] = useState(true);
  const [projetos, setProjetos] = useState< T | null>(null);
  const [error, setError] = useState<IOnSubmitResponse | boolean>(false);

  async function handleProjetos(page = 1) {
    setLoad(true);
    try {
      const { data } = await api.get(`/projects/${id}`, {
        params: {
          page,
          finalizado: "T",
        },
      });
      setProjetos(data);
    } catch (error) {
      setError({
        // @ts-ignore
        message: error.response.data.message,
        status: 'danger',
        title: 'Error',
      });
    }
    setLoad(false);
  }

  useEffect(() => {
    handleProjetos();
  }, []);

  return {
    projetos,
    load,
    error,
    handleProjetos,
  };
}
