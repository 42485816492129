import React from 'react';
import { IoIosDocument } from 'react-icons/io';

type PropsIconDetails = {
  cursor?: boolean
  tooltipMessage?: string
  size?: number
}

export function IconDocument({ cursor, tooltipMessage, size }:PropsIconDetails) {
  return (
    <div data-tip={tooltipMessage || ""}>
      <IoIosDocument
        size={size || 30}
        cursor={cursor ? "pointer" : undefined}
      />
    </div>
  );
}
