import React, { useState } from 'react';
import { AbaFilterButtons, AbaFilterContent } from '../../../../components/develop-nilton-components/AbaFiltro';
import { FatoresEmoAnual } from './FatoresEmoAnual';
import { FatoresEmoTrimestral } from './FatoresEmoTrimestral';

export function FatoresEmo({ tabType }: {tabType: number}) {
  const [tab, setTab] = useState(1);
  return (
    <div className="mt-3">
      <AbaFilterButtons
        buttons={["Anual", "Trimestral"]}
        useStateTab={[tab, setTab]}
      />

      <AbaFilterContent
        tab={tab}
        components={[
          <FatoresEmoAnual tab={tab} tabType={tabType} />,
          <FatoresEmoTrimestral tab={tab} tabType={tabType} />,
        ]}
      />
    </div>
  );
}
