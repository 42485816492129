import React, { useEffect, useState } from 'react';
import { useAuth } from '../../../../hooks/auth';
import api from '../../../../services/api';

export function useRoundsPendentes<T>() {
  const [rounds, setRounds] = useState<T | null>(null);
  const { user } = useAuth();

  async function handleSetRounds(page = 1, search?: string, title?: string) {
    if (title) {
      const { data } = await api.get('/rounds', {
        params: {
          page,
          responsable_id: user?.id,
          name: title,
        },
      });
      setRounds(() => data);
    } else if (!search) {
      const { data } = await api.get('/rounds', {
        params: {
          page,
          responsable_id: user?.id,
          em_homologacao: "F",
          homologado: "null",
        },
      });
      setRounds(data);
    } else {
      const { data } = await api.get('/rounds', {
        params: {
          page,
          responsable_id: user?.id,
          data: search,
        },
      });
      setRounds(data);
    }
  }
  useEffect(() => {
    (async () => {
      await handleSetRounds(1);
    })();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return { rounds, handleSetRounds };
}
