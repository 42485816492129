import React, { useEffect, useState } from 'react';
import api from '../../../../services/api';

export function useCursosPendente<T>() {
  const [pendente, setCursos] = useState<T | null>(null);
  const [load_pendente, setLoad] = useState(false);

  async function handleSetCursosPendente(page = 1, search?: string) {
    setLoad(true);
    const { data } = await api.get('/courses', {
      params: {
        page,
        title: search,
        status: "Pendente",
      },
    });
    if (data.data.length === 0 && page > 1) {
      handleSetCursosPendente(page - 1);
      setCursos(data);
    } else {
      setCursos(data);
    }
    setLoad(false);
  }

  useEffect(() => {
    handleSetCursosPendente();
  }, []);
  return {
    pendente, handleSetCursosPendente, load_pendente,
  };
}
