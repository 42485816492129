import React, { useEffect, useState } from 'react';
import { IconAdd } from '../../../../components/develop-nilton-components/IconAdd';
import { IconDetails } from '../../../../components/develop-nilton-components/IconDetails';
import { IconRemove } from '../../../../components/develop-nilton-components/IconRemove';
import { ModalDetailsEgresse } from '../../../../components/develop-nilton-components/ModalDetailsEgresses';
import { Td, Tr } from '../../../../components/develop-nilton-components/TablePadrão';
import { Tbody } from '../types';

export function TbodyAdicionado({
  egress, handleUpdate, headWidth, index, addEgresses,
}: Tbody) {
  const [isOpenDetails, setIsOpenDetails] = useState(false);

  function handleIsOpenDetails() {
    setIsOpenDetails(!isOpenDetails);
  }

  return (
    <Tr>
      <Td width={headWidth[0]}>{index}</Td>
      <Td width={headWidth[1]}>{egress?.nome}</Td>
      <Td width={headWidth[2]}>
        <div onClick={handleIsOpenDetails}>
          <IconDetails cursor />
        </div>
        <ModalDetailsEgresse
          isOpen={isOpenDetails}
          egresse={egress}
          handleIsOpen={handleIsOpenDetails}
        />
      </Td>
      <Td width={headWidth[2]}>
        <div onClick={() => {
          if (egress?.id) {
            addEgresses(egress?.id);
          }
        }}
        >
          <IconRemove color="text-danger" cursor />
        </div>
      </Td>
    </Tr>
  );
}
