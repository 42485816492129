import React, { useEffect, useState } from 'react';
import api from '../../../../services/api';

export function useGetProjetosConcluidos() {
  const [projetosConcluidos, setProjetos] = useState<any | null>(null);
  const [loadConcluido, setLoad] = useState(false);

  async function handleProjetosConcluidos(page = 1, search?: string) {
    setLoad(true);
    const { data } = await api.get('/projects', {
      params: {
        page,
        nome: search,
        filter: "Finalizado",
      },
    });

    if (data.data.length === 0 && page > 1) {
      handleProjetosConcluidos(page - 1);
    }

    setProjetos(data);
    setLoad(false);
  }

  useEffect(() => {
    handleProjetosConcluidos();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return {
    projetosConcluidos, handleProjetosConcluidos, loadConcluido,
  };
}
