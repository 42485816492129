import React, { useState } from 'react';
// @ts-ignore
import { CKEditor } from '@ckeditor/ckeditor5-react';
// @ts-ignore
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { useHistory } from 'react-router-dom';
import { TypeAbasContainerComponents } from '../types';
import api from '../../../../services/api';
import { SpinnerLoading } from '../../../../components/SpinnerLoading';

export function Observação({ getValues, setValue }:TypeAbasContainerComponents) {
  const [text, setText] = useState(getValues().observacoes);
  const [load, setLoad] = useState(false);

  const history = useHistory();

  async function handleFinalizar() {
    setLoad(true);
    if (text !== "") {
      await api.put(`/psychologist/${getValues().id}`, {
        finalizado: "T",
        muda_humor_facilmente: Number(getValues("muda_humor_facilmente")),
        fica_nervoso_constantemente: Number(getValues("fica_nervoso_constantemente")),
        fica_triste_euforico: Number(getValues("fica_triste_euforico")),
        fica_raiva_hostil: Number(getValues("fica_raiva_hostil")),
        pensamentos_suicidas: Number(getValues("pensamentos_suicidas")),
        sente_cansaco: Number(getValues("sente_cansaco")),
        consegue_concentrar: Number(getValues("consegue_concentrar")),
        fadiga_matinal: Number(getValues("fadiga_matinal")),
        manter_atencao_tarefas_especificas: Number(getValues("manter_atencao_tarefas_especificas")),
        dificuldade_tomar_decisao: Number(getValues("dificuldade_tomar_decisao")),
        queixas: getValues("queixas"),
        observacoes: text,
      });
    } else {
      await api.put(`/psychologist/${getValues().id}`, {
        finalizado: "T",
        muda_humor_facilmente: Number(getValues("muda_humor_facilmente")),
        fica_nervoso_constantemente: Number(getValues("fica_nervoso_constantemente")),
        fica_triste_euforico: Number(getValues("fica_triste_euforico")),
        fica_raiva_hostil: Number(getValues("fica_raiva_hostil")),
        pensamentos_suicidas: Number(getValues("pensamentos_suicidas")),
        sente_cansaco: Number(getValues("sente_cansaco")),
        consegue_concentrar: Number(getValues("consegue_concentrar")),
        fadiga_matinal: Number(getValues("fadiga_matinal")),
        manter_atencao_tarefas_especificas: Number(getValues("manter_atencao_tarefas_especificas")),
        dificuldade_tomar_decisao: Number(getValues("dificuldade_tomar_decisao")),
        queixas: getValues("queixas"),
      });
    }
    history.push({ pathname: '/perfil' });
  }

  return (
    <div className="w-75">
      <CKEditor
        editor={ClassicEditor}
        // onReady={(editor: any) => {
        //   // You can store the "editor" and use when it is needed.
        //   console.log(editor);
        // }}
        data={text || ""}
        onChange={(event: any, editor: { getData: () => any; }) => {
          const data = editor.getData();
          setText(data);
        }}
      />
      <div className="d-flex justify-content-center mt-5 mb-5">
        <div
          className="d-flex justify-content-center align-items-center m-5"
          onClick={handleFinalizar}
        >
          <button className="btn btn-primary btn-lg">
            {load ? <SpinnerLoading /> : 'Finalizar'}
          </button>
        </div>
      </div>
    </div>
  );
}
