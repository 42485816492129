import React, { useEffect, useState } from "react";
import { useGet_relatorio_psicologico_indicadores } from "./useGet_relatorio_psicologico_indicadores";

export function useGet_data_set_observaçao() {
  const { handle_relatorio_psicologico_indicadores } = useGet_relatorio_psicologico_indicadores();
  const [data_set_observacao, set_data_set_observacao] = useState<any>(null);

  async function handle_onChange_observacao(year: string, trimestre?: string) {
    const observacao = await handle_relatorio_psicologico_indicadores(year, "observacao", trimestre);
    set_data_set_observacao(observacao);
  }

  useEffect(() => {
    handle_onChange_observacao('2022');
  }, []);
  return { data_set_observacao, handle_onChange_observacao };
}
