import { addHours, format } from 'date-fns';
import React from 'react';
import { BsPersonFill } from 'react-icons/bs';
import { useHistory } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import { Badge } from '../../../../components/develop-nilton-components/Badge';
import { IconConcluido } from '../../../../components/develop-nilton-components/IconConcluido';
import { IconDetails } from '../../../../components/develop-nilton-components/IconDetails';
import { Td, Tr } from '../../../../components/develop-nilton-components/TablePadrão';

type PropsTbodyTable = {
  headWidth: string[]
  roundsItems: any
  index: number
}

export function TbodyConcluidos({ roundsItems, headWidth, index }:PropsTbodyTable) {
  const history = useHistory();

  function handleHistoryToPageEgressesInTheRounds() {
    history.push({ state: roundsItems, pathname: "/homologar-ronda/egressos" });
  }

  return (
    <Tr>
      <Td width={headWidth[0]}>
        {index}
      </Td>
      <Td width={headWidth[1]}>
        {roundsItems.responsavel.name}
      </Td>
      <Td width={headWidth[2]}>
        {roundsItems.title}
      </Td>
      <Td width={headWidth[3]}>
        {format(addHours(new Date(roundsItems.data_ronda), 20), "dd/MM/yyyy")}
      </Td>
      <Td width={headWidth[4]}>
        <Badge type="success">Concluído</Badge>
      </Td>
      <Td width={headWidth[5]}>
        <div
          onClick={handleHistoryToPageEgressesInTheRounds}
          data-tip="Visualizar egressos"
        >
          <IconDetails size={30} cursor />
        </div>
        <ReactTooltip />
      </Td>
    </Tr>
  );
}
