import { eachMonthOfInterval, eachYearOfInterval, format } from "date-fns";

const year_options = eachYearOfInterval({ start: new Date(0), end: new Date() }).map((year) => format(new Date(year), "yyyy")).reverse();
const month_options = eachMonthOfInterval({ start: new Date().setMonth(0), end: new Date().setMonth(11) }).map((date) => ({ label: new Date(date).toLocaleDateString("pt-BR", { month: "long" }), value: format(date, "MM") }));

const trimestral_options = [
  { value: "01-03", label: "Primeiro trimestre" },
  { value: "03-06", label: "Segundo trimestre" },
  { value: "06-09", label: "Terceiro trimestre" },
  { value: "09-12", label: "Quarto trimestre" },
];

function handleTrimestre() {
  if (Number(format(new Date(), "MM")) < 3) {
    return "01-03";
  }
  if (Number(format(new Date(), "MM")) < 6) {
    return "03-06";
  }
  if (Number(format(new Date(), "MM")) < 9) {
    return "06-09";
  }
  if (Number(format(new Date(), "MM")) < 12) {
    return "09-12";
  }
  return "";
}

const moneyMask = (value: string) => {
  value = value.replace('.', '').replace(',', '').replace(/\D/g, '');

  const options = { minimumFractionDigits: 2 };
  const result = new Intl.NumberFormat('pt-BR', options).format(
    parseFloat(value) / 100,
  );

  console.log(result);

  return `R$ ${result}`;
};

export {
  year_options, trimestral_options, handleTrimestre, month_options, moneyMask,
};
