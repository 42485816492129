import { format } from 'date-fns';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import ReactTooltip from 'react-tooltip';
import { Badge } from '../../../../components/develop-nilton-components/Badge';
import { IconAdd } from '../../../../components/develop-nilton-components/IconAdd';
import { IconDetails } from '../../../../components/develop-nilton-components/IconDetails';
import { IconEdit } from '../../../../components/develop-nilton-components/IconEdit';
import { Td, Tr } from '../../../../components/develop-nilton-components/TablePadrão';
import { SpinnerLoading } from '../../../../components/SpinnerLoading';
import api from '../../../../services/api';
import { PropsTbody } from '../types';
import { ModalTurmas } from './ModalTurmas';

export function TbodyAnteriores({
  curso, headWidth, index, handleUpdate, meta,
}: PropsTbody) {
  const history = useHistory();
  const [loadRetomar, setLoadRetomar] = useState(false);
  const [isOpenModalDetalhes, setIsOpenModalDetalhes] = useState(false);

  function handleIsOpen() {
    setIsOpenModalDetalhes(!isOpenModalDetalhes);
  }

  function handleRedirectEdit() {
    history.push({
      pathname: "/gerir-turmas/editar",
      state: {
        id: curso.id,
        data_fim: format(new Date(curso.data_fim), "yyyy-MM-dd"),
        data_inicio: format(new Date(curso.data_inicio), "yyyy-MM-dd"),
        coordenador: curso.coordenador,
        projetos: curso.projetos,
        egresses: curso.egressos.map((item) => ({ id: item.egress_id })),
        title: curso.title,
      },
    });
  }

  async function retomar(id: number) {
    setLoadRetomar(true);
    try {
      await api.put(`/courses/${id}`, { turma_finalizada: "F" });
      toast.success("Curso retomado com sucesso");
      await handleUpdate(meta.current_page);
    } catch (error) {
      // @ts-ignore
      toast.success(error.response.data.message);
    }
    setLoadRetomar(false);
  }
  return (
    <Tr>
      <Td width={headWidth[0]}>{index}</Td>
      <Td width={headWidth[1]}>{curso.title}</Td>
      <Td width={headWidth[2]}>
        <div>
          <Badge type="success">{curso.situacao}</Badge>
        </div>
      </Td>
      <Td width={headWidth[3]}>
        <div onClick={handleIsOpen}>
          <IconDetails cursor tooltipMessage="Detalhes do curso" />
        </div>
        <ModalTurmas
          handleIsOpen={handleIsOpen}
          isOpen={isOpenModalDetalhes}
          turmas={curso}
        />
      </Td>
      {/* <Td width={headWidth[3]}>
        <div className="d-flex justify-content-between align-items-center w-50">
          <div onClick={handleRedirectEdit}>
            <IconEdit color="text-warning" cursor tooltipMessage="Editar" />
          </div>
          {loadRetomar ? <SpinnerLoading /> : (
            <div onClick={() => { retomar(curso.id); }}>
              <IconAdd
                color="text-primary"
                cursor
                tooltipMessage="Retomar curso"
              />
            </div>
          )}
        </div>
        <ReactTooltip />
      </Td> */}
    </Tr>
  );
}
