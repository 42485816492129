import React, { useState } from 'react';
import { Chart } from 'react-chartjs-2';
import { ModalGraficoAnual } from '../../pages/Psicologo/PerfilPsicologicoDoEgresso/components/ModalGraficoAnual';
import api from '../../services/api';

export type IIndicadoresGraficosAcompanhamento = {
  data_sets: {
    titleGraphic: string
    data: {
      labels: string[]
      datasets: {
        label: string
        data: any[]
        borderColor: string
        backgroundColor: string
      }[];
    }
  }[];
};

export function IndicadoresGraficosAcompanhamento({
  data_sets,
}:IIndicadoresGraficosAcompanhamento) {
  // const [isOpenModalGraficoAnual, setIsOpenModalGraficoAnual] = useState(false);
  // function handleModalGraficoAnual() {
  //   setIsOpenModalGraficoAnual((prev) => !prev);
  // }
  // const [data_set_anul, set_data_set_anual] = useState(null);
  // const [titleGraficoAnual, setTitleGraficoAnual] = useState<string | null>("");
  // async function handleGraficoAnual(key: string, titleGrafico: string | null) {
  //   set_data_set_anual(null);
  //   handleModalGraficoAnual();
  //   const { data } = await api.get(pathApi, {
  //     params: {
  //       year: new Date().getFullYear(),
  //       [key]: true,
  //       egress_id: egress.id,
  //     },
  //   });
  //   setTitleGraficoAnual(titleGrafico);
  //   set_data_set_anual(data);
  // }

  return (
    <div>
      <div className="d-flex  flex-column justify-content-center align-items-center" style={{ width: "100%" }}>
        {data_sets.map((data_set) => (
          <div style={{ width: '100%' }}>
            <h2 className="border-bottom border-secondary pb-3 mt-2">{data_set.titleGraphic}</h2>
            <Chart
              data={data_set.data}
              type="bar"
              options={{
                plugins: { legend: { display: false } },
                scales: {
                  y: {
                    suggestedMin: 0,
                    suggestedMax: 10,
                  },
                },
              }}
            />
          </div>
        ))}
      </div>
      {/* <ModalGraficoAnual
        data_set={data_set_anul}
        handleOpenModalGraficoAnual={handleModalGraficoAnual}
        isOpenModalGraficoAnual={isOpenModalGraficoAnual}
        titleGrafico={titleGraficoAnual}
      /> */}
    </div>
  );
}
