import { useMutation, useQueryClient } from "react-query";
import { IFetchParams } from '../../types/IFetchParams';

export function useUpdateEgresses(onSubmit: () => Promise<void>, { search, page }: IFetchParams) {
  const queryClient = useQueryClient();
  const { mutate } = useMutation(onSubmit, {
    onSuccess: () => {
      queryClient.invalidateQueries(['egresses', page, search]);
    },
  });

  return { mutate, isFetching: queryClient.isFetching({ queryKey: ['egresses'] }) === 1 };
}
