import React from 'react';
import { Link } from 'react-router-dom';
import {
  FaTimes,
} from 'react-icons/fa';
import { Container } from './MobileSidebar.styled';
import { useSidebarList, useSidebarListMaster } from './sidebarList';
import NavBar from '../NavBar/NavBar';

const MobileSideBar = ({ active }: any) => {
  const closeSidebar = () => {
    active(false);
  };

  const permission = useSidebarList();
  const permissionMaster = useSidebarListMaster();

  const [selected, setSelected] = React.useState<any>(
    {
      icon: '/assets/dashboard.png',
      title: 'Dashboard',
      to: '/dashboard',
    },
  );

  return (
    <Container left={active}>
      <FaTimes
        style={{
          marginTop: 122,
          marginLeft: 10,
        }}
        size={20}
        onClick={closeSidebar}
      />
      <div className="menu-section1">
        {/* <Link style={{ textDecoration: 'none' }} to="/dashboard">
          <div className="title-section">
            <img src="/assets/social_icon.png" alt="icone-albergado" />
            E-Albergado
          </div>
        </Link> */}
        <div style={{ marginTop: 20 }} />
        {permissionMaster ? permissionMaster?.functions.map((item, i) => (
          <Link key={item.title} style={{ textDecoration: 'none' }} to={item.to}>
            <button className="list-menu1" onClick={() => setSelected(item)} style={{ background: selected?.title == item.title ? 'rgba(0,62,93, 0.3)' : '#fff' }}>
              {selected?.title == item.title && (
              <div style={{
                position: 'relative', right: 0, background: 'rgba(0,62,93)', width: 10, height: '100%',
              }}
              />
              )}
              <div style={{
                paddingLeft: selected?.title == item.title ? 10 : 20, display: "flex", flexDirection: 'row', alignItems: 'center',
              }}
              >
                <img src={item.icon} alt={item.title} />
                <h1 className="text1">{item.title}</h1>
              </div>
            </button>
          </Link>
        )) : permission.functions.map((item, i) => (
          <Link key={item.title} style={{ textDecoration: 'none' }} to={item.to}>
            <button className="list-menu1" onClick={() => setSelected(item)} style={{ background: selected?.title == item.title ? 'rgba(0,62,93, 0.3)' : '#fff' }}>
              {selected?.title == item.title && (
              <div style={{
                position: 'relative', right: 0, background: 'rgba(0,62,93)', width: 10, height: '100%',
              }}
              />
              )}
              <div style={{
                paddingLeft: selected?.title == item.title ? 10 : 20, display: "flex", flexDirection: 'row', alignItems: 'center',
              }}
              >
                <img src={item.icon} alt={item.title} />
                <h1 className="text1">{item.title}</h1>
              </div>
            </button>
          </Link>
        ))}
        <div className="title-section1" />
      </div>
    </Container>
  );
};

export default MobileSideBar;
