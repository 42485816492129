/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import ReactModal from 'react-modal';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Label,
  Input,
} from 'reactstrap';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import { DatePicker } from 'reactstrap-date-picker';
import { format } from 'date-fns';
import { useFormValidate } from '../../../../components/develop-nilton-components/hooks/useFormValidate';
import { SpinnerLoading } from '../../../../components/SpinnerLoading';
import api from '../../../../services/api';
import { useGetOcurrences } from '../hooks/useGetOcurrences';
import { ModalImage } from '../../../../components/modalImage/modalImage';

type PropsAddEgressesInTheRounds = {
  round_egresses_egressos: any;
  egresse: any;
  handleSetIsOpen: [boolean, (boolean: boolean) => void];
  handleUpdate(page?: number): Promise<void> | void;
};

export function ModalHistoryComprovanteFigmaEditable({
  round_egresses_egressos,
  handleSetIsOpen,
  egresse,
  handleUpdate,
}: PropsAddEgressesInTheRounds) {
  const { ocurrences } = useGetOcurrences();
  const [comprovante, setComprovante] = useState<File | null>(null);
  const [load, setLoad] = useState(false);
  const [isOpen, setIsOpen] = handleSetIsOpen;
  const data = new Date();
  const dataAtual = data.getDate();
  const [dataExec, setDataExec] = useState('');
  const [timeExec, setTimeExec] = useState('');
  const [ocorrencia, setOcorrencia] = useState('');
  const [observacao, setObservacao] = useState('');
  const [isOpenImage, setIsOpenImage] = useState(false);
  function handleOpenImage() {
    setIsOpenImage(!isOpenImage);
  }
  const { register, formState, reset } = useForm({
    defaultValues: {
      comprovante: round_egresses_egressos?.comprovante,
      observacao: round_egresses_egressos?.observacao,
      ocorrencia_id: round_egresses_egressos?.ocorrencia_id,
    },
  });

  // erros
  const [dataExecError, setDataExecError] = useState('');
  const [timeExecError, setTimeExecError] = useState('');
  const [ocorrenciaError, setOcorrenciaError] = useState('');

  useEffect(() => {
    try {
      setOcorrencia(round_egresses_egressos?.ocorrencia_id);
      // setComprovante(round_egresses_egressos?.comprovante?.url_file);
      setDataExec(round_egresses_egressos?.data_ocorrencia);
      setTimeExec(
        `${String(
          new Date(round_egresses_egressos?.data_ocorrencia).getHours(),
        ).padStart(2, '0')}:${String(
          new Date(round_egresses_egressos?.data_ocorrencia).getMinutes(),
        ).padStart(2, '0')}`,
      );
      setObservacao(round_egresses_egressos?.observacao);
    } catch (error) {
      console.log(error);
    }
  }, [egresse, isOpen]);
  function handleSetComprovante(e: any) {
    setComprovante(e.target.files[0]);
  }

  async function onSubmit() {
    if (ocorrencia === '') {
      setOcorrenciaError('Campo Obrigatório');
    } else {
      setOcorrenciaError('');
    }
    if (dataExec === '') {
      setDataExecError('Campo Obrigatório');
    } else {
      setDataExecError('');
    }
    if (timeExec === '') {
      setTimeExecError('Campo Obrigatório');
    } else {
      setTimeExecError('');
    }
    if (ocorrencia !== '' && dataExec !== '' && timeExec !== '') {
      setLoad(true);
      try {
        const formData = new FormData();
        const dataExecAux = new Date(dataExec);
        dataExecAux.setHours(
          Number(timeExec.split(':')[0]),
          Number(timeExec.split(':')[1]),
        );

        if (comprovante) {
          // if (comprovante.length > 0) {

          formData.append('comprovante', comprovante);
          // }
        }

        // if (ocorrencia === '' && observacao !== '') {
        //   console.log('teste');
        //   formData.append('data', JSON.stringify({}));
        // } else {
        formData.append(
          'data',
          JSON.stringify({
            ocorrencia_id: ocorrencia,
            data_ocorrencia: dataExecAux,
            observacao,
          }),
        );
        // }

        await api.put(
          `/rounds_egresses/${round_egresses_egressos.id}`,
          formData,
        );
        await handleUpdate();
        toast('atualizado com sucesso.', { type: 'success' });
        toggle();
      } catch (error) {
        console.log(error);
        // @ts-ignore
        toast(error.response?.data.message, { type: 'error' });
      }
      setLoad(false);
    }
  }

  const toggle = () => {
    setIsOpen(!isOpen);
    setComprovante(null);
    setDataExec('');
    setDataExecError('');
    setTimeExecError('');
    setOcorrenciaError('');
    setTimeExec('');
    reset();
  };

  // useEffect(() => {
  //   console.log(round_egresses_egressos);
  // }, []);

  const formValues = ['comprovante', 'ocorrencia_id'];
  const formStateErrors = [
    formState.errors.comprovante,
    formState.errors.ocorrencia_id,
  ];
  function handleChange(v: string, f: string): void {
    setDataExec(v);
  }
  function handleChangeOcorrencia(e): void {
    setOcorrencia(e.target.value);

    if (dataExec === '') {
      setDataExec(new Date().toISOString());
    }
    if (timeExec === '') {
      setTimeExec(
        `${String(new Date().getHours()).padStart(2, '0')}:${String(
          new Date().getMinutes(),
        ).padStart(2, '0')}`,
      );
    }
  }

  const { formIsValidated } = useFormValidate({ formValues, formStateErrors });
  return (
    <Modal
      isOpen={isOpen}
      ariaHideApp={false}
      shouldCloseOnOverlayClick
      toggle={toggle}
      size="lg"
    >
      <ModalHeader toggle={toggle}>Comprovante de Ronda</ModalHeader>
      <Form className={formIsValidated} noValidate>
        <ModalBody>
          <div style={{ marginTop: 10 }}>
            <img
              style={{ cursor: 'pointer' }}
              onClick={handleOpenImage}
              src={
                round_egresses_egressos?.egresso?.avatar?.url_file
                  ? round_egresses_egressos?.egresso?.avatar?.url_file
                  : 'https://static.vecteezy.com/ti/vetor-gratis/p1/1840618-imagem-perfil-icone-masculino-icone-humano-ou-pessoa-sinal-e-simbolo-gr%C3%A1tis-vetor.jpg'
              }
              width={150}
              height={150}
            />
          </div>
          <div style={{ marginTop: 10 }}>
            <Label style={{ fontSize: 16, fontWeight: 'bold' }}>Nome</Label>
            <Input disabled placeholder={egresse?.nome} />
          </div>

          <div style={{ marginTop: 10 }}>
            <Label style={{ fontSize: 16, fontWeight: 'bold' }}>Endereço</Label>
            <Input
              disabled
              placeholder={`${round_egresses_egressos?.address?.endereco}, nº ${round_egresses_egressos?.address?.numero_endereco}, ${round_egresses_egressos?.address?.bairro}, ${round_egresses_egressos?.address?.cep}, ${round_egresses_egressos?.address?.municipio} - ${round_egresses_egressos?.address?.estado}`}
            />
          </div>
          <div style={{ marginTop: 10 }}>
            <Label style={{ fontSize: 16, fontWeight: 'bold' }}>
              Comprovante de fiscalização
            </Label>
          </div>

          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              borderRadius: 10,
              flexDirection: 'row',
              alignItems: 'center',
              border: 'solid 1px #ccd5e0',
            }}
          >
            <div
              style={{
                height: !comprovante ? '150px' : undefined,
                border: 'solid 1px #ccd5e0',
                background: '#C8C8C8',
                borderRadius: '10px',
                width: '150px',
              }}
            >
              {/* {(!round_egresses_egressos?.comprovante?.url_file && !comprovante) && <Center><SpinnerLoading size={70} color="black" /></Center>} */}
              {comprovante && (
                <Comprovante
                  src={URL.createObjectURL(comprovante)}
                  alt="Comprovante"
                  height={250}
                />
              )}
              {!comprovante &&
                round_egresses_egressos?.comprovante?.url_file && (
                  <Comprovante
                    onClick={() =>
                      window.open(
                        round_egresses_egressos?.comprovante?.url_file,
                        'blank',
                      )
                    }
                    height={250}
                    src={round_egresses_egressos?.comprovante?.url_file}
                    alt="Comprovante"
                  />
                )}
            </div>
            <div>
              <div>
                <InputFile htmlFor="formFile" style={{ padding: 10 }}>
                  Clique aqui para alterar o comprovante
                  <input
                    type="file"
                    id="formFile"
                    style={{ display: 'none' }}
                    // required
                    // {...register('comprovante', {
                    //   onChange: handleSetComprovante,
                    // })}
                    onChange={handleSetComprovante}
                  />
                </InputFile>
              </div>
              <span
                onClick={() =>
                  round_egresses_egressos?.comprovante?.name &&
                  window.open(
                    round_egresses_egressos?.comprovante?.url_file,
                    'blank',
                  )
                }
                style={{
                  cursor: 'pointer',
                  alignItems: 'center',
                  color: '#0D6EFD',
                }}
              >
                {round_egresses_egressos?.comprovante?.name || 'Sem anexo'}
              </span>
            </div>

            <div
              style={{
                color: '#666666',
                alignItems: 'center',
                marginRight: 20,
              }}
            >
              {format(
                new Date(round_egresses_egressos?.data_ocorrencia),
                'dd/MM/yyyy',
              )}
            </div>
          </div>
          <div style={{ marginTop: 10 }}>
            <Label style={{ fontSize: 16, fontWeight: 'bold' }}>
              Selecione uma das opções abaixo *
            </Label>
            <WrapperSelect>
              <select
                className="form-select"
                required
                onChange={handleChangeOcorrencia}
                value={ocorrencia}
              >
                <option value="">Escolha</option>
                {ocurrences?.map(item => (
                  <option key={item.value} value={item.value}>
                    {item.label}
                  </option>
                ))}
              </select>
              {ocorrenciaError && (
                <span style={{ color: '#F66064' }}>{ocorrenciaError}</span>
              )}
            </WrapperSelect>
          </div>
          <div className="row" style={{ marginTop: 10 }}>
            <div className="col-sm">
              <Label style={{ fontSize: 16, fontWeight: 'bold' }}>
                Selecione a data da execução da fiscalização
              </Label>

              <DatePicker
                monthLabels={[
                  'Janeiro',
                  'Fevereiro',
                  'Março',
                  'Abril',
                  'Maio',
                  'Junho',
                  'Julho',
                  'Agosto',
                  'Setembro',
                  'Outubro',
                  'Novembro',
                  'Dezembro',
                ]}
                className="form-select"
                dayLabels={['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb']}
                // {...register('data_ocorrencia', { required: true })}
                // required
                name="data_ocorrencia"
                register={register}
                onChange={(v, f) => handleChange(v, f)}
                showClearButton={false}
                value={dataExec}
              />
              {/* <Input
                style={{ display: 'none' }}
                {...register('data_ocorrencia', { required: true })}
                required
                value={dataExec}
              /> */}
              {dataExecError && (
                <span style={{ color: '#F66064' }}>{dataExecError}</span>
              )}
              <div className="invalid-feedback">Campo obrigatório</div>
            </div>
            <div className="col">
              <Label style={{ fontSize: 16, fontWeight: 'bold' }}>
                Horário da execução da fiscalização
              </Label>
              <Input
                required
                placeholder="Insira o horário de início do evento"
                type="time"
                // value={timeExec}
                // {...register('hora_ocorrencia', { required: true })}
                value={timeExec}
                onChange={e => {
                  setTimeExec(e.target.value);
                }}
              />
              {timeExecError && (
                <span style={{ color: '#F66064' }}>{timeExecError}</span>
              )}
            </div>
          </div>

          <div style={{ marginTop: 10 }}>
            <Label style={{ fontSize: 16, fontWeight: 'bold' }}>
              Observações
            </Label>
            <Input
              value={observacao}
              onChange={e => setObservacao(e.target.value)}
              rows={5}
              type="textarea"
              placeholder="Digite o problema que impediu de realizar a fiscalização"
            />
          </div>
          {/* <div style={{ marginTop: 10 }} />
          <div style={{ marginTop: 10 }}>
            <Label style={{ fontSize: 16, fontWeight: 'bold' }}>
              Observações
            </Label>
            <Textarea
              value={observacao}
              onChange={e => setObservacao(e.target.value)}
              rows={5}
              placeholder="Digite o problema que impediu de realizar a fiscalização"
              className="form-control"
            />
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              marginTop: 10,
            }}
          >
            <div style={{ width: '48%' }}>
              <Label style={{ fontSize: 16, fontWeight: 'bold' }}>
                Ocorrência
              </Label>
              <Input
                type="text"
                className="form-control"
                disabled
                value={round_egresses_egressos?.ocorrencia?.name}
              >

              </Input>
            </div>
            <div style={{ width: '48%' }}>
              <Label style={{ fontSize: 16, fontWeight: 'bold' }}>
                Data e Hora
              </Label>
              <Input
                disabled
                placeholder={`${format(
                  new Date(round_egresses_egressos?.data_ocorrencia),
                  'dd/MM/yyyy',
                )} às ${format(
                  new Date(round_egresses_egressos?.data_ocorrencia),
                  'HH:mm',
                )}`}
              />
            </div>
          </div>

          <div style={{ marginTop: 10 }}>
            <Label style={{ fontSize: 16, fontWeight: 'bold' }}>
              Observações
            </Label>
            <Input
              disabled
              rows={5}
              type="textarea"
              placeholder={round_egresses_egressos?.observacao}
            />
          </div> */}
        </ModalBody>
        <ModalFooter className="d-flex justify-content-between">
          <Button color="secondary" onClick={toggle}>
            voltar
          </Button>
          <WrapperButton>
            <Button
              style={{ background: 'rgb(104, 153, 109)' }}
              disabled={load}
              className="btn btn-success"
              onClick={onSubmit}
            >
              {load && <SpinnerLoading />}
              {!load && 'Atualizar'}
            </Button>
          </WrapperButton>
        </ModalFooter>
      </Form>
      <ModalImage
        image={round_egresses_egressos?.egresso?.avatar?.url_file}
        isOpen={isOpenImage}
        handleIsOpen={handleOpenImage}
      />
    </Modal>
  );
}

type PropsMessageError = {
  isValid: boolean;
};

const MessageError = styled.p<PropsMessageError>`
  display: ${props => (props.isValid ? '' : 'none')};
  font-size: 12px;
  color: red;
  position: absolute;
`;

const styleModal = {
  content: {
    width: '80%',
    margin: '0 auto',
  },
  overlay: {
    background: 'rgba(1,1,1,.5)',
  },
};

const Center = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 30px;
`;

const WrapperButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const WrapperSelect = styled.div`
  select {
    width: 100%;
    height: 40px;
    font-size: 18px;
    color: black;
  }
`;

const H2 = styled.h2`
  font-size: 20px;
`;

const InputFile = styled.label`
  height: 46px;
  width: 60%;
  left: 163px;
  top: 830px;
  border-radius: 8px;
  background: #c8c8c8;
  /* position: relative; */

  display: flex;
  justify-content: center;
  align-items: center;

  cursor: pointer;

  input {
    font-size: 20px;
    font-weight: 400;
    line-height: 23px;
  }
`;

const Textarea = styled.textarea`
  min-height: 150px;
  width: 100%;
  border-radius: 17px;
  border: 1px solid #000000;

  padding: 10px;
`;

const Comprovante = styled.img`
  width: 150px;
  height: 150px;
  border-radius: 10px;
  cursor: pointer;
`;
