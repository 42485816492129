import React from 'react';
import { useTable, useGlobalFilter, useSortBy } from 'react-table';
import { Table, Input } from 'reactstrap';
import styled from 'styled-components';
import { useEgresses } from '../../../hooks/Egresses';
import { Loading } from "../Loading";

export type TableProps = {
  data: any;
  isEgresse?: boolean
};

export function TableComponent(props: TableProps) {
  const { data, isEgresse } = props;

  const {
    habilitado, handleHabilitado, load,
  } = useEgresses();

  const dataMemo = React.useMemo(() => [...data], [data]);

  const columns = React.useMemo(
    () => (data[0]
      ? Object.keys(data[0])
        ?.filter((key) => key !== 'rating')
        ?.map((key) => ({ Header: key, accessor: key }))
      : isEgresse ? [
        {
          Header: "Nome",
          accessor: "Nome",
        },
        {
          Header: "Status",
          accessor: "Status",
        },
        {
          Header: "Opções",
          accessor: "Opções",
        },
      ] : []),
    [data],
  );

  // @ts-ignore
  const tableInstance = useTable?.({ columns, data: dataMemo }, useGlobalFilter, useSortBy);

  const {
    getTableProps, getTableBodyProps, headerGroups, rows, prepareRow,
  } = tableInstance;

  const isEven = (index: number) => index % 2 === 0;

  return (
    <Container>
      <Table {...getTableProps()} striped responsive>
        <thead>
          {headerGroups.map((headerGroup, i) => (
            <tr
              {...headerGroup.getHeaderGroupProps()}
              key={i}
              // className="text-center"
            >
              {headerGroup.headers.map((column, i) => (
                <>
                  {column.render('Header') === 'Status' && isEgresse ? (
                    <th
                      key={i}
                      className="p-2"
                      colSpan={1}
                      role="columnheader"
                      title=""
                    >
                      {column.render('Header') === 'Status' ? (
                        <Input
                          value={habilitado || ""}
                          onChange={(e) => handleHabilitado((e.target.value === '') ? undefined : e.target.value)}
                          style={{
                            /* border: 'none',  */borderColor: '#ced4da', boxShadow: "none", cursor: "pointer", fontWeight: 'bold', height: 40, marginRight: 5, width: 150,
                          }}
                          type="select"
                          name="status"
                          id="status"
                        >
                          <option value="">Status</option>
                          <option value="T">Habilitado</option>
                          <option value="F">Desabilitado</option>
                        </Input>
                      ) : column.render('Header')}
                    </th>
                  ) : (
                    <th
                      {
                      // @ts-ignore
                      ...column.getHeaderProps(column.getSortByToggleProps())
                      }
                      style={{ cursor: 'pointer', verticalAlign: 'middle', width: column.render('Header') === 'Nome' && isEgresse ? '50%' : undefined }}
                      title=""
                      key={i}
                      className="p-2"
                    >
                      {column.render('Header')}

                      {
                        // @ts-ignore
                        column.isSorted ? (column.isSortedDesc ? "▼" : "▲") : ""
                      }
                    </th>
                  )}
                </>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {!isEgresse ? (
            <>
              {rows.map((row, i) => {
                prepareRow(row);
                return (
                  <tr
                    {...row.getRowProps()}
                    key={i}
                    className={`${isEven(i) ? 'bg-gray' : 'bg-white'}`}
                    style={{ background: isEven(i) ? 'rgba(217, 217, 217, 0.4)' : '#fff' }}
                  >
                    {row.cells.map((cell, i) => (
                      <td
                        {...cell.getCellProps()}
                        key={i}
                        className="p-2 text-[10px] md:text-base"
                      >
                        {cell.render('Cell')}
                      </td>
                    ))}
                  </tr>
                );
              })}
            </>
          ) : (
            <>
              {!load && rows && (
              <>
                {rows.map((row, i) => {
                  prepareRow(row);
                  return (
                    <tr
                      {...row.getRowProps()}
                      key={i}
                      className={`${isEven(i) ? 'bg-gray' : 'bg-white'}`}
                      style={{ background: isEven(i) ? 'rgba(217, 217, 217, 0.4)' : '#fff' }}
                    >
                      {row.cells.map((cell, i) => (
                        <td
                          {...cell.getCellProps()}
                          key={i}
                          className="p-2 text-[10px] md:text-base"
                        >
                          {cell.render('Cell')}
                        </td>
                      ))}
                    </tr>
                  );
                })}
              </>
              )}
            </>
          )}
        </tbody>
      </Table>

      {load && isEgresse && rows && (
      <>
        <div style={{ display: "flex", justifyContent: 'center' }}><Loading /></div>
      </>
      )}
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
`;
