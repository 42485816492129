import React, { useState } from 'react';
import { AbaFilterContent } from '../../../components/develop-nilton-components/AbaFiltro';
import { SectionHeader } from '../../../components/SectionHeader';
import { AbaTabs } from './components/AbaTabs';
import { FatoresEmo } from './components/FatoresEmo';
import { FatoresFis } from './components/FatoresFis';
import { FatoresInt } from './components/FatoresInt';
import { Observação } from './components/Observação';
import { Queixas } from './components/Queixas';

export function IndicadoresPsicopedagogo() {
  const [tab, setTab] = useState(1);

  return (
    <div className="w-100">
      <SectionHeader subtitle="Indicadores Gráficos" />

      <AbaTabs
        abas={[" Fatores Intelectuais", "Fatores Emocionais", "Fatores Físicos", "Queixa", "Observações"]}
        states={[tab, setTab]}
      />

      <AbaFilterContent
        tab={tab}
        components={[
          <FatoresInt tabType={tab} />,
          <FatoresEmo tabType={tab} />,
          <FatoresFis tabType={tab} />,
          <Queixas tabType={tab} />,
          <Observação tabType={tab} />,
        ]}
      />
    </div>
  );
}
