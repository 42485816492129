import React from 'react';
import { useLocation } from 'react-router-dom';
import { TablePadrão } from '../../../../components/develop-nilton-components/TablePadrão';
import { SectionHeader } from '../../../../components/SectionHeader';
import { TbodyPageEgresses } from './TbodyPageEgresses';

function PageEgressesFiscal() {
  const headWidth = ["10%", "50%", "50%", "50%"];
  const { state } = useLocation<any>();
  return (
    <div style={{ width: "100%" }}>
      <SectionHeader subtitle={`Ronda: ${state.title}`} backPage="/cadastrar-ronda" />
      <TablePadrão thead={["#", "Nome", "Detalhes", "Comprovante"]} headWidth={headWidth}>
        {state.egressos.map((item:any, i:number) => (
          <TbodyPageEgresses
            key={i}
            index={i + 1}
            headWidth={headWidth}
            item={item}
          />
        ))}
      </TablePadrão>
    </div>
  );
}

export default PageEgressesFiscal;
