import React from 'react';
import styled from 'styled-components';

type IAbaTabs = {
  abas: string[]
  states: [number, React.Dispatch<React.SetStateAction<number>>]
}

export function AbaTabs({ abas, states }:IAbaTabs) {
  const [tab, setTab] = states;

  function handleSetTab(index: number) {
    setTab(index);
  }

  return (
    <div>
      {abas.map((aba, i) => (
        <Button
          className={`btn ${tab === (i + 1) && "btn-primary"} mr-2`}
          onClick={() => handleSetTab(i + 1)}
        >
          {aba}
        </Button>
      ))}
    </div>
  );
}

const Button = styled.button`
  :focus {
    box-shadow: 0 0 0 0;
    outline: 0;
  }
`;
