import React, { ChangeEvent, ChangeEventHandler, useState } from 'react';
import styled from 'styled-components';

import { SpinnerLoading } from '../../../../components/SpinnerLoading';

interface IProps {
  title: string;
  type?: string;
  autosearch?: boolean;
  placeholder: string;
  buttonText: string;
  description: string;
  loading?: boolean;
  setSearch?(page: number, value: string): Promise<void>
  searchAlvara?(numero_alvara: string): void
  button?: React.ReactNode
}

function InputSearch({
  title, button, type, placeholder, loading, buttonText, description, autosearch, setSearch, searchAlvara,
}: IProps) {
  const [value, setValue] = useState<any>(null);

  function handleKeyOp(e: React.KeyboardEvent<HTMLInputElement>) {
    if (e.key === "Enter" && setSearch) {
      setSearch(1, value);
    }
  }

  function handleOnChange(e: ChangeEvent<HTMLInputElement>) {
    setValue(e.target.value);
  }

  function handleOnClick(e: React.MouseEvent) {
    if (setSearch) {
      setSearch(1, value);
    }
  }

  React.useEffect(() => {
    if (autosearch && setSearch && value != null) {
      setSearch(1, value);
    }
  }, [value]);

  return (
    <>
      <h3 style={{ fontSize: 23, fontWeight: 'bold', marginBottom: 10 }}>{title}</h3>
      <div style={{ marginBottom: 5, marginTop: 5 }}>
        <input
          style={{ padding: 15, borderRadius: 5, height: 47 }}
          type={type || "text"}
          className="form-control form-control-lg"
          value={value}
          placeholder={placeholder}
          onKeyUp={handleKeyOp}
          onChange={handleOnChange}
        />
        {!autosearch && !button && (
          <div
            style={{
              display: 'flex',
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: '#007BFF',
              padding: 10,
              height: 47,
              width: 150,
              borderRadius: 5,
              marginLeft: 5,
              cursor: 'pointer',
            }}
            onClick={handleOnClick}
          >
            {loading ? <SpinnerLoading color="#fff" size={20} /> : <h3 style={{ marginBottom: 0, color: '#fff', fontSize: 16 }}>{buttonText}</h3>}
          </div>
        )}

        {button}
      </div>
      <Description>
        {description}
      </Description>
    </>
  );
}

export default InputSearch;

const Description = styled.h3`
  font-size: 16px;
  color: #595959;
  @media (max-width: 768px) {
    font-size: 13px;
  }
`;
