import React from 'react';
import { FieldArrayWithId, UseFieldArrayRemove, UseFormSetValue } from 'react-hook-form';
import { BsFillXCircleFill } from 'react-icons/bs';
import { MateriaisSelecionados } from '../styles/style';
import { IProjeto } from '../hooks/Model/Projeto/type';
import { WrapperMateriais } from '../styles/WrapperMateriais';

type IMaterialValues = {
    fields: FieldArrayWithId<IProjeto, "materials", "id">[]
    setValue: UseFormSetValue<IProjeto>
    remove: UseFieldArrayRemove
}

export function MaterialValues({ fields, remove, setValue }: any) {
  return (
    <WrapperMateriais
      style={
          {
            display: fields.length === 0 ? "none" : "",
          }
        }
    >
      {fields.map((item: any, i: any) => (
        <MateriaisSelecionados key={i}>
          <label htmlFor="">{item.material?.title}</label>
          <input
            type="number"
            className="form-control"
            defaultValue={item?.qtd}
            onChange={(e) => {
              setValue(`materials.${i}.qtd`, Number(e.target.value) ? Number(e.target.value) : 0);
            }}
          />
          <BsFillXCircleFill
            onClick={() => remove(i)}
            color="rgba(242, 78, 30, 1)"
            size={48}
            style={{ cursor: "pointer" }}
          />
        </MateriaisSelecionados>
      ))}
    </WrapperMateriais>
  );
}
