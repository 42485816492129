import styled from "styled-components";

export const Container = styled.div`
  margin: 20px 20px 20px 0px;
  width: 100%;

  /* .graphics {
    @media(max-width: 768px) {
      width: 200px;
    }
  } */
`;

export const Subtitle = styled.div`
  margin-top: 30px;

  p {
    font-size: 30px;
    font-weight: 700;
    line-height: 24px;
  }

  span {
    color: red
  }

  span:nth-child(2) {
    color: #007BFF;
    cursor: pointer;
  }
`;

export const WrapperButton = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  gap: 20px;
  color: white;
`;

type IButton = {
  backgroundr: string
  color: string
  fontSize?: string
  size?: string
}

export const Button = styled.button<IButton>`
  outline: none;
  border: none;
  background-color: ${(props) => props.backgroundr};
  color: ${(props) => props.color};
  font-size: ${(props) => props.fontSize || `24px`};
  padding: ${(props) => props.size || `10px 30px`};
  border-radius: 5px;
  box-shadow: 0px 4px 4px 0px #00000040;
  font-weight: 700;
  cursor: pointer;

  @media (max-width: 1024px) {

  }
`;

// export const Container = styled.div`
//   margin: 20px 20px 20px 0px;
//   width: 100%;

//   td {
//     text-align: center;
//   }

//   table {
//     width: 100%;
//     border-collapse: collapse;
//   }

//   & h2 {
//     color: #182339;
//     font-size: 26px;
//   }

//   select:focus {
//     outline: none;
//   }

//   textarea:focus {
//     outline: none;
//   }

//   .content {
//     & div {
//       display: flex;
//     }

//     select:focus {
//       outline: none;
//     }

//     textarea:focus {
//       outline: none;
//     }

//     & input {
//       width: 100%;
//     }

//     & input:focus {
//       outline: none !important;
//       border-color: #007BFF;
//     }

//     .inputfile {
//       /* visibility: hidden etc. wont work */
//       width: 0.1px;
//       height: 0.1px;
//       opacity: 0;
//       overflow: hidden;
//       position: absolute;
//       z-index: -1;
//     }
//     .inputfile:focus + label {
//       /* keyboard navigation */
//       outline: 1px dotted #000;
//       outline: -webkit-focus-ring-color auto 5px;
//     }
//     .inputfile + label * {
//       pointer-events: none;
//     }
//   }
// `;
