import { useMutation, useQueryClient } from "react-query";
import { IFetchParams } from '../../types/IFetchParams';

export function useUpdateUsers(onSubmit: (values?:any) => Promise<void>, { query, search, page }: IFetchParams) {
  const queryClient = useQueryClient();
  const { mutate } = useMutation(onSubmit, {
    onSuccess: () => {
      setTimeout(() => queryClient.invalidateQueries([query, search, page]), 1000);
    },
  });

  return { mutate };
}
