import React, { useState } from "react";
import { Chart } from "react-chartjs-2";
import { useLocation } from "react-router-dom";
import { AbaFilterButtons, AbaFilterContent } from "../../../../components/develop-nilton-components/AbaFiltro";
import { SectionHeader } from "../../../../components/SectionHeader";

export function IndicadoresFiscalizacao() {
  const { state } = useLocation();
  const [tab, setTab] = useState(1);

  const optionsChart = {
    plugins: { legend: { display: true, position: "bottom" } },
  };

  const chartArray = [
    {
      title: "Renda pessoal e familiar",
      type: "pie",
      data: {
        labels: [
          "Fiscalização",
          "Notificação",
          "Comprimento de alvará",
        ],
        datasets: [{
          label: '',
          data: [
            1, 2, 3,
          ],
          borderColor: '#FFA500',
          backgroundColor: ['#FFA500', "red", "blue"],
        }],
      },
    },
  ];
  return (
    <div className="w-100">
      <SectionHeader subtitle="Indicadores de Fiscalização" />
      <h2 className="text-primary">{state.nome}</h2>

      <AbaFilterButtons buttons={["Anual", "Trimestral"]} useStateTab={[tab, setTab]} />

      <h3 className="m-2 border-bottom">Quadro geral</h3>
      <AbaFilterContent
        tab={tab}
        components={[
          <div className=" d-flex justify-content-center">
            {chartArray.map((chart) => (
              <div className="w-50 ">
                <Chart
            // @ts-ignore
                  type={chart.type}
                  data={chart.data}
               // @ts-ignore
                  options={optionsChart}
                />
              </div>
            ))}
          </div>,
          <div className=" d-flex justify-content-center">
            {chartArray.map((chart) => (
              <div className="w-50 ">
                <Chart
              // @ts-ignore
                  type={chart.type}
                  data={chart.data}
                 // @ts-ignore
                  options={optionsChart}
                />
              </div>
            ))}
          </div>,
        ]}
      />

    </div>
  );
}
