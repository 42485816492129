import React, { useEffect, useState } from 'react';
import api from '../../../../services/api';
import { data } from '../types';

export function useGetProjetosNaoIniciado() {
  const [projetosNaoIniciado, setProjetos] = useState<any | null>(null);
  const [loadNaoIniciado, setLoad] = useState(false);

  async function handleProjetosNaoIniciado(page = 1, search?: string) {
    setLoad(true);
    const { data } = await api.get('/projects', {
      params: {
        page,
        nome: search,
        filter: "Homologado/Não Iniciado",
      },
    });

    if (data.data.length === 0 && page > 1) {
      handleProjetosNaoIniciado(page - 1);
    }

    setProjetos(data);
    setLoad(false);
  }

  useEffect(() => {
    handleProjetosNaoIniciado();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return {
    projetosNaoIniciado, handleProjetosNaoIniciado, loadNaoIniciado,
  };
}
