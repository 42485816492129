import React from 'react';
import { IoIosArrowDropleftCircle } from 'react-icons/io';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { useAuth, usePermission } from '../../hooks/auth';
import { ConteinerSectionHeader } from './style';
import { IPropsSectionHeader } from './type';

export const SectionHeader = ({
  isIconBack, isIconClose, subtitle, backPage, handleRedirect,
}:IPropsSectionHeader) => {
  const history = useHistory();
  const { title } = usePermission();
  const { profile } = useAuth();
  return (
    <>
      <ConteinerSectionHeader style={{ marginTop: isIconClose ? 0 : 20 }}>

        <div className="header" style={{ width: '100%' }}>

          <div style={{ width: '100%' }}>
            {isIconBack ? (
              <div style={{ display: 'flex', alignItems: "center" }}>
                {isIconBack}
                <div>
                  {subtitle && <h2 style={{ marginBottom: 0 }}>{`${subtitle}`}</h2>}
                </div>
              </div>
            ) : isIconClose ? (
              <div style={{ display: 'flex', alignItems: "center", justifyContent: "space-between" }}>
                <div>
                  {subtitle && <h2 style={{ fontWeight: 'bold', fontSize: 24, marginBottom: 0 }}>{`${subtitle}`}</h2>}
                </div>
                {isIconClose}
              </div>
            ) : (
              <div>
                {subtitle && <h2>{`${subtitle}`}</h2>}
              </div>
            )}

            <div style={{
              width: '100%', marginTop: 20, marginBottom: 10, borderBottom: 2, borderBottomStyle: 'solid', borderBottomColor: '#1B7E9F',
            }}
            />
          </div>

          {/* <div>
          <div
            style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
            onClick={handleRedirect || (() => history.push(backPage || '/dashboard'))}
          >
            <WrapperBack>
              <IoIosArrowDropleftCircle color="#BE1E2D" className="icon" />
              <h1 style={{
                fontSize: 20, color: '#BE1E2D', marginLeft: 5, marginBottom: 3,
              }}
              >
                voltar
              </h1>
            </WrapperBack>
          </div>
        </div> */}

        </div>
      </ConteinerSectionHeader>
    </>
  );
};

const WrapperBack = styled.div`
  display: flex;
  align-items: center;
  .icon{
    font-size: 20px;
  }
  @media (max-width: 1024px) {
    font-size: 16px;
    h1 {
      display: none;
    }
  }
`;
