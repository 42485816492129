/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { BsCheckLg } from 'react-icons/bs';

type PropsIconConcluido = {
  tooltipMessage?: string
  size?:number
}

export function IconConcluido({ tooltipMessage, size }:PropsIconConcluido) {
  return (
    <div data-tip={tooltipMessage || ""}>
      <BsCheckLg
        color="#080"
        size={size || 30}
      />
    </div>
  );
}
