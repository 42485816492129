import React, { useEffect, useState } from 'react';
import api from '../../../../services/api';

export const useOptionSearch = () => {
  const [options, setOptions] = useState<any>(null);

  const handleOptions = async () => {
    const { data } = await api.get('/courses');
    setOptions(data);
  };

  useEffect(() => {
    handleOptions();
  }, []);
  return { options };
};
