import { PageError } from "../../components/PageError";
import { Egressos } from "../../pages/Adm/Egressos";
import { HomologarProjeto } from "../../pages/Adm/HomologarProjeto";
import { Dashboard } from "../../pages/Dashboard";
import { CadastrarRonda } from "../../pages/Fiscal/CadastrarRonda";
import { PageAddRoundsEgresses } from "../../pages/Fiscal/CadastrarRonda/components/PageAddRoundsEgresses";
import { PageHistoryFigma } from "../../pages/Fiscal/CadastrarRonda/components/PageHistoryFigma";
import { PageAddRoundsCreateEgresses } from "../../pages/Fiscal/CadastrarRonda/components/PageAddRoundsCreateEgress";
import { PageConfirmRounds } from "../../pages/Fiscal/CadastrarRonda/components/PageConfirmRounds";
import PageEgressesFiscal from "../../pages/Fiscal/CadastrarRonda/components/PageEgressesFiscal";
import { RelatorioFiscal } from "../../pages/Fiscal/components/Relatorios";
import { CumprirAlvara } from "../../pages/Fiscal/CumprirAlvara";
import { IndicadoresFiscal } from "../../pages/Fiscal/Indicadores";
import { ListarRonda } from "../../pages/Fiscal/ListarRonda";
import { NotificarEgresso } from "../../pages/Fiscal/NotificarEgresso";
import { RondaDiaria } from "../../pages/Fiscal/RondaDiaria";

export const fiscalRoutes = [
  { path: "/", component: Dashboard },
  { path: "/dashboard", component: Dashboard },
  { path: "/egressos", component: Egressos },
  { path: "/cadastrar-ronda", component: CadastrarRonda },
  { path: "/cadastrar-ronda/adicionar-egressos", component: PageAddRoundsEgresses },
  { path: "/cadastrar-ronda/historico-ronda", component: PageHistoryFigma },
  { path: "/cadastrar-ronda/create/adicionar-egressos", component: PageAddRoundsCreateEgresses },
  { path: "/cadastrar-ronda/confirmar-egressos", component: PageConfirmRounds },
  { path: "/cadastrar-rond/egressos", component: PageEgressesFiscal },
  { path: "/listar-ronda", component: ListarRonda },
  { path: "/ronda-diaria", component: RondaDiaria },
  { path: "/cumprir-alvara", component: CumprirAlvara },
  { path: "/notificar-egresso", component: NotificarEgresso },
  { path: "/relatorios", component: RelatorioFiscal },
  { path: "/indicadores", component: IndicadoresFiscal },
  { path: "/homologar_pro", component: HomologarProjeto },
  { path: "*", component: PageError },
];
