import React from 'react';

import {
  Nav, NavItem, NavLink, TabContent, TabPane, Row, Col, Card, CardTitle, CardText, Button,
} from 'reactstrap';

import ReactPaginate from 'react-paginate';

import { BsFillHandThumbsDownFill, BsFillHandThumbsUpFill } from 'react-icons/bs';
import { AiOutlineFilePdf, AiFillWarning } from 'react-icons/ai';
import { SpinnerLoading } from '../../../../components/SpinnerLoading';

import { Container } from '../../CumprirAlvara/styles';

function TableAlvaras({
  user, tab, setTab, alvaras, setAlvara, page, getLicenses, loading,
}: any) {
  return (
    <Container>
      <Nav tabs>
        <NavItem>
          <NavLink
            className={tab === '1' ? 'active' : ''}
            onClick={() => setTab("1")}
            style={{ cursor: "pointer" }}
          >
            Geral
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={tab === '2' ? 'active' : ''}
            onClick={() => setTab("2")}
            style={{ cursor: "pointer" }}
          >
            Pendentes
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={tab === '3' ? 'active' : ''}
            onClick={() => setTab("3")}
            style={{ cursor: "pointer" }}
          >
            Concluídos
          </NavLink>
        </NavItem>
      </Nav>
      <TabContent activeTab={tab}>

        <TabPane tabId="1">
          <Row>
            <Col sm="12">
              {loading ? (
                <div style={{
                  display: "flex", justifyContent: "center", alignItems: 'center', height: '50vh',
                }}
                >
                  <SpinnerLoading color="#000" size={50} />
                </div>
              ) : (
                <table style={{ marginTop: 10 }}>
                  <tr>
                    <th style={{ textAlign: 'center' }}>#</th>
                    <th style={{ textAlign: 'center' }}>Número do Alvará</th>
                    <th style={{ textAlign: 'center' }}>Egresso</th>
                    <th style={{ textAlign: 'center' }}>Fiscal</th>
                    <th style={{ textAlign: 'center' }}>Executado</th>
                    {/* <th style={{ textAlign: 'center' }}>Registro SEUU</th> */}
                    <th style={{ textAlign: 'center' }}>Arquivo</th>
                    <th style={{ textAlign: 'center' }}>Observação</th>
                  </tr>
                  {alvaras.data.map(((alvara: any, index: number) => (
                    <tr>
                      <td>{index + 1 + (page > 1 ? (page - 1) * 30 : 0)}</td>
                      <td onClick={() => user.profile.title === 'Fiscal' && setAlvara(alvara)} style={{ color: '#f00' }}>{alvara.numero_alvara}</td>
                      <td>{alvara.egress.nome}</td>
                      <td>{alvara?.responsavel_execucao?.name || 'Sem responsável'}</td>
                      <td>
                        { alvara.executado == "T" ? <BsFillHandThumbsUpFill color="#080" style={{ fontSize: 30 }} /> : <BsFillHandThumbsDownFill color="#f00" style={{ fontSize: 30 }} /> }
                      </td>
                      {/* <td>
                        { alvara.registro_seuu == "T" ? <BsFillHandThumbsUpFill color="#080" style={{ fontSize: 30 }} /> : <BsFillHandThumbsDownFill color="#f00" style={{ fontSize: 30 }} /> }
                      </td> */}
                      <td>{ alvara.attachment ? <AiOutlineFilePdf onClick={() => user.profile.title === 'Fiscal' && window.open(alvara.attachment.url_file, '_blank')} color="#c7b515" style={{ fontSize: 30 }} /> : '' }</td>
                      <td>{ alvara.observacao ? <AiFillWarning onClick={() => user.profile.title === 'Fiscal' && setAlvara(alvara)} color="#c7b515" style={{ fontSize: 30 }} /> : '' }</td>
                    </tr>
                  )))}
                </table>
              )}
            </Col>
          </Row>
        </TabPane>

        <TabPane tabId="2">
          <Row>
            <Col sm="12">
              {loading ? (
                <div style={{
                  display: "flex", justifyContent: "center", alignItems: 'center', height: '50vh',
                }}
                >
                  <SpinnerLoading color="#000" size={50} />
                </div>
              ) : (
                <table style={{ marginTop: 10 }}>
                  <tr>
                    <th style={{ textAlign: 'center' }}>#</th>
                    <th style={{ textAlign: 'center' }}>Número do Alvará</th>
                    <th style={{ textAlign: 'center' }}>Egresso</th>
                    <th style={{ textAlign: 'center' }}>Fiscal</th>
                    <th style={{ textAlign: 'center' }}>Executado</th>
                    {/* <th style={{ textAlign: 'center' }}>Registro SEUU</th> */}
                    <th style={{ textAlign: 'center' }}>Arquivo</th>
                    <th style={{ textAlign: 'center' }}>Observação</th>
                  </tr>
                  {alvaras.data.filter((item: any) => item.executado == "F").map(((alvara: any, index: number) => (
                    <tr>
                      <td>{index + 1 + (page > 1 ? (page - 1) * 30 : 0)}</td>
                      <td onClick={() => user.profile.title === 'Fiscal' && setAlvara(alvara)} style={{ color: '#f00' }}>{alvara.numero_alvara}</td>
                      <td>{alvara.egress.nome}</td>
                      <td>{alvara?.responsavel_execucao?.name || 'Sem responsável'}</td>
                      <td>
                        { alvara.executado == "T" ? <BsFillHandThumbsUpFill color="#080" style={{ fontSize: 30 }} /> : <BsFillHandThumbsDownFill color="#f00" style={{ fontSize: 30 }} /> }
                      </td>
                      {/* <td>
                        { alvara.registro_seuu == "T" ? <BsFillHandThumbsUpFill color="#080" style={{ fontSize: 30 }} /> : <BsFillHandThumbsDownFill color="#f00" style={{ fontSize: 30 }} /> }
                      </td> */}
                      <td>{ alvara.attachment ? <AiOutlineFilePdf onClick={() => user.profile.title === 'Fiscal' && window.open(alvara.attachment.url_file, '_blank')} color="#c7b515" style={{ fontSize: 30 }} /> : '' }</td>
                      <td>{ alvara.observacao ? <AiFillWarning onClick={() => user.profile.title === 'Fiscal' && setAlvara(alvara)} color="#c7b515" style={{ fontSize: 30 }} /> : '' }</td>
                    </tr>
                  )))}
                </table>
              )}
            </Col>
          </Row>
        </TabPane>

        <TabPane tabId="3">
          <Row>
            <Col sm="12">
              {loading ? (
                <div style={{
                  display: "flex", justifyContent: "center", alignItems: 'center', height: '50vh',
                }}
                >
                  <SpinnerLoading color="#000" size={50} />
                </div>
              ) : (
                <table style={{ marginTop: 10 }}>
                  <tr>
                    <th style={{ textAlign: 'center' }}>#</th>
                    <th style={{ textAlign: 'center' }}>Número do Alvará</th>
                    <th style={{ textAlign: 'center' }}>Egresso</th>
                    <th style={{ textAlign: 'center' }}>Fiscal</th>
                    <th style={{ textAlign: 'center' }}>Executado</th>
                    {/* <th style={{ textAlign: 'center' }}>Registro SEUU</th> */}
                    <th style={{ textAlign: 'center' }}>Arquivo</th>
                    <th style={{ textAlign: 'center' }}>Observação</th>
                  </tr>
                  {alvaras.data.filter((item: any) => item.executado == 'T').map((alvara: any, index: number) => (
                    <tr>
                      <td>{index + 1 + (page > 1 ? (page - 1) * 30 : 0)}</td>
                      <td onClick={() => user.profile.title === 'Fiscal' && setAlvara(alvara)} style={{ color: '#f00' }}>{alvara.numero_alvara}</td>
                      <td>{alvara.egress.nome}</td>
                      <td>{alvara?.responsavel_execucao?.name || 'Sem responsável'}</td>
                      <td>
                        { alvara.executado == "T" ? <BsFillHandThumbsUpFill color="#080" style={{ fontSize: 30 }} /> : <BsFillHandThumbsDownFill color="#f00" style={{ fontSize: 30 }} /> }
                      </td>
                      {/* <td>
                        { alvara.registro_seuu == "T" ? <BsFillHandThumbsUpFill color="#080" style={{ fontSize: 30 }} /> : <BsFillHandThumbsDownFill color="#f00" style={{ fontSize: 30 }} /> }
                      </td> */}
                      <td>{ alvara.attachment ? <AiOutlineFilePdf onClick={() => user.profile.title === 'Fiscal' && window.open(alvara.attachment.url_file, '_blank')} color="#c7b515" style={{ fontSize: 30 }} /> : '' }</td>
                      <td>{ alvara.observacao ? <AiFillWarning onClick={() => user.profile.title === 'Fiscal' && setAlvara(alvara)} color="#c7b515" style={{ fontSize: 30 }} /> : '' }</td>
                    </tr>
                  ))}
                </table>
              )}
            </Col>
          </Row>
        </TabPane>

      </TabContent>

      {alvaras && alvaras?.data?.length > 0
        && (
        <div style={{ display: "flex", alignItems: 'flex-end', justifyContent: "flex-end" }}>
          <ReactPaginate
            previousLabel="anterior"
            nextLabel="próximo"
            breakLabel="..."
            breakClassName="break-me"
            pageCount={alvaras ? alvaras?.meta?.last_page : 0}
            marginPagesDisplayed={4}
            pageRangeDisplayed={5}
            forcePage={page - 1}
            onPageChange={(page) => { getLicenses(page.selected); }}
            containerClassName="pagination"
            activeClassName="active"
          />
        </div>
        )}
    </Container>
  );
}

export default TableAlvaras;
