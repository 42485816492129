import React from 'react';
import { TablePadrão, Td, Tr } from '../../../../components/develop-nilton-components/TablePadrão';
import { Pagination } from '../../../../components/Pagination';
import { SpinnerLoading } from '../../../../components/SpinnerLoading';
import { SubmitResponse } from '../../../../components/SubmitResponse';
import { ICursos } from '../types';
import { TbodyEmAndamento } from './TbodyEmAndamento';
import { TbodyPendente } from './TbodyPendente';

export function TablePendente({
  data, meta, load, handleUpdate,
}: ICursos) {
  const headWidth = ["5%", "50%", "23%", "20%"];
  const thead = [
    "#",
    "Nome do curso",
    "Status",
    "Opções",
  ];

  return (
    <TablePadrão headWidth={headWidth} thead={thead}>
      {(load) && (
      <Tr>
        <Td width="100%"><SpinnerLoading /></Td>
      </Tr>
      )}

      {data?.length === 0 && (
      <Tr>
        <Td width="100%">
          <div className="w-100">
            <SubmitResponse submitResponse={{ message: "Não há turmas pendentes cadastradas", status: "danger", title: "Aviso" }} />
          </div>
        </Td>
      </Tr>
      )}
      {data?.map((item, i) => (
        <TbodyPendente
          key={i}
          curso={item}
          headWidth={headWidth}
          index={i + 1}
          meta={meta}
          handleUpdate={handleUpdate}
        />
      ))}
      {data && (
        <Tr>
          <Td width="100%">
            <Pagination
              meta={meta}
              setPage={handleUpdate}
              load={load}
            />
          </Td>
        </Tr>
      )}
    </TablePadrão>
  );
}
