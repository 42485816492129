import React, { useEffect, useState } from 'react';
import { Chart } from 'react-chartjs-2';
import { SpinnerLoading } from '../../../../components/SpinnerLoading';
import { useGetIndicadores } from '../hooks/useGetIndicadores';
import { handleTrimestre, trimestral_options } from '../../../../components/develop-nilton-components/hooks/time_options';

export function FatoresFisTrimestral({ tab, tabType }: {tab:number, tabType: number}) {
  const { indicador: tratamento_especifico, handleSetIndicador: handle_set_tratamento_especifico } = useGetIndicadores();
  const { indicador: toma_medicamento_em_aula, handleSetIndicador: handle_set_toma_medicamento_em_aula } = useGetIndicadores();
  const { indicador: aproxima_olhos_para_leitura, handleSetIndicador: handle_set_aproxima_olhos_para_leitura } = useGetIndicadores();
  const { indicador: usa_oculos, handleSetIndicador: handle_set_usa_oculos } = useGetIndicadores();

  const options_graphics = [
    {
      title: "Já fizeram tratamento específico",
      type: "pie",
      options: {
        plugins: {
          legend: { display: true, position: "left" },
        },
      },
      data: tratamento_especifico,
      onChange: (e:any) => {
        handle_set_tratamento_especifico("tratamento_especifico", undefined, e.target.value);
      },
    },
    {
      title: "Tomam medicamento no horário de aula",
      type: "pie",
      options: {
        plugins: {
          legend: { display: true, position: "left" },
        },
      },
      data: toma_medicamento_em_aula,
      onChange: (e:any) => {
        handle_set_toma_medicamento_em_aula("toma_medicamento_em_aula", undefined, e.target.value);
      },
    },
    {
      title: "Problemas de visão para leitura",
      type: "pie",
      options: {
        plugins: {
          legend: { display: true, position: "left" },
        },
      },
      data: aproxima_olhos_para_leitura,
      onChange: (e:any) => {
        handle_set_aproxima_olhos_para_leitura("aproxima_olhos_para_leitura", undefined, e.target.value);
      },
    },
    {
      title: "Usam óculos",
      type: "pie",
      options: {
        plugins: {
          legend: { display: true, position: "left" },
        },
      },
      data: usa_oculos,
      onChange: (e:any) => {
        handle_set_usa_oculos("usa_oculos", undefined, e.target.value);
      },
    },

  ];

  const [load, set_load] = useState(false);

  useEffect(() => {
    (async () => {
      set_load(false);
      await handle_set_tratamento_especifico("tratamento_especifico", undefined, handleTrimestre());
      await handle_set_toma_medicamento_em_aula("toma_medicamento_em_aula", undefined, handleTrimestre());
      await handle_set_aproxima_olhos_para_leitura("aproxima_olhos_para_leitura", undefined, handleTrimestre());
      await handle_set_usa_oculos("usa_oculos", undefined, handleTrimestre());
      set_load(true);
    })();
  }, [tab, tabType]);

  return (
    <div>
      {!load && (
        <div className="d-flex justify-content-center m-5">
          <SpinnerLoading />
        </div>
      )}
      {load && options_graphics.map(({
        data, type, options, title, onChange,
      }, i) => (
        <div key={i}>
          <div className="mt-3 border-bottom border-secondary d-flex">
            <h3 className="mr-2">{title}</h3>
            <select
              style={{ height: 35, width: 200, cursor: "pointer" }}
              className="form-control"
              onChange={onChange}
              defaultValue={handleTrimestre()}
            >
              {trimestral_options.map((trimestre, i) => (
                <option key={i} value={trimestre.value}>{trimestre.label}</option>
              ))}
            </select>
          </div>
          <div className="d-flex align-items-center flex-column w-100">
            <div>
              <Chart
                // @ts-ignore
                type={type}
                data={data}
                // @ts-ignore
                options={options}
                style={{ width: type === "line" ? 900 : 600 }}
              />
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}
