import UserCheck from '../../assets/user_check.png';
// import Users from '../../assets/users.png';
import UserConfirm from '../../assets/user_confirm.png';
// import List from '../../assets/list.png';
import HexUsers from '../../assets/hex_users.png';
import HexUsersPlus from '../../assets/hex_users_plus.png';
import Notify from '../../assets/notify.png';
import Report from '../../assets/report.png';

export const admin = {
  title: 'Administrador',
  functions: [
    // {
    //   title: 'Cadastrar Egresso',
    //   url: 'assets/cad_egresso.png',
    //   pathPainel: '/cad-egresso',
    // },
    // {
    //   title: 'Gerir Egresso',
    //   url: '/assets/gerir_egre.png',
    //   pathPainel: '/gerir-egresso',
    // },
    {
      title: 'Egressos',
      url: '/assets/gerir_egre.png',
      pathPainel: '/egressos',
    },

    // {
    //   title: 'Buscar Habilidade',
    //   url: '/assets/bsucar_habi.png',
    //   pathPainel: '/buscar-habilidade',
    // },

    // {
    //   title: 'Cadastrar Usuário',
    //   url: 'assets/usuario_profile.png',
    //   pathPainel: '/cad-usuario',
    // },
    {
      title: 'Usuários',
      url: '/assets/gerir_user.png',
      pathPainel: '/usuarios',
    },
    /* {
      title: 'Indicadores',
      url: 'assets/indicadores.png',
      pathPainel: '/',
    }, */
    /* {
      title: 'Cadastrar Curso',
      url: 'assets/cad_curso.png',
      pathPainel: '/cad_curso',
    }, */
    /* {
      title: 'Relatórios',
      url: 'assets/rela.png',
      pathPainel: '/',
    }, */
    {
      title: 'Homologar Projeto',
      url: '/assets/homologar_pro.png',
      pathPainel: '/homologar_pro',
    },
    {
      url: UserConfirm,
      title: 'Homologar Ronda',
      pathPainel: '/homologar-ronda',
    },
    {
      url: HexUsersPlus,
      title: 'Cadastrar Alvará',
      pathPainel: '/cadastrar-alvara',
    },
    {
      url: Notify,
      title: 'Notificar egresso',
      pathPainel: '/notificar-egresso',
    },
    /* {
      title: 'Cadastrar Projeto',
      url: 'assets/cad_project.png',
      pathPainel: '/cad_project',
    }, */

    // {
    //   title: 'Vizualizar Egressos',
    //   url: 'assets/view_pro.png',
    //   pathPainel: '/',
    // },
  ],
};

export const fiscal = {
  title: 'Fiscal',
  functions: [
    {
      url: UserCheck,
      title: 'Rondas',
      pathPainel: '/cadastrar-ronda',
    },
    // {
    //   url: UserConfirm,
    //   title: 'Homologar ronda',
    //   pathPainel: '/homologar-ronda',
    // },
    // {
    //   url: List,
    //   title: 'Listar ronda',
    //   pathPainel: '/listar-ronda',
    // },
    {
      url: HexUsers,
      title: 'Cumprir alvará',
      pathPainel: '/cumprir-alvara',
    },
    {
      url: Notify,
      title: 'Notificar egresso',
      pathPainel: '/notificar-egresso',
    },
    // {
    //   url: Users,
    //   title: 'Ronda diária',
    //   pathPainel: '/ronda-diaria',
    // },

    {
      url: 'assets/dashboard.png',
      title: 'Indicadores',
      pathPainel: '/indicadores',
    },

    {
      url: Report,
      title: 'Relatórios',
      pathPainel: '/relatorios',
    },
  ],
};

export const convidado = {
  title: 'Convidado',
  functions: [
    {
      url: '/assets/usuario_profile.png',
      title: 'Ver Egresso',
      pathPainel: '/pesquisar-egresso',
    },
    {
      url: '/assets/icon_social.png',
      title: 'Socio Economico',
      pathPainel: '/socio-economico',
    },
    {
      url: '/assets/psico.png',
      title: 'Psicologicos',
      pathPainel: '/psicologicos',
    },
    {
      url: UserCheck,
      title: 'Fiscalização',
      pathPainel: '/fiscalizacao',
    },
    {
      url: '/assets/capacitacao.png',
      title: 'Capacitação',
      pathPainel: '/capacitacao',
    },
  ],
};

export const pedagogo = {
  title: 'Pedagogo',
  functions: [
    // {
    //   title: 'Cadastrar Curso',
    //   url: 'assets/cad_curso.png',
    //   pathPainel: '/cad_curso',
    // },
    // {
    //   title: 'Cadastrar Projeto',
    //   url: 'assets/cad_project.png',
    //   pathPainel: '/cad_project',
    // },
    // {
    //   title: 'Homologar Projeto',
    //   url: 'assets/homologar_pro.png',
    //   pathPainel: '/homologar_pro',
    // },

    {
      title: 'Analisar Egresso',
      url: '/assets/analisar-egresso.png',
      pathPainel: '/analisar-egresso',
    },
    {
      title: 'Turmas',
      url: HexUsers,
      pathPainel: '/gerir-turmas',
    },
    {
      title: 'Projetos',
      url: 'assets/edit_projeto.png',
      pathPainel: '/gerir-projetos',
    },
    {
      title: 'Estatísticas',
      url: '/assets/estatistica.png',
      pathPainel: '/estatistica',
    },
    {
      title: 'Relatório',
      url: Report,
      pathPainel: '/relatorio',
    },
    // {
    //   url: Report,
    //   title: 'Relatórios',
    //   pathPainel: '/relatorios',
    // },
  ],
};

export const assistente_social = {
  title: 'Assistente Social',
  functions: [
    {
      url: '/assets/analisar-egresso.png',
      title: 'Acompanhamento',
      pathPainel: '/acompanhamento',
    },
    {
      url: '/assets/rela.png',
      title: 'Relatórios',
      pathPainel: '/relatorios',
    },
  ],
};

export const psicologo = {
  title: 'Psicólogo',
  functions: [
    {
      url: '/assets/Perfil.png',
      title: 'Perfil',
      pathPainel: '/perfil',
    },
    {
      url: '/assets/estatistica.png',
      title: 'Indicadores',
      pathPainel: '/indicadores',
    },
    {
      url: Report,
      title: 'Relatórios',
      pathPainel: '/relatorios',
    },
  ],
};

export const psico_pedagogo = {
  title: 'Psico-pedagogo',
  functions: [
    {
      url: '/assets/Perfil.png',
      title: 'Acompanhamento',
      pathPainel: '/acompanhamento',
    },
    {
      url: '/assets/estatistica.png',
      title: 'Indicadores',
      pathPainel: '/indicadores',
    },
    {
      url: Report,
      title: 'Relatórios',
      pathPainel: '/relatorios',
    },
  ],
};

export const master = {
  title: 'master',
  perfis: [
    admin,
    fiscal,
    psicologo,
    pedagogo,
    psico_pedagogo,
    assistente_social,
    convidado,
  ],
};
