import React, { useState } from 'react';
import { Chart } from 'react-chartjs-2';
import { Spinner } from 'reactstrap';
import { AbaFilterButtons, AbaFilterContent } from '../../../components/develop-nilton-components/AbaFiltro';

import { SectionHeader } from '../../../components/SectionHeader';
import { useReportSocioEconomico } from './hooks/useReportSocioEconomico';
import { year_options, handleTrimestre, trimestral_options } from '../../../components/develop-nilton-components/hooks/time_options';
import { SpinnerLoading } from '../../../components/SpinnerLoading';

export function SocioEconomico() {
  const [tab, setTab] = useState(1);
  const { report: habitacao, handleSetReport: handleReportHabilitação } = useReportSocioEconomico('/reports/relatorio_egresso', "habitacao", "2022");
  const { report: familiar, handleSetReport: handleReportFamiliar } = useReportSocioEconomico('/reports/relatorio_egresso', "familiar", "2022");
  const { report: renda, handleSetReport: handleReportRenda } = useReportSocioEconomico('/reports/relatorio_egresso', "renda", "2022");
  const { report: lgbt, handleSetReport: handleReportLgbt } = useReportSocioEconomico('/reports/relatorio_egresso', "lgbt", "2022");

  const { report: habitacaoTri, handleSetReport: handleReportHabilitaçãoTri } = useReportSocioEconomico('/reports/relatorio_egresso', "habitacao", "2022");
  const { report: familiarTri, handleSetReport: handleReportFamiliarTri } = useReportSocioEconomico('/reports/relatorio_egresso', "familiar", "2022");
  const { report: rendaTri, handleSetReport: handleReportRendaTri } = useReportSocioEconomico('/reports/relatorio_egresso', "renda", "2022");
  const { report: lgbtTri, handleSetReport: handleReportLgbtTri } = useReportSocioEconomico('/reports/relatorio_egresso', "lgbt", "2022");

  const load = habitacao && familiar && renda && lgbt;
  const loadTri = habitacaoTri && familiarTri && rendaTri && lgbtTri;

  console.log(rendaTri);

  const optionsChart = {
    plugins: { legend: { display: false } },
    indexAxis: 'y',
    scales: {
      x: {
        suggestedMax: 10,
      },
    },
  };

  return (
    <div className="w-100">
      <SectionHeader subtitle="Indicadores Sociais e Econômicos" />

      <AbaFilterButtons buttons={["Anual", "Trimestral"]} useStateTab={[tab, setTab]} />

      <AbaFilterContent
        tab={tab}
        components={[
          <div>
            {load ? (
              <div data-testid="graficos">
                <div>
                  <div className="d-flex m-3 justify-content-center">
                    <h3 className="mr-2">Sobre a habitação do egresso</h3>
                    <select onChange={(e) => handleReportHabilitação(e.target.value)}>
                      {year_options.map((year) => (
                        <option value={year} key={year}>{year}</option>
                      ))}
                    </select>
                  </div>
                  <Chart
                // @ts-ignore
                    type="bar"
                    data={habitacao}
                // @ts-ignore
                    options={optionsChart}
                  />
                </div>
                <div>
                  <div className="d-flex m-3 justify-content-center">
                    <h3 className="m-2">Perfil pessoal e familiar</h3>
                    <select onChange={(e) => handleReportFamiliar(e.target.value)}>
                      {year_options.map((year) => (
                        <option value={year} key={year}>{year}</option>
                      ))}
                    </select>
                  </div>
                  <Chart
                // @ts-ignore
                    type="bar"
                    data={familiar}
                // @ts-ignore
                    options={optionsChart}
                  />
                </div>
                <div>
                  <div className="d-flex m-3 justify-content-center">
                    <h3 className="m-2">Renda pessoal e familiar</h3>
                    <select onChange={(e) => handleReportRenda(e.target.value)}>
                      {year_options.map((year) => (
                        <option value={year} key={year}>{year}</option>
                      ))}
                    </select>
                  </div>
                  <Chart
                // @ts-ignore
                    type="bar"
                    data={renda}
                // @ts-ignore
                    options={optionsChart}
                  />
                </div>
                <div>
                  <div className="d-flex m-3 justify-content-center">
                    <h3 className="m-2">Comunidade LGBT</h3>
                    <select onChange={(e) => handleReportLgbt(e.target.value)}>
                      {year_options.map((year) => (
                        <option value={year} key={year}>{year}</option>
                      ))}
                    </select>
                  </div>
                  <Chart
                // @ts-ignore
                    type="bar"
                    data={lgbt}
                // @ts-ignore
                    options={optionsChart}
                  />
                </div>
              </div>
            ) : (
              <div className="d-flex justify-content-center">
                <SpinnerLoading />
              </div>
            )}
          </div>,
          <div>
            {loadTri ? (
              <div>
                <div>
                  <div className="d-flex m-3 justify-content-center">
                    <h3 className="mr-2">Sobre a habitação do egresso</h3>
                    <select value={handleTrimestre()} onChange={(e) => handleReportHabilitaçãoTri(undefined, e.target.value)}>
                      {trimestral_options.map((options) => (
                        <option value={options.value} key={options.label}>{options.label}</option>
                      ))}
                    </select>
                  </div>
                  <Chart
              // @ts-ignore
                    type="bar"
                    data={habitacaoTri}
              // @ts-ignore
                    options={optionsChart}
                  />
                </div>
                <div>
                  <div className="d-flex m-3 justify-content-center">
                    <h3 className="m-2">Perfil pessoal e familiar</h3>
                    <select value={handleTrimestre()} onChange={(e) => handleReportFamiliarTri(undefined, e.target.value)}>
                      {trimestral_options.map((options) => (
                        <option value={options.value} key={options.value}>{options.label}</option>
                      ))}
                    </select>
                  </div>
                  <Chart
              // @ts-ignore
                    type="bar"
                    data={familiarTri}
              // @ts-ignore
                    options={optionsChart}
                  />
                </div>
                <div>
                  <div className="d-flex m-3 justify-content-center">
                    <h3 className="m-2">Renda pessoal e familiar</h3>
                    <select value={handleTrimestre()} onChange={(e) => handleReportRendaTri(undefined, e.target.value)}>
                      {trimestral_options.map((options) => (
                        <option value={options.value} key={options.value}>{options.label}</option>
                      ))}
                    </select>
                  </div>
                  <Chart
              // @ts-ignore
                    type="bar"
                    data={rendaTri}
              // @ts-ignore
                    options={optionsChart}
                  />
                </div>
                <div>
                  <div className="d-flex m-3 justify-content-center">
                    <h3 className="m-2">Comunidade LGBT</h3>
                    <select value={handleTrimestre()} onChange={(e) => handleReportLgbtTri(undefined, e.target.value)}>
                      {trimestral_options.map((options) => (
                        <option value={options.value} key={options.label}>{options.label}</option>
                      ))}
                    </select>
                  </div>
                  <Chart
              // @ts-ignore
                    type="bar"
                    data={lgbtTri}
              // @ts-ignore
                    options={optionsChart}
                  />
                </div>
              </div>
            ) : (
              <div className="d-flex justify-content-center">
                <SpinnerLoading />
              </div>
            )}
          </div>,
        ]}
      />

    </div>
  );
}
