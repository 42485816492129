import { eachYearOfInterval, format } from 'date-fns';
import React from 'react';
import { Chart } from 'react-chartjs-2';
import { SectionHeader } from '../../../components/SectionHeader';
import { SpinnerLoading } from '../../../components/SpinnerLoading';

import { useReport } from './hooks/useReport';

export function Capacitacao() {
  const { report: cursos_por_mes_no_ano, handleSetReport: handleSet_egresso_cursos_por_mes_no_ano } = useReport('/reports/cursos_por_mes_no_ano');
  const { report: reprovados_por_projeto_no_ano, handleSetReport: handle_egresso_reprovados_por_projeto_no_ano } = useReport('/reports/reprovados_por_projeto_no_ano');
  const { report: media_notas_egressos_ultimos_cursos } = useReport('/reports/media_notas_egressos_ultimos_cursos');
  const { report: indicadores_gerais_dos_egressos } = useReport('/reports/indicadores_gerais_dos_egressos');

  const years = eachYearOfInterval({ start: new Date(0), end: new Date() }).map((item) => format(new Date(item), "yyyy"));

  const load = (media_notas_egressos_ultimos_cursos && cursos_por_mes_no_ano && reprovados_por_projeto_no_ano && indicadores_gerais_dos_egressos);

  const options_line = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top' as const,
      },
    },
    scales: {
      y: {
        min: 0,
      },
    },
  };

  return (
    <div className="w-100">
      <SectionHeader subtitle="Indicadores de Capacitação" />
      {!load ? (
        <div className="d-flex justify-content-center m-5">
          <SpinnerLoading size={50} />
        </div>
      ) : (
        <div>
          {media_notas_egressos_ultimos_cursos && (
          <div data-testid="graficos">
            <div className="d-flex align-items-center justify-content-center mt-2">
              <p className="m-0 mr-3 h4">Média de notas dos egressos nos últimos 7 cursos</p>
            </div>
            <Chart
              data={media_notas_egressos_ultimos_cursos}
              type="line"
              options={options_line}
              height={95}
            />
          </div>
          )}

          {cursos_por_mes_no_ano && (
          <div>
            <div className="d-flex align-items-center justify-content-center mt-2">
              <p className="m-0 mr-3 h4">Cursos por mês no ano :</p>
              <select onChange={(e) => handleSet_egresso_cursos_por_mes_no_ano(e.target.value)}>
                {years.map((year) => {
                  if (year === format(new Date(), 'yyyy')) {
                    return <option value={year} selected>{year}</option>;
                  } return <option value={year}>{year}</option>;
                })}
              </select>
            </div>
            <Chart
              data={cursos_por_mes_no_ano}
              type="line"
              height={95}
              options={options_line}
            />
          </div>
          )}

          {reprovados_por_projeto_no_ano && (
          <div>
            <div className="d-flex align-items-center justify-content-center mt-2">
              <p className="m-0 mr-3 h4">Reprovados por projeto no ano:</p>
              <select onChange={(e) => handle_egresso_reprovados_por_projeto_no_ano(e.target.value)}>
                {years.map((year) => {
                  if (year === format(new Date(), 'yyyy')) {
                    return <option value={year} selected>{year}</option>;
                  } return <option value={year}>{year}</option>;
                })}
              </select>
            </div>
            <Chart
              data={reprovados_por_projeto_no_ano}
              type="line"
              height={95}
              options={options_line}
            />
          </div>
          )}

          {indicadores_gerais_dos_egressos && (
          <div className="d-flex justify-content-center m-5" style={{ gap: 20 }}>
            <div className="border border-secondary rounded d-flex flex-column justify-content-center align-items-center p-3">
              <span>Nota em cursos</span>
              <span className="h3">{indicadores_gerais_dos_egressos.media_avaliacoes}</span>
            </div>
            <div className="border border-secondary rounded d-flex flex-column justify-content-center align-items-center p-3">
              <span>Cognição</span>
              <span className="h3">{indicadores_gerais_dos_egressos.media_aspectos_cognitivos}</span>
            </div>
            <div className="border border-secondary rounded d-flex flex-column justify-content-center align-items-center p-3">
              <span>Socialização</span>
              <span className="h3">{indicadores_gerais_dos_egressos.media_socializacao}</span>
            </div>
            <div className="border border-secondary rounded d-flex flex-column justify-content-center align-items-center p-3">
              <span>Ass. / Pontualidade</span>
              <span className="h3">{indicadores_gerais_dos_egressos.media_assiduidade_pontualidade}</span>
            </div>
            <div className="border border-secondary rounded d-flex flex-column justify-content-center align-items-center p-3">
              <span>Disciplina</span>
              <span className="h3">{indicadores_gerais_dos_egressos.media_disciplina}</span>
            </div>
            <div className="border border-secondary rounded d-flex flex-column justify-content-center align-items-center p-3">
              <span>Aproveitamento</span>
              <span className="h3">{indicadores_gerais_dos_egressos.media_aproveitamentos_pratico}</span>
            </div>
          </div>
          )}
        </div>
      )}

    </div>
  );
}
