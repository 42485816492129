import React, { useEffect, useState } from 'react';
import { Modal } from 'reactstrap';

interface PropsModalImage {
  isOpen: boolean;
  handleIsOpen: () => void;

  image: string;
}

export function ModalImage({
  isOpen,
  handleIsOpen,
  image,
}: PropsModalImage): JSX.Element {
  return (
    <div>
      <Modal isOpen={isOpen} toggle={handleIsOpen} size="md">
        <img src={image} width={500} height={500} />
      </Modal>
    </div>
  );
}
