import React, { useEffect, useState } from 'react';
import api from '../../../../services/api';

export function useGetProjetosConcluidos<T>() {
  const [loadConcluidos, setLoad] = useState(false);
  const [projetosConcluidos, setProjetosConcluidos] = useState< T | null>(null);

  async function handleProjetosConcluidos(page = 1, search = "") {
    setLoad(true);
    const { data } = await api.get('/projects', {
      params: {
        page,
        homologado: "T",
        nome: search,
      },
    });
    if (data.data.length === 0 && page > 1) {
      handleProjetosConcluidos(page - 1);
    } else {
      setProjetosConcluidos(data);
      setLoad(false);
    }
  }

  useEffect(() => {
    handleProjetosConcluidos();
  }, []);

  return {
    projetosConcluidos,
    handleProjetosConcluidos,
    loadConcluidos,
  };
}
