/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { AiFillPlusCircle } from 'react-icons/ai';
import ReactModal from 'react-modal';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import { MdOutlinePlaylistPlay } from 'react-icons/md';
import { TablePadrão } from '../../../../components/develop-nilton-components/TablePadrão';
import api from '../../../../services/api';
import { TbodyConfirmRound } from './TbodyConfirmRound';

type PropsBtnRemoveHomologacao = {
  handleUpdate():void
  item:any
}

export function BtnSendToHomologacao({ item, handleUpdate }:PropsBtnRemoveHomologacao) {
  const history = useHistory();

  function redirectConfirmEgresses() {
    if (item?.egressos.length === 0) {
      toast(
        'Não há egressos na ronda. Adicione egressos para enviar para homologação.',
        { type: "warning" },
      );
    } else {
      history.push({
        pathname: '/cadastrar-ronda/confirmar-egressos',
        state: { id: item.id, title: item.title },
      });
    }
  }

  return (
    <>
      <Wrapper
        data-tip="Enviar para homologação"
        onClick={redirectConfirmEgresses}
        data-testid="btn-send-homologacao"
      >
        <MdOutlinePlaylistPlay
          color={"#005cbe" ? "#212529" : undefined}
          className="icon"
          style={{ marginTop: 5, marginRight: 10 }}
          size={26}
          cursor="pointer"
        />
      </Wrapper>
    </>
  );
}

const Wrapper = styled.div`
  .icon {
    font-size: 30px;
  }
  @media (max-width: 1024px) {
    .icon {
      font-size: 20px;
    }
  }
`;
