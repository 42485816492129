import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { BsFillArrowRightCircleFill } from 'react-icons/bs';
import styled from 'styled-components';
import { BiArrowBack } from 'react-icons/bi';
import { TablePadraoDraggable } from '../../../../components/develop-nilton-components/TablePadraoDraggable';
import { SectionHeader } from '../../../../components/SectionHeader';
import { Loading } from '../../../../components/new-components/Loading';
import { SpinnerLoading } from '../../../../components/SpinnerLoading';
import api from '../../../../services/api';
import { UseRoundEgressesOfFical } from '../hooks/useRoundEgressesOfFiscal';
import { TbodyConfirmRound } from './TbodyConfirmRound';

import InputSearch from '../../components/InputSearch';
import { Detail } from '../../../Adm/Egressos/Detail';
import { IEgresses } from '../../../../types/IEgresses';

export function PageConfirmRounds() {
  const headWidth = ['20%', '60%', '40%', '40%', '40%'];
  const { state } = useLocation<any>();
  const history = useHistory();
  const {
    roundEgressesOfFiscal,
    setRoundEgressesOfFiscal,
    handleUseRoundEgressesOfFical,
  } = UseRoundEgressesOfFical();
  const [egresse, setEgresse] = React.useState<IEgresses | null>(null);
  const [roundEgresses, setRoundEgresses] = useState([]);
  const checkedComprovante = roundEgressesOfFiscal?.find(
    (item: any) => !item.ocorrencia,
  );
  const [isWatchEgresse, setIsWatchEgresse] = React.useState(false);
  const [loadingSearch, setLoadingSearch] = useState(false);

  const [load, setLoad] = useState(false);
  const [first, setFirst] = useState(null);
  const [second, setSecond] = useState(null);

  useEffect(() => {
    setRoundEgresses(roundEgressesOfFiscal);
  }, [roundEgressesOfFiscal]);

  async function handleSendToHomologação() {
    try {
      if (!checkedComprovante) {
        setLoad(true);
        await api.put(`/rounds/${state.id}`, {
          em_homologacao: 'T',
          homologado: 'F',
        });
        toast('Ronda enviada para fila de espera de homologação.', {
          type: 'success',
        });
        setLoad(false);
        history.push('/cadastrar-ronda');
      } else {
        toast('Adicione o comprovante em todos os egressos.', {
          type: 'warning',
        });
      }
    } catch (error) {
      setLoad(false);
      // @ts-ignore
      toast(error.response.data.message, { type: 'error' });
    }
  }

  async function search(page: number, search: string) {
    setLoadingSearch(true);

    const filter = roundEgressesOfFiscal.filter((value: any) =>
      String(value.egresso.nome)
        .toUpperCase()
        .includes(String(search).toUpperCase()),
    );
    setRoundEgresses(filter);

    setLoadingSearch(false);
  }

  const dragItem = React.useRef<any>(null);
  const dragOverItem = React.useRef<any>(null);

  // const handle drag sorting
  const handleSort = () => {
    try {
      const roundEgressesCopy = [...roundEgresses];

      const draggedItemContent = roundEgressesCopy.splice(
        dragItem.current,
        1,
      )[0];

      roundEgressesCopy.splice(dragOverItem.current, 0, draggedItemContent);

      dragItem.current = null;
      dragOverItem.current = null;
      setFirst(null);
      setSecond(null);
      setRoundEgresses(roundEgressesCopy);
    } catch (error) {
      console.log(error);
    }
  };

  function selectFirst(i: number) {
    setFirst(i);
  }
  function selectSecond(i: number) {
    setSecond(i);
    dragItem.current = first;
    dragOverItem.current = i;
    handleSort();
  }

  return (
    <WrapperContainer>
      {isWatchEgresse && (
        <Detail
          useStateEgresse={[egresse, setEgresse]}
          useStateIsOpen={[isWatchEgresse, setIsWatchEgresse]}
        />
      )}
      <div style={{ display: isWatchEgresse ? 'none' : undefined }}>
        <SectionHeader
          subtitle={`${state.title}`}
          isIconBack={
            <div
              onClick={() => history.push({ pathname: '/cadastrar-ronda' })}
              style={{ cursor: 'pointer', marginRight: 10 }}
            >
              <BiArrowBack size={30} />
            </div>
          }
        />

        <div className="content">
          {/* <div style={{ marginBottom: 10 }} /> */}
          <InputSearch
            loading={loadingSearch}
            setSearch={search}
            autosearch
            title="Pesquisar Egresso"
            placeholder="Digite o nome do egresso"
            buttonText="Buscar"
            description=""
            // lembrar de colocar a propriedade disabled
            button={
              <button
                style={{
                  marginLeft: 10,
                  background: '#1B7E9F',
                  border: 'none',
                }}
                className="btn btn-primary btn-lg"
                onClick={handleSendToHomologação}
                disabled={load}
              >
                {load && <SpinnerLoading />}
                {!load && (
                  <>
                    <BsFillArrowRightCircleFill style={{ marginRight: 10 }} />
                    Homologar
                  </>
                )}
              </button>
            }
          />
        </div>

        <TablePadraoDraggable
          thead={['Ordem', 'Egresso', 'Data/hora', 'Status', 'Ações']}
          headWidth={headWidth}
        >
          {roundEgresses?.map((item: any, i: any) => (
            <div
              draggable
              onDragStart={e => (dragItem.current = i)}
              onDragEnter={e => (dragOverItem.current = i)}
              onDragEnd={handleSort}
              onDragOver={e => e.preventDefault()}
              style={{ cursor: 'move' }}
            >
              <TbodyConfirmRound
                first={first}
                selectFirst={selectFirst}
                selectSecond={selectSecond}
                key={item.id}
                iteration={i}
                headWidth={headWidth}
                itemEgresso={item}
                update={handleUseRoundEgressesOfFical}
                roundEgressesOfFiscal={roundEgressesOfFiscal}
                setRoundEgressesOfFiscal={setRoundEgressesOfFiscal}
                useStateEgresse={[egresse, setEgresse]}
                useStateIsOpen={[isWatchEgresse, setIsWatchEgresse]}
              />
            </div>
          ))}
        </TablePadraoDraggable>

        {!roundEgressesOfFiscal && (
          <Center>
            <Loading />
          </Center>
        )}
      </div>
    </WrapperContainer>
  );
}

const WrapperContainer = styled.div`
  width: 100%;

  .content {
    width: 100%;
    margin-bottom: 50px;

    & div {
      display: flex;
    }

    select:focus {
      outline: none;
    }

    textarea:focus {
      outline: none;
    }

    & input {
      width: 100%;
    }

    & input:focus {
      outline: none !important;
    }

    .inputfile {
      width: 0.1px;
      height: 0.1px;
      opacity: 0;
      overflow: hidden;
      position: absolute;
      z-index: -1;
    }
    .inputfile:focus + label {
      outline: 1px dotted #000;
      outline: -webkit-focus-ring-color auto 5px;
    }
    .inputfile + label * {
      pointer-events: none;
    }
  }
`;

const Center = styled.div`
  display: flex;
  justify-content: center;
`;
