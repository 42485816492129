import React, { useState } from 'react';
import ReactTooltip from 'react-tooltip';
import { IconConcluido } from '../../../../components/develop-nilton-components/IconConcluido';
import { IconDetails } from '../../../../components/develop-nilton-components/IconDetails';
import { Td, Tr } from '../../../../components/develop-nilton-components/TablePadrão';
import { PropsTbodyTable } from '../types';
import { ModalVisualizarProjeto } from './ModalVisualizarProjeto';

export function TbodyTableConcluido({ headWidth, projetos, index }:PropsTbodyTable) {
  const [isOpenModalVisualizarProjeto, setIsOpenModalVisualizarProjeto] = useState(false);
  function handleIsOpenModalVisualizarProjeto() {
    setIsOpenModalVisualizarProjeto(!isOpenModalVisualizarProjeto);
  }
  return (
    <Tr>
      <Td width={headWidth[0]}>{index}</Td>
      <Td width={headWidth[1]}>{projetos.nome}</Td>
      <Td width={headWidth[2]}>
        {projetos.pedagogo.name}
      </Td>
      <Td width={headWidth[3]}>
        <div onClick={handleIsOpenModalVisualizarProjeto} className="mr-3">
          <IconDetails cursor size={30} tooltipMessage="Visualizar" />
        </div>
        <IconConcluido
          size={30}
          tooltipMessage="Homologar"
        />
        <ModalVisualizarProjeto id={projetos.id} isOpen={isOpenModalVisualizarProjeto} handleIsOpen={handleIsOpenModalVisualizarProjeto} />
        <ReactTooltip />
      </Td>
    </Tr>
  );
}
