/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { IconAdd } from '../../../../components/develop-nilton-components/IconAdd';
import { TablePadrão, Td, Tr } from '../../../../components/develop-nilton-components/TablePadrão';
import { Pagination } from '../../../../components/Pagination';
import { SpinnerLoading } from '../../../../components/SpinnerLoading';
import { TbodyGeralPageAddRoundsEgresses } from './TbodyGeralPageAddRoundsEgresses';
import { IEgresses } from '../../../../types/IEgresses';

type PropsTableGeralRoundEgresses = {
  egresses: any
  handleSetEgresses(page?: number): Promise<void> | void
  useStateCheckboxAll: [any, React.Dispatch<any>]
  loadEgress: boolean
  useStateEgresse: [IEgresses | null, (egresse:IEgresses | null) => void]
  useStateIsOpen: [boolean, (boolean: boolean) => void]
}

export function TableGeralRoundCreateEgresses({
  egresses, handleSetEgresses, useStateCheckboxAll, loadEgress, useStateEgresse, useStateIsOpen,
}:PropsTableGeralRoundEgresses) {
  const headWidth = ["5%", "85%", "10%"];
  const [checkboxAll, setCheckboxAll] = useStateCheckboxAll;

  const [egresse, setEgresse] = useStateEgresse;
  const [isWatchEgresse, setIsWatchEgresse] = useStateIsOpen;

  function handleCheckedAll(e:any) {
    setCheckboxAll(checkboxAll?.map((item:any) => ({ ...item, checked: e.target.checked })));
  }

  function handleChecked(egress_id:number) {
    setCheckboxAll(checkboxAll?.map((item:any) => (item.egress_id === egress_id ? ({ ...item, checked: !item.checked }) : item)));
  }

  useEffect(() => {
    setCheckboxAll(
      egresses?.data
        .filter((item:any) => (item.inRound.length === 0 && item.endereco.filter((addr: any) => addr.atual).length !== 0))
        .map((item:any) => ({
          round_id: '',
          nome: item.nome,
          egress_id: item.id,
          checked: false,
        })),
    );
  }, [egresses, setCheckboxAll]);

  return (
    <>
      <TablePadrão
        thead={[<CheckBox
          type="checkbox"
          autoComplete="off"
          onChange={handleCheckedAll}
        />, "Nome", "Ações"]}
        headWidth={headWidth}
      >
        {loadEgress && <Tr><Td width="100%"><SpinnerLoading /></Td></Tr>}
        {egresses?.data.map((item: any, i: React.Key | null | undefined) => (
          <TbodyGeralPageAddRoundsEgresses
            key={i}
            itemEgresso={item}
            headWidth={headWidth}
            isCheckboxAll={checkboxAll}
            handleChecked={handleChecked}
            loadEgress={loadEgress}
            useStateEgresse={[egresse, setEgresse]}
            useStateIsOpen={[isWatchEgresse, setIsWatchEgresse]}
          />
        ))}
        {egresses && <Tr><Td width="100%"><Pagination meta={egresses?.meta} setPage={handleSetEgresses} load={loadEgress} /></Td></Tr>}
      </TablePadrão>
    </>
  );
}

const CheckBox = styled.input`
  width: 15px;
  height: 15px;
`;
