import React from 'react';
import { AiFillMinusCircle } from 'react-icons/ai';
import ReactTooltip from 'react-tooltip';
import { Td, Tr } from '../../../../components/develop-nilton-components/TablePadrão';

type PropsTbodyAdicionarEgressoNaRonda = {
  itemEgresso:any
  headWidth: string[]
  checkboxAll?: any
  setCheckboxAll?(value: any): void
  isLoad: boolean
}

export function TbodyToBeAddInTheRounds({
  headWidth, itemEgresso, checkboxAll, setCheckboxAll, isLoad,
}:PropsTbodyAdicionarEgressoNaRonda) {
  async function handleDelete() {
    if (setCheckboxAll && checkboxAll && itemEgresso) {
      setCheckboxAll(
        checkboxAll
          .map((item:any) => (item.egress_id == itemEgresso.egress_id ? ({
            ...item,
            checked: false,
          }) : item)),
      );
    }
  }

  return (
    <Tr>
      <Td width={headWidth[0]}>{itemEgresso?.nome}</Td>
      <Td width={headWidth[1]}>
        {!isLoad && (
          <div
            data-tip="Remover da ronda"
            onClick={handleDelete}
          >
            <AiFillMinusCircle
              color="rgba(201, 19, 19, 0.6)"
              size={30}
              cursor="pointer"
            />
          </div>
        )}

        <ReactTooltip />
      </Td>
    </Tr>
  );
}
