import { useQuery } from "react-query";
import api from "../../services/api";
import { IFetchParams } from "../../types/IFetchParams";
import { IUser } from "../../types/IUser";

export type UserAxiosResponse = {
  data: IUser[]
  meta:{last_page: number}
}

export function useGetUsers({ page, search }:IFetchParams) {
  const { isLoading, data: users } = useQuery<UserAxiosResponse>(
    ['users', search, page],
    async () => {
      const { data } = await api.get('/users', { params: { search, page, perPage: 15 } });
      return data;
    },
  );
  return { isLoading, users };
}
