/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { BiArrowBack } from 'react-icons/bi';
import { IoIosArrowDropleftCircle } from 'react-icons/io';
import { debounce } from 'lodash';
import { AbaFilterButtons, AbaFilterContent } from '../../../../components/develop-nilton-components/AbaFiltro';
import { IconAdd } from '../../../../components/develop-nilton-components/IconAdd';
import { SectionHeader } from '../../../../components/SectionHeader';
import { SpinnerLoading } from '../../../../components/SpinnerLoading';
import { useEgresses } from '../hooks/useEgresses';
import { UseRoundEgressesOfFical } from '../hooks/useRoundEgressesOfFiscal';
import { TableAlreadyAddsInARounds } from './TableAlreadyAddsInTheRound';
import { TableGeralRoundEgresses } from './TableGeralRoundEgresses';
import { useCheckboxAll } from '../hooks/useCheckboxAll';
import { SearchEgresses } from '../../../../components/develop-nilton-components/SearchEgresses';
import { ModalAdicionarRonda } from './ModalAdicionarRonda';
import { TableGeralRoundCreateEgresses } from './TableGeralRoundCreateEgresses';
import { ButtonAdd } from '../../../../components/new-components/ButtonAdd';
import { useGetEgressesOptions } from "../../../Pedagogo/CadCurso/hooks/useGetEgressesOptions";
import InputSearch from '../../components/InputSearch';
import api from '../../../../services/api';
import { Detail } from '../../../Adm/Egressos/Detail';
import { IEgresses } from '../../../../types/IEgresses';

export function PageAddRoundsCreateEgresses() {
  const history = useHistory();
  const { state } = useLocation<any>();
  const [openModalAdicionarRonda, setOpenModalAdicionarRonda] = useState(false);
  const [tab, setTab] = useState(1);
  const [checkboxAll, setCheckboxAll] = useState<any>(null);
  const [checkboxAllRound, setCheckboxAllRound] = useState<any>(null);
  const [isOpenAddEgressRondasModal, setIsOpenAddEgressRondasModal] = React.useState(false);
  const [load, setLoad] = useState(false);

  const [optionsEgresses, setOptionsEgresses] = useState([]);

  const [egresse, setEgresse] = React.useState<IEgresses |null>(null);
  const [isWatchEgresse, setIsWatchEgresse] = React.useState(false);

  const [loadingSearch, setLoadingSearch] = useState(false);

  const { egresses, handleSetEgresses, load: loadEgress } = useEgresses();

  const { handleAddEgresses } = useCheckboxAll(checkboxAll, handleUpdate, setLoad, setTab);

  const [roundEgresses, setRoundEgresses] = useState<any>([]);

  async function getEgresses() {
    // const { data } = await api.get("/egresses", { params: { nopaginate: true } });
    // setOptionsEgresses(data);
    // setRoundEgresses(data);
  }

  React.useEffect(() => {
    getEgresses();
  }, []);

  function handleIsOpenModalDelete() {
    history.push({ pathname: "/cadastrar-ronda" });
  }

  async function handleUpdate(page?:number) {
    // console.log(search);
    await handleSetEgresses(page);
  }

  function handleOpenModalAdicionarRonda() {
    setOpenModalAdicionarRonda(!openModalAdicionarRonda);
  }

  const [searchTerm, setSearchTerm] = useState("");

  async function search(page: number, search: string) {
    setLoadingSearch(true);

    /* console.log(optionsEgresses);

    // @ts-ignore
    const filter = optionsEgresses.filter((value: any) => String(value.egresso.nome)
      .toUpperCase()
      .includes(String(search).toUpperCase()));
    setRoundEgresses(filter); */

    // await handleSetEgresses(page || 1, search);
    setSearchTerm(search);
    setLoadingSearch(false);
  }

  const debounceLoadData = useMemo(() => debounce(handleSetEgresses, 700), []);

  React.useEffect(() => {
    // @ts-ignore
    window.addEventListener("keydown", debounceLoadData(1, searchTerm));
    return () => {
      // @ts-ignore
      window.removeEventListener("keydown", debounceLoadData(1, searchTerm));
    };
  }, [searchTerm]);

  return (
    <WrapperContainer>
      {isWatchEgresse && (
        <Detail
          useStateEgresse={[egresse, setEgresse]}
          useStateIsOpen={[isWatchEgresse, setIsWatchEgresse]}
        />
      )}
      {/* ) : ( */}
      <div style={{ display: isWatchEgresse ? 'none' : undefined }}>
        <ModalAdicionarRonda
          handleUpdateTable={handleUpdate}
          useStateCheckboxAll={[checkboxAll, setCheckboxAll]}
          stateModalIsOpen={[openModalAdicionarRonda, setOpenModalAdicionarRonda]}
          handleOpen={handleOpenModalAdicionarRonda}
        />

        <SectionHeader
          subtitle="Rondas"
          isIconBack={(
            <div onClick={() => history.push({ pathname: '/cadastrar-ronda' })} style={{ cursor: "pointer", marginRight: 10 }}>
              <BiArrowBack size={30} />
            </div>
            )}
        />

        <div style={{ width: "100%", marginBottom: 50 }}>
          <div className="content">
            <InputSearch
              loading={loadingSearch}
              setSearch={search}
              autosearch
              title="Pesquisar Egresso"
              placeholder="Digite o nome ou localidade do egresso"
              buttonText="Buscar"
              description=""
              button={(
                <ButtonAdd
                  style={{ marginLeft: 10, background: '#68996D' }}
                  onClick={() => {
                    handleOpenModalAdicionarRonda();
                  }}
                />
          )}
            />
          </div>
        </div>

        <AbaFilterContent
          tab={tab}
          components={[
            <TableGeralRoundCreateEgresses
              egresses={egresses}
              handleSetEgresses={handleUpdate}
              useStateCheckboxAll={[checkboxAll, setCheckboxAll]}
              loadEgress={loadEgress}
              useStateEgresse={[egresse, setEgresse]}
              useStateIsOpen={[isWatchEgresse, setIsWatchEgresse]}
            />,
          ]}
        />
      </div>
      {/* )} */}
    </WrapperContainer>
  );
}

const WrapperContainer = styled.div`
  width: 100%;

  .content {
    width: 100%;

    & div {
      display: flex;
    }

    select:focus {
      outline: none;
    }

    textarea:focus {
      outline: none;
    }

    & input {
      width: 100%;
    }

    & input:focus {
      outline: none !important;
    }

    .inputfile {
      width: 0.1px;
      height: 0.1px;
      opacity: 0;
      overflow: hidden;
      position: absolute;
      z-index: -1;
    }
    .inputfile:focus + label {
      outline: 1px dotted #000;
      outline: -webkit-focus-ring-color auto 5px;
    }
    .inputfile + label * {
      pointer-events: none;
    }
  }
`;

const Button = styled.button`
div {
  display: flex;
  gap: 10px;
  align-items: center;
}

@media (max-width: 1024px) {
  span {
    display: none;
  }
  }
`;

const ContainerAba = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px
`;

const ButtonWrapper = styled.div`
  @media (max-width: 1024px) {
    display: none;
  }
`;
