import styled from 'styled-components';

export const Button = styled.button`
    font-size: 20px;
    font-weight: 700;
    line-height: 30px;
    letter-spacing: 0px;
    text-align: center;

    height: 46px;
    width: 160px;
    border-radius: 4.800000190734863px;
    padding: 8px, 16px, 8px, 16px;

`;
