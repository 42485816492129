import React, { useEffect, useState } from 'react';
import api from '../../../../services/api';

export function useGetTurmaEvasao_nas_turma_no_ano() {
  const [evasao_nas_turma_no_ano, set_media_alunos_por_turma_no_ano] = useState<any>(null);

  async function handleEvasao_nas_turma_no_ano(year?:string) {
    const { data } = await api.get('/reports/evasao_nas_turma_no_ano', {
      params: {
        year,
      },
    });
    set_media_alunos_por_turma_no_ano(data);
  }

  useEffect(() => {
    handleEvasao_nas_turma_no_ano();
  }, []);
  return { evasao_nas_turma_no_ano, handleEvasao_nas_turma_no_ano };
}
