import React from 'react';
import { FormState, UseFormRegister } from 'react-hook-form';
import styled from 'styled-components';
import { Errors } from '../../pages/Pedagogo/CadastrarProjeto/components/Erro';

type INameField = {
    register: UseFormRegister<any>
    formState: FormState<any>
    name: string
    label?: string
    size?: "lg" | "sm"
    width?: string | number
    disable?: boolean
}

export function DateField({
  register, formState, label, name, size, width, disable,
}:INameField) {
  return (
    <Input width={width || null}>
      {label && <label htmlFor="">{label}</label>}
      <div>
        <input
          required
          autoComplete="off"
          type="date"
          {...register(name, { required: true })}
          className={size ? `form-control form-control-${size} position-relative` : "form-control position-relative"}
          disabled={disable}
        />
        {formState.errors[name] && (
          <Errors />
        )}
      </div>
    </Input>
  );
}

type PropsInput = {
  width?: string | number | null
}

export const Input = styled.div<PropsInput>`
    label {
        font-size: 24px;
        font-weight: 700;
        line-height: 28px;
        letter-spacing: 0px;
        text-align: left;
    }

    input {
        width: ${(props) => (props.width ? props.width : "100%")};
        font-size: 18px;
        font-weight: 400;
        line-height: 28px;
        letter-spacing: 0em;
        text-align: left;
    }
`;
