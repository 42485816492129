import styled from 'styled-components';

export const SelectField = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;

    h2 {
        font-size: 24px;
        font-weight: 700;
        line-height: 28px;
        letter-spacing: 0px;
        text-align: left;
    }

    label {
        font-size: 18px;
        font-weight: 700;
        line-height: 22px;
        letter-spacing: 0px;
        text-align: left;
    }

    input {
        width: 100%;
        font-size: 18px;
        font-weight: 400;
        line-height: 28px;
        letter-spacing: 0em;
        text-align: left;
    }

    div {
        display: flex;
        align-items: center;
        gap: 10px;
    }

    .react-select-container {
        width: 100%;
        height: 45px;
    }
    .react-select__control {
        width: 100%;
        height: 100%;
    }
    .react-select__menu-list {
        width: 100%;
        display: flex;
        flex-direction: column
    }
`;
